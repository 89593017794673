import cn from 'clsx';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { SLA_TYPE_TITLES_MAP } from '@entities/sla';
import { Ticket, TicketSLAType } from '@entities/ticket';
import {
  Avatar,
  ClockFat,
  ConditionBlock,
  getDurationTime,
  getFormatDisplayedTime,
  RouterHref,
  Size,
  Table,
  Typography,
  TypographyVariants,
} from '@shared';

import { TICKETS_FROM_DESKTOP_TABLE_FIELDS } from '../../config';

import styles from './TicketsFromDesktopTable.module.scss';

interface DataTableProps {
  tickets?: Ticket[];
  linkTicketTitle?: string;
}

const slaTypeStylesMap = {
  [TicketSLAType.DECISION]: styles.ticketsFromDesktopTable__condition_decision,
  [TicketSLAType.REACTION]: styles.ticketsFromDesktopTable__condition_reaction,
};

const getDataTable = ({ tickets, linkTicketTitle }: DataTableProps) =>
  tickets?.map((ticket) => {
    const { number, id, specialistId, client, slaInfo } = ticket;

    const { hours, minutes, seconds } = getDurationTime(
      slaInfo?.expiredSeconds || 0
    );

    return {
      id,
      number: {
        title: `${linkTicketTitle} #${number}`,
        to: `${RouterHref.Tickets}/${id}`,
        withNotification: false,
      },
      sla: (
        <div className={styles.ticketsFromDesktopTable__slaCell}>
          <Typography
            variant={TypographyVariants.b3}
          >{`${getFormatDisplayedTime(hours)} : ${getFormatDisplayedTime(
            minutes
          )} : ${getFormatDisplayedTime(seconds)}`}</Typography>
          <ConditionBlock
            text={
              SLA_TYPE_TITLES_MAP[slaInfo?.slaType || TicketSLAType.DECISION]
            }
            className={cn(
              styles.ticketsFromDesktopTable__condition,
              slaTypeStylesMap[slaInfo?.slaType || TicketSLAType.DECISION]
            )}
          />
          <div
            className={cn(styles.ticketsFromDesktopTable__roundClock, {
              [styles.ticketsFromDesktopTable__condition_decision]:
                !slaInfo?.roundTheClock,
              [styles.ticketsFromDesktopTable__condition_reaction]:
                slaInfo?.roundTheClock,
            })}
          >
            <ClockFat
              className={styles.ticketsFromDesktopTable__conditionIcon}
            />
            <Typography variant={TypographyVariants.h5}>24/7</Typography>
          </div>
        </div>
      ),
      specialistId: (
        <div className={styles.ticketsFromDesktopTable__cell}>
          {specialistId?.value && (
            <Avatar
              size={Size.xxs}
              initialsUser={specialistId?.value}
              className={styles.ticketsFromDesktopTable__cellItem}
            />
          )}
          {specialistId?.value || '-'}
        </div>
      ),
      client: (
        <div className={styles.ticketsFromDesktopTable__cell}>
          {client?.value && (
            <Avatar
              size={Size.xxs}
              initialsUser={client?.value}
              className={styles.ticketsFromDesktopTable__cellItem}
            />
          )}
          {client?.value || '-'}
        </div>
      ),
    };
  }) || [];

interface TicketsFromDesktopTableProps {
  tickets?: Ticket[];
  sort?: string;
  handleSort?: (value: string) => void;
  loading?: boolean;
}

export const TicketsFromDesktopTable: FC<TicketsFromDesktopTableProps> = ({
  tickets,
  sort,
  handleSort,
  loading,
}) => {
  const { t } = useTranslation('notifications');

  const data = getDataTable({
    tickets,
    linkTicketTitle: t('notification.ticketLinkTitle'),
  });

  return (
    <Table
      columns={TICKETS_FROM_DESKTOP_TABLE_FIELDS}
      data={data}
      sort={sort}
      onSort={handleSort}
      dataLoading={loading}
      disabledSort={loading}
    />
  );
};
