import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
  fetchSystemsRequest,
  getPropsSystems,
  getSystemId,
  getSystems,
  setCurrentSystemId,
  setCurrentSystemsPage,
  setPageSize,
  setSortSystems,
} from '@entities/statuses-builder';
import { getSystemsFilter } from '@entities/systems';
import { checkObjectIdentity } from '@shared';

export const useSystemsTableContainer = () => {
  const dispatch = useDispatch();

  const { pageNum, pageSize, sortSystems, totalElements, loadingSystem } =
    useSelector(getPropsSystems);
  const systems = useSelector(getSystems);
  const currentSystemId = useSelector(getSystemId);
  const filterValues = useSelector(getSystemsFilter);

  const [showFilterRow, setShowFilterRow] = useState(false);

  const isFilterActive = !checkObjectIdentity(filterValues, {});

  const searchDisabled = !systems?.length || (showFilterRow && isFilterActive);

  const toggleFilterRow = () => {
    setShowFilterRow((prevState) => !prevState);
  };

  const onRowClick = (value: string) => {
    if (currentSystemId !== value) {
      dispatch(setCurrentSystemId(value));
    }
  };

  const handleChangePage = (page: number) => {
    dispatch(setCurrentSystemsPage(page));
    dispatch(fetchSystemsRequest());
  };

  const handleChangePageSize = (size: number) => {
    dispatch(setPageSize(size));
  };

  const handleSort = (value: string) => {
    dispatch(setSortSystems(value));
    dispatch(fetchSystemsRequest());
  };

  return {
    methods: {
      toggleFilterRow,
      handleChangePage,
      handleChangePageSize,
      handleSort,
      onRowClick,
    },
    state: {
      pageNum,
      pageSize,
      sortSystems,
      totalElements,
      systems,
      currentSystemId,
      showFilterRow,
      isFilterActive,
      searchDisabled,
      loadingSystem,
    },
  };
};
