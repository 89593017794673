import { isString } from 'lodash';

import { CustomFieldType } from '@shared/model';
import { SelectOption } from '@shared/ui/Select';
import {
  ACTIVE_SELECT_OPTIONS,
  getReverseDateFromCustomField,
} from '@shared/ui/Table';

import { TableCustomField } from '../../model';

const getPropsCustomFieldType = (field: TableCustomField) => {
  const fieldTypeMap = {
    [CustomFieldType.FIELD_DATE]: () =>
      isString(field.date) ? getReverseDateFromCustomField(field.date) : '-',
    [CustomFieldType.FIELD_FLAG]: () =>
      field.flag
        ? ACTIVE_SELECT_OPTIONS[0].title
        : ACTIVE_SELECT_OPTIONS[1].title,
    [CustomFieldType.FIELD_TEXT]: () => field.text ?? '-',
    [CustomFieldType.FIELD_LIST]: () => field.listValue ?? '-',
  };

  return fieldTypeMap[field.customFieldType]();
};

export const getCustomFieldsForTable = (fields: TableCustomField[]) => {
  const customFieldsForTable: Record<string, string | boolean | SelectOption> =
    {};

  fields.forEach((field) => {
    if (field.customFieldId) {
      customFieldsForTable[field.customFieldId] =
        getPropsCustomFieldType(field);
    }
  });

  return customFieldsForTable;
};
