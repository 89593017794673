import { Priority } from '../../../model/types';

export const PRIORITY_TITLE_MAP_SHORT = {
  [Priority.NONE]: 'Без приоритета',
  [Priority.LOW]: 'Низкий',
  [Priority.MIDDLE]: 'Средний',
  [Priority.HIGH]: 'Высокий',
  [Priority.VERY_HIGH]: 'Критический',
};

export const PRIORITY_TITLE_MAP_SHORT_EN = {
  [Priority.NONE]: 'Without priority',
  [Priority.LOW]: 'Low',
  [Priority.MIDDLE]: 'Middle',
  [Priority.HIGH]: 'High',
  [Priority.VERY_HIGH]: 'Very high',
};
