import { useSelector } from 'react-redux';

import { ActionList, getIsAccessedAction } from '@entities/auth';
import { DesktopHeader } from '@features/desktop';
import { MainLayout } from '@shared';
import { DesktopCurrentState } from '@widgets/desktop';

import styles from './DesktopCurrentStatePage.module.scss';

const DesktopCurrentStatePage = () => {
  const isAccessToCreateTicket = useSelector(
    getIsAccessedAction(ActionList.CreateTicket)
  );

  return (
    <MainLayout
      customHeader={<DesktopHeader />}
      accessToCreateTicket={isAccessToCreateTicket}
    >
      <DesktopCurrentState className={styles.desktopPage__content} />
    </MainLayout>
  );
};

export default DesktopCurrentStatePage;
