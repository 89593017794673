import cn from 'clsx';
import { FC, useState } from 'react';

import {
  ApproveOrCancel,
  ApproveOrCancelProps,
} from '@shared/ui/ApproveOrCancelModal';
import {
  CardTableHeader,
  CardTableHeaderProps,
} from '@shared/ui/CardTableHeader';
import { CountRecords, CountRecordsProps } from '@shared/ui/CountRecords';
import { Info, InfoProps } from '@shared/ui/Info';
import { Pagination, PaginationProps } from '@shared/ui/Pagination';

import styles from './TableContainer.module.scss';

interface TableContainerProps {
  classNameRoot?: string;
  classNameContent?: string;
  headerProps?: CardTableHeaderProps;
  contentTable?: JSX.Element;
  countRecordsProps?: CountRecordsProps;
  paginationProps: PaginationProps;
  approveOrCancelProps?: ApproveOrCancelProps;
  infoProps?: InfoProps;
  isWithOutsideClick?: boolean;
}

export const TableContainer: FC<TableContainerProps> = ({
  classNameRoot,
  classNameContent,
  headerProps,
  contentTable,
  countRecordsProps,
  paginationProps,
  approveOrCancelProps,
  infoProps,
  isWithOutsideClick,
}) => {
  const [tableOpened, setTableOpened] = useState(true);

  const loader = headerProps?.loading && (
    <>
      <div className={styles.tableContainer__progressLine} />
      <div className={styles.tableContainer__thumb} />
    </>
  );

  return (
    <div
      className={cn(styles.tableContainer, classNameRoot, {
        [styles.tableContainer_close]: !tableOpened,
      })}
    >
      {headerProps && (
        <CardTableHeader
          {...headerProps}
          tableOpened={tableOpened}
          handleTableOpen={setTableOpened}
          isWithOutsideClick={isWithOutsideClick}
        />
      )}
      <div
        className={cn(
          styles.tableContainer__content,
          { [styles.tableContainer__content_loading]: headerProps?.loading },
          classNameContent
        )}
      >
        <div
          className={cn(styles.tableContainer__background, {
            [styles.tableContainer__background_show]: headerProps?.loading,
          })}
        />
        {contentTable}
        {loader}
        <div className={styles.tableContainer__pagination}>
          {countRecordsProps && (
            <CountRecords
              {...countRecordsProps}
              className={styles.tableContainer__countRecords}
            />
          )}
          {paginationProps && <Pagination {...paginationProps} />}
        </div>
      </div>
      {approveOrCancelProps && <ApproveOrCancel {...approveOrCancelProps} />}
      {infoProps && <Info {...infoProps} />}
    </div>
  );
};
