import { AccessesState } from './types';

export const getAccesses = (state: AccessesState) => state.accesses.accesses;
export const getLoading = (state: AccessesState) => state.accesses.loading;
export const getAccessesFilter = (state: AccessesState) =>
  state.accesses.accessesFilter || {};
export const getPropsAccesses = (state: AccessesState) => ({
  pageNum: state.accesses.pagination?.pageNum,
  pageSize: state.accesses.pagination?.pageSize,
  sortAccesses: state.accesses.sort,
  totalElements: state.accesses.pagination?.totalElements,
  loadingAccesses: state.accesses.loading,
});
