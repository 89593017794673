import { useEffect, useMemo, useState } from 'react';
import { useFieldArray, useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';

import { ActionList, getIsAccessedAction } from '@entities/auth';
import {
  createSystem,
  editSystem,
  fetchSystemIndexList,
  FieldArrayUpdate,
  getSystem,
  getSystemIndexList,
  SystemFormData,
} from '@entities/system';
import { getValueFromValueType, RouterHref } from '@shared';

import { DEFAULT_CREATE_SYSTEM_FORM_VALUES } from '../../config';
import { getPreparedSystemValues } from '../utils';

import { useSystemId } from './useSystemId';

// eslint-disable-next-line sonarjs/cognitive-complexity
export const useSystemForm = () => {
  const { push } = useHistory();
  const dispatch = useDispatch();

  const systemId = useSystemId();

  const mainLayoutSticky = document.getElementById('mainLayoutSticky');

  const system = useSelector(getSystem);
  const indexList = useSelector(getSystemIndexList);

  const isAccessToCreateSystem = useSelector(
    getIsAccessedAction(ActionList.CreateSystem)
  );

  const [currentIndex, setCurrentIndex] = useState('');
  const [isTicketTypeCreate, setIsTicketTypeCreate] = useState(false);
  const [isEnvironmentCreate, setIsEnvironmentCreate] = useState(false);
  const [isModal, setIsModal] = useState<boolean>(false);

  const isEditMode = !!systemId && !!system;

  const canEditForm = isAccessToCreateSystem;

  const defaultValues = useMemo(
    () =>
      isEditMode
        ? getPreparedSystemValues(system)
        : DEFAULT_CREATE_SYSTEM_FORM_VALUES,
    [isEditMode, system]
  );

  const {
    register,
    watch,
    handleSubmit,
    control,
    reset,
    setValue,
    formState: { errors, isDirty, isValid },
  } = useForm<SystemFormData>({
    mode: 'onChange',
    defaultValues,
  });

  const fieldValues = watch();

  const ticketTypeFieldArray = useFieldArray({
    control,
    name: 'ticketType',
    keyName: '_id',
  });

  const environmentFieldArray = useFieldArray({
    control,
    name: 'environment',
    keyName: '_id',
  });

  const isEmptyFields =
    !ticketTypeFieldArray.fields.length || !environmentFieldArray.fields.length;

  const disabledAddTicketType =
    (!!fieldValues.ticketType.length &&
      !fieldValues.ticketType[fieldValues.ticketType.length - 1].title
        .length) ||
    !!errors.ticketType?.length;

  const disabledAddEnvironment =
    (!!fieldValues.environment.length &&
      !fieldValues.environment[fieldValues.environment.length - 1].name) ||
    !!errors.environment?.length;

  const isCreate = isTicketTypeCreate || isEnvironmentCreate;

  const canCreateSystem = isValid && isDirty && !isEmptyFields && !isCreate;

  const canEditSystem = isDirty && !isEmptyFields && !isCreate;

  const canSave = isEditMode ? canEditSystem : canCreateSystem;

  useEffect(() => {
    if (systemId && system) {
      reset(getPreparedSystemValues(system));
      setCurrentIndex(system?.index);
    }
  }, [systemId, system]);

  useEffect(() => {
    dispatch(fetchSystemIndexList());
  }, []);

  const titleInputOptions = register('system.title', {
    required: true,
    maxLength: {
      value: 100,
      message: 'Название системы не может быть длиннее 100 символов.',
    },
    onChange: (event) => {
      const index = event.target.value.toUpperCase().trim();

      if (!isEditMode) {
        // eslint-disable-next-line sonarjs/no-duplicate-string
        setValue('system.index', index, {
          shouldValidate: true,
        });
      }
    },
  });

  const versionInputOptions = register('system.versionTitle', {
    required: true,
    maxLength: {
      value: 100,
      message: 'Версия системы не может быть длиннее 100 символов.',
    },
  });

  const indexInputOptions = register('system.index', {
    required: true,
    maxLength: {
      value: 10,
      message: 'Индекс не может быть длиннее 10 символов.',
    },
    onChange: (event) => {
      const index = event.target.value.toUpperCase().trim();
      setValue('system.index', index);
    },
    validate: (value) => {
      if (indexList?.includes(value) && value !== currentIndex) {
        return 'Индекс уже существует.';
      }
      return true;
    },
  });

  const descriptionTextAreaOptions = register('system.description', {
    required: true,
    maxLength: {
      value: 255,
      message: 'Описание системы не может быть длиннее 255-ти символов.',
    },
  });

  const toggleModal = () => setIsModal((prev) => !prev);

  const onApproveModal = () => {
    push(RouterHref.AdminSystems);
    reset();
  };

  const formSubmitHandler = handleSubmit((data) => {
    const preparedData = {
      ...data,
      system: {
        ...data.system,
        ...(isEditMode && { id: systemId }),
        organization: {
          id: getValueFromValueType(data.system.organization) || '',
        },
      },
    };

    if (isEditMode) {
      dispatch(editSystem(preparedData));
      reset();
      return;
    }

    dispatch(createSystem(preparedData));
    reset();
  });

  const ticketTypeCreate = () => {
    setIsTicketTypeCreate(true);
    ticketTypeFieldArray.append({ id: uuidv4(), title: '', value: '' });
  };

  const environmentCreate = () => {
    setIsEnvironmentCreate(true);
    environmentFieldArray.append({ id: uuidv4(), name: '', description: '' });
  };

  const ticketTypeUpdate =
    ({ id, index, value }: FieldArrayUpdate) =>
    () => {
      setIsTicketTypeCreate(false);
      ticketTypeFieldArray.update(index, {
        id,
        title: value,
        value,
      });
    };

  const environmentUpdate =
    ({ id, index, value }: FieldArrayUpdate) =>
    () => {
      setIsEnvironmentCreate(false);
      environmentFieldArray.update(index, {
        id,
        name: value,
        description: value,
      });
    };

  const handleDeleteTicketType = (index: number) => () => {
    ticketTypeFieldArray.remove(index);
    setIsTicketTypeCreate(false);
  };

  const handleDeleteEnvironment = (index: number) => () => {
    environmentFieldArray.remove(index);
    setIsEnvironmentCreate(false);
  };

  return {
    methods: {
      toggleModal,
      onApproveModal,
      formSubmitHandler,
      ticketTypeCreate,
      environmentCreate,
      ticketTypeUpdate,
      environmentUpdate,
      handleDeleteTicketType,
      handleDeleteEnvironment,
    },
    state: {
      control,
      errors,
      isModal,
      mainLayoutSticky,
      isTicketTypeCreate,
      isEnvironmentCreate,
      isEditMode,
      canEditForm,
      fieldValues,
      ticketTypeFieldArray,
      environmentFieldArray,
      disabledAddTicketType,
      disabledAddEnvironment,
      canSave,
      titleInputOptions,
      versionInputOptions,
      indexInputOptions,
      descriptionTextAreaOptions,
    },
  };
};
