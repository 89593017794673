import { OrganizationType } from '@entities/organizations/model/types';
import { SelectOption } from '@shared';

export const TYPE_OPTIONS: SelectOption<OrganizationType>[] = [
  { title: 'Клиентская', value: OrganizationType.CUSTOMER },
  { title: 'Партнерская', value: OrganizationType.PARTNER },
];

export const TYPE_OPTIONS_FOR_FILTER: SelectOption<OrganizationType>[] = [
  ...TYPE_OPTIONS,
  { title: 'Обслуживающая', value: OrganizationType.SERVICE },
];
