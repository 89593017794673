import { getUrl } from '@shared';

export const endpoints = {
  getUsers: () => getUrl('user/filter'),
  getUser: (id: string) => getUrl(`user/${id}`),
  createUser: () => getUrl('user'),
  editUser: () => getUrl('user'),
  getUsersByGroupId: (id: string) => getUrl(`user/users-work-group/${id}`),
  getSubordinates: (contractId: string, systemId: string) =>
    getUrl(`user/subordinates/${contractId}/${systemId}`),
  getUpdateUserRolesInGroup: (id: string, groupId: string) =>
    getUrl(`user/update-roles-in-group/${id}/${groupId}`),
  getUpdateUserRolesInSystem: (id: string) =>
    getUrl(`user/update-roles-in-system/${id}`),
  getFilterClients: () => getUrl('filter-param/clients'),
  getFilterSpecialists: () => getUrl('filter-param/specialists'),
  getCheckIsExistLogin: (login: string) => getUrl(`user/exists-login/${login}`),
  getCheckIsExistEmail: (email: string) => getUrl(`user/exists-email/${email}`),
  getCheckIsExistPhone: (phone: string) =>
    getUrl(`user/exists-phone-number/${phone}`),
  getUsersByContractId: (id: string) => getUrl(`user/contract/${id}`),
  deleteUser: (id: string) => getUrl(`user/${id}`),
};
