import cn from 'clsx';
import { FC } from 'react';

import { Loader } from '../Loader';

import styles from './LazyLoader.module.scss';

interface LazyLoaderProps {
  className?: string;
}

export const LazyLoader: FC<LazyLoaderProps> = ({ className }) => (
  <div className={cn(styles.lazyLoader, className)}>
    <Loader />
  </div>
);
