import {
  CustomCondition,
  CustomConditionType,
  DefaultConditionType,
  TriggerCondition,
} from '@entities/triggers';
import { convertedTimeToMinutes, getValueFromValueType } from '@shared';

import { getCustomValue } from './getCustomValue';

export const prepareTriggerConditionForSubmit = (
  conditions?: TriggerCondition[]
) => {
  if (!conditions) {
    return [];
  }

  return conditions.map(({ condition, value }) => {
    const conditionValue = getValueFromValueType(
      condition
    ) as DefaultConditionType;
    const minutes = convertedTimeToMinutes(value);

    return { conditionType: conditionValue, minutes };
  });
};

export const prepareCustomConditionForSubmit = (
  conditions?: CustomCondition[]
) => {
  if (conditions) {
    return conditions.map(({ customField, condition, type, ...other }) => {
      const id = getValueFromValueType(customField) || '';
      const conditionType = getValueFromValueType(
        condition
      ) as CustomConditionType;
      const value = getCustomValue(type, conditionType, other.value);

      return {
        customFieldId: id,
        customFieldType: type,
        conditionType,
        ...value,
      };
    });
  }
  return [];
};
