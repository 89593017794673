import {
  Organization,
  OrganizationType,
} from '@entities/organizations/model/types';
import { WorkGroup } from '@entities/work-group/model/types';
import { TicketsTabType, ValueType } from '@shared';

export type User = {
  id: string;
  firstName?: string;
  userFullName?: string;
  lastName?: string;
  middleName?: string;
  dateBirth?: string;
  email?: string;
  phoneNumber?: string;
  login?: string;
  password?: string;
  admin?: boolean;
  workGroupList?: WorkGroup[];
  description?: string;
  organization?: Organization;
  positions?: unknown;
  dateCreate?: string;
  work?: number;
  closed?: number;
  expired?: number;
  percentFromMaxDecision?: number;
  avgDecisionSeconds?: number;
  requireUpdatePassword?: boolean;
  hasCustomerAttribute?: boolean;
  hasSpecialistAttribute?: boolean;
};

export type UsersFilterToRequest = {
  login?: string;
  lastName?: string;
  firstName?: string;
  middleName?: string;
  email?: string;
  phoneNumber?: string;
  organizationId?: string;
  idsUsersDelete?: Array<string>;
  idsWorkGroupsDelete?: Array<string>;
  fullName?: string;
  organizationType?: OrganizationType;
  ticketTab?: TicketsTabType;
};

export type UsersFilter = Omit<UsersFilterToRequest, 'organizationId'> & {
  fullName?: string;
  organizationId?: ValueType<string>;
};

export enum TableUsersTypes {
  FULL = 'FULL',
  FROM_GROUPS = 'FROM_GROUPS',
  ADD_MODAL = 'ADD_MODAL',
  FROM_DESKTOP = 'FROM_DESKTOP',
}

export interface CreateUserData
  extends Pick<
    User,
    | 'firstName'
    | 'lastName'
    | 'middleName'
    | 'description'
    | 'login'
    | 'password'
    | 'email'
    | 'phoneNumber'
    | 'id'
    | 'requireUpdatePassword'
  > {
  organizationId?: ValueType<string>;
}

export interface CreateUserDataToRequest
  extends Omit<CreateUserData, 'organizationId'> {
  organization?: {
    id: string;
  };
}
