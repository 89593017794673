import cn from 'clsx';
import { isBoolean, isEmpty, isNumber, isString } from 'lodash';
import { nanoid } from 'nanoid';
import { FC, useEffect } from 'react';
import { Controller } from 'react-hook-form';

import { CustomConditionType } from '@entities/triggers';
import {
  AlertItem,
  AlertTypes,
  Button,
  CloseIcon,
  createSelectOption,
  CustomFieldType,
  getConvertedTime,
  getOptionValue,
  getTitleFromValueType,
  getValueFromValueType,
  Input,
  PlusIcon,
  Select,
  SelectOption,
  Size,
  Tooltip,
  Typography,
  TypographyVariants,
  ValueType,
} from '@shared';

import {
  CUSTOM_FIELD_TYPE_CONDITION_MAP,
  SELECT_DATE_CONDITION_LIST,
} from '../../config';
import {
  getAttributesValueOptions,
  getListOptions,
  useTriggerForm,
} from '../../lib';

import styles from './TriggerModalContent.module.scss';

interface TriggerModalContentProps {
  triggerForm: ReturnType<typeof useTriggerForm>;
  onReset(): void;
}

export const TriggerModalContent: FC<TriggerModalContentProps> = ({
  onReset,
  triggerForm,
}) => {
  const { state, methods } = triggerForm;

  const {
    control,
    resetTrigger,
    onAddAttributeHandler,
    onAddCustomConditionHandler,
    onAddTriggerConditionHandler,
    onDeleteAttributeHandler,
    onDeleteCustomConditionHandler,
    onDeleteTriggerConditionHandler,
    onChangeAttribute,
    onChangeCustomField,
    onChangeRequiredAttributeHandler,
    onChangeAttributeValue,
    onChangeCustomCondition,
    onChangeCustomConditionValue,
    onChangeTriggerConditionValue,
    onChangeTriggerCondition,
    formSubmitHandler,
    onCloseAlert,
    onChangeListCustomField,
  } = methods;

  const {
    attributes,
    customConditions,
    triggerConditions,
    errors,
    isDisabledSubmit,
    isDisabledTargetStatus,
    isDisabledAttribute,
    isDisabledCustomCondition,
    isDisabledTriggerCondition,
    attributesFields,
    attributesOptions,
    customFieldsOptions,
    triggerConditionOptions,
    titleInputOptions,
    nextTicketStatusOptions,
    alertIsOpen,
  } = state;

  useEffect(
    () => () => {
      resetTrigger();
    },
    []
  );

  const CONDITION_COMPONENT_MAP: Record<
    CustomConditionType,
    (
      id: string,
      value: string | number | boolean | null,
      listOptions: SelectOption[]
    ) => JSX.Element
  > = {
    [CustomConditionType.TEXT_IS_EQUAL]: (id, value) => {
      const textValue = isString(value) ? value : '';

      return (
        <Input
          label="Значение"
          value={textValue}
          onChange={onChangeCustomConditionValue(id)}
        />
      );
    },
    [CustomConditionType.FLAG_IS_EQUAL]: (id) => (
      <Input
        label="Значение"
        value="Проставлен"
        onChange={onChangeCustomConditionValue(id)}
        disabled
      />
    ),
    [CustomConditionType.DATE_IS_NOW]: () => (
      <Input label="Значение" value="Текущая дата" disabled />
    ),
    [CustomConditionType.MINUTES_AFTER_DATE]: (id, value) => {
      const dateValue =
        value && !isBoolean(value) ? getConvertedTime(value) : '00:00';

      return (
        <Input
          label="Значение"
          onChange={onChangeCustomConditionValue(id)}
          mask="99:99"
          defaultValue="00:00"
          value={dateValue}
        />
      );
    },
    // eslint-disable-next-line sonarjs/no-identical-functions
    [CustomConditionType.MINUTES_BEFORE_DATE]: (id, value) => {
      const dateValue =
        value && !isBoolean(value) ? getConvertedTime(value) : '00:00';

      return (
        <Input
          label="Значение"
          onChange={onChangeCustomConditionValue(id)}
          mask="99:99"
          defaultValue="00:00"
          value={dateValue}
        />
      );
    },
    [CustomConditionType.LIST_IS_EQUAL]: (id, value, listOptions) => (
      <Select
        value={createSelectOption(value)}
        label="Значение"
        options={listOptions}
        onChange={onChangeListCustomField(id)}
      />
    ),
  };

  const getConditionComponent = (
    id: string,
    value: string | number | boolean | null,
    type: CustomFieldType | null,
    condition: ValueType<string>,
    listOptions: SelectOption[]
  ) => {
    if (type) {
      const isDate = type === CustomFieldType.FIELD_DATE;
      const preparedValue = isDate
        ? getValueFromValueType(condition)
        : getOptionValue(CUSTOM_FIELD_TYPE_CONDITION_MAP[type]);

      if (preparedValue) {
        return CONDITION_COMPONENT_MAP[preparedValue as CustomConditionType](
          id,
          value,
          listOptions
        );
      }
    }

    return (
      <Select
        label="Значение"
        options={[]}
        mobileModalTitle="значение"
        disabled
      />
    );
  };

  const attributesRows = attributes.map(
    ({ id, attribute, isActive, required, value }, index) => {
      const attributesValueOptions = getAttributesValueOptions({
        attributesFields,
        attribute,
      });

      const prevIndex = index === 0 ? null : index - 1;
      const prevAttributes = isNumber(prevIndex) && attributes[prevIndex];
      const prevAttributeValueIsEmpty =
        prevAttributes && isEmpty(prevAttributes.value);

      const attributeValue = getValueFromValueType(attribute) || '';

      const attributeComponent = required ? (
        <Input
          label="Атрибут"
          value={getTitleFromValueType(attribute)}
          className={styles.triggerModalContent__attribute}
          disabled
        />
      ) : (
        <Select
          label="Атрибут"
          value={attribute}
          onChange={onChangeAttribute(id)}
          options={attributesOptions}
          mobileModalTitle="атрибут"
          className={styles.triggerModalContent__attribute}
          isTooltip={false}
        />
      );

      return (
        (required || isActive) && (
          <div key={id} className={styles.triggerModalContent__row}>
            {attributeComponent}
            <Select
              label="Значение"
              value={value}
              onChange={onChangeAttributeValue(id, attributeValue)}
              options={attributesValueOptions}
              mobileModalTitle="значение"
              disabled={!attribute || prevAttributeValueIsEmpty}
              isMulti={!required}
              isTooltip={false}
            />
            <button
              onClick={
                required
                  ? (event) => onChangeRequiredAttributeHandler(event, id)
                  : onDeleteAttributeHandler(id)
              }
              className={styles.triggerModalContent__closeButton}
            >
              <CloseIcon
                className={styles.triggerModalContent__closeButtonIcon}
              />
            </button>
          </div>
        )
      );
    }
  );

  const customConditionsRows = customConditions?.map(
    ({ id, condition, isActive, customField, value, type }) => {
      const isDate = type === CustomFieldType.FIELD_DATE;
      const conditionOptions = isDate ? SELECT_DATE_CONDITION_LIST : [];
      const listOptions = getListOptions(type, customField?.values);

      const conditionComponent = getConditionComponent(
        id,
        value,
        type,
        condition,
        listOptions
      );

      return (
        isActive && (
          <div key={id} className={styles.triggerModalContent__customFieldRow}>
            <div className={styles.triggerModalContent__customWrapper}>
              <Select
                label="Название кастомного поля"
                options={customFieldsOptions}
                onChange={onChangeCustomField(id)}
                value={customField}
              />
              <div className={styles.triggerModalContent__container}>
                <Select
                  label="Условие"
                  onChange={onChangeCustomCondition(id)}
                  options={conditionOptions}
                  value={condition}
                  mobileModalTitle="условие"
                  className={styles.triggerModalContent__customConditions}
                />
                {conditionComponent}
              </div>
            </div>
            <button
              onClick={onDeleteCustomConditionHandler(id)}
              className={styles.triggerModalContent__closeButton}
            >
              <CloseIcon
                className={styles.triggerModalContent__closeButtonIcon}
              />
            </button>
          </div>
        )
      );
    }
  );

  const triggerConditionRows = triggerConditions?.map(
    ({ id, condition, isActive, value }) =>
      isActive && (
        <div key={id} className={styles.triggerModalContent__row}>
          <Select
            label="Условие"
            onChange={onChangeTriggerCondition(id)}
            options={triggerConditionOptions}
            value={condition}
            mobileModalTitle="условие"
            className={styles.triggerModalContent__triggerCondition}
          />
          <Input
            label="Значение"
            onChange={(event) => onChangeTriggerConditionValue(event, id)}
            disabled={!getValueFromValueType(condition)}
            mask="99:99"
            defaultValue="00:00"
            value={value}
          />
          <button
            onClick={onDeleteTriggerConditionHandler(id)}
            className={styles.triggerModalContent__closeButton}
          >
            <CloseIcon
              className={styles.triggerModalContent__closeButtonIcon}
            />
          </button>
        </div>
      )
  );

  const warningAlert = alertIsOpen && (
    <AlertItem
      alert={{
        id: nanoid(),
        type: AlertTypes.WARNING,
        message: `Внимание! Убедитесь, что созданный Вами триггер не приведет к
        цикличной смене статусов! При возникновении данной проблемы
        необходимо отключить триггер или перевести тикет в другой статус
        вручную.`,
      }}
      className={styles.triggerModalContent__alert}
      classNameText={styles.triggerModalContent__alertText}
      onClose={onCloseAlert}
    />
  );

  return (
    <form className={styles.triggerModalContent}>
      {warningAlert}
      <div>
        <Input
          {...titleInputOptions}
          label="Название"
          error={!!errors.title}
          errorMessage={errors.title?.message}
          className={styles.triggerModalContent__titleInput}
        />
        <Typography
          variant={TypographyVariants.h4}
          className={styles.triggerModalContent__subTitle}
        >
          Если тикет имеет следующие атрибуты
        </Typography>

        {attributesRows}

        <Button
          type="button"
          onClick={onAddAttributeHandler}
          icon={<PlusIcon />}
          appearance="flat"
          disabled={isDisabledAttribute}
          size={Size.xs}
        >
          Добавить атрибут
        </Button>
        <Typography
          className={styles.triggerModalContent__separator}
          variant={TypographyVariants.b2}
        >
          И
        </Typography>
        <div className={styles.triggerModalContent__customFieldRows}>
          {customConditionsRows}
        </div>
        <Button
          type="button"
          onClick={onAddCustomConditionHandler}
          icon={<PlusIcon />}
          appearance="flat"
          disabled={isDisabledCustomCondition}
          size={Size.xs}
          className={styles.triggerModalContent__customBtn}
        >
          Добавить кастомные условия
        </Button>
        <div className={styles.triggerModalContent__separator}>И</div>
        <Typography
          variant={TypographyVariants.h4}
          className={styles.triggerModalContent__subTitle}
        >
          Если
        </Typography>
        {triggerConditionRows}
        <Button
          type="button"
          onClick={onAddTriggerConditionHandler}
          icon={<PlusIcon />}
          appearance="flat"
          disabled={isDisabledTriggerCondition}
          size={Size.xs}
          className={styles.triggerModalContent__customBtn}
        >
          Добавить условие
        </Button>
        <Typography
          variant={TypographyVariants.h4}
          className={styles.triggerModalContent__subTitle}
        >
          Тогда
        </Typography>
        <div className={styles.triggerModalContent__row}>
          <Input label="Атрибут" value="Статус" disabled />
        </div>
        <Typography
          className={cn(
            styles.triggerModalContent__subTitle,
            styles.triggerModalContent__subTitleBecome
          )}
        >
          Становится
        </Typography>
        <Controller
          control={control}
          name="targetStatus"
          key="targetStatus"
          rules={{
            required: true,
          }}
          render={({ field }) => (
            <Select
              label="Значение"
              value={field.value}
              onChange={field.onChange}
              options={nextTicketStatusOptions}
              mobileModalTitle="значение"
              disabled={isDisabledTargetStatus}
            />
          )}
        />
      </div>
      <div className={styles.triggerModalContent__footer}>
        <div data-tip data-for="submit-button">
          <Button
            disabled={isDisabledSubmit}
            className={styles.triggerModalContent__button}
            onClick={formSubmitHandler}
          >
            Создать
          </Button>
        </div>
        {isDisabledSubmit && (
          <Tooltip id="submit-button" place="right">
            Остались незаполненные поля
          </Tooltip>
        )}
        <Button
          type="button"
          onClick={onReset}
          appearance="flat"
          className={styles.triggerModalContent__button}
        >
          Отмена
        </Button>
      </div>
    </form>
  );
};
