import { FC } from 'react';

import styles from './DeleteOrganizationTitle.module.scss';

export const DeleteOrganizationTitle: FC = () => (
  <>
    <p className={styles.deleteOrganizationTitle}>
      В случае удаления организации из системы также будут удалены все связанные
      с ней сущности в том числе пользователи, контракты, системы и т.д.
    </p>
    <p>Вы уверены, что хотите удалить организацию?</p>
  </>
);
