import { CreateUserData, User } from '@entities/user';

export const getPreparedUserValues = (user: User): CreateUserData => {
  const {
    id,
    firstName,
    lastName,
    middleName,
    description,
    login,
    email,
    phoneNumber,
    requireUpdatePassword,
  } = user;
  return {
    id,
    firstName,
    lastName,
    middleName,
    description,
    login,
    email,
    phoneNumber,
    requireUpdatePassword,
  };
};
