import { FC } from 'react';
import { Controller } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';

import { Storage } from '@entities/storage';
import {
  fetchOrganizationsForCreateRequest,
  fetchWorkGroupsEditorsRequest,
  fetchWorkGroupsViewersRequest,
  getPropsOrganization,
  PanelType,
  resetOrganizationsForCreate,
  resetWorkGroupsEditorsCreate,
  resetWorkGroupsViewersCreate,
  setSizeWorkGroupsEditorsPage,
  setSizeWorkGroupsViewersPage,
} from '@entities/storages';
import {
  BackArrowIcon,
  Button,
  CloseIcon,
  Drawer,
  IconButton,
  Input,
  MinusIcon,
  PlusIcon,
  RadioSlide,
  SearchSelect,
  SelectOption,
  Size,
  TagNew,
  Typography,
  TypographyVariants,
} from '@shared';

import { SLIDE_RADIO_TABS } from '../../config';
import { useStorageCreate } from '../../lib';

import styles from './StorageCreate.module.scss';

interface StorageCreateProps {
  isOpen: boolean;
  panelType: PanelType;
  toggleCreatePanel: () => void;
  handleChangeHeaderOrganization: (organizations: SelectOption[]) => void;
  selectedStorage?: Storage;
}
// TODO подумать над разбитием
export const StorageCreate: FC<StorageCreateProps> = ({
  isOpen,
  panelType,
  toggleCreatePanel,
  handleChangeHeaderOrganization,
  selectedStorage,
}) => {
  const {
    state: {
      organizationsOptions,
      workGroupsViewersOptions,
      workGroupsEditorsOptions,
      isCancelModal,
      addAttributeIsOpen,
      titleInput,
      descriptionInput,
      attributeTitle,
      attributeDescription,
      isValid,
      errors,
      size,
      accessDisabled,
      propsViewersGroups,
      propsEditorsGroups,
      organizationIdsEditors,
      organizationIdsViewers,
      addAttributeDisabled,
      attributes,
    },
    methods: {
      onSubmit,
      toggleCancelModal,
      toggleAddAttribute,
      handleCloseAddAttribute,
      onApproveCancelModal,
      control,
      resetField,
      handlePlusIcon,
      handleMinusIcon,
      handleAddAttribute,
      handleDeleteAttribute,
    },
  } = useStorageCreate({
    toggleCreatePanel,
    handleChangeHeaderOrganization,
    selectedStorage,
    panelType,
  });
  const dispatch = useDispatch();
  const { create } = panelType;

  const currentTitle = create ? 'Создать хранилище' : 'Редактировать хранилище';
  const currentSubmitTitle = create ? 'Создать' : 'Сохранить';

  const inputs = (
    <div className={styles.storageCreate__inputsContainer}>
      <Controller
        control={control}
        name="title"
        render={({ field }) => (
          <Input
            {...titleInput}
            label="Название"
            value={field.value}
            onChange={field.onChange}
            error={!!errors.title}
            errorMessage={errors.title?.message}
          />
        )}
      />
      <Controller
        control={control}
        name="description"
        render={({ field }) => (
          <Input
            {...descriptionInput}
            label="Описание"
            value={field.value}
            onChange={field.onChange}
            error={!!errors.description}
            errorMessage={errors.description?.message}
          />
        )}
      />
      <Controller
        control={control}
        name="organizations"
        render={({ field }) => (
          <SearchSelect
            size={Size.m}
            key="organizations"
            label="Организация"
            value={field.value}
            options={organizationsOptions}
            dataPagination={useSelector(getPropsOrganization)}
            resetState={resetOrganizationsForCreate}
            fetchRequest={fetchOrganizationsForCreateRequest}
            onChange={(value) => {
              resetField('editorsGroups');
              resetField('viewersGroups');
              dispatch(setSizeWorkGroupsViewersPage(0));
              dispatch(setSizeWorkGroupsEditorsPage(0));
              field.onChange(value);
            }}
          />
        )}
      />
    </div>
  );

  const sizes = (
    <div className={styles.storageCreate__sizeContainer}>
      <div className={styles.storageCreate__sizeTitleContainer}>
        <Typography
          variant={TypographyVariants.b2}
          className={styles.storageCreate__sizeTitle}
        >
          Объем
        </Typography>
      </div>
      <div className={styles.storageCreate__sizeToggle}>
        <IconButton
          onClick={handleMinusIcon}
          appearance="outline"
          icon={<MinusIcon />}
        />
        <Typography variant={TypographyVariants.d2}>{size} гб</Typography>
        <IconButton
          onClick={handlePlusIcon}
          appearance="outline"
          icon={<PlusIcon />}
        />
      </div>
      <div className={styles.storageCreate__sizeRange}>
        <Controller
          control={control}
          name="size"
          render={({ field }) => (
            <input
              className={styles.storageCreate__sizeInput}
              type="range"
              value={field.value}
              onChange={field.onChange}
              min={1}
              max={50}
            />
          )}
        />
        <div className={styles.storageCreate__sizeMaxMinContainer}>
          <Typography
            className={styles.storageCreate__sizeMaxMin}
            variant={TypographyVariants.b3}
          >
            1 гб
          </Typography>
          <Typography
            className={styles.storageCreate__sizeMaxMin}
            variant={TypographyVariants.b3}
          >
            50 гб
          </Typography>
        </div>
      </div>
    </div>
  );

  const access = (
    <>
      <Typography variant={TypographyVariants.h4}>Доступ</Typography>
      <Controller
        control={control}
        name="viewersGroups"
        render={({ field }) => (
          <SearchSelect
            size={Size.m}
            key="viewersGroups"
            label="Просмотр файлов"
            value={field.value}
            options={workGroupsViewersOptions}
            dataPagination={propsViewersGroups}
            resetState={resetWorkGroupsViewersCreate}
            fetchRequest={fetchWorkGroupsViewersRequest}
            onChange={field.onChange}
            disabled={accessDisabled}
            triggerFetchField="organizationIds"
            isChip
            filter={{ organizationIds: organizationIdsViewers }}
          />
        )}
      />
      <Controller
        control={control}
        name="editorsGroups"
        render={({ field }) => (
          <SearchSelect
            size={Size.m}
            key="editorsGroups"
            label="Загрузка файлов"
            value={field.value}
            options={workGroupsEditorsOptions}
            dataPagination={propsEditorsGroups}
            resetState={resetWorkGroupsEditorsCreate}
            fetchRequest={fetchWorkGroupsEditorsRequest}
            onChange={field.onChange}
            disabled={accessDisabled}
            triggerFetchField="organizationIds"
            isChip
            filter={{ organizationIds: organizationIdsEditors }}
          />
        )}
      />
    </>
  );

  const header = (
    <div className={styles.storageCreate__header}>
      <Typography variant={TypographyVariants.h3}>{currentTitle}</Typography>
      <IconButton
        appearance="flat"
        onClick={toggleCancelModal}
        icon={<CloseIcon className={styles.storageCreate__headerCloseIcon} />}
      />
    </div>
  );

  const attributesComponent = (
    <div className={styles.storageCreate__attributes}>
      <div className={styles.storageCreate__attributesTitleContainer}>
        <Typography variant={TypographyVariants.h4}>
          Атрибуты загружаемых файлов
        </Typography>
        <Button
          type="button"
          onClick={toggleAddAttribute}
          icon={<PlusIcon />}
          appearance="flat"
          size={Size.xs}
        >
          Добавить
        </Button>
      </div>
      <div className={styles.storageCreate__attributesTags}>
        {attributes.map(({ id, title, required }) => {
          const onDelete = !required ? handleDeleteAttribute(title) : undefined;
          const colorScheme = required ? 'grey' : 'purple';

          return (
            <TagNew
              key={id || title}
              colorScheme={colorScheme}
              onDelete={onDelete}
            >
              {title}
            </TagNew>
          );
        })}
      </div>
    </div>
  );
  // TODO зарефачить BackButton
  const goBackButton = (
    <button
      onClick={handleCloseAddAttribute}
      className={styles.storageCreate__arrowButton}
    >
      <BackArrowIcon className={styles.storageCreate__arrowButtonIcon} />
      <Typography variant={TypographyVariants.h5}>Назад</Typography>
    </button>
  );

  const addAttribute = (
    <div className={styles.storageCreate__addAttribute}>
      <div>
        <Typography variant={TypographyVariants.h4}>
          Добавить атрибут
        </Typography>
        {goBackButton}
      </div>
      <Typography
        variant={TypographyVariants.b2}
        className={styles.storageCreate__addAttributeTitle}
      >
        Создайте новый атрибут для загружаемых файлов
      </Typography>

      <Controller
        control={control}
        name="customField.type"
        key="customField.type"
        rules={{ required: addAttributeIsOpen }}
        render={({ field }) => (
          <RadioSlide
            size={Size.s}
            items={SLIDE_RADIO_TABS}
            value={field.value}
            onChange={field.onChange}
          />
        )}
      />
      <Controller
        control={control}
        name="customField.title"
        key="customField.title"
        render={({ field }) => (
          <Input
            {...attributeTitle}
            label="Название атрибута"
            value={field.value}
            onChange={field.onChange}
            error={!!errors.customField?.title}
            errorMessage={errors.customField?.title?.message}
          />
        )}
      />
      <Controller
        control={control}
        name="customField.description"
        key="customField.description"
        render={({ field }) => (
          <Input
            {...attributeDescription}
            label="Описание атрибута"
            value={field.value}
            onChange={field.onChange}
            error={!!errors.customField?.description}
            errorMessage={errors.customField?.description?.message}
          />
        )}
      />
    </div>
  );

  const content = addAttributeIsOpen ? (
    addAttribute
  ) : (
    <>
      {inputs}
      {sizes}
      {access}
      {attributesComponent}
    </>
  );

  const createButtons = (
    <div className={styles.storageCreate__buttons}>
      <Button onClick={onSubmit} disabled={!isValid}>
        {currentSubmitTitle}
      </Button>
      <Button appearance="flat" onClick={toggleCancelModal}>
        Отмена
      </Button>
    </div>
  );

  const addAttributeButtons = (
    <div className={styles.storageCreate__buttons}>
      <Button onClick={handleAddAttribute} disabled={addAttributeDisabled}>
        Добавить
      </Button>
      <Button appearance="flat" onClick={handleCloseAddAttribute}>
        Отмена
      </Button>
    </div>
  );

  const buttons = addAttributeIsOpen ? addAttributeButtons : createButtons;

  return (
    <Drawer
      isOpen={isOpen}
      onClose={toggleCreatePanel}
      approveOrCancelProps={{
        onApprove: onApproveCancelModal,
        isModal: isCancelModal,
        toggleModal: toggleCancelModal,
        text: 'Вы уверены, что хотите отменить создание хранилища?',
      }}
    >
      <div className={styles.storageCreate}>
        <div className={styles.storageCreate__container}>
          {header}
          <div className={styles.storageCreate__content}>{content}</div>
          {buttons}
        </div>
      </div>
    </Drawer>
  );
};
