import { Comment } from '@entities/ticket';

export const getLastMessage = (messageHistory: Comment[]) =>
  [...messageHistory]
    .sort(
      (a, b) =>
        new Date(a?.created || '').valueOf() -
        new Date(b?.created || '').valueOf()
    )
    .slice(messageHistory.length - 1);
