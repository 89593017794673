import cn from 'clsx';
import { ButtonHTMLAttributes, FC, MouseEvent, SVGProps } from 'react';

import { ButtonType, Size } from '@shared/model';

import { Ripple } from '../Ripple';

import styles from './IconButton.module.scss';

const buttonStyles = {
  default: styles.iconButton_default,
  flat: styles.iconButton_flat,
  outline: styles.iconButton_outline,
};

interface IconButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  appearance?: ButtonType;
  size?: Size;
  icon?: SVGProps<SVGElement>;
}

export const IconButton: FC<IconButtonProps> = ({
  appearance = 'default',
  size = Size.xs,
  className,
  icon,
  disabled,
  onMouseDown,
  ...otherProps
}) => {
  const handleClick = (event: MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    if (onMouseDown) {
      onMouseDown(event);
    }
  };

  return (
    <button
      className={cn(
        styles.iconButton,
        buttonStyles[appearance],
        styles[`icon-button_${size}`],
        className
      )}
      onMouseDown={handleClick}
      disabled={disabled}
      {...otherProps}
    >
      {icon}
      {!disabled && <Ripple appearance={appearance} />}
    </button>
  );
};
