import { ProcedureType } from '../model';

export const TICKET_TYPE_TEXT_MAP = {
  [ProcedureType.INCIDENT]: 'Инцидент',
  [ProcedureType.CONSULTATION]: 'Консультация',
};

export const TICKET_TYPE_TEXT_MAP_EN = {
  [ProcedureType.INCIDENT]: 'Incident',
  [ProcedureType.CONSULTATION]: 'Consultation',
};
