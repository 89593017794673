import { FC } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
  addArticlesRequest,
  getSelectedArticlesIds,
} from '@entities/user-assistance';
import { Button } from '@shared';

import styles from './UserAssistanceAddButtons.module.scss';

interface UserAssistanceAddButtonsProps {
  onReset(): void;
  toggleIsAddMode(): void;
}

export const UserAssistanceAddButtons: FC<UserAssistanceAddButtonsProps> = ({
  onReset,
  toggleIsAddMode,
}) => {
  const dispatch = useDispatch();

  const selectedArticlesIds = useSelector(getSelectedArticlesIds);

  const onAdd = () => {
    dispatch(
      addArticlesRequest({
        articlesIds: selectedArticlesIds,
        toggleIsAddMode,
      })
    );
    onReset();
  };

  return (
    <div className={styles.userAssistanceAddButtons}>
      <Button
        onClick={onAdd}
        type="button"
        className={styles.userAssistanceAddButtons__button}
      >
        Добавить
      </Button>
      <Button
        onClick={onReset}
        type="button"
        className={styles.userAssistanceAddButtons__button}
        appearance="flat"
      >
        Отмена
      </Button>
    </div>
  );
};
