import cn from 'clsx';
import { FC, useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';

import { getIsAdmin } from '@entities/auth';
import {
  AccessType,
  CategoryArticle,
  CategoryArticleCreateForm,
  fetchOrganizationsRequestArticleCreate,
  getArticleOrganizationsSelectList,
} from '@entities/knowledge-base';
import {
  CheckMarkIcon,
  CloseIcon,
  getMultiOptionValues,
  getValueFromValueType,
  Input,
  PopoverButton,
  PopoverContainer,
  PopoverItemWrapper,
  PopoverLine,
  PopoverTitle,
  Select,
  Size,
} from '@shared';

import { SELECT_ACCESS_TYPE_LIST } from '../../../../config';

import styles from './KBCreateCategory.module.scss';

interface KBCreateCategoryProps {
  onClick?: (data: Partial<CategoryArticle>) => void;
  togglePopover?: () => void;
}

export const KBCreateCategory: FC<KBCreateCategoryProps> = ({
  onClick,
  togglePopover,
}) => {
  const dispatch = useDispatch();

  const organizationsOptions = useSelector(getArticleOrganizationsSelectList);
  const isAdmin = useSelector(getIsAdmin);

  const {
    handleSubmit,
    control,
    register,
    reset,
    watch,
    resetField,
    unregister,
    setValue,
    formState: { isValid },
  } = useForm<CategoryArticleCreateForm>({
    mode: 'onChange',
  });

  const { accessType } = watch();

  const isPrivate = getValueFromValueType(accessType) === AccessType.PRIVATE;

  const toggleCreatePopover = () => {
    reset();
    if (togglePopover) {
      togglePopover();
    }
  };

  const titleInputOptions = register('title', {
    required: true,
    maxLength: {
      value: 100,
      message: 'Название категории не может быть длиннее 100 символов.',
    },
  });

  const formSubmitHandler = handleSubmit((data) => {
    if (onClick) {
      const { title } = data;

      const prepareData = {
        title,
        accessType: getValueFromValueType(data.accessType),
        organizationIds: getMultiOptionValues(data.organizationIds) || [],
      };
      onClick(prepareData);
    }
    toggleCreatePopover();
  });

  useEffect(() => {
    if (!isAdmin) {
      setValue('accessType', SELECT_ACCESS_TYPE_LIST[1]);
    }
  }, [isAdmin]);

  useEffect(() => {
    dispatch(fetchOrganizationsRequestArticleCreate());
  }, []);

  return (
    <PopoverContainer className={styles.KBCreateCategory}>
      <PopoverTitle title="Создать категорию" />
      <PopoverItemWrapper>
        <Input
          {...titleInputOptions}
          size={Size.xs}
          label="Названиe"
          name="title"
        />
      </PopoverItemWrapper>
      <PopoverItemWrapper>
        <Controller
          control={control}
          name="accessType"
          key="accessType"
          rules={{
            required: true,
          }}
          render={({ field }) => (
            <Select<AccessType>
              size={Size.xs}
              label="Кому отправить"
              mobileModalTitle="кому отправить"
              value={field.value}
              onChange={(value) => {
                field.onChange(value);
                resetField('organizationIds');
                unregister('organizationIds');
              }}
              options={SELECT_ACCESS_TYPE_LIST}
              disabled={!isAdmin}
            />
          )}
        />
      </PopoverItemWrapper>

      {isPrivate && (
        <PopoverItemWrapper>
          <Controller
            control={control}
            name="organizationIds"
            key="organizationIds"
            rules={{
              required: isPrivate,
            }}
            render={({ field }) => (
              <Select<string>
                size={Size.xs}
                onChange={field.onChange}
                value={field.value}
                options={organizationsOptions}
                placeholder="Организация"
                mobileModalTitle="организацию"
                isMulti
              />
            )}
          />
        </PopoverItemWrapper>
      )}

      <PopoverLine />
      <div
        className={cn(
          styles.KBCreateCategory__inner,
          styles.KBCreateCategory__buttons
        )}
      >
        <PopoverButton
          type="submit"
          icon={<CheckMarkIcon className={styles.KBCreateCategory__icon} />}
          onClick={formSubmitHandler}
          disabled={!isValid}
        >
          Создать
        </PopoverButton>
        <PopoverButton
          icon={<CloseIcon className={styles.KBCreateCategory__icon} />}
          onClick={toggleCreatePopover}
        >
          Отмена
        </PopoverButton>
      </div>
    </PopoverContainer>
  );
};
