import { getUrl } from '@shared';

import {
  CheckExistIntegration,
  NextStatusesRequest,
} from '../../model/ticket/types';

export const ticketEndpoints = {
  getTicket: (id: string) => getUrl(`ticket/${id}`),
  getTicketSystem: (id: string) => getUrl(`system/${id}`),
  setTicketToWork: () => getUrl('ticket/work'),
  addSpecialist: () => getUrl('ticket/appoint'),
  getHistory: (ticketId: string) => getUrl(`ticket/${ticketId}/history`),
  getEditTicketPriority: (ticketId: string) =>
    getUrl(`ticket/ticket-priority/${ticketId}`),
  getCurrentTicketActions: () => getUrl('action/action-auth-user'),
  deleteTicket: (id: string) => getUrl(`ticket/${id}`),
  updateDataTicket: () => getUrl('ticket'),
  createTicketInJira: () => getUrl('jira-issue/create'),
  getIsJiraIntegratedWithJira: ({ systemId, typeId }: CheckExistIntegration) =>
    getUrl(`jira-properties/exists/system/${systemId}/type/${typeId}`),
  getJiraUrl: (ticketId: string) =>
    getUrl(`jira-ticket/issue-self/${ticketId}`),
  getCustomFieldsByTicketIdUrl: (ticketId: string) =>
    getUrl(`custom-ticket-field/${ticketId}`),
  updateCustomFieldsByTicketIdUrl: (ticketId: string) =>
    getUrl(`custom-ticket-field/${ticketId}`),
  getNextStatuses: ({
    currentStatusId,
    ticketCreatorId,
  }: NextStatusesRequest) =>
    getUrl(`statuses/${currentStatusId}/client/${ticketCreatorId}/next`),
  getChangeStatus: () => getUrl('comment/status-change'),
  closeTicket: (id?: string) => getUrl(`comment/close-ticket/${id}`),
  restoreTicket: (id?: string) => getUrl(`ticket/restore/${id}`),
  compareWithJiraStatuses: (ticketId: string) =>
    getUrl(`jira-ticket/compareWithJiraStatuses/${ticketId}`),
  getTicketTypes: (systemId: string) =>
    getUrl(`type/system/${systemId}/authUser`),
  updateDataTicketType: (ticketId: string, typeId: string) =>
    getUrl(`ticket/${ticketId}/ticket-type/${typeId}`),
  reopenTicket: (ticketId: string) =>
    getUrl(`comment/reopen-ticket/${ticketId}`),
  getCustomFieldBySystemId: (id: string) => getUrl(`custom-field/system/${id}`),
  getTicketStatuses: () => getUrl('statuses/simple-filter'),
};
