import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
  deleteRuleRequest,
  fetchCurrentRule,
  fetchRulesRequest,
  getCurrentRule,
  getCurrentRuleId,
  getFilterValues,
  getPropsRules,
  getRules,
  setCurrentRuleId,
  setCurrentRulesPage,
  setCurrentRuleSuccess,
  setSizePage,
  setSortRules,
} from '@entities/rules';
import { checkObjectIdentity, getScreenWidth, ScreenWidth } from '@shared';

export const useRulesTableContainer = () => {
  const dispatch = useDispatch();

  const { pageNum, pageSize, sortRules, loadingRules, totalElements } =
    useSelector(getPropsRules);
  const rules = useSelector(getRules);
  const currentRule = useSelector(getCurrentRule);
  const currentRuleId = useSelector(getCurrentRuleId);
  const filterValues = useSelector(getFilterValues);
  const screenWidth = useSelector(getScreenWidth);

  const [isModal, setIsModal] = useState<boolean>(false);
  const [changeModals, setOpenChangeModals] = useState({
    isEditMode: false,
    isOpenModal: false,
  });
  const [showFilterRow, setShowFilterRow] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);

  const isMobile = screenWidth === ScreenWidth.MOBILE;
  const isMobileSmall = screenWidth === ScreenWidth.MOBILE_SMALL;

  const toggleDeleteModal = () => {
    setShowDeleteModal((prev) => !prev);
  };

  const toggleIsOpenChangeModals = () => {
    setOpenChangeModals((prev) => ({
      ...prev,
      isOpenModal: !prev.isOpenModal,
    }));
  };

  const setIsEditModeModal = () => {
    setOpenChangeModals((prev) => ({ ...prev, isEditMode: true }));
  };

  const setIsCreateModeModal = () => {
    setOpenChangeModals((prev) => ({ ...prev, isEditMode: false }));
  };

  const handleCreateClick = () => {
    setIsCreateModeModal();
    toggleIsOpenChangeModals();
  };

  const handleEditClick = () => {
    setIsEditModeModal();
    toggleIsOpenChangeModals();
  };

  const toggleFilterRow = () => {
    setShowFilterRow((prevState) => !prevState);
  };

  const isFilterActive = !checkObjectIdentity(filterValues, {});

  const searchDisabled = !rules?.length || (showFilterRow && isFilterActive);

  const toggleModal = () => {
    setIsModal(!isModal);
    setShowFilterRow(false);
  };

  const handleChangePage = (page: number) => {
    dispatch(setCurrentRulesPage(page));
    dispatch(fetchRulesRequest());
  };

  const handleChangePageSize = (size: number) => {
    dispatch(setSizePage(size));
  };

  const handleSort = (value: string) => {
    dispatch(setSortRules(value));
    dispatch(fetchRulesRequest());
  };

  const handleRowFullTableClick = (value: string) => {
    if (currentRuleId !== value) {
      dispatch(setCurrentRuleId(value));
      dispatch(fetchCurrentRule(value));
      return;
    }
    if (isMobile || isMobileSmall) {
      dispatch(setCurrentRuleId(undefined));
      dispatch(setCurrentRuleSuccess(undefined));
    }
  };

  const handleShowModalDelete = () => {
    if (currentRule?.id) {
      setShowDeleteModal(true);
    }
  };

  const handleDelete = () => {
    if (currentRule?.id) {
      dispatch(deleteRuleRequest(currentRule.id));
    }
  };

  return {
    methods: {
      handleDelete,
      toggleDeleteModal,
      toggleIsOpenChangeModals,
      handleEditClick,
      toggleModal,
      toggleFilterRow,
      handleChangePage,
      handleChangePageSize,
      handleSort,
      handleCreateClick,
      handleShowModalDelete,
      onRowClick: handleRowFullTableClick,
    },
    state: {
      pageNum,
      pageSize,
      sortRules,
      totalElements,
      rules,
      currentRule,
      currentRuleId,
      isModal,
      changeModals,
      filterValues,
      showFilterRow,
      showDeleteModal,
      loadingRules,
      searchDisabled,
    },
  };
};
