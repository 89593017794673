import { createSelector } from 'reselect';

import { CoreState, ScreenWidth } from './types';

export const getScreenWidth = (state: CoreState) => state.core.screenWidth;
export const getRedirectPath = (state: CoreState) => state.core.redirectPath;
export const getTheme = (state: CoreState) => state.core.theme;
export const getSystemThemeSelector = (state: CoreState) =>
  state.core.systemTheme;
export const getContrastTheme = (state: CoreState) => state.core.contrastTheme;
export const getAlerts = (state: CoreState) => state.core.alerts;
export const getBreadCrumbsConfig = (state: CoreState) =>
  state.core.breadCrumbsConfig;
export const getHref = (state: CoreState) => state.core.href;
export const getPrevHref = (state: CoreState) => state.core.prevHref;

export const getIsMobileSmall = createSelector<
  CoreState,
  ScreenWidth | undefined,
  boolean
>(
  [getScreenWidth],
  (screenWidth): boolean => screenWidth === ScreenWidth.MOBILE_SMALL
);

export const getIsMobile = createSelector<
  CoreState,
  ScreenWidth | undefined,
  boolean
>(
  [getScreenWidth],
  (screenWidth): boolean => screenWidth === ScreenWidth.MOBILE
);

export const getIsTabletSmall = createSelector<
  CoreState,
  ScreenWidth | undefined,
  boolean
>(
  [getScreenWidth],
  (screenWidth): boolean => screenWidth === ScreenWidth.TABLET_SMALL
);

export const getIsTablet = createSelector<
  CoreState,
  ScreenWidth | undefined,
  boolean
>(
  [getScreenWidth],
  (screenWidth): boolean => screenWidth === ScreenWidth.TABLET
);

export const getIsDesktopSmall = createSelector<
  CoreState,
  ScreenWidth | undefined,
  boolean
>(
  [getScreenWidth],
  (screenWidth): boolean => screenWidth === ScreenWidth.DESKTOP_SMALL
);

export const getIsDesktop = createSelector<
  CoreState,
  ScreenWidth | undefined,
  boolean
>(
  [getScreenWidth],
  (screenWidth): boolean => screenWidth === ScreenWidth.DESKTOP
);
