import { SelectOption } from '@shared';

import { ConditionType, DestinationType, RuleFieldsType } from '../model/types';

export const CONDITION_MAP = {
  [ConditionType.REACTION_EXPIRE_BEFORE]: 'До просрочки по реакции осталось',
  [ConditionType.REACTION_EXPIRE_EQUAL]: 'Просрочка по реакции составляет',
};

export const INITIAL_ATTRIBUTES_VALUES = {
  organizations: [],
  environments: [],
  systems: [],
  ticketTypes: [],
  ticketPriorities: [],
  clients: [],
};

export const INITIAL_RULES_FILTER = {
  title: '',
  isActive: null,
  ...INITIAL_ATTRIBUTES_VALUES,
};

const ORGANIZATIONS = 'Организация';
const SYSTEMS = 'Система';
const TICKET_TYPES = 'Тип тикета';
const TICKET_PRIORITIES = 'Приоритет';
const ENVIRONMENTS = 'Среда';
const CLIENTS = 'Клиент';

export const SELECT_ATTRIBUTES_LIST: SelectOption[] = [
  { title: ORGANIZATIONS, value: 'organizations' },
  { title: SYSTEMS, value: 'systems' },
  { title: TICKET_TYPES, value: 'ticketTypes' },
  { title: TICKET_PRIORITIES, value: 'ticketPriorities' },
  { title: ENVIRONMENTS, value: 'environments' },
  { title: CLIENTS, value: 'clients' },
];

export const SELECT_CONDITION_LIST: SelectOption<ConditionType>[] = [
  {
    title: 'До просрочки по реакции осталось',
    value: ConditionType.REACTION_EXPIRE_BEFORE,
  },
  {
    title: 'Просрочка по реакции составляет',
    value: ConditionType.REACTION_EXPIRE_EQUAL,
  },
];

export const DEFAULT_VALUES_RULE_FORM = {
  title: '',
  minutes: '00:00',
  isEnable: false,
  executorType: DestinationType.SPECIALIST,
  executor: null,
  conditionType: SELECT_CONDITION_LIST[0],
  ...INITIAL_ATTRIBUTES_VALUES,
};

export const SELECT_ATTRIBUTES_MAP = {
  [RuleFieldsType.organizations]: ORGANIZATIONS,
  [RuleFieldsType.systems]: SYSTEMS,
  [RuleFieldsType.ticketTypes]: TICKET_TYPES,
  [RuleFieldsType.ticketPriorities]: TICKET_PRIORITIES,
  [RuleFieldsType.environments]: ENVIRONMENTS,
  [RuleFieldsType.clients]: CLIENTS,
};
