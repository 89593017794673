import { FC, MouseEvent } from 'react';

import { JiraLogoIcon, PopoverButton, PopoverContainer } from '@shared';

import styles from './TicketInfoDropContent.module.scss';

interface TicketInfoDropContentProps {
  onClick(event?: MouseEvent<HTMLElement>): void;
  disabled: boolean;
}

export const TicketInfoDropContent: FC<TicketInfoDropContentProps> = ({
  onClick,
  disabled,
}) => (
  <PopoverContainer>
    <PopoverButton
      icon={<JiraLogoIcon className={styles.ticketInfoDropContent__icon} />}
      onClick={onClick}
      disabled={disabled}
    >
      Взять в работу и создать задачу в JIRA
    </PopoverButton>
  </PopoverContainer>
);
