import { format } from 'date-fns';

import { convertFromDateFormat } from '@shared';

type CheckValidNotificationExpiry = {
  closingDate: string;
  openDate: string;
  notificationDate: number;
};

export const checkValidNotificationExpiry = ({
  closingDate,
  openDate,
  notificationDate,
}: CheckValidNotificationExpiry) => {
  const dayInMilliseconds = 24 * 60 * 60 * 1000;
  const endDateTime = convertFromDateFormat(closingDate)?.getTime();
  const date = convertFromDateFormat(openDate)?.getTime();
  const today = convertFromDateFormat(
    format(new Date(), 'dd.MM.yyyy')
  )?.getTime();
  const openDateTime = date && today && date > today ? date : today;

  if (openDateTime && endDateTime) {
    return notificationDate * dayInMilliseconds >= endDateTime - openDateTime;
  }
  return false;
};
