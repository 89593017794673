import { Attachment } from '@entities/attachment/model/types';
import {
  Contract,
  ContractDetailPayload,
  ContractIdSystemIdArgs,
} from '@entities/contract/model/types';
import { SLAType } from '@entities/sla/model/types';
import { System } from '@entities/system/model/types';
import { TicketTypeSettings } from '@entities/ticket/model/types';
import { WorkGroup, WorkGroupFilter } from '@entities/work-group/model/types';
import { ResponseWithMeta } from '@shared';

import {
  CreateSupplementaryAgreementSupAgDetailAction,
  DeleteSupplementaryAgreementDetailRequestAction,
  FetchAttachmentsRequestSupAgDetailAction,
  FetchAttachmentsSuccessSupAgDetailAction,
  FetchSlaBySAIdBySystemIdRequestSupAgDetailAction,
  FetchSlaBySAIdBySystemIdSuccessSupAgDetailAction,
  FetchSupplementaryAgreementRequestSupAgDetailAction,
  FetchSupplementaryAgreementSuccessSupAgDetailAction,
  FetchSystemByIdRequestSupAgDetailAction,
  FetchSystemByIdSuccessSupAgDetailAction,
  FetchSystemsBySAIdRequestSupAgDetailAction,
  FetchSystemsBySAIdSuccessSupAgDetailAction,
  FetchTicketTypesRequestSupAgDetailAction,
  FetchTicketTypesSuccessSupAgDetailAction,
  FetchWorkGroupsBySABySystemRequestSupAgDetailAction,
  FetchWorkGroupsBySABySystemSuccessSupAgDetailAction,
  HideAttachmentsLoadingSupAgDetailAction,
  LoadingHideSlaBySAIdBySystemIdRequestSupAgDetailAction,
  LoadingHideSystemByIdSupAgDetailAction,
  LoadingHideWorkGroupsBySABySystemSupAgDetailAction,
  LoadingShowSlaBySAIdBySystemIdRequestSupAgDetailAction,
  LoadingShowSystemByIdSupAgDetailAction,
  LoadingShowWorkGroupsBySABySystemSupAgDetailAction,
  ResetSupplementaryAgreementDetailStateAction,
  SetFilterWorkGroupsBySABySystemSupAgDetailAction,
  SetPageWorkGroupsBySABySystemSupAgDetailAction,
  SetSizePageWorkGroupsBySABySystemSupAgDetailAction,
  SetSortWorkGroupsBySABySystemSupAgDetailAction,
  ShowAttachmentsLoadingSupAgDetailAction,
  SupplementaryAgreementDetail,
} from './actionTypes';
import { CreateSupplementaryAgreementType } from './types';

export const showSupplementaryAgreementLoadingSADetail = () => ({
  type: SupplementaryAgreementDetail.SUPPLEMENTARY_AGREEMENT_LOADING_SHOW,
});

export const hideSupplementaryAgreementLoadingSADetail = () => ({
  type: SupplementaryAgreementDetail.SUPPLEMENTARY_AGREEMENT_LOADING_HIDE,
});

export const fetchSupplementaryAgreementRequestSADetail = (
  id: string
): FetchSupplementaryAgreementRequestSupAgDetailAction => ({
  type: SupplementaryAgreementDetail.FETCH_SUP_AG_REQUEST,
  payload: id,
});

export const fetchSupplementaryAgreementSuccessSADetail = (
  contract: Contract
): FetchSupplementaryAgreementSuccessSupAgDetailAction => ({
  type: SupplementaryAgreementDetail.FETCH_SUP_AG_SUCCESS,
  payload: contract,
});

export const createSupplementaryAgreementSADetail = (
  data: CreateSupplementaryAgreementType
): CreateSupplementaryAgreementSupAgDetailAction => ({
  type: SupplementaryAgreementDetail.CREATE_SUP_AG_DETAIL,
  payload: data,
});
// подгрузка системы для создания допсоглашения (1 система по id)
export const fetchSystemByIdRequestSupAgDetail = (
  id: string
): FetchSystemByIdRequestSupAgDetailAction => ({
  type: SupplementaryAgreementDetail.FETCH_SYSTEM_BY_ID_REQUEST,
  payload: id,
});

export const fetchSystemByIdSuccessSupAgDetail = (
  system: System
): FetchSystemByIdSuccessSupAgDetailAction => ({
  type: SupplementaryAgreementDetail.FETCH_SYSTEM_BY_ID_SUCCESS,
  payload: system,
});

export const loadingShowSystemByIdSADetail =
  (): LoadingShowSystemByIdSupAgDetailAction => ({
    type: SupplementaryAgreementDetail.LOADING_SHOW_SYSTEM_BY_ID,
  });

export const loadingHideSystemByIdSADetail =
  (): LoadingHideSystemByIdSupAgDetailAction => ({
    type: SupplementaryAgreementDetail.LOADING_HIDE_SYSTEM_BY_ID,
  });

// подгрузка систем по id сохраненного допсоглашения (массив систем с размером 1)
export const fetchSystemsBySAIdRequestSADetail = (
  id: string
): FetchSystemsBySAIdRequestSupAgDetailAction => ({
  type: SupplementaryAgreementDetail.FETCH_SYSTEMS_BY_SUP_AG_ID_REQUEST,
  payload: id,
});

export const fetchSystemsBySAIdSuccessSADetail = (
  system: System[]
): FetchSystemsBySAIdSuccessSupAgDetailAction => ({
  type: SupplementaryAgreementDetail.FETCH_SYSTEMS_BY_SUP_AG_ID_SUCCESS,
  payload: system,
});

// удаление допсоглашения
export const deleteSupplementaryAgreementDetailRequest = (
  contract: Contract
): DeleteSupplementaryAgreementDetailRequestAction => ({
  type: SupplementaryAgreementDetail.DELETE_SUP_AG_REQUEST,
  payload: contract,
});

export const resetSupplementaryAgreementDetailState =
  (): ResetSupplementaryAgreementDetailStateAction => ({
    type: SupplementaryAgreementDetail.RESET_SUP_AG_DETAIL_STATE,
  });

// attachments
export const showAttachmentsLoadingSADetail =
  (): ShowAttachmentsLoadingSupAgDetailAction => ({
    type: SupplementaryAgreementDetail.ATTACHMENTS_LOADING_SHOW,
  });

export const hideAttachmentsLoadingSADetail =
  (): HideAttachmentsLoadingSupAgDetailAction => ({
    type: SupplementaryAgreementDetail.ATTACHMENTS_LOADING_HIDE,
  });

export const fetchAttachmentsRequestSupAgDetail = (
  id: string
): FetchAttachmentsRequestSupAgDetailAction => ({
  type: SupplementaryAgreementDetail.FETCH_SUP_AG_ATTACHMENTS_REQUEST,
  payload: id,
});

export const fetchAttachmentsSuccessSupAgDetail = (
  data: Attachment[]
): FetchAttachmentsSuccessSupAgDetailAction => ({
  type: SupplementaryAgreementDetail.FETCH_SUP_AG_ATTACHMENTS_SUCCESS,
  payload: data,
});

// work groups
export const fetchWorkGroupsBySupAgBySystemRequestSupAgDetail = (
  data: ContractDetailPayload
): FetchWorkGroupsBySABySystemRequestSupAgDetailAction => ({
  type: SupplementaryAgreementDetail.FETCH_WORK_GROUPS_BY_SUP_AG_BY_SYSTEM_REQUEST,
  payload: data,
});

export const fetchWorkGroupsBySupAgBySystemSuccessSupAgDetail = (
  groups: ResponseWithMeta<WorkGroup[]>
): FetchWorkGroupsBySABySystemSuccessSupAgDetailAction => ({
  type: SupplementaryAgreementDetail.FETCH_WORK_GROUPS_BY_SUP_AG_BY_SYSTEM_SUCCESS,
  payload: groups,
});

export const loadingShowWorkGroupsBySupAgBySystemSupAgDetail =
  (): LoadingShowWorkGroupsBySABySystemSupAgDetailAction => ({
    type: SupplementaryAgreementDetail.LOADING_SHOW_WORK_GROUPS_BY_SUP_AG_BY_SYSTEM,
  });

export const loadingHideWorkGroupsBySupAgBySystemSupAgDetail =
  (): LoadingHideWorkGroupsBySABySystemSupAgDetailAction => ({
    type: SupplementaryAgreementDetail.LOADING_HIDE_WORK_GROUPS_BY_SUP_AG_BY_SYSTEM,
  });

export const setPageWorkGroupsBySupAgBySystemSupAgDetail = (
  pageNum: number
): SetPageWorkGroupsBySABySystemSupAgDetailAction => ({
  type: SupplementaryAgreementDetail.SET_WORK_GROUPS_PAGE_BY_SUP_AG_BY_SYSTEM,
  payload: pageNum,
});

export const setFilterWorkGroupsBySupAgBySystemSupAgDetail = (
  filter: WorkGroupFilter
): SetFilterWorkGroupsBySABySystemSupAgDetailAction => ({
  type: SupplementaryAgreementDetail.SET_WORK_GROUPS_FILTER_BY_SUP_AG_BY_SYSTEM,
  payload: filter,
});

export const setSizePageWorkGroupsBySupAgBySystemSupAgDetail = (
  pageSize: number
): SetSizePageWorkGroupsBySABySystemSupAgDetailAction => ({
  type: SupplementaryAgreementDetail.SET_SIZE_PAGE_WORK_GROUPS_BY_SUP_AG_BY_SYSTEM,
  payload: pageSize,
});

export const setSortWorkGroupsBySupAgBySystemSupAgDetail = (
  sort: string
): SetSortWorkGroupsBySABySystemSupAgDetailAction => ({
  type: SupplementaryAgreementDetail.SET_WORK_GROUPS_SORT_BY_SUP_AG_BY_SYSTEM,
  payload: sort,
});

export const fetchSlaBySAIdBySystemIdRequestSADetail = (
  data: ContractDetailPayload
): FetchSlaBySAIdBySystemIdRequestSupAgDetailAction => ({
  type: SupplementaryAgreementDetail.FETCH_SLA_BY_CONTRACT_ID_BY_SYSTEM_ID_REQUEST,
  payload: data,
});

export const fetchSlaBySAIdBySystemIdSuccessSADetail = (
  sla: SLAType
): FetchSlaBySAIdBySystemIdSuccessSupAgDetailAction => ({
  type: SupplementaryAgreementDetail.FETCH_SLA_BY_CONTRACT_ID_BY_SYSTEM_ID_SUCCESS,
  payload: sla,
});

export const fetchTicketTypesRequestSADetail = (
  payload: ContractIdSystemIdArgs
): FetchTicketTypesRequestSupAgDetailAction => ({
  type: SupplementaryAgreementDetail.FETCH_TICKET_TYPES_REQUEST,
  payload,
});

export const fetchTicketTypesSuccessSADetail = (
  payload: TicketTypeSettings[]
): FetchTicketTypesSuccessSupAgDetailAction => ({
  type: SupplementaryAgreementDetail.FETCH_TICKET_TYPES_SUCCESS,
  payload,
});

export const loadingShowSlaBySAIdBySystemIdRequestSADetail =
  (): LoadingShowSlaBySAIdBySystemIdRequestSupAgDetailAction => ({
    type: SupplementaryAgreementDetail.LOADING_SHOW_SLA_BY_CONTRACT_ID_BY_SYSTEM_ID_REQUEST,
  });

export const loadingHideSlaBySAIdBySystemIdRequestSADetail =
  (): LoadingHideSlaBySAIdBySystemIdRequestSupAgDetailAction => ({
    type: SupplementaryAgreementDetail.LOADING_HIDE_SLA_BY_CONTRACT_ID_BY_SYSTEM_ID_REQUEST,
  });

export const getNumberForCreateRequestSupAgDetail = (id: string) => ({
  type: SupplementaryAgreementDetail.GET_NUMBER_FOR_CREATE_REQUEST,
  payload: id,
});

export const getNumberForCreateSuccessSupAgDetail = (title: string) => ({
  type: SupplementaryAgreementDetail.GET_NUMBER_FOR_CREATE_SUCCESS,
  payload: title,
});
