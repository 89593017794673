import { SLATableDataType } from '../../../model/types';
import { initialCheckedPriority } from '../config';

export const getInitialCheckedSLA = (tableData?: SLATableDataType) => {
  const result = { ...initialCheckedPriority };
  if (tableData) {
    Object.keys(initialCheckedPriority).forEach((priority) => {
      if (tableData[priority as keyof typeof tableData]) {
        result[priority as keyof typeof result] = true;
        return;
      }
      result[priority as keyof typeof result] = false;
    });
  }
  return result;
};
