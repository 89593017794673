import { useSelector } from 'react-redux';

import {
  ActionList,
  getIsAccessedAction,
  getIsAdmin,
  getIsClients,
  getUserOrganization,
} from '@entities/auth';
import { Organization } from '@entities/organizations';

import { getOrganizationIds } from '../utils';

export const useIsAccessEditCategoryOrArticle = (
  organizations?: Organization[]
) => {
  const isAdmin = useSelector(getIsAdmin);
  const isClients = useSelector(getIsClients);
  const userOrg = useSelector(getUserOrganization);
  const isAccessCreateArticleInClientOrganization = useSelector(
    getIsAccessedAction(ActionList.CreateKBArticleInClientOrganization)
  );
  const isAccessCreateArticleInMyOrganization = useSelector(
    getIsAccessedAction(ActionList.CreateKBArticleInMyOrganization)
  );

  if (isAdmin) {
    return true;
  }

  const orgIds = getOrganizationIds(organizations);
  const isExistOrg = orgIds.includes(userOrg?.id ?? '');
  const accessToUserOrg = !!orgIds.length && isExistOrg;

  const userClientGrantAccess =
    isClients && accessToUserOrg && isAccessCreateArticleInMyOrganization;
  const userAdminAccessForYourOrg =
    !isClients && accessToUserOrg && isAccessCreateArticleInClientOrganization;

  return userClientGrantAccess || userAdminAccessForYourOrg;
};
