import { ChangeEvent, FC } from 'react';
import { LinkProps } from 'react-router-dom';

import { Nullable } from '@shared/model';

import { ConditionBlockProps } from '../../ConditionBlock';
import { IconBlockProps } from '../../IconBlock';

export enum RenderTypes {
  NOTIFICATION_LINK = 'NOTIFICATION_LINK',
  CONDITION = 'CONDITION',
  ICON_BLOCK = 'ICON_BLOCK',
}

export type CellContent = string | JSX.Element;

export type TableColumn = {
  title: CellContent;
  fieldName: string;
  renderType?: RenderTypes;
  sortField?: string;
};

type List<T> = Array<T>;

export type TableColumns = List<TableColumn>;

export enum Sort {
  DESC = 'DESC',
  ASC = 'ASC',
}

type DataTypes =
  | CellContent
  | LinkProps
  | ConditionBlockProps
  | FC<IconBlockProps>
  | boolean
  | undefined;

export type ObjDataType = Record<string, DataTypes>;

export type CheckboxProps = {
  withCheckbox?: boolean;
  onChangeCheckbox?: (event: ChangeEvent<HTMLInputElement>) => void;
  onSelectAllCheckbox?: (event: ChangeEvent<HTMLInputElement>) => void;
};

export type TableCustomField = Nullable<string | boolean | string[]>;
