import { call, put, select, StrictEffect, takeEvery } from 'redux-saga/effects';

import { getCurrentResponsibilityId } from '@entities/responsibilities/model/selectors';
import { createError, ResponseWithMeta } from '@shared';
import { fetchResponsibilitiesRequest } from 'entities/responsibilities/model/actions';

import { requests } from '../api';

import {
  createAccessCategory,
  fetchAccessesSuccess,
  hideAccessesLoading,
  showAccessesLoading,
} from './actions';
import { AccessesCategories } from './actionTypes';
import { getAccessesFilter, getPropsAccesses } from './selectors';
import { Access } from './types';

function* accessesFetchByRespId() {
  try {
    const {
      pageNum,
      pageSize,
      sortAccesses,
    }: ReturnType<typeof getPropsAccesses> = yield select(getPropsAccesses);
    const filter: ReturnType<typeof getAccessesFilter> = yield select(
      getAccessesFilter
    );
    const respId: ReturnType<typeof getCurrentResponsibilityId> = yield select(
      getCurrentResponsibilityId
    );
    if (respId) {
      yield put(showAccessesLoading());
      const accesses: ResponseWithMeta<Access[]> = yield call(
        requests.fetchAccessesByRespId,
        respId,
        pageNum,
        pageSize,
        sortAccesses,
        filter
      );
      yield put(fetchAccessesSuccess(accesses));
    }
    yield put(hideAccessesLoading());
  } catch (e) {
    createError(e);
    yield put(hideAccessesLoading());
  }
}

function* accessCreate({ payload }: ReturnType<typeof createAccessCategory>) {
  try {
    yield put(showAccessesLoading());
    yield call(requests.createAccessCategory, payload);
    yield put(hideAccessesLoading());
    yield call(fetchResponsibilitiesRequest);
  } catch (e) {
    createError(e);
    yield put(hideAccessesLoading());
  }
}

export function* accessesSaga(): Generator<StrictEffect> {
  yield takeEvery(
    AccessesCategories.FETCH_ACCESS_BY_RESP_ID_REQUEST,
    accessesFetchByRespId
  );
  yield takeEvery(AccessesCategories.ACCESS_CATEGORY_CREATE, accessCreate);
}
