import {
  checkPathnameWithTrailingSlash,
  RouterHref,
  TicketsTabType,
} from '@shared';

export const getCurrentTableType = (pathname: string) => {
  if (checkPathnameWithTrailingSlash(pathname, RouterHref.Tickets)) {
    return TicketsTabType.MY_TICKETS;
  }
  if (checkPathnameWithTrailingSlash(pathname, RouterHref.TicketsAll)) {
    return TicketsTabType.ALL_TICKETS;
  }
  return TicketsTabType.MY_GROUPS_TICKETS;
};
