import { ResponsibilitiesState } from './types';

export const getResponsibilities = (state: ResponsibilitiesState) =>
  state.responsibilities.responsibilities;
export const getLoading = (state: ResponsibilitiesState): boolean | undefined =>
  state.responsibilities.loading;
export const getLoadingAdd = (
  state: ResponsibilitiesState
): boolean | undefined => state.responsibilities.loadingAdd;

export const getPropsResponsibilities = (state: ResponsibilitiesState) => ({
  pageNum: state.responsibilities.pagination?.pageNum,
  pageSize: state.responsibilities.pagination?.pageSize,
  sortResponsibilities: state?.responsibilities.sort,
  loadingResponsibilities: getLoading(state),
  totalElements: state.responsibilities.pagination?.totalElements,
});

export const getPropsResponsibilitiesAdd = (state: ResponsibilitiesState) => ({
  pageNum: state.responsibilities.paginationAdd?.pageNum,
  pageSize: state.responsibilities.paginationAdd?.pageSize,
  totalElements: state.responsibilities.paginationAdd?.totalElements,
  loadingRespAdd: state.responsibilities.loadingAdd,
});

export const getCurrentResponsibilityId = (state: ResponsibilitiesState) =>
  state.responsibilities.responsibilityId;
export const getResponsibilitiesAdd = (state: ResponsibilitiesState) =>
  state.responsibilities.responsibilitiesAdd;
export const getResponsibilitiesAddFilter = (state: ResponsibilitiesState) =>
  state.responsibilities.responsibilitiesAddFilter || {};
export const getResponsibilitiesFilter = (state: ResponsibilitiesState) =>
  state.responsibilities.responsibilitiesFilter || {};
export const getCurrentResponsibility = (state: ResponsibilitiesState) =>
  state.responsibilities.currentResponsibility;
