import { FC } from 'react';

import { TableJiraIntegrationsTypes } from '@entities/jira-integrations';
import { entityNames, TableContainer } from '@shared';

import { PAGINATION_RANGE_MAP } from '../../config';
import { useJiraIntegrationsTableContainer } from '../../lib';
import { JiraIntegrationCreate } from '../JiraIntegrationCreate';
import { JiraIntegrationsDropContent } from '../JiraIntegrationsDropContent';
import { JiraIntegrationsFullTable } from '../JiraIntegrationsFullTable';

import styles from './JiraIntegrationsTableContainer.module.scss';

interface JiraIntegrationsTableContainerProps {
  tableType: TableJiraIntegrationsTypes;
}

export const JiraIntegrationsTableContainer: FC<
  JiraIntegrationsTableContainerProps
> = ({ tableType }) => {
  const { methods, state } = useJiraIntegrationsTableContainer();

  const {
    toggleFilterRow,
    handleChangePage,
    handleChangePageSize,
    handleSort,
    toggleIsOpenCreateModal,
    handleClickRow,
    toggleDeleteModal,
    handleDelete,
    handleShowModalDelete,
  } = methods;

  const {
    showFilterRow,
    isFilterActive,
    searchDisabled,
    pageNum,
    pageSize,
    sort,
    totalElements,
    jiraIntegrations,
    isOpenCreateModal,
    currentJiraIntegrationId,
    jiraIntegration,
    showDeleteModal,
    loading,
  } = state;

  const getTableComponent: Record<
    TableJiraIntegrationsTypes,
    () => JSX.Element
  > = {
    [TableJiraIntegrationsTypes.FULL]: () => (
      <JiraIntegrationsFullTable
        jiraIntegrations={jiraIntegrations}
        handleSort={handleSort}
        sort={sort}
        showFilterRow={showFilterRow}
        isFilterActive={isFilterActive}
        activeRowId={currentJiraIntegrationId}
        handleClickRow={handleClickRow}
      />
    ),
  };

  const getContent = () => getTableComponent[tableType]();

  const createJiraIntegrationModal = isOpenCreateModal && (
    <JiraIntegrationCreate
      isModal={isOpenCreateModal}
      toggleModal={toggleIsOpenCreateModal}
    />
  );

  return (
    <TableContainer
      classNameRoot={styles.jiraIntegrationsTableContainer}
      headerProps={{
        title: 'Интеграция с Jira',
        searchDisabled,
        toggleSearch: toggleFilterRow,
        isSearchOpen: showFilterRow,
        createTitle: 'Создать',
        createHandler: toggleIsOpenCreateModal,
        dropContent: (
          <JiraIntegrationsDropContent handleDelete={handleShowModalDelete} />
        ),
        dropDisabled: !currentJiraIntegrationId,
        loading,
      }}
      contentTable={
        <>
          {getContent()}
          {createJiraIntegrationModal}
        </>
      }
      countRecordsProps={{
        records: totalElements,
      }}
      paginationProps={{
        pageNum,
        pageSize,
        marginPagesDisplayed: PAGINATION_RANGE_MAP[tableType],
        elementsCount: totalElements,
        handleChangePage,
        handleChangePageSize,
        disabled: loading,
        entityName: entityNames.JIRA_INTEGRATION,
      }}
      approveOrCancelProps={{
        onApprove: handleDelete,
        isModal: showDeleteModal,
        toggleModal: toggleDeleteModal,
        approveTitle: 'Удалить',
        approveBtnProps: {
          appearance: 'outline',
          className: styles.jiraIntegrationsTableContainer__iconDelete,
        },
        cancelTitle: 'Отмена',
        text: `Вы уверены, что хотите удалить интеграцию ${jiraIntegration?.name}?`,
      }}
    />
  );
};
