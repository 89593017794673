import { FC } from 'react';
import { Controller } from 'react-hook-form';
import { v4 as uuidv4 } from 'uuid';

import { OrganizationSelect } from '@entities/organizations';
import {
  ApproveOrCancel,
  BottomButtonsBlock,
  Button,
  CheckMarkIcon,
  CloseIcon,
  IconButtonNew,
  InfoIcon,
  Input,
  PlusIcon,
  Size,
  TextArea,
  Typography,
  TypographyVariants,
} from '@shared';

import { ENVIRONMENT } from '../../config';
import { useSystemForm } from '../../lib';

import styles from './SystemForm.module.scss';

type ButtonArgs = {
  onClick: (index: number) => () => void;
  index: number;
  disabled?: boolean;
};

const getCreateButton = (disabled: boolean, onClick: () => void) => (
  <IconButtonNew
    className={styles.systemForm__button}
    size="m"
    variety="success"
    appearance="outline"
    onClick={onClick}
    disabled={disabled}
    icon={<CheckMarkIcon className={styles.systemForm__button_create} />}
  />
);

const getCloseButton = ({ onClick, index }: ButtonArgs) => (
  <IconButtonNew
    className={styles.systemForm__button}
    size="m"
    variety="danger"
    appearance="outline"
    onClick={onClick(index)}
    icon={<CloseIcon className={styles.systemForm__button_close} />}
  />
);

const getDeleteButton = ({ onClick, index, disabled }: ButtonArgs) => (
  <IconButtonNew
    className={styles.systemForm__button}
    size="m"
    variety="secondary"
    appearance="outline"
    onClick={onClick(index)}
    disabled={disabled}
    icon={<CloseIcon className={styles.systemForm__button_delete} />}
  />
);

export const SystemForm: FC = () => {
  const {
    methods: {
      toggleModal,
      onApproveModal,
      formSubmitHandler,
      ticketTypeCreate,
      environmentCreate,
      ticketTypeUpdate,
      environmentUpdate,
      handleDeleteTicketType,
      handleDeleteEnvironment,
    },
    state: {
      control,
      errors,
      isModal,
      mainLayoutSticky,
      isTicketTypeCreate,
      isEnvironmentCreate,
      isEditMode,
      canEditForm,
      fieldValues,
      ticketTypeFieldArray,
      environmentFieldArray,
      disabledAddTicketType,
      disabledAddEnvironment,
      canSave,
      titleInputOptions,
      versionInputOptions,
      indexInputOptions,
      descriptionTextAreaOptions,
    },
  } = useSystemForm();

  const ticketTypeInputList = ticketTypeFieldArray.fields?.map(
    (ticketType, index) => (
      <div className={styles.systemForm__environments} key={ticketType?.id}>
        <Controller
          control={control}
          key={ticketType.id}
          name={`ticketType.${index}.title`}
          rules={{
            validate: (value) => {
              if (isTicketTypeCreate) {
                const exist = ticketTypeFieldArray.fields.some(
                  (item) => item.title === value
                );

                return exist ? 'Название уже используется' : true;
              }

              return true;
            },
          }}
          render={({ field, fieldState }) => (
            <>
              <Input
                key={ticketType.id}
                label="Тип тикета"
                value={field.value}
                onChange={field.onChange}
                error={!!fieldState.error?.message?.length}
                errorMessage={fieldState.error?.message}
                readOnly={!!ticketType.title}
              />
              {!ticketType.value && !ticketType.title && isTicketTypeCreate && (
                <div className={styles.systemForm__buttonContainer}>
                  {getCreateButton(
                    disabledAddTicketType,
                    ticketTypeUpdate({
                      id: uuidv4(),
                      index,
                      value: field.value,
                    })
                  )}
                  {getCloseButton({
                    onClick: handleDeleteTicketType,
                    index,
                  })}
                </div>
              )}
            </>
          )}
        />
        {!!ticketType.title &&
          getDeleteButton({
            onClick: handleDeleteTicketType,
            index,
            disabled: isTicketTypeCreate,
          })}
      </div>
    )
  );

  const environmentInputList = environmentFieldArray.fields?.map(
    (environment, index) => {
      const isEnvironmentMain = ENVIRONMENT.includes(environment?.name || '');

      return (
        <div className={styles.systemForm__environments} key={environment?.id}>
          <Controller
            control={control}
            key={environment.id}
            name={`environment.${index}.name`}
            rules={{
              validate: (value) => {
                if (isEnvironmentCreate) {
                  const exist =
                    ENVIRONMENT.includes(value) ||
                    environmentFieldArray.fields.some(
                      (item) => item.name === value
                    );

                  return exist ? 'Название уже используется' : true;
                }

                return true;
              },
            }}
            render={({ field, fieldState }) => (
              <>
                <Input
                  key={environment.id}
                  label="Среда"
                  value={field.value}
                  onChange={field.onChange}
                  readOnly={!!environment.name}
                  error={!!fieldState.error?.message?.length}
                  errorMessage={fieldState.error?.message}
                />
                {!environment.name && isEnvironmentCreate && (
                  <div className={styles.systemForm__buttonContainer}>
                    {getCreateButton(
                      disabledAddEnvironment,
                      environmentUpdate({
                        id: uuidv4(),
                        index,
                        value: field.value,
                      })
                    )}
                    {getCloseButton({
                      onClick: handleDeleteEnvironment,
                      index,
                    })}
                  </div>
                )}
              </>
            )}
          />
          {!!environment.name &&
            !isEnvironmentMain &&
            getDeleteButton({
              onClick: handleDeleteEnvironment,
              index,
              disabled: isEnvironmentCreate,
            })}
        </div>
      );
    }
  );

  return (
    <form className={styles.systemForm}>
      <div className={styles.systemForm__card}>
        <div className={styles.systemForm__header}>
          <Typography variant={TypographyVariants.h4}>Информация</Typography>
        </div>
        <div className={styles.systemForm__content}>
          <Input
            {...titleInputOptions}
            label="Название"
            error={!!errors.system?.title}
            errorMessage={errors.system?.title?.message}
          />
          <Input
            {...versionInputOptions}
            label="Версия"
            error={!!errors.system?.versionTitle}
            errorMessage={errors.system?.versionTitle?.message}
          />
          <div className={styles.systemForm__index}>
            <div className={styles.systemForm__indexContainer}>
              <Input
                {...indexInputOptions}
                label="Индекс"
                error={!!errors.system?.index}
              />
              {fieldValues?.system?.index && (
                <div className={styles.systemForm__indexNotification}>
                  <InfoIcon
                    className={styles.systemForm__indexNotificationIcon}
                  />
                  <div>
                    <Typography
                      className={styles.systemForm__indexNotificationText}
                    >
                      Отображается в номере тикета этой системы:
                    </Typography>
                    <Typography
                      variant={TypographyVariants.h5}
                      className={styles.systemForm__indexNotificationNumber}
                    >
                      Тикет #{fieldValues?.system?.index}-001
                    </Typography>
                  </div>
                </div>
              )}
            </div>
            {errors.system?.index && (
              <span className={styles.systemForm__indexError}>
                {errors.system?.index.message}
              </span>
            )}
          </div>
          <Controller
            control={control}
            name="system.organization"
            rules={{
              required: true,
            }}
            render={({ field }) => (
              <OrganizationSelect
                placeholder="Организация"
                value={field?.value}
                onChange={field.onChange}
                addOrgState={false}
                disabled={isEditMode}
              />
            )}
          />

          <TextArea
            {...descriptionTextAreaOptions}
            label="Описание"
            error={!!errors.system?.description}
            errorMessage={errors.system?.description?.message}
            className={styles.systemForm__textArea}
          />
        </div>
      </div>

      <div className={styles.systemForm__card}>
        <div className={styles.systemForm__header}>
          <Typography variant={TypographyVariants.h4}>Типы тикета</Typography>
        </div>
        <div className={styles.systemForm__content}>
          {ticketTypeInputList}

          <Button
            className={styles.systemForm__button_add}
            appearance="flat"
            disabled={isTicketTypeCreate}
            size={Size.xs}
            onClick={ticketTypeCreate}
            icon={<PlusIcon />}
            type="button"
          >
            Создать новый тип тикета
          </Button>
        </div>
      </div>

      <div className={styles.systemForm__card}>
        <div className={styles.systemForm__header}>
          <Typography variant={TypographyVariants.h4}>Среды</Typography>
        </div>
        <div className={styles.systemForm__content}>
          {environmentInputList}
          <Button
            className={styles.systemForm__button_add}
            appearance="flat"
            disabled={isEnvironmentCreate}
            size={Size.xs}
            onClick={environmentCreate}
            icon={<PlusIcon />}
            type="button"
          >
            Создать кастомную среду
          </Button>
        </div>
      </div>

      {canEditForm && (
        <BottomButtonsBlock
          isOpen={canSave}
          parentNode={mainLayoutSticky}
          onCancel={toggleModal}
          onSave={formSubmitHandler}
          disabledSubmit={!canSave}
        />
      )}

      <ApproveOrCancel
        onApprove={onApproveModal}
        isModal={isModal}
        toggleModal={toggleModal}
        text="Вы уверены, что хотите отменить создание системы?"
      />
    </form>
  );
};
