import cn from 'clsx';
import * as flags from 'country-flag-icons/string/3x2';
import { FC, useCallback, useEffect, useState } from 'react';
import {
  Country,
  getCountries,
  getCountryCallingCode,
} from 'react-phone-number-input/input';
import Locale from 'react-phone-number-input/locale/ru.json';

import { Size } from '@shared/model';

import { OutsideClickHandler } from '../../../OutsideClickHandler';
import { MenuItem } from '../../../Select';
import { Typography } from '../../../Typography';

import styles from './CountrySelect.module.scss';

interface CountrySelectProps {
  className?: string;
  size?: Size;
  selectedCountry?: Country;
  setSelectedCountry: (value: Country) => void;
  labels: Locale;
}

export const CountrySelect: FC<CountrySelectProps> = ({
  className,
  size = Size.m,
  selectedCountry = 'RU',
  setSelectedCountry,
  labels,
}) => {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [inputWidth, setInputWidth] = useState<number>();
  const [countries, setCountries] = useState<Country[]>([]);

  const changeWidth = useCallback(() => {
    const width = document.getElementById('phoneNumber')?.offsetWidth;
    if (width) {
      setInputWidth(width);
    }
  }, [setInputWidth]);

  useEffect(() => {
    const filteredCountries = getCountries().filter(
      (el) => !(el === 'RU' || el === 'BY' || el === 'KZ' || el === 'AM')
    );
    setCountries(['RU', 'BY', 'KZ', 'AM', ...filteredCountries]);
  }, []);

  useEffect(() => {
    changeWidth();
    window.addEventListener('resize', changeWidth);
    return () => window.removeEventListener('resize', changeWidth);
  }, [changeWidth]);

  const toggleDropdown = () => {
    setIsDropdownOpen((prev) => !prev);
  };

  const closeDropdown = () => {
    setIsDropdownOpen(false);
  };

  const selectOption = (option: Country) => {
    setSelectedCountry(option);
    closeDropdown();
  };

  const getCountryCode = (country: Country) => (
    <Typography>{`+${getCountryCallingCode(country)}`}</Typography>
  );

  const getImg = (country: Country) => {
    const src = `data:image/svg+xml;utf8,${encodeURIComponent(flags[country])}`;
    return (
      <img src={src} alt={country} className={styles.countrySelect__img} />
    );
  };

  const countriesList = countries.map((country, index) => (
    <>
      <MenuItem
        className={styles.countrySelect__item}
        key={country}
        onClick={() => selectOption(country)}
        onKeyDown={undefined}
      >
        <div
          className={cn(styles.countrySelect__item, styles.countrySelect__flag)}
        >
          {getImg(country)}
          {getCountryCode(country)}
        </div>
        <Typography className={styles.countrySelect__label}>
          {labels[country]}
        </Typography>
      </MenuItem>
      {index === 3 && <div className={styles.countrySelect__separator} />}
    </>
  ));

  return (
    <OutsideClickHandler onClickOutside={closeDropdown}>
      <div className={cn(styles.countrySelect, className)}>
        <div className={styles.countrySelect__container}>
          <div
            className={styles.countrySelect__toggle}
            onClick={toggleDropdown}
            onKeyDown={undefined}
            role="button"
            tabIndex={0}
          >
            {selectedCountry && (
              <div className={styles.countrySelect_selected}>
                {getImg(selectedCountry)}
                {getCountryCode(selectedCountry)}
              </div>
            )}
            <span
              className={cn(styles.countrySelect__caret, {
                [styles.countrySelect__caret_open]: isDropdownOpen,
              })}
            />
          </div>
          <ul
            className={cn(styles.countrySelect__menu, {
              [styles.countrySelect__menu_open]: isDropdownOpen,
              [styles[`countrySelect__menu_open_${size}`]]: isDropdownOpen,
            })}
            style={{ width: inputWidth }}
            role="menu"
          >
            {countriesList}
          </ul>
        </div>
      </div>
    </OutsideClickHandler>
  );
};
