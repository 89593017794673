import { Organization } from '@entities/organizations';
import { WorkGroup } from '@entities/work-group';

import { Article, ArticleCreateData, CategoryArticle } from '../types';

export enum ArticleCreate {
  CREATE_ARTICLE_REQUEST_ARTICLE_CREATE = 'KB_CREATE_ARTICLE/CREATE_ARTICLE_REQUEST_ARTICLE_CREATE',
  EDIT_ARTICLE_REQUEST_ARTICLE_CREATE = 'KB_CREATE_ARTICLE/EDIT_ARTICLE_REQUEST_ARTICLE_CREATE',
  DELETE_ARTICLE_REQUEST_ARTICLE_CREATE = 'KB_CREATE_ARTICLE/DELETE_ARTICLE_REQUEST_ARTICLE_CREATE',
  CREATE_CATEGORY_REQUEST_ARTICLE_CREATE = 'KB_CREATE_ARTICLE/CREATE_CATEGORY_REQUEST_ARTICLE_CREATE',

  FETCH_ORGANIZATIONS_REQUEST_ARTICLE_CREATE = 'KB_CREATE_ARTICLE/FETCH_ORGANIZATIONS_REQUEST_ARTICLE_CREATE',
  FETCH_ORGANIZATIONS_SUCCESS_ARTICLE_CREATE = 'KB_CREATE_ARTICLE/FETCH_ORGANIZATIONS_SUCCESS_ARTICLE_CREATE',

  FETCH_WORK_GROUPS_REQUEST_ARTICLE_CREATE = 'KB_CREATE_ARTICLE/FETCH_WORK_GROUPS_REQUEST_ARTICLE_CREATE',
  FETCH_WORK_GROUPS_SUCCESS_ARTICLE_CREATE = 'KB_CREATE_ARTICLE/FETCH_WORK_GROUPS_SUCCESS_ARTICLE_CREATE',
  FETCH_ALL_CATEGORIES_REQUEST_ARTICLE_CREATE = 'KB_CREATE_ARTICLE/FETCH_ALL_CATEGORIES_REQUEST_ARTICLE_CREATE',
  FETCH_CATEGORIES_REQUEST_ARTICLE_CREATE = 'KB_CREATE_ARTICLE/FETCH_CATEGORIES_REQUEST_ARTICLE_CREATE',
  FETCH_CATEGORIES_SUCCESS_ARTICLE_CREATE = 'KB_CREATE_ARTICLE/FETCH_CATEGORIES_SUCCESS_ARTICLE_CREATE',

  FETCH_SUB_CATEGORIES_REQUEST_ARTICLE_CREATE = 'KB_CREATE_ARTICLE/FETCH_SUB_CATEGORIES_REQUEST_ARTICLE_CREATE',
  FETCH_SUB_CATEGORIES_SUCCESS_ARTICLE_CREATE = 'KB_CREATE_ARTICLE/FETCH_SUB_CATEGORIES_SUCCESS_ARTICLE_CREATE',

  CURRENT_ARTICLE_LOADING_SHOW_ARTICLE_CREATE = 'KB_CREATE_ARTICLE/CURRENT_ARTICLE_LOADING_SHOW_ARTICLE_CREATE',
  CURRENT_ARTICLE_LOADING_HIDE_ARTICLE_CREATE = 'KB_CREATE_ARTICLE/CURRENT_ARTICLE_LOADING_HIDE_ARTICLE_CREATE',
  FETCH_CURRENT_ARTICLE_REQUEST_ARTICLE_CREATE = 'KB_CREATE_ARTICLE/FETCH_CURRENT_ARTICLE_REQUEST_ARTICLE_CREATE',
  FETCH_CURRENT_ARTICLE_SUCCESS_ARTICLE_CREATE = 'KB_CREATE_ARTICLE/FETCH_CURRENT_ARTICLE_SUCCESS_ARTICLE_CREATE',

  RESET_ARTICLE_CREATE_STATE = 'KB_CREATE_ARTICLE/RESET_ARTICLE_CREATE_STATE',
}

export interface CreateArticleRequestArticleCreateAction {
  type: ArticleCreate.CREATE_ARTICLE_REQUEST_ARTICLE_CREATE;
  payload: ArticleCreateData;
}

export interface EditArticleRequestArticleCreateAction {
  type: ArticleCreate.EDIT_ARTICLE_REQUEST_ARTICLE_CREATE;
  payload: ArticleCreateData;
}

export interface DeleteArticleRequestArticleCreateAction {
  type: ArticleCreate.DELETE_ARTICLE_REQUEST_ARTICLE_CREATE;
  payload: string;
}

export interface FetchOrganizationsRequestArticleCreateAction {
  type: ArticleCreate.FETCH_ORGANIZATIONS_REQUEST_ARTICLE_CREATE;
}

export interface FetchOrganizationsSuccessArticleCreateAction {
  type: ArticleCreate.FETCH_ORGANIZATIONS_SUCCESS_ARTICLE_CREATE;
  payload: Organization[];
}

export interface FetchWorkGroupsSuccessArticleCreateAction {
  type: ArticleCreate.FETCH_WORK_GROUPS_SUCCESS_ARTICLE_CREATE;
  payload: WorkGroup[];
}

export interface FetchAllCategoriesRequestArticleCreateAction {
  type: ArticleCreate.FETCH_ALL_CATEGORIES_REQUEST_ARTICLE_CREATE;
}

export interface FetchCategoriesRequestArticleCreateAction {
  type: ArticleCreate.FETCH_CATEGORIES_REQUEST_ARTICLE_CREATE;
  payload: string;
}

export interface FetchCategoriesSuccessArticleCreateAction {
  type: ArticleCreate.FETCH_CATEGORIES_SUCCESS_ARTICLE_CREATE;
  payload: CategoryArticle[];
}

export interface ResetArticleCreateStateAction {
  type: ArticleCreate.RESET_ARTICLE_CREATE_STATE;
}

export interface FetchSubCategoriesRequestArticleCreateAction {
  type: ArticleCreate.FETCH_SUB_CATEGORIES_REQUEST_ARTICLE_CREATE;
  payload: string;
}

export interface FetchSubCategoriesSuccessArticleCreateAction {
  type: ArticleCreate.FETCH_SUB_CATEGORIES_SUCCESS_ARTICLE_CREATE;
  payload: CategoryArticle[];
}

export interface CurrentArticleLoadingShowArticleCreateAction {
  type: ArticleCreate.CURRENT_ARTICLE_LOADING_SHOW_ARTICLE_CREATE;
}
export interface CurrentArticleLoadingHideArticleCreateAction {
  type: ArticleCreate.CURRENT_ARTICLE_LOADING_HIDE_ARTICLE_CREATE;
}

export interface FetchCurrentArticleRequestArticleCreateAction {
  type: ArticleCreate.FETCH_CURRENT_ARTICLE_REQUEST_ARTICLE_CREATE;
  payload: string;
}

export interface FetchCurrentArticleSuccessArticleCreateAction {
  type: ArticleCreate.FETCH_CURRENT_ARTICLE_SUCCESS_ARTICLE_CREATE;
  payload?: Article;
}

export interface CreateCategoryRequestArticleCreateAction {
  type: ArticleCreate.CREATE_CATEGORY_REQUEST_ARTICLE_CREATE;
  payload: CategoryArticle;
}

export type ArticleCreateActionsTypes =
  | CreateArticleRequestArticleCreateAction
  | EditArticleRequestArticleCreateAction
  | DeleteArticleRequestArticleCreateAction
  | FetchOrganizationsSuccessArticleCreateAction
  | FetchWorkGroupsSuccessArticleCreateAction
  | FetchCategoriesRequestArticleCreateAction
  | FetchCategoriesSuccessArticleCreateAction
  | ResetArticleCreateStateAction
  | CreateCategoryRequestArticleCreateAction
  | FetchSubCategoriesRequestArticleCreateAction
  | FetchSubCategoriesSuccessArticleCreateAction
  | FetchCurrentArticleSuccessArticleCreateAction
  | CurrentArticleLoadingShowArticleCreateAction
  | CurrentArticleLoadingHideArticleCreateAction
  | FetchCurrentArticleRequestArticleCreateAction;
