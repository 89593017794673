import cn from 'clsx';
import { ChangeEvent, FC, memo, useCallback } from 'react';

import { Input, ToggleSwitch, Typography, TypographyVariants } from '@shared';

import { SLASettings } from '../../../../model/types';
import { DEFAULT_SLA_TIME } from '../../config';
import { getConvertedTimeToHours } from '../../utils';
import { Cell } from '../Cell';
import { InfoTooltip } from '../InfoTooltip';

import styles from './SlaCell.module.scss';

const getValueFromSetting = (value?: string | number) =>
  Number.isNaN(Number(value)) ? value : getConvertedTimeToHours(Number(value));

interface SlaCellProps {
  setting: SLASettings;
  canEdit?: boolean;
  onCellDataChange?: (setting: SLASettings, cellType: string) => void;
  className?: string;
  disabled?: boolean;
  cellType: string;
}
export const SlaCell: FC<SlaCellProps> = memo(
  ({
    setting,
    canEdit = false,
    onCellDataChange,
    className,
    disabled = false,
    cellType,
  }) => {
    const reactionTime =
      getValueFromSetting(setting?.reactionTime) || DEFAULT_SLA_TIME;
    const decisionTime =
      getValueFromSetting(setting?.decisionTime) || DEFAULT_SLA_TIME;

    const handleInputChange = useCallback(
      (typeTime: 'reactionTime' | 'decisionTime') =>
        (input?: ChangeEvent<HTMLInputElement>) => {
          if (onCellDataChange) {
            onCellDataChange(
              { ...setting, [typeTime]: input?.target.value },
              cellType
            );
          }
        },
      [onCellDataChange, setting, cellType]
    );

    const handleWorkModeChange = useCallback(
      (value?: boolean) => {
        if (onCellDataChange) {
          onCellDataChange({ ...setting, workMode: Boolean(value) }, cellType);
        }
      },
      [onCellDataChange, setting, cellType]
    );

    const onChangeWorkMode = (event: ChangeEvent<HTMLInputElement>) => {
      handleWorkModeChange(event.target.checked);
    };

    if (canEdit) {
      return (
        <Cell>
          <div className={cn(styles.slaCell, className)}>
            <Input
              mask="999:99"
              disabled={disabled}
              value={reactionTime}
              onChange={handleInputChange('reactionTime')}
              placeholder="Ччч:Мм"
              label="Реакция"
              className={styles.slaCell__input}
            />
            <Input
              mask="999:99"
              disabled={disabled}
              placeholder="Ччч:Мм"
              value={decisionTime}
              onChange={handleInputChange('decisionTime')}
              label="Решение"
              className={styles.slaCell__input}
            />
            <div className={styles.slaCell__timeSwitch}>
              <ToggleSwitch
                label="24/7"
                checked={setting?.workMode}
                disabled={disabled}
                onChange={onChangeWorkMode}
              />
            </div>
          </div>
        </Cell>
      );
    }

    return (
      <Cell>
        <div className={styles.slaCell}>
          <div className={styles.slaCell__timeWrapper}>
            <Typography
              variant={TypographyVariants.s2}
              className={styles.slaCell__inputText}
            >
              Реакция
            </Typography>
            <span className={styles.slaCell__warning}>
              {setting?.reactionTime
                ? getConvertedTimeToHours(Number(setting?.reactionTime))
                : '-'}
              <InfoTooltip
                isUpdated={setting?.updateReactionTime}
                updatedFrom={setting?.updateReactionNameSupplementaryAgreement}
              />
            </span>
          </div>
          <div className={styles.slaCell__timeWrapper}>
            <Typography
              variant={TypographyVariants.s2}
              className={styles.slaCell__inputText}
            >
              Решение
            </Typography>
            <span className={styles.slaCell__warning}>
              {setting?.decisionTime
                ? getConvertedTimeToHours(Number(setting?.decisionTime))
                : '-'}
              <InfoTooltip
                isUpdated={setting?.updateDecisionTime}
                updatedFrom={setting?.updateDecisionNameSupplementaryAgreement}
              />
            </span>
          </div>
          <div className={styles.slaCell__timeSwitch}>
            <ToggleSwitch checked={setting?.workMode} disabled readOnly />
            <Typography variant={TypographyVariants.b3}>24/7</Typography>
            <InfoTooltip
              isUpdated={setting?.updateWorkMode}
              updatedFrom={setting?.updateWorkModeNameSupplementaryAgreement}
            />
          </div>
        </div>
      </Cell>
    );
  }
);
