import { FC } from 'react';

import { ExportIcon, Loader, PopoverButton, PopoverContainer } from '@shared';

import styles from './DesktopTicketsDropContent.module.scss';

interface TicketsDropContentProps {
  handleDownload?: () => void;
  isDownload?: boolean;
}

export const TicketsDropContent: FC<TicketsDropContentProps> = ({
  isDownload,
  handleDownload,
}) => (
  <PopoverContainer>
    <PopoverButton
      icon={
        isDownload ? (
          <Loader
            classNameRoot={styles.dropContent__loader}
            className={styles.dropContent__loader}
          />
        ) : (
          <ExportIcon className={styles.dropContent__icon} />
        )
      }
      onClick={handleDownload}
      disabled={isDownload}
    >
      Экспорт данных Excel
    </PopoverButton>
  </PopoverContainer>
);
