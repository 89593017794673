import { KeyValueOption } from '@shared/model';

import { SelectOption } from '../../model';

export const convertKeyValueOptionToArraySelectOption = (
  options?: KeyValueOption[] | KeyValueOption
) => {
  if (options && !Array.isArray(options)) {
    return [{ title: options.value, value: options.key }];
  }

  return options?.map(
    (option): SelectOption => ({
      title: option.value,
      value: option.key,
    })
  );
};

export const convertKeyValueOptionToSelectOption = (
  option?: KeyValueOption
): SelectOption | undefined =>
  option && { title: option.value, value: option.key };
