import { System, SystemFormData } from '@entities/system';

export const getPreparedSystemValues = (system: System): SystemFormData => {
  const {
    id,
    title,
    versionTitle,
    description,
    organization,
    index,
    typeList,
    environmentList,
  } = system;
  return {
    system: {
      id,
      title,
      versionTitle,
      description,
      organization: {
        title: organization?.title || '',
        value: organization?.id || '',
      },
      index,
    },
    ticketType: typeList || [],
    environment: environmentList || [],
  };
};
