import { useContext, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { getIsClient, getIsOrgTypePartner } from '@entities/auth';
import { NotificationsLayoutContext } from '@entities/notifications';
import {
  fetchCurrentTicketSystemRequest,
  fetchCustomFieldsByTicketIdRequest,
  fetchHistoryRequest,
  fetchIsSystemIntegratedWithJiraRequest,
  fetchTicketAttachmentsRequest,
  fetchTicketCustomFieldsRequest,
  fetchTicketRequest,
  getClientInfoOrgTypeFromTicket,
  getLoading,
  getTicket,
  resetTicketState,
  setCurrentTicket,
  TicketStatus,
  useTicketId,
} from '@entities/ticket';
import { getIsHideSLA } from '@entities/tickets';
import {
  getIsMobile,
  getIsMobileSmall,
  useResizeObserverWrapper,
} from '@shared';

import { TicketAdaptiveInfo } from '../TicketAdaptiveInfo';
import { TicketClientInfo } from '../TicketClientInfo';
import { TicketComments } from '../TicketComments';
import { TicketHistory } from '../TicketHistory';
import { TicketInfo } from '../TicketInfo';
import { TicketSLA } from '../TicketSla';

import styles from './Ticket.module.scss';

export const Ticket = () => {
  const dispatch = useDispatch();

  const { sendMessage } = useContext(NotificationsLayoutContext);

  const ticket = useSelector(getTicket);
  const loading = useSelector(getLoading);
  const isClient = useSelector(getIsClient);
  const isPartner = useSelector(getIsOrgTypePartner);
  const clientInfo = useSelector(getClientInfoOrgTypeFromTicket);
  const isHideSLA = useSelector(getIsHideSLA(clientInfo, ticket));

  const typeId = ticket?.ticketType?.key;

  const isTickedClosed =
    ticket?.customStatus?.defaultStatus === TicketStatus.CLOSE;

  const isSlaShow = useMemo(
    () => (ticket && !loading && isPartner ? !isHideSLA : !isClient),
    [ticket, loading, isPartner, isHideSLA, isClient]
  );

  const isMobile = useSelector(getIsMobile);
  const isMobileSmall = useSelector(getIsMobileSmall);
  const isMobileAll = isMobile || isMobileSmall;

  const ticketId = useTicketId();

  const systemId = ticket?.systemId?.key;

  const { ref: infoBlockRef, height: infoBlockHeight = 0 } =
    useResizeObserverWrapper<HTMLDivElement>();
  const { ref: commentsRef, height: commentsHeight = 0 } =
    useResizeObserverWrapper<HTMLDivElement>();
  const { ref: slaRef, height: slaHeight = 0 } =
    useResizeObserverWrapper<HTMLDivElement>();
  const { ref: clientInfoRef, height: clientInfoHeight = 0 } =
    useResizeObserverWrapper<HTMLDivElement>();

  const slaMargin = !isTickedClosed && isSlaShow && !isClient ? 60 : 0;
  const clientInfoMargin = !isClient ? 12 : 0;

  const historyHeight = useMemo(
    () =>
      infoBlockHeight +
      commentsHeight +
      60 -
      (slaHeight + clientInfoHeight + slaMargin + clientInfoMargin),
    [
      infoBlockHeight,
      slaHeight,
      clientInfoHeight,
      slaMargin,
      clientInfoMargin,
      isSlaShow,
      isClient,
      commentsHeight,
    ]
  );

  useEffect(() => {
    dispatch(setCurrentTicket(undefined));
    if (ticketId) {
      dispatch(
        fetchTicketRequest({
          ticketId,
          withJira: true,
          withNextStatuses: true,
        })
      );
      dispatch(fetchHistoryRequest(ticketId));
      dispatch(fetchTicketAttachmentsRequest(ticketId));
      dispatch(fetchCustomFieldsByTicketIdRequest(ticketId));
      sendMessage(
        JSON.stringify({
          type: 'VIEW',
          content: {
            ticketId,
          },
        })
      );
    }
    return () => {
      dispatch(resetTicketState());
    };
  }, [ticketId]);

  useEffect(() => {
    if (systemId) {
      dispatch(fetchCurrentTicketSystemRequest(systemId));
      dispatch(fetchTicketCustomFieldsRequest(systemId));
    }
  }, [systemId]);

  useEffect(() => {
    if (systemId && typeId) {
      dispatch(fetchIsSystemIntegratedWithJiraRequest({ systemId, typeId }));
    }
  }, [systemId, typeId]);

  return (
    <div className={styles.ticket}>
      <div className={styles.ticket__leftWrapper}>
        {!isMobileAll && (
          <TicketInfo
            ref={infoBlockRef}
            className={styles.ticket__infoBlock}
            isLoading={loading}
          />
        )}
        {isMobileAll && <TicketAdaptiveInfo isLoading={loading} />}
        <TicketComments ref={commentsRef} />
      </div>
      <div className={styles.ticket__rightWrapper}>
        {!isTickedClosed && isSlaShow && !isClient && ticket && (
          <TicketSLA ticket={ticket} ref={slaRef} />
        )}
        {!isClient && (
          <TicketClientInfo ref={clientInfoRef} isLoading={loading} />
        )}
        <TicketHistory
          style={{ maxHeight: historyHeight }}
          className={styles.ticket__historyChangesBlock}
        />
      </div>
    </div>
  );
};
