import { Ticket } from '@entities/ticket/model/types';
import { ApiClient, getEnv, ResponseWithMeta } from '@shared';

import { DesktopFilters, TicketsStatistic } from '../../model';

import { endpointsHistory } from './endpoints';

export const requestsHistory = {
  fetchHistoryTickets: (
    pageNum = 0,
    pageSize?: number,
    sort?: string,
    filterValues?: DesktopFilters
  ) =>
    ApiClient.post<ResponseWithMeta<Ticket[]>>({
      baseURL: getEnv('REACT_APP_SLA_URL'),
      url: endpointsHistory.getHistoryTickets(),
      params: { pageNum, pageSize, sort: sort?.toUpperCase() },
      data: filterValues,
    }),
  fetchHistoryTicketsStatistic: (filterValues?: DesktopFilters) =>
    ApiClient.post<TicketsStatistic>({
      baseURL: getEnv('REACT_APP_TICKET_REPORT_URL'),
      url: endpointsHistory.getHistoryTicketsStatistic(),
      data: filterValues,
    }),
};
