import cn from 'clsx';
import { FC, memo, MouseEvent, ReactElement } from 'react';
import { useSelector } from 'react-redux';
import { NavLink } from 'react-router-dom';

import {
  getScreenWidth,
  ScreenWidth,
  Skeleton,
  Tooltip,
  Typography,
  TypographyVariants,
} from '@shared';

import styles from './NavigationTab.module.scss';

interface NavigationTabProps {
  title?: string;
  icon?: ReactElement;
  className?: string;
  href: string;
  exact?: boolean;
  authLoading?: boolean;
  expanded?: boolean;
  hide?: boolean;
}

export const NavigationTab: FC<NavigationTabProps> = memo(
  ({
    title,
    href,
    exact,
    className,
    authLoading,
    icon,
    expanded = true,
    hide,
  }) => {
    const screenWidth = useSelector(getScreenWidth);

    const isMobile = screenWidth === ScreenWidth.MOBILE;
    const isMobileSmall = screenWidth === ScreenWidth.MOBILE_SMALL;
    const isMobileAll = isMobile || isMobileSmall;

    const handleClick = (event: MouseEvent<HTMLAnchorElement>) => {
      event.preventDefault();
    };

    if (authLoading) {
      return (
        <div
          className={cn(
            styles.navigationTab,
            styles.navigationTab_skeleton,
            className
          )}
        >
          <Skeleton
            className={cn(styles.navigationTab__title_skeleton, {
              [styles.navigationTab__title_skeletonExpanded]: expanded,
            })}
          />
        </div>
      );
    }

    const tooltip = title && !expanded && !isMobileAll && (
      <Tooltip
        place="right"
        id={href}
        className={styles.navigationTab__tooltip}
      >
        {title}
      </Tooltip>
    );

    return (
      <>
        <NavLink
          to={href}
          className={cn(
            styles.navigationTab,
            {
              [styles.navigationTab_hidden]: hide,
            },
            className
          )}
          activeClassName={styles.navigationTab_active}
          onMouseDown={handleClick}
          exact={exact}
          data-tip
          data-for={href}
        >
          <div className={styles.navigationTab__icon}>{icon}</div>
          <Typography
            variant={TypographyVariants.b3}
            className={cn(styles.navigationTab__title, {
              [styles.navigationTab__title_expanded]: expanded || isMobileAll,
            })}
          >
            {title}
          </Typography>
        </NavLink>
        {tooltip}
      </>
    );
  }
);
