import { StorageAddFiles } from '@features/storage';
import {
  StorageCreate,
  StorageDeleteModal,
  StoragesHeader,
  StoragesList,
  useStorages,
} from '@features/storages';
import { LazyLoader } from '@shared';

import styles from './Storages.module.scss';

export const Storages = () => {
  const {
    state: {
      currentOrganization,
      isOpenPanel,
      organizationsOptions,
      storages,
      isAccessCreateStorage,
      selectedStorages,
      isOpenAddModal,
      isOpenDeleteModal,
      deleteStorageDisabled,
      titleSelectedStorage,
      isEmpty,
      panelType,
      selectedStorage,
      storagesLoading,
    },
    methods: {
      handleChangeHeaderOrganization,
      handleChangeSearch,
      handleChangeCheckbox,
      toggleCreatePanel,
      toggleAddModal,
      handleDeleteStorage,
      toggleDeleteModal,
      toggleEditPanel,
      hasMore,
      handleLoadMore,
    },
  } = useStorages();

  const createStoragePanel = (
    <StorageCreate
      isOpen={isOpenPanel}
      panelType={panelType}
      toggleCreatePanel={toggleCreatePanel}
      handleChangeHeaderOrganization={handleChangeHeaderOrganization}
      selectedStorage={selectedStorage}
    />
  );

  const addFilesModal = isOpenAddModal && (
    <StorageAddFiles
      isOpenModal={isOpenAddModal}
      toggleModal={toggleAddModal}
      selectedStorages={selectedStorages}
    />
  );

  const storagesList = !!storages?.content.length && (
    <StoragesList
      storages={storages?.content}
      selectedStorages={selectedStorages}
      handleChangeCheckbox={handleChangeCheckbox}
      hasMore={hasMore}
      handleLoadMore={handleLoadMore}
    />
  );

  const list = (
    <div className={styles.storagesList}>
      {!storages?.content.length && storagesLoading ? (
        <LazyLoader />
      ) : (
        storagesList
      )}
    </div>
  );

  return (
    <div className={styles.storages}>
      <StoragesHeader
        currentOrganization={currentOrganization}
        handleChangeOrganization={handleChangeHeaderOrganization}
        toggleCreatePanel={toggleCreatePanel}
        toggleEditPanel={toggleEditPanel}
        organizationsOptions={organizationsOptions}
        handleChangeSearch={handleChangeSearch}
        isAccessCreateStorage={isAccessCreateStorage}
        toggleAddModal={toggleAddModal}
        addDisabled={!selectedStorages.length}
        toggleCancelModal={toggleDeleteModal}
        deleteStorageDisabled={deleteStorageDisabled}
        storagesLoading={storagesLoading}
      />
      {list}
      <StorageDeleteModal
        isEmpty={isEmpty}
        isOpenModal={isOpenDeleteModal}
        toggleModal={toggleDeleteModal}
        handleDelete={handleDeleteStorage}
        condition={titleSelectedStorage}
      />
      {createStoragePanel}
      {addFilesModal}
    </div>
  );
};
