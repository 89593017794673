import cn from 'clsx';
import { FC } from 'react';

import {
  AttachSystemAndSLAToContractType,
  SLAChoiceType,
} from '@entities/contract';
import { SLADataType, SlaTableProps, SLAType } from '@entities/sla';
import { TicketTypeSettings } from '@entities/ticket';
import { Create, Popover, Typography, TypographyVariants } from '@shared';

import { useCreateSLA } from '../../lib/hooks';

import styles from './CreateSLA.module.scss';
import {
  CreateSLAPopover,
  SavedPatternsSla,
  SLA,
  SlaAutoClosingProps,
  SystemSelection,
} from './ui';

export interface CreateSLAProps {
  isModal?: boolean;
  toggleModal?: () => void;
  isCreateMode?: boolean;
  canChooseSystem?: boolean;
  showContentInModal?: boolean;
  sla?: SLAType;
  slaTicketTypes?: TicketTypeSettings[];
  autoClosingProps?: Partial<SlaAutoClosingProps>;
  onCreate?: (data: AttachSystemAndSLAToContractType) => void;
  onChange?: (data: SLADataType) => void;
  setIsValidSlaTicketTypes?: (data: boolean) => void;
}

export const CreateSLA: FC<CreateSLAProps> = ({
  isModal,
  toggleModal,
  isCreateMode = true,
  canChooseSystem = true,
  showContentInModal = true,
  sla,
  slaTicketTypes,
  autoClosingProps,
  onCreate,
  onChange,
  setIsValidSlaTicketTypes,
}) => {
  const { methods, state } = useCreateSLA({
    toggleModal,
    isCreateMode,
    canChooseSystem,
    sla,
    slaTicketTypes,
    onCreate,
    onChange,
    setIsValidSlaTicketTypes,
  });
  const {
    setValue,
    resetSLAData,
    onCreatePattern,
    setCheckedPriority,
    updateSlaSettings,
    setSelectedSystem,
    copySlaSettingsFromPattern,
    togglePopover,
    formSubmitHandler,
    handleActiveTicketType,
  } = methods;

  const {
    control,
    closeAfterHours,
    autoClosableTickets,
    workingHoursInput,
    workingHoursToInput,
    isDataSLAValid,
    disabledSubmit,
    localSlaTableData,
    checkedPriority,
    ticketTypes,
    slaForSystem,
    isPopoverOpen,
    createTitle,
    titleModal,
    createType,
  } = state;

  const autoClothingProps: SlaAutoClosingProps = {
    control,
    setValue,
    isEditMode: true,
    closeAfterHours,
    autoClosableTickets,
    className: styles.createSLA__autoClosing,
    ...autoClosingProps,
  };

  const prepareContent = (
    <CreateSLAPopover
      resetForm={resetSLAData}
      onCreatePattern={onCreatePattern}
      isDataSLAValid={isDataSLAValid}
    />
  );

  const slaTableProps: SlaTableProps = {
    canEditTable: isCreateMode,
    slaSettings: localSlaTableData,
    checkedPriority,
    setCheckedPriority,
    slaTicketTypes: ticketTypes,
    updateSlaSettings,
  };

  const headerSlide = (
    <div className={styles.createSLA__header}>
      <Typography
        variant={TypographyVariants.h4}
        className={styles.createSLA__searchHeader}
      >
        SLA
      </Typography>
      {/* TODO дождаться анализа */}
      {/* <Controller
        name="createType"
        control={control}
        render={({ field }) => {
          return (
            <RadioSlide
              size={Size.s}
              items={SLIDE_RADIO_TABS}
              value={field.value}
              onChange={field.onChange}
            />
          );
        }}
      /> */}
    </div>
  );

  const contentSystemWithSLA = (
    <div className={styles.createSLA__form}>
      {canChooseSystem && (
        <SystemSelection
          setSelectedSystem={setSelectedSystem}
          selectedSystem={slaForSystem}
        />
      )}
      <div
        className={cn(styles.createSLA__sla, {
          [styles.createSLA__sla_modal]: isCreateMode,
        })}
      >
        {headerSlide}
        {createType === SLAChoiceType.CREATE_NEW ? (
          <SLA
            workingHoursInput={workingHoursInput}
            workingHoursToInput={workingHoursToInput}
            autoClothingProps={autoClothingProps}
            slaTableProps={slaTableProps}
            handleActiveTicketType={handleActiveTicketType}
            popover={
              <Popover
                isOpen={isPopoverOpen}
                positions={['bottom']}
                padding={20}
                align="end"
                content={prepareContent}
                togglePopover={togglePopover}
                withOutsideClick={false}
                elevated
              />
            }
          />
        ) : (
          <SavedPatternsSla
            copyPatternToCreateMode={copySlaSettingsFromPattern}
          />
        )}
      </div>
    </div>
  );

  return (
    <>
      {showContentInModal && toggleModal ? (
        <Create
          className={styles.createSLA}
          classNameBlockAdd={styles.createSLA__buttonsBlockAdd}
          toggleModal={toggleModal}
          isModal={isModal || false}
          createTitle={createTitle}
          title={titleModal}
          onSubmit={formSubmitHandler}
          disabledSubmit={disabledSubmit}
          disabledText="Добавьте хотя бы один тип тикета"
          subModalText="добавление системы"
        >
          {contentSystemWithSLA}
        </Create>
      ) : (
        <div>{contentSystemWithSLA}</div>
      )}
    </>
  );
};
