import cn from 'clsx';
import { FC, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { ActionList, getIsAccessedAction } from '@entities/auth';
import {
  fetchContractAttachmentsRequestContractDetail,
  fetchContractDetailRequest,
  fetchSupplementaryAgreementsRequest,
  fetchSystemsByContractIdRequestContractDetail,
  getContractDetail,
  getProlongationContract,
  getSystemsTransfer,
  ProgressStatus,
  resetContractDetailState,
  resetPartlyContractDetailStateForProlongation,
  setProlongationContract,
} from '@entities/contract';
import { getLoadingContract } from '@entities/contracts';
import {
  getHref,
  getIsMobile,
  getIsMobileSmall,
  getPrevHref,
  LazyLoader,
  RouterHref,
} from '@shared';

import { useContractId } from '../../lib';
import { ContractForm } from '../ContractForm';
import { ContractSign } from '../ContractSign';
import { EnvironmentTableContainer } from '../EnvironmentTableContainer';
import { ResponsibleForContract } from '../ResponsibleForContract';
import { SupplementaryAgreementsAndAttachments } from '../SupplementaryAgreementsAndAttachments';
import { SystemsWithSLA } from '../SystemsWithSLA';
import { WorkGroupsTableContainer } from '../WorkGroupsTableContainer';

import styles from './Contract.module.scss';

interface ContractProps {
  className?: string;
}

export const Contract: FC<ContractProps> = ({ className }) => {
  const contractId = useContractId();

  const dispatch = useDispatch();

  const loading = useSelector(getLoadingContract);
  const contract = useSelector(getContractDetail);
  const { transferStatus } = useSelector(getSystemsTransfer);
  const prolongationContract = useSelector(getProlongationContract);
  const href = useSelector(getHref);
  const prevHref = useSelector(getPrevHref);

  const isMobile = useSelector(getIsMobile);
  const isMobileSmall = useSelector(getIsMobileSmall);

  const isMobileAll = isMobile || isMobileSmall;

  const isAccessToCreateContract = useSelector(
    getIsAccessedAction(ActionList.CreateContract)
  );

  const prevPathAdminContractCard = prevHref === RouterHref.AdminContractCard;
  const pathAdminCreateContract = href === RouterHref.AdminCreateContract;

  useEffect(() => {
    if (contractId) {
      dispatch(fetchContractDetailRequest(contractId));
      dispatch(fetchSupplementaryAgreementsRequest(contractId));
      dispatch(fetchContractAttachmentsRequestContractDetail(contractId));
      dispatch(
        fetchSystemsByContractIdRequestContractDetail({
          id: contractId,
          withDeletedSystems: true,
        })
      );
    }
  }, [contractId]);

  useEffect(
    () => () => {
      if (
        prevPathAdminContractCard &&
        prolongationContract === ProgressStatus.IN_PROGRESS
      ) {
        dispatch(setProlongationContract(undefined));
        dispatch(resetPartlyContractDetailStateForProlongation());
        return;
      }
      if (
        (pathAdminCreateContract &&
          transferStatus === ProgressStatus.IN_PROGRESS) ||
        (prevPathAdminContractCard &&
          transferStatus === ProgressStatus.COMPLETED) ||
        prolongationContract === ProgressStatus.COMPLETED
      ) {
        dispatch(resetContractDetailState());
      }
    },
    [
      transferStatus,
      prolongationContract,
      pathAdminCreateContract,
      prevPathAdminContractCard,
    ]
  );

  if (loading && !contract) {
    return <LazyLoader className={styles.contact__loader} />;
  }

  const mobileSignComponent = isMobileAll && contractId && (
    <div className={styles.contract__subscribe}>
      <ContractSign status={contract?.status} contractId={contractId} />
    </div>
  );

  return (
    <div className={cn(styles.contract, className)}>
      {mobileSignComponent}
      <div className={styles.contract__leftContainer}>
        <ContractForm />
        <SystemsWithSLA />
        <EnvironmentTableContainer />
        <WorkGroupsTableContainer />
      </div>
      <div className={styles.contract__rightContainer}>
        <ResponsibleForContract
          usersList={contract?.userList}
          showCreateBtn={Boolean(contractId && isAccessToCreateContract)}
        />
        <SupplementaryAgreementsAndAttachments />
      </div>
    </div>
  );
};
