import { useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { resetArticlesTableState } from '@entities/knowledge-base';

import { KBTableArticlesContainer } from '../KBTableArticlesContainer';
import { KBTableArticlesSearch } from '../KBTableArticlesSearch';

import styles from './KBTable.module.scss';

export const KBTable = () => {
  const dispatch = useDispatch();

  useEffect(
    () => () => {
      dispatch(resetArticlesTableState());
    },
    []
  );

  return (
    <div className={styles.KBTable}>
      <KBTableArticlesContainer className={styles.KBTable__table} />
      <KBTableArticlesSearch className={styles.KBTable__search} />
    </div>
  );
};
