import { getUrl } from '@shared';

export const endpoints = {
  getResponsibilities: () => getUrl('responsibility/filter'),
  getResponsibilitiesByPosId: (id: string) =>
    getUrl(`responsibility/position/${id}`),
  getResponsibilitiesByGroupId: (id: string) =>
    getUrl(`responsibility/work-group/${id}`),
  getResponsibilitiesByRoleId: (id: string) =>
    getUrl(`responsibility/role/${id}`),
  createResponsibility: () => getUrl('responsibility'),
  updateResponsibilityActions: (id: string) =>
    getUrl(`responsibility/update-action-all/${id}`),
  updateResponsibilityData: () => getUrl('responsibility'),
  addAllActionsResponsibility: (id: string) =>
    getUrl(`responsibility/all-actions/${id}`),
  getResponsibilityById: (id: string) => getUrl(`responsibility/${id}`),
  deleteResponsibility: (id: string) => getUrl(`responsibility/${id}`),
};
