import { SelectOption, ValueType } from '../../model';

export const getOptionValue = <T>(option?: SelectOption<T> | null) =>
  option?.value;

export const getOptionTitle = <T>(option?: SelectOption<T> | null) =>
  option?.title;

export const getValueFromValueType = <T>(option?: ValueType<T> | null) =>
  option && !Array.isArray(option) ? option.value : undefined;

export const getTitleFromValueType = <T>(option?: ValueType<T> | null) =>
  option && !Array.isArray(option) ? option.title : undefined;

export const getMultiOptionValues = <T>(options?: Array<SelectOption<T>>) =>
  options?.map((option) => option.value);
