import { ContractsState } from './types';

export const getContracts = (state: ContractsState) =>
  state.contracts.contracts;
export const getLoading = (state: ContractsState): boolean | undefined =>
  state.contracts.loading;
export const getLoadingContract = (
  state: ContractsState
): boolean | undefined => state.contracts.loadingContract;
export const getCurrentContract = (state: ContractsState) =>
  state.contracts.contract;
export const getCurrentContractId = (
  state: ContractsState
): string | undefined => state.contracts.contractId;
export const getPropsContracts = (state: ContractsState) => ({
  pageNum: state.contracts.pagination?.pageNum,
  pageSize: state.contracts.pagination?.pageSize,
  sortContracts: state?.contracts.sort,
  loadingContracts: getLoading(state),
  totalElements: state.contracts.pagination?.totalElements,
});
export const getContractFilter = (state: ContractsState) =>
  state.contracts.contractsFilter || {};
export const getSystemsForContract = (state: ContractsState) =>
  state.contracts.systemsForContract;
export const getSLAContracts = (state: ContractsState) => state.contracts.sla;
