import cn from 'clsx';
import { FC, useEffect } from 'react';

import { Card } from '../Card';
import { Portal } from '../Portal';

import styles from './DoubleModal.module.scss';

interface DoubleModalProps {
  toggleModal(): void;
  isModal: boolean;
  topContent?: JSX.Element;
  bottomContent?: JSX.Element;
  classNameTopContent?: string;
  classNameBottomContent?: string;
}

export const DoubleModal: FC<DoubleModalProps> = ({
  toggleModal,
  isModal,
  topContent,
  bottomContent,
  classNameTopContent,
  classNameBottomContent,
}) => {
  useEffect(() => {
    if (isModal) {
      document.body.style.overflow = 'hidden';
      return;
    }
    document.body.style.overflow = 'visible';
  }, [isModal]);

  if (!isModal) {
    return null;
  }

  return (
    <Portal>
      <div
        className={styles.doubleModal}
        onMouseDown={toggleModal}
        aria-hidden="true"
      >
        <div
          className={styles.doubleModal__content}
          onMouseDown={(e) => e.stopPropagation()}
          aria-hidden="true"
        >
          {topContent && (
            <Card
              className={cn(
                styles.doubleModal__contentTop,
                classNameTopContent
              )}
            >
              {topContent}
            </Card>
          )}
          <Card
            className={cn(
              styles.doubleModal__contentBottom,
              classNameBottomContent
            )}
          >
            {bottomContent}
          </Card>
        </div>
      </div>
    </Portal>
  );
};
