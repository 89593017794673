import cn from 'clsx';
import { FC, MouseEvent } from 'react';

import { CloseIcon, UploadFileIcon } from '../../assets';
import { downloadFileWithToken } from '../../lib';
import { IconButtonWrapper } from '../IconButtonWrapper';

import styles from './FileChip.module.scss';

interface FileChipProps {
  title: string;
  className?: string;
  filePath?: string;
  onDelete?: (event: MouseEvent<HTMLButtonElement>) => void;
}

export const FileChip: FC<FileChipProps> = ({
  title,
  className,
  filePath,
  onDelete,
}) => {
  const handleClick = (event: MouseEvent<HTMLDivElement>) => {
    event.preventDefault();
  };

  const downloadFileHandler = async () => {
    await downloadFileWithToken(title, filePath);
  };

  const deleteButton = onDelete && (
    <IconButtonWrapper
      icon={<CloseIcon className={styles.fileChip__delete} />}
      onClick={onDelete}
    />
  );

  return (
    <div
      role="button"
      onClick={filePath ? downloadFileHandler : undefined}
      onKeyDown={filePath ? downloadFileHandler : undefined}
      onMouseDown={handleClick}
      tabIndex={0}
      className={cn(
        styles.fileChip,
        { [styles.fileChip_default]: !filePath },
        className
      )}
    >
      <UploadFileIcon className={styles.fileChip__icon} />
      <span className={styles.fileChip__title}>{title}</span>
      {deleteButton}
    </div>
  );
};
