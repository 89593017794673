import cn from 'clsx';
import { FC, useMemo } from 'react';

import { Article, getOrgByUserOrgId } from '@entities/knowledge-base';
import { Organization } from '@entities/organizations';
import {
  BackArrowIcon,
  CalendarIcon,
  getFormatDateWithYear,
  IconButtonWrapper,
  InfoBlock,
  InfoBlockProps,
  Typography,
  TypographyVariants,
  UserIcon,
} from '@shared';

import styles from './UserAssistanceArticleDetailHeader.module.scss';

const getInfoBlocks = (config: InfoBlockProps[]) =>
  config?.map((infoItem) => (
    <InfoBlock
      key={infoItem.title?.toString()}
      mainTitle={infoItem.title}
      info={infoItem.info}
      icon={infoItem.icon}
      className={styles.userAssistanceArticleDetailHeader__infoBlocksItem}
      classNameInfo={
        styles.userAssistanceArticleDetailHeader__infoBlocksItemText
      }
    />
  ));

interface UserAssistanceArticleDetailHeaderProps {
  toggleIsOpen(): void;
  article?: Article;
  userOrganization?: Organization;
  canCreateArticle: boolean;
}

export const UserAssistanceArticleDetailHeader: FC<
  UserAssistanceArticleDetailHeaderProps
> = ({ toggleIsOpen, article, userOrganization, canCreateArticle }) => {
  const infoBlocksArticleConfig = useMemo(
    () => [
      {
        title: 'Организация',
        info: getOrgByUserOrgId({
          canCreateArticle,
          organizations: article?.organizations,
          userOrganization,
        }),
        icon: (
          <UserIcon
            className={cn(
              styles.userAssistanceArticleDetailHeader__ticketInfoIcon,
              styles.userAssistanceArticleDetailHeader__userIcon
            )}
          />
        ),
      },
      {
        title: 'Создатель',
        info: article?.userFullName || '-',
        icon: (
          <UserIcon
            className={cn(
              styles.userAssistanceArticleDetailHeader__ticketInfoIcon,
              styles.userAssistanceArticleDetailHeader__userIcon
            )}
          />
        ),
      },
      {
        title: 'Редактирован',
        info: article?.updated ? getFormatDateWithYear(article?.updated) : '-',
        icon: (
          <CalendarIcon
            className={styles.userAssistanceArticleDetailHeader__ticketInfoIcon}
          />
        ),
      },
    ],
    [article?.organizations, article?.userFullName, article?.updated]
  );

  return (
    <div className={styles.userAssistanceArticleDetailHeader}>
      <div className={styles.userAssistanceArticleDetailHeader__titleWrapper}>
        <IconButtonWrapper
          icon={
            <BackArrowIcon
              className={styles.userAssistanceArticleDetailHeader__icon}
            />
          }
          onClick={toggleIsOpen}
        />
        <Typography
          variant={TypographyVariants.h2}
          className={styles.userAssistanceArticleDetailHeader__title}
        >
          {article?.title}
        </Typography>
      </div>
      <div className={styles.userAssistanceArticleDetailHeader__infoBlocks}>
        {getInfoBlocks(infoBlocksArticleConfig)}
      </div>
    </div>
  );
};
