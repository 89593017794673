import { TicketTypeSettings } from '@entities/ticket/model/types';

import { SLATableDataType } from '../../model/types';

export const getSlaTicketTypesFromSettings = (
  slaSettings?: SLATableDataType
): TicketTypeSettings[] =>
  (slaSettings
    ? Array.from(
        new Set(
          Object.keys(slaSettings).reduce(
            (arr: string[], priority: string) => [
              ...arr,
              ...Object.keys(slaSettings[priority as keyof typeof slaSettings]),
            ],
            []
          )
        )
      )
    : []
  ).map((item, index) => ({
    ticketTypeId: index.toString(),
    title: item,
    active: true,
  }));
