import { RolesState } from './types';

export const getRoles = (state: RolesState) => state.roles.roles;
export const getLoading = (state: RolesState): boolean | undefined =>
  state.roles.loading;
export const getLoadingAdd = (state: RolesState): boolean | undefined =>
  state.roles.loadingAdd;
export const getCurrentRoleId = (state: RolesState): string | undefined =>
  state.roles.roleId;
export const getPropsRoles = (state: RolesState) => ({
  pageNum: state.roles.pagination?.pageNum,
  pageSize: state.roles.pagination?.pageSize,
  sortRoles: state?.roles.sort,
  loadingRoles: getLoading(state),
  totalElements: state.roles.pagination?.totalElements,
});
export const getPropsRolesAdd = (state: RolesState) => ({
  pageNum: state.roles.paginationAdd?.pageNum,
  loadingAdd: getLoadingAdd(state),
  pageSize: state.roles.paginationAdd?.pageSize,
  totalElements: state.roles.paginationAdd?.totalElements,
});
export const getRolesAdd = (state: RolesState) => state.roles.rolesAdd;
export const getRolesAddFilter = (state: RolesState) =>
  state.roles.rolesAddFilter || {};
export const getRolesFilter = (state: RolesState) =>
  state.roles.rolesFilter || {};
export const getCurrentRole = (state: RolesState) => state.roles.currentRole;
export const getIsExistTitle = (state: RolesState) => state.roles.isExistTitle;
