import { ComponentType, ReactPropTypes, useEffect } from 'react';
import { useLocation } from 'react-router-dom';

export const withScrollWindow =
  (Component: ComponentType<ReactPropTypes>) => (props: ReactPropTypes) => {
    const location = useLocation();

    useEffect(() => {
      window.scrollTo({ top: 0, left: 0 });
    }, [location]);

    return <Component {...props} />;
  };
