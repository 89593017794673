import { useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';

import {
  getTicket,
  getTicketTypes,
  updateTicketTypesRequest,
} from '@entities/ticket';
import { getIsMobile, getIsMobileSmall } from '@shared';

import { EditTicketTypeFormValues } from '../../model';
import { getMatchedTicketTypeValue, mapTicketTypesByTabs } from '../utils';

export const useTicketTypeEditor = () => {
  const dispatch = useDispatch();

  const ticket = useSelector(getTicket);
  const ticketTypes = useSelector(getTicketTypes);
  const isMobile = useSelector(getIsMobile);
  const isMobileSmall = useSelector(getIsMobileSmall);

  const isMobileAll = isMobile || isMobileSmall;

  const ticketTypesTabs = useMemo(
    () => mapTicketTypesByTabs(ticketTypes),
    [ticketTypes]
  );

  const {
    register,
    handleSubmit,
    setValue,
    watch,
    formState: { isDirty, isValid },
  } = useForm<EditTicketTypeFormValues>({
    mode: 'onChange',
  });

  const { typeId } = watch();

  const ticketTypesRadioOptions = register('typeId', { required: true });

  const [isModal, setIsModal] = useState(false);

  const matchedTicketTypeValue = useMemo(
    () => getMatchedTicketTypeValue(ticketTypesTabs, ticket),
    [ticketTypesTabs, ticket]
  );

  const isDisabled = useMemo(
    () => !isDirty || !isValid || typeId === matchedTicketTypeValue,
    [isDirty, isValid, typeId, matchedTicketTypeValue]
  );

  useEffect(() => {
    if (matchedTicketTypeValue && isModal) {
      setValue('typeId', matchedTicketTypeValue);
    }
  }, [matchedTicketTypeValue, setValue, isModal]);

  const toggleModal = () => {
    setIsModal((prevState) => !prevState);
  };

  const closeModal = () => {
    setIsModal(false);
  };

  const formSubmitHandler = handleSubmit((data) => {
    dispatch(updateTicketTypesRequest(data));
    closeModal();
  });

  return {
    ticket,
    ticketTypesTabs,
    ticketTypesRadioOptions,
    isDisabled,
    isModal,
    isMobileAll,
    toggleModal,
    formSubmitHandler,
  };
};
