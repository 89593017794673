import { useSelector } from 'react-redux';

import { ActionList, getIsAccessedAction } from '@entities/auth';
import { MainLayout } from '@shared';
import { Storages } from '@widgets/storages';

import styles from './StoragesPage.module.scss';

const StoragesPage = () => {
  const isAccessToCreateTicket = useSelector(
    getIsAccessedAction(ActionList.CreateTicket)
  );

  return (
    <MainLayout
      title="Хранилища"
      classNameRoot={styles.storagesPage}
      accessToCreateTicket={isAccessToCreateTicket}
    >
      <Storages />
    </MainLayout>
  );
};

export default StoragesPage;
