import { pick } from 'lodash';

import { CheckedPriorityType, SLATableDataType } from '@entities/sla';
import { Priority, TicketTypeSettings } from '@entities/ticket';

import { getActiveTicketTypes } from '../../../../lib/utils';

type Args = {
  settings: SLATableDataType;
  checkedPriority: CheckedPriorityType;
  ticketTypes?: TicketTypeSettings[];
};

export const excludeUncheckedRows = ({
  settings,
  checkedPriority,
  ticketTypes,
}: Args) => {
  const activeTicketTypes = getActiveTicketTypes(ticketTypes);

  const result = {} as SLATableDataType;
  Object.keys(settings).forEach((priorityRow) => {
    if (checkedPriority[priorityRow as Priority] && activeTicketTypes) {
      const value = pick(settings[priorityRow as Priority], activeTicketTypes);

      result[priorityRow as Priority] = value;
    }
  });
  return result;
};
