import {
  Attachment,
  AttachmentDeleteType,
  AttachmentsFiles,
} from '@entities/attachment/model/types';
import { Size } from '@shared';

import { TicketFileType } from '../types';

import { AttachmentsReducerState } from './slice';

export type AttachmentsState = {
  attachments: AttachmentsReducerState;
};

export enum VisibilityType {
  PRIVATE = 'PRIVATE',
  WORKGROUPS = 'WORKGROUPS',
}

export type TicketAttachmentFile = {
  lastName: string;
  firstName: string;
  path: string;
  fileName: string;
  created: string;
};

export type Note = {
  id?: string;
  text?: string;
  ticketId?: string;
  userId?: string;
  created?: Date;
  userFirstName?: string;
  userLastName?: string;
  visibility?: VisibilityType;
  files?: AttachmentsFiles[];
};

export interface DeleteFileAttachmentsTicketRequest {
  attachmentFileId?: string;
  attachmentId?: string;
  ticketId?: string;
  fileName?: string;
  attachmentDeleteType?: AttachmentDeleteType;
}

export type AttachmentsRequest = {
  attachment: FormData;
  id: string;
};

export interface PreviewAttachmentType {
  index?: number;
  handleAttachment?: (index: number | undefined) => void;
  handleDeleteFile({
    e,
    attachmentId,
    attachmentFileId,
    fileName,
  }: TicketFileType): void;
  handleDownloadFile({ e, path, fileName }: TicketFileType): void;
  size?: Size;
  isButtonDelete?: boolean;
  files?: AttachmentsFiles[];
  attachment?: Attachment;
}

export interface AddMessageAttachmentsPayload {
  filesData: FormData;
}
