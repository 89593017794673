import DOMPurify from 'dompurify';
import { debounce, omit } from 'lodash';
import { useCallback, useEffect, useState } from 'react';
import { useFieldArray, useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import {
  createTicketAction,
  CreateTicketData,
  CreateTicketDataToRequest,
  fetchEnvironmentsRequest,
  fetchRequiredCustomFieldsRequest,
  fetchTicketsRequest,
  fetchTicketTypesRequest,
  getCustomFields,
  getEnvironmentsSelectList,
  getPropsTickets,
  getTicketsSelectList,
  getTicketTypesSelectList,
  MAX_FILE_SIZE_TEXT,
  resetCreateTicketState,
  resetTicketsState,
  setCurrentPageTickets,
  setFilterTickets,
} from '@entities/create-ticket';
import {
  fetchMySystemsRequest,
  fetchSystemPrioritiesRequest,
  getPriorityByTitleRadioTabs,
  getSystems,
  getSystemsSelectList,
  resetSystemPriorities,
  resetSystemsState,
} from '@entities/systems';
import {
  createErrorAlert,
  DEFAULT_DEBOUNCE_DELAY,
  getIsMobile,
  getIsMobileSmall,
  getScreenWidth,
  getTitleFromValueType,
  getValueFromValueType,
  RouterHref,
  ScreenWidth,
  SelectOption,
  setAlert,
  ValueType,
} from '@shared';

import { DEFAULT_FORM_VALUES } from '../../config';
import { getPreparedCustomFields } from '../utils';

export const useCreateTicket = () => {
  const screenWidth = useSelector(getScreenWidth);
  const systemOptions = useSelector(getSystemsSelectList);
  const environmentOptions = useSelector(getEnvironmentsSelectList);
  const ticketTypesOptions = useSelector(getTicketTypesSelectList);
  const systems = useSelector(getSystems);
  const radioPriorityTabs = useSelector(getPriorityByTitleRadioTabs);
  const isMobile = useSelector(getIsMobile);
  const isMobileSmall = useSelector(getIsMobileSmall);
  const ticketsOptions = useSelector(getTicketsSelectList);
  const ticketsProps = useSelector(getPropsTickets);
  const requiredCustomFields = useSelector(getCustomFields);

  const { pageNum, totalPagesTickets, loadingTickets } = ticketsProps;

  const [files, setFiles] = useState<FormData | null>(null);
  const [isModal, setIsModal] = useState<boolean>(false);
  const [myFiles, setMyFiles] = useState<File[]>([]);
  const [isTicketsIds, setIsTicketsIds] = useState<boolean>(false);
  const [inputValue, setInputValue] = useState<string>('');
  const [description, setDescription] = useState('');
  const [editorLoaded, setEditorLoaded] = useState(false);

  const isMobileAll = isMobile || isMobileSmall;
  const isDesktopSmall = screenWidth === ScreenWidth.DESKTOP_SMALL;
  const isDesktop = screenWidth === ScreenWidth.DESKTOP;

  const { push } = useHistory();

  const dispatch = useDispatch();

  const toggleModal = () => setIsModal(!isModal);

  const setFileHandler = (acceptedFiles: File[]) => {
    const formData = new FormData();
    acceptedFiles.forEach((file) => {
      formData.append('file', new Blob([file]), encodeURI(file.name));
    });
    setFiles(formData);
  };

  const toggleIsTicketsIds = () => {
    setIsTicketsIds((prevState) => !prevState);
  };

  const {
    register,
    handleSubmit,
    control,
    reset,
    watch,
    setValue,
    resetField,

    formState: { errors, isDirty, isValid },
  } = useForm<CreateTicketData>({
    mode: 'onChange',
    defaultValues: DEFAULT_FORM_VALUES,
  });

  const { fields, replace } = useFieldArray({
    control,
    name: 'customFields',
    keyName: '_id',
  });

  const { systemId, typeId, environmentId } = watch();

  const isDisabledSubmit = !isDirty || !isValid || !description.length;

  const titleInputOptions = register('theme', {
    required: true,
    maxLength: {
      value: 255,
      message: 'Тема тикета не может быть длиннее 255-ти символов.',
    },
  });

  const versionSelectOptions = register('versionId', { required: true });
  const priorityRadioOptions = register('priority', { required: true });

  const selectedSystemId = getValueFromValueType(systemId);

  const formSubmitHandler = handleSubmit((data) => {
    const { mentionedTickets } = data;

    const preparedData: CreateTicketDataToRequest = omit(
      {
        ...data,
        description: DOMPurify.sanitize(description || ' '),
        systemId: selectedSystemId || '',
        systemName: getTitleFromValueType(systemId),
        environmentId: getValueFromValueType(environmentId) || '',
        typeId: getValueFromValueType(typeId) || '',
        customFields: getPreparedCustomFields(data.customFields),
        mentionedTickets:
          mentionedTickets && mentionedTickets instanceof Array
            ? mentionedTickets?.map((item: SelectOption) => item.value)
            : [],
      },

      ['versionId', '_id']
    );

    dispatch(createTicketAction({ ticket: preparedData, attachments: files }));
    setMyFiles([]);
    reset();
  });

  const onChangeEditor = useCallback(
    (_, editor) => {
      const data = editor.getData();
      setDescription(data);
    },
    [setDescription]
  );

  const onApproveModal = () => {
    push(RouterHref.Tickets);
  };

  const onErrorMaxFileSize = () => {
    dispatch(setAlert(createErrorAlert(MAX_FILE_SIZE_TEXT)));
  };

  const onInputFilter = debounce((value: string) => {
    dispatch(setCurrentPageTickets(0));
    dispatch(setFilterTickets({ number: value }));
    dispatch(fetchTicketsRequest('update'));
  }, DEFAULT_DEBOUNCE_DELAY);

  const handleInput = (value: string) => {
    onInputFilter(value);
    setInputValue(value);
  };

  const hideInputRow = () => {
    setIsTicketsIds(false);
    setInputValue('');
    resetField('mentionedTickets');
    dispatch(resetTicketsState());
  };

  const setNextPage = () => {
    if (!loadingTickets && totalPagesTickets > (pageNum || 0)) {
      dispatch(setCurrentPageTickets((pageNum || 0) + 1));
      dispatch(fetchTicketsRequest('join'));
    }
  };

  const fetchRequiredCustomFields = (value: ValueType<string>) => {
    const systId = getValueFromValueType(value);
    if (systId) {
      dispatch(fetchRequiredCustomFieldsRequest(systId));
    }
  };

  useEffect(() => {
    const targetSystem = systems?.find(
      (system) => system.id === (systemId as SelectOption)?.value
    );
    const currentVersion = targetSystem?.versionTitle;
    if (currentVersion) {
      setValue('versionId', currentVersion);
    }

    resetField('typeId');

    if (systemId && !Array.isArray(systemId)) {
      dispatch(fetchSystemPrioritiesRequest(systemId.value));
      dispatch(fetchTicketTypesRequest(systemId.value));
    }
  }, [systemId]);

  useEffect(() => {
    if (requiredCustomFields) {
      replace(requiredCustomFields);
    }
  }, [requiredCustomFields]);

  useEffect(() => {
    dispatch(fetchMySystemsRequest('update'));
    return () => {
      dispatch(resetSystemPriorities());
      dispatch(resetCreateTicketState());
      dispatch(resetSystemsState());
    };
  }, []);

  useEffect(() => {
    if (selectedSystemId) {
      dispatch(
        fetchEnvironmentsRequest({
          systemId: selectedSystemId,
        })
      );
    }
  }, [selectedSystemId]);

  useEffect(() => {
    setValue('priority', null);
  }, [systemId]);

  useEffect(() => {
    if (isTicketsIds) {
      dispatch(setCurrentPageTickets(0));
      dispatch(fetchTicketsRequest('update'));
    }
  }, [isTicketsIds]);

  return {
    methods: {
      register,
      setNextPage,
      hideInputRow,
      handleInput,
      onErrorMaxFileSize,
      onApproveModal,
      formSubmitHandler,
      toggleIsTicketsIds,
      setFileHandler,
      toggleModal,
      setMyFiles,
      setEditorLoaded,
      onChangeEditor,
      fetchRequiredCustomFields,
    },
    state: {
      titleInputOptions,
      priorityRadioOptions,
      versionSelectOptions,
      errors,
      control,
      isDisabledSubmit,
      systemOptions,
      environmentOptions,
      ticketTypesOptions,
      radioPriorityTabs,
      myFiles,
      ticketsOptions,
      pageNum,
      totalPagesTickets,
      loadingTickets,
      inputValue,
      description,
      isMobileAll,
      isDesktopSmall,
      isDesktop,
      isModal,
      isTicketsIds,
      editorLoaded,
      fields,
    },
  };
};
