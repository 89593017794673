import { TableWorkGroupsTypes } from '../model';

export const PAGINATION_RANGE_MAP: Record<
  TableWorkGroupsTypes,
  number | undefined
> = {
  [TableWorkGroupsTypes.FULL]: 1,
  [TableWorkGroupsTypes.FROM_ORGANIZATIONS]: 0,
  [TableWorkGroupsTypes.ADD_GROUPS]: 0,
  [TableWorkGroupsTypes.FROM_USERS]: 0,
};
