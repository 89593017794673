import { CustomFieldType } from '@shared/model';
import { SettingField } from '@shared/ui/SettingFields';

import { CustomFieldTypeForFilter } from '../../model';

export const getCustomFieldType = (customColumns?: SettingField[]) => {
  const result: Record<string, CustomFieldTypeForFilter> = {};

  if (!customColumns) {
    return {};
  }

  customColumns?.forEach(({ customFieldType, naturalKey, values }) => {
    if (customFieldType) {
      result[naturalKey] = {
        fieldId: naturalKey,
        type: customFieldType,
        ...(customFieldType === CustomFieldType.FIELD_LIST && {
          values,
        }),
      };
    }
  });

  return result;
};
