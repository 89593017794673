import { createContext } from 'react';
import { SendMessage } from 'react-use-websocket';

interface NotificationContext {
  sendMessage: SendMessage;
}

export const NotificationsLayoutContext = createContext<NotificationContext>({
  sendMessage: () => null,
});
