import { ResponseWithMeta, UpdateContentType } from '@shared';

import { Tag } from '../types';

import {
  CreateTagRequestAction,
  DeleteTagRequestAction,
  FetchTagsRequestAction,
  FetchTagsSuccessJoinAction,
  FetchTagsSuccessUpdateAction,
  Tags,
} from './actionTypes';
import { CheckIsExistTagArgs, CreateTagData, TagsFilter } from './types';

export const fetchTagsRequest = (
  updateType: UpdateContentType
): FetchTagsRequestAction => ({
  type: Tags.FETCH_TAGS_REQUEST,
  payload: updateType,
});

export const loadingTagsShowRequest = () => ({
  type: Tags.TAGS_LOADING_SHOW,
});

export const loadingTagsHideRequest = () => ({
  type: Tags.TAGS_LOADING_HIDE,
});

export const fetchTagsSuccessUpdate = (
  data: ResponseWithMeta<Tag[]>
): FetchTagsSuccessUpdateAction => ({
  type: Tags.FETCH_TAGS_SUCCESS_UPDATE,
  payload: data,
});

export const fetchTagsSuccessJoin = (
  data: ResponseWithMeta<Tag[]>
): FetchTagsSuccessJoinAction => ({
  type: Tags.FETCH_TAGS_SUCCESS_JOIN,
  payload: data,
});

export const createTagRequest = (
  data: CreateTagData
): CreateTagRequestAction => ({
  type: Tags.CREATE_TAG_REQUEST,
  payload: data,
});

export const deleteTagRequest = (id: string): DeleteTagRequestAction => ({
  type: Tags.DELETE_TAG_BY_ID_REQUEST,
  payload: id,
});

export const resetTagsState = () => ({
  type: Tags.RESET_TAGS_STATE,
});

export const setFilterTags = (filter: TagsFilter) => ({
  type: Tags.SET_FILTER_TAGS,
  payload: filter,
});

export const setCurrentPageTags = (page: number) => ({
  type: Tags.SET_CURRENT_PAGE_TAGS,
  payload: page,
});

export const checkIsExistTagRequest = (payload: CheckIsExistTagArgs) => ({
  type: Tags.CHECK_IS_EXIST_TAG_REQUEST,
  payload,
});

export const checkIsExistTagSuccess = (payload: boolean) => ({
  type: Tags.CHECK_IS_EXIST_TAG_SUCCESS,
  payload,
});
