import { FC } from 'react';

import { getFileField } from '../../lib';
import { PreviewAttachmentType } from '../../model/attachment/types';
import { PreviewAttachment } from '../PreviewAttachment';

export const ContentPreviewAttachment: FC<PreviewAttachmentType> = ({
  files,
  attachment,
  handleAttachment,
  handleDeleteFile,
  handleDownloadFile,
  size,
  isButtonDelete,
}) => (
  <>
    {files?.map((file, index) => {
      const attachmentField = getFileField(file, attachment);
      return (
        <PreviewAttachment
          key={attachmentField.attachmentId}
          file={file}
          index={index}
          handleAttachment={handleAttachment}
          handleDeleteFile={handleDeleteFile}
          handleDownloadFile={handleDownloadFile}
          attachmentField={attachmentField}
          size={size}
          isButtonDelete={isButtonDelete}
        />
      );
    })}
  </>
);
