import { FC } from 'react';

import {
  Button,
  ConditionBlock,
  Create,
  Pen3Icon,
  PopoverButton,
  RadioGroup,
  Size,
} from '@shared';

import { TEXT_CONTENT_TICKET_TYPE_EDITOR } from '../../config';
import { useTicketTypeEditor } from '../../lib';

import styles from './TicketTypeEditor.module.scss';

export const TicketTypeEditor: FC = () => {
  const {
    ticket,
    ticketTypesTabs,
    ticketTypesRadioOptions,
    isDisabled,
    isModal,
    isMobileAll,
    toggleModal,
    formSubmitHandler,
  } = useTicketTypeEditor();

  const { editTicketType, createTitle, cancelTitle, subModalText } =
    TEXT_CONTENT_TICKET_TYPE_EDITOR;

  const desktopButton = !isMobileAll && (
    <Button size={Size.xs} appearance="flat" onClick={toggleModal}>
      {editTicketType}
    </Button>
  );

  const mobileButton = isMobileAll && (
    <PopoverButton
      icon={<Pen3Icon className={styles.ticketTypeEditorIcon} />}
      onClick={toggleModal}
    >
      {editTicketType}
    </PopoverButton>
  );

  const renderRadioGroupComponent = (title?: string) => (
    <ConditionBlock
      text={title}
      className={styles.ticketTypeEditor__conditionBlock}
    />
  );

  return (
    <>
      {desktopButton}
      {mobileButton}
      <Create
        isModal={isModal && !ticket?.isDeleted}
        title={editTicketType}
        createTitle={createTitle}
        cancelTitle={cancelTitle}
        subModalText={subModalText}
        disabledSubmit={isDisabled}
        toggleModal={toggleModal}
        onSubmit={formSubmitHandler}
      >
        <RadioGroup
          {...ticketTypesRadioOptions}
          items={ticketTypesTabs}
          renderComponent={renderRadioGroupComponent}
        />
      </Create>
    </>
  );
};
