import { Organization } from '@entities/organizations';
import { ApiClient, getEnv, ResponseWithMeta } from '@shared';

import {
  Article,
  CategoryArticle,
  KBArticleTableFilterToRequest,
  KBOrganizationsFilter,
} from '../../model/types';

import { endpoints } from './endpoints';

export const requests = {
  fetchArticles: (
    pageNum = 0,
    pageSize?: number,
    sort?: string,
    filter?: KBArticleTableFilterToRequest
  ) =>
    ApiClient.post<ResponseWithMeta<Article[]>>({
      baseURL: getEnv('REACT_APP_KNOWLEDGE_BASE_URL'),
      url: endpoints.getArticlesFilter(),
      params: { pageNum, pageSize, sort: sort?.toUpperCase() },
      data: {
        ...filter,
      },
    }),
  fetchOrganizations: (filter?: KBOrganizationsFilter) =>
    ApiClient.post<Organization[]>({
      baseURL: getEnv('REACT_APP_KNOWLEDGE_BASE_URL'),
      url: endpoints.getOrganizations(),
      data: filter,
    }),
  fetchCategoriesRequest: (organizationIds?: string[]) =>
    ApiClient.post<CategoryArticle[]>({
      baseURL: getEnv('REACT_APP_KNOWLEDGE_BASE_URL'),
      url: endpoints.fetchCategories(),
      data: organizationIds,
    }),
};
