import cn from 'clsx';
import { FC } from 'react';

import { FolderIcon, IconBlock, Tooltip } from '@shared';

import styles from './KBCategoryTitle.module.scss';

type KBCategoryTitleProps = {
  id?: string;
  title: string;
  isMobileAll: boolean;
  showFullCategory?: boolean;
  isSubCategory?: boolean;
};

export const KBCategoryTitle: FC<KBCategoryTitleProps> = ({
  id,
  title,
  isMobileAll,
  showFullCategory,
  isSubCategory,
}) => {
  const tooltipId = `${id}-${title}`;

  const tooltip = !isMobileAll && <Tooltip id={tooltipId}>{title}</Tooltip>;

  return (
    <>
      <IconBlock
        icon={<FolderIcon className={styles.KBCategoryTitle__icon} />}
        text={title}
        className={cn(styles.KBCategoryTitle, {
          [styles.KBCategoryTitle_full]: showFullCategory,
          [styles.KBCategoryTitle_subCategory]: isSubCategory,
        })}
        classNameText={styles.KBCategoryTitle__text}
        data-for={tooltipId}
        data-tip
      />
      {tooltip}
    </>
  );
};
