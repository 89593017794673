import { CustomFieldTypeStorage, StorageCustomField } from '@entities/storage';
import { TabItem } from '@shared';

export const DEFAULT_CREATE_STORAGE_FORM_VALUES = {
  title: '',
  description: '',
  organizations: [],
  editorsGroups: [],
  viewersGroups: [],
  size: 1,
  customField: {
    title: '',
    description: '',
    type: CustomFieldTypeStorage.FIELD_TEXT,
  },
};

export const SLIDE_RADIO_TABS: TabItem<CustomFieldTypeStorage>[] = [
  {
    title: 'Текстовый',
    value: CustomFieldTypeStorage.FIELD_TEXT,
  },
  {
    title: 'Дата',
    value: CustomFieldTypeStorage.FIELD_DATE,
  },
  {
    title: 'Бинарный',
    value: CustomFieldTypeStorage.FIELD_FLAG,
  },
];

export const DEFAULT_ATTRIBUTES: StorageCustomField[] = [
  {
    title: 'Название',
    description: '',
    type: CustomFieldTypeStorage.FIELD_TEXT,
    required: true,
  },
  {
    title: 'Описание',
    description: '',
    type: CustomFieldTypeStorage.FIELD_TEXT,
    required: true,
  },
];
