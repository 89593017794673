import {
  HasUncheckedNotificationsType,
  ProfileTabSettings,
} from '@entities/profile';

export const getTabsWithStatusesProfile = (
  tabs: ProfileTabSettings[],
  statuses: HasUncheckedNotificationsType[]
) =>
  tabs.map((item) => {
    const statusForUnite = statuses.find(
      (status: HasUncheckedNotificationsType) =>
        status.notificationType === item.titleNotification ||
        (status.notificationType === null &&
          item.titleNotification?.toLocaleLowerCase() === 'all')
    );
    return {
      ...item,
      ...statusForUnite,
    };
  });
