import { FC } from 'react';

import { User } from '@entities/user';
import {
  Avatar,
  getShortName,
  IconBlock,
  MailIcon,
  PhoneIcon,
  Size,
  Typography,
  TypographyVariants,
} from '@shared';

import styles from './ResponsibleUser.module.scss';

interface ResponsibleUserProps {
  user: User;
}

export const ResponsibleUser: FC<ResponsibleUserProps> = ({ user }) => (
  <div className={styles.responsibleUser}>
    <div className={styles.responsibleUser__name}>
      <Avatar
        size={Size.xs}
        initialsUser={`${user.firstName} ${user.lastName}`}
      />
      <Typography variant={TypographyVariants.b3}>
        {getShortName(user.firstName, user.lastName)}
      </Typography>
    </div>
    <div className={styles.responsibleUser__personalData}>
      <IconBlock
        text={user.phoneNumber}
        icon={
          <PhoneIcon className={styles.responsibleUser__personalDataIcon} />
        }
      />
      <IconBlock
        text={user.email}
        icon={<MailIcon className={styles.responsibleUser__personalDataIcon} />}
      />
    </div>
  </div>
);
