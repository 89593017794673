import { ConditionBlock, ConditionBlockProps } from '@shared/ui/ConditionBlock';
import { IconBlock, IconBlockProps } from '@shared/ui/IconBlock';
import {
  NotificationLink,
  NotificationLinkProps,
} from '@shared/ui/NotificationLink';

import { RenderTypes } from '../../model';

const components = {
  [RenderTypes.NOTIFICATION_LINK]: (props: NotificationLinkProps) => (
    <NotificationLink {...props} />
  ),
  [RenderTypes.CONDITION]: (props: ConditionBlockProps) => (
    <ConditionBlock {...props} />
  ),
  [RenderTypes.ICON_BLOCK]: (props: IconBlockProps) => <IconBlock {...props} />,
};

export const getTableComponent = (componentType: RenderTypes) =>
  components[componentType];
