import { call, put, select, StrictEffect, takeEvery } from 'redux-saga/effects';

import { createError, ResponseWithMeta } from '@shared';

import { tagsRequests as requests } from '../../api/tags';
import { getSystemId } from '../ticket';
import { Tag } from '../types';

import {
  checkIsExistTagSuccess,
  fetchTagsSuccessJoin,
  fetchTagsSuccessUpdate,
  loadingTagsHideRequest,
  loadingTagsShowRequest,
} from './actions';
import {
  CheckIsExistTagRequestAction,
  CreateTagRequestAction,
  FetchTagsRequestAction,
  Tags,
} from './actionTypes';
import { getFilterTags, getPropsTags } from './selectors';
import { TagsFilter } from './types';

function* tagsFetch({ payload }: FetchTagsRequestAction) {
  try {
    yield put(loadingTagsShowRequest());
    const { pageNum, pageSize } = yield select(getPropsTags);
    const filter: TagsFilter = yield select(getFilterTags);
    const systemId: ReturnType<typeof getSystemId> = yield select(getSystemId);
    const tags: ResponseWithMeta<Tag[]> = yield call(
      requests.fetchTags,
      pageNum,
      pageSize,
      { ...filter, systemIds: [systemId?.key || ''] }
    );
    yield put(
      payload === 'join'
        ? fetchTagsSuccessJoin(tags)
        : fetchTagsSuccessUpdate(tags)
    );
    yield put(loadingTagsHideRequest());
  } catch (e) {
    createError(e);
    yield put(loadingTagsHideRequest());
  }
}

function* tagCreate({ payload }: CreateTagRequestAction) {
  try {
    yield call(requests.createTag, payload);
  } catch (e) {
    createError(e);
  }
}

function* checkIsExistTag({ payload }: CheckIsExistTagRequestAction) {
  try {
    const isExist: boolean = yield call(requests.checkIsExistTag, payload);
    yield put(checkIsExistTagSuccess(isExist));
  } catch (e) {
    createError(e);
  }
}

export function* tagsSaga(): Generator<StrictEffect> {
  yield takeEvery(Tags.FETCH_TAGS_REQUEST, tagsFetch);
  yield takeEvery(Tags.CREATE_TAG_REQUEST, tagCreate);
  yield takeEvery(Tags.CHECK_IS_EXIST_TAG_REQUEST, checkIsExistTag);
}
