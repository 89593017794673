import cn from 'clsx';
import { FC } from 'react';

import styles from './Row.module.scss';

interface RowProps {
  className?: string;
  disabled?: boolean;
}

export const Row: FC<RowProps> = ({
  children,
  className,
  disabled = false,
}) => (
  <div
    className={cn(styles.row, className, { [styles.row_disabled]: disabled })}
  >
    {children}
  </div>
);
