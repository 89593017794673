import { AnyAction } from 'redux';

import { Contract } from '@entities/contract/model/types';
import { WorkGroup } from '@entities/work-group/model/types';
import { SelectOption, UpdateContentType, ValueType } from '@shared';

import { OrganizationReducerState } from './slice';

export type OrganizationsState = {
  organizations: OrganizationReducerState;
};

export enum OrganizationType {
  CUSTOMER = 'CUSTOMER',
  SERVICE = 'SERVICE',
  PARTNER = 'PARTNER',
}

export type Organization = {
  id?: string;
  title: string;
  type: OrganizationType;
  domain: string;
  description: string;
  address: string;
  workGroupList: WorkGroup[];
  contractList: Contract[];
  dateCreate?: string;
  emailTwoStepAuthIsEnable?: boolean;
};

export type OrganizationShort = Omit<
  Partial<Organization>,
  'type' | 'workGroupList' | 'contractList'
>;

export type OrganizationsFilter = {
  organizationTypes?: ValueType<OrganizationType> | OrganizationType[];
} & Partial<Pick<Organization, 'title' | 'domain' | 'description' | 'address'>>;

export interface OrganizationsFilterToRequest
  extends Omit<OrganizationsFilter, 'organizationTypes'> {
  organizationTypes?: OrganizationType[];
}

export type FetchOrganizations = {
  updateType: UpdateContentType;
} & Pick<OrganizationsFilterToRequest, 'organizationTypes'>;

export type OrganizationTypeForCustomField = Exclude<
  OrganizationType,
  OrganizationType.PARTNER
>;

export interface OrganizationActionsType<T> {
  setPage: (page: number) => AnyAction;
  setFilter: (filter: OrganizationsFilter) => AnyAction;
  fetchData: (data: FetchOrganizations) => AnyAction;
  setSort: (sort: string) => AnyAction;
  resetData: () => AnyAction;
  getList: (state: T) => SelectOption<string>[];
  getPropsData: (state: T) => {
    totalElements: number | undefined;
    pageNum: number | undefined;
    pageSize: number | undefined;
    loadingOrganization: boolean | undefined;
  };
}

export interface CreateOrganizationToRequest
  extends Partial<
    Pick<
      Organization,
      | 'id'
      | 'title'
      | 'domain'
      | 'address'
      | 'description'
      | 'type'
      | 'emailTwoStepAuthIsEnable'
    >
  > {}

export interface CreateOrganizationData
  extends Omit<CreateOrganizationToRequest, 'type'> {
  type?: ValueType<OrganizationType>;
}

export type DataForRequest = {
  organizationTypes: OrganizationType[];
};
