import { Alert } from '@shared/layouts';

import { BreadCrumb } from '../ui';

import { ScreenWidth, Theme, ThemeActive } from './types';

export enum Core {
  SET_SCREEN_WIDTH = 'CORE/SET_SCREEN_WIDTH',
  SET_REDIRECT_PATH = 'CORE/SET_REDIRECT_PATH',
  SET_THEME = 'CORE/SET_THEME',
  SET_IS_SYSTEM_THEME = 'CORE/SET_IS_SYSTEM_THEME',
  SET_IS_CONTRAST_THEME = 'CORE/SET_IS_CONTRAST_THEME',
  SET_ALERT = 'CORE/SET_ALERT',
  DELETE_ALERT = 'CORE/DELETE_ALERT',
  SET_BREAD_CRUMBS_CONFIG = 'CORE/SET_BREAD_CRUMBS_CONFIG',
  SET_HREF = 'CORE/SET_HREF',
}

export interface SetScreenWidthAction {
  type: Core.SET_SCREEN_WIDTH;
  payload: ScreenWidth;
}

export interface SetRedirectPathAction {
  type: Core.SET_REDIRECT_PATH;
  payload: string | null;
}

export interface SetThemeAction {
  type: Core.SET_THEME;
  payload: Theme;
}

export interface SetIsSystemThemeAction {
  type: Core.SET_IS_SYSTEM_THEME;
  payload: ThemeActive;
}

export interface SetIsContrastThemeAction {
  type: Core.SET_IS_CONTRAST_THEME;
  payload: ThemeActive;
}

export interface SetAlertAction {
  type: Core.SET_ALERT;
  payload: Alert;
}

export interface DeleteAlertAction {
  type: Core.DELETE_ALERT;
  payload: string;
}

export interface SetBreadCrumbsConfigAction {
  type: Core.SET_BREAD_CRUMBS_CONFIG;
  payload: BreadCrumb[];
}

export interface SetHrefAction {
  type: Core.SET_HREF;
  payload: string;
}

export type CoreActionsTypes =
  | SetScreenWidthAction
  | SetRedirectPathAction
  | SetThemeAction
  | SetIsSystemThemeAction
  | SetIsContrastThemeAction
  | SetAlertAction
  | DeleteAlertAction
  | SetBreadCrumbsConfigAction
  | SetHrefAction;
