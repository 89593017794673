import { v4 as uuidv4 } from 'uuid';

import {
  Attribute,
  DestinationType,
  SELECT_ATTRIBUTES_LIST,
} from '@entities/rules';
import { TabItem, TableColumns } from '@shared';

const ORGANIZATIONS = 'Организация';
const SYSTEMS = 'Система';
const TICKET_TYPES = 'Тип тикета';
const TICKET_PRIORITIES = 'Приоритет';
const ENVIRONMENTS = 'Среда';
const CLIENTS = 'Клиент';

export const RADIO_TABS: TabItem<DestinationType>[] = [
  {
    title: 'Назначить тикет на',
    value: DestinationType.SPECIALIST,
  },
  {
    title: 'Распределить тикет по',
    value: DestinationType.WORK_GROUP,
  },
];

export const INITIAL_ATTRIBUTES: Attribute[] = [
  {
    id: uuidv4(),
    isActive: true,
    attribute: SELECT_ATTRIBUTES_LIST[0],
    value: [],
  },
  {
    id: uuidv4(),
    isActive: true,
    attribute: SELECT_ATTRIBUTES_LIST[1],
    value: [],
  },
];

export const TARGET_MAP = {
  [DestinationType.SPECIALIST]: 'targetSpecialist',
  [DestinationType.WORK_GROUP]: 'targetWorkgroup',
};

export const TABLE_HEADER: TableColumns = [
  {
    title: 'Название',
    sortField: 'title',
    fieldName: 'title',
  },
  {
    title: 'Активно',
    sortField: 'isActive',
    fieldName: 'isActive',
  },
  {
    title: ORGANIZATIONS,
    sortField: 'organizations',
    fieldName: 'organizations',
  },
  {
    title: SYSTEMS,
    sortField: 'systems',
    fieldName: 'systems',
  },
  {
    title: TICKET_TYPES,
    sortField: 'ticketTypes',
    fieldName: 'ticketTypes',
  },
  {
    title: TICKET_PRIORITIES,
    sortField: 'ticketPriorities',
    fieldName: 'ticketPriorities',
  },
  {
    title: ENVIRONMENTS,
    sortField: 'environments',
    fieldName: 'environments',
  },
  {
    title: CLIENTS,
    sortField: 'clients',
    fieldName: 'clients',
  },
];
