import { useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { resetAccessesState } from '@entities/accesses';
import { resetActionsState } from '@entities/actions';
import {
  fetchResponsibilitiesRequest,
  resetResponsibilitiesState,
  TableResponsibilitiesTypes,
} from '@entities/responsibilities';
import { AccessesTableContainer } from '@features/accesses';
import { ActionsTableContainer } from '@features/actions';
import { ResponsibilitiesTableContainer } from '@features/responsibilities';
import { TablesLayout } from '@shared';

import styles from './Responsibilities.module.scss';

const SubTableFirst = () => <AccessesTableContainer />;

const SubTableSecond = () => <ActionsTableContainer />;

const MainTable = () => (
  <ResponsibilitiesTableContainer tableType={TableResponsibilitiesTypes.FULL} />
);

export const Responsibilities = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchResponsibilitiesRequest());
    return () => {
      dispatch(resetResponsibilitiesState());
      dispatch(resetAccessesState());
      dispatch(resetActionsState());
    };
  }, []);

  return (
    <TablesLayout
      MainTable={[MainTable]}
      SubTable={[SubTableFirst, SubTableSecond]}
      classNameRoot={styles.responsibilities}
    />
  );
};
