import cn from 'clsx';
import { FC } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Article } from '@entities/knowledge-base';
import {
  fetchArticleRequest,
  getArticle,
  getSelectedArticlesIds,
  selectArticleId,
} from '@entities/user-assistance';

import { UserAssistanceArticle } from '../UserAssistanceArticle';
import { UserAssistanceArticleDetail } from '../UserAssistanceArticleDetail';

import styles from './UserAssistanceArticlesList.module.scss';

interface UserAssistanceArticlesListProps {
  articles: Article[];
  className?: string;
  toggleIsOpenParent(): void;
  toggleIsHiddenParent(): void;
  isAddMode: boolean;
  isAccessToAddArticleToTheHelp?: boolean;
  isOpen: boolean;
  toggleIsOpen(): void;
}

export const UserAssistanceArticlesList: FC<
  UserAssistanceArticlesListProps
> = ({
  articles,
  className,
  toggleIsOpenParent,
  toggleIsHiddenParent,
  isAddMode,
  isAccessToAddArticleToTheHelp,
  isOpen,
  toggleIsOpen,
}) => {
  const dispatch = useDispatch();

  const articleDetail = useSelector(getArticle);
  const selectedArticlesIds = useSelector(getSelectedArticlesIds);

  const toggleIsOpenAll = () => {
    toggleIsOpenParent();
    toggleIsOpen();
  };

  const onArticleClick = (id?: string) => () => {
    if (id) {
      dispatch(fetchArticleRequest(id));
      toggleIsOpen();
    }
  };

  const selectCustomFieldHandler = (id?: string) => () => {
    if (id) {
      dispatch(selectArticleId(id));
    }
  };

  const articlesList = articles.map((article) => {
    const checked = !!selectedArticlesIds?.find((id) => id === article.id);

    return (
      <UserAssistanceArticle
        key={article.id}
        article={article}
        onClick={onArticleClick(article.id)}
        isAddMode={isAddMode}
        withDelete={isAccessToAddArticleToTheHelp}
        checked={checked}
        onChange={selectCustomFieldHandler(article.id)}
        className={styles.userAssistanceArticlesList__article}
      />
    );
  });

  return (
    <div className={cn(styles.userAssistanceArticlesList, className)}>
      {articlesList}
      <UserAssistanceArticleDetail
        article={articleDetail}
        isOpen={isOpen}
        toggleIsOpen={toggleIsOpen}
        toggleIsHiddenParent={toggleIsHiddenParent}
        toggleIsOpenAll={toggleIsOpenAll}
      />
    </div>
  );
};
