import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';

import { ActionList, getIsAccessedAction } from '@entities/auth';
import { getContractDetail } from '@entities/contract';
import {
  getCurrentOrganization,
  getCurrentOrganizationId,
} from '@entities/organizations';
import {
  fetchRespByGroupIdRequest,
  resetResponsibilitiesState,
} from '@entities/responsibilities';
import { fetchRolesByGroupId, resetRolesState } from '@entities/roles';
import { getSystemId } from '@entities/systems';
import {
  fetchUsersByGroupId,
  getCurrentUserId,
  resetUsersState,
} from '@entities/users';
import { TableWorkGroupsTypes } from '@entities/work-group';
import {
  deleteWorkGroupRequest,
  fetchCurrentWorkGroup,
  fetchFunctions,
  getCurrentGroupId,
  getPropsGroups,
  getWorkGroup,
  getWorkGroupFilter,
  getWorkGroups,
  setCurrentWorkGroupsPage,
  setGroupTitle,
  setSizePage,
  setSortWorkGroups,
  setWorkGroupId,
} from '@entities/work-groups';
import { checkObjectIdentity, getChangeButtonProps, RouterHref } from '@shared';

import { getDataTable } from '../utils';

export const useWorkGroupsTableContainer = (
  tableType: TableWorkGroupsTypes
) => {
  const { push } = useHistory();

  const { pathname } = useLocation();

  const workGroups = useSelector(getWorkGroups);

  const filterValues = useSelector(getWorkGroupFilter);
  const groupId = useSelector(getCurrentGroupId);
  const { totalElements, pageNum, pageSize, sortGroups, loadingGroups } =
    useSelector(getPropsGroups);
  const isAccessToCreateWorkGroup = useSelector(
    getIsAccessedAction(ActionList.CreateWorkGroup)
  );
  const isAccessToDeleteGroup = useSelector(
    getIsAccessedAction(ActionList.DeleteEntries)
  );
  const currentOrganizationId = useSelector(getCurrentOrganizationId);
  const currentUserId = useSelector(getCurrentUserId);
  const currentSystemId = useSelector(getSystemId);
  const organization = useSelector(getCurrentOrganization);
  const contractData = useSelector(getContractDetail);
  const workGroup = useSelector(getWorkGroup);

  const dispatch = useDispatch();

  const [isModal, setIsModal] = useState<boolean>(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showFilterRow, setShowFilterRow] = useState(false);

  const toggleDeleteModal = () => {
    setShowDeleteModal((prev) => !prev);
  };

  const isFilterActive = !checkObjectIdentity(filterValues, {});
  const searchDisabled =
    !workGroups?.length || (showFilterRow && isFilterActive);

  const getActiveId = () => {
    const keys = {
      [TableWorkGroupsTypes.FROM_ORGANIZATIONS]: currentOrganizationId,
      [TableWorkGroupsTypes.FROM_USERS]: currentUserId,
      [TableWorkGroupsTypes.FULL]: '',
      [TableWorkGroupsTypes.ADD_GROUPS]: currentSystemId,
    };
    return keys[tableType];
  };

  useEffect(() => {
    if (showFilterRow) {
      setShowFilterRow(false);
    }
  }, [getActiveId()]);

  const toggleModal = () => setIsModal(!isModal);

  const toggleFilterRow = () => {
    setShowFilterRow((prevState) => !prevState);
  };

  const handleSort = (value: string) => {
    dispatch(setSortWorkGroups(value));
    dispatch(fetchFunctions[tableType]());
  };

  const handleGroupClick = (value: ReturnType<typeof getDataTable>[number]) => {
    const { id } = value;
    if (groupId !== id) {
      dispatch(resetUsersState());
      dispatch(resetRolesState());
      dispatch(resetResponsibilitiesState());
      dispatch(setWorkGroupId(id));
      dispatch(fetchCurrentWorkGroup(id));
      dispatch(setGroupTitle(value?.title.title));
      dispatch(fetchUsersByGroupId());
      dispatch(fetchRolesByGroupId());
      dispatch(fetchRespByGroupIdRequest());
    }
  };

  const handleCreate = () => {
    push(RouterHref.AdminCreateGroup, {
      organizationId: organization?.id || currentOrganizationId,
      organizationTitle: organization?.title,
    });
  };

  const prepareCreateTitle = () => {
    const dataTitle = {
      [TableWorkGroupsTypes.FULL]: 'Создать',
      [TableWorkGroupsTypes.ADD_GROUPS]: getChangeButtonProps(
        totalElements || 0
      ).createTitle,
      [TableWorkGroupsTypes.FROM_ORGANIZATIONS]: 'Создать',
      [TableWorkGroupsTypes.FROM_USERS]: undefined,
    };
    return dataTitle[tableType];
  };

  const prepareHandler = () => {
    const dataHandler = {
      [TableWorkGroupsTypes.FULL]: handleCreate,
      [TableWorkGroupsTypes.ADD_GROUPS]: contractData?.id
        ? toggleModal
        : undefined,
      [TableWorkGroupsTypes.FROM_ORGANIZATIONS]: !pathname.includes('users')
        ? handleCreate
        : undefined,
      [TableWorkGroupsTypes.FROM_USERS]: undefined,
    };
    return dataHandler[tableType];
  };

  const prepareDisabled = () => {
    const dataHandler = {
      [TableWorkGroupsTypes.FULL]: !isAccessToCreateWorkGroup,
      [TableWorkGroupsTypes.ADD_GROUPS]:
        pathname === RouterHref.AdminCreateContract || !currentSystemId,
      [TableWorkGroupsTypes.FROM_ORGANIZATIONS]:
        !isAccessToCreateWorkGroup || pathname === RouterHref.AdminCreateUser,
      [TableWorkGroupsTypes.FROM_USERS]: true,
    };
    return dataHandler[tableType];
  };

  const handleChangePage = (page: number) => {
    dispatch(setCurrentWorkGroupsPage(page));
    dispatch(fetchFunctions[tableType]());
  };

  const handleChangePageSize = (arg: number) => {
    dispatch(setSizePage(arg));
  };

  const handleShowModalDelete = () => {
    if (workGroup?.id) {
      setShowDeleteModal(true);
    }
  };

  const handleDelete = () => {
    if (workGroup?.id) {
      dispatch(deleteWorkGroupRequest({ id: workGroup?.id }));
    }
  };

  return {
    state: {
      isAccessToDeleteGroup,
      showDeleteModal,
      searchDisabled,
      prepareCreateTitle,
      prepareDisabled,
      pageNum,
      pageSize,
      sortGroups,
      workGroups,
      groupId,
      showFilterRow,
      isFilterActive,
      workGroup,
      isModal,
      totalElements,
      loadingGroups,
    },
    methods: {
      toggleDeleteModal,
      toggleFilterRow,
      handleSort,
      handleGroupClick,
      prepareHandler,
      handleChangePage,
      handleChangePageSize,
      handleShowModalDelete,
      handleDelete,
      toggleModal,
    },
  };
};
