import { AdminsLayout } from '@pages/AdminsLayout';
import { Organizations } from '@widgets/organizations';

const OrganizationsPage = () => (
  <AdminsLayout>
    <Organizations />
  </AdminsLayout>
);

export default OrganizationsPage;
