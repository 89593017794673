import { ProfileTabSettings } from '@entities/profile';
import { RouterHref, Tab } from '@shared';

import { convertTabTitleToPath } from './convertTabTitleToPath';

export const convertProfileTabToTab = (
  tabs?: (ProfileTabSettings & Partial<Tab>)[]
) =>
  tabs?.map((item) => {
    const { titleNotification, description, hasUnchecked } = item;
    return {
      to: `${RouterHref.ProfileNotifications}/${convertTabTitleToPath(
        titleNotification
      )}`,
      title: description,
      exact: !titleNotification,
      notificationType: titleNotification,
      hasUnchecked,
    };
  }) || [];
