import { isNil } from 'lodash';
import { useEffect, useMemo, useState } from 'react';

import { ObjectType } from '@shared/model';

import { FetchRequestArgs } from '../../ui/SearchSelect';

export const useInfiniteScroll = ({
  pageNum,
  pageSize,
  totalElements,
  loading,
}: FetchRequestArgs<ObjectType>) => {
  const [infiniteScrollable, setInfiniteScrollable] = useState(false);

  const totalPage = useMemo(
    () =>
      !isNil(totalElements) && !isNil(pageSize)
        ? Math.ceil(totalElements / pageSize)
        : 0,
    [totalElements, pageSize]
  );

  const hasMore = useMemo(
    () => pageNum !== undefined && !loading && totalPage > pageNum + 1,
    [loading, totalPage, pageNum]
  );

  useEffect(() => {
    if (pageNum) {
      setInfiniteScrollable(
        (pageNum === 0 && totalElements === 0) || pageNum < totalPage - 1
      );
    }
  }, [pageNum, totalPage, totalElements]);

  return { infiniteScrollable, setInfiniteScrollable, totalPage, hasMore };
};
