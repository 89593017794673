import { FC, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { ActionList, getIsAccessedAction } from '@entities/auth';
import {
  getCurrentNews,
  getHasUnpublishedNews,
  getIsEditNewsMode,
  getIsNewsModal,
  getLoadingCurrentNews,
  setIsNewsModal,
} from '@entities/profile';
import {
  Button,
  EllipseIconButton,
  getIsMobile,
  getIsMobileSmall,
  PlusIcon,
  Size,
  Tabs,
} from '@shared';

import { DEFAULT_NEWS_TAB } from '../../config';
import { NewsDetail } from '../../ui/NewsDetail';
import { NewsModal } from '../../ui/NewsModal';

import styles from './ProfileNewsLayout.module.scss';

export const ProfileNewsLayout: FC = ({ children }) => {
  const currentNews = useSelector(getCurrentNews);
  const loadingCurrentNews = useSelector(getLoadingCurrentNews);
  const isModal = useSelector(getIsNewsModal);
  const isAccessToEditNews = useSelector(
    getIsAccessedAction(ActionList.EditNews)
  );
  const isMobile = useSelector(getIsMobile);
  const isMobileSmall = useSelector(getIsMobileSmall);
  const isEditMode = useSelector(getIsEditNewsMode);
  const hasUnpublishedNews = useSelector(getHasUnpublishedNews);

  const isMobileAll = isMobile || isMobileSmall;
  const isAccessUnpublishedNews = isAccessToEditNews && hasUnpublishedNews;

  const isNewsDetail = useMemo(
    () => !isMobileAll || (!!currentNews && !isEditMode),
    [isMobileAll, currentNews, isEditMode]
  );

  const dispatch = useDispatch();

  const toggleIsModal = () => {
    dispatch(setIsNewsModal(!isModal));
  };

  const NEWS_TAB = isAccessUnpublishedNews
    ? DEFAULT_NEWS_TAB
    : [DEFAULT_NEWS_TAB[0]];

  const tabsList = (
    <Tabs tabs={NEWS_TAB} classNameTab={styles.profileNewsLayout__tabsItem} />
  );

  const addButton = isAccessToEditNews && !isMobileAll && (
    <Button
      onClick={toggleIsModal}
      appearance="flat"
      icon={<PlusIcon />}
      size={Size.xs}
      className={styles.profileNewsLayout__addButton}
    >
      Добавить
    </Button>
  );

  const addButtonMobile = isAccessToEditNews && isMobileAll && (
    <EllipseIconButton
      onClick={toggleIsModal}
      icon={<PlusIcon />}
      className={styles.profileNewsLayout__addButton}
    >
      Добавить
    </EllipseIconButton>
  );

  const newsHeader = (
    <div className={styles.profileNewsLayout__tabs}>
      {tabsList}
      {addButton}
      {addButtonMobile}
    </div>
  );

  const newsDetail = isNewsDetail && (
    <NewsDetail
      news={currentNews}
      loading={loadingCurrentNews}
      className={styles.profileNewsLayout__detail}
    />
  );

  return (
    <div className={styles.profileNewsLayout}>
      <div className={styles.profileNewsLayout__content}>
        {newsHeader}
        {children}
      </div>
      {newsDetail}
      <NewsModal isModal={isModal} toggleIsModal={toggleIsModal} />
    </div>
  );
};
