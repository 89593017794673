import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Route, Switch, useHistory, useLocation } from 'react-router-dom';

import {
  fetchNotificationsProfileRequest,
  getNotificationsProfile,
  getPropsNotificationsProfile,
  resetNotificationsProfileState,
} from '@entities/profile';
import { resetTicketsState } from '@entities/tickets';
import { RouterHref, useInfiniteScroll } from '@shared';

import { ProfileNotificationsLayout } from '../../layouts';
import { NotificationsList } from '../NotificationsList';

export const ProfileNotifications = () => {
  const dispatch = useDispatch();

  const { pathname } = useLocation();
  const { push } = useHistory();

  const notifications = useSelector(getNotificationsProfile);
  const {
    totalElements,
    pageNum = 0,
    pageSize,
    loading,
  } = useSelector(getPropsNotificationsProfile);

  const { totalPage } = useInfiniteScroll({
    pageNum,
    pageSize,
    totalElements,
  });

  const onLoadMore = (page: number) => {
    dispatch(
      fetchNotificationsProfileRequest({ updateType: 'join', pageNum: page })
    );
  };

  useEffect(() => {
    if (pathname === RouterHref.ProfileNotifications) {
      push(`${RouterHref.ProfileNotifications}/all`);
    }
  }, [pathname]);

  useEffect(
    () => () => {
      dispatch(resetNotificationsProfileState());
      dispatch(resetTicketsState());
    },
    []
  );

  return (
    <ProfileNotificationsLayout>
      <Switch>
        <Route
          path="/profile/notifications/:tabType"
          render={() => (
            <NotificationsList
              notifications={notifications}
              onLoadMore={onLoadMore}
              hasMore={totalPage > pageNum + 1}
              loading={loading}
              totalPages={totalPage}
            />
          )}
        />
      </Switch>
    </ProfileNotificationsLayout>
  );
};
