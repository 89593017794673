import { SelectOption } from '../../model';

export const createSelectValue = <T>(
  title?: string,
  value?: T
): SelectOption<T> | null => {
  if (title && value) {
    return {
      title,
      value,
    };
  }
  return null;
};
