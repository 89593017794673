import { FC, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { AccessSwitchForm } from '@entities/accesses';
import {
  getActionsCreate,
  resetActionsCreate,
  setActionsForEditResp,
} from '@entities/actions';
import { updateResponsibility } from '@entities/responsibilities';
import { AddEntityModal } from '@shared';

import { MODAL_TITLE } from '../../config';

import styles from './AccessEdit.module.scss';
import { getActionsTransform } from './utils';

interface AccessEditProps {
  isModal: boolean;
  toggleModal(): void;
}

export const AccessEdit: FC<AccessEditProps> = ({ isModal, toggleModal }) => {
  const actionsNotSorted = useSelector(getActionsCreate) || [];

  const dispatch = useDispatch();

  const closeModal = () => {
    toggleModal();
  };

  const onSubmit = () => {
    dispatch(
      updateResponsibility({
        actions: [...getActionsTransform(actionsNotSorted)],
      })
    );
    closeModal();
  };

  useEffect(() => {
    dispatch(setActionsForEditResp());
    return () => {
      dispatch(resetActionsCreate());
    };
  }, []);

  return (
    // AddModalContainer
    <AddEntityModal
      toggleModal={toggleModal}
      isModal={isModal}
      title={MODAL_TITLE}
      subModalText="полномочия"
      disabledSubmit={false}
      onSubmit={onSubmit}
      isChanging
    >
      <div className={styles.accessEdit__form}>
        <AccessSwitchForm actionsNotSorted={actionsNotSorted} />
      </div>
    </AddEntityModal>
  );
};
