import { FC } from 'react';

import {
  PopoverButton,
  PopoverContainer,
  SendToIcon,
  Typography,
} from '@shared';

import styles from './SystemsWithSLADropContent.module.scss';

interface SystemsWithSLADropContentProps {
  handleExpand: () => void;
  disabled: boolean;
}

export const SystemsWithSLADropContent: FC<SystemsWithSLADropContentProps> = ({
  handleExpand,
  disabled,
}) => (
  <PopoverContainer>
    <PopoverButton
      icon={<SendToIcon className={styles.systemsWithSlaDropContent__icon} />}
      disabled={disabled}
      onClick={handleExpand}
    >
      <Typography>Расширить поддержку</Typography>
    </PopoverButton>
  </PopoverContainer>
);
