import { CurrentUser, FetchPermissionsSuccessActionPayload } from './types';

export enum CurrentUserTypes {
  SET_CURRENT_USER = 'AUTH/SET_CURRENT_USER',
  SET_AUTHORIZED = 'AUTH/SET_AUTHORIZED',
  FETCH_USER_PERMISSIONS_REQUEST = 'AUTH/FETCH_USER_PERMISSIONS_REQUEST',
  FETCH_USER_PERMISSIONS_SUCCESS = 'AUTH/FETCH_USER_PERMISSIONS_SUCCESS',
  CHANGE_PASSWORD = 'AUTH/CHANGE_PASSWORD',
}

export interface SetCurrentUserAction {
  type: CurrentUserTypes.SET_CURRENT_USER;
  payload: CurrentUser;
}

export interface SetAuthorizedAction {
  type: CurrentUserTypes.SET_AUTHORIZED;
  payload: boolean;
}

export interface FetchPermissionsRequestAction {
  type: CurrentUserTypes.FETCH_USER_PERMISSIONS_REQUEST;
}

export interface FetchPermissionsSuccessAction {
  type: CurrentUserTypes.FETCH_USER_PERMISSIONS_SUCCESS;
  payload: FetchPermissionsSuccessActionPayload;
}

export interface ChangeUserPasswordAction {
  type: CurrentUserTypes.CHANGE_PASSWORD;
  payload: string;
}

export type CurrentUserActionsTypes =
  | SetCurrentUserAction
  | FetchPermissionsSuccessAction
  | SetAuthorizedAction;
