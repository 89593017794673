import { Reducer } from 'redux';

import { Organization } from '@entities/organizations';
import { WorkGroup } from '@entities/work-group';
import { ReducerMap } from '@shared';

import { Article, CategoryArticle } from '../types';

import {
  ArticleCreate,
  ArticleCreateActionsTypes,
  FetchCategoriesSuccessArticleCreateAction,
  FetchCurrentArticleSuccessArticleCreateAction,
  FetchOrganizationsSuccessArticleCreateAction,
  FetchSubCategoriesSuccessArticleCreateAction,
  FetchWorkGroupsSuccessArticleCreateAction,
} from './actionTypes';

export interface ArticleCreateReducerState {
  organizations?: Organization[];
  workGroups?: WorkGroup[];
  categories?: CategoryArticle[];
  subCategories?: CategoryArticle[];
  currentArticle?: Article;
  currentArticleLoading?: boolean;
}

const initialState: ArticleCreateReducerState = {
  organizations: [],
  workGroups: [],
  categories: [],
  subCategories: [],
  currentArticleLoading: false,
};

const articleCreateReducerMap: ReducerMap<
  ArticleCreateReducerState,
  ArticleCreateActionsTypes
> = {
  [ArticleCreate.FETCH_ORGANIZATIONS_SUCCESS_ARTICLE_CREATE]: (
    state,
    action
  ) => {
    const { payload } = action as FetchOrganizationsSuccessArticleCreateAction;
    return {
      ...state,
      organizations: payload,
    };
  },
  [ArticleCreate.FETCH_WORK_GROUPS_SUCCESS_ARTICLE_CREATE]: (state, action) => {
    const { payload } = action as FetchWorkGroupsSuccessArticleCreateAction;
    return {
      ...state,
      workGroups: payload,
    };
  },
  [ArticleCreate.FETCH_CATEGORIES_SUCCESS_ARTICLE_CREATE]: (state, action) => {
    const { payload } = action as FetchCategoriesSuccessArticleCreateAction;
    return {
      ...state,
      categories: payload,
    };
  },
  [ArticleCreate.FETCH_SUB_CATEGORIES_SUCCESS_ARTICLE_CREATE]: (
    state,
    action
  ) => {
    const { payload } = action as FetchSubCategoriesSuccessArticleCreateAction;
    return {
      ...state,
      subCategories: payload,
    };
  },
  [ArticleCreate.FETCH_CURRENT_ARTICLE_SUCCESS_ARTICLE_CREATE]: (
    state,
    action
  ) => {
    const { payload } = action as FetchCurrentArticleSuccessArticleCreateAction;
    return {
      ...state,
      currentArticle: payload,
    };
  },
  [ArticleCreate.CURRENT_ARTICLE_LOADING_SHOW_ARTICLE_CREATE]: (state) => ({
    ...state,
    currentArticleLoading: true,
  }),
  [ArticleCreate.CURRENT_ARTICLE_LOADING_HIDE_ARTICLE_CREATE]: (state) => ({
    ...state,
    currentArticleLoading: false,
  }),
  [ArticleCreate.RESET_ARTICLE_CREATE_STATE]: () => ({
    ...initialState,
  }),
};

export const articleCreateSlice: Reducer<
  ArticleCreateReducerState,
  ArticleCreateActionsTypes
> = (state = initialState, action) => {
  const reducer = articleCreateReducerMap[action.type];
  if (!reducer) {
    return state;
  }
  return reducer(state, action);
};
