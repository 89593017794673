import { ProfileSettings } from '@entities/profile';
import { KeyValueOption } from '@shared';

export const prepareNotificationSettings = (
  notificationsSettingsLocale: ProfileSettings,
  notificationWorkgroups: KeyValueOption[],
  isOnlySpecialistWorkGroup: boolean,
  isManagerSpecialistWorkGroup: boolean
) => {
  const emptyWorkGroupList: KeyValueOption[] = [];
  return {
    ...notificationsSettingsLocale,
    specialistNotificationWorkgroups: isOnlySpecialistWorkGroup
      ? notificationWorkgroups
      : emptyWorkGroupList,
    managerNotificationWorkgroups: isManagerSpecialistWorkGroup
      ? notificationWorkgroups
      : emptyWorkGroupList,
  };
};
