import { FC } from 'react';
import { useSelector } from 'react-redux';

import { Action, getLoading } from '@entities/actions';
import { Table } from '@shared';

import { ACTIONS_FROM_RESP_FIELDS } from '../../config';
import { ActionsFilter } from '../ActionsFilter';

import { getDataTable } from './utils';

interface ActionsFromResponsibilitiesTableProps {
  actions?: Action[];
  sort?: string;
  handleSort?: (value: string) => void;
  handleRowClick?: (value: string) => void;
  activeRowId?: string;
  showFilterRow?: boolean;
  isFilterActive?: boolean;
}

export const ActionsFromResponsibilitiesTable: FC<
  ActionsFromResponsibilitiesTableProps
> = ({
  actions,
  sort,
  handleSort,
  handleRowClick,
  activeRowId,
  showFilterRow,
  isFilterActive,
}) => {
  const data = getDataTable(actions);

  const loading = useSelector(getLoading);

  return (
    <Table
      data={data}
      columns={ACTIONS_FROM_RESP_FIELDS}
      sort={sort}
      onRowClick={handleRowClick}
      onSort={handleSort}
      activeRowId={activeRowId}
      dataLoading={loading}
      isFilterActive={isFilterActive}
      filterComponent={showFilterRow && <ActionsFilter />}
      disabledSort={loading}
    />
  );
};
