import { SelectOption } from '../../model';

type Item = { id?: string; title?: string; name?: string };

const getTitle = ({ title, name }: Item) => title ?? name ?? '';

const getOption = (item: Item): SelectOption => ({
  title: getTitle(item),
  value: item?.id || '',
});

export const getArraySelectOptions = <T extends Array<Item>>(array?: T) =>
  array?.map(getOption) || [];

export const getSelectOption = (item: Item) => getOption(item);
