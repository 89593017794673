import { CurrentState } from './types';

export const getCurrentStateTickets = (state: CurrentState) =>
  state.desktopCurrentState.tickets;
export const getCurrentStateTotalTickets = (state: CurrentState) =>
  state.desktopCurrentState.totalTickets;
export const getCurrentStateTicketsStatistic = (state: CurrentState) =>
  state.desktopCurrentState.ticketsStatistic;
export const getCurrentStatePageNumber = (state: CurrentState) =>
  state.desktopCurrentState.pageNum;
export const getCurrentStatePageSize = (state: CurrentState) =>
  state.desktopCurrentState.pageSize;
export const getCurrentStateSort = (state: CurrentState) =>
  state.desktopCurrentState.sort;
export const getIsCurrentStateTicketsLoading = (state: CurrentState) =>
  state.desktopCurrentState.isTicketsLoading;
