import { TriggerAttributes } from '@entities/triggers';
import {
  convertKeyValueOptionToArraySelectOption,
  getValueFromValueType,
  ValueType,
} from '@shared';

interface Args {
  attributesFields: TriggerAttributes;
  attribute: ValueType<string>;
}

export const getAttributesValueOptions = ({
  attributesFields,
  attribute,
}: Args) => {
  const optionValue = getValueFromValueType(attribute) || '';
  const fields = attributesFields[optionValue as keyof TriggerAttributes];

  return convertKeyValueOptionToArraySelectOption(fields) || [];
};
