import { useSelector } from 'react-redux';
import { Redirect, useLocation } from 'react-router-dom';

import { ActionList, getIsAccessedAction } from '@entities/auth';
import { RouterHref } from '@shared';

const AdminPage = () => {
  const { pathname } = useLocation();

  const isAccessToViewingSettings = useSelector(
    getIsAccessedAction(ActionList.ViewingSettings)
  );
  const isAccessToViewingCompany = useSelector(
    getIsAccessedAction(ActionList.ViewingCompanyAdministration)
  );
  const isAccessToViewingUser = useSelector(
    getIsAccessedAction(ActionList.ViewingUserAdministration)
  );

  const redirectPath = (() => {
    if (isAccessToViewingCompany) {
      return RouterHref.AdminOrganizations;
    }
    if (isAccessToViewingUser) {
      return RouterHref.AdminUsers;
    }
    if (isAccessToViewingSettings) {
      return RouterHref.AdminCustomFields;
    }
    return RouterHref.NotFound;
  })();

  if (pathname === RouterHref.Admin) {
    return <Redirect to={redirectPath} />;
  }
  return null;
};

export default AdminPage;
