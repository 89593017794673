import { debounce } from 'lodash';
import { FC, memo, useMemo } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';

import {
  fetchOrganizationsAddRequest,
  OrganizationSelect,
  OrganizationType,
  setCurrentOrganizationsAddPage,
  setOrganizationsAddFilterAction,
} from '@entities/organizations';
import {
  SystemsFilter as SystemsFilterData,
  TableSystemsTypes,
} from '@entities/system';
import {
  getEnvironmentsOptions,
  INITIAL_SYSTEMS_FILTER,
  setCurrentSystemsPage,
  setSystemsFilter,
} from '@entities/systems';
import {
  checkObjectIdentity,
  DEFAULT_DEBOUNCE_DELAY,
  Input,
  Select,
  Size,
  TableBodyCell,
  TableFilter,
} from '@shared';

import { fetchFunctions } from '../../lib';

import styles from './SystemsFilter.module.scss';

interface SystemsFilterProps {
  tableType: TableSystemsTypes;
}

export const SystemsFilter: FC<SystemsFilterProps> = memo(({ tableType }) => {
  const dispatch = useDispatch();

  const { register, handleSubmit, reset, watch, control } =
    useForm<SystemsFilterData>({
      mode: 'onChange',
      defaultValues: INITIAL_SYSTEMS_FILTER,
    });

  const environmentsOptions = useSelector(getEnvironmentsOptions);

  const filterValues = watch();

  const resetFilter = () => {
    dispatch(setSystemsFilter({}));
    dispatch(fetchFunctions[tableType]());
    if (tableType === TableSystemsTypes.FULL) {
      dispatch(setCurrentOrganizationsAddPage(0));
      dispatch(setOrganizationsAddFilterAction({}));
      dispatch(fetchOrganizationsAddRequest({ updateType: 'update' }));
    }
    reset();
  };

  const formSubmitHandler = handleSubmit((data) => {
    const {
      title,
      description,
      organizationId,
      versionTitle,
      contractTitle,
      environmentIds,
    } = data;

    const preparedData = {
      title: title || undefined,
      description: description || undefined,
      organizationId: organizationId || undefined,
      versionTitle: versionTitle || undefined,
      contractTitle: contractTitle || undefined,
      withOnlyActiveContract: contractTitle ? true : undefined,
      environmentIds: environmentIds || undefined,
    };

    dispatch(setSystemsFilter(preparedData));
    dispatch(setCurrentSystemsPage(0));
    dispatch(fetchFunctions[tableType]());
  });

  const formSubmitHandlerDebounced = useMemo(
    () => debounce(formSubmitHandler, DEFAULT_DEBOUNCE_DELAY),
    []
  );

  const titleToInput = register('title', {
    onChange: formSubmitHandlerDebounced,
  });
  const descriptionToInput = register('description', {
    onChange: formSubmitHandlerDebounced,
  });
  const versionToInput = register('versionTitle', {
    onChange: formSubmitHandlerDebounced,
  });
  const contractToInput = register('contractTitle', {
    onChange: formSubmitHandlerDebounced,
  });
  const ticketTypeToInput = register('ticketType', {
    onChange: formSubmitHandlerDebounced,
  });

  const tableBodyWrapper = (elements: JSX.Element[]) =>
    elements.map((item) => (
      <TableBodyCell className={styles.systemsFilter__cell} key={item.key}>
        {item}
      </TableBodyCell>
    ));

  const filterElements = {
    [TableSystemsTypes.FULL]: () => [
      <Input size={Size.xs} type="title" key="title" {...titleToInput} />,
      <Input
        size={Size.xs}
        type="description"
        key="description"
        {...descriptionToInput}
      />,
      <Input
        size={Size.xs}
        type="versionTitle"
        key="versionTitle"
        {...versionToInput}
      />,
      <Controller
        control={control}
        name="organizationId"
        key="organizationId"
        render={({ field }) => (
          <OrganizationSelect
            size={Size.xs}
            onChange={(value) => {
              field.onChange(value);
              formSubmitHandler();
            }}
            value={field.value}
            dataForRequest={{
              organizationTypes: [
                OrganizationType.CUSTOMER,
                OrganizationType.SERVICE,
              ],
            }}
          />
        )}
      />,
      <Input
        size={Size.xs}
        type="contractTitle"
        key="contractTitle"
        {...contractToInput}
      />,
      <Input
        size={Size.xs}
        type="ticketTypeToInput"
        key="ticketTypeToInput"
        disabled
        {...ticketTypeToInput}
      />,
      <Controller
        control={control}
        name="environmentIds"
        key="environmentIds"
        render={({ field }) => (
          <Select
            size={Size.xs}
            mobileModalTitle="среда"
            options={environmentsOptions}
            value={field.value}
            onChange={(value) => {
              field.onChange(value);
              formSubmitHandler();
            }}
            isMulti
          />
        )}
      />,
    ],
    [TableSystemsTypes.FROM_CONTRACTS]: () => [
      <Input
        size={Size.xs}
        type="title"
        key="titleContract"
        {...titleToInput}
      />,
      <Input
        size={Size.xs}
        type="description"
        key="descriptionContract"
        {...descriptionToInput}
      />,
    ],
  };

  const filter = (
    <>
      {tableBodyWrapper(
        filterElements[tableType as keyof typeof filterElements]()
      )}
    </>
  );

  const disableReset = checkObjectIdentity(filterValues, {});

  return (
    <TableFilter
      filterComponent={filter}
      onReset={resetFilter}
      disableReset={disableReset}
    />
  );
});
