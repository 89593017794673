import { FC } from 'react';

import { CloseIcon } from '../../assets';
import { PopoverButton, PopoverButtonProps } from '../Popover';

import styles from './DeleteButton.module.scss';
// TODO мб положить в Popover
export const DeleteButton: FC<PopoverButtonProps> = (props) => (
  <PopoverButton
    icon={<CloseIcon className={styles.deleteButton__icon} />}
    {...props}
  >
    Удалить
  </PopoverButton>
);
