import { Languages } from '@shared';

import {
  TICKET_STATUS_TITLE_MAP,
  TICKET_STATUS_TITLE_MAP_EN,
} from '../../config';

export const getTicketStatusTitleMap = (language: Languages) => {
  const isRU = language.includes(Languages.RU);
  return isRU ? TICKET_STATUS_TITLE_MAP : TICKET_STATUS_TITLE_MAP_EN;
};
