import { useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { resetFilterOrganizationIdState } from '@entities/organizations';
import {
  resetResponsibilitiesState,
  TableResponsibilitiesTypes,
} from '@entities/responsibilities';
import {
  fetchRolesRequest,
  resetRolesState,
  TableRolesTypes,
} from '@entities/roles';
import { ResponsibilitiesTableContainer } from '@features/responsibilities';
import { RolesTableContainer } from '@features/roles';
import { TablesLayout } from '@shared';

import styles from './Roles.module.scss';

const SubTableFirst = () => (
  <ResponsibilitiesTableContainer
    tableType={TableResponsibilitiesTypes.FROM_ROLES}
  />
);

const MainTable = () => (
  <RolesTableContainer tableType={TableRolesTypes.FULL} />
);

export const Roles = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchRolesRequest());
    return () => {
      dispatch(resetRolesState());
      dispatch(resetResponsibilitiesState());
      dispatch(resetFilterOrganizationIdState());
    };
  }, []);

  return (
    <TablesLayout
      MainTable={[MainTable]}
      SubTable={[SubTableFirst]}
      classNameRoot={styles.roles}
    />
  );
};
