import { isEqual } from 'lodash';
import { call, put, select, StrictEffect, takeEvery } from 'redux-saga/effects';

import { TicketTypeSettings } from '@entities/ticket/model/types';
import {
  ActionForAlertTypes,
  createError,
  getErrorAlert,
  getSuccessAlert,
  setAlert,
  setRedirectPath,
} from '@shared';

import { requests } from '../api';

import { getSystem } from './selectors';
import {
  addEnvironments,
  addTicketTypes,
  createSystem,
  editSystem,
  fetchSystem,
  fetchSystemIndexList,
  setEnvironments,
  setLoading,
  setSystemIndexList,
  setSystemSuccess,
  setTicketTypes,
} from './slice';
import {
  CreateEnvironment,
  CreateTicketType,
  EnvironmentResponse,
  System,
} from './types';

const ENTITY_SYSTEM = 'Система';

function* currentSystemFetch({ payload }: ReturnType<typeof fetchSystem>) {
  try {
    const system: System = yield call(requests.fetchCurrentSystem, payload);
    yield put(setSystemSuccess(system));
  } catch (e) {
    createError(e);
  }
}

function* ticketTypeCreate(payload: CreateTicketType) {
  try {
    const ticketTypes: TicketTypeSettings[] = yield call(
      requests.createTicketType,
      payload
    );
    yield put(setTicketTypes(ticketTypes));
  } catch (e) {
    createError(e);
  }
}

function* environmentCreate(payload: CreateEnvironment) {
  try {
    const environments: EnvironmentResponse[] = yield call(
      requests.createEnvironment,
      payload
    );
    yield put(setEnvironments(environments));
  } catch (e) {
    createError(e);
  }
}

function* editTicketTypes({ payload }: ReturnType<typeof addTicketTypes>) {
  try {
    const system: ReturnType<typeof getSystem> = yield select(getSystem);
    const systemId = system?.id;

    if (systemId) {
      const ticketTypes: TicketTypeSettings[] = yield call(
        requests.editTicketTypes,
        systemId,
        payload
      );
      yield put(setTicketTypes(ticketTypes));
    }
  } catch (e) {
    createError(e);
  }
}

function* editEnvironment({ payload }: ReturnType<typeof addEnvironments>) {
  try {
    const system: ReturnType<typeof getSystem> = yield select(getSystem);
    const systemId = system?.id;

    if (systemId) {
      const environment: EnvironmentResponse[] = yield call(
        requests.editEnvironments,
        systemId,
        payload
      );
      yield put(setEnvironments(environment));
    }
  } catch (e) {
    createError(e);
  }
}

function* systemCreate({ payload }: ReturnType<typeof createSystem>) {
  try {
    const { system, ticketType, environment } = payload;

    const preparedTicketType = ticketType.map(
      (item) => item.title || item.value
    );
    const preparedEnvironment = environment.map((item) => item.name);

    yield put(setLoading(true));

    const newSystem: System = yield call(requests.createSystem, system);

    yield call(ticketTypeCreate, {
      systemId: newSystem.id,
      ticketTypes: preparedTicketType,
    });

    yield call(environmentCreate, {
      systemId: newSystem.id,
      environments: preparedEnvironment,
    });

    yield put(setRedirectPath(`/admin/systems/${newSystem.id}`));

    yield put(
      setAlert(getSuccessAlert(ENTITY_SYSTEM, ActionForAlertTypes.CREATE))
    );
  } catch (e) {
    yield put(
      setAlert(getErrorAlert(ENTITY_SYSTEM, ActionForAlertTypes.CREATE))
    );
    createError(e);
  } finally {
    yield put(setLoading(false));
  }
}

function* systemEdit({ payload }: ReturnType<typeof createSystem>) {
  try {
    const { ticketType, environment } = payload;

    const system: ReturnType<typeof getSystem> = yield select(getSystem);

    const { description, id, index, organization, title, versionTitle } =
      system || {};

    const systemForCompare = {
      description,
      id,
      index,
      organization: { id: organization?.id },
      title,
      versionTitle,
    };

    const isEqualSystem = isEqual(systemForCompare, payload.system);
    const isEqualTicketTypes = isEqual(system?.typeList, ticketType);
    const isEqualEnvironment = isEqual(system?.environmentList, environment);

    yield put(setLoading(true));

    if (!isEqualSystem) {
      const newSystem: System = yield call(requests.editSystem, payload.system);
      yield put(setSystemSuccess(newSystem));
    }

    if (!isEqualTicketTypes) {
      const preparedTicketType = ticketType.map(
        (item) => item.title || item.value
      );

      yield call(editTicketTypes, {
        type: addTicketTypes.type,
        payload: preparedTicketType,
      });
    }

    if (!isEqualEnvironment) {
      const preparedEnvironment = environment.map((item) => item.name);

      yield call(editEnvironment, {
        type: addEnvironments.type,
        payload: preparedEnvironment,
      });
    }

    yield put(
      setAlert(getSuccessAlert(ENTITY_SYSTEM, ActionForAlertTypes.EDIT))
    );
  } catch (e) {
    yield put(setAlert(getErrorAlert(ENTITY_SYSTEM, ActionForAlertTypes.EDIT)));
    createError(e);
  } finally {
    yield put(setLoading(false));
  }
}

function* systemIndexListFetch() {
  try {
    const data: Array<string> = yield call(requests.fetchSystemIndexList);
    yield put(setSystemIndexList(data));
  } catch (e) {
    createError(e);
  }
}

export function* systemSaga(): Generator<StrictEffect> {
  yield takeEvery(fetchSystem.type, currentSystemFetch);
  yield takeEvery(createSystem.type, systemCreate);
  yield takeEvery(editSystem.type, systemEdit);
  yield takeEvery(fetchSystemIndexList.type, systemIndexListFetch);
  yield takeEvery(addTicketTypes.type, editTicketTypes);
  yield takeEvery(addEnvironments.type, editEnvironment);
}
