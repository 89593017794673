import { FC, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { ActionList, getIsAccessedAction } from '@entities/auth';
import {
  fetchCurrentArticleRequestArticleCreate,
  getCurrentArticle,
  resetArticleCreateState,
} from '@entities/knowledge-base';
import { MainLayout } from '@shared';
import {
  KBCreateArticle,
  KBCreateArticleHeader,
  useArticleId,
  useCreateArticleForm,
} from '@widgets/knowledge-base';

interface KBCreateArticlePageProps {
  isEditMode?: boolean;
}

const KBCreateArticlePage: FC<KBCreateArticlePageProps> = ({
  isEditMode = false,
}) => {
  const dispatch = useDispatch();
  const articleId = useArticleId();
  const article = useSelector(getCurrentArticle);
  const isAccessToCreateTicket = useSelector(
    getIsAccessedAction(ActionList.CreateTicket)
  );

  useEffect(() => {
    if (articleId && isEditMode) {
      dispatch(fetchCurrentArticleRequestArticleCreate(articleId));
    }
  }, [articleId]);

  useEffect(
    () => () => {
      dispatch(resetArticleCreateState());
    },
    []
  );

  const data = useCreateArticleForm({ article, isEditMode });

  return (
    <MainLayout
      withHeader={false}
      accessToCreateTicket={isAccessToCreateTicket}
    >
      <KBCreateArticleHeader form={data} isEditMode={isEditMode} />
      <KBCreateArticle form={data} />
    </MainLayout>
  );
};

export default KBCreateArticlePage;
