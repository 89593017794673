import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { Ticket } from '@entities/ticket/model/types';
import { ResponseWithMeta } from '@shared';

import { TicketsStatistic } from '../types';

export interface CurrentReducerState {
  tickets?: Ticket[];
  totalTickets?: number;
  ticketsStatistic?: TicketsStatistic;
  isTicketsLoading?: boolean;
  sort?: string;
  pageNum?: number;
  pageSize?: number;
}

const initialState: CurrentReducerState = {
  tickets: [],
  sort: 'datecreate_DESC',
  pageNum: 0,
  pageSize: 10,
  isTicketsLoading: false,
};

const currentStateSlice = createSlice({
  name: 'desktopCurrentState',
  initialState,
  reducers: {
    fetchTicketsSuccessCurrentState: (
      state,
      action: PayloadAction<ResponseWithMeta<Ticket[]>>
    ) => {
      state.tickets = action.payload.content;
      state.totalTickets = action.payload.totalElements;
    },
    fetchTicketsRequestCurrentState: (_state) => {},
    fetchTicketsStatisticSuccessCurrentState: (
      state,
      action: PayloadAction<TicketsStatistic>
    ) => {
      state.ticketsStatistic = action.payload;
    },
    fetchTicketsStatisticRequestCurrentState: (_state) => {},
    setIsTicketsLoadingCurrentState: (
      state,
      action: PayloadAction<boolean>
    ) => {
      state.isTicketsLoading = action.payload;
    },
    setPageNumCurrentState: (
      state,
      action: PayloadAction<number | undefined>
    ) => {
      state.pageNum = action.payload;
    },
    setPageSizeCurrentState: (
      state,
      action: PayloadAction<number | undefined>
    ) => {
      state.pageSize = action.payload;
    },
    setSortCurrentState: (state, action: PayloadAction<string | undefined>) => {
      state.sort = action.payload;
    },
    resetCurrentState: () => initialState,
  },
});

export const {
  fetchTicketsStatisticSuccessCurrentState,
  fetchTicketsRequestCurrentState,
  fetchTicketsStatisticRequestCurrentState,
  fetchTicketsSuccessCurrentState,
  setIsTicketsLoadingCurrentState,
  setPageNumCurrentState,
  setPageSizeCurrentState,
  setSortCurrentState,
  resetCurrentState,
} = currentStateSlice.actions;
export const desktopCurrentState = currentStateSlice.reducer;
