import { Organization } from '@entities/organizations';
import { ResponseWithMeta, SelectOption } from '@shared';

import {
  Article,
  CategoryArticle,
  FetchArticlesParams,
  KBArticleTableFilterToRequest,
  KBOrganizationsFilter,
} from '../types';

export enum ArticlesMain {
  SET_FILTER_ARTICLES_MAIN = 'KB_MAIN/SET_FILTER_ARTICLES_MAIN',

  FETCH_REQUEST_ARTICLES_MAIN = 'KB_MAIN/FETCH_REQUEST_ARTICLES_MAIN',
  FETCH_SUCCESS_ARTICLES_MAIN = 'KB_MAIN/FETCH_SUCCESS_ARTICLES_MAIN',
  FETCH_ARTICLES_MAIN_JOIN_SUCCESS = 'KB_MAIN/FETCH_ARTICLES_MAIN_JOIN_SUCCESS',
  LOADING_SHOW_ARTICLES_MAIN = 'KB_MAIN/LOADING_SHOW_ARTICLES_MAIN',
  LOADING_HIDE_ARTICLES_MAIN = 'KB_MAIN/LOADING_HIDE_ARTICLES_MAIN',
  SET_ARTICLES_MAIN_PAGE = 'KB_MAIN/SET_ARTICLES_MAIN_PAGE',
  RESET_ARTICLES_STATE_ARTICLES_MAIN = 'KB_MAIN/RESET_ARTICLES_STATE_ARTICLES_MAIN',

  FETCH_DEFAULT_ARTICLES_MAIN_REQUEST = 'KB_MAIN/FETCH_DEFAULT_ARTICLES_MAIN_REQUEST',
  FETCH_DEFAULT_ARTICLES_MAIN_SUCCESS = 'KB_MAIN/FETCH_DEFAULT_ARTICLES_MAIN_SUCCESS',

  FETCH_ORGANIZATIONS_REQUEST_ARTICLES_MAIN = 'KB_MAIN/FETCH_ORGANIZATIONS_REQUEST_ARTICLES_MAIN',
  FETCH_ORGANIZATIONS_SUCCESS_ARTICLES_MAIN = 'KB_MAIN/FETCH_ORGANIZATIONS_SUCCESS_ARTICLES_MAIN',
  ORGANIZATIONS_LOADING_SHOW_ARTICLES_MAIN = 'KB_MAIN/ORGANIZATIONS_LOADING_SHOW_ARTICLES_MAIN',
  ORGANIZATIONS_LOADING_HIDE_ARTICLES_MAIN = 'KB_MAIN/ORGANIZATIONS_LOADING_HIDE_ARTICLES_MAIN',

  FETCH_CATEGORIES_REQUEST_ARTICLES_MAIN = 'KB_MAIN/FETCH_CATEGORIES_REQUEST_ARTICLES_MAIN',
  FETCH_CATEGORIES_SUCCESS_ARTICLES_MAIN = 'KB_MAIN/FETCH_CATEGORIES_SUCCESS_ARTICLES_MAIN',
  CATEGORIES_LOADING_SHOW_ARTICLES_MAIN = 'KB_MAIN/CATEGORIES_LOADING_SHOW_ARTICLES_MAIN',
  CATEGORIES_LOADING_HIDE_ARTICLES_MAIN = 'KB_MAIN/CATEGORIES_LOADING_HIDE_ARTICLES_MAIN',
  RESET_CATEGORIES_STATE_ARTICLES_MAIN = 'KB_MAIN/RESET_CATEGORIES_STATE_ARTICLES_MAIN',

  CREATE_CATEGORY_REQUEST_ARTICLES_MAIN = 'KB_MAIN/CREATE_CATEGORY_REQUEST_ARTICLES_MAIN',
  EDIT_CATEGORY_REQUEST_ARTICLES_MAIN = 'KB_MAIN/EDIT_CATEGORY_REQUEST_ARTICLES_MAIN',
  DELETE_CATEGORY_REQUEST_ARTICLES_MAIN = 'KB_MAIN/DELETE_CATEGORY_REQUEST_ARTICLES_MAIN',

  SET_ORGANIZATION_SELECTED_ARTICLES_MAIN = 'KB_MAIN/SET_ORGANIZATION_SELECTED_ARTICLES_MAIN',

  EDIT_ARTICLE_REQUEST_ARTICLES_MAIN = 'KB_MAIN/EDIT_ARTICLE_REQUEST_ARTICLES_MAIN',
  DELETE_ARTICLE_REQUEST_ARTICLES_MAIN = 'KB_MAIN/DELETE_ARTICLE_REQUEST_ARTICLES_MAIN',

  RESET_ARTICLES_MAIN_STATE = 'KB_MAIN/RESET_ARTICLE_CREATE_STATE',
}

export interface SetArticlesMainFilterAction {
  type: ArticlesMain.SET_FILTER_ARTICLES_MAIN;
  payload: KBArticleTableFilterToRequest;
}

export interface FetchArticlesMainRequestAction {
  type: ArticlesMain.FETCH_REQUEST_ARTICLES_MAIN;
  payload: FetchArticlesParams;
}

export interface FetchArticlesMainSuccessAction {
  type: ArticlesMain.FETCH_SUCCESS_ARTICLES_MAIN;
  payload: ResponseWithMeta<Article[]>;
}

export interface FetchArticlesMainJoinSuccessAction {
  type: ArticlesMain.FETCH_ARTICLES_MAIN_JOIN_SUCCESS;
  payload: ResponseWithMeta<Article[]>;
}

export interface SetArticlesMainPageAction {
  type: ArticlesMain.SET_ARTICLES_MAIN_PAGE;
  payload: number;
}

export interface ResetArticlesStateArticlesMainAction {
  type: ArticlesMain.RESET_ARTICLES_STATE_ARTICLES_MAIN;
}

export interface LoadingShowArticlesMainAction {
  type: ArticlesMain.LOADING_SHOW_ARTICLES_MAIN;
}

export interface LoadingHideArticlesMainAction {
  type: ArticlesMain.LOADING_HIDE_ARTICLES_MAIN;
}

export interface FetchDefaultArticlesMainRequestAction {
  type: ArticlesMain.FETCH_DEFAULT_ARTICLES_MAIN_REQUEST;
}

export interface FetchDefaultArticlesMainSuccessAction {
  type: ArticlesMain.FETCH_DEFAULT_ARTICLES_MAIN_SUCCESS;
  payload: Article[];
}

export interface FetchOrganizationsArticlesMainRequestAction {
  type: ArticlesMain.FETCH_ORGANIZATIONS_REQUEST_ARTICLES_MAIN;
  payload?: KBOrganizationsFilter;
}

export interface FetchOrganizationsArticlesMainSuccessAction {
  type: ArticlesMain.FETCH_ORGANIZATIONS_SUCCESS_ARTICLES_MAIN;
  payload: Organization[];
}

export interface OrganizationsArticlesMainLoadingShowAction {
  type: ArticlesMain.ORGANIZATIONS_LOADING_SHOW_ARTICLES_MAIN;
}

export interface OrganizationsArticlesMainLoadingHideAction {
  type: ArticlesMain.ORGANIZATIONS_LOADING_HIDE_ARTICLES_MAIN;
}

export interface FetchCategoriesRequestArticlesMainAction {
  type: ArticlesMain.FETCH_CATEGORIES_REQUEST_ARTICLES_MAIN;
  payload?: string;
}

export interface FetchCategoriesSuccessArticlesMainAction {
  type: ArticlesMain.FETCH_CATEGORIES_SUCCESS_ARTICLES_MAIN;
  payload: CategoryArticle[];
}

export interface CategoriesLoadingShowArticlesMainAction {
  type: ArticlesMain.CATEGORIES_LOADING_SHOW_ARTICLES_MAIN;
}

export interface CategoriesLoadingHideArticlesMainAction {
  type: ArticlesMain.CATEGORIES_LOADING_HIDE_ARTICLES_MAIN;
}

export interface CreateCategoryRequestArticlesMainAction {
  type: ArticlesMain.CREATE_CATEGORY_REQUEST_ARTICLES_MAIN;
  payload: CategoryArticle;
}

export interface EditCategoryRequestArticlesMainAction {
  type: ArticlesMain.EDIT_CATEGORY_REQUEST_ARTICLES_MAIN;
  payload: CategoryArticle;
}

export interface DeleteCategoryRequestArticlesMainAction {
  type: ArticlesMain.DELETE_CATEGORY_REQUEST_ARTICLES_MAIN;
  payload: string;
}

export interface ResetCategoriesStateArticlesMainAction {
  type: ArticlesMain.RESET_CATEGORIES_STATE_ARTICLES_MAIN;
}

export interface SetSelectedOrganizationArticlesMainAction {
  type: ArticlesMain.SET_ORGANIZATION_SELECTED_ARTICLES_MAIN;
  payload: SelectOption | null;
}

export interface EditArticleRequestArticlesMainAction {
  type: ArticlesMain.EDIT_ARTICLE_REQUEST_ARTICLES_MAIN;
  payload: Partial<Article>;
}

export interface DeleteArticleRequestArticlesMainAction {
  type: ArticlesMain.DELETE_ARTICLE_REQUEST_ARTICLES_MAIN;
  payload: string;
}

export interface ResetArticlesMainStateAction {
  type: ArticlesMain.RESET_ARTICLES_MAIN_STATE;
}

export type ArticlesMainActionsTypes =
  | SetArticlesMainFilterAction
  | FetchArticlesMainRequestAction
  | FetchArticlesMainSuccessAction
  | ResetArticlesStateArticlesMainAction
  | LoadingShowArticlesMainAction
  | LoadingHideArticlesMainAction
  | FetchDefaultArticlesMainRequestAction
  | FetchDefaultArticlesMainSuccessAction
  | FetchOrganizationsArticlesMainRequestAction
  | FetchOrganizationsArticlesMainSuccessAction
  | FetchArticlesMainJoinSuccessAction
  | SetArticlesMainPageAction
  | OrganizationsArticlesMainLoadingShowAction
  | OrganizationsArticlesMainLoadingHideAction
  | FetchCategoriesRequestArticlesMainAction
  | CategoriesLoadingHideArticlesMainAction
  | CategoriesLoadingShowArticlesMainAction
  | FetchCategoriesSuccessArticlesMainAction
  | CreateCategoryRequestArticlesMainAction
  | EditCategoryRequestArticlesMainAction
  | DeleteCategoryRequestArticlesMainAction
  | EditArticleRequestArticlesMainAction
  | SetSelectedOrganizationArticlesMainAction
  | DeleteArticleRequestArticlesMainAction
  | ResetCategoriesStateArticlesMainAction
  | ResetArticlesMainStateAction;
