import { v4 as uuidv4 } from 'uuid';

import { CustomConditions } from '@entities/triggers';
import { getConvertedTime } from '@shared';

import { CUSTOM_CONDITION_MAP } from '../../config';

export const getNormalizedCustomConditions = (
  customConditions: CustomConditions[]
) =>
  customConditions.map((conditions) => {
    const {
      customField,
      customFieldType,
      conditionType,
      minutes,
      flagValue,
      textValue,
    } = conditions || {};

    const value =
      flagValue ||
      textValue ||
      (minutes && getConvertedTime(minutes)) ||
      '00:00';

    return {
      id: uuidv4(),
      isActive: true,
      customField: {
        title: customField.value,
        value: customField.key,
        values: customField.values,
      },
      type: customFieldType,
      condition: {
        title: CUSTOM_CONDITION_MAP[conditionType],
        value: conditionType,
      },
      value,
    };
  });
