import { FC } from 'react';
import { Control, Controller, ControllerRenderProps } from 'react-hook-form';

import {
  Checkbox,
  CustomFieldType,
  Input,
  InputDatePicker,
  mapListToSelectOptions,
  Select,
} from '@shared';

import { CustomFieldTicket } from '../../model/ticket';
import { AbstractDataType } from '../../model/types';

import styles from './TicketCustomField.module.scss';

interface TicketCustomFieldProps {
  customField: CustomFieldTicket;
  control: Control<AbstractDataType>;
  disabled?: boolean;
  onChange?: () => void;
}

export const TicketCustomField: FC<TicketCustomFieldProps> = ({
  customField,
  control,
  disabled = false,
  onChange,
}) => {
  const { id, customFieldType, title, values } = customField;

  const getCustomFieldComponent = () => {
    if (!customFieldType) {
      return null;
    }

    const reactHookProps = {
      control,
      key: id,
      name: id || '',
    };

    const getCustomField = (
      field: ControllerRenderProps<AbstractDataType, string>
    ) => {
      const props = {
        key: id,
        label: title,
        disabled,
        className: styles.ticketCustomField,
      };

      const customFieldMap: Record<CustomFieldType, JSX.Element> = {
        [CustomFieldType.FIELD_TEXT]: (
          <Input
            {...props}
            value={field.value || ''}
            onChange={(value) => {
              field.onChange(value);
              onChange?.();
            }}
          />
        ),
        [CustomFieldType.FIELD_FLAG]: (
          <Checkbox
            {...props}
            checked={!!field.value}
            onChange={(value) => {
              field.onChange(value);
              onChange?.();
            }}
          />
        ),
        [CustomFieldType.FIELD_DATE]: (
          <InputDatePicker
            {...props}
            type="datePicker"
            placeholder={title}
            value={field.value || ''}
            onChange={(value) => {
              field.onChange(value);
              onChange?.();
            }}
          />
        ),
        [CustomFieldType.FIELD_LIST]: (
          <Select
            {...props}
            value={field.value ?? ''}
            options={mapListToSelectOptions(values ?? [])}
            onChange={(value) => {
              field.onChange(value);
              onChange?.();
            }}
          />
        ),
      };

      return customFieldMap[customFieldType];
    };

    return (
      <Controller
        {...reactHookProps}
        render={({ field }) => getCustomField(field)}
      />
    );
  };

  return getCustomFieldComponent();
};
