import { FC } from 'react';

import { Loader } from '@shared';

import styles from './Loading.module.scss';

export const Loading: FC = () => (
  <div className={styles.loading__wrapper}>
    <Loader />
  </div>
);
