import { useEffect } from 'react';
import { useDispatch } from 'react-redux';

import {
  fetchAttributesFieldsRequest,
  fetchFiltersFieldsRequest,
  fetchRulesRequest,
  fetchSpecialistsRequest,
  fetchWorkGroupsRequest,
  INITIAL_ATTRIBUTES_VALUES,
  resetRulesState,
} from '@entities/rules';
import { TablesLayout } from '@shared';

import { RulesTableContainer } from '../RulesTableContainer';

import styles from './Rules.module.scss';

const MainTable = () => <RulesTableContainer />;

const Rules = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchRulesRequest());
    dispatch(fetchFiltersFieldsRequest());
    dispatch(fetchAttributesFieldsRequest(INITIAL_ATTRIBUTES_VALUES));
    dispatch(fetchSpecialistsRequest());
    dispatch(fetchWorkGroupsRequest());
    return () => {
      dispatch(resetRulesState());
    };
  }, []);

  return (
    <TablesLayout
      MainTable={[MainTable]}
      classNameRoot={styles.rules}
      classNameSubTables={styles.rules__subTable}
      classNameMainTables={styles.rules__mainTable}
      SubTable={[]}
    />
  );
};

export default Rules;
