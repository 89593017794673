import { createBrowserHistory } from 'history';
import { FC } from 'react';
import { Router } from 'react-router-dom';
import { compose } from 'redux';

import { NotificationsLayout } from '@features/notifications/layouts';
import { AlertsLayout } from '@shared';

import { SidebarLayout } from '../layouts';
import { withRedirectPath, withScrollWindow } from '../providers';

import { SwitchElements } from './SwitchElements';

const history = createBrowserHistory();

const withRouterHistoryData = (Component: FC): FC =>
  compose<FC>(withRedirectPath, withScrollWindow)(Component);

const SwitchComponent = withRouterHistoryData(SwitchElements);

export const RouterHistory: FC = () => (
  <Router history={history}>
    <SidebarLayout>
      <NotificationsLayout>
        <AlertsLayout>
          <SwitchComponent />
        </AlertsLayout>
      </NotificationsLayout>
    </SidebarLayout>
  </Router>
);
