import { useDispatch, useSelector } from 'react-redux';

import {
  getIsError,
  getIsSuccess,
  getToastMessage,
  setIsError,
  setIsSuccess,
} from '@entities/ticket';

export const useTicketToast = () => {
  const dispatch = useDispatch();

  const isSuccess = useSelector(getIsSuccess);
  const isError = useSelector(getIsError);
  const toastMessage = useSelector(getToastMessage);

  const handleCloseSuccessToast = () => {
    dispatch(setIsSuccess(false));
  };

  const handleCloseErrorToast = () => {
    dispatch(setIsError(false));
  };

  return {
    isSuccess,
    isError,
    toastMessage,
    handleCloseSuccessToast,
    handleCloseErrorToast,
  };
};
