import cn from 'clsx';
import { MouseEvent, useState } from 'react';

import {
  CategoryArticle,
  useCanCreateArticleOrCategory,
} from '@entities/knowledge-base';
import {
  AddLineIcon,
  IconButton,
  Popover,
  Typography,
  TypographyVariants,
} from '@shared';

import { SelectActionsProps, SelectorProps, ValuesProps } from '../../model';
import { KBCreateCategory } from '../KBCategoryDrop/ui';
import { KBOrganizationSelect } from '../KBOrganizationSelect';

import styles from './KBCategoriesHeader.module.scss';

interface KBCategoriesHeaderProps<T> {
  clearWhenIsInputValue?: boolean;
  valuesProps: ValuesProps;
  selectorProps: SelectorProps<T>;
  actionsProps: SelectActionsProps;
  onCreateCategory: (data: Partial<CategoryArticle>) => void;
}

export const KBCategoriesHeader = <T,>(props: KBCategoriesHeaderProps<T>) => {
  const {
    clearWhenIsInputValue = false,
    onCreateCategory,
    ...selectProps
  } = props;
  const [isDropOpen, setIsDropOpen] = useState(false);
  const showCreateCategory = useCanCreateArticleOrCategory();
  const togglePopover = (event?: MouseEvent<HTMLButtonElement>) => {
    event?.preventDefault();
    setIsDropOpen((prev) => !prev);
  };

  const dropContent = (
    <KBCreateCategory
      onClick={onCreateCategory}
      togglePopover={togglePopover}
    />
  );

  return (
    <form className={styles.KBCategoriesHeader}>
      <div
        className={cn(styles.KBCategoriesHeader__header, {
          [styles.KBCategoriesHeader__header_withDrop]: showCreateCategory,
        })}
      >
        <Typography variant={TypographyVariants.h4}>Категории</Typography>
        <KBOrganizationSelect
          clearWhenIsInputValue={clearWhenIsInputValue}
          className={styles.KBCategoriesHeader__select}
          {...selectProps}
        />
      </div>
      {showCreateCategory && (
        <Popover
          content={dropContent}
          isOpen={isDropOpen}
          togglePopover={togglePopover}
          withOutsideClick={false}
          positions={['left', 'bottom', 'top', 'right']}
          popoverClassName={styles.KBCategoriesHeader__popover}
        >
          <IconButton
            icon={<AddLineIcon />}
            onClick={togglePopover}
            appearance="flat"
            className={styles.KBCategoriesHeader__popover_adaptive}
          />
        </Popover>
      )}
    </form>
  );
};
