import cn from 'clsx';
import {
  forwardRef,
  InputHTMLAttributes,
  LegacyRef,
  SVGProps,
  useMemo,
  useState,
} from 'react';
import MaskedInput, { ReactInputMask } from 'react-input-mask';

import { Size } from '@shared/model';

import { CloseIcon, EyeCloseIcon, EyeOpenIcon } from '../../assets';
import { IconButtonWrapper } from '../IconButtonWrapper';

import styles from './Input.module.scss';

export interface InputProps
  extends Omit<InputHTMLAttributes<HTMLInputElement>, 'size'> {
  size?: Size;
  label?: string;
  inputClassName?: string;
  labelClassName?: string;
  classNameContainer?: string;
  classNameErrorMessage?: string;
  error?: boolean;
  errorMessage?: string;
  mask?: string | (string | RegExp)[];
  isPassword?: boolean;
  rightIcon?: SVGProps<SVGElement>;
  isClearable?: boolean;
  onClear?: () => void;
}

// eslint-disable-next-line sonarjs/cognitive-complexity
export const Input = forwardRef<HTMLInputElement, InputProps>((props, ref) => {
  const {
    size = Size.m,
    label,
    inputClassName,
    classNameContainer,
    classNameErrorMessage,
    labelClassName,
    error,
    errorMessage,
    mask,
    isPassword,
    placeholder = ' ',
    rightIcon,
    onClear,
    type,
    className,
    ...otherProps
  } = props;

  const [isHidePassword, setIsHidePassword] = useState<boolean>(true);

  const toggleHidePassword = () => {
    setIsHidePassword(!isHidePassword);
  };

  const handleClear = () => {
    if (onClear) {
      onClear();
    }
  };

  const inputType = useMemo(() => {
    if (isPassword) {
      if (isHidePassword) {
        return 'password';
      }
      return 'text';
    }
    return type;
  }, [isPassword, isHidePassword]);

  const errorWarning = errorMessage && (
    <span
      className={cn(
        styles.input__errorMessage,
        styles[`input__error-message_${size}`],
        {
          [styles.input__errorMargin]: errorMessage,
          [styles[`input__error-margin_${size}`]]: errorMessage,
          classNameErrorMessage,
        }
      )}
    >
      {errorMessage}
    </span>
  );

  const eyeIcon = isHidePassword ? <EyeOpenIcon /> : <EyeCloseIcon />;

  const inputStyles = cn(
    styles.input__item,
    styles[`input__item_${size}`],
    {
      [styles.input__item_error]: error,
    },
    inputClassName
  );

  const maskedInput = mask && (
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    <MaskedInput
      mask={mask}
      ref={ref as LegacyRef<ReactInputMask>}
      type={inputType}
      placeholder={placeholder}
      className={inputStyles}
      {...otherProps}
    />
  );

  const defaultInput = (
    <input
      ref={ref}
      type={inputType}
      placeholder={placeholder}
      className={inputStyles}
      {...otherProps}
    />
  );

  const getInput = () => {
    if (mask) {
      return maskedInput;
    }
    return defaultInput;
  };

  const passwordIcon = isPassword && (
    <IconButtonWrapper
      onClick={toggleHidePassword}
      type="button"
      tabIndex={-1}
      className={styles.input__eyeButton}
      icon={eyeIcon}
    />
  );

  const clearIcon = onClear && (
    <div className={cn(styles.input__icon, styles.input__icon_clear)}>
      <CloseIcon onClick={handleClear} />
    </div>
  );

  const icon = !!rightIcon && (
    <div className={styles.input__icon}>{rightIcon}</div>
  );

  const iconsWrapper = (!!rightIcon || onClear) && (
    <div className={styles.input__iconsWrapper}>
      {clearIcon}
      {icon}
    </div>
  );

  return (
    <div className={cn(styles.input, styles[`input_${size}`], className)}>
      <div
        className={cn(
          styles.input__container,
          styles[`input__container_${size}`],
          classNameContainer
        )}
      >
        {getInput()}
        {passwordIcon}
        {iconsWrapper}
        <label
          className={cn(
            styles.input__label,
            styles[`input__label_${size}`],
            labelClassName
          )}
        >
          {label}
        </label>
      </div>
      {errorWarning}
    </div>
  );
});
