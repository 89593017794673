import { createSelector } from 'reselect';

import { Environment } from '@entities/environment/model/types';
import { System, SystemPriority } from '@entities/system/model/types';
import { DEFAULT_RADIO_TABS } from '@entities/ticket/config';
import { getArraySelectOptions, SelectOption, TabItem } from '@shared';

import { SystemsState } from './types';

export const getSystems = (state: SystemsState): System[] | undefined =>
  state.systems.systems;
export const getSystemsTickets = (state: SystemsState): System[] | undefined =>
  state.systems.systemsTickets;
export const getSystemsOrganization = (
  state: SystemsState
): System[] | undefined => state.systems.systemsOrganization;
export const getLoading = (state: SystemsState): boolean | undefined =>
  state.systems.loading;
export const getPropsSystems = (state: SystemsState) => ({
  pageNum: state.systems.pagination?.pageNum,
  pageSize: state.systems.pagination?.pageSize,
  sort: state.systems.sort,
  totalElements: state.systems.pagination?.totalElements,
  loadingSystem: getLoading(state),
});
export const getPropsSystemsTicket = (state: SystemsState) => ({
  pageNum: state.systems.paginationTicketsFilter?.pageNum,
  pageSize: state.systems.paginationTicketsFilter?.pageSize,
  sort: state.systems.sortSystemTickets,
  totalElements: state.systems.paginationTicketsFilter?.totalElements,
});
export const getSort = (state: SystemsState) => state.systems.sort;
export const getMySystems = (state: SystemsState) => state.systems.mySystems;
export const getSystemByContract = (state: SystemsState) =>
  state.systems.systemByContract;
export const getSystemId = (state: SystemsState) => state.systems.systemId;
export const getOrganizationSystemId = (state: SystemsState) =>
  state.systems.organizationSystemId;
export const getSystemsFilter = (state: SystemsState) =>
  state.systems.systemsFilter || {};
export const getSelectedSystem = (state: SystemsState) =>
  state.systems.selectedSystem;
export const getSystemPriorities = (state: SystemsState) =>
  state.systems.systemPriorities;
export const getSLASystems = (state: SystemsState) => state.systems.sla;
export const getEnvironments = (state: SystemsState) =>
  state.systems.environments;

export const getTicketSystemsSelectList = createSelector<
  SystemsState,
  System[] | undefined,
  SelectOption[]
>([getSystemsTickets], (systemsTickets): SelectOption[] =>
  getArraySelectOptions(systemsTickets)
);

export const getSystemsSelectList = createSelector<
  SystemsState,
  System[] | undefined,
  SelectOption[]
>([getSystems], (systems): SelectOption[] => getArraySelectOptions(systems));

export const getMySystemsSelectList = createSelector<
  SystemsState,
  System[] | undefined,
  SelectOption[]
>([getMySystems], (mySystems): SelectOption[] =>
  getArraySelectOptions(mySystems)
);

export const getPriorityByTitleRadioTabs = createSelector<
  SystemsState,
  SystemPriority[] | undefined,
  TabItem[]
>([getSystemPriorities], (priorities): TabItem[] =>
  DEFAULT_RADIO_TABS.map((tab) => {
    if (priorities?.find((priority) => priority.value === tab.value)) {
      return { ...tab, title: tab.value };
    }
    return {
      ...tab,
      title: tab.value,
      disabled: true,
    };
  })
);

export const getIsErrorNoData = (state: SystemsState) =>
  state.systems.isErrorNoData;
export const getSystem = (state: SystemsState) => state.systems.system;

export const getSystemsOrganizationSelectList = createSelector<
  SystemsState,
  System[] | undefined,
  SelectOption[]
>([getSystemsOrganization], (systemsOrganization): SelectOption[] =>
  getArraySelectOptions(systemsOrganization)
);

export const getEnvironmentsOptions = createSelector<
  SystemsState,
  Environment[] | undefined,
  SelectOption[]
>([getEnvironments], (environments): SelectOption[] =>
  getArraySelectOptions(environments)
);
