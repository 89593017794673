import cn from 'clsx';
import { FC, ReactNode } from 'react';
import { useTranslation } from 'react-i18next';

import { Languages } from '../../lib';

import styles from './ConditionBlock.module.scss';
import { getTicketTypeTextMap } from './lib';
import { ProcedureType } from './model';

export interface ConditionBlockProps {
  text?: string | ReactNode;
  ticketType?: ProcedureType;
  isSolution?: boolean;
  className?: string;
}

// TODO feature/tsp-2515  положить позже обратно в entities/ticket/ui
export const ConditionBlock: FC<ConditionBlockProps> = ({
  text,
  ticketType,
  isSolution,
  className,
}) => {
  const { i18n } = useTranslation();

  const title = ticketType
    ? // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      getTicketTypeTextMap(i18n.language as Languages)[ticketType]
    : text;

  return (
    <div
      className={cn(
        styles.conditionBlock,
        {
          [styles.conditionBlock_solution]: isSolution,
        },
        className
      )}
    >
      {title}
    </div>
  );
};
