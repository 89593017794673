import { useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { resetOrganizationState } from '@entities/organizations';
import { resetRolesState, TableRolesTypes } from '@entities/roles';
import { TableUsersTypes } from '@entities/user';
import { fetchUsersRequest, resetUsersState } from '@entities/users';
import { TableWorkGroupsTypes } from '@entities/work-group';
import { resetWorkGroupsState } from '@entities/work-groups';
import { RolesTableContainer } from '@features/roles';
import { UsersTableContainer } from '@features/users/ui';
import { WorkGroupsTableContainer } from '@features/work-groups';
import { TablesLayout } from '@shared';

import styles from './Users.module.scss';

const SubContractsTable = () => (
  <WorkGroupsTableContainer tableType={TableWorkGroupsTypes.FROM_USERS} />
);

const SubUsersTable = () => (
  <RolesTableContainer tableType={TableRolesTypes.FROM_USERS} />
);

const MainTable = () => (
  <UsersTableContainer tableType={TableUsersTypes.FULL} />
);

export const Users = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchUsersRequest());
    return () => {
      dispatch(resetUsersState());
      dispatch(resetWorkGroupsState());
      dispatch(resetRolesState());
      dispatch(resetOrganizationState());
    };
  }, []);

  return (
    <TablesLayout
      MainTable={[MainTable]}
      SubTable={[SubContractsTable, SubUsersTable]}
      classNameRoot={styles.users}
    />
  );
};
