import { isEmpty } from 'lodash';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';

import {
  createJiraIntegration,
  fetchJiraPrioritiesRequest,
  fetchJiraStatusesRequest,
  fetchJiraTicketTypeRequest,
  fetchSPStatusesRequest,
  fetchSystemPrioritiesRequest,
  fetchTicketTypeRequest,
  getJiraPriorities,
  getJiraPrioritiesOptions,
  getJiraStatuses,
  getJiraStatusesOptions,
  getJiraTicketTypesOptions,
  getSpStatuses,
  getSystemPriorities,
  getTicketTypesOptions,
  IssuePriorityCreateDtos,
  IssueStatusDtos,
  JiraConnectionStatus,
  JiraIntegrationCreateData,
  requests,
  resetJiraPriorities,
  resetJiraStatuses,
  resetSPStatuses,
  resetSystemPriorities,
} from '@entities/jira-integrations';
import {
  fetchOrganizationsRequest,
  getOrganizationsSelectList,
  OrganizationType,
  resetOrganizationState,
  setOrganizationsFilter,
  setSizePage,
} from '@entities/organizations';
import {
  fetchSystemsOrganizationForJiraRequest,
  fetchSystemsRequest,
  getSystemsOrganizationSelectList,
  resetSystemsState,
} from '@entities/systems';
import { Priority } from '@entities/ticket';
import {
  getTitleFromValueType,
  getValueFromValueType,
  ValueType,
} from '@shared';

import { DEFAULT_JIRA_INTEGRATION_FORM_VALUES } from '../../config/constants';
import { getPreparedDataForCreate } from '../utils';

interface UseJiraIntegrationCreateProps {
  toggleModal(): void;
}

export const useJiraIntegrationCreate = ({
  toggleModal,
}: UseJiraIntegrationCreateProps) => {
  const dispatch = useDispatch();

  const spStatuses = useSelector(getSpStatuses);
  const jiraStatuses = useSelector(getJiraStatuses);
  const jiraPriorities = useSelector(getJiraPriorities);
  const organizationsOptions = useSelector(getOrganizationsSelectList);
  const systemsOptions = useSelector(getSystemsOrganizationSelectList);
  const systemPriorities = useSelector(getSystemPriorities);
  const jiraPrioritiesOptions = useSelector(() =>
    getJiraPrioritiesOptions(jiraPriorities)
  );
  const jiraStatusesOptions = useSelector(() =>
    getJiraStatusesOptions(jiraStatuses)
  );
  const ticketTypesOptions = useSelector(getTicketTypesOptions);
  const jiraTicketTypesOptions = useSelector(getJiraTicketTypesOptions);

  const [jiraConnectionStatus, setJiraConnectionStatus] =
    useState<JiraConnectionStatus>(JiraConnectionStatus.DEFAULT);

  const isSuccessConnect =
    jiraConnectionStatus === JiraConnectionStatus.SUCCESS;
  const isErrorConnect = jiraConnectionStatus === JiraConnectionStatus.ERROR;

  const [statuses, setStatuses] = useState<IssueStatusDtos[]>([]);
  const [priorities, setPriorities] = useState<IssuePriorityCreateDtos[]>([]);

  const {
    control,
    register,
    formState: { isDirty, isValid, errors },
    watch,
    resetField,
    handleSubmit,
    setValue,
    trigger,
  } = useForm<JiraIntegrationCreateData>({
    mode: 'onChange',
    // TODO: jira test dev
    // login: 'svgri@list.ru',
    // password: 'FTHMeRBtqpyNepnZv1G2EDCD',
    // projectKey: 'XRFH',
    // jiraUrl: 'https://svgri.atlassian.net',
    defaultValues: DEFAULT_JIRA_INTEGRATION_FORM_VALUES,
  });

  const {
    login,
    password,
    jiraUrl,
    projectKey,
    organizationId,
    systemId,
    ticketTypeId,
    jiraTicketType,
  } = watch();

  const systemIdValue = getValueFromValueType(systemId);

  const isTicketTypeDisabled = !systemIdValue;
  const isJiraTicketTypeDisabled = isEmpty(ticketTypeId);
  const statusesDisabled = spStatuses?.length !== statuses.length;
  const prioritiesDisabled = systemPriorities?.length !== priorities.length;

  const disabledSubmit =
    !isDirty || !isValid || statusesDisabled || prioritiesDisabled;

  const nameInputOptions = register('name', {
    required: true,
  });

  const loginInputOptions = register('login', {
    required: true,
  });

  const passwordInputOptions = register('password', {
    required: true,
  });

  const jiraUrlInputOptions = register('jiraUrl', {
    required: true,
  });

  const projectKeyInputOptions = register('projectKey', {
    required: true,
  });

  const getIsPriorityAvailable = useCallback(
    (priority: Priority) =>
      !!systemPriorities?.find((el) => el.value === priority),
    [systemPriorities]
  );

  const spPriorities = Object.values(Priority).map((priority) => ({
    priority,
  }));

  const isCheckConnectionDisabled = useMemo(
    () => !login || !password || !jiraUrl || !projectKey || isSuccessConnect,
    [login, password, jiraUrl, projectKey, isSuccessConnect]
  );

  const checkConnectionData = useMemo(
    () => ({
      login,
      password,
      jiraUrl,
      projectKey,
    }),
    [login, password, jiraUrl, projectKey]
  );

  const checkConnectionHandler = useCallback(async () => {
    try {
      const isConnect = await requests.checkConnectionToJira(
        checkConnectionData
      );
      if (isConnect) {
        setJiraConnectionStatus(JiraConnectionStatus.SUCCESS);
        dispatch(fetchJiraTicketTypeRequest(checkConnectionData));
        dispatch(fetchJiraPrioritiesRequest(checkConnectionData));
        return;
      }
      setJiraConnectionStatus(JiraConnectionStatus.ERROR);
    } catch {
      setJiraConnectionStatus(JiraConnectionStatus.ERROR);
    }
  }, [login, password, jiraUrl, projectKey, setJiraConnectionStatus]);

  const onChangeStatuses = useCallback(
    (id: string) => (value: ValueType<number>) => {
      setStatuses((prevState) => {
        const result = jiraStatuses?.find(
          (item) => item.idStatus === getValueFromValueType(value)
        );
        const existingTicketStatusId = !!prevState?.find((item) =>
          item.ticketStatusIds.includes(id)
        );

        if (!existingTicketStatusId) {
          return [...prevState, { ...result, ticketStatusIds: [id] }];
        }

        return prevState.map((status) => {
          if (status.ticketStatusIds.includes(id)) {
            return { ...result, ticketStatusIds: [id] };
          }
          return status;
        });
      });
    },
    [setStatuses, jiraStatuses]
  );

  const onChangePriorities = useCallback(
    (priority: Priority) => (value: ValueType) => {
      setPriorities((prevState) => {
        const title = getTitleFromValueType(value) || '';
        const result = jiraPriorities?.find((item) => item.name === title);
        const existingPriority = !!prevState?.find((item) =>
          item?.ticketPriorities?.includes(priority)
        );

        if (!existingPriority) {
          return [...prevState, { ...result, ticketPriorities: [priority] }];
        }

        return prevState.map((item) => {
          if (item?.ticketPriorities?.includes(priority)) {
            return { ...result, ticketPriorities: [priority] };
          }
          return item;
        });
      });
    },
    [setPriorities, jiraPriorities]
  );

  useEffect(() => {
    dispatch(
      setOrganizationsFilter({
        organizationTypes: {
          title: OrganizationType.CUSTOMER,
          value: OrganizationType.CUSTOMER,
        },
      })
    );
    dispatch(setSizePage(1000));
    dispatch(fetchOrganizationsRequest());
    return () => {
      dispatch(resetOrganizationState());
      dispatch(resetSystemsState());
      dispatch(fetchSystemsRequest());
      dispatch(resetJiraStatuses());
      dispatch(resetJiraPriorities());
      dispatch(resetSystemPriorities());
      dispatch(resetSPStatuses());
    };
  }, []);

  useEffect(() => {
    if (organizationId && !Array.isArray(organizationId)) {
      dispatch(resetSystemsState());
      dispatch(resetSystemPriorities());
      dispatch(fetchSystemsOrganizationForJiraRequest(organizationId.value));
      dispatch(resetSPStatuses());
      resetField('systemId');
      resetField('ticketTypeId');
      resetField('jiraTicketType');
    }
  }, [organizationId]);

  useEffect(() => {
    if (systemIdValue) {
      dispatch(fetchSystemPrioritiesRequest(systemIdValue));
      dispatch(fetchTicketTypeRequest(systemIdValue));
      dispatch(resetSPStatuses());
      resetField('ticketTypeId');
      resetField('jiraTicketType');
    }
  }, [systemIdValue]);

  useEffect(() => {
    if (ticketTypeId || jiraTicketType) {
      setStatuses([]);
      setPriorities([]);
    }
  }, [ticketTypeId, jiraTicketType]);

  const fetchSPStatuses = (typeId: string) => {
    if (systemIdValue) {
      dispatch(fetchSPStatusesRequest({ systemId: systemIdValue, typeId }));
    }
  };

  const fetchJiraStatuses = (idType: string) => {
    dispatch(fetchJiraStatusesRequest({ ...checkConnectionData, idType }));
  };

  const createJiraIntegrationHandler = handleSubmit((data) => {
    const prepareData = getPreparedDataForCreate({
      data,
      statuses,
      priorities,
    });
    dispatch(createJiraIntegration(prepareData));
    toggleModal();
  });

  return {
    state: {
      organizationsOptions,
      systemsOptions,
      jiraPrioritiesOptions,
      jiraStatusesOptions,
      jiraConnectionStatus,
      isSuccessConnect,
      isErrorConnect,
      control,
      errors,
      nameInputOptions,
      loginInputOptions,
      passwordInputOptions,
      jiraUrlInputOptions,
      projectKeyInputOptions,
      isCheckConnectionDisabled,
      spPriorities,
      spStatuses,
      ticketTypeId,
      isTicketTypeDisabled,
      isJiraTicketTypeDisabled,
      jiraTicketTypesOptions,
      ticketTypesOptions,
      statuses,
      priorities,
      disabledSubmit,
    },
    methods: {
      checkConnectionHandler,
      createJiraIntegrationHandler,
      getIsPriorityAvailable,
      fetchSPStatuses,
      fetchJiraStatuses,
      setValue,
      resetField,
      trigger,
      onChangeStatuses,
      onChangePriorities,
    },
  };
};
