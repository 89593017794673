import cn from 'clsx';
import { FC } from 'react';

import { getFormatDisplayedTime, getMsToTime } from '@shared';

import { SLASize } from '../../model/types';

import styles from './SLATimer.module.scss';

interface SLATimerProps {
  size?: SLASize;
  timeLeft: number | null;
  timeAll: number;
  className?: string;
  isExpired?: boolean;
}

export const SLATimer: FC<SLATimerProps> = ({
  size = 'default',
  timeLeft,
  timeAll,
  className,
  isExpired,
}) => {
  const isSmall = size === 'small';

  const { hours, minutes, seconds } = getMsToTime(timeLeft);
  const time = getMsToTime(timeAll);

  return (
    <div
      className={cn(styles.slaTimer, className, {
        [styles.slaTimer_small]: isSmall,
      })}
    >
      <div
        className={cn(styles.slaTimer__wrapper, {
          [styles.slaTimer__wrapper_small]: isSmall,
        })}
      >
        <p
          className={cn(
            styles.slaTimer__element,
            styles.slaTimer__element_number,
            { [styles.slaTimer__element_small]: isSmall }
          )}
        >
          {getFormatDisplayedTime(hours)}
        </p>
        <span
          className={cn(
            styles.slaTimer__element,
            styles.slaTimer__element_colon,
            { [styles.slaTimer__element_small]: isSmall }
          )}
        >
          :
        </span>
        <p
          className={cn(
            styles.slaTimer__element,
            styles.slaTimer__element_number,
            { [styles.slaTimer__element_small]: isSmall }
          )}
        >
          {getFormatDisplayedTime(minutes)}
        </p>
        <span
          className={cn(
            styles.slaTimer__element,
            styles.slaTimer__element_colon,
            { [styles.slaTimer__element_small]: isSmall }
          )}
        >
          :
        </span>
        <p
          className={cn(
            styles.slaTimer__element,
            styles.slaTimer__element_number,
            { [styles.slaTimer__element_small]: isSmall }
          )}
        >
          {getFormatDisplayedTime(seconds)}
        </p>
      </div>
      {!isExpired && (
        <span
          className={cn(styles.slaTimer__allTime, {
            [styles.slaTimer__allTime_small]: isSmall,
          })}
        >{`из ${getFormatDisplayedTime(
          Math.trunc(timeAll / 3600000)
        )} : ${getFormatDisplayedTime(time.minutes)}`}</span>
      )}
    </div>
  );
};
