import { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
  ActionList,
  getIsAccessedAction,
  getUserOrganization,
} from '@entities/auth';
import {
  deleteArticleRequestArticleCreate,
  fetchCurrentArticleRequestArticleViewing,
  getCurrentArticleArticleViewing,
  getCurrentArticleLoadingArticleViewing,
  getOrgByUserOrgId,
  resetStateArticleViewing,
  useCanCreateArticleOrCategory,
} from '@entities/knowledge-base';
import { useIsAccessEditCategoryOrArticle } from '@features/knowledge-base';
import { KBArticleDrop } from '@features/knowledge-base/ui';
import {
  CalendarIcon,
  DeleteApproveOrCancel,
  getFormatDateWithYear,
  LazyLoader,
  MainLayout,
  Tooltip,
  Typography,
  TypographyVariants,
  UserIcon,
  UsersGroupIcon,
} from '@shared';
import { KBArticle, useArticleId } from '@widgets/knowledge-base';

import styles from './KBArticle.module.scss';

const KBArticlePage = () => {
  const dispatch = useDispatch();

  const article = useSelector(getCurrentArticleArticleViewing);
  const loading = useSelector(getCurrentArticleLoadingArticleViewing);
  const userOrganization = useSelector(getUserOrganization);

  const isAccessToCreateTicket = useSelector(
    getIsAccessedAction(ActionList.CreateTicket)
  );

  const [isModal, setIsModal] = useState(false);

  const toggleModal = () => {
    setIsModal((prevState) => !prevState);
  };

  const articleId = useArticleId();

  const canEditArticle = useIsAccessEditCategoryOrArticle(
    article?.organizations
  );
  const canCreateArticle = useCanCreateArticleOrCategory();

  useEffect(() => {
    if (articleId) {
      dispatch(fetchCurrentArticleRequestArticleViewing(articleId));
    }
    return () => {
      dispatch(resetStateArticleViewing());
    };
  }, [articleId]);

  const onArticleDelete = (id: string) => {
    dispatch(deleteArticleRequestArticleCreate(id));
  };

  const handleDelete = () => {
    if (article?.id && onArticleDelete) {
      onArticleDelete(article.id);
    }
  };

  const titleComponent = (
    <>
      <Typography
        component="span"
        variant={TypographyVariants.h2}
        className={styles.KBArticle__title}
        data-for="article-title"
        data-tip
      >
        {article?.title}
      </Typography>
      {article?.title && article?.title.length > 20 && (
        <Tooltip id="article-title">{article?.title}</Tooltip>
      )}
    </>
  );

  const infoBlocksConfig = useMemo(
    () => [
      {
        title: 'Организация',
        info: getOrgByUserOrgId({
          canCreateArticle,
          organizations: article?.organizations,
          userOrganization,
        }),
        icon: <UsersGroupIcon className={styles.KBArticle__headerIcon} />,
      },
      {
        title: 'Создатель',
        info: article?.userFullName,
        icon: <UserIcon className={styles.KBArticle__headerIcon} />,
      },
      {
        title: 'Редактирован',
        info: article?.updated
          ? getFormatDateWithYear(article?.updated?.toString())
          : '',
        icon: <CalendarIcon className={styles.KBArticle__headerIcon} />,
      },
    ],
    [article]
  );

  const content = loading ? <LazyLoader /> : <KBArticle article={article} />;

  return (
    <>
      <MainLayout
        title={titleComponent}
        withHeader
        loading={loading}
        withGoBack
        headerType="withExtraInfo"
        infoBlocksConfig={infoBlocksConfig}
        classNameRoot={styles.KBArticle}
        dropContent={
          canEditArticle ? (
            <KBArticleDrop
              article={article}
              withEditArticle
              withDeleteArticle
              toggleModal={toggleModal}
            />
          ) : undefined
        }
        accessToCreateTicket={isAccessToCreateTicket}
      >
        {content}
      </MainLayout>
      <DeleteApproveOrCancel
        isModal={isModal}
        toggleModal={toggleModal}
        entityTitle="статью"
        onApprove={handleDelete}
      />
    </>
  );
};

export default KBArticlePage;
