import { Editor as EditorType } from '@ckeditor/ckeditor5-core';
import { ChangeEvent, FC, ReactNode } from 'react';
import { Action, AnyAction, Reducer } from 'redux';

import { ValueType } from '../ui/Select';

import { CoreReducerState } from './slice';

export type CoreState = { core: CoreReducerState };

export type ButtonType = 'default' | 'flat' | 'outline';

export type TabItem<Value = string> = {
  title?: string;
  value: Value;
  checked?: boolean;
  defaultChecked?: boolean;
  classNameTab?: string;
  disabled?: boolean;
  onClick?: () => void;
  icon?: ReactNode;
  readOnly?: boolean;
};

export enum Size {
  xxs = 'xxs',
  xs = 'xs',
  s = 's',
  m = 'm',
  l = 'l',
}

export type Orientation = 'vertical' | 'horizontal';

export type Nullable<T> = T | null;

export type NString = Nullable<string>;

export type NNumber = Nullable<number>;

export type NBoolean = Nullable<boolean>;

export enum ScreenWidth {
  MOBILE_SMALL = 'MOBILE_SMALL',
  MOBILE = 'MOBILE',
  TABLET_SMALL = 'TABLET_SMALL',
  TABLET = 'TABLET',
  DESKTOP_SMALL = 'DESKTOP_SMALL',
  DESKTOP = 'DESKTOP',
}

export enum Theme {
  LIGHT = 'light',
  DARK = 'dark',
  LIGHT_CONTRAST = 'light-contrast',
  DARK_CONTRAST = 'dark-contrast',
}

export enum ThemeActive {
  ON = 'on',
  OFF = 'off',
}

export type ReducerMap<State, Actions extends Action = AnyAction> = Record<
  string,
  Reducer<State, Actions>
>;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type ObjectType = Record<string | number, any>;

export interface ResponseWithMeta<Content> {
  elements: number;
  page: number;
  totalElements: number;
  totalPages: number;
  content: Content;
  size?: number;
}

export type KeyValueOption<K = string, V = string> = {
  readonly key: K;
  readonly value: V;
};

export type DeleteDataType = {
  id: string;
  withRedirect?: boolean;
};

type CKEditorProps = {
  editor: EditorType;
  data: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  config: any;
  onReady: (editor: EditorType) => void;
  onChange: (event: ChangeEvent<HTMLInputElement>, editor: EditorType) => void;
};

export type EditorRefType = {
  CKEditor: FC<CKEditorProps>;
  Editor: EditorType;
};

export type WsStatus = {
  connecting: boolean;
  open: boolean;
  closing: boolean;
  closed: boolean;
  uninstantiated: boolean;
};

// TODO feature/tsp-2515 положить позже обратно в entities/custom-fields
// ---------
export enum CustomFieldType {
  FIELD_TEXT = 'TEXT',
  FIELD_DATE = 'DATE',
  FIELD_FLAG = 'FLAG',
  FIELD_LIST = 'LIST',
}

export type ValueCustomField = {
  text: NString;
  date: NString;
  flag: NBoolean;
  listValue?: NString;
  values?: Nullable<string[]>;
};

export type CustomFieldListOption<T> = Omit<T, 'listValue'> & {
  listValue?: ValueType<string>;
};
// ---------
