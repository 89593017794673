import cn from 'clsx';
import {
  type ChangeEvent,
  forwardRef,
  type HTMLAttributes,
  useMemo,
  useRef,
} from 'react';
import { v4 as uuidv4 } from 'uuid';

import { AttachmentsIcon } from '../../assets/icons';
import { Button } from '../Button';
import { createBemClass } from '../helpers/createBemClass';
import { useDragAndDrop } from '../hooks/useDragAndDrop';
import { useMergeRefs } from '../hooks/useMergeRefs';
import { Spin } from '../Spin';
import { Typography } from '../Typography';
import { TypographyLink } from '../TypographyLink';

import styles from './AttachFiles.module.scss';
import {
  type MultipleAttachFilesProps,
  type SingleAttachFilesProps,
} from './types';
import { ListFiles } from './ui';

export type AttachFilesProps = {
  loading?: boolean;
  accept?: string;
  onCancel?: VoidFunction;
  hideFilesList?: boolean;
  required?: boolean;
} & (SingleAttachFilesProps | MultipleAttachFilesProps) &
  Omit<HTMLAttributes<HTMLInputElement>, 'type' | 'id' | 'onChange' | 'value'>;

const rootClassName = createBemClass('attach-files');

export const AttachFiles = forwardRef<HTMLInputElement, AttachFilesProps>(
  (
    {
      className,
      multiple,
      loading = false,
      value,
      onChange,
      onCancel,
      hideFilesList = false,
      ...other
    },
    ref
    // eslint-disable-next-line sonarjs/cognitive-complexity
  ) => {
    const inputId = uuidv4();

    const inputRef = useRef<HTMLInputElement>(null);
    const mergeRef = useMergeRefs([inputRef, ref]);

    const listFiles = useMemo(() => {
      if (multiple) {
        return value ?? [];
      }

      return value ? [value] : [];
    }, [multiple, value]);

    const clearInput = () => {
      if (inputRef.current) {
        inputRef.current.value = '';
      }
    };

    const changeValue = (newFiles?: File[] | FileList | null) => {
      if (multiple) {
        if (newFiles) {
          onChange?.([...(value ?? []), ...newFiles]);
        } else {
          onChange?.();
        }
      } else {
        onChange?.(newFiles?.[0]);
      }
    };

    const { isDragActive, rootEvents } = useDragAndDrop({
      onDrop: (e) => {
        changeValue(e.dataTransfer.files);
      },
    });

    const onChangeInput = (e: ChangeEvent<HTMLInputElement>) => {
      changeValue(e.target.files);

      clearInput();
    };

    const onDeleteFile = ({ name }: File) => {
      if (multiple) {
        onChange?.(value?.filter((file) => file.name !== name));
      } else {
        changeValue();
      }

      clearInput();
    };

    const loadingElements = useMemo(
      () => (
        <>
          <Spin
            appearance="outline"
            className={styles[rootClassName({ elementName: 'spinner' })]}
            size="xs"
          />
          <Typography
            className={styles[rootClassName({ elementName: 'legend' })]}
            variant="h5"
          >
            Идет загрузка файлов
          </Typography>
          {onCancel && (
            <Button
              appearance="outline"
              className={
                styles[
                  rootClassName({
                    elementName: 'cancel-button',
                  })
                ]
              }
              onClick={onCancel}
              size="xs"
              variety="secondary"
            >
              Остановить загрузку
            </Button>
          )}
        </>
      ),
      [onCancel]
    );

    const defaultElements = useMemo(
      () => (
        <>
          <AttachmentsIcon
            className={styles[rootClassName({ elementName: 'icon' })]}
          />
          <Typography
            className={styles[rootClassName({ elementName: 'legend' })]}
            variant="h5"
          >
            Перетащите файл{multiple ? '-ы' : ''} сюда
          </Typography>
          <Typography
            className={styles[rootClassName({ elementName: 'text' })]}
          >
            или{' '}
            <TypographyLink
              as="label"
              className={styles[rootClassName({ elementName: 'label' })]}
              htmlFor={inputId}
              variant="l2"
            >
              нажмите для загрузки {multiple ? 'файлов' : 'файла'}
            </TypographyLink>
          </Typography>
        </>
      ),
      [inputId, multiple]
    );

    return (
      <div className={cn(styles[rootClassName()], className)}>
        <div
          {...rootEvents}
          className={cn(styles[rootClassName({ elementName: 'drag-zone' })], {
            [styles[
              rootClassName({
                elementName: 'drag-zone',
                modName: 'drag-over',
              })
            ]]: isDragActive,
          })}
        >
          <div className={styles[rootClassName({ elementName: 'wrapper' })]}>
            {loading ? loadingElements : defaultElements}
          </div>

          <input
            {...other}
            ref={mergeRef}
            className={styles[rootClassName({ elementName: 'input' })]}
            id={inputId}
            multiple={multiple}
            onChange={onChangeInput}
            type="file"
          />
        </div>
        {!hideFilesList && value && (
          <ListFiles
            files={listFiles}
            loading={loading}
            onDelete={onDeleteFile}
          />
        )}
      </div>
    );
  }
);
