import { FC, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';

import { setBreadCrumbsConfig } from '@shared';

import { RouterConfig } from '../../routes/types';

import { getBreadCrumbsConfig } from './utils';

interface BreadCrumbsLayoutProps {
  routes: RouterConfig[];
  props: RouteComponentProps<{ [p: string]: string | undefined }>;
}

export const BreadCrumbsLayout: FC<BreadCrumbsLayoutProps> = ({
  children,
  routes,
  props,
}) => {
  const dispatch = useDispatch();

  const breadCrumbsConfig = getBreadCrumbsConfig(routes, props);

  useEffect(() => {
    dispatch(setBreadCrumbsConfig(breadCrumbsConfig));
  }, [breadCrumbsConfig]);

  return <>{children}</>;
};
