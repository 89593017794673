import { KeyValueOption } from '@shared/model';

export const getKeyValueFromIdTitle = <
  T extends Array<{ id?: string; title?: string }>
>(
  array?: T
) =>
  array?.map(
    (item): KeyValueOption => ({
      key: item?.id ?? '',
      value: item?.title ?? '',
    })
  ) || [];
