import { Priority } from '@entities/ticket/model/types';

import {
  fetchTicketStatusJoinSuccess,
  fetchTicketStatusUpdateSuccess,
  fetchTicketTypesJoinSuccess,
  fetchTicketTypesUpdateSuccess,
} from '../model/actions';

export const fetchTicketTypeSuccessMap = {
  update: fetchTicketTypesUpdateSuccess,
  join: fetchTicketTypesJoinSuccess,
};

export const fetchTicketStatusSuccessMap = {
  update: fetchTicketStatusUpdateSuccess,
  join: fetchTicketStatusJoinSuccess,
};

export const PRIORITY_TITLE_MAP = {
  [Priority.NONE]: 'Без приоритета',
  [Priority.LOW]: 'Низкий приоритет',
  [Priority.MIDDLE]: 'Средний приоритет',
  [Priority.HIGH]: 'Высокий приоритет',
  [Priority.VERY_HIGH]: 'Критический приоритет',
};

export const PRIORITY_TITLE_MAP_EN = {
  [Priority.NONE]: 'Without priority',
  [Priority.LOW]: 'Low priority',
  [Priority.MIDDLE]: 'Middle priority',
  [Priority.HIGH]: 'High priority',
  [Priority.VERY_HIGH]: 'Very high priority',
};

export const DEFAULT_SORT_FILTER_TYPES = 'TITLE_ASC';
export const DEFAULT_SORT_FILTER_STATUSES = 'NAME_ASC';
