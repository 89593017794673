import { FC, memo, useCallback, useMemo } from 'react';

import { TicketsStatistic } from '@entities/desktop';
import { Priority } from '@entities/ticket';
import { ChartBlock } from '@shared';

import { NO_DATA_GRAPH } from '../../config';

const DEFAULT_TITLE = 'Активные тикеты по приоритетам';

interface PieChartPriorityProps {
  className?: string;
  title?: string;
  ticketsStatistic?: TicketsStatistic;
}

export const PieChartPriority: FC<PieChartPriorityProps> = memo(
  ({ className, title = DEFAULT_TITLE, ticketsStatistic }) => {
    const getTicketsCount = useCallback(
      (type: Priority) => {
        const count = Number(
          ticketsStatistic?.priority?.find((el) => el.key === type)?.value
        );
        return count || 0;
      },
      [ticketsStatistic?.priority]
    );

    const dataGraph = useMemo(
      () =>
        [
          {
            name: 'Без приоритета',
            y: getTicketsCount(Priority.NONE),
            color: 'var(--grey90)',
          },
          {
            name: 'Низкий',
            y: getTicketsCount(Priority.LOW),
            color: 'var(--green120)',
          },
          {
            name: 'Средний',
            y: getTicketsCount(Priority.MIDDLE),
            color: 'var(--yellow100)',
          },
          {
            name: 'Высокий',
            y: getTicketsCount(Priority.HIGH),
            color: 'var(--orange110)',
          },
          {
            name: 'Критический',
            y: getTicketsCount(Priority.VERY_HIGH),
            color: 'var(--red120)',
          },
        ].filter((data) => data.y),
      [getTicketsCount, ticketsStatistic?.priority]
    );

    const currentDataGraph = dataGraph.length ? dataGraph : NO_DATA_GRAPH;

    return (
      <ChartBlock
        chartType="pieChart"
        title={title}
        dataChart={currentDataGraph}
        className={className}
      />
    );
  }
);
