import cn from 'clsx';
import { FC } from 'react';

import styles from './Date.module.scss';

interface DateProps {
  className?: string;
  date: Date;
  onClick: () => void;
  isCurrentMonthDate: boolean;
  isWeekend: boolean;
  isToday: boolean;
  isPicked: boolean;
  isInRange: boolean;
  isFirstInRange: boolean;
  isLastInRange: boolean;
  isInApproximateRange: boolean;
  isFirstInApproximateRange: boolean;
  isLastInApproximateRange: boolean;
  onMouseEnter: () => void;
}

export const Date: FC<DateProps> = ({
  className,
  date,
  onClick,
  isCurrentMonthDate,
  isWeekend,
  isToday,
  isPicked,
  isInRange,
  isFirstInRange,
  isLastInRange,
  isInApproximateRange,
  isFirstInApproximateRange,
  isLastInApproximateRange,
  onMouseEnter,
}) => (
  <button
    type="button"
    className={cn(
      styles.date,
      { [styles.date_inRange]: isInRange },
      { [styles.date_firstInRange]: isFirstInRange },
      { [styles.date_lastInRange]: isLastInRange },
      {
        [styles.date_inApproximateRange]: isInApproximateRange,
      },
      {
        [styles.date_inApproximateRange_first]: isFirstInApproximateRange,
      },
      {
        [styles.date_inApproximateRange_last]: isLastInApproximateRange,
      }
    )}
    onMouseEnter={onMouseEnter}
    onClick={onClick}
  >
    <div
      className={cn(styles.dateWrapper, className, {
        [styles.dateWrapper_today]: isToday,
        [styles.dateWrapper_picked]: isPicked,
      })}
    >
      <div
        className={cn(styles.date__typography, {
          [styles.date__typography_currentMonth]: isCurrentMonthDate,
          [styles.date__typography_weekend]: isWeekend,
          [styles.date__typography_picked]: isPicked,
        })}
      >
        {date.getDate()}
      </div>
    </div>
  </button>
);
