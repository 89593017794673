import { TicketsExportState } from './types';

export const getIsExportPanelOpen = (state: TicketsExportState) =>
  state.ticketsExport.isExportPanelOpen;
export const getExportFields = (state: TicketsExportState) =>
  state.ticketsExport.exportFields;
export const getTicketsCount = (state: TicketsExportState) =>
  state.ticketsExport.ticketsCount;
export const getTicketsRequestData = (state: TicketsExportState) =>
  state.ticketsExport.ticketsRequestData;
export const getIsDownloadingExcel = (state: TicketsExportState) =>
  state.ticketsExport.isDownloadingExcel;
export const getSystems = (state: TicketsExportState) =>
  state.ticketsExport.systems;
export const getSystemsLoading = (state: TicketsExportState) =>
  state.ticketsExport.systemsLoading;
export const getCustomFields = (state: TicketsExportState) =>
  state.ticketsExport.customFields;
export const getCustomFieldsLoading = (state: TicketsExportState) =>
  state.ticketsExport.customFieldsLoading;
export const getSelectedCustomFields = (state: TicketsExportState) =>
  state.ticketsExport.selectedCustomFields;
export const getExtraExportFields = (state: TicketsExportState) =>
  state.ticketsExport.extraExportFields;
