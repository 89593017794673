import { Note } from '../attachment';

import {
  DeleteNoteRequestAction,
  FetchNotesByTicketIdRequestAction,
  FetchNotesByTicketIdSuccessAction,
  NoteCreateRequestAction,
  NoteLoadingHideAction,
  NoteLoadingShowAction,
  Notes,
  ResetNotesStateAction,
  UpdateNoteRequestAction,
} from './actionTypes';
import { CreateNoteData } from './types';

export const createNoteRequest = (
  note: CreateNoteData
): NoteCreateRequestAction => ({
  type: Notes.NOTE_CREATE_REQUEST,
  payload: note,
});

export const updateNoteRequest = (note: Note): UpdateNoteRequestAction => ({
  type: Notes.NOTE_UPDATE_REQUEST,
  payload: note,
});

export const deleteNoteRequest = (noteId: string): DeleteNoteRequestAction => ({
  type: Notes.NOTE_DELETE_REQUEST,
  payload: noteId,
});

export const noteLoadingShow = (): NoteLoadingShowAction => ({
  type: Notes.NOTE_LOADING_SHOW,
});

export const noteLoadingHide = (): NoteLoadingHideAction => ({
  type: Notes.NOTE_LOADING_HIDE,
});

export const fetchNotesByTicketIdRequest = (
  ticketId: string
): FetchNotesByTicketIdRequestAction => ({
  type: Notes.FETCH_NOTES_BY_TICKET_ID_REQUEST,
  payload: ticketId,
});

export const fetchNotesByTicketIdSuccess = (
  notes: Note[]
): FetchNotesByTicketIdSuccessAction => ({
  type: Notes.FETCH_NOTES_BY_TICKET_ID_SUCCESS,
  payload: notes,
});

export const resetNotesState = (): ResetNotesStateAction => ({
  type: Notes.RESET_NOTES_STATE,
});
