import { FC } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { ActionList, getIsAccessedAction } from '@entities/auth';
import { getLoading as getResponsibilitiesLoading } from '@entities/responsibilities';
import { getLoading as getRolesLoading } from '@entities/roles';
import { getLoading as getUsersLoading } from '@entities/users';
import {
  TableWorkGroupsTypes,
  WorkGroup,
  WorkGroupFilter,
} from '@entities/work-group';
import {
  fetchWorkGroupsRequest,
  getLoading,
  setCurrentWorkGroupsPage,
  setWorkGroupsFilter,
  WorkGroupsFilter,
} from '@entities/work-groups';
import { Table } from '@shared';

import { WORK_GROUPS_FIELDS_FULL } from '../../config';
import { getDataTable } from '../../lib';

interface WorkGroupsFullTableProps {
  workGroups?: WorkGroup[];
  sort?: string;
  handleSort?: (value: string) => void;
  handleRowClick?: (value: ReturnType<typeof getDataTable>[number]) => void;
  activeRowId?: string;
  showFilterRow?: boolean;
  isFilterActive?: boolean;
}

export const WorkGroupsFullTable: FC<WorkGroupsFullTableProps> = ({
  workGroups,
  sort,
  handleSort,
  handleRowClick,
  activeRowId,
  showFilterRow,
  isFilterActive,
}) => {
  const loading = useSelector(getLoading);
  const responsibilitiesLoading = useSelector(getResponsibilitiesLoading);
  const rolesLoading = useSelector(getRolesLoading);
  const usersLoading = useSelector(getUsersLoading);
  const isAccessToViewingCompany = useSelector(
    getIsAccessedAction(ActionList.ViewingCompanyAdministration)
  );

  const dispatch = useDispatch();

  const data = getDataTable({ workGroups, isAccessToViewingCompany });

  const handleFilter = (filterData: WorkGroupFilter) => {
    dispatch(setCurrentWorkGroupsPage(0));
    dispatch(setWorkGroupsFilter(filterData));
    dispatch(fetchWorkGroupsRequest());
  };

  return (
    <Table
      data={data}
      columns={WORK_GROUPS_FIELDS_FULL}
      onRowClick={handleRowClick}
      sort={sort}
      onSort={handleSort}
      activeRowId={activeRowId}
      dataLoading={loading}
      isFilterActive={isFilterActive}
      filterComponent={
        showFilterRow && (
          <WorkGroupsFilter
            tableType={TableWorkGroupsTypes.FULL}
            onFilter={handleFilter}
          />
        )
      }
      disabledSort={loading}
      disabledRows={rolesLoading || responsibilitiesLoading || usersLoading}
    />
  );
};
