import cn from 'clsx';
import { FC, useRef, useState } from 'react';

import { PlusIcon, SearchIcon } from '@shared/assets';
import { Size } from '@shared/model';
import { Button } from '@shared/ui/Button';
import { Popover } from '@shared/ui/Popover';
import { RollUpButton } from '@shared/ui/RollUpButton';
import { Tab, Tabs } from '@shared/ui/Tabs';
import { ToggleButton } from '@shared/ui/ToggleButton';

import styles from './CardTableHeader.module.scss';

export interface CardTableHeaderProps {
  toggleSearch?: () => void;
  isSearchOpen?: boolean;
  tabs?: Tab[];
  dropContent?: JSX.Element;
  title?: string | JSX.Element;
  createHandler?: () => void;
  createTitle?: string | JSX.Element;
  tableOpened?: boolean;
  handleTableOpen?: (value: boolean) => void;
  additionalComponent?: JSX.Element;
  endComponent?: JSX.Element;
  icon?: JSX.Element;
  createDisabled?: boolean;
  searchDisabled?: boolean;
  dropDisabled?: boolean;
  headerElement?: JSX.Element;
  className?: string;
  classNameTitle?: string;
  loading?: boolean;
  tabsDisabled?: boolean;
  isWithOutsideClick?: boolean;
}

export const CardTableHeader: FC<Omit<CardTableHeaderProps, 'loading'>> = ({
  toggleSearch,
  isSearchOpen,
  tabs,
  dropContent,
  title,
  createHandler,
  createTitle = 'Создать',
  tableOpened = true,
  handleTableOpen,
  additionalComponent,
  icon,
  createDisabled = false,
  searchDisabled,
  dropDisabled,
  className,
  classNameTitle,
  endComponent,
  headerElement,
  tabsDisabled,
  isWithOutsideClick,
}) => {
  const ref = useRef<HTMLDivElement>(null);

  const handleOpenTable = () => {
    if (handleTableOpen) {
      handleTableOpen(!tableOpened);
    }
  };

  const [isPopoverOpen, setIsPopoverOpen] = useState<boolean>(false);

  const togglePopover = () => {
    setIsPopoverOpen((prevState) => !prevState);
  };

  const popover = dropContent && (
    <Popover
      disabled={dropDisabled}
      className={styles.cardTableHeader__drop}
      isOpen={isPopoverOpen}
      togglePopover={togglePopover}
      content={dropContent}
      positions={['left', 'bottom']}
      align="start"
      withOutsideClick={!isWithOutsideClick}
    />
  );

  return (
    <div
      className={cn(styles.cardTableHeader, className, {
        [styles.cardTableHeader_noTabs]: !tabs && !title,
        [styles.cardTableHeader_withBoldLine]: tabs,
        [styles.cardTableHeader_withTitle]: title,
        [styles.cardTableHeader_close]: !tableOpened,
      })}
    >
      {title && (
        <div className={styles.cardTableHeader__titleBlockContainer}>
          <div className={styles.cardTableHeader__titleBlock}>
            <RollUpButton
              isOpen={tableOpened}
              toggleButton={handleOpenTable}
              className={styles.cardTableHeader__titleBtn}
            />
            <h2
              className={cn(styles.cardTableHeader__tableTitle, classNameTitle)}
            >
              {title}
            </h2>
            <div className={styles.cardTableHeader__additionalComponent}>
              {additionalComponent}
            </div>
          </div>
          {headerElement}
        </div>
      )}
      {tabs && (
        <Tabs
          tabs={tabs}
          className={styles.cardTableHeader__tabs}
          tabsRef={ref}
          disabled={tabsDisabled}
        />
      )}
      <div className={styles.cardTableHeader__tools}>
        {createHandler && (
          <Button
            appearance="flat"
            size={Size.xs}
            onClick={createHandler}
            icon={
              icon || <PlusIcon className={styles.cardTableHeader__plusIcon} />
            }
            disabled={createDisabled}
            className={styles.cardTableHeader__createButton}
          >
            {createTitle}
          </Button>
        )}
        {toggleSearch && (
          <ToggleButton
            icon={<SearchIcon />}
            onClick={toggleSearch}
            isActive={isSearchOpen}
            disabled={searchDisabled}
            className={cn(styles.cardTableHeader__search, {
              [styles.cardTableHeader__search_mr]: !dropContent,
            })}
          />
        )}
        {popover}
        {endComponent}
      </div>
    </div>
  );
};
