import { FC, useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';

import {
  createFieldsIntegration,
  deleteFieldsIntegration,
  editFieldsIntegration,
  FieldsIntegration,
  FieldType,
  FormData,
  getJiraFieldsSelectList,
  getSpFields,
  getSpFieldsSelectList,
  getTypeId,
} from '@entities/jira-integrations';
import {
  getTitleFromValueType,
  getValueFromValueType,
  Select,
  SelectOption,
  Size,
  Typography,
  TypographyVariants,
} from '@shared';

import { JiraIntegrationsListElement } from '../JiraIntegrationsListElement';

import styles from './JiraIntegrationsFieldsIntegrations.module.scss';

interface JiraIntegrationsFieldsIntegrationsProps {
  className?: string;
  isAddDisabled: boolean;
  closeEditHandler(): void;
  fieldsIntegrations?: FieldsIntegration[];
}

export const JiraIntegrationsFieldsIntegrations: FC<
  JiraIntegrationsFieldsIntegrationsProps
> = ({ className, isAddDisabled, closeEditHandler, fieldsIntegrations }) => {
  const [spFieldType, setSpFieldType] = useState<FieldType>();

  const spFieldsOptions = useSelector(getSpFieldsSelectList);
  const spFields = useSelector(getSpFields);
  const jiraFieldsOptions = useSelector(getJiraFieldsSelectList(spFieldType));
  const typeId = useSelector(getTypeId);

  const dispatch = useDispatch();

  const {
    control,
    resetField,
    watch,
    setValue,
    reset,
    formState: { isDirty, isValid },
  } = useForm<FormData>({
    mode: 'onChange',
    defaultValues: {
      spField: null,
      jiraField: null,
    },
  });

  const { spField, jiraField } = watch();

  const spFieldValue = getValueFromValueType(spField);
  const spFieldTitle = getTitleFromValueType(spField);
  const jiraFieldValue = getValueFromValueType(jiraField);
  const jiraTitleConverted = getTitleFromValueType(jiraField);

  useEffect(() => {
    if (spFieldValue) {
      const selectedSpField = spFields?.find(
        (field: SelectOption) => field.value === spFieldValue
      );
      if (selectedSpField) {
        setSpFieldType(selectedSpField?.fieldType);
      }
    }
  }, [spFieldValue]);

  const fieldsIntegrationsList = fieldsIntegrations?.map(
    ({
      id,
      ticketColumn,
      ticketColumnTitle,
      jiraFieldKey,
      jiraFieldTitle,
      systemId,
      fieldType,
      // eslint-disable-next-line sonarjs/cognitive-complexity
    }) => {
      const isIdenticalData =
        spFieldValue === ticketColumn && jiraFieldValue === jiraFieldKey;

      const initialSpFieldOption = {
        title: ticketColumnTitle || '',
        value: ticketColumn || '',
      };

      const initialJiraFieldOption = {
        title: jiraFieldTitle || '',
        value: jiraFieldKey || '',
      };

      const onSuccessEdit = () => {
        dispatch(
          editFieldsIntegration({
            id,
            ticketColumn: spFieldValue,
            ticketColumnTitle:
              spFieldValue === ticketColumn ? ticketColumnTitle : spFieldTitle,
            jiraFieldKey: jiraFieldValue,
            jiraFieldTitle:
              jiraFieldValue === jiraFieldKey
                ? jiraFieldTitle
                : jiraTitleConverted,
            systemId,
            fieldType,
          })
        );
      };

      return (
        <JiraIntegrationsListElement
          key={id}
          leftElement={
            <Typography
              variant={TypographyVariants.b3}
              className={styles.jiraIntegrationsFieldsIntegrations__text}
            >
              {initialSpFieldOption?.title}
            </Typography>
          }
          leftElementEdit={
            <Controller
              control={control}
              name="spField"
              rules={{
                required: true,
                onChange: () => resetField('jiraField'),
              }}
              render={({ field }) => (
                <Select
                  size={Size.xs}
                  mobileModalTitle="поле"
                  options={spFieldsOptions}
                  onChange={field.onChange}
                  value={field.value}
                  className={styles.jiraIntegrationsFieldsIntegrations__select}
                />
              )}
            />
          }
          rightElement={
            <Typography
              variant={TypographyVariants.b3}
              className={styles.jiraIntegrationsFieldsIntegrations__text}
            >
              {initialJiraFieldOption?.title}
            </Typography>
          }
          rightElementEdit={
            <Controller
              control={control}
              name="jiraField"
              rules={{ required: true }}
              render={({ field }) => (
                <Select
                  size={Size.xs}
                  mobileModalTitle="поле"
                  options={jiraFieldsOptions}
                  onChange={field.onChange}
                  value={field.value}
                  disabled={!spField}
                  className={styles.jiraIntegrationsFieldsIntegrations__select}
                />
              )}
            />
          }
          isEditable
          isSuccessEditDisabled={!isDirty || !isValid || isIdenticalData}
          onEditModeOn={() => {
            if (initialSpFieldOption.title && initialSpFieldOption.value) {
              setValue('spField', initialSpFieldOption);
            }
            if (initialJiraFieldOption.title && initialJiraFieldOption.value) {
              setValue('jiraField', initialJiraFieldOption);
            }
            setSpFieldType(fieldType);
          }}
          onDelete={() => {
            if (id) {
              dispatch(deleteFieldsIntegration(id));
            }
          }}
          onSuccessEdit={onSuccessEdit}
        />
      );
    }
  );

  const onCreate = () => {
    dispatch(
      createFieldsIntegration({
        ticketColumn: spFieldValue,
        ticketColumnTitle: spFieldTitle,
        jiraFieldKey: jiraFieldValue,
        jiraFieldTitle: jiraTitleConverted,
        fieldType: spFieldType,
        typeId,
      })
    );
    closeEditHandler();
  };

  const fieldsIntegrationCreate = isAddDisabled && (
    <JiraIntegrationsListElement
      leftElementEdit={
        <Controller
          control={control}
          name="spField"
          rules={{ required: true, onChange: () => resetField('jiraField') }}
          render={({ field }) => (
            <Select
              size={Size.xs}
              label="Выберите поле"
              mobileModalTitle="поле"
              key="spField"
              options={spFieldsOptions}
              onChange={field.onChange}
              value={field.value}
              className={styles.jiraIntegrationsFieldsIntegrations__select}
            />
          )}
        />
      }
      rightElementEdit={
        <Controller
          control={control}
          name="jiraField"
          rules={{ required: true }}
          render={({ field }) => (
            <Select
              size={Size.xs}
              mobileModalTitle="поле"
              options={jiraFieldsOptions}
              onChange={field.onChange}
              value={field.value}
              className={styles.jiraIntegrationsFieldsIntegrations__select}
              disabled={!spField}
            />
          )}
        />
      }
      isEditable
      initialIsEdit
      onCloseEdit={closeEditHandler}
      onSuccessEdit={onCreate}
      isSuccessEditDisabled={!isDirty || !isValid}
      onEditModeOn={() => {
        reset();
      }}
    />
  );
  return (
    <div className={className}>
      {fieldsIntegrationsList}
      {fieldsIntegrationCreate}
    </div>
  );
};
