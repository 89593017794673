import {
  convertFromDateFormat,
  convertToDateFormat,
  SelectOption,
} from '@shared';

import { EnableFields, UseFormExportData } from '../../model';

type TicketsRequestForm = {
  data: UseFormExportData;
  enableFields: EnableFields;
  customFields?: string[];
};

export const getTicketsRequestData = ({
  data,
  enableFields,
  customFields,
}: TicketsRequestForm) => {
  const {
    status,
    priority,
    description,
    solutions,
    sla,
    ticketType,
    environment,
    system,
    performer,
    workgroup,
    client,
    organization,
    dateCreation,
    dateSolve,
    sources,
    customFields: enableCustomFields,
  } = enableFields;

  const currentMultiSelectValue = (fields?: Array<SelectOption>) =>
    fields?.map((field) => field.value);

  const currentSelectValue = (value?: string) =>
    value === undefined ? undefined : [value];

  return {
    status: {
      enable: status,
      value: currentMultiSelectValue(data.status),
    },
    priority: {
      enable: priority,
      value: currentMultiSelectValue(data.priority),
    },
    description: {
      enable: description,
    },
    solutions: {
      enable: solutions,
    },
    sla: {
      enable: sla,
      value: currentSelectValue(data.sla?.value),
    },
    ticketType: {
      enable: ticketType,
      value: currentMultiSelectValue(data.ticketType),
    },
    environment: {
      enable: environment,
      value: currentMultiSelectValue(data.environment),
    },
    system: {
      enable: system,
      value: currentMultiSelectValue(data.system),
    },
    performer: {
      enable: performer,
      value: currentMultiSelectValue(data.performer),
    },
    workgroup: {
      enable: workgroup,
      value: currentMultiSelectValue(data.workgroup),
    },
    client: {
      enable: client,
      value: currentMultiSelectValue(data.client),
    },
    organization: {
      enable: organization,
      value: currentMultiSelectValue(data.organization),
    },
    dateCreation: {
      enable: dateCreation,
      from: convertFromDateFormat(data.dateCreation),
      to: convertToDateFormat(data.dateCreation),
    },
    dateSolve: {
      enable: dateSolve,
      from: convertFromDateFormat(data.dateSolve),
      to: convertToDateFormat(data.dateSolve),
    },
    sources: {
      enable: sources,
      value: currentMultiSelectValue(data.sources),
    },
    customFields: {
      enable: enableCustomFields,
      value: customFields,
    },
  };
};
