import { FC, MouseEvent, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Attachment, ParentType } from '@entities/attachment';
import { getUserFirstName, getUserLastName } from '@entities/auth';
import { MAX_FILE_SIZE_TEXT } from '@entities/create-ticket';
import { addAttachmentsRequest } from '@entities/ticket';
import {
  Create,
  createErrorAlert,
  FileInput,
  MAX_FILE_SIZE,
  setAlert,
} from '@shared';

import styles from './AddAttachmentsModal.module.scss';

interface AddAttachmentsModalProps {
  isModal: boolean;
  toggleModal: () => void;
  contractId?: string;
}

export const AddAttachmentsModal: FC<AddAttachmentsModalProps> = ({
  isModal,
  toggleModal,
  contractId,
}) => {
  const dispatch = useDispatch();

  const [files, setFiles] = useState<FormData | null>(null);
  const [myFiles, setMyFiles] = useState<File[]>([]);

  const firstName = useSelector(getUserFirstName);
  const lastName = useSelector(getUserLastName);

  const setFileHandler = (acceptedFiles: File[]) => {
    const formData = new FormData();
    acceptedFiles.forEach((file) => {
      formData.append('file', new Blob([file]), encodeURI(file.name));
    });
    setFiles(formData);
  };

  const handleSubmit = (event: MouseEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (!files?.entries().next().done && contractId && files) {
      const attachmentsData: Omit<Attachment, 'id' | 'attachmentsFiles'> = {
        parentId: contractId,
        parentType: ParentType.CONTRACT,
        systemFile: 'test',
        userFirstName: firstName,
        userLastName: lastName,
      };
      files.append(
        'objects-attachments',
        new Blob([JSON.stringify(attachmentsData)], {
          type: 'application/json',
        })
      );
      if (files?.has('file')) {
        dispatch(addAttachmentsRequest({ attachment: files, id: contractId }));
      }
    }
    toggleModal();
  };

  const onErrorMaxFileSize = () => {
    dispatch(setAlert(createErrorAlert(MAX_FILE_SIZE_TEXT)));
  };

  return (
    <Create
      isModal={isModal}
      title="Новое вложение"
      createTitle="Создать"
      toggleModal={toggleModal}
      onSubmit={handleSubmit}
      subModalText="создание вложения"
      disabledSubmit={!myFiles.length}
    >
      <FileInput
        fileHandler={setFileHandler}
        myFiles={myFiles}
        setMyFiles={setMyFiles}
        maxFileSize={MAX_FILE_SIZE}
        onErrorMaxFileSize={onErrorMaxFileSize}
        classNameContent={styles.addAttachmentsModal__fileInputContent}
        classNameWrapper={styles.addAttachmentsModal__fileInputWrapper}
      />
    </Create>
  );
};
