import { debounce } from 'lodash';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
  DEFAULT_DEBOUNCE_DELAY,
  Select,
  SelectProps,
  useInfiniteScroll,
} from '@shared';

import {
  DataForRequest,
  fetchOrganizationsAddRequest,
  fetchOrganizationsRequest,
  getOrganizationsAddSelectList,
  getOrganizationsSelectList,
  getPropsOrganizations,
  getPropsOrganizationsAdd,
  OrganizationActionsType,
  OrganizationsFilter,
  OrganizationsState,
  resetOrganizationAddState,
  resetOrganizationOnlyState,
  setCurrentOrganizationsAddPage,
  setCurrentOrganizationsPage,
  setOrganizationsAddFilterAction,
  setOrganizationsFilter,
  setSortOrganizations,
  setSortOrganizationsAdd,
} from '../../model';

const actionsAddList: OrganizationActionsType<OrganizationsState> = {
  setPage: setCurrentOrganizationsAddPage,
  setFilter: setOrganizationsAddFilterAction,
  fetchData: fetchOrganizationsAddRequest,
  setSort: setSortOrganizationsAdd,
  resetData: resetOrganizationAddState,
  getList: getOrganizationsAddSelectList,
  getPropsData: getPropsOrganizationsAdd,
};

const actionsList: OrganizationActionsType<OrganizationsState> = {
  setPage: setCurrentOrganizationsPage,
  setFilter: setOrganizationsFilter,
  fetchData: fetchOrganizationsRequest,
  setSort: setSortOrganizations,
  resetData: resetOrganizationOnlyState,
  getList: getOrganizationsSelectList,
  getPropsData: getPropsOrganizations,
};

interface OrganizationSelectProps<T> extends Omit<SelectProps, 'options'> {
  addOrgState?: boolean;
  customFilters?: OrganizationsFilter;
  customActions?: OrganizationActionsType<T>;
  dataForRequest?: DataForRequest;
}

export const OrganizationSelect = <T,>(props: OrganizationSelectProps<T>) => {
  const {
    addOrgState = true,
    customFilters = {},
    customActions,
    dataForRequest,
    ...other
  } = props;
  const { organizationTypes } = dataForRequest || {};

  const dispatch = useDispatch();

  const getActions = () => {
    if (customActions) {
      return customActions;
    }
    return addOrgState ? actionsAddList : actionsList;
  };
  //  TODO убрать as OrganizationActionsType<T>
  const actions = getActions() as OrganizationActionsType<T>;

  const {
    totalElements,
    pageNum = 0,
    pageSize,
    loadingOrganization,
  } = useSelector(actions.getPropsData);

  const organizationSelectList = useSelector(actions.getList);

  const { totalPage } = useInfiniteScroll({
    pageNum,
    pageSize,
    totalElements,
  });

  const onFilterOrganization = debounce((valueFilter: string) => {
    dispatch(actions.setPage(0));
    dispatch(
      actions.setFilter({
        ...customFilters,
        title: valueFilter,
        organizationTypes,
      })
    );
    dispatch(actions.fetchData({ updateType: 'update', organizationTypes }));
  }, DEFAULT_DEBOUNCE_DELAY);

  const setNextPageOrg = () => {
    if (!loadingOrganization && totalPage > pageNum) {
      dispatch(actions.setPage(pageNum + 1));
      dispatch(actions.fetchData({ updateType: 'join', organizationTypes }));
    }
  };

  useEffect(() => {
    dispatch(actions.setPage(0));
    dispatch(actions.setFilter({ ...customFilters, organizationTypes }));
    if (actions.setSort) {
      dispatch(actions.setSort('TITLE_ASC'));
    }
    dispatch(actions.fetchData({ updateType: 'update', organizationTypes }));
    return () => {
      dispatch(actions.resetData());
    };
  }, []);

  const selectProps = {
    ...other,
    options: organizationSelectList,
    onChangeInput: onFilterOrganization,
    isSearchable: true,
    currentPage: pageNum,
    totalPage,
    setNextPage: setNextPageOrg,
    loading: loadingOrganization,
    infiniteScrollable: true,
    mobileModalTitle: 'организацию',
  };

  return <Select<string> {...selectProps} />;
};
