import { SLARowType, SLASettings, SLATableDataType } from '@entities/sla';
import { Priority } from '@entities/ticket';
import { convertedTimeToMinutes } from '@shared';

const prepareTime = (value?: string | number) => {
  if (value) {
    return Number.isNaN(Number(value)) ? convertedTimeToMinutes(value) : value;
  }
  return 0;
};

const prepareSettings = (data: SLASettings): SLASettings => ({
  ...data,
  decisionTime: prepareTime(data?.decisionTime),
  reactionTime: prepareTime(data?.reactionTime),
});

const prepareRow = (row: SLARowType) => {
  const newRow: SLARowType = {};
  Object.keys(row).forEach((ticketType) => {
    newRow[ticketType] = prepareSettings(row[ticketType]);
  });
  return newRow;
};

export const prepareSLATableForRequest = (sla: SLATableDataType) => {
  const result = {} as SLATableDataType;
  Object.keys(sla).forEach((priority) => {
    result[priority as Priority] = prepareRow(sla[priority as Priority]);
  });
  return result;
};
