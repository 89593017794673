import { RenderTypes, TableColumns } from '@shared';

export const WORK_GROUPS_FIELDS_FULL: TableColumns = [
  {
    title: 'Название',
    fieldName: 'title',
    sortField: 'title',
    renderType: RenderTypes.NOTIFICATION_LINK,
  },
  {
    title: 'Организация',
    fieldName: 'organizationId',
    sortField: 'organization',
  },
  { title: 'Описание', fieldName: 'description', sortField: 'description' },
];
