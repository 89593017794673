import { FC, useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';

import { AccessSwitchForm } from '@entities/accesses';
import {
  fetchActionsCreateRequest,
  getActionsCreate,
  resetActionsCreate,
  setActionsForEditResp,
} from '@entities/actions';
import { getIsClient, getUserOrganization } from '@entities/auth';
import { OrganizationSelect } from '@entities/organizations';
import {
  createResponsibility,
  CreateResponsibilityType,
  getCurrentResponsibility,
  getCurrentResponsibilityId,
  updateResponsibility,
} from '@entities/responsibilities';
import { Create, Input, TextArea } from '@shared';

import { actionsTransform } from '../../lib';

import styles from './ResponsibilityCreate.module.scss';

interface ResponsibilityCreateProps {
  isModal: boolean;
  toggleModal(): void;
  isEditMode?: boolean;
}

export const ResponsibilityCreate: FC<ResponsibilityCreateProps> = ({
  isModal,
  toggleModal,
  isEditMode,
}) => {
  const closeModal = () => {
    toggleModal();
  };
  const actionsNotSorted = useSelector(getActionsCreate) || [];
  const isClient = useSelector(getIsClient);
  const organization = useSelector(getUserOrganization);
  const responsibility = useSelector(getCurrentResponsibility);
  const responsibilityId = useSelector(getCurrentResponsibilityId);

  const getInitialOrganizationOption = () => {
    if (isClient && organization) {
      return {
        title: organization?.title || '',
        value: organization?.id || '',
      };
    }
    if (isEditMode && responsibility) {
      return {
        title: responsibility.organization?.title || '',
        value: responsibility.organization?.id || '',
      };
    }
    return null;
  };

  const modalTitle = isEditMode
    ? 'Редактировать полномочие'
    : 'Создать полномочие';

  const {
    register,
    handleSubmit,
    reset,
    control,
    watch,
    setValue,
    formState: { errors, isDirty, isValid },
  } = useForm<CreateResponsibilityType>({
    mode: 'onChange',
  });

  const { organizationId } = watch();

  const dispatch = useDispatch();

  const titleInputOptions = register('title', {
    required: true,
    maxLength: {
      value: 100,
      message: 'Название полномочия не может быть длиннее 100 символов.',
    },
  });

  const descriptionTextAreaOptions = register('description', {
    required: true,
    maxLength: {
      value: 255,
      message: 'Описание полномочия не может быть длиннее 255-ти символов.',
    },
  });

  const formSubmitHandlerCreate = handleSubmit((data) => {
    dispatch(
      createResponsibility({
        ...data,
        actionList: [...actionsTransform(actionsNotSorted)],
      })
    );
    closeModal();
    reset();
  });

  const formSubmitHandlerEdit = handleSubmit((data) => {
    if (responsibility) {
      const { id, title, description, organization: org } = responsibility;
      const preparedData = {
        id,
        title,
        description,
        organizationId: { value: org?.id || '', title: org?.title || '' },
        ...data,
      };
      delete preparedData?.actionList;
      dispatch(
        updateResponsibility({
          actions: [...actionsTransform(actionsNotSorted)],
          responsibilityData: preparedData,
        })
      );
    }

    closeModal();
    reset();
  });

  useEffect(() => {
    if (organizationId && !Array.isArray(organizationId) && !isEditMode) {
      dispatch(fetchActionsCreateRequest(organizationId.value));
    }
  }, [organizationId, isEditMode]);

  useEffect(() => {
    setValue('organizationId', getInitialOrganizationOption());
  }, []);

  useEffect(() => {
    if (isEditMode && responsibility) {
      setValue('title', responsibility?.title);
      setValue('description', responsibility.description);
      setValue('organizationId', getInitialOrganizationOption());
    }
  }, [isEditMode, responsibility]);

  useEffect(() => {
    if (responsibilityId && isEditMode) {
      dispatch(setActionsForEditResp());
    }
  }, [responsibilityId, isEditMode]);

  useEffect(
    () => () => {
      dispatch(resetActionsCreate());
    },
    []
  );

  return (
    <Create
      toggleModal={toggleModal}
      isModal={isModal}
      title={modalTitle}
      createTitle={isEditMode ? 'Сохранить' : 'Создать'}
      onSubmit={isEditMode ? formSubmitHandlerEdit : formSubmitHandlerCreate}
      disabledSubmit={isEditMode ? false : !isDirty || !isValid}
      subModalText={
        isEditMode ? 'редактирование полномочия' : 'создание полномочия'
      }
    >
      <div className={styles.responsibilityCreate__form}>
        <Input
          {...titleInputOptions}
          label="Название"
          error={!!errors.title}
          errorMessage={errors.title?.message}
        />
        <Controller
          control={control}
          name="organizationId"
          key="organizationId"
          rules={{ required: true }}
          render={({ field }) => (
            <OrganizationSelect
              onChange={field.onChange}
              placeholder="Организация"
              value={field?.value}
              disabled={isClient || isEditMode}
              addOrgState={false}
            />
          )}
        />
        <TextArea
          {...descriptionTextAreaOptions}
          label="Описание"
          error={!!errors.description}
          errorMessage={errors.description?.message}
        />
        <AccessSwitchForm actionsNotSorted={actionsNotSorted} />
      </div>
    </Create>
  );
};
