import { isString } from 'lodash';

import { CustomConditionType } from '@entities/triggers';
import { convertedTimeToMinutes, CustomFieldType } from '@shared';

export const getCustomValue = (
  type: CustomFieldType | null,
  conditionType: CustomConditionType,
  value: string | number | boolean | null
) => {
  const getMinutes = () => {
    if (type !== CustomFieldType.FIELD_DATE) {
      return null;
    }

    if (
      value === '00:00' ||
      (value === null &&
        (conditionType === CustomConditionType.MINUTES_BEFORE_DATE ||
          conditionType === CustomConditionType.MINUTES_AFTER_DATE))
    ) {
      return 0;
    }

    return isString(value) ? convertedTimeToMinutes(value) : null;
  };

  const getTextValueByType = (): string | null =>
    (type === CustomFieldType.FIELD_TEXT ||
      type === CustomFieldType.FIELD_LIST) &&
    isString(value)
      ? value
      : null;

  return {
    minutes: getMinutes(),
    flagValue: type === CustomFieldType.FIELD_FLAG ? true : null,
    textValue: getTextValueByType(),
    listValue: getTextValueByType(),
  };
};
