import cn from 'clsx';
import {
  FC,
  MouseEventHandler,
  ReactElement,
  useEffect,
  useState,
} from 'react';

import { BackArrowIcon, CloseIcon } from '../../assets';
import { ApproveOrCancel } from '../ApproveOrCancelModal';
import { Button } from '../Button';
import { IconButtonWrapper } from '../IconButtonWrapper';
import { Portal } from '../Portal';

import styles from './AddEntityModal.module.scss';

interface AddEntityModalProps {
  toggleModal(): void;
  isModal: boolean;
  title: string;
  disabledSubmit: boolean;
  onSubmit?: MouseEventHandler<HTMLButtonElement>;
  subModalText?: string;
  className?: string;
  classNameForm?: string;
  classNameFormPortal?: string;
  classNameFormPortalCancel?: string;
  isChanging?: boolean;
  saveTitle?: string;
  bottomElement?: ReactElement;
  classNameButtonsBlock?: string;
  withHideBodyScroll?: boolean;
  withHideBodyScrollSubModal?: boolean;
}

export const AddEntityModal: FC<AddEntityModalProps> = ({
  children,
  toggleModal,
  isModal,
  title,
  disabledSubmit,
  onSubmit,
  subModalText,
  className,
  classNameForm,
  classNameFormPortal,
  classNameFormPortalCancel,
  isChanging = false,
  saveTitle = 'Добавить',
  bottomElement,
  classNameButtonsBlock,
  withHideBodyScroll = true,
  withHideBodyScrollSubModal = true,
}) => {
  const [isSubModal, setIsSubModal] = useState<boolean>(false);

  const closeModal = () => {
    toggleModal();
  };

  const toggleSubModal = () => {
    setIsSubModal(!isSubModal);
  };

  const onApproveSubModal = () => {
    closeModal();
    toggleSubModal();
  };

  useEffect(() => {
    if (withHideBodyScroll) {
      if (isModal || isSubModal) {
        document.body.style.overflow = 'hidden';
        return;
      }
      document.body.style.overflow = 'visible';
    }
  }, [isModal, isSubModal, withHideBodyScroll]);

  useEffect(
    () => () => {
      if (withHideBodyScroll) {
        document.body.style.overflow = 'visible';
      }
    },
    [withHideBodyScroll]
  );

  if (!isModal) return null;

  return (
    <Portal>
      <div
        className={cn(styles.addEntityModal, classNameFormPortal)}
        onMouseDown={toggleSubModal}
        aria-hidden="true"
      >
        <div
          className={cn(styles.addEntityModal__content, className)}
          onMouseDown={(e) => e.stopPropagation()}
          aria-hidden="true"
        >
          <div className={styles.addEntityModal__header}>
            <IconButtonWrapper
              onClick={toggleSubModal}
              icon={<BackArrowIcon className={styles.addEntityModal__arrow} />}
              className={cn(
                styles.addEntityModal__button,
                styles.addEntityModal__button_left
              )}
            />
            <h4 className={styles.addEntityModal__title}>{title}</h4>
            <IconButtonWrapper
              onClick={toggleSubModal}
              icon={<CloseIcon className={styles.addEntityModal__cross} />}
              className={cn(
                styles.addEntityModal__button,
                styles.addEntityModal__button_right
              )}
            />
          </div>
          <div className={cn(styles.addEntityModal__form, classNameForm)}>
            {children}
            <div
              className={cn(
                styles.addEntityModal__buttonsBlock,
                classNameButtonsBlock
              )}
            >
              <div>{bottomElement}</div>
              <div
                className={cn(styles.addEntityModal__buttons, {
                  [styles.addEntityModal__buttons_mt]: bottomElement,
                })}
              >
                <Button
                  appearance="flat"
                  onClick={toggleSubModal}
                  type="button"
                  className={styles.addEntityModal__cancelButton}
                >
                  Отмена
                </Button>
                <Button disabled={disabledSubmit} onClick={onSubmit}>
                  {saveTitle}
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ApproveOrCancel
        onApprove={onApproveSubModal}
        isModal={isSubModal}
        toggleModal={toggleSubModal}
        text={`Вы уверены, что хотите отменить ${
          isChanging ? 'изменение' : 'добавление'
        } ${subModalText}?`}
        withHideBodyScroll={withHideBodyScrollSubModal}
        classNameFormPortalCancel={classNameFormPortalCancel}
      />
    </Portal>
  );
};
