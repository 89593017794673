import { call, put, select, StrictEffect, takeEvery } from 'redux-saga/effects';

import {
  Attachment,
  AttachmentDeleteType,
  AttachmentsFiles,
} from '@entities/attachment/model/types';
import {
  fetchContractAttachmentsSuccessContractDetail,
  hideAttachmentsLoadingContractDetail,
  showAttachmentsLoadingContractDetail,
} from '@entities/contract/model/actions';
import { getCurrentContractId } from '@entities/contracts/model/selectors';
import { createError } from '@shared';

import { attachmentRequests as requests } from '../../api/attachment';
import { fetchNotesByTicketIdSuccess } from '../notes';

import {
  fetchContractAttachmentsSuccess,
  fetchTicketAttachmentsSuccess,
  fetchTicketCommentsAttachmentsSuccess,
  hideAttachmentsLoading,
  showAttachmentsLoading,
} from './actions';
import {
  AddAttachmentsRequestAction,
  AddMessageAttachmentsAction,
  Attachments,
  DeleteFileAttachmentsTicketRequestAction,
  FetchTicketAttachmentsRequestAction,
  FetchTicketCommentsAttachmentsRequestAction,
} from './actionTypes';
import { Note } from './types';

function* ticketAttachmentsFetch({
  payload,
}: FetchTicketAttachmentsRequestAction) {
  try {
    yield put(showAttachmentsLoading());
    const attachments: Attachment[] = yield call(
      requests.fetchTicketAttachments,
      payload
    );
    yield put(fetchTicketAttachmentsSuccess(attachments));
    yield put(hideAttachmentsLoading());
  } catch (e) {
    createError(e);
    yield put(hideAttachmentsLoading());
  }
}

function* addMessageAttachments({ payload }: AddMessageAttachmentsAction) {
  const { filesData } = payload;
  try {
    yield put(showAttachmentsLoading());
    yield call(requests.addAttachment, filesData);
    yield put(hideAttachmentsLoading());
  } catch (e) {
    createError(e);
    yield put(hideAttachmentsLoading());
  }
}

function* ticketCommentsAttachmentsFetch({
  payload,
}: FetchTicketCommentsAttachmentsRequestAction) {
  try {
    yield put(showAttachmentsLoading());
    const attachments: AttachmentsFiles[] = yield call(
      requests.fetchTicketCommentsAttachments,
      payload
    );
    yield put(fetchTicketCommentsAttachmentsSuccess(attachments));
    yield put(hideAttachmentsLoading());
  } catch (e) {
    createError(e);
    yield put(hideAttachmentsLoading());
  }
}

function* contractAttachmentsFetch() {
  try {
    yield put(showAttachmentsLoading());
    const contractId: ReturnType<typeof getCurrentContractId> = yield select(
      getCurrentContractId
    );

    if (contractId) {
      const attachments: Attachment[] = yield call(
        requests.fetchContractAttachments,
        contractId
      );
      yield put(fetchContractAttachmentsSuccess(attachments));
    }
    yield put(hideAttachmentsLoading());
  } catch (e) {
    createError(e);
    yield put(hideAttachmentsLoading());
  }
}

function* addAttachments({ payload }: AddAttachmentsRequestAction) {
  try {
    const { attachment, id } = payload;

    yield call(requests.addAttachment, attachment);
    yield call(contractAttachmentsFetch);

    yield put(showAttachmentsLoadingContractDetail());
    const attachments: Attachment[] = yield call(
      requests.fetchContractAttachments,
      id
    );
    yield put(fetchContractAttachmentsSuccessContractDetail(attachments));
    yield put(hideAttachmentsLoadingContractDetail());
  } catch (e) {
    createError(e);
  }
}

function* deleteFileAttachmentsTicket({
  payload,
}: DeleteFileAttachmentsTicketRequestAction) {
  try {
    const { attachmentId, attachmentFileId, ticketId, attachmentDeleteType } =
      payload;
    yield call(requests.deleteFileAttachmentsTicket, {
      attachmentId,
      attachmentFileId,
    });
    if (ticketId) {
      if (attachmentDeleteType === AttachmentDeleteType.INFO) {
        const attachmentsTicketInfo: Attachment[] = yield call(
          requests.fetchTicketAttachments,
          ticketId
        );
        yield put(fetchTicketAttachmentsSuccess(attachmentsTicketInfo));
      }
      if (attachmentDeleteType === AttachmentDeleteType.COMMENTS) {
        const attachments: AttachmentsFiles[] = yield call(
          requests.fetchTicketCommentsAttachments,
          ticketId
        );
        yield put(fetchTicketCommentsAttachmentsSuccess(attachments));
      }
      if (attachmentDeleteType === AttachmentDeleteType.NOTES) {
        const notes: Note[] = yield call(
          requests.fetchTicketNotesAttachments,
          ticketId
        );
        yield put(fetchNotesByTicketIdSuccess(notes));
      }
    }
    yield put(hideAttachmentsLoading());
  } catch (e) {
    createError(e);
    yield put(hideAttachmentsLoading());
  }
}

export function* attachmentsSaga(): Generator<StrictEffect> {
  yield takeEvery(
    Attachments.FETCH_TICKET_ATTACHMENTS_REQUEST,
    ticketAttachmentsFetch
  );
  yield takeEvery(
    Attachments.ADD_MESSAGE_ATTACHMENT_REQUEST,
    addMessageAttachments
  );
  yield takeEvery(
    Attachments.FETCH_TICKET_COMMENTS_ATTACHMENTS_REQUEST,
    ticketCommentsAttachmentsFetch
  );
  yield takeEvery(
    Attachments.FETCH_CONTRACT_ATTACHMENTS_REQUEST,
    contractAttachmentsFetch
  );
  yield takeEvery(Attachments.ADD_ATTACHMENTS_REQUEST, addAttachments);
  yield takeEvery(
    Attachments.DELETE_FILE_ATTACHMENT_TICKET_REQUEST,
    deleteFileAttachmentsTicket
  );
}
