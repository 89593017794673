import { ChangeEvent } from 'react';

import { Checkbox } from '@shared/ui/Checkbox';

import { sortFields } from '../../lib';
import { SettingField as Field } from '../../model';
import { SettingField, SettingFieldProps } from '../SettingField';

import styles from './SettingFieldsList.module.scss';

interface SettingFieldsListProps<T> extends Omit<SettingFieldProps, 'field'> {
  fieldsList?: Field[];
  handleChange: (
    event: ChangeEvent<HTMLInputElement>,
    setFields: T,
    ordinal?: number
  ) => void;
  setFieldsList: T;
  checkedAllFields: (
    event: ChangeEvent<HTMLInputElement>,
    setFields: T
  ) => void;
}

export const SettingFieldsList = <T,>({
  fieldsList,
  handleChange,
  setFieldsList,
  checkedAllFields,
  dragStartHandler,
  dropHandler,
  disabledField,
}: SettingFieldsListProps<T>) => {
  const isEmpty = !fieldsList?.length;
  const isAllCheckedFieldsList = !fieldsList?.some((filed) => filed.hidden);

  const handleChangeCheckbox = (event: ChangeEvent<HTMLInputElement>) => {
    checkedAllFields(event, setFieldsList);
  };

  return (
    <div className={styles.settingFieldsList__container}>
      <Checkbox
        label="Выбрать все"
        className={styles.settingFieldsList__checkbox}
        onChange={handleChangeCheckbox}
        defaultChecked={isAllCheckedFieldsList}
        disabled={isEmpty}
      />
      <div className={styles.settingFieldsList__line} />
      {!!fieldsList?.length && (
        <div className={styles.settingFieldsList__list}>
          {[...fieldsList]?.sort(sortFields).map((field) => (
            <SettingField
              key={field.ordinal}
              field={field}
              onChange={(event) =>
                handleChange(event, setFieldsList, field.ordinal)
              }
              dragStartHandler={dragStartHandler}
              dropHandler={dropHandler}
              disabledField={disabledField}
            />
          ))}
        </div>
      )}
    </div>
  );
};
