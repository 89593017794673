import {
  IssuePriorityCreateDtos,
  IssueStatusDtos,
  JiraIntegrationCreateData,
} from '@entities/jira-integrations';
import { convertValueTypeToValue } from '@shared';

type Args = {
  data: JiraIntegrationCreateData;
  statuses: IssueStatusDtos[];
  priorities: IssuePriorityCreateDtos[];
};

export const getPreparedDataForCreate = ({
  data,
  statuses,
  priorities,
}: Args) => {
  const { organizationId, isActive, jiraTicketType } = data;

  const ticketTypeId = convertValueTypeToValue<string>(data.ticketTypeId);
  const systemId = convertValueTypeToValue<string>(data.systemId);

  const args = {
    systemId,
    ticketTypeId,
  };

  const issueStatusCreateDtos = statuses.reduce((acc, el) => {
    const result = acc.find((item) => item.idStatus === el.idStatus);
    const { ticketStatusIds } = el;

    if (result) {
      return [
        ...acc.filter((item) => item.idStatus !== el.idStatus),
        {
          ...el,
          ticketStatusIds: [...ticketStatusIds, ...result.ticketStatusIds],
        },
      ];
    }

    return [...acc, { ...el, ticketStatusIds }];
  }, [] as IssueStatusDtos[]);

  const issuePriorityCreateDtos = priorities.reduce((acc, el) => {
    const result = acc.find((item) => item.idPriority === el.idPriority);
    const { ticketPriorities } = el;

    if (result && result.ticketPriorities && ticketPriorities) {
      return [
        ...acc.filter((item) => item.idPriority !== el.idPriority),
        {
          ...el,
          ticketPriorities: [...ticketPriorities, ...result.ticketPriorities],
        },
      ];
    }

    return [...acc, { ...el, ticketPriorities }];
  }, [] as IssuePriorityCreateDtos[]);

  return {
    ...data,
    organizationId: convertValueTypeToValue<string>(organizationId),
    systemId,
    isActive: convertValueTypeToValue<boolean>(isActive),
    statusMapping: {
      ...args,
      issueStatusCreateDtos,
    },
    priorityMapping: {
      ...args,
      issuePriorityCreateDtos,
    },
    ticketTypeId,
    idType: convertValueTypeToValue<string>(jiraTicketType),
  };
};
