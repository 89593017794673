import { CustomField } from '@entities/custom-fields/model/types';
import { System } from '@entities/system/model/types';
import { ApiClient, getEnv, ResponseWithMeta } from '@shared';

import { ExportFields, TicketsRequestData } from '../model/types';

import { endpoints } from './endpoints';

export const requests = {
  getExportFields: () =>
    ApiClient.get<ExportFields>({
      baseURL: getEnv('REACT_APP_EXPORT_EXCEL_URL'),
      url: endpoints.getExportFields(),
    }),
  getTicketsCount: (data: TicketsRequestData) =>
    ApiClient.post<ExportFields>({
      baseURL: getEnv('REACT_APP_EXPORT_EXCEL_URL'),
      url: endpoints.getTicketsCount(),
      data,
    }),
  getDownloadExcelUrl: (data: TicketsRequestData) =>
    ApiClient.post<ExportFields>({
      baseURL: getEnv('REACT_APP_EXPORT_EXCEL_URL'),
      url: endpoints.getDownloadExcelData(),
      data,
    }),
  fetchSystems: (title?: string) =>
    ApiClient.post<ResponseWithMeta<System[]>>({
      baseURL: getEnv('REACT_APP_ORGANIZATIONS_URL'),
      url: endpoints.getSystems(),
      params: {
        pageNum: 0,
        pageSize: 1000,
        sort: 'date_create_DESC'.toUpperCase(),
      },
      data: title ? { title } : {},
    }),
  fetchCustomFields: (systemId: string) =>
    ApiClient.post<ResponseWithMeta<CustomField[]>>({
      baseURL: getEnv('REACT_APP_ORGANIZATIONS_URL'),
      url: endpoints.getCustomFields(),
      params: {
        pageNum: 0,
        pageSize: 1000,
        sort: 'created_DESC'.toUpperCase(),
      },
      data: { systemId },
    }),
};
