import { PickedTime } from '../../model';

import { addZeroIfOneDigit } from './addZeroIfOneDigit';

export const getTimeList = (listLength: number) =>
  Array.from<unknown, PickedTime>({ length: listLength }, (_, value) => ({
    value: addZeroIfOneDigit(value),
    valueForList: value,
    isActive: false,
  }));
