import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';

import {
  EmployeesFormData,
  fetchEmployeesStatisticRequest,
  fetchSlaStatisticRequestEmployees,
  fetchTicketsStatisticRequestEmployees,
  resetDesktopState,
  resetEmployeesState,
  setDesktopFilters,
} from '@entities/desktop';
import {
  convertFromDateFormat,
  convertToDateFormat,
  getValueFromValueType,
} from '@shared';

export const useEmployeesForm = () => {
  const dispatch = useDispatch();

  const { control, watch, resetField } = useForm<EmployeesFormData>({
    mode: 'onChange',
  });

  const { date, workGroupId } = watch();

  useEffect(() => {
    if (workGroupId) {
      dispatch(
        setDesktopFilters({
          workgroupId: getValueFromValueType(workGroupId),
          to: convertToDateFormat(date),
          from: convertFromDateFormat(date),
          onlyActualTickets: false,
        })
      );
      dispatch(fetchEmployeesStatisticRequest());
      dispatch(fetchSlaStatisticRequestEmployees());
      dispatch(fetchTicketsStatisticRequestEmployees());
    }
  }, [workGroupId, date]);

  useEffect(
    () => () => {
      dispatch(resetEmployeesState());
      dispatch(resetDesktopState());

      resetField('workGroupId');
      resetField('date');
    },
    []
  );

  return {
    methods: { employeesControl: control },
  };
};
