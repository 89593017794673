import { isString } from 'lodash';

import { CustomFieldToRequest } from '@entities/custom-fields/model/types';
import { CustomFieldType, getReverseDateFromCustomField } from '@shared';

import { FIELD_NAMES_BY_CUSTOM_FIELD_TYPE } from '../../config';
import { CustomFieldTicket } from '../../model/ticket/types';
import { AbstractDataType } from '../../model/types';

export const getFieldNameByType = (type: CustomFieldType) =>
  FIELD_NAMES_BY_CUSTOM_FIELD_TYPE[type] as keyof CustomFieldToRequest;

const getPropsCustomFieldType = (field: CustomFieldTicket) => {
  if (!field.customFieldType) {
    return null;
  }

  const fieldTypeMap = {
    [CustomFieldType.FIELD_DATE]: () => field.date,
    [CustomFieldType.FIELD_FLAG]: () => field.flag,
    [CustomFieldType.FIELD_TEXT]: () => field.text,
    [CustomFieldType.FIELD_LIST]: () => field.listValue,
  };

  return fieldTypeMap[field.customFieldType]();
};

const getFieldValueByFieldType = (customField: CustomFieldTicket) => {
  if (!customField?.customFieldType) {
    return '';
  }

  const isDate = customField?.customFieldType === CustomFieldType.FIELD_DATE;
  const value = getPropsCustomFieldType(customField);

  return isDate && isString(value)
    ? getReverseDateFromCustomField(value)
    : value;
};

export const convertCustomFieldsFromArrToObject = (
  customFieldsArr: CustomFieldTicket[]
) => {
  const result: AbstractDataType = {};
  customFieldsArr.forEach((item: CustomFieldTicket) => {
    if (item.id && item?.customFieldType) {
      result[item.id] = getFieldValueByFieldType(item);
    }
  });
  return result;
};
