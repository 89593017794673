import { RenderTypes, TableColumns } from '@shared';

export const INITIAL_SYSTEMS_FILTER = {
  title: '',
  description: '',
  versionTitle: '',
  organizationId: null,
};

export const TABLE_FIELDS_FROM_CONTRACTS: TableColumns = [
  {
    title: 'Название',
    fieldName: 'title',
    sortField: 'title',
    renderType: RenderTypes.CONDITION,
  },
  { title: 'Описание', fieldName: 'description', sortField: 'description' },
];

export const DEFAULT_SORT_FILTER_SYSTEMS = 'TITLE_ASC';
