import { Organization } from '@entities/organizations';
import { ApiClient, getEnv } from '@shared';

import {
  Article,
  CategoryArticle,
  KBArticleTableFilterToRequest,
  KBOrganizationsFilter,
} from '../../model/types';

import { endpoints } from './endpoints';

export const requests = {
  fetchArticlesMainRequest: (
    pageNum = 0,
    pageSize?: number,
    sort?: string,
    filter: KBArticleTableFilterToRequest = {}
  ) =>
    ApiClient.post<Article[]>({
      baseURL: getEnv('REACT_APP_KNOWLEDGE_BASE_URL'),
      url: endpoints.fetchArticlesMain(),
      params: { pageNum, pageSize, sort: sort?.toUpperCase() },
      data: { ...filter },
    }),
  fetchOrganizationsArticlesMainRequest: (filter: KBOrganizationsFilter = {}) =>
    ApiClient.post<Organization[]>({
      baseURL: getEnv('REACT_APP_KNOWLEDGE_BASE_URL'),
      url: endpoints.fetchOrganizationsArticlesMain(),
      data: { ...filter },
    }),
  fetchCategoriesRequest: (organizationIds?: string[]) =>
    ApiClient.post<CategoryArticle[]>({
      baseURL: getEnv('REACT_APP_KNOWLEDGE_BASE_URL'),
      url: endpoints.fetchCategories(),
      data: organizationIds,
    }),
};
