import { FC } from 'react';
import { Control, Controller, UseFormSetValue } from 'react-hook-form';

import { CurrentStateFormData } from '@entities/desktop';
import { ApiClient, RadioSlide, Select, SelectOption, TabItem } from '@shared';

import styles from './CurrentStateForm.module.scss';

interface CurrentStateFormProps {
  control: Control<CurrentStateFormData>;
  setValue: UseFormSetValue<CurrentStateFormData>;
  managersOptions: SelectOption[];
  organizationsOptions: SelectOption[];
  systemsOptions: SelectOption[];
  radioTabs: TabItem[];
  isOrganizationType: boolean;
}

export const CurrentStateForm: FC<CurrentStateFormProps> = (props) => {
  const {
    control,
    setValue,
    systemsOptions,
    organizationsOptions,
    managersOptions,
    radioTabs,
    isOrganizationType,
  } = props;

  return (
    <>
      <Controller
        control={control}
        name="infoType"
        rules={{ required: true }}
        render={({ field }) => (
          <RadioSlide
            value={field.value}
            onChange={(event) => {
              ApiClient.finishPendingRequests();
              field.onChange(event);
            }}
            items={radioTabs}
            className={styles.currentStateForm__radio}
          />
        )}
      />
      {isOrganizationType ? (
        <>
          <Controller
            control={control}
            name="organizationId"
            render={({ field }) => (
              <Select<string>
                label="Организация"
                mobileModalTitle="организацию"
                value={field.value}
                options={organizationsOptions}
                className={styles.currentStateForm__select}
                onChange={(value) => {
                  setValue('systemId', null);
                  field.onChange(value);
                }}
              />
            )}
          />
          <Controller
            control={control}
            name="systemId"
            render={({ field }) => (
              <Select<string>
                label="Система"
                mobileModalTitle="систему"
                value={field.value}
                className={styles.currentStateForm__select}
                options={systemsOptions}
                onChange={field.onChange}
              />
            )}
          />
        </>
      ) : (
        <Controller
          control={control}
          name="workGroupId"
          render={({ field }) => (
            <Select<string>
              label="Рабочая группа"
              mobileModalTitle="рабочую группу"
              value={field.value}
              className={styles.currentStateForm__select}
              options={managersOptions}
              onChange={field.onChange}
            />
          )}
        />
      )}
    </>
  );
};
