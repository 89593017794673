import { FC, MouseEvent, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { getLoading as getAccessesLoading } from '@entities/accesses';
import { getLoading as getActionsLoading } from '@entities/actions';
import { ActionList, getIsAccessedAction } from '@entities/auth';
import {
  getLoading,
  Responsibility,
  ResponsibilityFilter,
  setCurrentResponsibilitiesPage,
  setResponsibilitiesFilter,
  TableResponsibilitiesTypes,
} from '@entities/responsibilities';
import { NotificationLink, Table } from '@shared';

import { RESPONSIBILITIES_FULL_FIELDS } from '../../config/constants';
import { fetchFunction } from '../../lib';
import { ResponsibilitiesFilter } from '../ResponsibilitiesFilter';

import styles from './ResponsibilitiesFullTable.module.scss';

interface DataTableConfig {
  responsibilities?: Responsibility[];
  isAccessToViewingCompany: boolean;
  responsibilityEditClick?: (event: MouseEvent<HTMLButtonElement>) => void;
}

const getDataTable = ({
  responsibilities,
  isAccessToViewingCompany,
  responsibilityEditClick,
}: DataTableConfig) =>
  responsibilities?.map((responsibility) => {
    const { title, description, id, organization, readOnly } = responsibility;
    return {
      id,
      title: (
        <button
          onClick={responsibilityEditClick}
          className={styles.responsibilitiesFullTable__title}
        >
          {title}
        </button>
      ),
      description,
      organization: isAccessToViewingCompany ? (
        <NotificationLink
          to={`/admin/organizations/${organization?.id}`}
          title={organization?.title}
        />
      ) : (
        organization?.title
      ),
      disabled: readOnly,
    };
  }) || [];

interface ResponsibilitiesFullTableProps {
  responsibilities?: Responsibility[];
  sort?: string;
  handleSort?: (value: string) => void;
  handleRowClick?: (value: string) => void;
  activeRowId?: string;
  showFilterRow?: boolean;
  isFilterActive?: boolean;
  handleClickResponsibilityEdit?: () => void;
}

export const ResponsibilitiesFullTable: FC<ResponsibilitiesFullTableProps> = ({
  responsibilities,
  sort,
  handleSort,
  handleRowClick,
  activeRowId,
  showFilterRow,
  isFilterActive,
  handleClickResponsibilityEdit,
}) => {
  const isAccessToViewingCompany = useSelector(
    getIsAccessedAction(ActionList.ViewingCompanyAdministration)
  );
  const loading = useSelector(getLoading);
  const accessesLoading = useSelector(getAccessesLoading);
  const actionsLoading = useSelector(getActionsLoading);

  const dispatch = useDispatch();

  const responsibilityEditClick = (event: MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    if (handleClickResponsibilityEdit) {
      handleClickResponsibilityEdit();
    }
  };

  const data = getDataTable({
    responsibilities,
    isAccessToViewingCompany,
    responsibilityEditClick,
  });

  const handleFilter = useCallback(
    (filter: ResponsibilityFilter) => {
      dispatch(setResponsibilitiesFilter(filter));
      dispatch(setCurrentResponsibilitiesPage(0));
      dispatch(fetchFunction[TableResponsibilitiesTypes.FULL]());
    },
    [dispatch]
  );

  return (
    <Table
      data={data}
      columns={RESPONSIBILITIES_FULL_FIELDS}
      sort={sort}
      onRowClick={handleRowClick}
      onSort={handleSort}
      activeRowId={activeRowId}
      dataLoading={loading}
      isFilterActive={isFilterActive}
      valueFieldName="id"
      filterComponent={
        showFilterRow && (
          <ResponsibilitiesFilter
            tableType={TableResponsibilitiesTypes.FULL}
            onFilter={handleFilter}
          />
        )
      }
      disabledSort={loading}
      disabledRows={accessesLoading || actionsLoading}
    />
  );
};
