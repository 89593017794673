import { ParentType } from '@entities/attachment';
import { Note } from '@entities/ticket';

export const prepareAttachments = (
  attachments: FormData | null,
  note: Note
) => {
  if (!attachments?.entries().next().done && note.ticketId && attachments) {
    return {
      parentType: ParentType.NOTE,
      systemFile: 'test',
      userFirstName: note.userFirstName,
      userLastName: note.userLastName,
    };
  }
  return null;
};
