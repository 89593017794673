import Rules from '@features/rules';
import { AdminsLayout } from '@pages/AdminsLayout';

const RulesPage = () => (
  <AdminsLayout>
    <Rules />;
  </AdminsLayout>
);

export default RulesPage;
