import { FC, useState } from 'react';

import { Article } from '@entities/knowledge-base';
import { Popover } from '@shared';

import {
  getListArticlesAndSubCategories,
  useIsAccessEditCategoryOrArticle,
} from '../../../../lib';
import { KBCategoryProps } from '../../../../model';
import { KBArticleTitle } from '../../../KBArticleTitle';
import { KBCategoryContainer } from '../../../KBCategoryContainer';
import { KBCategoryDrop } from '../../../KBCategoryDrop';
import { useKBCategory } from '../../lib';

import styles from './KBSubCategory.module.scss';

export const KBSubCategory: FC<KBCategoryProps> = ({
  category,
  dropProps,
  showFullCategory = false,
  articleDropProps,
  // eslint-disable-next-line sonarjs/cognitive-complexity
}) => {
  const { articles = [], children = [], organizations } = category;

  const { methods, state } = useKBCategory();
  const { togglePopover, onMouseEnter, onMouseLeave, toggleCategoryOpen } =
    methods;
  const { isMobileAll, isDropOpen, isMouseOnTitle, isCategoryOpen } = state;

  const [isDeleteCategoryModal, setIsDeleteCategoryModal] = useState(false);

  const toggleModal = () => {
    setIsDeleteCategoryModal((prevState) => !prevState);
  };

  const { articlesList } = getListArticlesAndSubCategories({
    showFullCategory,
    categories: children,
    articles,
  });

  const canEditCategory = useIsAccessEditCategoryOrArticle(organizations);

  const needDrop = !isMobileAll && canEditCategory;
  const showPopover = needDrop && (isMouseOnTitle || isDropOpen);

  const articlesListComponent = articlesList?.map((article: Article) => (
    <KBArticleTitle
      article={article}
      key={article?.id}
      withTooltip
      withDrop
      dropProps={articleDropProps}
      className={styles.KBSubCategory__articlesItem}
    />
  ));

  const categoryDrop = showPopover ? (
    <div
      onClick={(e) => {
        e.stopPropagation();
      }}
      role="button"
      tabIndex={0}
      onKeyDown={undefined}
      className={styles.KBSubCategory__popover}
    >
      <Popover
        content={
          <KBCategoryDrop
            {...dropProps}
            category={category}
            toggleModal={toggleModal}
          />
        }
        isOpen={isDropOpen}
        // withOutsideClick={false}
        togglePopover={togglePopover}
        positions={['right', 'left', 'top', 'bottom']}
        className={styles.KBSubCategory__popoverButton}
      />
    </div>
  ) : undefined;

  return (
    <KBCategoryContainer
      articlesListComponent={articlesListComponent}
      isMobileAll={isMobileAll}
      isCategoryOpen={isCategoryOpen}
      category={category}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      toggleCategoryOpen={toggleCategoryOpen}
      isSubCategory
      dropProps={{ ...dropProps, isSubCategory: true }}
      categoryDrop={categoryDrop}
      needDrop={needDrop}
      isDelete={isDeleteCategoryModal}
      toggleModal={toggleModal}
    />
  );
};
