import cn from 'clsx';
import {
  type ElementType,
  forwardRef,
  type ReactElement,
  useMemo,
} from 'react';
import { Link } from 'react-router-dom';

import { createBemClass } from '../helpers/createBemClass';
import {
  type PolymorphicComponentPropWithRef,
  type PolymorphicRef,
} from '../types';

import { type LinkColor } from './types';
import styles from './TypographyLink.module.scss';

enum TypographyLinkVariants {
  l1 = 'l1',
  l2 = 'l2',
  sl = 'sl',
}

export type TypographyLinkProps<C extends ElementType> =
  PolymorphicComponentPropWithRef<
    C,
    {
      variant?: keyof typeof TypographyLinkVariants;
      disabled?: boolean;
      color?: LinkColor;
    }
  >;

type TypographyLinkComponent = <C extends ElementType = typeof Link>(
  props: TypographyLinkProps<C>
) => ReactElement | null;

const rootClassName = createBemClass('typography-link');

export const TypographyLink: TypographyLinkComponent = forwardRef(
  <C extends ElementType = typeof Link>(
    {
      variant = TypographyLinkVariants.l1,
      color = 'grey',
      className,
      children,
      disabled,
      as,
      to,
      ...other
    }: TypographyLinkProps<C>,
    ref?: PolymorphicRef<C>
  ) => {
    const Component = as ?? Link;

    const classes = useMemo(
      () =>
        cn(
          styles[rootClassName()],
          styles[rootClassName({ modName: variant })],
          styles[rootClassName({ modName: color })],
          {
            [styles[rootClassName({ modName: 'disabled' })]]: disabled,
          },
          className
        ),
      [variant, color, disabled, className]
    );

    return (
      // eslint-disable-next-line react/react-in-jsx-scope
      <Component {...other} ref={ref} className={classes} to={to}>
        {children}
      </Component>
    );
  }
);
