import { FC } from 'react';

import { entityNames, TableContainer } from '@shared';

import { useActionsTableContainer } from '../../lib';
import { ActionsFromResponsibilitiesTable } from '../ActionsFromResponsibilitiesTable';

import styles from './ActionsTableContainer.module.scss';

export const ActionsTableContainer: FC = () => {
  const { methods, state } = useActionsTableContainer();

  const {
    handleChangePageSize,
    handleChangePage,
    handleSort,
    toggleFilterRow,
  } = methods;

  const {
    searchDisabled,
    sortActions,
    totalElements,
    pageSize,
    pageNum,
    isFilterActive,
    actions,
    showFilterRow,
    loadingActions,
  } = state;

  const getContent = () => (
    <ActionsFromResponsibilitiesTable
      actions={actions}
      showFilterRow={showFilterRow}
      handleSort={handleSort}
      sort={sortActions}
      isFilterActive={isFilterActive}
    />
  );

  return (
    <TableContainer
      classNameRoot={styles.actionsTableContainer}
      headerProps={{
        title: 'Действия',
        isSearchOpen: showFilterRow,
        searchDisabled,
        toggleSearch: toggleFilterRow,
        loading: loadingActions,
      }}
      contentTable={getContent()}
      countRecordsProps={{
        records: totalElements,
      }}
      paginationProps={{
        elementsCount: totalElements,
        pageSize,
        handleChangePage,
        handleChangePageSize,
        marginPagesDisplayed: 0,
        pageNum,
        disabled: loadingActions,
        entityName: entityNames.ACTIONS,
      }}
    />
  );
};
