import { getUrl } from '@shared';

import { CheckIsExistTagArgs } from '../../model/tags/types';

export const tagsEndpoints = {
  getTags: () => getUrl('tag/filter'),
  createTag: () => getUrl('tag'),
  getCheckIsExistTag: ({ systemId, name }: CheckIsExistTagArgs) =>
    getUrl(`tag/systems/${systemId}/name/${name}/exists`),
};
