import { useMemo, useState } from 'react';
import { useSelector } from 'react-redux';

import { getIsClient } from '@entities/auth/model/selectors';

import {
  getClientTicketCustomFields,
  getSpecialistTicketCustomFields,
} from '../../../model/ticket/selectors';
import { TicketTab } from '../../../model/types';

import styles from './useTicketInfoTabs.module.scss';

export const useTicketInfoTabs = () => {
  const isClient = useSelector(getIsClient);
  const specCustomFields = useSelector(getSpecialistTicketCustomFields);
  const clientCustomFields = useSelector(getClientTicketCustomFields);

  const [mode, setTicketInfoMode] = useState<'info' | 'additionally'>('info');

  const customFields = isClient ? clientCustomFields : specCustomFields;

  const ticketInfoTabs: TicketTab[] = useMemo(() => {
    const tabs = [
      {
        title: 'Информация',
        onTabClick: () => setTicketInfoMode('info'),
        isTabActive: mode === 'info',
        className: styles.useTicketInfoTabs__tab,
      },
    ];
    if (customFields.length || clientCustomFields.length) {
      tabs.push({
        title: 'Дополнительно',
        onTabClick: () => setTicketInfoMode('additionally'),
        isTabActive: mode === 'additionally',
        className: styles.useTicketInfoTabs__tab,
      });
    }
    return tabs;
  }, [mode, customFields.length, clientCustomFields.length]);

  return {
    mode,
    ticketInfoTabs,
  };
};
