import { ResponseWithMeta, UpdateContentType } from '@shared';

import {
  CreateCustomFieldData,
  CustomField,
  CustomFieldsFilter,
  CustomFieldToRequest,
  DeleteCustomFieldTypeAction,
} from './types';

export enum CustomFields {
  FETCH_CUSTOM_FIELDS_SUCCESS = 'CUSTOM_FIELDS/FETCH_CUSTOM_FIELDS_SUCCESS',
  FETCH_CUSTOM_FIELDS_REQUEST = 'CUSTOM_FIELDS/FETCH_CUSTOM_FIELDS_REQUEST',
  CUSTOM_FIELDS_LOADING_SHOW = 'CUSTOM_FIELDS/CUSTOM_FIELDS_LOADING_SHOW',
  CUSTOM_FIELDS_LOADING_HIDE = 'CUSTOM_FIELDS/CUSTOM_FIELDS_LOADING_HIDE',
  SET_CURRENT_CUSTOM_FIELDS_PAGE = 'CUSTOM_FIELDS/SET_CURRENT_CUSTOM_FIELDS_PAGE',
  SET_SORT_CUSTOM_FIELDS = 'CUSTOM_FIELDS/SET_SORT_CUSTOM_FIELDS',
  SET_SIZE_PAGE_CUSTOM_FIELDS = 'CUSTOM_FIELDS/SET_SIZE_PAGE_CUSTOM_FIELDS',
  CREATE_CUSTOM_FIELD = 'CUSTOM_FIELDS/CREATE_CUSTOM_FIELD',
  UPDATE_CUSTOM_FIELD = 'CUSTOM_FIELDS/UPDATE_CUSTOM_FIELD',
  FETCH_CUSTOM_FIELD_REQUEST = 'CUSTOM_FIELDS/FETCH_CUSTOM_FIELD_REQUEST',
  SET_CURRENT_CUSTOM_FIELD_SUCCESS = 'CUSTOM_FIELDS/SET_CURRENT_CUSTOM_FIELD_SUCCESS',
  RESET_CUSTOM_FIELD_STATE = 'CUSTOM_FIELDS/RESET_CUSTOM_FIELD_STATE',
  SET_CURRENT_CUSTOM_FIELD_ID = 'CUSTOM_FIELDS/SET_CURRENT_CUSTOM_FIELD_ID',
  SET_CUSTOM_FIELDS_FILTER = 'CUSTOM_FIELDS/SET_CUSTOM_FIELDS_FILTER',
  SET_FILTER_CUSTOM_FIELD_ID = 'CUSTOM_FIELDS/SET_FILTER_CUSTOM_FIELD_ID',
  DELETE_CUSTOM_FIELD_REQUEST = 'CUSTOM_FIELDS/DELETE_CUSTOM_FIELD_REQUEST',
  CHECK_IS_EXIST_REQUEST = 'CUSTOM_FIELDS/CHECK_IS_EXIST_REQUEST',
  CHECK_IS_EXIST_SUCCESS = 'CUSTOM_FIELDS/CHECK_IS_EXIST_SUCCESS',
  RESET_CURRENT_CUSTOM_FIELD = 'CUSTOM_FIELDS/RESET_CURRENT_CUSTOM_FIELD',
}

export interface ShowCustomFieldsLoadingAction {
  type: CustomFields.CUSTOM_FIELDS_LOADING_SHOW;
}

export interface HideCustomFieldsLoadingAction {
  type: CustomFields.CUSTOM_FIELDS_LOADING_HIDE;
}

export interface FetchCustomFieldsSuccessAction {
  type: CustomFields.FETCH_CUSTOM_FIELDS_SUCCESS;
  payload: ResponseWithMeta<CustomField[]>;
}

export interface FetchCustomFieldsRequestAction {
  type: CustomFields.FETCH_CUSTOM_FIELDS_REQUEST;
  payload?: UpdateContentType;
}

export interface SetCurrentCustomFieldsPageAction {
  type: CustomFields.SET_CURRENT_CUSTOM_FIELDS_PAGE;
  payload: number;
}

export interface SetSizePageAction {
  type: CustomFields.SET_SIZE_PAGE_CUSTOM_FIELDS;
  payload: number;
}

export interface SetSortCustomFieldsAction {
  type: CustomFields.SET_SORT_CUSTOM_FIELDS;
  payload: string;
}

export interface FetchCustomFieldRequestAction {
  type: CustomFields.FETCH_CUSTOM_FIELD_REQUEST;
  payload: string;
}

export interface CreateCustomFieldAction {
  type: CustomFields.CREATE_CUSTOM_FIELD;
  payload: CreateCustomFieldData;
}

export interface UpdateCustomFieldAction {
  type: CustomFields.UPDATE_CUSTOM_FIELD;
  payload: CustomFieldToRequest;
}

export interface SetCurrentCustomFieldSuccessAction {
  type: CustomFields.SET_CURRENT_CUSTOM_FIELD_SUCCESS;
  payload?: CustomField;
}

export interface ResetCustomFieldStateAction {
  type: CustomFields.RESET_CUSTOM_FIELD_STATE;
}

export interface SetCurrentCustomFieldIdAction {
  type: CustomFields.SET_CURRENT_CUSTOM_FIELD_ID;
  payload?: string;
}

export interface SetFilterCustomFieldIdAction {
  type: CustomFields.SET_FILTER_CUSTOM_FIELD_ID;
  payload: string;
}

export interface SetCustomFieldsFilterAction {
  type: CustomFields.SET_CUSTOM_FIELDS_FILTER;
  payload: CustomFieldsFilter;
}

export interface DeleteCustomFieldRequestAction {
  type: CustomFields.DELETE_CUSTOM_FIELD_REQUEST;
  payload: DeleteCustomFieldTypeAction;
}

export interface CheckIsExistRequestAction {
  type: CustomFields.CHECK_IS_EXIST_REQUEST;
  payload: string;
}

export interface CheckIsExistSuccessAction {
  type: CustomFields.CHECK_IS_EXIST_SUCCESS;
  payload: boolean;
}

export interface ResetCurrentCustomFieldAction {
  type: CustomFields.RESET_CURRENT_CUSTOM_FIELD;
}

export type CustomFieldsActionsTypes =
  | FetchCustomFieldsSuccessAction
  | SetCurrentCustomFieldsPageAction
  | SetSizePageAction
  | SetSortCustomFieldsAction
  | FetchCustomFieldRequestAction
  | SetCurrentCustomFieldSuccessAction
  | ResetCustomFieldStateAction
  | SetCurrentCustomFieldIdAction
  | SetCustomFieldsFilterAction
  | SetFilterCustomFieldIdAction
  | CheckIsExistSuccessAction
  | UpdateCustomFieldAction
  | ResetCurrentCustomFieldAction;
