import { useSelector } from 'react-redux';

import { ActionList, getIsAccessedAction } from '@entities/auth';

export const useCanCreateArticleOrCategory = () => {
  const isAccessCreateArticleInClientOrganization = useSelector(
    getIsAccessedAction(ActionList.CreateKBArticleInClientOrganization)
  );
  const isAccessCreateArticleInMyOrganization = useSelector(
    getIsAccessedAction(ActionList.CreateKBArticleInMyOrganization)
  );

  return (
    isAccessCreateArticleInClientOrganization ||
    isAccessCreateArticleInMyOrganization
  );
};
