import { debounce } from 'lodash';
import { FC, memo, useMemo } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';

import {
  CustomFieldsFilter as CustomFieldsFilterData,
  fetchCustomFieldsRequest,
  setCurrentCustomFieldsPage,
  setCustomFieldsFilter,
} from '@entities/custom-fields';
import { OrganizationSelect, OrganizationType } from '@entities/organizations';
import { SystemSelect } from '@entities/systems';
import {
  checkObjectIdentity,
  CustomFieldType,
  DEFAULT_DEBOUNCE_DELAY,
  getValueFromValueType,
  IconBlock,
  Input,
  MenuItem,
  RenderMenuItemProps,
  Select,
  Size,
  TableBodyCell,
  TableFilter,
  ValueType,
} from '@shared';

import {
  CUSTOM_FIELD_ACCESS_OPTIONS,
  INITIAL_CUSTOM_FIELDS_FILTER,
  SLIDE_RADIO_TABS,
} from '../../config';
import { getPropsCustomFieldType } from '../../lib';

import styles from './CustomFieldsFilter.module.scss';

const customFilterOrganization = {
  organizationTypes: {
    value: OrganizationType.CUSTOMER,
    title: 'Клиентская',
  },
};

export const CustomFieldsFilter: FC = memo(() => {
  const dispatch = useDispatch();

  const { register, handleSubmit, control, reset, watch } =
    useForm<CustomFieldsFilterData>({
      mode: 'onChange',
      defaultValues: INITIAL_CUSTOM_FIELDS_FILTER,
    });

  const filterValues = watch();

  const resetFilter = () => {
    dispatch(setCustomFieldsFilter({}));
    dispatch(setCurrentCustomFieldsPage(0));
    dispatch(fetchCustomFieldsRequest());
    reset();
  };

  const formSubmitHandler = handleSubmit((data) => {
    const { title, customFieldType, organizationId, systemId, accessType } =
      data;
    const preparedData = {
      title: title || undefined,
      customFieldType: customFieldType || undefined,
      organizationId: organizationId || undefined,
      systemId: systemId || undefined,
      accessType: accessType || undefined,
    };
    dispatch(setCustomFieldsFilter(preparedData));
    dispatch(setCurrentCustomFieldsPage(0));
    dispatch(fetchCustomFieldsRequest());
  });

  const formSubmitHandlerDebounced = useMemo(
    () => debounce(formSubmitHandler, DEFAULT_DEBOUNCE_DELAY),
    []
  );

  const titleToInput = register('title', {
    onChange: formSubmitHandlerDebounced,
  });

  const tableBodyWrapper = (elements: JSX.Element[]) =>
    elements.map((item) => (
      <TableBodyCell className={styles.customFieldsFilter__cell} key={item.key}>
        {item}
      </TableBodyCell>
    ));

  const renderItemType = ({
    onChange,
    option,
    selected,
  }: RenderMenuItemProps) => {
    const itemProps = getPropsCustomFieldType(option.value as CustomFieldType);
    return (
      <MenuItem
        size={Size.xs}
        className={styles.customFieldsFilter__item}
        onClick={onChange}
        selected={selected}
      >
        <IconBlock {...itemProps} />
      </MenuItem>
    );
  };

  const renderValue = (value: ValueType<CustomFieldType>) => {
    const selectValue = getValueFromValueType(value);
    const itemProps = getPropsCustomFieldType(selectValue as CustomFieldType);
    return <IconBlock {...itemProps} />;
  };

  const filterElements = [
    <Input size={Size.xs} type="title" key="title" {...titleToInput} />,
    <Controller
      control={control}
      name="accessType"
      key="accessType"
      render={({ field }) => (
        <Select<OrganizationType>
          size={Size.xs}
          mobileModalTitle="доступ"
          options={CUSTOM_FIELD_ACCESS_OPTIONS}
          value={field.value}
          onChange={(value) => {
            field.onChange(value);
            formSubmitHandler();
          }}
          isTooltip={false}
        />
      )}
    />,
    <Controller
      control={control}
      name="customFieldType"
      key="customFieldType"
      render={({ field }) => (
        <Select<CustomFieldType>
          size={Size.xs}
          mobileModalTitle="тип"
          options={SLIDE_RADIO_TABS}
          renderMenuItem={renderItemType}
          renderValue={renderValue}
          value={field.value}
          onChange={(value: ValueType<CustomFieldType>) => {
            field.onChange(value);
            formSubmitHandler();
          }}
          isTooltip={false}
        />
      )}
    />,
    <Controller
      control={control}
      name="organizationId"
      key="organizationId"
      render={({ field }) => (
        <OrganizationSelect
          size={Size.xs}
          value={field.value}
          onChange={(value: ValueType<string>) => {
            field.onChange(value);
            formSubmitHandler();
          }}
          customFilters={customFilterOrganization}
        />
      )}
    />,
    <Controller
      control={control}
      name="systemId"
      key="systemId"
      render={({ field }) => (
        <SystemSelect
          mobileModalTitle="систему"
          value={field.value}
          onChange={(value: ValueType<string>) => {
            field.onChange(value);
            formSubmitHandler();
          }}
          isMulti={false}
        />
      )}
    />,
  ];

  const filter = <>{tableBodyWrapper(filterElements)}</>;

  const disableReset = checkObjectIdentity(filterValues, {});

  return (
    <TableFilter
      filterComponent={filter}
      onReset={resetFilter}
      disableReset={disableReset}
    />
  );
});
