import cn from 'clsx';
import { FC, useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { Article } from '@entities/knowledge-base';
import {
  CommentFile,
  DeleteApproveOrCancel,
  getScreenWidth,
  IconBlock,
  Popover,
  RouterHref,
  ScreenWidth,
  Tooltip,
  Typography,
  TypographyVariants,
  usePopover,
} from '@shared';

import { useIsAccessEditCategoryOrArticle } from '../../lib';
import { KBArticleDrop, KBArticleDropProps } from '../KBArticleDrop';

import styles from './KBArticleTitle.module.scss';

interface KBArticleTitleProps {
  article: Article;
  outlined?: boolean;
  withCategory?: boolean;
  withOrganization?: boolean;
  className?: string;
  withDrop?: boolean;
  withTooltip?: boolean;
  classNameTooltip?: string;
  dropProps?: KBArticleDropProps;
}

export const KBArticleTitle: FC<KBArticleTitleProps> = ({
  article,
  outlined = false,
  withCategory = false,
  withOrganization = false,
  className,
  withDrop,
  withTooltip = false,
  classNameTooltip,
  dropProps,
  // eslint-disable-next-line sonarjs/cognitive-complexity
}) => {
  const { push } = useHistory();

  const { title, id, categoryTitle, organizations } = article;

  const organizationsTitles = organizations.map(
    (organization) => organization.title
  );

  const [isPopoverOpen, togglePopover] = usePopover();
  const [isMouseOnTitle, setIsMouseOnTitle] = useState(false);
  const [isModal, setIsModal] = useState(false);

  const toggleModal = () => {
    setIsModal((prevState) => !prevState);
  };

  const screenWidth = useSelector(getScreenWidth);

  const canEditArticle = useIsAccessEditCategoryOrArticle(organizations);

  const isMobile = screenWidth === ScreenWidth.MOBILE;
  const isMobileSmall = screenWidth === ScreenWidth.MOBILE_SMALL;
  const isMobileAll = isMobile || isMobileSmall;

  const needDrop = !isMobileAll && withDrop && canEditArticle;
  const showPopover = needDrop && (isMouseOnTitle || isPopoverOpen);

  const getTooltipTitle = () => {
    const textForTooltip = [title];
    if (withCategory) {
      textForTooltip.push(categoryTitle);
    }
    return textForTooltip.join(', ');
  };

  const onMouseEnter = () => {
    setIsMouseOnTitle(true);
  };

  const onMouseLeave = () => {
    setIsMouseOnTitle(false);
  };

  const onArticleClick = () => {
    push(`${RouterHref.KnowledgeBase}/${id}`);
  };

  const handleDelete = () => {
    if (article?.id && dropProps?.onDelete) {
      dropProps?.onDelete(article.id);
    }
  };

  const articleInfo = (withCategory || withOrganization) && (
    <Typography
      variant={TypographyVariants.b3}
      className={styles.KBArticleTitle__articleInfo}
    >
      {withCategory ? categoryTitle : null}
      {withCategory && withOrganization ? ', ' : null}
      {withOrganization ? (
        <Typography
          component="span"
          variant={TypographyVariants.s2}
          className={styles.KBArticleTitle__articleOrganization}
        >
          {organizationsTitles}
        </Typography>
      ) : null}
    </Typography>
  );

  const articlePopover = showPopover && (
    <div
      onClick={(e) => {
        e.stopPropagation();
      }}
      role="button"
      tabIndex={0}
      onKeyDown={undefined}
      className={styles.KBArticleTitle__popover}
    >
      <Popover
        isOpen={isPopoverOpen}
        togglePopover={togglePopover}
        content={
          <KBArticleDrop
            {...dropProps}
            article={article}
            toggleModal={toggleModal}
          />
        }
        positions={['right', 'bottom', 'top', 'left']}
        withOutsideClick={false}
        className={styles.KBArticleTitle__popoverButton}
      />
    </div>
  );

  const tooltipId = `${article.id}-${article.title}`;

  const tooltip = withTooltip && !isMobileAll && (
    <Tooltip id={tooltipId} className={classNameTooltip}>
      {getTooltipTitle()}
    </Tooltip>
  );

  return (
    <>
      <div
        onClick={onArticleClick}
        onMouseEnter={needDrop ? onMouseEnter : undefined}
        onMouseLeave={needDrop ? onMouseLeave : undefined}
        onKeyDown={undefined}
        role="button"
        tabIndex={0}
        className={cn(
          styles.KBArticleTitle,
          {
            [styles.KBArticleTitle_outlined]: outlined,
            [styles.KBArticleTitle_withDrop]: withDrop,
          },
          className
        )}
      >
        {tooltip}
        <IconBlock
          icon={<CommentFile className={styles.KBArticleTitle__icon} />}
          text={title}
          data-for={tooltipId}
          data-tip
          className={styles.KBArticleTitle__title}
          classNameText={styles.KBArticleTitle__titleText}
        />
        {articleInfo}
        {articlePopover}
      </div>
      <DeleteApproveOrCancel
        isModal={isModal}
        toggleModal={toggleModal}
        entityTitle="статью"
        onApprove={handleDelete}
      />
    </>
  );
};
