import { TicketStatusesRequest } from '@entities/ticket/model/types';
import { ApiClient, getEnv, KeyValueOption } from '@shared';

import {
  CreateTrigger,
  CustomField,
  Trigger,
  TriggersAttributeToRequest,
  TriggersFilterToRequest,
} from '../model';

import { endpoints } from './endpoints';

export const requests = {
  fetchFiltersFields: () =>
    ApiClient.get({
      baseURL: getEnv('REACT_APP_AUTO_ASSIGNING'),
      url: endpoints.getFiltersFields(),
    }),
  fetchFiltersStatus: () =>
    ApiClient.get<string[]>({
      baseURL: getEnv('REACT_APP_TICKET_STATUS'),
      url: endpoints.getFiltersStatus(),
    }),
  fetchTriggersFields: (data: TriggersAttributeToRequest) =>
    ApiClient.post<TriggersAttributeToRequest>({
      baseURL: getEnv('REACT_APP_ORGANIZATIONS_URL'),
      url: endpoints.getTriggersFields(),
      data,
    }),
  fetchTriggers: (
    pageNum = 0,
    pageSize?: number,
    sort?: string,
    filter?: TriggersFilterToRequest
  ) =>
    ApiClient.post<Trigger[]>({
      baseURL: getEnv('REACT_APP_AUTO_ASSIGNING'),
      url: endpoints.getTriggers(),
      params: { pageNum, pageSize, sort: sort?.toUpperCase() },
      data: {
        ...filter,
      },
    }),
  fetchCurrentTrigger: (id: string) =>
    ApiClient.get<Trigger>({
      baseURL: getEnv('REACT_APP_AUTO_ASSIGNING'),
      url: endpoints.getCurrentTrigger(id),
      params: {},
      data: {},
    }),
  fetchCustomField: (id: string) =>
    ApiClient.get<CustomField[]>({
      baseURL: getEnv('REACT_APP_ORGANIZATIONS_URL'),
      url: endpoints.getCustomFieldBySystemId(id),
      params: {},
      data: {},
    }),
  createTrigger: (data: CreateTrigger) =>
    ApiClient.post<Trigger>({
      baseURL: getEnv('REACT_APP_AUTO_ASSIGNING'),
      url: endpoints.createTrigger(),
      data,
    }),
  updateTrigger: (data: CreateTrigger, id: string) =>
    ApiClient.put<Trigger>({
      baseURL: getEnv('REACT_APP_AUTO_ASSIGNING'),
      url: endpoints.editTrigger(id),
      data,
    }),
  deleteTrigger: (id: string) =>
    ApiClient.delete<boolean>({
      baseURL: getEnv('REACT_APP_AUTO_ASSIGNING'),
      url: endpoints.deleteTrigger(id),
    }),
  fetchTicketStatuses: (data: TicketStatusesRequest) =>
    ApiClient.post<KeyValueOption[]>({
      baseURL: getEnv('REACT_APP_TICKET_STATUS'),
      url: endpoints.getTicketStatuses(),
      data,
    }),
};
