import { Environment, EnvironmentSetting } from '@entities/environment';

export const getPreparedEnvironments = (
  environments?: Environment[],
  environmentSettings?: EnvironmentSetting[]
) =>
  environments?.filter(
    ({ id }) =>
      !!environmentSettings?.find(
        ({ environmentId, active }) => environmentId === id && active
      )
  );
