import { Edge, Node } from 'reactflow';
import { v4 as uuidv4 } from 'uuid';

import { EdgeType, NodeType } from '@entities/statuses-builder';

import { WAITING_INFO_SETTINGS } from '../../config/constants';

export const getDataWaitingInfoStatus = (nodeStatusWork: Node<NodeType>) => {
  const id = uuidv4();

  const node: Node<NodeType> = {
    id,
    data: {
      ...WAITING_INFO_SETTINGS,
    },
    position: {
      x: nodeStatusWork.position.x + 50,
      y: nodeStatusWork.position.y + 110,
    },
  };

  const edgeTo: Edge<EdgeType> = {
    id: uuidv4(),
    target: nodeStatusWork?.id,
    source: id,
    sourceHandle: 'b',
    data: { protected: true },
  };

  const edgeFrom: Edge<EdgeType> = {
    id: uuidv4(),
    target: id,
    targetHandle: 'b',
    source: nodeStatusWork?.id,
    sourceHandle: 'b',
    data: { protected: true },
  };

  return {
    node,
    edgeTo,
    edgeFrom,
  };
};
