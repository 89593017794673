import { useCallback, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';

import {
  getManagerSpecialistWorkGroup,
  getOnlySpecialistWorkGroup,
  getWorkGroupsOptionsList,
} from '@entities/auth';
import {
  getNotificationSettingsLocale,
  updateNotificationSettingsRequest,
  WorkGroupsData,
} from '@entities/profile';
import { ValueType } from '@shared';

import { DEFAULT_VALUES_WORK_GROUPS_FORM } from '../../config';
import { prepareNotificationSettings } from '../utils';

export const useWorkGroupsNotification = () => {
  const notificationsSettingsLocale = useSelector(
    getNotificationSettingsLocale
  );
  const workGroupsOptions = useSelector(getWorkGroupsOptionsList);
  const isOnlySpecialistWorkGroup = useSelector(getOnlySpecialistWorkGroup);
  const isManagerSpecialistWorkGroup = useSelector(
    getManagerSpecialistWorkGroup
  );

  const { control, watch, setValue } = useForm<WorkGroupsData>({
    mode: 'onChange',
    defaultValues: DEFAULT_VALUES_WORK_GROUPS_FORM,
  });

  const { withNotification } = watch();

  const dispatch = useDispatch();

  const handleChangeWorkGroups = useCallback(
    (event: ValueType<string>) => {
      if (notificationsSettingsLocale) {
        const notificationWorkgroups = Array.isArray(event)
          ? event.map((workGroup) => ({
              key: workGroup.value,
              value: workGroup.title,
            }))
          : [];

        const updatedSettingsPayload = prepareNotificationSettings(
          notificationsSettingsLocale,
          notificationWorkgroups,
          isOnlySpecialistWorkGroup,
          isManagerSpecialistWorkGroup
        );

        dispatch(updateNotificationSettingsRequest(updatedSettingsPayload));
      }
    },
    [
      dispatch,
      notificationsSettingsLocale,
      isOnlySpecialistWorkGroup,
      isManagerSpecialistWorkGroup,
    ]
  );

  const updateNotifications = useCallback(
    (checked: boolean) => {
      if (notificationsSettingsLocale) {
        dispatch(
          updateNotificationSettingsRequest({
            ...notificationsSettingsLocale,
            workgroupsNotificationIsEnable: checked,
          })
        );
      }
    },
    [dispatch, notificationsSettingsLocale]
  );

  useEffect(() => {
    setValue(
      'withNotification',
      !!notificationsSettingsLocale?.workgroupsNotificationIsEnable
    );

    const managerWorkGroups =
      notificationsSettingsLocale?.managerNotificationWorkgroups ?? [];
    const specialistWorkGroups =
      notificationsSettingsLocale?.specialistNotificationWorkgroups ?? [];

    setValue(
      'workGroupsIds',
      [...managerWorkGroups, ...specialistWorkGroups].map((workGroup) => ({
        title: workGroup.value,
        value: workGroup.key,
      }))
    );
  }, [
    notificationsSettingsLocale?.workgroupsNotificationIsEnable,
    notificationsSettingsLocale?.managerNotificationWorkgroups,
    notificationsSettingsLocale?.specialistNotificationWorkgroups,
  ]);

  return {
    state: { workGroupsOptions, withNotification },
    methods: { control, handleChangeWorkGroups, updateNotifications },
  };
};
