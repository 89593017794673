import { debounce } from 'lodash';
import { useCallback, useEffect, useMemo } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';

import {
  Article,
  FetchArticlesParams,
  KBArticleTableFilterToRequest,
} from '@entities/knowledge-base';
import {
  DEFAULT_DEBOUNCE_DELAY,
  PaginationType,
  SelectOption,
  useInfiniteScroll,
} from '@shared';

import { InputWithList, InputWithListProps } from '../InputWithList';
import { KBArticleTitle } from '../KBArticleTitle';

import styles from './KBArticlesSearch.module.scss';

export interface ActionsProps {
  resetState: () => void;
  fetch: (params: FetchArticlesParams) => void;
  setFilter: (filter: KBArticleTableFilterToRequest) => void;
  setSearchValueToState?: (value?: string) => void;
  fetchCategories?: () => void;
}

export interface SelectorsProps<T> {
  getList: (state: T) => Article[];
  getLoading: (state: T) => boolean;
  getOrganizationsSelected: (state: T) => SelectOption | undefined | null;
  getPaginationArticles: (state: T) => PaginationType;
}

interface KBArticlesSearchProps<T>
  extends Partial<InputWithListProps<Article>> {
  clearWhenOrganizationSelected?: boolean;
  needInitialOptions?: boolean;
  actionsProps: ActionsProps;
  selectorsProps: SelectorsProps<T>;
}

export const renderArticleFunction = (article: Article) => (
  <KBArticleTitle
    article={article}
    className={styles.KBArticlesSearch}
    classNameTooltip={styles.KBArticlesSearch__tooltip}
    withCategory
    withOrganization
    withTooltip
  />
);

export const KBArticlesSearch = <T,>(props: KBArticlesSearchProps<T>) => {
  const {
    clearWhenOrganizationSelected = false,
    needInitialOptions = true,
    selectorsProps,
    actionsProps,
    ...inputProps
  } = props;
  const {
    getList,
    getLoading,
    getOrganizationsSelected,
    getPaginationArticles,
  } = selectorsProps;

  const {
    resetState,
    fetch,
    setFilter,
    setSearchValueToState,
    fetchCategories,
  } = actionsProps;

  const articlesList = useSelector(getList);
  const loadingArticles = useSelector(getLoading);
  const organizationSelected = useSelector(getOrganizationsSelected);
  const { totalElements, pageNum, pageSize } = useSelector(
    getPaginationArticles
  );

  const { totalPage } = useInfiniteScroll({
    pageNum,
    pageSize,
    totalElements,
  });

  const { handleSubmit, setValue, control } =
    useForm<KBArticleTableFilterToRequest>({
      mode: 'onChange',
      defaultValues: {
        title: '',
      },
    });

  const setNextPageSystem = useCallback(
    (valuePage: number) => {
      fetch({ updateType: 'join', page: valuePage });
    },
    [fetch]
  );

  const formSubmitHandler = handleSubmit((data) => {
    const { title } = data;
    if (!needInitialOptions && !title && fetchCategories) {
      resetState();
      fetchCategories();
      return;
    }
    const preparedData = {
      title: title || undefined,
    };
    if (setSearchValueToState) {
      setSearchValueToState(title);
    }
    setFilter(preparedData);
    fetch({ updateType: 'update', page: 0 });
  });

  const formSubmitHandlerDebounced = useMemo(
    () => debounce(formSubmitHandler, DEFAULT_DEBOUNCE_DELAY),
    []
  );

  useEffect(() => {
    if (clearWhenOrganizationSelected && organizationSelected) {
      setValue('title', '');
      resetState();
    }
  }, [organizationSelected, clearWhenOrganizationSelected]);

  useEffect(() => {
    if (needInitialOptions) {
      fetch({ updateType: 'update', page: 0 });
    }
  }, []);

  return (
    <Controller
      control={control}
      name="title"
      rules={{ onChange: formSubmitHandlerDebounced }}
      render={({ field }) => (
        <InputWithList<Article>
          value={field.value}
          onChange={(event) => {
            field.onChange(event);
          }}
          options={articlesList}
          loading={loadingArticles}
          totalPage={totalPage}
          currentPage={pageNum}
          renderFunction={renderArticleFunction}
          setNextPage={setNextPageSystem}
          {...inputProps}
        />
      )}
    />
  );
};
