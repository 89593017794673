import Highcharts, { SeriesPieOptions } from 'highcharts';

export const getCurrentOptions = (
  days: SeriesPieOptions['data'],
  percents: SeriesPieOptions['data']
) => ({
  chart: {
    type: 'areaspline',
    backgroundColor: null,
  },
  title: {
    text: '',
  },
  credits: undefined,
  xAxis: {
    gridLineWidth: 0,
    categories: days,
  },
  yAxis: {
    gridLineWidth: 0.5,
    title: {
      text: '',
    },
    labels: {
      format: '{value} %',
    },
    min: 0,
    max: 100,
  },
  tooltip: {
    headerFormat: '{point.x}: ',
    pointFormat: '{point.y}%',
  },
  legend: {
    symbolPadding: 0,
    symbolWidth: 0,
    symbolHeight: 0,
    squareSymbol: false,
  },
  plotOptions: {
    areaspline: {
      lineWidth: 2,
      fillColor: {
        linearGradient: {
          x1: 0,
          y1: 0,
          x2: 0,
          y2: 1,
        },
        stops: [
          [0, 'var(--blue90)'],
          [1, Highcharts.color('var(--common2)').setOpacity(0).get('rgba')],
        ],
      },
    },
  },
  series: [
    {
      color: 'var(--blue90)',
      name: '',
      data: percents,
      marker: {
        radius: 0,
      },
    },
  ],
});
