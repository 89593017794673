import cn from 'clsx';
import { FC } from 'react';

import styles from './Cell.module.scss';

interface CellProps {
  className?: string;
}

export const Cell: FC<CellProps> = ({ className, children }) => (
  <div className={cn(styles.cell, className)}>{children}</div>
);
