import { FC, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
  getLoadingAdd,
  getPropsResponsibilitiesAdd,
  getResponsibilitiesAdd,
  getResponsibilitiesAddFilter,
  resetResponsibilitiesAddState,
  Responsibility,
  ResponsibilityFilter,
  setResponsibilitiesAddFilter,
  setResponsibilitiesAddPage,
  setSizePageResponsibilitiesAdd,
  TableResponsibilitiesTypes,
} from '@entities/responsibilities';
import { getWorkGroup, updateWorkGroup } from '@entities/work-groups';
import { AddModal, checkObjectIdentity } from '@shared';

import { COLUMNS_RESPONSIBILITIES_ADDED } from '../../config';
import { fetchFunction } from '../../lib';
import { ResponsibilitiesFilter } from '../ResponsibilitiesFilter';

import { getDataTable } from './utils';

interface AddResponsibilityToGroupProps {
  isModal: boolean;
  toggleModal: () => void;
}

export const AddResponsibilityToGroup: FC<AddResponsibilityToGroupProps> = ({
  isModal,
  toggleModal,
}) => {
  const dispatch = useDispatch();

  const responsibilityAll = useSelector(getResponsibilitiesAdd) || [];
  const groupData = useSelector(getWorkGroup);
  const filterAddValues = useSelector(getResponsibilitiesAddFilter);
  const loadingData = useSelector(getLoadingAdd);
  const { pageNum, pageSize, totalElements } = useSelector(
    getPropsResponsibilitiesAdd
  );

  const isFilterActive = !checkObjectIdentity(filterAddValues, {});

  const onSubmit = (data: Responsibility[]) => {
    if (groupData) {
      dispatch(
        updateWorkGroup({
          ...groupData,
          responsibilityList: data,
        })
      );
    }
    toggleModal();
  };

  const onChangeFilter = (filter: ResponsibilityFilter) => {
    dispatch(
      setResponsibilitiesAddFilter({
        ...filter,
        idsResponsibilitiesDelete: filterAddValues.idsResponsibilitiesDelete,
      })
    );
    dispatch(setResponsibilitiesAddPage(0));
    dispatch(fetchFunction[TableResponsibilitiesTypes.ADD_MODAL]());
  };

  const handleChangePage = (page: number) => {
    dispatch(setResponsibilitiesAddPage(page));
    dispatch(fetchFunction[TableResponsibilitiesTypes.ADD_MODAL]());
  };

  const handleChangePageSize = (size: number) => {
    dispatch(setSizePageResponsibilitiesAdd(size));
  };

  const onChangeIdsForDelete = (data: Responsibility[]) => {
    dispatch(
      setResponsibilitiesAddFilter({
        ...filterAddValues,
        idsResponsibilitiesDelete: data.map((item) => item.id),
      })
    );
    dispatch(fetchFunction[TableResponsibilitiesTypes.ADD_MODAL]());
  };

  useEffect(
    () => () => {
      dispatch(resetResponsibilitiesAddState());
    },
    []
  );

  return (
    <AddModal<Responsibility>
      leftData={responsibilityAll}
      rightData={groupData?.responsibilityList || []}
      getDataTable={getDataTable}
      columns={COLUMNS_RESPONSIBILITIES_ADDED}
      isModal={isModal}
      toggleModal={toggleModal}
      mainTitle="полномочия"
      subModalText="полномочия"
      leftTableTitle="Все полномочия"
      onSubmit={onSubmit}
      loadingData={loadingData}
      isFilterActive={isFilterActive}
      onChangeIdsForDelete={onChangeIdsForDelete}
      paginationLeftTableProps={{
        pageNum,
        pageSize,
        elementsCount: totalElements,
        handleChangePage,
        handleChangePageSize,
      }}
      filterComponent={
        <ResponsibilitiesFilter
          tableType={TableResponsibilitiesTypes.ADD_MODAL}
          onFilter={onChangeFilter}
        />
      }
    />
  );
};
