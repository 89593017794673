import { AttachmentsFiles } from '@entities/attachment';
import { getIsImage } from '@shared';

export const getAttachmentFilesComment = (
  files: AttachmentsFiles[] | undefined
) => {
  if (!files?.length) {
    return { imageFiles: [], documentFiles: [] };
  }

  const imageFiles = files.filter((item) => getIsImage(item.path));

  const documentFiles = files.filter((item) => !getIsImage(item.path));

  return { imageFiles, documentFiles };
};
