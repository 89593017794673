import { createSelector } from 'reselect';

import { Organization } from '@entities/organizations/model/types';
import { System } from '@entities/system/model/types';
import { SelectOption } from '@shared';

import { DesktopState } from './types';

export const getDesktopFilters = (state: DesktopState) => state.desktop.filters;

export const getOrganizations = (state: DesktopState) =>
  state.desktop.organizations;
export const getOrganizationsOptions = createSelector<
  DesktopState,
  Organization[] | undefined,
  SelectOption[]
>([getOrganizations], (organizations): SelectOption[] => {
  if (organizations) {
    return organizations.map(
      (organization: Organization): SelectOption => ({
        title: organization.title,
        value: organization.id || '',
      })
    );
  }
  return [];
});

export const getSystems = (state: DesktopState) => state.desktop.systems;
export const getSystemsOptions = createSelector<
  DesktopState,
  System[] | undefined,
  SelectOption[]
>([getSystems], (systemsOrganization): SelectOption[] => {
  if (systemsOrganization) {
    return systemsOrganization.map(
      (system: System): SelectOption => ({
        title: system.title,
        value: system.id,
      })
    );
  }
  return [];
});

export const getIsDesktopTicketsLoading = (state: DesktopState) =>
  state.desktop.isTicketsLoading;
