import cn from 'clsx';
import { forwardRef } from 'react';
import { useSelector } from 'react-redux';

import { getTicket } from '@entities/ticket';
import {
  Avatar,
  AvatarColor,
  Card,
  ConditionBlock,
  InfoBlock,
  Loader,
  MailIcon,
  PhoneIcon,
  Size,
  Tooltip,
} from '@shared';

import styles from './TicketClientInfo.module.scss';

interface TicketClientInfoProps {
  className?: string;
  isLoading?: boolean;
}

export const TicketClientInfo = forwardRef<
  HTMLDivElement,
  TicketClientInfoProps
>(({ className, isLoading }, ref) => {
  const ticket = useSelector(getTicket);

  const loader = isLoading && (
    <div className={styles.ticketClientInfo__loaderWrapper}>
      <Loader />
    </div>
  );

  const nameBlock = !isLoading && (
    <>
      <Avatar
        size={Size.m}
        color={AvatarColor.purple}
        initialsUser={ticket?.clientId?.value}
      />
      <div className={styles.ticketClientInfo__fio}>
        <span className={styles.ticketClientInfo__fioItem}>
          {`${ticket?.clientInfo?.lastName} ${ticket?.clientInfo?.firstName} ${ticket?.clientInfo?.middleName}`}
        </span>
      </div>
      <ConditionBlock
        text={ticket?.clientInfo?.organizationTitle}
        className={styles.ticketClientInfo__organization}
      />
    </>
  );

  return (
    <Card ref={ref} className={cn(styles.ticketClientInfo, className)}>
      <div className={styles.ticketClientInfo__info}>
        <div className={styles.ticketClientInfo__title}>
          <h3 className={styles.ticketClientInfo__titleText}>Клиент</h3>
        </div>
        <div className={styles.ticketClientInfo__nameBlock}>
          {loader}
          {nameBlock}
        </div>
        <div className={styles.ticketClientInfo__icons}>
          <InfoBlock
            data-tip
            data-for="mail"
            className={styles.ticketClientInfo__tooltip}
            classNameIconWrapper={styles.ticketClientInfo__tooltipContainer}
            icon={<MailIcon className={styles.ticketClientInfo__tooltipIcon} />}
          />
          {!isLoading && (
            <Tooltip id="mail">{ticket?.clientInfo?.email}</Tooltip>
          )}
          <InfoBlock
            data-tip
            data-for="phone"
            classNameIconWrapper={styles.ticketClientInfo__tooltipContainer}
            icon={
              <PhoneIcon className={styles.ticketClientInfo__tooltipIcon} />
            }
          />
          {!isLoading && (
            <Tooltip id="phone">{ticket?.clientInfo?.phoneNumber}</Tooltip>
          )}
        </div>
      </div>
    </Card>
  );
});
