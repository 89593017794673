import { FC, MouseEvent, useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
  CategoryArticle,
  createCategoryRequestArticleCreate,
  deleteArticleRequestArticleCreate,
  getCurrentArticle,
} from '@entities/knowledge-base';
import { KBArticleDrop, KBCreateCategory } from '@features/knowledge-base';
import {
  AddLineIcon,
  Card,
  DeleteApproveOrCancel,
  GoBackButton,
  IconButton,
  Popover,
  Typography,
  TypographyVariants,
} from '@shared';

import { useCreateArticleForm } from '../../../lib';
import { KBCreateArticleHeaderForm } from '../KBCreateArticleHeaderForm';

import styles from './KBCreateArticleHeader.module.scss';

interface KBCreateArticleHeaderProps {
  form: ReturnType<typeof useCreateArticleForm>;
  isEditMode?: boolean;
}

export const KBCreateArticleHeader: FC<KBCreateArticleHeaderProps> = ({
  form,
  isEditMode,
}) => {
  const dispatch = useDispatch();
  const [isDropOpen, setIsDropOpen] = useState<boolean>(false);
  const [isModal, setIsModal] = useState(false);

  const toggleModal = () => {
    setIsModal((prevState) => !prevState);
  };

  const article = useSelector(getCurrentArticle);

  const togglePopover = (event?: MouseEvent) => {
    event?.stopPropagation();
    setIsDropOpen((prevState) => !prevState);
  };

  const onCreateCategory = useCallback(
    (data?: Partial<CategoryArticle>) => {
      if (data) {
        dispatch(createCategoryRequestArticleCreate(data));
      }
    },
    [dispatch]
  );

  const onArticleDelete = (id: string) => {
    dispatch(deleteArticleRequestArticleCreate(id));
  };

  const handleDelete = () => {
    if (article?.id && onArticleDelete) {
      onArticleDelete(article.id);
    }
  };

  const dropContent = isEditMode ? (
    <KBArticleDrop
      withDeleteArticle
      toggleModal={toggleModal}
      article={article}
    />
  ) : (
    <KBCreateCategory
      onClick={onCreateCategory}
      togglePopover={togglePopover}
    />
  );

  return (
    <>
      <Card className={styles.KBCreateHeader}>
        <div className={styles.KBCreateHeader__titleWrapper}>
          <GoBackButton withLabel={false} />
          <Typography
            variant={TypographyVariants.h2}
            className={styles.KBCreateHeader__title}
          >
            {isEditMode ? 'Редактировать статью' : 'Создать статью'}
          </Typography>
        </div>
        <KBCreateArticleHeaderForm
          className={styles.KBCreateHeader__form}
          form={form}
        />
        <div className={styles.KBCreateHeader__popover}>
          <Popover
            content={dropContent}
            isOpen={isDropOpen}
            togglePopover={togglePopover}
            positions={['left', 'top', 'right', 'bottom']}
            withOutsideClick={false}
          >
            {isEditMode ? undefined : (
              <IconButton
                icon={<AddLineIcon />}
                onClick={togglePopover}
                appearance="flat"
              />
            )}
          </Popover>
        </div>
      </Card>
      <DeleteApproveOrCancel
        isModal={isModal}
        toggleModal={toggleModal}
        entityTitle="статью"
        onApprove={handleDelete}
      />
    </>
  );
};
