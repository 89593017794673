import { AdminsLayout } from '@pages/AdminsLayout';
import { Roles } from '@widgets/roles';

const RolesPage = () => (
  <AdminsLayout>
    <Roles />
  </AdminsLayout>
);

export default RolesPage;
