import { ApiClient, getEnv } from '@shared';

import {
  EvaluateSpecialistPayload,
  Improvement,
  TicketEvaluation,
} from '../../model/assessment-interaction';

import { assessmentInteractionEndpoints as endpoints } from './endpoints';

export const assessmentInteractionRequests = {
  fetchImprovements: (rating: number) =>
    ApiClient.get<Improvement[]>({
      baseURL: getEnv('REACT_APP_KPI_URL'),
      url: endpoints.getImprovements(rating),
    }),
  evaluateSpecialist: (payload: EvaluateSpecialistPayload) =>
    ApiClient.post<Improvement[]>({
      baseURL: getEnv('REACT_APP_KPI_URL'),
      url: endpoints.evaluateSpecialist(payload),
      data: payload.improvementsIds,
    }),
  fetchTicketEvaluation: (ticketId: string) =>
    ApiClient.get<TicketEvaluation>({
      baseURL: getEnv('REACT_APP_KPI_URL'),
      url: endpoints.getTicketEvaluation(ticketId),
    }),
};
