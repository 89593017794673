import { TicketStatus } from '@entities/ticket/model/types';

import { Accessibility, NodeType } from '../model';

export const REOPEN_STATUS_SETTINGS: NodeType = {
  label: 'Переоткрыт',
  color: 'orange90',
  accessibility: Accessibility.ALL,
  isNotifications: true,
  isSLA: true,
  isComment: true,
  defaultStatus: TicketStatus.REOPEN,
  deletable: true,
  modified: false,
};
