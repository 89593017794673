import { ApiClient, getEnv, KeyValueOption } from '@shared';

import {
  CreateRule,
  Rule,
  RulesAttributeToRequest,
  RulesFilterToRequest,
} from '../model';

import { endpoints } from './endpoints';

export const requests = {
  fetchFiltersFields: () =>
    ApiClient.get({
      baseURL: getEnv('REACT_APP_AUTO_ASSIGNING'),
      url: endpoints.getFiltersFields(),
    }),
  fetchRulesFields: (data: RulesAttributeToRequest) =>
    ApiClient.post<RulesAttributeToRequest>({
      baseURL: getEnv('REACT_APP_ORGANIZATIONS_URL'),
      url: endpoints.getRulesFields(),
      data,
    }),
  fetchSpecialists: () =>
    ApiClient.get<KeyValueOption[]>({
      baseURL: getEnv('REACT_APP_ORGANIZATIONS_URL'),
      url: endpoints.getSpecialists(),
    }),
  fetchWorkGroups: () =>
    ApiClient.get<KeyValueOption[]>({
      baseURL: getEnv('REACT_APP_ORGANIZATIONS_URL'),
      url: endpoints.getWorkGroups(),
    }),
  fetchRules: (
    pageNum = 0,
    pageSize?: number,
    sort?: string,
    filter?: RulesFilterToRequest
  ) =>
    ApiClient.post<Rule[]>({
      baseURL: getEnv('REACT_APP_AUTO_ASSIGNING'),
      url: endpoints.getRules(),
      params: { pageNum, pageSize, sort: sort?.toUpperCase() },
      data: {
        ...filter,
      },
    }),
  fetchCurrentRule: (id: string) =>
    ApiClient.get<Rule>({
      baseURL: getEnv('REACT_APP_AUTO_ASSIGNING'),
      url: endpoints.getCurrentRule(id),
      params: {},
      data: {},
    }),
  createRule: (data: CreateRule) =>
    ApiClient.post<Rule>({
      baseURL: getEnv('REACT_APP_AUTO_ASSIGNING'),
      url: endpoints.createRule(),
      data,
    }),
  updateRule: (data: CreateRule, id: string) =>
    ApiClient.patch<Rule>({
      baseURL: getEnv('REACT_APP_AUTO_ASSIGNING'),
      url: endpoints.editRule(id),
      data,
    }),
  deleteRule: (id: string) =>
    ApiClient.delete<boolean>({
      baseURL: getEnv('REACT_APP_AUTO_ASSIGNING'),
      url: endpoints.deleteRule(id),
    }),
};
