import { Responsibility } from '@entities/responsibilities';

export const getDataTable = (responsibilities?: Responsibility[]) =>
  responsibilities?.map((responsibility) => {
    const { title, description, id } = responsibility;
    return {
      id,
      title,
      description,
    };
  }) || [];
