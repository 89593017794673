import cn from 'clsx';
import { FC, InputHTMLAttributes } from 'react';

import { Orientation, TabItem } from '@shared/model';

import styles from './Radio.module.scss';
import { RadioItem } from './ui/RadioItem';

interface RadioProps extends InputHTMLAttributes<HTMLInputElement> {
  items: TabItem[];
  orientation?: Orientation;
}

export const Radio: FC<RadioProps> = ({
  className,
  items,
  value: currentValue,
  orientation = 'vertical',
  ...otherProps
}) => {
  const itemsEls = items.map(({ title, value, icon }) => (
    <RadioItem
      key={String(value)}
      value={value}
      title={title}
      checked={value === currentValue}
      icon={icon}
      {...otherProps}
    />
  ));

  return (
    <div
      className={cn(styles.radio, styles[`radio_${orientation}`], className)}
    >
      {itemsEls}
    </div>
  );
};
