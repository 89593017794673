import { Alert } from '../layouts/AlertsLayout/model';
import { BreadCrumb } from '../ui';

import {
  Core,
  SetAlertAction,
  SetBreadCrumbsConfigAction,
  SetHrefAction,
  SetIsContrastThemeAction,
  SetIsSystemThemeAction,
  SetRedirectPathAction,
  SetScreenWidthAction,
  SetThemeAction,
} from './actionTypes';
import { ScreenWidth, Theme, ThemeActive } from './types';

export const setScreenWidth = (
  screenWidth: ScreenWidth
): SetScreenWidthAction => ({
  type: Core.SET_SCREEN_WIDTH,
  payload: screenWidth,
});

export const setRedirectPath = (
  path: string | null
): SetRedirectPathAction => ({
  type: Core.SET_REDIRECT_PATH,
  payload: path,
});

export const setTheme = (theme: Theme): SetThemeAction => ({
  type: Core.SET_THEME,
  payload: theme,
});

export const setIsSystemTheme = (
  systemTheme: ThemeActive
): SetIsSystemThemeAction => ({
  type: Core.SET_IS_SYSTEM_THEME,
  payload: systemTheme,
});

export const setIsContrastTheme = (
  contrastTheme: ThemeActive
): SetIsContrastThemeAction => ({
  type: Core.SET_IS_CONTRAST_THEME,
  payload: contrastTheme,
});

export const setAlert = (alert: Alert): SetAlertAction => ({
  type: Core.SET_ALERT,
  payload: alert,
});

export const deleteAlert = (id: string) => ({
  type: Core.DELETE_ALERT,
  payload: id,
});

export const setBreadCrumbsConfig = (
  breadCrumbsConfig: BreadCrumb[]
): SetBreadCrumbsConfigAction => ({
  type: Core.SET_BREAD_CRUMBS_CONFIG,
  payload: breadCrumbsConfig,
});

export const setHref = (href: string): SetHrefAction => ({
  type: Core.SET_HREF,
  payload: href,
});
