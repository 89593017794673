import { OrganizationLayout } from '@features/organization';
import { Organization } from '@widgets/organization';

import styles from './OrganizationPage.module.scss';

const OrganizationPage = () => (
  <OrganizationLayout>
    <Organization className={styles.organizationPage__content} />
  </OrganizationLayout>
);

export default OrganizationPage;
