import { call, put, select, StrictEffect, takeEvery } from 'redux-saga/effects';

import { getUserOrganizationId } from '@entities/auth';
import { Organization } from '@entities/organizations';
import { WorkGroup } from '@entities/work-group';
import { createError, RouterHref, setRedirectPath } from '@shared';

import { requests } from '../../api/kb-create-article';
import {
  articleCreate,
  createCategory,
  deleteArticle,
  editArticle,
  fetchArticle,
  fetchCategory,
} from '../saga';
import { Article, CategoryArticle } from '../types';

import {
  createArticleRequestArticleCreate,
  createCategoryRequestArticleCreate,
  currentArticleLoadingHideArticleCreate,
  currentArticleLoadingShowArticleCreate,
  deleteArticleRequestArticleCreate,
  editArticleRequestArticleCreate,
  fetchCategoriesRequestArticleCreate,
  fetchCategoriesSuccessArticleCreate,
  fetchCurrentArticleRequestArticleCreate,
  fetchCurrentArticleSuccessArticleCreate,
  fetchOrganizationsSuccessArticleCreate,
  fetchSubCategoriesRequestArticleCreate,
  fetchSubCategoriesSuccessArticleCreate,
  fetchWorkGroupsRequestArticleCreate,
  fetchWorkGroupsSuccessArticleCreate,
} from './actions';
import { ArticleCreate } from './actionTypes';

function* articleCreateFunction({
  payload,
}: ReturnType<typeof createArticleRequestArticleCreate>) {
  try {
    const article: Article = yield call(articleCreate, payload);
    if (article?.id) {
      yield put(setRedirectPath(`${RouterHref.KnowledgeBase}/${article.id}`));
    }
  } catch (e) {
    createError(e);
  }
}

function* articleEditFunction({
  payload,
}: ReturnType<typeof editArticleRequestArticleCreate>) {
  try {
    yield call(editArticle, payload);
    if (payload?.id) {
      yield put(setRedirectPath(`${RouterHref.KnowledgeBase}/${payload.id}`));
    }
  } catch (e) {
    createError(e);
  }
}

function* organizationsFetch() {
  try {
    const organizations: Organization[] = yield call(
      requests.organizationsArticleRequest,
      {
        organizationTitle: '',
      }
    );
    yield put(fetchOrganizationsSuccessArticleCreate(organizations));
  } catch (e) {
    createError(e);
  }
}

function* workGroupsFetch({
  payload,
}: ReturnType<typeof fetchWorkGroupsRequestArticleCreate>) {
  try {
    const workGroups: WorkGroup[] = yield call(
      requests.fetchWorkGroups,
      payload
    );
    yield put(fetchWorkGroupsSuccessArticleCreate(workGroups));
  } catch (e) {
    createError(e);
  }
}

function* categoriesFetch({
  payload,
}: ReturnType<typeof fetchCategoriesRequestArticleCreate>) {
  try {
    const categories: CategoryArticle[] = yield call(
      requests.categoriesArticleRequest,
      payload
    );
    yield put(fetchCategoriesSuccessArticleCreate(categories));
  } catch (e) {
    createError(e);
  }
}

function* fetchAllCategories() {
  try {
    const categories: CategoryArticle[] = yield call(
      requests.fetchAllCategories
    );
    yield put(fetchCategoriesSuccessArticleCreate(categories));
  } catch (e) {
    createError(e);
  }
}

function* createCategoryFunction({
  payload,
}: ReturnType<typeof createCategoryRequestArticleCreate>) {
  try {
    const userOrgId: ReturnType<typeof getUserOrganizationId> = yield select(
      getUserOrganizationId
    );

    yield call(createCategory, payload);

    if (userOrgId) {
      yield call(categoriesFetch, {
        type: ArticleCreate.FETCH_CATEGORIES_REQUEST_ARTICLE_CREATE,
        payload: [userOrgId],
      });
    }
  } catch (e) {
    createError(e);
  }
}

function* fetchSubCategories({
  payload,
}: ReturnType<typeof fetchSubCategoriesRequestArticleCreate>) {
  try {
    const category: CategoryArticle = yield fetchCategory(payload);
    yield put(fetchSubCategoriesSuccessArticleCreate(category?.children || []));
  } catch (e) {
    createError(e);
  }
}

function* fetchArticleById({
  payload,
}: ReturnType<typeof fetchCurrentArticleRequestArticleCreate>) {
  try {
    yield put(currentArticleLoadingShowArticleCreate());
    const article: Article = yield call(fetchArticle, payload);
    yield put(fetchCurrentArticleSuccessArticleCreate(article));
    yield put(currentArticleLoadingHideArticleCreate());
  } catch (e) {
    createError(e);
    yield put(fetchCurrentArticleSuccessArticleCreate());
    yield put(currentArticleLoadingHideArticleCreate());
  }
}

function* deleteArticleRequest({
  payload,
}: ReturnType<typeof deleteArticleRequestArticleCreate>) {
  try {
    yield call(deleteArticle, payload);
    yield put(setRedirectPath(RouterHref.KnowledgeBase));
  } catch (e) {
    createError(e);
  }
}

export function* articleCreateSaga(): Generator<StrictEffect> {
  yield takeEvery(
    ArticleCreate.CREATE_ARTICLE_REQUEST_ARTICLE_CREATE,
    articleCreateFunction
  );
  yield takeEvery(
    ArticleCreate.EDIT_ARTICLE_REQUEST_ARTICLE_CREATE,
    articleEditFunction
  );
  yield takeEvery(
    ArticleCreate.FETCH_ORGANIZATIONS_REQUEST_ARTICLE_CREATE,
    organizationsFetch
  );
  yield takeEvery(
    ArticleCreate.FETCH_WORK_GROUPS_REQUEST_ARTICLE_CREATE,
    workGroupsFetch
  );
  yield takeEvery(
    ArticleCreate.FETCH_CATEGORIES_REQUEST_ARTICLE_CREATE,
    categoriesFetch
  );
  yield takeEvery(
    ArticleCreate.FETCH_ALL_CATEGORIES_REQUEST_ARTICLE_CREATE,
    fetchAllCategories
  );
  yield takeEvery(
    ArticleCreate.CREATE_CATEGORY_REQUEST_ARTICLE_CREATE,
    createCategoryFunction
  );
  yield takeEvery(
    ArticleCreate.FETCH_SUB_CATEGORIES_REQUEST_ARTICLE_CREATE,
    fetchSubCategories
  );
  yield takeEvery(
    ArticleCreate.FETCH_CURRENT_ARTICLE_REQUEST_ARTICLE_CREATE,
    fetchArticleById
  );
  yield takeEvery(
    ArticleCreate.DELETE_ARTICLE_REQUEST_ARTICLE_CREATE,
    deleteArticleRequest
  );
}
