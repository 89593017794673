import { RenderTypes, TableColumns } from '@shared';

export const ADD_MODAL_FROM_GROUPS_FIELDS: TableColumns = [
  {
    title: 'Логин',
    fieldName: 'login',
    renderType: RenderTypes.ICON_BLOCK,
  },
  { title: 'Фамилия', fieldName: 'lastName' },
  {
    title: 'Имя',
    fieldName: 'firstName',
  },
  {
    title: 'Отчество',
    fieldName: 'middleName',
  },
  {
    title: 'Организация',
    fieldName: 'organizationId',
  },
  {
    title: 'E-mail',
    fieldName: 'email',
    renderType: RenderTypes.ICON_BLOCK,
  },
  {
    title: 'Телефон',
    fieldName: 'phoneNumber',
    renderType: RenderTypes.ICON_BLOCK,
  },
];

export const INITIAL_USERS_FILTER = {
  login: '',
  lastName: '',
  firstName: '',
  middleName: '',
  email: '',
  phoneNumber: '',
  organizationId: null,
};

export const DEFAULT_SORT_FILTER_EXECUTOR = 'FIRSTNAME_ASC';
