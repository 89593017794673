import cn from 'clsx';
import { FC } from 'react';

import { Typography, TypographyVariants } from '../Typography';

import styles from './NumberBlock.module.scss';

interface NumberBlockProps {
  number: number;
  isRound?: boolean;
  isPurple?: boolean;
  className?: string;
}

export const NumberBlock: FC<NumberBlockProps> = ({
  number,
  isRound,
  isPurple,
  className,
}) => {
  const notificationsCount = number <= 99 ? number : 99;
  return (
    <div className={cn(styles.numberBlock, className)}>
      <Typography
        variant={TypographyVariants.h5}
        className={cn(styles.numberBlock__wrapper, {
          [styles.numberBlock__wrapper_purple]: isPurple,
          [styles.numberBlock__wrapper_round]: isRound,
        })}
      >
        {notificationsCount}
      </Typography>
    </div>
  );
};
