import { useSelector } from 'react-redux';

import { ActionList, getIsAccessedAction } from '@entities/auth';
import { DesktopHeader } from '@features/desktop';
import { MainLayout } from '@shared';
import { DesktopEmployees } from '@widgets/desktop';

import styles from './DesktopEmployeesPage.module.scss';

const DesktopEmployeesPage = () => {
  const isAccessToCreateTicket = useSelector(
    getIsAccessedAction(ActionList.CreateTicket)
  );

  return (
    <MainLayout
      customHeader={<DesktopHeader />}
      accessToCreateTicket={isAccessToCreateTicket}
    >
      <DesktopEmployees className={styles.desktopEmployeesPage__content} />
    </MainLayout>
  );
};

export default DesktopEmployeesPage;
