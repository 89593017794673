import { ActionWithCustomFields } from '@entities/actions/model/types';

export const sortActions = (actions: ActionWithCustomFields[]) => {
  const access: Record<string, ActionWithCustomFields[]> = {};
  actions?.forEach((item) => {
    access[item.accessCategory.id] = [
      ...(access[item.accessCategory.id] || []),
      {
        ...item,
      },
    ] as ActionWithCustomFields[];
  });
  return access;
};
