import { NotificationContent } from './types';

export enum Notifications {
  SET_ALL_NOTIFICATIONS = 'NOTIFICATIONS/SET_ALL_NOTIFICATIONS',
  ADD_CURRENT_NOTIFICATION = 'NOTIFICATIONS/ADD_CURRENT_NOTIFICATION',
  DELETE_CURRENT_NOTIFICATION = 'NOTIFICATIONS/DELETE_CURRENT_NOTIFICATION',
  DELETE_NOTIFICATION_BY_ID = 'NOTIFICATIONS/DELETE_NOTIFICATION_BY_ID',
  DELETE_ALL_CURRENT_NOTIFICATIONS = 'NOTIFICATIONS/DELETE_ALL_CURRENT_NOTIFICATIONS',
  SET_NOTIFICATIONS_MY_TICKERS_IDS = 'NOTIFICATIONS/SET_NOTIFICATIONS_MY_TICKERS_IDS',
  SET_NOTIFICATIONS_NEW_TICKERS_IDS = 'NOTIFICATIONS/SET_NOTIFICATIONS_NEW_TICKERS_IDS',
  SET_ACTIVE_NOTIFICATION_ID = 'NOTIFICATIONS/SET_ACTIVE_NOTIFICATION_ID',
}

export interface SetAllNotificationsAction {
  type: Notifications.SET_ALL_NOTIFICATIONS;
  payload: NotificationContent[];
}

export interface AddCurrentNotificationAction {
  type: Notifications.ADD_CURRENT_NOTIFICATION;
  payload: NotificationContent;
}

export interface DeleteCurrentNotificationAction {
  type: Notifications.DELETE_CURRENT_NOTIFICATION;
  payload: string;
}

export interface DeleteNotificationByIdAction {
  type: Notifications.DELETE_NOTIFICATION_BY_ID;
  payload: string;
}

export interface DeleteAllCurrentNotificationsAction {
  type: Notifications.DELETE_ALL_CURRENT_NOTIFICATIONS;
}

export interface SetNotificationsMyTicketsIdsAction {
  type: Notifications.SET_NOTIFICATIONS_MY_TICKERS_IDS;
  payload: string[];
}

export interface SetNotificationsNewTicketsIdsAction {
  type: Notifications.SET_NOTIFICATIONS_NEW_TICKERS_IDS;
  payload: string[];
}

export interface SetActiveNotificationIdAction {
  type: Notifications.SET_ACTIVE_NOTIFICATION_ID;
  payload: string;
}

export type NotificationsActionsTypes =
  | AddCurrentNotificationAction
  | DeleteCurrentNotificationAction
  | SetNotificationsMyTicketsIdsAction
  | SetNotificationsNewTicketsIdsAction
  | DeleteAllCurrentNotificationsAction
  | DeleteNotificationByIdAction
  | SetAllNotificationsAction
  | SetActiveNotificationIdAction;
