import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

import {
  ActionList,
  getIsAccessedAction,
  getIsClientPartner,
  getIsManagerClients,
  getIsOrgTypePartner,
  getManagerWorkGroup,
  getManagerWorkGroupsList,
} from '@entities/auth';
import {
  getKpiOrganizationsOptions,
  getKpiSystemsOptions,
} from '@entities/desktop';
import {
  checkPathnameWithTrailingSlash,
  getIsMobile,
  getIsMobileSmall,
  RouterHref,
} from '@shared';

export const useHeaderForm = () => {
  const { pathname } = useLocation();

  const isMobile = useSelector(getIsMobile);
  const isMobileSmall = useSelector(getIsMobileSmall);
  const isWorkGroupManager = useSelector(getManagerWorkGroup);
  const isPartner = useSelector(getIsOrgTypePartner);
  const isClientPartner = useSelector(getIsClientPartner);
  const isAccessToTakeTicketToWork = useSelector(
    getIsAccessedAction(ActionList.TakeTicketToWork)
  );
  const isAccessToCreateReportByOrg = useSelector(
    getIsAccessedAction(ActionList.CreateReportByOrganization)
  );
  const isAccessToCreateReportByMyOrg = useSelector(
    getIsAccessedAction(ActionList.CreateReportByMyOrg)
  );
  const isAccessToCreateTicket = useSelector(
    getIsAccessedAction(ActionList.CreateTicket)
  );

  const managerWorkGroupsList = useSelector(getManagerWorkGroupsList);
  const organizationsOptions = useSelector(getKpiOrganizationsOptions);
  const systemsOptions = useSelector(getKpiSystemsOptions);
  const isManagerClients = useSelector(getIsManagerClients);

  const isDesktopKpiPage = checkPathnameWithTrailingSlash(
    pathname,
    RouterHref.DesktopKpi
  );
  const isDesktopPage = checkPathnameWithTrailingSlash(
    pathname,
    RouterHref.DesktopCurrentState
  );
  const isDesktopHistoryPage = checkPathnameWithTrailingSlash(
    pathname,
    RouterHref.DesktopHistory
  );

  const isMobileAll = isMobile || isMobileSmall;

  const hideDesktopKpi = isPartner
    ? isClientPartner && isAccessToCreateTicket && !isAccessToTakeTicketToWork
    : !isAccessToTakeTicketToWork;

  const hideCurrentState = !isAccessToCreateReportByOrg && !isWorkGroupManager;

  const hideHistory = isManagerClients
    ? !isAccessToCreateReportByMyOrg
    : !isAccessToCreateReportByOrg;

  const hideEmployees = !isWorkGroupManager || isAccessToCreateReportByMyOrg;

  return {
    state: {
      isDesktopKpiPage,
      isDesktopPage,
      isDesktopHistoryPage,
      isMobileAll,
      managerWorkGroupsList,
      organizationsOptions,
      systemsOptions,
      hideDesktopKpi,
      hideCurrentState,
      hideHistory,
      hideEmployees,
    },
  };
};
