import { throttle } from 'lodash';
import {
  ChangeEvent,
  MouseEvent,
  MutableRefObject,
  useCallback,
  useState,
} from 'react';

import { SelectOption, SelectProps, ValueType } from '../../model';
import { getOptionValue } from '../utils';

type UseMethodsSelectProps<T> = Pick<
  SelectProps<T>,
  | 'isSearchable'
  | 'open'
  | 'defaultInputValue'
  | 'onChangeInput'
  | 'maxSelectedOptions'
  | 'onChange'
  | 'value'
  | 'setNextPage'
  | 'isMulti'
  | 'isMultiSearch'
  | 'isClearable'
  | 'onClickAddComponent'
> & {
  isMobileAll?: boolean;
  inputRef?: MutableRefObject<HTMLInputElement | null>;
};

export const useMethodsSelect = <T>({
  isMobileAll,
  isSearchable,
  open,
  defaultInputValue = '',
  onChangeInput,
  maxSelectedOptions,
  onChange,
  value: valueProp,
  isMulti,
  isMultiSearch,
  setNextPage,
  isClearable,
  inputRef,
  onClickAddComponent,
}: // eslint-disable-next-line sonarjs/cognitive-complexity
UseMethodsSelectProps<T>) => {
  const getInitialOption = (): ValueType<T> => {
    if (valueProp) {
      return valueProp;
    }
    if (isMulti) {
      return [];
    }
    return null;
  };
  const initialOption: ValueType<T> = getInitialOption();

  const [openDropdown, toggleDropdown] = useState<boolean>(Boolean(open));
  const [optionSelected, setOptionSelected] =
    useState<ValueType<T>>(initialOption);
  const [inputValue, setInputValue] = useState<string>(defaultInputValue);

  const handleToggleDropdown = (): void => {
    if (!isSearchable || isMobileAll || isMultiSearch) {
      toggleDropdown(!openDropdown);
      return;
    }
    toggleDropdown(true);
  };

  const handleChangeInput = (event: ChangeEvent<HTMLInputElement>): void => {
    event.stopPropagation();
    event.nativeEvent.stopImmediatePropagation();

    const { value: text } = event.target;
    setInputValue(text);
    if (onChangeInput) {
      onChangeInput(text);
    }
  };

  const getIsOptionSelected = (
    option: SelectOption<T>,
    selectedOptions: ValueType<T>
  ): boolean => {
    if (selectedOptions instanceof Array) {
      const optionValue = getOptionValue(option);
      return selectedOptions.some(
        (selected: SelectOption<T>): boolean =>
          getOptionValue(selected) === optionValue
      );
    }
    return selectedOptions?.value === option.value;
  };

  const changeSelectedOption = (
    nextValue: SelectOption<T>[] | SelectOption<T>
  ) => {
    setOptionSelected(nextValue);

    if (onChange) {
      onChange(nextValue);
    }
  };

  const handleDeleteMultiOption = useCallback(
    (option: SelectOption<T>) =>
      (event: MouseEvent<HTMLButtonElement>): void => {
        event.stopPropagation();
        event.preventDefault();
        // eslint-disable-next-line @typescript-eslint/no-shadow
        const getNextSelectOptions = (selectedOptions: SelectOption<T>[]) => {
          const valueToDelete = getOptionValue(option);

          if (getIsOptionSelected(option, selectedOptions))
            return selectedOptions.filter(
              (i: SelectOption<T>): boolean =>
                getOptionValue(i) !== valueToDelete
            );

          return maxSelectedOptions &&
            selectedOptions?.length >= maxSelectedOptions
            ? selectedOptions
            : [...selectedOptions, option];
        };

        const nextOptions = getNextSelectOptions(
          optionSelected as SelectOption<T>[]
        );

        changeSelectedOption(nextOptions);
      },
    [onChange, onChangeInput, isMulti, optionSelected]
  );

  const fetchNextData = setNextPage ? throttle(setNextPage, 2000) : undefined;

  const handleCloseDropdown = useCallback((): void => {
    if (
      isSearchable &&
      openDropdown &&
      onChangeInput &&
      !(initialOption instanceof Array)
    ) {
      setInputValue('');
      onChangeInput('');
    }
    if (openDropdown) {
      toggleDropdown(false);
    }
  }, [openDropdown, isSearchable, initialOption]);

  const handleClear = useCallback(
    (event: MouseEvent): void => {
      event.stopPropagation();
      if (isClearable && optionSelected) {
        const emptyValue: [] | null = isMulti ? [] : null;

        setOptionSelected(emptyValue);

        if (onChange) {
          onChange(emptyValue);
        }
      }
    },
    [isClearable, optionSelected, isMulti, onChange]
  );

  const handleChange = useCallback(
    (option: SelectOption<T>) => (): void => {
      // eslint-disable-next-line @typescript-eslint/no-shadow
      const getNextOptions = (selectedOptions: SelectOption<T>[]) => {
        const candidateValue = getOptionValue(option);

        if (getIsOptionSelected(option, selectedOptions))
          return selectedOptions.filter(
            (i: SelectOption<T>): boolean =>
              getOptionValue(i) !== candidateValue
          );

        return maxSelectedOptions &&
          selectedOptions?.length >= maxSelectedOptions
          ? selectedOptions
          : [...selectedOptions, option];
      };

      const nextOptions =
        optionSelected instanceof Array && isMulti
          ? getNextOptions(optionSelected)
          : option;

      changeSelectedOption(nextOptions);

      if (!isMulti) {
        toggleDropdown(false);
      }

      setInputValue('');

      inputRef?.current?.focus();
    },
    [onChange, isMulti, optionSelected]
  );

  const handleClickAddEntity = (event: MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    event.stopPropagation();
    if (onClickAddComponent && inputValue && onChangeInput) {
      onClickAddComponent(inputValue.toString());
      onChangeInput(inputValue.toString());
    }
  };

  return {
    values: {
      openDropdown,
      inputValue,
      optionSelected,
    },
    methods: {
      setInputValue,
      getIsOptionSelected,
      handleToggleDropdown,
      handleChangeInput,
      setOptionSelected,
      handleDeleteMultiOption,
      fetchNextData,
      handleCloseDropdown,
      handleClear,
      handleChange,
      handleClickAddEntity,
    },
  };
};
