import { isNonNil } from '../../../../lib';
import { ValueType } from '../../model';

import { getValueFromValueType } from './getOptionValue';

export const convertValueTypeToValue = <T>(
  option?: ValueType<T>
): T | undefined =>
  option && !Array.isArray(option) ? option.value : undefined;

export const convertValueTypeToArrayValue = <T>(options?: ValueType<T>) => {
  const result =
    options && Array.isArray(options)
      ? options?.map(getValueFromValueType)
      : [options?.value];

  return result.filter(isNonNil);
};

export const convertValueTypeToTitle = <T>(
  option?: ValueType<T>
): string | undefined =>
  option && !Array.isArray(option) ? option.title : undefined;
