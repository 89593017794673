import cn from 'clsx';
import { FC } from 'react';

import { TableResponsibilitiesTypes } from '@entities/responsibilities';
import {
  ConditionBlock,
  DeleteButton,
  PopoverContainer,
  TableContainer,
} from '@shared';

import { PAGINATION_RANGE_MAP } from '../../config';
import { useResponsibilitiesTableContainer } from '../../lib';
import { AddResponsibilityToGroup } from '../AddResponsibilityToGroup';
import { AddResponsibilityToRole } from '../AddResponsibilityToRole';
import { ResponsibilitiesFullTable } from '../ResponsibilitiesFullTable';
import { ResponsibilitiesShortTable } from '../ResponsibilitiesShortTable';
import { ResponsibilityCreate } from '../ResponsibilityCreate';

import styles from './ResponsibilitiesTableContainer.module.scss';

interface ResponsibilitiesTableContainerProps {
  tableType: TableResponsibilitiesTypes;
}

export const ResponsibilitiesTableContainer: FC<
  ResponsibilitiesTableContainerProps
> = ({ tableType }) => {
  const { methods, state } = useResponsibilitiesTableContainer(tableType);

  const {
    pageNum,
    pageSize,
    sortResponsibilities,
    totalElements,
    responsibilities,
    currentGroupId,
    groupTitle,
    isAccessToDeleteResponsibility,
    responsibility,
    modalCreateOpen,
    showFilterRow,
    showDeleteModal,
    changeModals,
    isFilterActive,
    searchDisabled,
    responsibilitiesTitle,
    getCreateTableDisable,
    createTableTitle,
    createIcon,
    currentResponsibilityId,
    loadingResponsibilities,
  } = state;

  const {
    toggleIsOpenChangeModals,
    handleEditClick,
    toggleDeleteModal,
    toggleFilterRow,
    handleSort,
    onRowClick,
    handleDelete,
    handleShowModalDelete,
    handleChangePage,
    handleChangePageSize,
    handleAddResponsibility,
    getCreateFunction,
  } = methods;

  const additionalHeaderComponent =
    tableType === TableResponsibilitiesTypes.FROM_GROUPS &&
    currentGroupId &&
    groupTitle ? (
      <ConditionBlock text={groupTitle} />
    ) : undefined;

  const dropContent =
    tableType === TableResponsibilitiesTypes.FULL &&
    isAccessToDeleteResponsibility ? (
      <PopoverContainer>
        <DeleteButton
          onClick={handleShowModalDelete}
          disabled={!responsibility}
        />
      </PopoverContainer>
    ) : undefined;

  const getTableComponent: Record<
    TableResponsibilitiesTypes,
    () => JSX.Element | null
  > = {
    [TableResponsibilitiesTypes.FULL]: () => (
      <ResponsibilitiesFullTable
        responsibilities={responsibilities}
        handleSort={handleSort}
        sort={sortResponsibilities}
        handleRowClick={onRowClick}
        activeRowId={currentResponsibilityId}
        showFilterRow={showFilterRow}
        isFilterActive={isFilterActive}
        handleClickResponsibilityEdit={handleEditClick}
      />
    ),
    [TableResponsibilitiesTypes.FROM_GROUPS]: () => (
      <ResponsibilitiesShortTable
        responsibilities={responsibilities}
        showFilterRow={showFilterRow}
        handleSort={handleSort}
        sort={sortResponsibilities}
        isFilterActive={isFilterActive}
        tableType={tableType}
      />
    ),
    [TableResponsibilitiesTypes.FROM_ROLES]: () => (
      <ResponsibilitiesShortTable
        key="role"
        responsibilities={responsibilities}
        showFilterRow={showFilterRow}
        handleSort={handleSort}
        sort={sortResponsibilities}
        isFilterActive={isFilterActive}
        tableType={tableType}
      />
    ),
    [TableResponsibilitiesTypes.ADD_MODAL]: () => null,
  };

  const getContent = () => getTableComponent[tableType]();

  const getResponsibilitiesModal = {
    [TableResponsibilitiesTypes.FULL]: () =>
      changeModals.isOpenModal && (
        <ResponsibilityCreate
          isModal={changeModals.isOpenModal}
          toggleModal={toggleIsOpenChangeModals}
          isEditMode={changeModals.isEditMode}
        />
      ),
    [TableResponsibilitiesTypes.FROM_GROUPS]: () =>
      modalCreateOpen && (
        <AddResponsibilityToGroup
          isModal={modalCreateOpen}
          toggleModal={handleAddResponsibility}
        />
      ),
    [TableResponsibilitiesTypes.FROM_ROLES]: () =>
      modalCreateOpen && (
        <AddResponsibilityToRole
          isModal={modalCreateOpen}
          toggleModal={handleAddResponsibility}
        />
      ),
  };

  const modalCreate =
    getResponsibilitiesModal[
      tableType as keyof typeof getResponsibilitiesModal
    ]();

  return (
    <TableContainer
      classNameRoot={cn(styles.responsibilitiesTableContainer, {
        [styles.responsibilitiesTableContainer_full]:
          tableType === TableResponsibilitiesTypes.FULL,
        [styles.responsibilitiesTableContainer_fromGroups]:
          tableType === TableResponsibilitiesTypes.FROM_GROUPS,
        [styles.responsibilitiesTableContainer_fromRoles]:
          tableType === TableResponsibilitiesTypes.FROM_ROLES,
      })}
      headerProps={{
        title: responsibilitiesTitle,
        createTitle: createTableTitle,
        icon: createIcon,
        createHandler: getCreateFunction(),
        additionalComponent: additionalHeaderComponent,
        createDisabled:
          getCreateTableDisable[
            tableType as keyof typeof getCreateTableDisable
          ],
        searchDisabled,
        toggleSearch: toggleFilterRow,
        isSearchOpen: showFilterRow,
        dropContent,
        loading: loadingResponsibilities,
      }}
      contentTable={
        <>
          {getContent()}
          {modalCreate}
        </>
      }
      countRecordsProps={{
        records: totalElements,
      }}
      paginationProps={{
        pageNum,
        elementsCount: totalElements,
        pageSize,
        marginPagesDisplayed: PAGINATION_RANGE_MAP[tableType],
        handleChangePage,
        handleChangePageSize,
        disabled: loadingResponsibilities,
        entityName: `RESPONSIBILITIES_${tableType}`,
      }}
      approveOrCancelProps={{
        onApprove: handleDelete,
        isModal: showDeleteModal,
        toggleModal: toggleDeleteModal,
        approveTitle: 'Удалить',
        approveBtnProps: {
          appearance: 'outline',
          className: styles.responsibilitiesTableContainer__iconDelete,
        },
        cancelTitle: 'Отмена',
        text: `Вы уверены, что хотите удалить полномочие ${responsibility?.title}?`,
      }}
    />
  );
};
