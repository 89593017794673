import { RoleType } from '@entities/roles';

const ROLE_IN_GROUP_TAB = {
  title: 'Роль в группе',
  value: RoleType.ROLE_IN_GROUP,
};

const ROLE_IN_SYSTEM_TAB = {
  title: 'Роль в системе',
  value: RoleType.ROLE_IN_SYSTEM,
};

export const SLIDE_RADIO_TABS_DEFAULT = [ROLE_IN_GROUP_TAB];

export const SLIDE_RADIO_TABS_SERVICE_ADMIN = [
  ...SLIDE_RADIO_TABS_DEFAULT,
  ROLE_IN_SYSTEM_TAB,
];
