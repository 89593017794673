import { useEffect } from 'react';
import { useDispatch } from 'react-redux';

import {
  fetchAttributesFieldsRequest,
  fetchFiltersFieldsRequest,
  fetchTriggersRequest,
  INITIAL_ATTRIBUTES_VALUES,
  resetTriggersState,
} from '@entities/triggers';
import { TablesLayout } from '@shared';

import { TriggersTableContainer } from '../TriggersTableContainer';

import styles from './Triggers.module.scss';

const MainTable = () => <TriggersTableContainer />;

const Triggers = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchTriggersRequest());
    dispatch(fetchFiltersFieldsRequest());
    dispatch(fetchAttributesFieldsRequest(INITIAL_ATTRIBUTES_VALUES));

    return () => {
      dispatch(resetTriggersState());
    };
  }, []);

  return (
    <TablesLayout
      MainTable={[MainTable]}
      classNameRoot={styles.triggers}
      classNameSubTables={styles.triggers__subTable}
      classNameMainTables={styles.triggers__mainTable}
      SubTable={[]}
    />
  );
};

export default Triggers;
