import { FC } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { ActionList, getIsAccessedAction } from '@entities/auth';
import { useCanCreateArticleOrCategory } from '@entities/knowledge-base';
import {
  Button,
  getScreenWidth,
  IconButton,
  MainLayout,
  PlusIcon,
  RouterHref,
  ScreenWidth,
} from '@shared';

import styles from './KBLayout.module.scss';

interface KBLayoutProps {
  className?: string;
}

export const KBLayout: FC<KBLayoutProps> = ({ children, className }) => {
  const { push } = useHistory();
  const screenWidth = useSelector(getScreenWidth);

  const isAccessToCreateTicket = useSelector(
    getIsAccessedAction(ActionList.CreateTicket)
  );

  const isMobile = screenWidth === ScreenWidth.MOBILE;
  const isMobileSmall = screenWidth === ScreenWidth.MOBILE_SMALL;
  const isMobileAll = isMobile || isMobileSmall;

  const canCreateArticle = useCanCreateArticleOrCategory();

  const showButtonCreateDesktop = canCreateArticle && !isMobileAll;
  const showButtonCreateMobile = canCreateArticle && isMobileAll;

  const onCreateLBArticle = () => {
    push(RouterHref.KnowledgeBaseArticleCreate);
  };

  const addButton = (
    <IconButton
      icon={<PlusIcon />}
      appearance="flat"
      className={styles.KBLayout__add}
      onClick={onCreateLBArticle}
    />
  );

  return (
    <MainLayout
      title="База знаний"
      classNameRoot={className}
      customComponent={showButtonCreateMobile ? addButton : undefined}
      slideSetting={
        showButtonCreateDesktop ? (
          <Button icon={<PlusIcon />} onClick={onCreateLBArticle}>
            Создать статью
          </Button>
        ) : undefined
      }
      accessToCreateTicket={isAccessToCreateTicket}
    >
      {children}
    </MainLayout>
  );
};
