import { validSupplementaryAgreementMap } from '@entities/contract';
import { convertFromDateFormat } from '@shared';

export const endAfterStart = (start: string, end: string) => {
  const startDateTime = convertFromDateFormat(start)?.getTime();
  const endDateTime = convertFromDateFormat(end)?.getTime();
  if (startDateTime && endDateTime && startDateTime > endDateTime) {
    return validSupplementaryAgreementMap.END_AFTER_START_DATE;
  }
  return undefined;
};

export const endAfterDateContract = (
  closeDate: string,
  closeContractDate?: string
) => {
  const closeDateTime = convertFromDateFormat(closeDate)?.getTime();

  const closeDateContactConvert = new Date(closeContractDate || '');
  if (
    closeContractDate &&
    closeDateTime &&
    new Date(
      closeDateContactConvert.getFullYear(),
      closeDateContactConvert.getMonth(),
      closeDateContactConvert.getDate()
    )?.getTime() < closeDateTime
  ) {
    return validSupplementaryAgreementMap.VALID_BEYOND_CONTRACT_END_DATE;
  }
  return undefined;
};

export const startAfterDateContract = (
  startDate: string,
  startDateContract?: string
) => {
  const startDateTime = convertFromDateFormat(startDate)?.getTime();

  const startDateContactConvert = new Date(startDateContract || '');

  if (
    startDateContract &&
    startDateTime &&
    new Date(
      startDateContactConvert.getFullYear(),
      startDateContactConvert.getMonth(),
      startDateContactConvert.getDate()
    )?.getTime() > startDateTime
  ) {
    return validSupplementaryAgreementMap.VALID_BEYOND_CONTRACT_START_DATE;
  }
  return undefined;
};
