import cn from 'clsx';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { Priority, Ticket } from '@entities/ticket/model/types';
import { Languages, Typography, TypographyVariants } from '@shared';

import { getPriorityByTitleTitleMap } from '../../../../lib';

import styles from './CurrentTicketHeader.module.scss';

const priorityStyleMap = {
  [Priority.NONE]: styles.currentTicketHeader__titleBlock_none,
  [Priority.LOW]: styles.currentTicketHeader__titleBlock_low,
  [Priority.MIDDLE]: styles.currentTicketHeader__titleBlock_middle,
  [Priority.HIGH]: styles.currentTicketHeader__titleBlock_high,
  [Priority.VERY_HIGH]: styles.currentTicketHeader__titleBlock_veryHigh,
};

interface CurrentTicketHeaderProps {
  ticket?: Ticket;
}

export const CurrentTicketHeader: FC<CurrentTicketHeaderProps> = ({
  ticket,
}) => {
  const { t, i18n } = useTranslation('notifications');

  const priorities = getPriorityByTitleTitleMap(i18n.language as Languages)[
    ticket?.priority || Priority.NONE
  ];

  return (
    <>
      <div className={styles.currentTicketHeader__titleBlockWrapper}>
        <div
          className={cn(
            styles.currentTicketHeader__titleBlock,
            ticket?.priority && priorityStyleMap[ticket?.priority]
          )}
        >
          <div className={styles.currentTicketHeader__titleBlockSubWrapper}>
            <Typography
              variant={TypographyVariants.h2}
              className={styles.currentTicketHeader__title}
            >
              {`${t('notification.ticketLinkTitle')} #${ticket?.number}`}
            </Typography>
            <div className={styles.currentTicketHeader__priorityBlock}>
              <Typography
                variant={TypographyVariants.o}
                className={styles.currentTicketHeader__priority}
              >
                {priorities}
              </Typography>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
