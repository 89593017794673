import { getUrl } from '@shared';

import { FetchArgs } from '../model';

export const endpoints = {
  getJiraIntegrations: () => getUrl('jira-properties/properties/filter'),
  getCheckConnectionToJira: () => getUrl('jira-properties/properties/check'),
  getJiraStatuses: (idType: string) =>
    getUrl(`jira-issue-status/issue-type/${idType}`),
  getJiraPriorities: () => getUrl('jira-issue-priority/by-project'),
  getJiraPrioritiesByIntegrationId: ({ systemId, typeId }: FetchArgs) =>
    getUrl(`jira-issue-priority/system/${systemId}/type/${typeId}`),
  getCreateJiraIntegration: () => getUrl('jira-properties/properties'),
  getEditJiraIntegration: (issueKey: string) =>
    getUrl(`jira-issue/update/${issueKey}`),
  getMappingTicketTypes: () => getUrl('jira-issue-type/mapping'),
  getMappingStatuses: () => getUrl('jira-issue-status/mapping'),
  getEditMappingStatuses: () => getUrl('jira-issue-status/mapping'),
  getMappingPriorities: () => getUrl('jira-issue-priority/mapping'),
  getEditMappingPriorities: () => getUrl('jira-issue-priority/mapping'),
  getJiraStatusesById: ({ systemId, typeId }: FetchArgs) =>
    getUrl(`jira-issue-status/system/${systemId}/type/${typeId}`),
  getJiraPrioritiesBySystemId: ({ systemId, typeId }: FetchArgs) =>
    getUrl(`jira-issue-priority/mapping/system/${systemId}/type/${typeId}`),
  getDeleteJiraIntegration: (id: string) => getUrl(`jira-properties/${id}`),
  getFetchJiraIntegration: (id: string) =>
    getUrl(`jira-properties/properties/${id}`),
  getEditJiraIntegrationActive: () => getUrl('jira-properties/active'),
  getSpFields: ({ systemId, typeId }: FetchArgs) =>
    getUrl(`jira-ticket/fields-ticket/system/${systemId}/type/${typeId}`),
  getJiraFields: ({ systemId, typeId }: FetchArgs) =>
    getUrl(`jira-fields/system/${systemId}/type/${typeId}`),
  getFieldsIntegrations: ({ systemId, typeId }: FetchArgs) =>
    getUrl(`jira-fields/mapping/system/${systemId}/type/${typeId}`),
  getCreateFieldsIntegration: ({ systemId, typeId }: FetchArgs) =>
    getUrl(`jira-fields/mapping/system/${systemId}/type/${typeId}`),
  getDeleteFieldsIntegration: (id: string) =>
    getUrl(`jira-fields/mapping/${id}`),
  getEditFieldsIntegration: () => getUrl('jira-integration/update-mapping'),
  getSystemPriorities: (systemId: string) =>
    getUrl(`priority/priority-system/${systemId}`),
  getSPStatuses: ({ systemId, typeId }: FetchArgs) =>
    getUrl(`jira-ticket/ticket-statuses/system/${systemId}/type/${typeId}`),
  getTicketTypes: (systemId: string) => getUrl(`system/${systemId}`),
  getJiraTicketType: () => getUrl(`jira-issue-type/by-project`),
  getEditMappingJiraTicketType: () => getUrl(`jira-issue-type/mapping`),
  getJiraTicketTypesByIntegrationId: (propertyId: string) =>
    getUrl(`jira-issue-type/by-property-id/${propertyId}`),
  getJiraTicketTypeByIntegrationId: (propertyId: string) =>
    getUrl(`jira-issue-type/mapping/property/${propertyId}`),
};
