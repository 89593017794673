import cn from 'clsx';
import { InputHTMLAttributes } from 'react';

import { Size, TabItem } from '@shared/model';

import styles from './RadioSlide.module.scss';

interface RadioSlideProps<T>
  extends Omit<InputHTMLAttributes<HTMLInputElement>, 'size'> {
  items: TabItem<T>[];
  size?: Size;
  classNameTitleLabel?: string;
  classNameIconLabel?: string;
  classNameLabelBlock?: string;
}

export const RadioSlide = <T extends string | number>(
  props: RadioSlideProps<T>
) => {
  const {
    className,
    items,
    classNameTitleLabel,
    classNameIconLabel,
    classNameLabelBlock,
    disabled,
    value: currentValue,
    size = Size.m,
    ...otherProps
  } = props;

  const itemsEls = items.map(
    ({ title, onClick, icon: Icon, value, defaultChecked, checked }) => {
      const isChecked = currentValue === value;

      return (
        <label
          key={value}
          className={cn(styles.radioSlide__label, {
            [styles.radioSlide__label_disabled]: disabled,
          })}
        >
          <input
            type="radio"
            value={value}
            checked={checked || isChecked}
            disabled={disabled}
            onClick={onClick}
            defaultChecked={defaultChecked}
            className={styles.radioSlide__input}
            {...otherProps}
          />
          <span className={classNameLabelBlock}>
            <div className={cn(styles.radioSlide__title, classNameTitleLabel)}>
              {title}
            </div>
            <div className={classNameIconLabel}>{Icon}</div>
          </span>
        </label>
      );
    }
  );

  return (
    <div
      className={cn(
        styles.radioSlide,
        styles[`radio-slide_${size}`],
        className
      )}
    >
      {itemsEls}
    </div>
  );
};
