import { JiraPriority } from '@entities/jira-integrations';
import { Priority } from '@entities/ticket';
import { isNonNil } from '@shared';

export const getPreparedPriorities = (jiraPriority?: JiraPriority[]) =>
  Object.values(Priority)
    .map((priority) => {
      const existingTicketPriority = jiraPriority?.find((item) =>
        item?.ticketPriorities?.find((el) => el.includes(priority))
      );

      if (!existingTicketPriority) {
        return undefined;
      }

      return {
        ...existingTicketPriority,
        ticketPriorities: [priority],
      };
    })
    .filter(isNonNil);
