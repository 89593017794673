import { FC, useState } from 'react';

import { ArrowLeftIcon, CloseIcon, Drawer, IconButtonWrapper } from '@shared';

import { useTriggerForm } from '../../lib';
import { TriggerModalContent } from '../TriggerModalContent';

import styles from './TriggerModal.module.scss';

interface TriggerModalProps {
  isModal: boolean;
  isEditMode: boolean;
  toggleIsModal(): void;
  toggleIsChangeModals?(): void;
}

export const TriggerModal: FC<TriggerModalProps> = ({
  isModal = true,
  isEditMode,
  toggleIsModal,
  toggleIsChangeModals,
}) => {
  const [isSubModal, setIsSubModal] = useState<boolean>(false);

  const toggleIsSubModal = () => {
    setIsSubModal((prevState) => !prevState);
  };

  const triggerForm = useTriggerForm({ isModal, isEditMode, toggleIsModal });

  const title = !isEditMode ? 'Создать триггер' : 'Редактировать триггер';

  const subModalText = !isEditMode ? 'создание' : 'редактирование';

  return (
    <Drawer
      isOpen={isModal}
      onClose={toggleIsChangeModals}
      approveOrCancelProps={{
        onApprove: toggleIsModal,
        isModal: isSubModal,
        toggleModal: toggleIsSubModal,
        text: `Вы уверены, что хотите отменить ${subModalText} триггера?`,
      }}
    >
      {/* TODO вынести общую логику работы с модалками */}
      <div className={styles.triggerModal}>
        <div className={styles.triggerModal__container}>
          <div className={styles.triggerModal__header}>
            <p className={styles.triggerModal__headerTitle}>{title}</p>
            <div className={styles.triggerModal__buttonsWrapper}>
              <IconButtonWrapper
                className={styles.triggerModal__headerCloseButton}
                onClick={toggleIsSubModal}
                icon={
                  <CloseIcon className={styles.triggerModal__headerCloseIcon} />
                }
              />
            </div>
            <IconButtonWrapper
              className={styles.triggerModal__headerCloseButtonMobile}
              onClick={toggleIsSubModal}
              icon={
                <ArrowLeftIcon
                  className={styles.triggerModal__headerCloseIcon}
                />
              }
            />
          </div>
          <TriggerModalContent
            triggerForm={triggerForm}
            onReset={toggleIsSubModal}
          />
        </div>
      </div>
    </Drawer>
  );
};
