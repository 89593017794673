import { ContractsFilter } from '../model';

export const DEFAULT_FILTER: ContractsFilter = {
  openDate: new Date('1995-12-17'),
  endDate: '',
  title: '',
  description: '',
  organizationId: null,
  type: null,
  status: null,
  number: '',
};
