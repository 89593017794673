import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import {
  KeyValueOption,
  PAGE_SIZE_SEARCH_SELECT,
  PaginationType,
} from '@shared';

import { DEFAULT_FOR_CREATE_STORAGE_VALUES } from '../config';

import {
  CreateStorageRequest,
  DataForCreateStorageRequest,
  EditStorageRequest,
  StorageForCreateRequest,
  Storages,
} from './types';

export interface StoragesReducerState {
  adminOrganizationId?: string;
  organizations?: KeyValueOption[];
  organizationsForCreate?: KeyValueOption[];
  storages?: Storages;
  workGroupsViewers?: KeyValueOption[];
  workGroupsEditors?: KeyValueOption[];
  loadingStorages?: boolean;
  loadingGroupsViewers?: boolean;
  loadingOrganization?: boolean;
  loadingEditorsGroups?: boolean;
  paginationOrganization?: PaginationType;
  paginationGroupsViewers?: PaginationType;
  paginationGroupsEditors?: PaginationType;
  sortSelect?: string;
  workGroupsViewersFilter?: StorageForCreateRequest;
  workGroupsEditorsFilter?: StorageForCreateRequest;
  organizationFilter?: StorageForCreateRequest;
  organizationIdsViewers?: string[];
  organizationIdsEditors?: string[];
}

export const initialState: StoragesReducerState = {
  organizations: [],
  organizationsForCreate: [],
  organizationIdsViewers: [],
  organizationIdsEditors: [],
  workGroupsViewers: [],
  workGroupsEditors: [],
  loadingStorages: false,
  loadingOrganization: false,
  loadingGroupsViewers: false,
  loadingEditorsGroups: false,
  sortSelect: 'TITLE_ASC',
  workGroupsViewersFilter: DEFAULT_FOR_CREATE_STORAGE_VALUES,
  workGroupsEditorsFilter: DEFAULT_FOR_CREATE_STORAGE_VALUES,
  organizationFilter: DEFAULT_FOR_CREATE_STORAGE_VALUES,
  paginationOrganization: {
    pageSize: PAGE_SIZE_SEARCH_SELECT,
  },
  paginationGroupsViewers: {
    pageSize: PAGE_SIZE_SEARCH_SELECT,
  },
  paginationGroupsEditors: {
    pageSize: PAGE_SIZE_SEARCH_SELECT,
  },
};

const storagesSlice = createSlice({
  name: 'storages',
  initialState,
  reducers: {
    fetchOrganizationsSuccess: (
      state,
      action: PayloadAction<KeyValueOption[]>
    ) => {
      state.organizations = action.payload;
    },
    fetchOrganizationRequest: (_state, _action: PayloadAction<string>) => {},
    fetchAdminIdOrganizationRequest: (_state) => {},
    fetchAdminIdOrganizationSuccess: (state, action: PayloadAction<string>) => {
      state.adminOrganizationId = action.payload;
    },
    fetchOrganizationsForCreateSuccess: (
      state,
      action: PayloadAction<KeyValueOption[]>
    ) => {
      state.organizationsForCreate = action.payload;
    },
    fetchOrganizationsForCreateRequest: (
      state,
      action: PayloadAction<StorageForCreateRequest>
    ) => {
      state.organizationFilter = action.payload;
    },
    fetchOrganizationsForCreateSuccessUpdate(
      state,
      action: PayloadAction<StorageForCreateRequest>
    ) {
      const { payload } = action;
      const { content, pageNum, totalElements } = payload;
      state.organizationsForCreate = content;
      state.paginationOrganization = {
        ...state.paginationOrganization,
        pageNum,
        totalElements,
      };
    },
    fetchOrganizationsForCreateSuccessJoin(
      state,
      action: PayloadAction<StorageForCreateRequest>
    ) {
      const { payload } = action;
      const { content, pageNum, totalElements } = payload;
      state.organizationsForCreate = [
        ...(state.organizationsForCreate || []),
        ...(content || []),
      ];
      state.paginationOrganization = {
        ...state.paginationOrganization,
        pageNum,
        totalElements,
      };
    },
    resetOrganizationsForCreate(state) {
      state.organizationsForCreate = initialState.organizationsForCreate;
      state.paginationOrganization = initialState.paginationOrganization;
    },
    setSizeOrganizationsForCreatePage(state, action: PayloadAction<number>) {
      const { payload } = action;
      state.paginationOrganization = {
        ...state.paginationOrganization,
        pageNum: payload,
      };
    },
    setOrganizationIdsViewers(state, action: PayloadAction<string[]>) {
      state.organizationIdsViewers = action.payload;
    },
    setOrganizationIdsEditors(state, action: PayloadAction<string[]>) {
      state.organizationIdsEditors = action.payload;
    },
    fetchWorkGroupsViewersSuccess: (
      state,
      action: PayloadAction<KeyValueOption[]>
    ) => {
      state.workGroupsViewers = action.payload;
    },
    fetchWorkGroupsViewersRequest: (
      state,
      action: PayloadAction<StorageForCreateRequest>
    ) => {
      state.workGroupsViewersFilter = action.payload;
    },
    fetchWorkGroupsViewersSuccessUpdate(
      state,
      action: PayloadAction<StorageForCreateRequest>
    ) {
      const { payload } = action;
      const { content, pageNum, totalElements } = payload;
      state.workGroupsViewers = content;
      state.paginationGroupsViewers = {
        ...state.paginationGroupsViewers,
        pageNum,
        totalElements,
      };
    },
    fetchWorkGroupsViewersSuccessJoin(
      state,
      action: PayloadAction<StorageForCreateRequest>
    ) {
      const { payload } = action;
      const { content, pageNum, totalElements } = payload;
      state.workGroupsViewers = [
        ...(state.workGroupsViewers || []),
        ...(content || []),
      ];
      state.paginationGroupsViewers = {
        ...state.paginationGroupsViewers,
        pageNum,
        totalElements,
      };
    },
    resetWorkGroupsViewersCreate(state) {
      state.workGroupsViewers = initialState.workGroupsViewers;
      state.paginationGroupsViewers = initialState.paginationGroupsViewers;
    },
    setSizeWorkGroupsViewersPage(state, action: PayloadAction<number>) {
      const { payload } = action;
      state.paginationGroupsViewers = {
        ...state.paginationGroupsViewers,
        pageNum: payload,
      };
    },
    fetchWorkGroupsEditorsSuccessUpdate(
      state,
      action: PayloadAction<StorageForCreateRequest>
    ) {
      const { payload } = action;
      const { content, pageNum, totalElements } = payload;
      state.workGroupsEditors = content;
      state.paginationGroupsEditors = {
        ...state.paginationGroupsEditors,
        pageNum,
        totalElements,
      };
    },
    fetchWorkGroupsEditorsSuccessJoin(
      state,
      action: PayloadAction<StorageForCreateRequest>
    ) {
      const { payload } = action;
      const { content, pageNum, totalElements } = payload;
      state.workGroupsEditors = [
        ...(state.workGroupsEditors || []),
        ...(content || []),
      ];
      state.paginationGroupsEditors = {
        ...state.paginationGroupsEditors,
        pageNum,
        totalElements,
      };
    },
    resetWorkGroupsEditorsCreate(state) {
      state.workGroupsEditors = initialState.workGroupsEditors;
      state.paginationGroupsEditors = initialState.paginationGroupsEditors;
    },
    setSizeWorkGroupsEditorsPage(state, action: PayloadAction<number>) {
      const { payload } = action;
      state.paginationGroupsEditors = {
        ...state.paginationGroupsEditors,
        pageNum: payload,
      };
    },
    fetchWorkGroupsEditorsSuccess: (
      state,
      action: PayloadAction<KeyValueOption[]>
    ) => {
      state.workGroupsEditors = action.payload;
    },
    fetchWorkGroupsEditorsRequest: (
      state,
      action: PayloadAction<StorageForCreateRequest>
    ) => {
      state.workGroupsEditorsFilter = action.payload;
    },
    fetchStoragesSuccess: (state, action: PayloadAction<Storages>) => {
      if (!state.storages) {
        state.storages = action.payload;
        return;
      }
      if (state.storages) {
        state.storages.content = [
          ...state.storages.content,
          ...action.payload.content,
        ];
      }
    },
    fetchStoragesRequest: (
      _state,
      _action: PayloadAction<DataForCreateStorageRequest>
    ) => {},
    createStoragesRequest: (
      _state,
      _action: PayloadAction<CreateStorageRequest>
    ) => {},
    editStoragesRequest: (
      _state,
      _action: PayloadAction<EditStorageRequest>
    ) => {},
    deleteStorageRequest: (_state, _action: PayloadAction<string>) => {},
    setStoragesLoading: (state, action: PayloadAction<boolean>) => {
      state.loadingStorages = action.payload;
    },
    resetStoragesContent: (state) => {
      state.storages = initialState.storages;
    },
    resetStorages: () => initialState,
  },
});

export const {
  fetchAdminIdOrganizationRequest,
  fetchAdminIdOrganizationSuccess,
  fetchOrganizationsSuccess,
  fetchOrganizationRequest,
  fetchOrganizationsForCreateSuccess,
  fetchOrganizationsForCreateRequest,
  fetchWorkGroupsViewersRequest,
  fetchWorkGroupsViewersSuccess,
  fetchWorkGroupsEditorsSuccess,
  fetchWorkGroupsEditorsRequest,
  fetchStoragesSuccess,
  fetchStoragesRequest,
  createStoragesRequest,
  deleteStorageRequest,
  resetStorages,
  setStoragesLoading,
  resetStoragesContent,
  editStoragesRequest,
  fetchOrganizationsForCreateSuccessUpdate,
  fetchOrganizationsForCreateSuccessJoin,
  resetOrganizationsForCreate,
  setSizeOrganizationsForCreatePage,
  fetchWorkGroupsViewersSuccessUpdate,
  fetchWorkGroupsViewersSuccessJoin,
  resetWorkGroupsViewersCreate,
  setSizeWorkGroupsViewersPage,
  setOrganizationIdsViewers,
  setOrganizationIdsEditors,
  fetchWorkGroupsEditorsSuccessUpdate,
  fetchWorkGroupsEditorsSuccessJoin,
  resetWorkGroupsEditorsCreate,
  setSizeWorkGroupsEditorsPage,
} = storagesSlice.actions;
export const storages = storagesSlice.reducer;
