import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Route, RouteProps, useLocation } from 'react-router-dom';

import { getIsModal, setIsModal } from '@entities/user-assistance';
import { ApiClient } from '@shared';

export const CustomRoute = (props: RouteProps) => {
  const location = useLocation();

  const dispatch = useDispatch();

  const isModal = useSelector(getIsModal);

  useEffect(
    () => () => {
      ApiClient.finishPendingRequests();
    },
    [location?.pathname]
  );

  useEffect(
    () => () => {
      if (isModal) {
        dispatch(setIsModal(false));
      }
    },
    [location?.pathname, isModal]
  );

  return <Route {...props} />;
};
