import { ProfileLayout } from '@features/profile';
import { ProfileTelegram } from '@features/profile/ui';

const ProfileTelegramPage = () => (
  <ProfileLayout>
    <ProfileTelegram />
  </ProfileLayout>
);

export default ProfileTelegramPage;
