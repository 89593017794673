import { FC } from 'react';
import { useSelector } from 'react-redux';

import { getNextStatuses, Status } from '@entities/ticket';
import { StatusBlock } from '@entities/ticket/ui';
import { Ripple } from '@shared';

import styles from './StatusDropContent.module.scss';

interface StatusDropContentProps {
  onChangeNextStatusHandler(status: Status | null): void;
}

export const StatusDropContent: FC<StatusDropContentProps> = ({
  onChangeNextStatusHandler,
}) => {
  const nextStatuses = useSelector(getNextStatuses);

  const nextStatusesList = nextStatuses?.map((nextStatus) => {
    const { id, name, defaultStatus, color } = nextStatus;
    return (
      <div
        onClick={() => onChangeNextStatusHandler(nextStatus)}
        onKeyDown={() => onChangeNextStatusHandler(nextStatus)}
        tabIndex={-1}
        role="button"
        key={id}
        className={styles.statusDropContent__itemWrapper}
      >
        <StatusBlock label={name} status={defaultStatus} color={color} />
        <Ripple appearance="default" />
      </div>
    );
  });

  return <div className={styles.statusDropContent}>{nextStatusesList}</div>;
};
