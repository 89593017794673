import { ResponseWithMeta } from '@shared';

import {
  Actions,
  FetchActionsByRespIdAction,
  FetchActionsCreateRequestAction,
  FetchActionsRequestAction,
  FetchActionsSuccessAction,
  HideActionsLoadingAction,
  ResetActionsCreateAction,
  ResetActionsStateAction,
  SetActionsCreateAction,
  SetActionsFilterAction,
  SetActionsForEditRespAction,
  SetCurrentActionsPageAction,
  SetSizePageAction,
  SetSortActionsAction,
  ShowActionsLoadingAction,
} from './actionTypes';
import { ActionFilter, ActionWithCustomFields } from './types';

export const showActionsLoading = (): ShowActionsLoadingAction => ({
  type: Actions.ACTIONS_LOADING_SHOW,
});

export const hideActionsLoading = (): HideActionsLoadingAction => ({
  type: Actions.ACTIONS_LOADING_HIDE,
});

export const fetchActionsRequest = (): FetchActionsRequestAction => ({
  type: Actions.FETCH_ACTIONS_REQUEST,
});

export const fetchActionsSuccess = (
  data: ResponseWithMeta<ActionWithCustomFields[]>
): FetchActionsSuccessAction => ({
  type: Actions.FETCH_ACTIONS_SUCCESS,
  payload: data,
});

export const setActionsCreate = (
  data: ActionWithCustomFields[]
): SetActionsCreateAction => ({
  type: Actions.SET_ACTIONS_CREATE_STATE,
  payload: data,
});

export const fetchActionsCreateRequest = (
  organizationId: string
): FetchActionsCreateRequestAction => ({
  type: Actions.FETCH_ACTIONS_CREATE_REQUEST,
  payload: organizationId,
});

export const fetchActionsByRespIdRequest = (): FetchActionsByRespIdAction => ({
  type: Actions.FETCH_ACTION_BY_RESP_ID_REQUEST,
});

export const resetActionsState = (): ResetActionsStateAction => ({
  type: Actions.RESET_ACTIONS_STATE,
});

export const setActionsForEditResp = (): SetActionsForEditRespAction => ({
  type: Actions.SET_ACTIONS_FOR_EDIT_RESPONSIBILITY,
});

export const setActionFilter = (
  data: ActionFilter
): SetActionsFilterAction => ({
  type: Actions.SET_ACTIONS_FILTER,
  payload: data,
});

export const setCurrentActionPage = (
  page: number
): SetCurrentActionsPageAction => ({
  type: Actions.SET_CURRENT_ACTIONS_PAGE,
  payload: page,
});

export const setSizePage = (size: number): SetSizePageAction => ({
  type: Actions.SET_SIZE_PAGE_ACTIONS,
  payload: size,
});

export const setSortAction = (sort: string): SetSortActionsAction => ({
  type: Actions.SET_SORT_ACTIONS,
  payload: sort,
});

export const resetActionsCreate = (): ResetActionsCreateAction => ({
  type: Actions.RESET_ACTIONS_CREATE,
});
