import { NotificationContent } from '@entities/notifications/model/types';
import { KeyValueOption, ResponseWithMeta } from '@shared';

import {
  CheckHasUnpublishedNewsAction,
  CheckHasUnpublishedNewsSuccessAction,
  CheckNewsRequestAction,
  CheckNewsSuccessAction,
  FetchCurrentNewsRequestAction,
  FetchCurrentNewsSuccessAction,
  FetchIsUncheckedNewsRequestAction,
  FetchIsUncheckedNewsSuccessAction,
  FetchIsUncheckedNotificationsRequestAction,
  FetchNewsJoinSuccessAction,
  FetchNewsRequestAction,
  FetchNewsSuccessAction,
  FetchNotificationSettingsRequestAction,
  FetchNotificationSettingsSuccessAction,
  FetchNotificationsProfileJoinSuccessAction,
  FetchNotificationsProfileRequestAction,
  FetchNotificationsProfileSuccessAction,
  FetchNotificationTabsRequestAction,
  FetchNotificationTabsSuccessAction,
  FetchOrganizationsRequestAction,
  FetchOrganizationsSuccessAction,
  FetchWorkGroupsRequestAction,
  FetchWorkGroupsSuccessAction,
  HideCurrentNewsLoadingAction,
  HideNewsLoadingAction,
  HideNotificationsProfileLoadingAction,
  NewsCreateAction,
  NewsDeleteAction,
  NewsEditAction,
  NotificationsProfile,
  ResetNewsStateAction,
  ResetNotificationsListStateAction,
  ResetNotificationsProfileStateAction,
  SetAllCheckedNotificationsRequestAction,
  SetCheckedNotificationRequestAction,
  SetCheckedNotificationSuccessAction,
  SetCurrentNotificationsProfilePageAction,
  SetFilterNotificationsProfileAction,
  SetIsEditNewsModeAction,
  SetIsNewsModalAction,
  SetIsUncheckedNotificationsSuccessAction,
  SetSizePageNotificationsProfileAction,
  SetSortNotificationsProfileAction,
  ShowCurrentNewsLoadingAction,
  ShowNewsLoadingAction,
  ShowNotificationsProfileLoadingAction,
  UpdateNotificationSettingsRequestAction,
} from './actionTypes';
import {
  FetchNewsParams,
  FetchNotificationsParams,
  FilterNotificationsProfile,
  HasUncheckedNotificationsType,
  News,
  NewsCreateData,
  NewsDelete,
  ProfileSettings,
  ProfileTabSettings,
} from './types';

export const showNotificationsProfileLoading =
  (): ShowNotificationsProfileLoadingAction => ({
    type: NotificationsProfile.LOADING_SHOW_NOTIFICATIONS_PROFILE,
  });

export const hideNotificationsProfileLoading =
  (): HideNotificationsProfileLoadingAction => ({
    type: NotificationsProfile.LOADING_HIDE_NOTIFICATIONS_PROFILE,
  });

export const fetchNotificationsProfileRequest = (
  options: FetchNotificationsParams
): FetchNotificationsProfileRequestAction => ({
  type: NotificationsProfile.FETCH_NOTIFICATIONS_PROFILE_REQUEST,
  payload: options,
});

export const fetchNotificationsProfileSuccess = (
  data: ResponseWithMeta<NotificationContent[]>
): FetchNotificationsProfileSuccessAction => ({
  type: NotificationsProfile.FETCH_NOTIFICATIONS_PROFILE_SUCCESS,
  payload: data,
});

export const fetchNotificationsProfileJoinSuccess = (
  data: ResponseWithMeta<NotificationContent[]>
): FetchNotificationsProfileJoinSuccessAction => ({
  type: NotificationsProfile.FETCH_NOTIFICATIONS_PROFILE_SUCCESS_JOIN,
  payload: data,
});

export const setCurrentNotificationsProfilePage = (
  page: number
): SetCurrentNotificationsProfilePageAction => ({
  type: NotificationsProfile.SET_CURRENT_PAGE_NOTIFICATIONS_PROFILE,
  payload: page,
});

export const setSizePageNotificationsProfile = (
  size: number
): SetSizePageNotificationsProfileAction => ({
  type: NotificationsProfile.SET_SIZE_PAGE_NOTIFICATIONS_PROFILE,
  payload: size,
});

export const setSortNotificationsProfile = (
  sort: string
): SetSortNotificationsProfileAction => ({
  type: NotificationsProfile.SET_SORT_NOTIFICATIONS_PROFILE,
  payload: sort,
});

export const resetNotificationsProfileState =
  (): ResetNotificationsProfileStateAction => ({
    type: NotificationsProfile.RESET_STATE_NOTIFICATIONS_PROFILE,
  });

export const setNotificationsProfileFilter = (
  data: FilterNotificationsProfile
): SetFilterNotificationsProfileAction => ({
  type: NotificationsProfile.SET_FILTER_NOTIFICATIONS_PROFILE,
  payload: data,
});

export const setAllCheckedNotifications =
  (): SetAllCheckedNotificationsRequestAction => ({
    type: NotificationsProfile.SET_ALL_CHECKED_NOTIFICATIONS_REQUEST,
  });

export const getIsUncheckedNotifications = (
  data: HasUncheckedNotificationsType[]
): FetchIsUncheckedNotificationsRequestAction => ({
  type: NotificationsProfile.GET_IS_UNCHECKED_STATUSES_REQUEST,
  payload: data,
});

export const setIsUncheckedNotificationsSuccess = (
  data: HasUncheckedNotificationsType[]
): SetIsUncheckedNotificationsSuccessAction => ({
  type: NotificationsProfile.SET_IS_UNCHECKED_STATUSES_SUCCESS,
  payload: data,
});

export const setCheckedNotificationRequest = (
  id: string
): SetCheckedNotificationRequestAction => ({
  type: NotificationsProfile.SET_CHECKED_NOTIFICATION_REQUEST,
  payload: id,
});

export const setCheckedNotificationSuccess = (
  id: string
): SetCheckedNotificationSuccessAction => ({
  type: NotificationsProfile.SET_CHECKED_NOTIFICATION_SUCCESS,
  payload: id,
});

export const fetchNotificationTabsRequest =
  (): FetchNotificationTabsRequestAction => ({
    type: NotificationsProfile.FETCH_NOTIFICATION_TABS_REQUEST,
  });

export const fetchNotificationTabsSuccess = (
  tabs: ProfileTabSettings[]
): FetchNotificationTabsSuccessAction => ({
  type: NotificationsProfile.FETCH_NOTIFICATION_TABS_SUCCESS,
  payload: tabs,
});

export const updateNotificationSettingsRequest = (
  tabs: ProfileSettings
): UpdateNotificationSettingsRequestAction => ({
  type: NotificationsProfile.UPDATE_NOTIFICATION_SETTINGS_REQUEST,
  payload: tabs,
});

export const fetchNotificationSettingsRequest =
  (): FetchNotificationSettingsRequestAction => ({
    type: NotificationsProfile.FETCH_NOTIFICATION_SETTINGS_REQUEST,
  });

export const fetchNotificationSettingsSuccess = (
  settings: ProfileSettings
): FetchNotificationSettingsSuccessAction => ({
  type: NotificationsProfile.FETCH_NOTIFICATION_SETTINGS_SUCCESS,
  payload: settings,
});

export const resetNotificationList = (): ResetNotificationsListStateAction => ({
  type: NotificationsProfile.RESET_NOTIFICATIONS_LIST,
});

export const showNewsLoading = (): ShowNewsLoadingAction => ({
  type: NotificationsProfile.LOADING_SHOW_NEWS,
});

export const hideNewsLoading = (): HideNewsLoadingAction => ({
  type: NotificationsProfile.LOADING_HIDE_NEWS,
});

export const fetchNewsRequest = (
  payload: FetchNewsParams
): FetchNewsRequestAction => ({
  type: NotificationsProfile.FETCH_NEWS_REQUEST,
  payload,
});

export const fetchNewsSuccess = (
  data: ResponseWithMeta<News[]>
): FetchNewsSuccessAction => ({
  type: NotificationsProfile.FETCH_NEWS_SUCCESS,
  payload: data,
});

export const fetchNewsJoinSuccess = (
  data: ResponseWithMeta<News[]>
): FetchNewsJoinSuccessAction => ({
  type: NotificationsProfile.FETCH_NEWS_SUCCESS_JOIN,
  payload: data,
});

export const resetNewsState = (): ResetNewsStateAction => ({
  type: NotificationsProfile.RESET_STATE_NEWS,
});

export const newsCreate = (data: NewsCreateData): NewsCreateAction => ({
  type: NotificationsProfile.NEWS_CREATE,
  payload: data,
});

export const newsEdit = (data: NewsCreateData): NewsEditAction => ({
  type: NotificationsProfile.NEWS_EDIT,
  payload: data,
});

export const fetchCurrentNewsRequest = (
  id: string
): FetchCurrentNewsRequestAction => ({
  type: NotificationsProfile.CURRENT_NEWS_FETCH_REQUEST,
  payload: id,
});

export const fetchCurrentNewsSuccess = (
  payload?: News
): FetchCurrentNewsSuccessAction => ({
  type: NotificationsProfile.CURRENT_NEWS_FETCH_SUCCESS,
  payload,
});

export const showCurrentNewsLoading = (): ShowCurrentNewsLoadingAction => ({
  type: NotificationsProfile.LOADING_SHOW_CURRENT_NEWS,
});

export const hideCurrentNewsLoading = (): HideCurrentNewsLoadingAction => ({
  type: NotificationsProfile.LOADING_HIDE_CURRENT_NEWS,
});

export const setIsEditNewsMode = (
  payload: boolean
): SetIsEditNewsModeAction => ({
  type: NotificationsProfile.SET_IS_EDIT_NEWS_MODE,
  payload,
});

export const setIsNewsModal = (payload: boolean): SetIsNewsModalAction => ({
  type: NotificationsProfile.SET_IS_NEWS_MODAL,
  payload,
});

export const newsDelete = (payload: NewsDelete): NewsDeleteAction => ({
  type: NotificationsProfile.NEWS_DELETE,
  payload,
});

export const fetchIsUncheckedNewsRequest =
  (): FetchIsUncheckedNewsRequestAction => ({
    type: NotificationsProfile.FETCH_IS_UNCHECKED_NEWS_REQUEST,
  });

export const fetchIsUncheckedNewsSuccess = (
  payload: boolean
): FetchIsUncheckedNewsSuccessAction => ({
  type: NotificationsProfile.FETCH_IS_UNCHECKED_NEWS_SUCCESS,
  payload,
});

export const checkNewsRequest = (id: string): CheckNewsRequestAction => ({
  type: NotificationsProfile.CHECK_NEWS_REQUEST,
  payload: id,
});

export const checkNewsSuccess = (payload: string): CheckNewsSuccessAction => ({
  type: NotificationsProfile.CHECK_NEWS_SUCCESS,
  payload,
});

export const fetchOrganizationsRequest =
  (): FetchOrganizationsRequestAction => ({
    type: NotificationsProfile.FETCH_ORGANIZATIONS,
  });

export const fetchOrganizationsSuccess = (
  payload: KeyValueOption[]
): FetchOrganizationsSuccessAction => ({
  type: NotificationsProfile.FETCH_ORGANIZATIONS_SUCCESS,
  payload,
});

export const fetchWorkGroupsRequest = (
  payload: string[]
): FetchWorkGroupsRequestAction => ({
  type: NotificationsProfile.FETCH_WORK_GROUPS,
  payload,
});

export const fetchWorkGroupsSuccess = (
  payload: KeyValueOption[]
): FetchWorkGroupsSuccessAction => ({
  type: NotificationsProfile.FETCH_WORK_GROUPS_SUCCESS,
  payload,
});

export const checkHasUnpublishedNews = (): CheckHasUnpublishedNewsAction => ({
  type: NotificationsProfile.CHECK_HAS_UNPUBLISHED_NEWS_REQUEST,
});

export const checkHasUnpublishedNewsSuccess = (
  payload: boolean
): CheckHasUnpublishedNewsSuccessAction => ({
  type: NotificationsProfile.CHECK_HAS_UNPUBLISHED_NEWS_SUCCESS,
  payload,
});
