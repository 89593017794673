import { useSelector } from 'react-redux';

import { getKpiData } from '@entities/desktop';

import { KpiActiveTickets } from '../KpiActiveTickets';
import { KpiAllTickets } from '../KpiAllTickets';
import { KpiSlaLineChart } from '../KpiSlaLineChart';
import { KpiTicketsPieChart } from '../KpiTicketsPieChart';

import styles from './DesktopKpi.module.scss';

export const DesktopKpi = () => {
  const kpiData = useSelector(getKpiData);

  return (
    <div className={styles.kpi}>
      <div className={styles.kpi__container}>
        <KpiActiveTickets kpiData={kpiData} />
        <KpiAllTickets kpiData={kpiData} />
      </div>
      <div className={styles.kpi__container}>
        <KpiSlaLineChart kpiData={kpiData} />
        <KpiTicketsPieChart kpiData={kpiData} />
      </div>
    </div>
  );
};
