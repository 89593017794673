import { useCallback, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';

import {
  ActionList,
  getIsAccessedAction,
  getIsManagerClients,
  getUserOrganization,
} from '@entities/auth';
import {
  downloadTicketsRequest,
  fetchOrganizationsRequest,
  fetchSystemsRequest,
  fetchTicketsRequestHistory,
  fetchTicketsStatisticRequestHistory,
  getHistoryTickets,
  getIsDesktopTicketsLoading,
  getTicketsStatistic,
  HistoryFormData,
  resetDesktopState,
  resetHistoryState,
  setDesktopFilters,
} from '@entities/desktop';
import {
  convertFromDateFormat,
  convertToDateFormat,
  getSelectOption,
  getValueFromValueType,
} from '@shared';

export const useHistoryForm = () => {
  const dispatch = useDispatch();

  const tickets = useSelector(getHistoryTickets);
  const ticketsStatistic = useSelector(getTicketsStatistic);
  const isDownload = useSelector(getIsDesktopTicketsLoading);
  const userOrganization = useSelector(getUserOrganization);
  const isManagerClients = useSelector(getIsManagerClients);

  const isAccessToCreateReportByMyOrg = useSelector(
    getIsAccessedAction(ActionList.CreateReportByMyOrg)
  );

  const downloadTicketsData = ticketsStatistic?.downloadInfo;

  const { control, watch, resetField, setValue } = useForm<HistoryFormData>({
    mode: 'onChange',
  });

  const { date, organizationId, systemId } = watch();

  const isReportClients = isAccessToCreateReportByMyOrg && isManagerClients;

  const isFetchDesktopFilters = isReportClients
    ? !!(organizationId && systemId)
    : !!(organizationId || systemId);

  useEffect(() => {
    dispatch(fetchOrganizationsRequest());
  }, []);

  useEffect(() => {
    if (organizationId && !Array.isArray(organizationId)) {
      dispatch(fetchSystemsRequest(organizationId?.value));
    }
  }, [organizationId]);

  useEffect(() => {
    if (!organizationId && isReportClients && userOrganization) {
      setValue('organizationId', getSelectOption(userOrganization));
    }
  }, [organizationId, isReportClients, userOrganization]);

  useEffect(() => {
    if (isFetchDesktopFilters) {
      dispatch(
        setDesktopFilters({
          organizationId: getValueFromValueType(organizationId),
          systemId: getValueFromValueType(systemId),
          to: convertToDateFormat(date),
          from: convertFromDateFormat(date),
          onlyActualTickets: false,
        })
      );
      dispatch(fetchTicketsRequestHistory());
      dispatch(fetchTicketsStatisticRequestHistory());
    }
  }, [isFetchDesktopFilters, date, organizationId, systemId]);

  useEffect(
    () => () => {
      dispatch(resetHistoryState());
      dispatch(resetDesktopState());

      resetField('date');
      resetField('organizationId');
      resetField('systemId');
    },
    []
  );

  const handleDownload = useCallback(() => {
    if (downloadTicketsData) {
      dispatch(
        downloadTicketsRequest({
          path: downloadTicketsData?.path,
          fileName: downloadTicketsData?.fileName,
        })
      );
    }
  }, [tickets, downloadTicketsData]);

  return {
    state: {
      tickets,
      ticketsStatistic,
      isDownload,
      isReportClients,
    },
    methods: {
      historyControl: control,
      handleDownload,
      setHistoryValue: setValue,
    },
  };
};
