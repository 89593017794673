import { isNil } from 'lodash';
import { createSelector } from 'reselect';

import { SelectOption } from '@shared';

import { Tag } from '../types';

import { TagsState } from './types';

export const getTags = (state: TagsState) => state.tags.tags;
export const getTagsSelectList = createSelector<
  TagsState,
  Tag[] | undefined,
  SelectOption[]
>([getTags], (tags): SelectOption[] => {
  if (tags) {
    return tags.map(
      (tag: Tag): SelectOption => ({
        title: tag.name || '',
        value: tag.id,
      })
    );
  }
  return [];
});
export const getPropsTags = (state: TagsState) => {
  const totalElements = state.tags.pagination?.totalElements;
  const size = state.tags.pagination?.pageSize;
  return {
    pageNum: state.tags.pagination?.pageNum,
    pageSize: state.tags.pagination?.pageSize,
    totalElementsTags: totalElements,
    loadingTags: state.tags.loading,
    totalPagesTags:
      !isNil(totalElements) && !isNil(size)
        ? Math.ceil(totalElements / size)
        : 0,
  };
};
export const getFilterTags = (state: TagsState) => state.tags.filter;
export const getIsExistTag = (state: TagsState) => state.tags.isExistTag;
