import {
  Contract,
  CONTRACT_TYPE_TITLE_MAP,
  ContractStatusBlock,
} from '@entities/contract';
import { CalendarIcon, getFormatDateWithoutTime, IconBlock } from '@shared';

export const getDataTable = (contracts?: Contract[]) =>
  contracts?.map((contract) => {
    const { title, type, status, id, endDate } = contract;
    return {
      id,
      title,
      type: CONTRACT_TYPE_TITLE_MAP[type] || '-',
      status: <ContractStatusBlock status={status} />,
      endDate: (
        <IconBlock
          icon={
            <CalendarIcon
              style={{ color: 'var(--grey80)', minWidth: '14px' }}
            />
          }
          text={getFormatDateWithoutTime(endDate)}
        />
      ),
    };
  }) || [];
