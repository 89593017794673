import { FC } from 'react';
import { Control, Controller, UseFormSetValue } from 'react-hook-form';

import { HistoryFormData } from '@entities/desktop';
import { InputDatePicker, Select, SelectOption } from '@shared';

import styles from './HistoryForm.module.scss';

interface HistoryFormProps {
  control: Control<HistoryFormData>;
  setValue: UseFormSetValue<HistoryFormData>;
  organizationsOptions: SelectOption[];
  systemsOptions: SelectOption[];
}

export const HistoryForm: FC<HistoryFormProps> = ({
  control,
  setValue,
  organizationsOptions,
  systemsOptions,
}) => (
  <>
    <Controller
      control={control}
      name="date"
      render={({ field }) => (
        <InputDatePicker
          type="datePicker"
          placeholder="Период"
          value={field.value}
          onChange={field.onChange}
          className={styles.historyForm__select}
          showRange
        />
      )}
    />
    <Controller
      control={control}
      name="organizationId"
      render={({ field }) => (
        <Select<string>
          label="Организация"
          mobileModalTitle="организацию"
          value={field.value}
          options={organizationsOptions}
          className={styles.historyForm__select}
          onChange={(value) => {
            setValue('systemId', null);
            field.onChange(value);
          }}
        />
      )}
    />
    <Controller
      control={control}
      name="systemId"
      render={({ field }) => (
        <Select<string>
          label="Система"
          mobileModalTitle="систему"
          value={field.value}
          className={styles.historyForm__select}
          options={systemsOptions}
          onChange={field.onChange}
        />
      )}
    />
  </>
);
