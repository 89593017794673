import cn from 'clsx';
import { ChangeEvent, FC, MutableRefObject } from 'react';

import { ArrowDownIcon } from '../../../../assets';
import { IconButtonWrapper } from '../../../IconButtonWrapper';
import { Input } from '../../../Input';

import styles from './MobileDropdownWrapper.module.scss';

interface MobileDropdownWrapperProps {
  openDropdown?: boolean;
  handleCloseDropdown: () => void;
  mobileModalTitle?: string;
  isSearchable?: boolean;
  inputValue?: string;
  handleChangeInput?: (event: ChangeEvent<HTMLInputElement>) => void;
  inputRef?: MutableRefObject<HTMLInputElement | null>;
  mobileRef?: MutableRefObject<HTMLDivElement | null>;
}

export const MobileDropdownWrapper: FC<MobileDropdownWrapperProps> = ({
  openDropdown,
  handleChangeInput,
  handleCloseDropdown,
  mobileModalTitle,
  isSearchable,
  inputValue,
  inputRef,
  mobileRef,
  children,
}) => (
  <div
    className={cn(styles.mobileDropdownWrapper__mobileContent, {
      [styles.mobileDropdownWrapper__mobileContent_show]: openDropdown,
    })}
  >
    <div className={cn(styles.mobileDropdownWrapper__mobileHeader)}>
      <IconButtonWrapper
        onClick={handleCloseDropdown}
        icon={
          <ArrowDownIcon
            className={styles.mobileDropdownWrapper__mobileArrow}
          />
        }
        type="button"
      />
      <span className={styles.mobileDropdownWrapper__mobileHeaderTitle}>
        {`Выбрать ${mobileModalTitle}`}
      </span>
    </div>
    <div
      className={cn(styles.mobileDropdownWrapper__mobileBody)}
      ref={mobileRef}
    >
      {isSearchable && (
        <Input
          label="Поиск"
          className={styles.mobileDropdownWrapper__searchInput}
          value={inputValue}
          onChange={handleChangeInput}
          ref={inputRef}
        />
      )}
      {children}
    </div>
  </div>
);
