import cn from 'clsx';
import { FC } from 'react';

import styles from './PopoverItemWrapper.module.scss';

interface PopoverItemWrapperProps {
  className?: string;
}

export const PopoverItemWrapper: FC<PopoverItemWrapperProps> = ({
  children,
  className,
}) => (
  <div className={cn(styles.popoverItemWrapper, className)}>{children}</div>
);
