import { TablesLayout } from '@shared';

import { StorageTableContainer } from '../StorageTableContainer';

import styles from './Storage.module.scss';

export const Storage = () => {
  const MainTable = () => <StorageTableContainer />;

  return (
    <TablesLayout
      MainTable={[MainTable]}
      classNameRoot={styles.storage}
      classNameMainTables={styles.storage__mainTable}
      classNameSubTables={styles.storage__subTable}
    />
  );
};
