import _, { omit } from 'lodash';

import { CustomFieldType, getReverseDateForRequest } from '@shared';

import { CustomFieldTicket } from '../../model/ticket/types';
import { AbstractDataType } from '../../model/types';

import { getFieldNameByType } from './getConvertedCustomFields';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const checkValueIsNotEmpty = (value: any) =>
  value !== undefined && !_.isNull(value);

const joinCustomFieldWithValue = (
  field: CustomFieldTicket,
  data: AbstractDataType
) => {
  if (
    !field?.id ||
    !checkValueIsNotEmpty(data[field.id]) ||
    !field?.customFieldType
  ) {
    return { ...field };
  }

  const value = data[field.id];
  const fieldType = field.customFieldType;

  const transformedValue = (() => {
    switch (fieldType) {
      case CustomFieldType.FIELD_DATE:
        return getReverseDateForRequest(value);
      case CustomFieldType.FIELD_LIST:
        return value.value;
      default:
        return value;
    }
  })();

  return {
    ...field,
    [getFieldNameByType(fieldType)]: transformedValue,
  };
};

export const convertCustomFieldsForUpdate = (
  data: AbstractDataType,
  customArray: CustomFieldTicket[]
) =>
  customArray.map((customField) =>
    joinCustomFieldWithValue(omit(customField, ['values']), data)
  );
