import { AdminsLayout } from '@pages/AdminsLayout';
import { Responsibilities } from '@widgets/responsibilities';

const ResponsibilitiesPage = () => (
  <AdminsLayout>
    <Responsibilities />
  </AdminsLayout>
);

export default ResponsibilitiesPage;
