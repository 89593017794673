import cn from 'clsx';
import { ChangeEvent, FC, useCallback, useMemo } from 'react';
import { useSelector } from 'react-redux';

import { Priority, TicketTypeSettings } from '@entities/ticket/model/types';
import { getIsMobile, getIsMobileSmall } from '@shared';

import { SLARowType, SLATableDataType } from '../../model/types';

import { DEFAULT_SLA_SETTINGS, INITIAL_SLA_SETTINGS } from './config';
import { CheckedPriorityType } from './model/types';
import styles from './SlaTable.module.scss';
import { Cell, Row, SlaRow, SlaWarning } from './ui';

export interface SlaTableProps {
  canEditTable?: boolean;
  isSwitchHeader?: boolean;
  slaSettings?: SLATableDataType;
  checkedPriority?: CheckedPriorityType;
  setCheckedPriority?: (checkedPriority: CheckedPriorityType) => void;
  updateSlaSettings?: (data: SLATableDataType) => void;
  slaTicketTypes?: TicketTypeSettings[];
  className?: string;
}

export const SlaTable: FC<SlaTableProps> = ({
  canEditTable = false,
  isSwitchHeader = false,
  slaSettings,
  slaTicketTypes,
  updateSlaSettings,
  checkedPriority,
  setCheckedPriority,
  className,
}) => {
  const isMobileSmall = useSelector(getIsMobileSmall);
  const isMobile = useSelector(getIsMobile);
  const isMobileAll = isMobileSmall || isMobile;
  const showSwitchTable = isSwitchHeader || isMobileAll;
  const dataTableConverted = slaSettings;

  const preparedSlaTicketTypes = slaTicketTypes?.filter((item) => item.active);

  const defaultRow = useMemo(
    () =>
      preparedSlaTicketTypes?.reduce((result: SLARowType, type) => {
        // eslint-disable-next-line no-param-reassign
        result[type.title] = DEFAULT_SLA_SETTINGS;
        return result;
      }, {}),
    [preparedSlaTicketTypes]
  );

  const slaTicketTypesList = preparedSlaTicketTypes?.map((item) => (
    <Cell key={item.ticketTypeId} className={styles.slaTable__cellHead}>
      {item.title}
    </Cell>
  ));

  const headDesktop = !showSwitchTable && (
    <Row className={canEditTable ? styles.slaTable__head : undefined}>
      <Cell className={styles.slaTable__cellHead}>Показатели SLA</Cell>
      {slaTicketTypesList}
    </Row>
  );

  const switchHeader = showSwitchTable && (
    <div className={styles.slaTable__switchHeader}>
      {preparedSlaTicketTypes?.map((type) => (
        <div className={styles.slaTable__switchHeaderContainer}>
          <button
            key={type.ticketTypeId}
            className={cn(styles.slaTable__switchHeaderItem)}
          >
            {type}
          </button>
        </div>
      ))}
    </div>
  );

  const onRowDataChange = useCallback(
    (settingsRow: SLARowType, priority: Priority) => {
      if (dataTableConverted && updateSlaSettings) {
        updateSlaSettings({
          ...(dataTableConverted || {}),
          [priority]: settingsRow,
        });
      }
    },
    [dataTableConverted]
  );

  const tableRows =
    !!preparedSlaTicketTypes?.length &&
    INITIAL_SLA_SETTINGS?.map((priority) => {
      const priorityName = priority.name as Priority;
      const currentRowChecked = checkedPriority
        ? checkedPriority[priorityName]
        : false;
      const rowContentInDataTable =
        dataTableConverted && dataTableConverted[priorityName];
      const settings = rowContentInDataTable || defaultRow;

      const checkboxChange =
        setCheckedPriority && checkedPriority
          ? (event: ChangeEvent<HTMLInputElement>) => {
              setCheckedPriority({
                ...checkedPriority,
                [priorityName]: event.target.checked,
              });
            }
          : undefined;

      return (
        <SlaRow
          priority={priority}
          slaSettings={settings || {}}
          slaTicketTypes={preparedSlaTicketTypes}
          key={priority.name}
          canEdit={canEditTable}
          rowActive={currentRowChecked}
          changeRowActive={checkboxChange}
          onRowDataChange={onRowDataChange}
          isDefaultRow={!rowContentInDataTable}
        />
      );
    });

  const warning = !preparedSlaTicketTypes?.length && <SlaWarning />;

  return (
    <div className={cn(styles.slaTable, className)}>
      {switchHeader}
      <div className={styles.slaTable__wrapper}>
        <div className={styles.slaTable__table}>
          {headDesktop}
          {tableRows}
        </div>
        {warning}
      </div>
    </div>
  );
};
