import { DATE_FORMATS } from '@shared';

import { addZeroIfOneDigit } from './addZeroIfOneDigit';

export const getFormattedDate = (
  dates: Date,
  format: (typeof DATE_FORMATS)[number]
) => {
  const [year, month, date, hours, minutes] = [
    new Date(dates).getFullYear(),
    new Date(dates).getMonth() + 1,
    new Date(dates).getDate(),
    new Date(dates).getHours(),
    new Date(dates).getMinutes(),
  ].map((value) => addZeroIfOneDigit(value));

  if (format === 'dd.mm.yyyy') {
    return `${date}.${month}.${year}`;
  }
  if (format === 'dd.mm.yyyy  hh:mm') {
    return `${date}.${month}.${year}  ${hours}:${minutes}`;
  }
  return `${date}.${month}.${year}`;
};
