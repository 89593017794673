import cn from 'clsx';
import { FC } from 'react';

import styles from './KBCategoriesList.module.scss';

interface KBCategoriesListProps {
  classNameList?: string;
  categories?: JSX.Element[];
  loading: boolean;
}

export const KBCategoriesList: FC<KBCategoriesListProps> = ({
  classNameList,
  categories = [],
  loading,
}) => (
  <div
    className={cn(styles.KBCategoriesList, classNameList, {
      [styles.KBCategoriesList_loading]: loading,
    })}
  >
    {categories}
  </div>
);
