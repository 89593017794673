import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { EmployeesStatistic, SlaStatistic, TicketsStatistic } from '../types';

export interface EmployeesReducerState {
  slaStatistic?: SlaStatistic;
  ticketsStatistic?: TicketsStatistic;
  employeesStatistic?: EmployeesStatistic;
  totalEmployees?: number;
  isLoading?: boolean;
  pageNum?: number;
  pageSize?: number;
}

const initialState: EmployeesReducerState = {
  pageNum: 0,
  pageSize: 10,
  isLoading: false,
};

const employeesSlice = createSlice({
  name: 'desktopEmployees',
  initialState,
  reducers: {
    fetchSlaStatisticSuccessEmployees: (
      state,
      action: PayloadAction<SlaStatistic>
    ) => {
      state.slaStatistic = action.payload;
    },
    fetchSlaStatisticRequestEmployees: (_state) => {},
    fetchEmployeesStatisticSuccessEmployees: (
      state,
      action: PayloadAction<EmployeesStatistic>
    ) => {
      state.employeesStatistic = action.payload;
      state.totalEmployees = action.payload.totalElements;
    },
    fetchEmployeesStatisticRequest: (_state) => {},
    fetchTicketsStatisticSuccessEmployees: (
      state,
      action: PayloadAction<TicketsStatistic>
    ) => {
      state.ticketsStatistic = action.payload;
    },
    fetchTicketsStatisticRequestEmployees: (_state) => {},
    setIsLoadingEmployees: (state, action: PayloadAction<boolean>) => {
      state.isLoading = action.payload;
    },
    setPageNumEmployees: (state, action: PayloadAction<number | undefined>) => {
      state.pageNum = action.payload;
    },
    setPageSizeEmployees: (
      state,
      action: PayloadAction<number | undefined>
    ) => {
      state.pageSize = action.payload;
    },
    resetEmployeesState: () => initialState,
  },
});

export const {
  fetchSlaStatisticSuccessEmployees,
  fetchEmployeesStatisticRequest,
  fetchSlaStatisticRequestEmployees,
  fetchTicketsStatisticRequestEmployees,
  fetchEmployeesStatisticSuccessEmployees,
  fetchTicketsStatisticSuccessEmployees,
  resetEmployeesState,
  setPageNumEmployees,
  setPageSizeEmployees,
  setIsLoadingEmployees,
} = employeesSlice.actions;
export const desktopEmployees = employeesSlice.reducer;
