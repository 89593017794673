import { useCallback, useLayoutEffect, useRef } from 'react';

import { VoidFunc } from '../types';

export const useEvent = <T extends VoidFunc<Parameters<T>>>(fn: T) => {
  const fnRef = useRef<T>();

  useLayoutEffect(() => {
    fnRef.current = fn;
  }, [fn]);

  return useCallback(
    (...args: Parameters<T>) => fnRef.current?.apply(null, args),
    [fnRef]
  );
};
