import cn from 'clsx';
import copy from 'copy-to-clipboard';
import { FC } from 'react';

import { CopyIcon } from '../../assets';
import { ConditionBlock } from '../ConditionBlock';
import { IconButton } from '../IconButton';

import styles from './Solution.module.scss';

interface SolutionProps {
  className?: string;
  classNameText?: string;
  text?: string;
  withCopy?: boolean;
}

export const Solution: FC<SolutionProps> = ({
  className,
  text,
  classNameText,
  withCopy,
}) => {
  const copySolutionHandler = () => {
    if (text) {
      copy(text);
    }
  };

  return (
    <div className={cn(styles.solution, className)}>
      <div className={styles.solution__header}>
        <ConditionBlock text="Решение" isSolution />
        {withCopy && (
          <IconButton
            onClick={copySolutionHandler}
            icon={<CopyIcon />}
            appearance="flat"
            disabled={!text}
          />
        )}
      </div>
      <p className={cn(styles.solution__text, classNameText)}>{text}</p>
    </div>
  );
};
