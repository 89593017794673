import { FC, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { ActionList, getIsAccessedAction } from '@entities/auth';
import { useWorkGroupId } from '@entities/work-group';
import {
  deleteWorkGroupRequest,
  getLoadingWorkGroup,
  getWorkGroup,
} from '@entities/work-groups';
import {
  ApproveOrCancel,
  DeleteButton,
  getIsMobile,
  getIsMobileSmall,
  HEADER_TYPE,
  MainLayout,
  PopoverContainer,
  RouterHref,
  UsersGroupIcon,
} from '@shared';

import styles from './WorkGroupLayout.module.scss';

export const WorkGroupLayout: FC = ({ children }) => {
  const dispatch = useDispatch();

  const isMobile = useSelector(getIsMobile);
  const isMobileSmall = useSelector(getIsMobileSmall);
  const workGroup = useSelector(getWorkGroup);
  const isAccessToDeleteGroup = useSelector(
    getIsAccessedAction(ActionList.DeleteEntries)
  );
  const isAccessToCreateTicket = useSelector(
    getIsAccessedAction(ActionList.CreateTicket)
  );
  const loading = useSelector(getLoadingWorkGroup);

  const isMobileAll = isMobile || isMobileSmall;

  const [showDeleteModal, setShowDeleteModal] = useState(false);

  const workGroupId = useWorkGroupId();

  const infoBlocksConfig = useMemo(
    () => [
      {
        title: 'Организация',
        info:
          workGroupId && workGroup?.organization?.title
            ? workGroup.organization.title
            : 'Нет данных',
        icon: <UsersGroupIcon className={styles.workGroupLayout__infoIcon} />,
      },
    ],
    [workGroupId, workGroup?.organization?.title]
  );

  const breadCrumbsConfig = useMemo(
    () => [
      { title: 'Группы', path: RouterHref.AdminGroups },
      { title: workGroup?.title || '' },
    ],
    [workGroup?.title]
  );

  const toggleDeleteModal = () => {
    setShowDeleteModal((prev) => !prev);
  };

  const handleDelete = () => {
    if (workGroupId) {
      dispatch(deleteWorkGroupRequest({ id: workGroupId, withRedirect: true }));
    }
  };

  const handleShowModalDelete = () => {
    if (workGroupId) {
      setShowDeleteModal(true);
    }
  };

  const dropContent =
    isAccessToDeleteGroup && workGroupId ? (
      <PopoverContainer>
        <DeleteButton
          onClick={handleShowModalDelete}
          disabled={!workGroup || !isAccessToDeleteGroup}
        />
      </PopoverContainer>
    ) : undefined;

  return (
    <>
      <MainLayout
        headerType={HEADER_TYPE}
        title={workGroupId ? workGroup?.title : 'Создать группу'}
        titleIcon={
          <UsersGroupIcon className={styles.workGroupLayout__titleInfoIcon} />
        }
        withGoBack={isMobileAll}
        infoBlocksConfig={infoBlocksConfig}
        breadCrumbsConfig={workGroupId ? breadCrumbsConfig : undefined}
        classNameTitleInfoBlock={styles.workGroupLayout__titleInfoBlock}
        dropContent={dropContent}
        loading={loading && !workGroup}
        accessToCreateTicket={isAccessToCreateTicket}
      >
        {children}
      </MainLayout>
      <ApproveOrCancel
        onApprove={handleDelete}
        isModal={showDeleteModal}
        toggleModal={toggleDeleteModal}
        approveTitle="Удалить"
        approveBtnProps={{
          appearance: 'outline',
          className: styles.workGroupLayout__iconDelete,
        }}
        cancelTitle="Отмена"
        text={`Вы уверены, что хотите удалить группу ${workGroup?.title}?`}
      />
    </>
  );
};
