import { ApiClient, getEnv } from '@shared';

import {
  CreateCustomFieldData,
  CustomField,
  CustomFieldsFilterToRequest,
  CustomFieldToRequest,
} from '../model';

import { endpoints } from './endpoints';

export const requests = {
  fetchCustomFields: (
    pageNum = 0,
    pageSize?: number,
    sort?: string,
    filter?: CustomFieldsFilterToRequest
  ) =>
    ApiClient.post<CustomField[]>({
      baseURL: getEnv('REACT_APP_ORGANIZATIONS_URL'),
      url: endpoints.getCustomFields(),
      params: { pageNum, pageSize, sort: sort?.toUpperCase() },
      data: {
        ...filter,
      },
    }),
  fetchCurrentCustomField: (id: string) =>
    ApiClient.get<CustomField>({
      baseURL: getEnv('REACT_APP_ORGANIZATIONS_URL'),
      url: endpoints.getCurrentCustomField(id),
      params: {},
      data: {},
    }),
  createCustomField: (body: CreateCustomFieldData) =>
    ApiClient.post<CustomField>({
      baseURL: getEnv('REACT_APP_ORGANIZATIONS_URL'),
      url: endpoints.createCustomField(),
      data: body,
    }),
  updateCustomField: (body: CustomFieldToRequest) =>
    ApiClient.put<CustomField>({
      baseURL: getEnv('REACT_APP_ORGANIZATIONS_URL'),
      url: endpoints.createCustomField(),
      data: body,
    }),
  deleteCustomField: (id: string) =>
    ApiClient.delete<boolean>({
      baseURL: getEnv('REACT_APP_ORGANIZATIONS_URL'),
      url: endpoints.deleteCustomField(id),
      data: {},
    }),
};
