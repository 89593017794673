import cn from 'clsx';
import { FC, MouseEvent, useEffect } from 'react';

import { Button, ButtonProps } from '../Button';
import { Portal } from '../Portal';
import { Typography, TypographyVariants } from '../Typography';

import styles from './ApproveOrCancelModal.module.scss';

export interface ApproveOrCancelModalProps {
  onApprove?(): void;
  onCancel?(): void;
  text: string | JSX.Element;
  description?: string;
  toggleModal(): void;
  isModal: boolean;
  approveTitle?: string | JSX.Element;
  cancelTitle?: string | JSX.Element;
  approveBtnProps?: ButtonProps;
  cancelBtnProps?: ButtonProps;
  withHideBodyScroll?: boolean;
  className?: string;
  classNameFormPortalCancel?: string;
}

export const ApproveOrCancelModal: FC<ApproveOrCancelModalProps> = ({
  text,
  description,
  onApprove,
  onCancel,
  toggleModal,
  isModal,
  approveTitle = 'Да',
  cancelTitle = 'Нет',
  approveBtnProps,
  cancelBtnProps,
  withHideBodyScroll = true,
  className,
  classNameFormPortalCancel,
}) => {
  const approveHandler = (event?: MouseEvent<HTMLButtonElement>) => {
    event?.stopPropagation();
    if (onApprove) {
      onApprove();
    }
    toggleModal();
  };

  const cancelHandler = (event?: MouseEvent<HTMLButtonElement>) => {
    event?.stopPropagation();
    if (onCancel) {
      onCancel();
    }
    toggleModal();
  };

  const getDescription = description && (
    <Typography
      variant={TypographyVariants.b3}
      className={styles.approveOrCancelModal__description}
    >
      {description}
    </Typography>
  );

  useEffect(() => {
    if (withHideBodyScroll) {
      if (isModal) {
        document.body.style.overflow = 'hidden';
        return;
      }
      document.body.style.overflow = 'visible';
    }
  }, [isModal, withHideBodyScroll]);

  useEffect(
    () => () => {
      if (withHideBodyScroll) {
        document.body.style.overflow = 'visible';
      }
    },
    [withHideBodyScroll]
  );

  if (!isModal) return null;

  return (
    <Portal>
      <div
        className={cn(styles.approveOrCancelModal, classNameFormPortalCancel)}
        onMouseDown={toggleModal}
        aria-hidden="true"
      >
        <div
          className={cn(styles.approveOrCancelModal__content, className)}
          onMouseDown={(e) => e.stopPropagation()}
          aria-hidden="true"
        >
          <Typography
            variant={TypographyVariants.b2}
            className={styles.approveOrCancelModal__text}
          >
            {text}
          </Typography>
          {getDescription}
          <div className={styles.approveOrCancelModal__buttonsBlock}>
            <Button
              appearance="flat"
              onClick={cancelHandler}
              type="button"
              className={styles.approveOrCancelModal__cancelButton}
              {...cancelBtnProps}
            >
              {cancelTitle}
            </Button>
            <Button onClick={approveHandler} {...approveBtnProps}>
              {approveTitle}
            </Button>
          </div>
        </div>
      </div>
    </Portal>
  );
};
