import { TableColumns } from '@shared';

export const ENVIRONMENT_FIELDS: TableColumns = [
  { title: 'Название', fieldName: 'name', sortField: 'name' },
];

export const INITIAL_ENVIRONMENT_FILTER = {
  id: '',
  name: '',
  systemId: '',
  description: '',
};
