import cn from 'clsx';
import { FC, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { resetFilterOrganizationIdState } from '@entities/organizations';
import {
  fetchRespByGroupIdRequest,
  resetResponsibilitiesState,
  TableResponsibilitiesTypes,
} from '@entities/responsibilities';
import {
  fetchRolesByGroupId,
  resetRolesState,
  TableRolesTypes,
} from '@entities/roles';
import { TableUsersTypes } from '@entities/user';
import { fetchUsersByGroupId, resetUsersState } from '@entities/users';
import { useWorkGroupId } from '@entities/work-group';
import {
  fetchCurrentWorkGroup,
  getLoadingWorkGroup,
  getWorkGroup,
  resetWorkGroupsState,
  setWorkGroupId,
} from '@entities/work-groups';
import { ResponsibilitiesTableContainer } from '@features/responsibilities';
import { RolesTableContainer } from '@features/roles';
import { UsersTableContainer } from '@features/users';
import { LazyLoader } from '@shared';

import { WorkGroupForm } from '../WorkGroupForm';

import styles from './WorkGroup.module.scss';

interface WorkGroupProps {
  className?: string;
}

export const WorkGroup: FC<WorkGroupProps> = ({ className }) => {
  const dispatch = useDispatch();

  const workGroupId = useWorkGroupId();

  const workGroup = useSelector(getWorkGroup);
  const loading = useSelector(getLoadingWorkGroup);

  useEffect(() => {
    if (workGroupId) {
      dispatch(setWorkGroupId(workGroupId));
      dispatch(fetchRespByGroupIdRequest());
      dispatch(fetchUsersByGroupId());
      dispatch(fetchRolesByGroupId());
      dispatch(fetchCurrentWorkGroup(workGroupId));
    }
  }, [workGroupId]);

  useEffect(
    () => () => {
      dispatch(resetWorkGroupsState());
      dispatch(resetUsersState());
      dispatch(resetResponsibilitiesState());
      dispatch(resetFilterOrganizationIdState());
      dispatch(resetRolesState());
    },
    []
  );

  if (loading && !workGroup) {
    return <LazyLoader className={styles.workGroup__loader} />;
  }

  return (
    <div className={cn(styles.workGroup, className)}>
      <div className={styles.workGroup__leftContainer}>
        <WorkGroupForm />
        <UsersTableContainer tableType={TableUsersTypes.FROM_GROUPS} />
      </div>
      <div className={styles.workGroup__rightContainer}>
        <ResponsibilitiesTableContainer
          tableType={TableResponsibilitiesTypes.FROM_GROUPS}
        />
        <RolesTableContainer tableType={TableRolesTypes.FROM_GROUPS} />
      </div>
    </div>
  );
};
