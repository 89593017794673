import {
  SupplementaryAgreement,
  SupplementaryAgreementLayout,
} from '@widgets/supplementary-agreement';

import styles from './SupplementaryAgreementPage.module.scss';

const SupplementaryAgreementPage = () => (
  <SupplementaryAgreementLayout>
    <SupplementaryAgreement
      className={styles.supplementaryAgreementPage__content}
    />
  </SupplementaryAgreementLayout>
);

export default SupplementaryAgreementPage;
