import { Control, Controller } from 'react-hook-form';

import { CreateTicketData } from '@entities/create-ticket';
import {
  Checkbox,
  CustomFieldType,
  Input,
  InputDatePicker,
  mapListToSelectOptions,
  Nullable,
  Select,
} from '@shared';

type CustomFieldComponentArgs = {
  customFieldId: string;
  title?: string;
  type?: CustomFieldType;
  index: number;
  control: Control<CreateTicketData>;
  values?: Nullable<string[]>;
};

export const getCustomFieldComponent = ({
  customFieldId,
  title,
  type,
  index,
  control,
  values,
}: CustomFieldComponentArgs) => {
  if (!type) {
    return null;
  }

  const props = {
    key: customFieldId,
    label: title,
  };

  const reactHookProps = {
    control,
    key: customFieldId,
  };

  const customFieldMap: Record<CustomFieldType, JSX.Element> = {
    [CustomFieldType.FIELD_TEXT]: (
      <Controller
        {...reactHookProps}
        name={`customFields.${index}.text`}
        rules={{ required: true }}
        render={({ field }) => <Input {...props} onChange={field.onChange} />}
      />
    ),
    [CustomFieldType.FIELD_FLAG]: (
      <Controller
        {...reactHookProps}
        name={`customFields.${index}.flag`}
        rules={{ required: true }}
        render={({ field }) => (
          <Checkbox {...props} onChange={field.onChange} />
        )}
      />
    ),
    [CustomFieldType.FIELD_DATE]: (
      <Controller
        {...reactHookProps}
        name={`customFields.${index}.date`}
        rules={{ required: true }}
        render={({ field }) => (
          <InputDatePicker
            {...props}
            type="datePicker"
            placeholder={title}
            value={field.value || ''}
            onChange={field.onChange}
          />
        )}
      />
    ),
    [CustomFieldType.FIELD_LIST]: (
      <Controller
        {...reactHookProps}
        name={`customFields.${index}.listValue`}
        rules={{ required: true }}
        render={({ field }) => (
          <Select
            options={mapListToSelectOptions(values ?? [])}
            value={field.value}
            onChange={field.onChange}
            {...props}
          />
        )}
      />
    ),
  };

  return customFieldMap[type];
};
