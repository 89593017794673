import {
  Contract,
  ContractDetailPayload,
} from '@entities/contract/model/types';
import { SLAType } from '@entities/sla/model/types';
import { System } from '@entities/system/model/types';
import { DeleteDataType, ResponseWithMeta } from '@shared';

import {
  Contracts,
  DeleteContractRequestAction,
  FetchContractRequestAction,
  FetchContractsByOrganizationsRequestAction,
  FetchContractsBySystemIdRequestAction,
  FetchContractsRequestAction,
  FetchContractsSuccessAction,
  FetchContractSuccessAction,
  HideContractLoadingAction,
  HideContractsLoadingAction,
  ResetContractsStateAction,
  ResetCurrentContractAction,
  SetContractFilterAction,
  SetCurrentContractIdAction,
  SetCurrentContractsPageAction,
  SetSizePageAction,
  SetSortContractsAction,
  SetSystemsForContractAction,
  ShowContractLoadingAction,
  ShowContractsLoadingAction,
  UpdateContractAction,
} from './acttionTypes';
import { ContractsFilter } from './types';

export const showContractsLoading = (): ShowContractsLoadingAction => ({
  type: Contracts.CONTRACTS_LOADING_SHOW,
});

export const hideContractsLoading = (): HideContractsLoadingAction => ({
  type: Contracts.CONTRACTS_LOADING_HIDE,
});

export const showContractLoading = (): ShowContractLoadingAction => ({
  type: Contracts.CONTRACT_LOADING_SHOW,
});

export const hideContractLoading = (): HideContractLoadingAction => ({
  type: Contracts.CONTRACT_LOADING_HIDE,
});

export const fetchContractsRequest = (): FetchContractsRequestAction => ({
  type: Contracts.FETCH_CONTRACTS_REQUEST,
});

export const fetchContractsSuccess = (
  data: ResponseWithMeta<Contract[]>
): FetchContractsSuccessAction => ({
  type: Contracts.FETCH_CONTRACTS_SUCCESS,
  payload: data,
});

export const setCurrentContractsPage = (
  page: number
): SetCurrentContractsPageAction => ({
  type: Contracts.SET_CURRENT_CONTRACTS_PAGE,
  payload: page,
});

export const setSizePage = (size: number): SetSizePageAction => ({
  type: Contracts.SET_SIZE_PAGE_CONTRACTS,
  payload: size,
});

export const setSortContracts = (sort: string): SetSortContractsAction => ({
  type: Contracts.SET_SORT_CONTRACTS,
  payload: sort,
});

export const fetchContractRequest = (
  id: string
): FetchContractRequestAction => ({
  type: Contracts.FETCH_CONTRACT_REQUEST,
  payload: id,
});

export const fetchContractSuccess = (
  data?: Contract
): FetchContractSuccessAction => ({
  type: Contracts.FETCH_CONTRACT_SUCCESS,
  payload: data,
});

export const resetContractsState = (): ResetContractsStateAction => ({
  type: Contracts.RESET_CONTRACTS_STATE,
});

export const setCurrentContractId = (
  id: string
): SetCurrentContractIdAction => ({
  type: Contracts.SET_CURRENT_CONTRACT_ID,
  payload: id,
});

export const fetchContractsByOrganizationsRequest =
  (): FetchContractsByOrganizationsRequestAction => ({
    type: Contracts.FETCH_CONTRACTS_BY_ORGANIZATION_ID_REQUEST,
  });

export const fetchContractsBySystemIdRequest =
  (): FetchContractsBySystemIdRequestAction => ({
    type: Contracts.FETCH_CONTRACTS_BY_SYSTEM_ID_REQUEST,
  });

export const setContractFilter = (
  data: ContractsFilter
): SetContractFilterAction => ({
  type: Contracts.SET_CONTRACT_FILTER,
  payload: data,
});

export const updateContract = (data: Contract): UpdateContractAction => ({
  type: Contracts.UPDATE_CONTRACT,
  payload: data,
});

export const setSystemsForContract = (
  data: System[]
): SetSystemsForContractAction => ({
  type: Contracts.SET_SYSTEMS_FOR_CONTRACT,
  payload: data,
});

export const deleteContractRequest = (
  data: DeleteDataType
): DeleteContractRequestAction => ({
  type: Contracts.DELETE_CONTRACT_REQUEST,
  payload: data,
});

export const resetCurrentContract = (): ResetCurrentContractAction => ({
  type: Contracts.RESET_CURRENT_CONTRACT,
});

export const fetchSLAByContractIdBySystemIdRequestContracts = (
  payload: ContractDetailPayload
) => ({
  type: Contracts.FETCH_SLA_BY_CONTRACT_ID_BY_SYSTEM_ID_REQUEST,
  payload,
});

export const fetchSLAByContractIdBySystemIdSuccessContracts = (
  sla: SLAType
) => ({
  type: Contracts.FETCH_SLA_BY_CONTRACT_ID_BY_SYSTEM_ID_SUCCESS,
  payload: sla,
});

export const resetSLAByContractIdBySystemIdContracts = () => ({
  type: Contracts.RESET_SLA_BY_CONTRACT_ID_BY_SYSTEM_ID,
});
