import Triggers from '@features/triggers';
import { AdminsLayout } from '@pages/AdminsLayout';

const TriggersPage = () => (
  <AdminsLayout>
    <Triggers />
  </AdminsLayout>
);

export default TriggersPage;
