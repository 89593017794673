import { JiraPriority } from '@entities/jira-integrations';
import { Priority } from '@entities/ticket';

type Args = {
  priorities?: JiraPriority[];
  priority: Priority;
};

export const getJiraPriority = ({ priorities, priority }: Args) =>
  priorities?.find((item) => item.ticketPriorities?.includes(priority));
