import { getUrl } from '@shared';

export const endpoints = {
  getOrganizationsForArticleCreate: () =>
    getUrl(`v1/user-access/organizations-for-write`),
  getWorkGroupsForArticleCreate: () =>
    getUrl('v1/user-access/workgroups-for-write'),
  getCategoriesByOrganizationIds: () =>
    getUrl('v1/category/parent/organizations'),
  getAllCategories: () => getUrl('v1/category/parent'),
};
