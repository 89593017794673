import _ from 'lodash';

import { ObjectType } from '@shared/model';

const isValue = (value: unknown) => {
  if (_.isNil(value)) {
    return false;
  }
  if (typeof value === 'number') {
    return true;
  }
  if (typeof value === 'string') {
    return !!value;
  }
  if (_.isDate(value)) {
    return true;
  }
  if (typeof value === 'object') {
    return !_.isEmpty(value);
  }
  return true;
};

export const checkObjectIdentity = (obj1: ObjectType, obj2: ObjectType) =>
  _.isEqual(
    _.omitBy(obj1, (value, key) => (!isValue(value) ? key : false)),
    _.omitBy(obj2, (value, key) => (!isValue(value) ? key : false))
  );
