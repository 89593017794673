import _, { debounce } from 'lodash';
import { memo, useEffect, useMemo } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';

import {
  fetchArticlesTableRequest,
  fetchOrganizationsRequestArticleTable,
  getSubCategoriesSelectListTable,
  KBArticleTableFilter,
  setArticlesTableFilter,
  setCurrentArticlesTablePage,
} from '@entities/knowledge-base';
import {
  DEFAULT_DEBOUNCE_DELAY,
  Input,
  Select,
  TableBodyCell,
  TableFilter,
} from '@shared';
import { Size } from '@shared/model';

import { useCategoryId } from '../../../lib';

import styles from './KBTableArticlesFilter.module.scss';

export const KBTableArticlesFilter = memo(() => {
  const dispatch = useDispatch();

  const categoryId = useCategoryId();

  const subCategories = useSelector(getSubCategoriesSelectListTable);

  const { register, handleSubmit, control, reset, watch } =
    useForm<KBArticleTableFilter>({
      mode: 'onChange',
      defaultValues: {
        category: null,
        organization: null,
      },
    });

  const filterValues = watch();
  const disableReset = _.isEqual(
    _.omitBy(filterValues, (value, key) =>
      _.isNil(value) || (_.isEmpty(value) && typeof value !== 'boolean')
        ? key
        : false
    ),
    {}
  );

  const onFilter = (data: KBArticleTableFilter) => {
    if (categoryId) {
      dispatch(setCurrentArticlesTablePage(0));
      dispatch(setArticlesTableFilter(data));
      dispatch(fetchArticlesTableRequest(categoryId));
    }
  };

  const resetFilter = () => {
    onFilter({});
    reset();
  };

  const formSubmitHandler = handleSubmit((data) => {
    onFilter(data);
  });

  const formSubmitHandlerDebounced = useMemo(
    () => debounce(formSubmitHandler, DEFAULT_DEBOUNCE_DELAY),
    []
  );

  const titleToInput = register('title', {
    onChange: formSubmitHandlerDebounced,
  });
  const userNameToInput = register('username', {
    onChange: formSubmitHandlerDebounced,
  });

  const updatedToInputOptions = register('updated', {
    onChange: formSubmitHandlerDebounced,
  });

  useEffect(() => {
    dispatch(fetchOrganizationsRequestArticleTable({}));
  }, []);

  const tableBodyWrapper = (elements: JSX.Element[]) =>
    elements.map((item) => (
      <TableBodyCell
        className={styles.KBTableArticlesFilter__cell}
        key={item.key}
      >
        {item}
      </TableBodyCell>
    ));

  const filterElements = () => [
    <Input size={Size.xs} type="title" key="title" {...titleToInput} />,
    <Controller
      control={control}
      name="category"
      key="category"
      render={({ field }) => (
        <Select<string>
          size={Size.xs}
          mobileModalTitle="тип"
          options={subCategories}
          value={field.value}
          onChange={(value) => {
            field.onChange(value);
            formSubmitHandler();
          }}
        />
      )}
    />,
    <Input
      size={Size.xs}
      type="username"
      key="username"
      {...userNameToInput}
    />,
    <Input size={Size.xs} type="datetime-local" {...updatedToInputOptions} />,
    <Controller
      control={control}
      name="organization"
      key="organization"
      render={({ field }) => (
        <Select<string>
          size={Size.xs}
          options={[]}
          disabled
          value={field.value}
        />
      )}
    />,
  ];

  const filter = <>{tableBodyWrapper(filterElements())}</>;

  return (
    <TableFilter
      filterComponent={filter}
      onReset={resetFilter}
      disableReset={disableReset}
    />
  );
});
