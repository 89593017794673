import { FC } from 'react';
import { useSelector } from 'react-redux';

import { ActionList, getIsAccessedAction } from '@entities/auth';
import { getSystem } from '@entities/system';
import {
  Cubes,
  getIsMobile,
  getIsMobileSmall,
  HEADER_TYPE,
  MainLayout,
  RouterHref,
} from '@shared';

import { useSystemId } from '../lib/hooks';

import styles from './SystemLayout.module.scss';

export const SystemLayout: FC = ({ children }) => {
  const systemId = useSystemId();

  const system = useSelector(getSystem);
  const isMobile = useSelector(getIsMobile);
  const isMobileSmall = useSelector(getIsMobileSmall);

  const isAccessToCreateTicket = useSelector(
    getIsAccessedAction(ActionList.CreateTicket)
  );

  const isMobileAll = isMobile || isMobileSmall;

  const breadCrumbsConfig = [
    { title: 'Системы', path: RouterHref.AdminSystems },
    { title: `${system?.title}` },
  ];

  return (
    <MainLayout
      headerType={HEADER_TYPE}
      title={systemId ? system?.title : 'Создать систему'}
      titleIcon={<Cubes className={styles.system__icon} />}
      withGoBack={isMobileAll}
      breadCrumbsConfig={systemId ? breadCrumbsConfig : undefined}
      accessToCreateTicket={isAccessToCreateTicket}
    >
      {children}
    </MainLayout>
  );
};
