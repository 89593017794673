import { TableResponsibilitiesTypes } from '@entities/responsibilities';
import { TableColumns } from '@shared';

export const RESPONSIBILITIES_FULL_FIELDS: TableColumns = [
  {
    title: 'Название',
    sortField: 'title',
    fieldName: 'title',
  },
  { title: 'Описание', fieldName: 'description', sortField: 'description' },
  {
    title: 'Организация',
    fieldName: 'organization',
  },
];

export const RESPONSIBILITIES_FIELDS: TableColumns = [
  {
    title: 'Название',
    sortField: 'title',
    fieldName: 'title',
  },
  { title: 'Описание', fieldName: 'description', sortField: 'description' },
];

export const COLUMNS_RESPONSIBILITIES_ADDED: TableColumns = [
  {
    title: 'Название',
    fieldName: 'title',
  },
  { title: 'Описание', fieldName: 'description' },
];

export const INITIAL_RESPONSIBILITIES_FILTER = {
  title: '',
  description: '',
  organizationId: null,
};

export const PAGINATION_RANGE_MAP: Record<
  TableResponsibilitiesTypes,
  number | undefined
> = {
  [TableResponsibilitiesTypes.FULL]: 1,
  [TableResponsibilitiesTypes.FROM_GROUPS]: 0,
  [TableResponsibilitiesTypes.FROM_ROLES]: 0,
  [TableResponsibilitiesTypes.ADD_MODAL]: 0,
};
