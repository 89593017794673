import { FC } from 'react';

import { Button, InfoIcon, Typography, TypographyVariants } from '@shared';

import styles from './StatusChain.module.scss';

interface StatusChainProps {
  onCreateStatus: () => void;
}

export const StatusChain: FC<StatusChainProps> = ({
  onCreateStatus,
  children,
}) => (
  <div className={styles.statusChain__wrapper}>
    <InfoIcon className={styles.statusChain__icon} />
    <Typography
      variant={TypographyVariants.b3}
      className={styles.statusChain__description}
    >
      <span className={styles.statusChain__description_text}>
        Попробуйте использовать статус
      </span>
      <Typography variant={TypographyVariants.h5} component="span">
        {children}
      </Typography>
    </Typography>
    <Button
      onClick={onCreateStatus}
      className={styles.statusChain__button}
      appearance="outline"
    >
      Применить
    </Button>
  </div>
);
