import { ActionList } from '@entities/auth/model/types';

import { Action, ActionWithCustomFields } from '../../model';

export const addCustomFieldsToAction = (
  actions: Action[],
  serviceAdmin: boolean
): ActionWithCustomFields[] =>
  actions.map((item: Action) => {
    const canChangeAccessViewingAllTickets =
      serviceAdmin && item.title === ActionList.ViewingAllTickets;
    return {
      ...item,
      status: !!item?.scope?.length,
      disabled: !canChangeAccessViewingAllTickets,
    };
  });
