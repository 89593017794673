import { User } from '@entities/user/model/types';
import { ApiClient, getEnv, ResponseWithMeta } from '@shared';

import { DesktopFilters, SlaStatistic, TicketsStatistic } from '../../model';

import { endpointsEmployees } from './endpoints';

export const requestsEmployees = {
  fetchEmployeesSlaStatistic: (filterValues?: DesktopFilters) =>
    ApiClient.post<SlaStatistic>({
      baseURL: getEnv('REACT_APP_SLA_URL'),
      url: endpointsEmployees.getEmployeesSlaStatistic(),
      data: filterValues,
    }),
  fetchEmployeesStatistic: (
    pageNum = 0,
    pageSize?: number,
    filterValues?: DesktopFilters
  ) =>
    ApiClient.post<ResponseWithMeta<User[]>>({
      url: endpointsEmployees.getEmployeesStatistic(),
      params: { pageNum, pageSize },
      data: filterValues,
    }),
  fetchEmployeesTicketsStatistic: (filterValues?: DesktopFilters) =>
    ApiClient.post<TicketsStatistic>({
      baseURL: getEnv('REACT_APP_TICKET_REPORT_URL'),
      url: endpointsEmployees.getEmployeesTicketsStatistic(),
      data: filterValues,
    }),
};
