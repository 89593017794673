import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { KpiData, KpiDataRequest } from '../types';

export interface KpiReducerState {
  kpiData?: KpiData;
}

const initialState: KpiReducerState = {};

const kpiSlice = createSlice({
  name: 'desktopKpi',
  initialState,
  reducers: {
    fetchKpiSuccess: (state, action: PayloadAction<KpiData>) => {
      state.kpiData = action.payload;
    },
    fetchKpiRequest: (_state, _action: PayloadAction<KpiDataRequest>) => {},
    resetKpiState: () => initialState,
  },
});

export const { fetchKpiRequest, fetchKpiSuccess, resetKpiState } =
  kpiSlice.actions;
export const desktopKpi = kpiSlice.reducer;
