import cn from 'clsx';
import { FC } from 'react';

import styles from './NotificationCircle.module.scss';

interface NotificationCircleProps {
  newTicketNotification?: boolean;
  className?: string;
}

export const NotificationCircle: FC<NotificationCircleProps> = ({
  className,
  newTicketNotification,
}) => (
  <div
    className={cn(
      styles.notificationCircle,
      {
        [styles.notificationCircle_new]: newTicketNotification,
      },
      className
    )}
  />
);
