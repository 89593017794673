import { createSelector } from 'reselect';

import { DEFAULT_CUSTOM_FIELD_VALUES } from '@entities/custom-fields/config';

import {
  FileCardCustomFields,
  StorageCustomField,
  StorageState,
} from './types';

export const getStorage = (state: StorageState) => state.storage.storage;
export const getStorageFiles = (state: StorageState) =>
  state.storage.storageFiles;
export const getCurrentFileCard = (state: StorageState) =>
  state.storage.currentFileCard;
export const getFileCardCustomFields = (state: StorageState) =>
  state.storage.currentFileCard?.fileCardCustomFields || [];
export const getDownloadedFiles = (state: StorageState) =>
  state.storage.downloadedFiles || [];
export const getStoragePageNum = (state: StorageState) => state.storage.pageNum;
export const getStoragePageSize = (state: StorageState) =>
  state.storage.pageSize;
export const getLoading = (state: StorageState) => state.storage.loading;
export const getStorageTitle = (state: StorageState) =>
  state.storage.storage?.title || '';
export const getStorageDescription = (state: StorageState) =>
  state.storage.storage?.description || '';
export const getUploadingFiles = (state: StorageState) =>
  state.storage.uploadingFiles;
export const getCustomFields = (state: StorageState) =>
  state.storage.storage?.customFields || [];

export const getCustomFieldsWithValue = createSelector<
  StorageState,
  StorageCustomField[],
  FileCardCustomFields[],
  StorageCustomField[]
>(
  [getCustomFields, getFileCardCustomFields],
  (customFields, fileCardCustomFields) =>
    customFields?.reduce<StorageCustomField[]>((acc, { id, ...other }) => {
      const valueCustomField = fileCardCustomFields.find(
        (item) => item.customFieldId === id
      );
      const result = [...acc];

      if (valueCustomField) {
        const { text, flag, date } = valueCustomField;
        return [...result, { text, flag, date, id, ...other }];
      }

      return [...result, { DEFAULT_CUSTOM_FIELD_VALUES, id, ...other }];
    }, [])
);
