import { convertValueTypeToArrayValue, convertValueTypeToValue } from '@shared';

import { SystemsFilter, SystemsFilterToRequest } from '../../model';

export const getFilterSystemToRequest = (
  filter: SystemsFilter
): SystemsFilterToRequest => {
  const { organizationId, environmentIds } = filter;

  return {
    ...filter,
    organizationId: convertValueTypeToValue(organizationId),
    environmentIds: convertValueTypeToArrayValue(environmentIds),
  };
};
