import { FC, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';

import { fetchSystem, resetSystemState } from '@entities/system';

import { SystemForm } from '../SystemForm';

import styles from './System.module.scss';

export const System: FC = () => {
  const { systemId } = useParams<{ systemId: string }>();
  const dispatch = useDispatch();

  useEffect(() => {
    if (systemId) {
      dispatch(fetchSystem(systemId));
    }
  }, [systemId]);

  useEffect(
    () => () => {
      dispatch(resetSystemState);
    },
    []
  );

  return (
    <div className={styles.system}>
      <SystemForm />
    </div>
  );
};
