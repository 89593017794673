import cn from 'clsx';
import { debounce } from 'lodash';
import { FC, useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';

import { ActionList } from '@entities/auth';
import {
  addSpecAndSetToWork,
  getIsAccessedTicketAction,
  getTicket,
  TicketStatus,
  useTicketId,
} from '@entities/ticket';
import {
  fetchSubordinatesRequest,
  getSubordinatesWithOutCurrentSelectList,
  setSubordinatesFilter,
} from '@entities/users';
import {
  Button,
  DEFAULT_DEBOUNCE_DELAY,
  Select,
  SelectOption,
  Size,
  Typography,
  TypographyVariants,
  ValueType,
} from '@shared';

import styles from './SpecialistForm.module.scss';

interface SpecialistFormProps {
  className?: string;
  classNameForm?: string;
  classNameSelect?: string;
  classNameButton?: string;
}

export const SpecialistForm: FC<SpecialistFormProps> = ({
  className,
  classNameForm,
  classNameSelect,
  classNameButton,
}) => {
  const ticket = useSelector(getTicket);
  const isAccessToAssigningTicketToSpecialist = useSelector(
    getIsAccessedTicketAction(ActionList.AssigningTicketToSpecialist)
  );
  const executorOptions =
    useSelector(
      getSubordinatesWithOutCurrentSelectList(ticket?.specialistId?.key)
    ) || [];

  const ticketId = useTicketId();

  const dispatch = useDispatch();

  const onFilterSpecialist = debounce((valueFilter: string) => {
    dispatch(
      setSubordinatesFilter({
        fullName: valueFilter,
      })
    );
    dispatch(fetchSubordinatesRequest());
  }, DEFAULT_DEBOUNCE_DELAY);

  useEffect(() => {
    if (isAccessToAssigningTicketToSpecialist && ticket) {
      dispatch(fetchSubordinatesRequest());
    }
  }, [isAccessToAssigningTicketToSpecialist, ticket]);

  const {
    control,
    handleSubmit,
    watch,
    reset,
    formState: { isDirty, isValid },
  } = useForm<{ executor: ValueType<string> }>({
    mode: 'onChange',
    defaultValues: {
      executor: null,
    },
  });

  const currentExecutorId = watch('executor');

  const formSubmitHandler = handleSubmit(({ executor }) => {
    if (ticketId) {
      dispatch(
        addSpecAndSetToWork({
          ticketId: [ticketId],
          specialistId:
            executor && !Array.isArray(executor) ? executor.value : '',
        })
      );
    }
    reset();
  });

  return (
    <div className={cn(styles.specialistForm, className)}>
      <Typography
        variant={TypographyVariants.o}
        className={styles.specialistForm__subTitle}
      >
        Назначить исполнителя
      </Typography>
      <form
        onSubmit={formSubmitHandler}
        className={cn(styles.specialistForm__form, classNameForm)}
      >
        <Controller
          control={control}
          name="executor"
          rules={{ required: true }}
          render={({ field }) => (
            <Select<string>
              size={Size.s}
              mobileModalTitle="исполнителя"
              placeholder="Исполнитель"
              options={executorOptions}
              onChange={field.onChange}
              onChangeInput={onFilterSpecialist}
              value={field.value}
              isSearchable
              className={cn(styles.specialistForm__select, classNameSelect)}
            />
          )}
        />
        <Button
          className={cn(styles.specialistForm__toAppoint, classNameButton)}
          disabled={
            ticket?.customStatus?.defaultStatus === TicketStatus.CLOSE ||
            ticket?.specialistId?.key ===
              (currentExecutorId as SelectOption)?.value ||
            !isDirty ||
            !isValid
          }
        >
          Назначить
        </Button>
      </form>
    </div>
  );
};
