import { FC } from 'react';

import { ConditionBlock } from '@shared';

import { useTicketTypes } from '../../lib';
import { TicketTypeEditor } from '../TicketTypeEditor';

import styles from './TicketType.module.scss';

export const TicketType: FC = () => {
  const { canProcessTicketType, ticket } = useTicketTypes();

  const ticketTypeEditor = canProcessTicketType && <TicketTypeEditor />;

  return (
    <div className={styles.ticketType}>
      <ConditionBlock text={ticket?.ticketType?.value} />
      {ticketTypeEditor}
    </div>
  );
};
