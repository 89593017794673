import { isEmpty } from 'lodash';
import {
  ChangeEvent,
  MouseEvent,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { v4 as uuidv4 } from 'uuid';

import {
  createTrigger,
  CreateTriggerData,
  CustomCondition,
  DEFAULT_VALUES_TRIGGER_FORM,
  fetchAttributesFieldsRequest,
  fetchCustomFieldsRequest,
  fetchNextTicketStatusRequest,
  fetchTicketStatusRequest,
  getAttributesFields,
  getCustomFields,
  getCustomFieldsMap,
  getNextTicketStatusOptions,
  getTriggerWithCustomFieldValues,
  INITIAL_ATTRIBUTES_VALUES,
  resetCurrentTrigger,
  TriggerAttribute,
  TriggerAttributeIds,
  TriggerCondition,
  updateTrigger,
} from '@entities/triggers';
import {
  CustomFieldType,
  getOptionTitle,
  getOptionValue,
  getValueFromValueType,
  SelectOption,
  ValueType,
} from '@shared';

import {
  CUSTOM_FIELD_TYPE_CONDITION_MAP,
  INITIAL_ATTRIBUTES,
  TICKET_STATUS,
} from '../../config';
import {
  getAttributesOptions,
  getCustomFieldsOptions,
  getNormalizedAttributes,
  getNormalizedCustomConditions,
  getNormalizedTriggerConditions,
  getTriggerConditionOptions,
  prepareAttributeForRequest,
  prepareAttributeForSubmit,
  prepareCustomConditionForSubmit,
  prepareTriggerConditionForSubmit,
} from '../utils';

interface UseTriggerFormProps {
  isModal: boolean;
  isEditMode: boolean;
  toggleIsModal(): void;
}

export const useTriggerForm = ({
  isModal,
  isEditMode,
  toggleIsModal,
}: UseTriggerFormProps) => {
  const dispatch = useDispatch();

  const trigger = useSelector(getTriggerWithCustomFieldValues);
  const attributesFields = useSelector(getAttributesFields);
  const customFields = useSelector(getCustomFields);
  const customFieldsMap = useSelector(getCustomFieldsMap);
  const nextTicketStatusOptions = useSelector(getNextTicketStatusOptions);

  const [attributes, setAttributes] =
    useState<TriggerAttribute[]>(INITIAL_ATTRIBUTES);
  const [customConditions, setCustomConditions] = useState<CustomCondition[]>();
  const [triggerConditions, setTriggerConditions] =
    useState<TriggerCondition[]>();
  const [alertIsOpen, setAlertIsOpen] = useState(true);

  const attributesOptions = getAttributesOptions(attributes);

  const customFieldsOptions = getCustomFieldsOptions(
    customConditions,
    customFields
  );

  const triggerConditionOptions = getTriggerConditionOptions(triggerConditions);

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [organizationAttribute, systemAttribute, _, statusAttribute] =
    attributes;

  const {
    handleSubmit,
    control,
    reset,
    setValue,
    register,
    resetField,
    formState: { errors, isValid },
  } = useForm<CreateTriggerData>({
    mode: 'onChange',
    defaultValues: DEFAULT_VALUES_TRIGGER_FORM,
  });

  const titleInputOptions = register('title', {
    required: true,
    minLength: {
      value: 3,
      message: 'Название триггера не может быть меньше 3 символов.',
    },
    maxLength: {
      value: 100,
      message: 'Название триггера не может быть длиннее 100 символов.',
    },
  });

  const isDisabledAttribute = useMemo(() => {
    let valid = false;

    if (!attributesOptions.length) {
      return true;
    }

    attributes.forEach((attribute) => {
      if (isEmpty(attribute.value)) {
        valid = true;
      }
    });

    return valid;
  }, [attributes, attributesOptions]);

  const isDisabledCustomCondition = useMemo(() => {
    let valid = false;

    if (isEmpty(systemAttribute.value) || !customFieldsOptions?.length) {
      return true;
    }

    if (customConditions) {
      customConditions.forEach((condition) => {
        if (
          isEmpty(condition.condition) ||
          !getValueFromValueType(condition.customField)
        ) {
          valid = true;
        }
      });
    }

    return valid;
  }, [customConditions, customFieldsOptions, systemAttribute]);

  const isDisabledTriggerCondition = useMemo(() => {
    let valid = false;

    if (!isEmpty(triggerConditions) && !triggerConditionOptions.length) {
      return true;
    }

    if (triggerConditions) {
      triggerConditions.forEach((condition) => {
        if (
          !condition.condition ||
          !getValueFromValueType(condition.condition)
        ) {
          valid = true;
        }
      });
    }

    return valid;
  }, [triggerConditions, triggerConditionOptions]);

  const isDisabledSubmit = useMemo(() => {
    const customConditionsIsEmpty =
      customConditions &&
      customConditions.find((item) => {
        const hasCustomFieldValue = getValueFromValueType(item.customField);
        const isListWithValue =
          item.type === CustomFieldType.FIELD_LIST && !item.value;
        return hasCustomFieldValue && !isListWithValue;
      });

    const triggerConditionsIsEmpty =
      triggerConditions &&
      triggerConditions.find((item) => getValueFromValueType(item.condition));

    const conditionsIsEmpty = !(
      customConditionsIsEmpty || triggerConditionsIsEmpty
    );

    const attributeValueIsEmpty = !!attributes.filter((attribute) =>
      isEmpty(attribute.value)
    ).length;

    return !isValid || conditionsIsEmpty || attributeValueIsEmpty;
  }, [isValid, customConditions, triggerConditions, attributes]);

  const isDisabledTargetStatus = !getValueFromValueType(statusAttribute?.value);

  const resetTrigger = () => {
    resetField('targetStatus');
    reset();
    setAttributes(INITIAL_ATTRIBUTES);
    setCustomConditions(undefined);
    setTriggerConditions(undefined);
  };

  const closeModal = () => {
    toggleIsModal();
    resetTrigger();
  };

  const onCloseAlert = () => {
    setAlertIsOpen(false);
  };

  const formSubmitHandler = handleSubmit((data) => {
    const { title, isEnable } = data;

    const targetStatus = getValueFromValueType(data.targetStatus) || '';

    const preparedAttributes = prepareAttributeForSubmit(attributes);

    const preparedTriggerCondition =
      prepareTriggerConditionForSubmit(triggerConditions);
    const preparedCustomConditions =
      prepareCustomConditionForSubmit(customConditions);

    const preparedData = {
      title,
      isEnable,
      targetStatus,
      defaultConditions: preparedTriggerCondition,
      customConditionRequests: preparedCustomConditions,
      ...preparedAttributes,
    };

    if (isEditMode && trigger) {
      dispatch(updateTrigger({ ...preparedData, isEnable: trigger.isEnable }));
      closeModal();
      return;
    }

    dispatch(createTrigger(preparedData));
    closeModal();
  });

  const fetchTriggersFieldsHandler = (data: TriggerAttribute[]) => {
    const preparedAttributes = prepareAttributeForRequest(data);
    dispatch(fetchAttributesFieldsRequest(preparedAttributes));
  };

  const fetchCustomFieldHandler = (systemId: string) => {
    dispatch(fetchCustomFieldsRequest(systemId));
  };

  const fetchTicketStatus = (ids: TriggerAttributeIds) => {
    resetField('targetStatus');
    const { systems: systemIds, ticketTypes: typeIds } = ids || {};

    dispatch(
      fetchTicketStatusRequest({
        excludeStatuses: TICKET_STATUS,
        systemIds,
        typeIds,
      })
    );
  };

  const fetchNextTicketStatus = (ids: TriggerAttributeIds) => {
    resetField('targetStatus');

    const {
      systems: systemIds,
      ticketTypes: typeIds,
      status: statusId,
    } = ids || {};

    dispatch(
      fetchNextTicketStatusRequest({
        nextToIds: statusId,
        excludeStatuses: TICKET_STATUS,
        systemIds,
        typeIds,
      })
    );
  };

  const onAddAttributeHandler = useCallback(() => {
    setAttributes((prevState) => [
      ...prevState,
      {
        id: uuidv4(),
        isActive: true,
        attribute: { title: '', value: '' },
        value: [],
      },
    ]);
  }, [setAttributes]);

  const onDeleteAttributeHandler = useCallback(
    (id: string) => () => {
      setAttributes((prevState) => {
        const newState = prevState.filter((attribute) => attribute.id !== id);
        fetchTriggersFieldsHandler(newState);
        return newState;
      });
    },
    [setAttributes]
  );

  const onChangeRequiredAttributeHandler = useCallback(
    (event: MouseEvent<HTMLButtonElement>, id: string) => {
      event.preventDefault();
      resetField('targetStatus');
      setAttributes((prevState) => {
        const attributeIndex = prevState.findIndex(
          (attribute) => attribute.id === id
        );

        const newState = prevState.map((attribute, index) => {
          if (index >= attributeIndex) {
            return { ...attribute, value: [] };
          }
          return attribute;
        });

        fetchTriggersFieldsHandler(newState);
        return newState;
      });

      const isOrganizationAttr = organizationAttribute.id === id;
      const isSystemAttr = systemAttribute.id === id;

      if (isOrganizationAttr || isSystemAttr) {
        setCustomConditions(undefined);
      }
    },
    [setAttributes, setCustomConditions, organizationAttribute, systemAttribute]
  );

  const onChangeAttributeValue = useCallback(
    // eslint-disable-next-line sonarjs/cognitive-complexity
    (id: string, attributeValue?: string) => (value: ValueType<string>) => {
      const isSystems = attributeValue === 'systems';
      const isTicketTypes = attributeValue === 'ticketTypes';
      const systemId = isSystems ? getValueFromValueType(value) : undefined;

      if (isSystems && customConditions?.length) {
        setCustomConditions(undefined);
      }

      if (systemId) {
        fetchCustomFieldHandler(systemId);
      }

      const isStatus = attributeValue === 'status';

      setAttributes((prevState) => {
        const attributeIndex = prevState.findIndex(
          (attribute) => attribute.id === id
        );

        const newState = prevState.map((attribute, index) => {
          if (index > attributeIndex) {
            return { ...attribute, value: [] };
          }

          if (attribute.id === id) {
            return { ...attribute, value };
          }

          return attribute;
        });

        const attributeIds = prepareAttributeForRequest(newState);

        if (isTicketTypes) {
          fetchTicketStatus(attributeIds);
        }

        if (isStatus) {
          fetchNextTicketStatus(attributeIds);
        }

        fetchTriggersFieldsHandler(newState);
        return newState;
      });
    },
    [customConditions, setAttributes, setCustomConditions]
  );

  const onChangeAttribute = useCallback(
    (id: string) => (value: SelectOption) => {
      setAttributes((prevState) => {
        fetchTriggersFieldsHandler(prevState);
        return prevState.map((attribute) => {
          if (!prevState.length || attribute.id === id) {
            return { ...attribute, isActive: true, attribute: value };
          }
          return attribute;
        });
      });
      onChangeAttributeValue(id)([]);
    },
    [setAttributes]
  );

  const onAddCustomConditionHandler = useCallback(() => {
    setCustomConditions((prevState) => [
      ...(prevState || []),
      {
        id: uuidv4(),
        isActive: true,
        customField: { title: '', value: '' },
        type: null,
        condition: [],
        value: null,
      },
    ]);
  }, [setCustomConditions]);

  const onDeleteCustomConditionHandler = useCallback(
    (id: string) => () => {
      setCustomConditions((prevState) =>
        prevState?.filter((attribute) => attribute.id !== id)
      );
    },
    [setCustomConditions]
  );

  const onChangeCustomCondition = useCallback(
    (id: string) => (value: ValueType<string>) => {
      setCustomConditions((prevState) => {
        if (prevState) {
          return prevState.map((customField) => {
            if (customField.id === id) {
              return { ...customField, condition: value };
            }
            return customField;
          });
        }
        return prevState;
      });
    },
    [setCustomConditions]
  );

  const onChangeCustomField = useCallback(
    (id: string) => (value: SelectOption) => {
      const customFieldId = getValueFromValueType(value) || '';
      const type = customFieldsMap[customFieldId];

      setCustomConditions((prevState) => {
        if (prevState) {
          return prevState.map((customField) => {
            if (customField.id === id) {
              return {
                ...customField,
                customField: value,
                type,
              };
            }
            return customField;
          });
        }
        return prevState;
      });

      const customField = {
        title: getOptionTitle(CUSTOM_FIELD_TYPE_CONDITION_MAP[type]) || '',
        value: getOptionValue(CUSTOM_FIELD_TYPE_CONDITION_MAP[type]) || '',
      };

      onChangeCustomCondition(id)(customField);
    },
    [setCustomConditions, onChangeCustomCondition, customFieldsMap]
  );

  const onChangeListCustomField = useCallback(
    (id: string) =>
      ({ value }: SelectOption) => {
        setCustomConditions((prevState) => {
          if (prevState) {
            return prevState.map((customField) => {
              if (customField.id === id) {
                return {
                  ...customField,
                  value,
                };
              }
              return customField;
            });
          }
          return prevState;
        });
      },
    [customFieldsMap]
  );

  const onChangeCustomConditionValue = useCallback(
    (id: string) => (event: ChangeEvent<HTMLInputElement>) => {
      setCustomConditions((prevState) => {
        if (prevState) {
          return prevState.map((condition) => {
            if (condition.id === id) {
              return { ...condition, value: event.target.value };
            }
            return condition;
          });
        }
        return prevState;
      });
    },
    [setCustomConditions]
  );

  const onAddTriggerConditionHandler = useCallback(() => {
    setTriggerConditions((prevState) => [
      ...(prevState || []),
      {
        id: uuidv4(),
        isActive: true,
        condition: { title: '', value: '' },
        value: '00:00',
      },
    ]);
  }, [setTriggerConditions]);

  const onChangeTriggerCondition = useCallback(
    (id: string) => (value: ValueType<string>) => {
      setTriggerConditions((prevState) => {
        if (prevState) {
          return prevState.map((condition) => {
            if (condition.id === id) {
              return { ...condition, condition: value };
            }
            return condition;
          });
        }
        return prevState;
      });
    },
    [setTriggerConditions]
  );

  const onChangeTriggerConditionValue = useCallback(
    (event: ChangeEvent<HTMLInputElement>, id: string) => {
      // eslint-disable-next-line sonarjs/no-identical-functions
      setTriggerConditions((prevState) => {
        if (prevState) {
          // eslint-disable-next-line sonarjs/no-identical-functions
          return prevState.map((condition) => {
            if (condition.id === id) {
              return { ...condition, value: event.target.value };
            }
            return condition;
          });
        }
        return prevState;
      });
    },
    [setTriggerConditions]
  );

  const onDeleteTriggerConditionHandler = useCallback(
    (id: string) => () => {
      setTriggerConditions((prevState) =>
        prevState?.filter((attribute) => attribute.id !== id)
      );
    },
    [setTriggerConditions]
  );

  useEffect(() => {
    if (isModal) {
      resetTrigger();
    }

    if (isEditMode && trigger) {
      const normalizedAttributes = getNormalizedAttributes(trigger);
      const normalizedCustomConditions = getNormalizedCustomConditions(
        trigger.customConditions
      );
      const normalizedTriggerConditions = getNormalizedTriggerConditions(
        trigger.defaultConditions
      );

      setValue('title', trigger.title);
      setValue('targetStatus', {
        title: trigger.targetStatus.value,
        value: trigger.targetStatus.key,
      });
      setAttributes(normalizedAttributes);
      setCustomConditions(normalizedCustomConditions);
      setTriggerConditions(normalizedTriggerConditions);
    }
  }, [isEditMode, isModal, trigger]);

  useEffect(() => {
    if (!isModal) {
      dispatch(resetCurrentTrigger());
      dispatch(fetchAttributesFieldsRequest(INITIAL_ATTRIBUTES_VALUES));
    }
  }, [isModal]);

  return {
    methods: {
      control,
      closeModal,
      resetTrigger,
      reset,
      resetField,
      handleSubmit,
      onAddAttributeHandler,
      onAddCustomConditionHandler,
      onAddTriggerConditionHandler,
      onDeleteAttributeHandler,
      onDeleteCustomConditionHandler,
      onDeleteTriggerConditionHandler,
      onChangeAttribute,
      onChangeCustomField,
      onChangeCustomCondition,
      onChangeRequiredAttributeHandler,
      onChangeAttributeValue,
      onChangeCustomConditionValue,
      onChangeTriggerConditionValue,
      onChangeTriggerCondition,
      setAttributes,
      formSubmitHandler,
      onCloseAlert,
      onChangeListCustomField,
    },
    state: {
      attributes,
      customConditions,
      triggerConditions,
      errors,
      isDisabledSubmit,
      isDisabledTargetStatus,
      isDisabledAttribute,
      isDisabledCustomCondition,
      isDisabledTriggerCondition,
      attributesFields,
      attributesOptions,
      customFieldsOptions,
      triggerConditionOptions,
      titleInputOptions,
      nextTicketStatusOptions,
      alertIsOpen,
    },
  };
};
