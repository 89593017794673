import { FC } from 'react';

import { entityNames, PenIcon, TableContainer } from '@shared';

import { useAccessesTableContainer } from '../../lib';
import { AccessEdit } from '../AccessEdit';
import { AccessesFullTable } from '../AccessesFullTable';

import styles from './AccessesTableContainer.module.scss';

export const AccessesTableContainer: FC = () => {
  const { methods, state } = useAccessesTableContainer();

  const {
    toggleFilterRow,
    handleSort,
    handleAddAccess,
    handleChangePage,
    handleChangePageSize,
  } = methods;

  const {
    accesses,
    sortAccesses,
    totalElements,
    pageSize,
    pageNum,
    modalCreateOpen,
    showFilterRow,
    searchDisabled,
    isFilterActive,
    currentResponsibilityId,
    loadingAccesses,
  } = state;

  const getContent = () => (
    <>
      <AccessesFullTable
        access={accesses}
        showFilterRow={showFilterRow}
        handleSort={handleSort}
        sort={sortAccesses}
        isFilterActive={isFilterActive}
      />
      {modalCreateOpen && (
        <AccessEdit isModal={modalCreateOpen} toggleModal={handleAddAccess} />
      )}
    </>
  );

  return (
    <TableContainer
      classNameRoot={styles.accessesTableContainer}
      headerProps={{
        title: 'Категории доступа',
        createTitle: 'Изменить',
        createHandler: handleAddAccess,
        icon: <PenIcon />,
        createDisabled: !currentResponsibilityId,
        searchDisabled,
        isSearchOpen: showFilterRow,
        toggleSearch: toggleFilterRow,
        loading: loadingAccesses,
      }}
      contentTable={getContent()}
      countRecordsProps={{
        records: totalElements,
      }}
      paginationProps={{
        elementsCount: totalElements,
        handleChangePage,
        handleChangePageSize,
        pageSize,
        pageNum,
        marginPagesDisplayed: 0,
        disabled: loadingAccesses,
        entityName: entityNames.ACCESSES,
      }}
    />
  );
};
