import { FC } from 'react';

import { Toast } from '@shared';

import { DEFAULT_TEXT_TICKET_TOAST } from '../../config';
import { useTicketToast } from '../../lib';

export const TicketToast: FC = () => {
  const {
    isSuccess,
    toastMessage,
    handleCloseSuccessToast,
    isError,
    handleCloseErrorToast,
  } = useTicketToast();

  if (isSuccess) {
    return (
      <Toast
        title={toastMessage || DEFAULT_TEXT_TICKET_TOAST.success}
        onClose={handleCloseSuccessToast}
      />
    );
  }
  if (isError) {
    return (
      <Toast
        type="error"
        title={toastMessage || DEFAULT_TEXT_TICKET_TOAST.error}
        onClose={handleCloseErrorToast}
      />
    );
  }
  return null;
};
