import { useDispatch } from 'react-redux';

import {
  ArticlesMainState,
  fetchArticlesMainRequest,
  FetchArticlesParams,
  getArticlesMain,
  getLoadingArticlesMain,
  getPaginationArticlesMain,
  getSelectedOrganizationArticlesMain,
  KBArticleTableFilterToRequest,
  resetArticlesStateArticlesMain,
  setArticlesMainFilter,
} from '@entities/knowledge-base';
import {
  ActionsProps,
  KBArticlesSearch,
  SelectorsProps,
} from '@features/knowledge-base';
import { BookIcon, Card, Typography, TypographyVariants } from '@shared';

import styles from './KBMainWelcome.module.scss';

export const KBMainWelcome = () => {
  const dispatch = useDispatch();

  const resetState = () => {
    dispatch(resetArticlesStateArticlesMain());
  };

  const fetch = (params: FetchArticlesParams) => {
    dispatch(fetchArticlesMainRequest(params));
  };

  const setFilter = (filter: KBArticleTableFilterToRequest) => {
    dispatch(setArticlesMainFilter(filter));
  };

  const selectorsProps: SelectorsProps<ArticlesMainState> = {
    getList: getArticlesMain,
    getLoading: getLoadingArticlesMain,
    getOrganizationsSelected: getSelectedOrganizationArticlesMain,
    getPaginationArticles: getPaginationArticlesMain,
  };

  const actionsProps: ActionsProps = {
    resetState,
    fetch,
    setFilter,
  };

  return (
    <Card className={styles.KBMainWelcome}>
      <div className={styles.KBMainWelcome__wrapper}>
        <BookIcon className={styles.KBMainWelcome__bookIcon} />
        <Typography
          variant={TypographyVariants.h2}
          className={styles.KBMainWelcome__title}
        >
          Добро пожаловать
        </Typography>
        <Typography
          variant={TypographyVariants.b3}
          className={styles.KBMainWelcome__subTitle}
        >
          Найдите интересующую вас информацию в одной из категорий или
          воспользуйтесь поиском
        </Typography>
        <KBArticlesSearch
          selectorsProps={selectorsProps}
          actionsProps={actionsProps}
        />
      </div>
    </Card>
  );
};
