import { Note } from '../attachment';

import { CreateNoteData } from './types';

export enum Notes {
  NOTE_CREATE_REQUEST = 'TICKET/NOTES/NOTE_CREATE_REQUEST',
  NOTE_UPDATE_REQUEST = 'TICKET/NOTES/NOTE_UPDATE_REQUEST',
  NOTE_DELETE_REQUEST = 'TICKET/NOTES/NOTE_DELETE_REQUEST',
  NOTE_LOADING_SHOW = 'TICKET/NOTES/NOTE_LOADING_SHOW',
  NOTE_LOADING_HIDE = 'TICKET/NOTES/NOTE_LOADING_HIDE',
  FETCH_NOTES_BY_TICKET_ID_REQUEST = 'TICKET/NOTES/FETCH_NOTES_BY_TICKET_ID_REQUEST',
  FETCH_NOTES_BY_TICKET_ID_SUCCESS = 'TICKET/NOTES/FETCH_NOTES_BY_TICKET_ID_SUCCESS',
  RESET_NOTES_STATE = 'TICKET/NOTES/RESET_NOTES_STATE',
}

export interface NoteCreateRequestAction {
  type: Notes.NOTE_CREATE_REQUEST;
  payload: CreateNoteData;
}

export interface UpdateNoteRequestAction {
  type: Notes.NOTE_UPDATE_REQUEST;
  payload: Note;
}

export interface DeleteNoteRequestAction {
  type: Notes.NOTE_DELETE_REQUEST;
  payload: string;
}

export interface NoteLoadingShowAction {
  type: Notes.NOTE_LOADING_SHOW;
}

export interface NoteLoadingHideAction {
  type: Notes.NOTE_LOADING_HIDE;
}

export interface FetchNotesByTicketIdRequestAction {
  type: Notes.FETCH_NOTES_BY_TICKET_ID_REQUEST;
  payload: string;
}

export interface FetchNotesByTicketIdSuccessAction {
  type: Notes.FETCH_NOTES_BY_TICKET_ID_SUCCESS;
  payload: Array<Note>;
}

export interface ResetNotesStateAction {
  type: Notes.RESET_NOTES_STATE;
}

export type NotesActionsTypes =
  | NoteLoadingShowAction
  | NoteLoadingHideAction
  | FetchNotesByTicketIdSuccessAction
  | ResetNotesStateAction;
