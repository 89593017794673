import { OrganizationItem } from '../../../../entities/desktop/model/types';

export const getCurrentOrganization = (
  organizationList?: Array<OrganizationItem>
) =>
  organizationList
    ?.map((el) => ({
      name: el.key,
      y: el.value,
    }))
    .filter((data) => data.y);
