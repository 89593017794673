import cn from 'clsx';
import { FC, InputHTMLAttributes, memo, ReactNode } from 'react';

import { CircleIcon } from '../../../../assets';
import { Typography, TypographyVariants } from '../../../Typography';

import styles from './RadioItem.module.scss';

interface RadioItemProps extends InputHTMLAttributes<HTMLInputElement> {
  title?: string;
  icon?: ReactNode;
}

export const RadioItem: FC<RadioItemProps> = memo(
  ({ title, disabled, icon, ...other }) => (
    <label
      className={cn(styles.radioItem, {
        [styles.radioItem_disabled]: disabled,
      })}
    >
      <input
        type="radio"
        disabled={disabled}
        className={styles.radioItem__input}
        {...other}
      />
      <CircleIcon className={styles.radioItem__icon} />
      {title && (
        <Typography
          variant={TypographyVariants.b3}
          className={styles.radioItem__title}
        >
          {title}
        </Typography>
      )}
      {icon}
    </label>
  )
);
