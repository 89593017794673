import DOMPurify from 'dompurify';
import { FC } from 'react';

import { Article } from '@entities/knowledge-base';
import { Card } from '@shared';

import { KBArticleViewingSearch } from '../KBArticleViewingSearch';

import styles from './KBArticle.module.scss';

interface KBArticleProps {
  article?: Article;
}

export const KBArticle: FC<KBArticleProps> = ({ article }) => (
  <div className={styles.KBArticle__wrapper}>
    <Card className={styles.KBArticle}>
      <div
        className="ck-content"
        // eslint-disable-next-line react/no-danger
        dangerouslySetInnerHTML={{
          __html: DOMPurify.sanitize(article?.text || ''),
        }}
      />
    </Card>
    <KBArticleViewingSearch className={styles.KBArticle__search} />
  </div>
);
