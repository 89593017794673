import cn from 'clsx';
import { FC } from 'react';
import { useSelector } from 'react-redux';

import { ActionList, getIsAccessedAction } from '@entities/auth';
import {
  getLoadingJiraIntegrationInfo,
  getLoadingJiraIntegrations,
  JiraIntegration,
  TableJiraIntegrationsTypes,
} from '@entities/jira-integrations';
import { NotificationLink, Table, UserIcon } from '@shared';

import { TABLE_HEADER_FULL_FIELDS } from '../../config';
import { JiraIntegrationsFilter } from '../JiraIntegrationsFilter';

import styles from './JiraIntegrationsFullTable.module.scss';

const getDataTable = (
  jiraIntegrations: JiraIntegration[],
  isAccessToViewingCompany: boolean
) =>
  jiraIntegrations?.map((jiraIntegration) => {
    const {
      id,
      name,
      isActive,
      jiraUrl,
      systemTitle,
      organizationId,
      organizationTitle,
      login,
      projectKey,
    } = jiraIntegration;
    return {
      id,
      name,
      status: (
        <div className={styles.jiraIntegrationsFullTable__cell}>
          <div
            className={cn(
              styles.jiraIntegrationsFullTable__cellItem,
              styles.jiraIntegrationsFullTable__round,
              { [styles.jiraIntegrationsFullTable__round_active]: isActive }
            )}
          />
          {isActive ? 'Активно' : 'Не активно'}
        </div>
      ),
      jiraUrl,
      system: { text: systemTitle },
      organization: isAccessToViewingCompany ? (
        <NotificationLink
          to={`/admin/organizations/${organizationId}`}
          title={organizationTitle}
        />
      ) : (
        organizationTitle
      ),
      login: (
        <div className={styles.jiraIntegrationsFullTable__cell}>
          <UserIcon
            className={styles.jiraIntegrationsFullTable__cellItem}
            style={{ color: 'var(--grey80)', width: 20, height: 20 }}
          />
          {login}
        </div>
      ),
      projectKey,
    };
  }) || [];

interface JiraIntegrationsFullTableProps {
  jiraIntegrations?: JiraIntegration[];
  sort?: string;
  handleSort?: (value: string) => void;
  handleClickRow?: (value: string) => void;
  activeRowId?: string;
  showFilterRow?: boolean;
  isFilterActive?: boolean;
}

export const JiraIntegrationsFullTable: FC<JiraIntegrationsFullTableProps> = ({
  jiraIntegrations = [],
  sort,
  handleSort,
  handleClickRow,
  activeRowId,
  showFilterRow,
  isFilterActive,
}) => {
  const isAccessToViewingCompany = useSelector(
    getIsAccessedAction(ActionList.ViewingCompanyAdministration)
  );
  const loading = useSelector(getLoadingJiraIntegrations);
  const loadingInfo = useSelector(getLoadingJiraIntegrationInfo);

  const data = getDataTable(jiraIntegrations, isAccessToViewingCompany);

  return (
    <Table
      data={data}
      columns={TABLE_HEADER_FULL_FIELDS}
      sort={sort}
      onRowClick={handleClickRow}
      onSort={handleSort}
      activeRowId={activeRowId}
      dataLoading={loading}
      isFilterActive={isFilterActive}
      valueFieldName="id"
      filterComponent={
        showFilterRow && (
          <JiraIntegrationsFilter tableType={TableJiraIntegrationsTypes.FULL} />
        )
      }
      disabledSort={loading}
      disabledRows={loadingInfo}
    />
  );
};
