import { useEffect } from 'react';
import { useForm } from 'react-hook-form';

import { Article, ArticleCreateFormData } from '@entities/knowledge-base';
import {
  AccessTypeMap,
  DEFAULT_CREATE_ARTICLE_FORM_VALUES,
} from '@features/knowledge-base';
import { createSelectValue, getArraySelectOptions } from '@shared';

interface UseCreateArticleFormProps {
  article?: Article;
  isEditMode?: boolean;
}

export const useCreateArticleForm = ({
  article,
  isEditMode,
}: UseCreateArticleFormProps) => {
  const {
    handleSubmit,
    register,
    reset,
    control,
    watch,
    setValue,
    resetField,
    formState: { isValid, isDirty },
  } = useForm<ArticleCreateFormData>({
    mode: 'onChange',
    defaultValues: DEFAULT_CREATE_ARTICLE_FORM_VALUES,
  });

  const titleInputOptions = register('title', {
    required: true,
    maxLength: {
      value: 100,
      message: 'Название статьи не может быть длиннее 100 символов.',
    },
  });

  useEffect(() => {
    if (article && isEditMode) {
      const category = createSelectValue(
        article.categoryTitle,
        article.categoryId
      );
      const parentCategory = createSelectValue(
        article.parentCategoryTitle,
        article.parentCategoryId
      );

      setValue('accessType', AccessTypeMap[article?.accessType]);
      setValue('title', article.title);
      setValue('organizationIds', getArraySelectOptions(article.organizations));
      setValue('workgroupIds', getArraySelectOptions(article.workgroups));
      setValue('parentCategory', parentCategory || category);
      setValue('category', parentCategory ? category : null);
    }
  }, [article]);

  return {
    methods: {
      watch,
      reset,
      setValue,
      handleSubmit,
      resetField,
    },
    state: {
      control,
      text: article?.text,
      id: article?.id,
      titleInputOptions,
      isEditMode,
      isValid,
      isDirty,
    },
  };
};
