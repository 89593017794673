import { FC } from 'react';

import {
  PopoverButton,
  PopoverContainer,
  PopoverTitle,
  TicketNavIcon,
} from '@shared';

interface TicketCommentsDropContentProps {
  togglePopover(): void;
  toggleIsTicketsIds(): void;
  isTicketsIds: boolean;
}

export const TicketCommentsDropContent: FC<TicketCommentsDropContentProps> = ({
  togglePopover,
  toggleIsTicketsIds,
  isTicketsIds,
}) => {
  const addTickets = () => {
    if (!isTicketsIds) {
      toggleIsTicketsIds();
      togglePopover();
    }
  };

  return (
    <PopoverContainer>
      <PopoverTitle title="Дополнительно" />
      <PopoverButton
        onClick={addTickets}
        icon={<TicketNavIcon style={{ color: 'var(--blue120)' }} />}
      >
        Указать тикет
      </PopoverButton>
    </PopoverContainer>
  );
};
