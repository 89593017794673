import { Attachment, AttachmentsFiles } from '@entities/attachment/model/types';

import {
  AddMessageAttachmentsPayload,
  AttachmentsRequest,
  DeleteFileAttachmentsTicketRequest,
} from './types';

export enum Attachments {
  ATTACHMENTS_LOADING_SHOW = 'TICKET/ATTACHMENTS/ATTACHMENTS_LOADING_SHOW',
  ATTACHMENTS_LOADING_HIDE = 'TICKET/ATTACHMENTS/ATTACHMENTS_LOADING_HIDE',
  ADD_MESSAGE_ATTACHMENT_REQUEST = 'TICKET/ATTACHMENTS/ADD_MESSAGE_ATTACHMENT_REQUEST',
  ADD_ATTACHMENTS_REQUEST = 'TICKET/ATTACHMENTS/ADD_ATTACHMENTS_REQUEST',
  FETCH_TICKET_ATTACHMENTS_REQUEST = 'TICKET/ATTACHMENTS/FETCH_TICKET_ATTACHMENTS_REQUEST',
  FETCH_TICKET_ATTACHMENTS_SUCCESS = 'TICKET/ATTACHMENTS/FETCH_TICKET_ATTACHMENTS_SUCCESS',
  FETCH_CONTRACT_ATTACHMENTS_REQUEST = 'TICKET/ATTACHMENTS/FETCH_CONTRACT_ATTACHMENTS_REQUEST',
  FETCH_CONTRACT_ATTACHMENTS_SUCCESS = 'TICKET/ATTACHMENTS/FETCH_CONTRACT_ATTACHMENTS_SUCCESS',
  FETCH_TICKET_COMMENTS_ATTACHMENTS_REQUEST = 'TICKET/ATTACHMENTS/FETCH_TICKET_COMMENTS_ATTACHMENTS_REQUEST',
  FETCH_TICKET_COMMENTS_ATTACHMENTS_SUCCESS = 'TICKET/ATTACHMENTS/FETCH_TICKET_COMMENTS_ATTACHMENTS_SUCCESS',
  RESET_ATTACHMENTS_STATE = 'TICKET/ATTACHMENTS/RESET_ATTACHMENTS_STATE',
  DELETE_FILE_ATTACHMENT_TICKET_REQUEST = 'TICKET/DELETE_FILE_ATTACHMENT_TICKET_REQUEST',
}

export interface ShowAttachmentsLoadingAction {
  type: Attachments.ATTACHMENTS_LOADING_SHOW;
}

export interface HideAttachmentsLoadingAction {
  type: Attachments.ATTACHMENTS_LOADING_HIDE;
}

export interface FetchTicketAttachmentsRequestAction {
  type: Attachments.FETCH_TICKET_ATTACHMENTS_REQUEST;
  payload: string;
}

export interface FetchTicketAttachmentsSuccessAction {
  type: Attachments.FETCH_TICKET_ATTACHMENTS_SUCCESS;
  payload: Attachment[];
}

export interface FetchContractAttachmentsRequestAction {
  type: Attachments.FETCH_CONTRACT_ATTACHMENTS_REQUEST;
}

export interface FetchContractAttachmentsSuccessAction {
  type: Attachments.FETCH_CONTRACT_ATTACHMENTS_SUCCESS;
  payload: Attachment[];
}

export interface AddMessageAttachmentsAction {
  type: Attachments.ADD_MESSAGE_ATTACHMENT_REQUEST;
  payload: AddMessageAttachmentsPayload;
}

export interface FetchTicketCommentsAttachmentsRequestAction {
  type: Attachments.FETCH_TICKET_COMMENTS_ATTACHMENTS_REQUEST;
  payload: string;
}

export interface FetchTicketCommentsAttachmentsSuccessAction {
  type: Attachments.FETCH_TICKET_COMMENTS_ATTACHMENTS_SUCCESS;
  payload: AttachmentsFiles[];
}

export interface AddAttachmentsRequestAction {
  type: Attachments.ADD_ATTACHMENTS_REQUEST;
  payload: AttachmentsRequest;
}

export interface ResetAttachmentsStateAction {
  type: Attachments.RESET_ATTACHMENTS_STATE;
}

export interface DeleteFileAttachmentsTicketRequestAction {
  type: Attachments.DELETE_FILE_ATTACHMENT_TICKET_REQUEST;
  payload: DeleteFileAttachmentsTicketRequest;
}

export type AttachmentsActionsTypes =
  | FetchTicketAttachmentsSuccessAction
  | FetchTicketCommentsAttachmentsSuccessAction
  | FetchContractAttachmentsSuccessAction
  | ResetAttachmentsStateAction;
