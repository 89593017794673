import { FC } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
  getLoading,
  Role,
  RolesFilter as RolesFilterData,
  setCurrentRolesPage,
  setRolesFilter,
  TableRolesTypes,
} from '@entities/roles';
import { Table } from '@shared';

import { ROLES_FROM_USERS_FIELDS } from '../../config/constants';
import { getDataTable, getFetchFunction } from '../../lib';
import { RolesFilter } from '../RolesFilter';

interface RolesFromUsersTableProps {
  roles?: Role[];
  sort?: string;
  handleSort?: (value: string) => void;
  handleRowClick?: (value: string) => void;
  activeRowId?: string;
  showFilterRow?: boolean;
  isFilterActive?: boolean;
}

export const RolesFromUsersTable: FC<RolesFromUsersTableProps> = ({
  roles,
  sort,
  handleSort,
  handleRowClick,
  activeRowId,
  showFilterRow,
  isFilterActive,
}) => {
  const dispatch = useDispatch();

  const loading = useSelector(getLoading);

  const data = getDataTable(roles);

  const handleFilter = (values: RolesFilterData) => {
    dispatch(setRolesFilter(values));
    dispatch(setCurrentRolesPage(0));
    dispatch(getFetchFunction(TableRolesTypes.FROM_USERS)());
  };

  return (
    <Table
      data={data}
      columns={ROLES_FROM_USERS_FIELDS}
      sort={sort}
      onRowClick={handleRowClick}
      onSort={handleSort}
      activeRowId={activeRowId}
      dataLoading={loading}
      isFilterActive={isFilterActive}
      valueFieldName="id"
      filterComponent={
        showFilterRow && (
          <RolesFilter
            tableType={TableRolesTypes.FROM_USERS}
            onFilter={handleFilter}
          />
        )
      }
      disabledSort={loading}
    />
  );
};
