import cn from 'clsx';
import { FC, useEffect } from 'react';

import { ButtonType } from '@shared/model';

import { CloseIcon } from '../../assets';
import { Button } from '../Button';
import { IconButtonWrapper } from '../IconButtonWrapper';
import { Portal } from '../Portal';

import styles from './Info.module.scss';

export interface InfoProps {
  toggleModal(): void;
  isModal: boolean;
  title?: string;
  className?: string;
  classNameTitle?: string;
  cancelTitle?: string;
  buttonType?: ButtonType;
  children?: JSX.Element;
}

export const Info: FC<InfoProps> = ({
  children,
  toggleModal,
  isModal,
  title,
  className,
  classNameTitle,
  cancelTitle = 'Закрыть',
  buttonType = 'flat',
}) => {
  useEffect(() => {
    if (isModal) {
      document.body.style.overflow = 'hidden';
      return;
    }
    document.body.style.overflow = 'visible';
  }, [isModal]);

  useEffect(
    () => () => {
      document.body.style.overflow = 'visible';
    },
    []
  );

  if (!isModal) {
    return null;
  }

  return (
    <Portal>
      <div className={styles.info} aria-hidden="true" onMouseDown={toggleModal}>
        <div
          className={cn(styles.info__content, className)}
          onMouseDown={(e) => e.stopPropagation()}
          aria-hidden="true"
        >
          {title && (
            <div className={styles.info__header}>
              <h4 className={cn(styles.info__title, classNameTitle)}>
                {title}
              </h4>
              <IconButtonWrapper
                onClick={toggleModal}
                icon={<CloseIcon className={styles.info__cross} />}
                className={cn(styles.info__button, styles.info__button_right)}
              />
            </div>
          )}
          <form className={styles.info__formList}>{children}</form>
          <div className={cn(styles.info__buttonsBlock)}>
            <Button
              appearance={buttonType}
              onClick={toggleModal}
              type="button"
              className={styles.info__cancelButton}
            >
              {cancelTitle}
            </Button>
          </div>
        </div>
      </div>
    </Portal>
  );
};
