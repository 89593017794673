import cn from 'clsx';
import { FC } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { getLoading as getRolesLoading } from '@entities/roles';
import {
  TableUsersTypes,
  User,
  UsersFilter as UsersFilterData,
} from '@entities/user';
import {
  fetchUsersByGroupId,
  getLoading,
  setUsersFilter,
  setUsersPage,
  UsersFilter,
} from '@entities/users';
import {
  MailIcon,
  NotificationLink,
  PhoneIcon,
  Table,
  UserIcon,
} from '@shared';

import { TABLE_HEADER_FROM_GROUPS_FIELD } from '../../config';

import styles from './UsersFromGroupsTable.module.scss';

const getDataTable = (users?: User[]) =>
  users?.map((user) => {
    const { login, lastName, firstName, id, middleName, email, phoneNumber } =
      user;
    return {
      id,
      login: {
        icon: (
          <UserIcon
            className={cn(
              styles.usersFromGroupsTable__icon,
              styles.usersFromGroupsTable__icon_blue
            )}
          />
        ),
        children: <NotificationLink to={`/admin/users/${id}`} title={login} />,
        to: `/admin/users/${id}`,
      },
      firstName,
      lastName,
      middleName,
      email: {
        text: email,
        icon: <MailIcon className={styles.usersFromGroupsTable__icon} />,
      },
      phoneNumber: {
        text: phoneNumber,
        icon: <PhoneIcon className={styles.usersFromGroupsTable__icon} />,
      },
    };
  }) || [];

interface UsersFromGroupsTableProps {
  users?: User[];
  sort?: string;
  handleSort?: (value: string) => void;
  handleRowClick?: (value: string) => void;
  activeRowId?: string;
  showFilterRow?: boolean;
  isFilterActive?: boolean;
}

export const UsersFromGroupsTable: FC<UsersFromGroupsTableProps> = ({
  users,
  handleRowClick,
  sort,
  handleSort,
  activeRowId,
  showFilterRow,
  isFilterActive,
}) => {
  const dispatch = useDispatch();

  const data = getDataTable(users);

  const handleFilter = (values: UsersFilterData) => {
    dispatch(setUsersFilter(values));
    dispatch(setUsersPage(0));
    dispatch(fetchUsersByGroupId());
  };

  const loading = useSelector(getLoading);
  const rolesLoading = useSelector(getRolesLoading);

  return (
    <Table
      data={data}
      columns={TABLE_HEADER_FROM_GROUPS_FIELD}
      onRowClick={handleRowClick}
      onSort={handleSort}
      sort={sort}
      activeRowId={activeRowId}
      dataLoading={loading}
      isFilterActive={isFilterActive}
      valueFieldName="id"
      filterComponent={
        showFilterRow && (
          <UsersFilter
            tableType={TableUsersTypes.FROM_GROUPS}
            onFilter={handleFilter}
          />
        )
      }
      disabledSort={loading}
      disabledRows={rolesLoading}
    />
  );
};
