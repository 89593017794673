import { createSelector } from 'reselect';

import { Article } from '@entities/knowledge-base/model/types';

import { UserAssistanceState } from './types';

export const getIsModal = (state: UserAssistanceState) =>
  state.userAssistance.isModal;
export const getArticles = (state: UserAssistanceState) =>
  state.userAssistance.articles;
export const getArticlesLoading = (state: UserAssistanceState) =>
  state.userAssistance.articlesLoading;
export const getArticle = (state: UserAssistanceState) =>
  state.userAssistance.article;
export const getArticlesAll = (state: UserAssistanceState) =>
  state.userAssistance.articlesAll;
export const getArticlesAllLoading = (state: UserAssistanceState) =>
  state.userAssistance.articlesAllLoading;
export const getArticlesAllUniq = createSelector<
  UserAssistanceState,
  Article[],
  Article[],
  Article[]
>([getArticles, getArticlesAll], (articles, articlesAll) =>
  articlesAll?.filter(
    (articleAll) => !articles?.some((article) => articleAll.id === article.id)
  )
);
export const getSelectedArticlesIds = (state: UserAssistanceState) =>
  state.userAssistance.selectedArticlesIds;
export const getArticleLoading = (state: UserAssistanceState) =>
  state.userAssistance.articleLoading;
