import { ResponseWithMeta, UpdateContentType } from '@shared';

import {
  CheckIsExistRequestAction,
  CheckIsExistSuccessAction,
  CreateCustomFieldAction,
  CustomFields,
  DeleteCustomFieldRequestAction,
  FetchCustomFieldRequestAction,
  FetchCustomFieldsRequestAction,
  FetchCustomFieldsSuccessAction,
  HideCustomFieldsLoadingAction,
  ResetCurrentCustomFieldAction,
  ResetCustomFieldStateAction,
  SetCurrentCustomFieldIdAction,
  SetCurrentCustomFieldsPageAction,
  SetCurrentCustomFieldSuccessAction,
  SetSizePageAction,
  SetSortCustomFieldsAction,
  ShowCustomFieldsLoadingAction,
} from './actionTypes';
import {
  CreateCustomFieldData,
  CustomField,
  CustomFieldsFilter,
  DeleteCustomFieldTypeAction,
} from './types';

export const showCustomFieldsLoading = (): ShowCustomFieldsLoadingAction => ({
  type: CustomFields.CUSTOM_FIELDS_LOADING_SHOW,
});

export const hideCustomFieldsLoading = (): HideCustomFieldsLoadingAction => ({
  type: CustomFields.CUSTOM_FIELDS_LOADING_HIDE,
});

export const updateCustomField = (data: CustomField) => ({
  type: CustomFields.UPDATE_CUSTOM_FIELD,
  payload: data,
});

export const fetchCustomFieldsSuccess = (
  data: ResponseWithMeta<CustomField[]>
): FetchCustomFieldsSuccessAction => ({
  type: CustomFields.FETCH_CUSTOM_FIELDS_SUCCESS,
  payload: data,
});

export const setCurrentCustomFieldsPage = (
  page: number
): SetCurrentCustomFieldsPageAction => ({
  type: CustomFields.SET_CURRENT_CUSTOM_FIELDS_PAGE,
  payload: page,
});

export const setSizePage = (size: number): SetSizePageAction => ({
  type: CustomFields.SET_SIZE_PAGE_CUSTOM_FIELDS,
  payload: size,
});

export const setSortCustomFields = (
  sort: string
): SetSortCustomFieldsAction => ({
  type: CustomFields.SET_SORT_CUSTOM_FIELDS,
  payload: sort,
});

export const fetchCurrentCustomField = (
  id: string
): FetchCustomFieldRequestAction => ({
  type: CustomFields.FETCH_CUSTOM_FIELD_REQUEST,
  payload: id,
});

export const setCurrentCustomFieldSuccess = (
  data?: CustomField
): SetCurrentCustomFieldSuccessAction => ({
  type: CustomFields.SET_CURRENT_CUSTOM_FIELD_SUCCESS,
  payload: data,
});

export const createCustomField = (
  data: CreateCustomFieldData
): CreateCustomFieldAction => ({
  type: CustomFields.CREATE_CUSTOM_FIELD,
  payload: data,
});

export const resetCustomFieldState = (): ResetCustomFieldStateAction => ({
  type: CustomFields.RESET_CUSTOM_FIELD_STATE,
});

export const setCurrentCustomFieldId = (
  id?: string
): SetCurrentCustomFieldIdAction => ({
  type: CustomFields.SET_CURRENT_CUSTOM_FIELD_ID,
  payload: id,
});

export const setCustomFieldsFilter = (data: CustomFieldsFilter) => ({
  type: CustomFields.SET_CUSTOM_FIELDS_FILTER,
  payload: data,
});

export const fetchCustomFieldsRequest = (
  joinData?: UpdateContentType
): FetchCustomFieldsRequestAction => ({
  type: CustomFields.FETCH_CUSTOM_FIELDS_REQUEST,
  payload: joinData,
});

export const deleteCustomFieldRequest = (
  params: DeleteCustomFieldTypeAction
): DeleteCustomFieldRequestAction => ({
  type: CustomFields.DELETE_CUSTOM_FIELD_REQUEST,
  payload: params,
});

export const checkIsExistRequest = (
  title: string
): CheckIsExistRequestAction => ({
  type: CustomFields.CHECK_IS_EXIST_REQUEST,
  payload: title,
});

export const checkIsExistSuccess = (
  isExist: boolean
): CheckIsExistSuccessAction => ({
  type: CustomFields.CHECK_IS_EXIST_SUCCESS,
  payload: isExist,
});

export const resetCurrentCustomField = (): ResetCurrentCustomFieldAction => ({
  type: CustomFields.RESET_CURRENT_CUSTOM_FIELD,
});
