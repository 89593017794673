import { useEffect, useState } from 'react';

import { Attachment } from '@entities/attachment/model/types';

export const useCurrentAttachment = (
  ticketAttachments: Attachment[] | undefined
) => {
  const [attachment, setAttachment] = useState<Attachment>();

  useEffect(() => {
    if (ticketAttachments) {
      setAttachment(ticketAttachments[0]);
    }
  }, [ticketAttachments]);

  return {
    attachment,
  };
};
