import { FC } from 'react';

import { ApproveOrCancel, DeleteByConditionModal } from '@shared';

interface StorageDeleteModalProps {
  isEmpty?: boolean;
  isOpenModal: boolean;
  toggleModal(): void;
  handleDelete(): void;
  condition?: string;
}

export const StorageDeleteModal: FC<StorageDeleteModalProps> = ({
  isEmpty,
  isOpenModal,
  toggleModal,
  handleDelete,
  condition,
}) =>
  isEmpty ? (
    <ApproveOrCancel
      onApprove={handleDelete}
      isModal={isOpenModal}
      toggleModal={toggleModal}
      text="Вы уверены, что хотите удалить хранилище?"
    />
  ) : (
    <DeleteByConditionModal
      onDelete={handleDelete}
      isModal={isOpenModal}
      toggleModal={toggleModal}
      title="Удалить"
      description="Пожалуйста, введите название хранилища для подтверждения."
      condition={condition}
      label="Имя хранилища"
    />
  );
