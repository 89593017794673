import { debounce } from 'lodash';
import { KeyboardEvent, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';

import {
  fetchTicketsRequest,
  getPropsTickets,
  getTicketsSelectList,
  resetCreateTicketState,
  resetTicketsState,
  setCurrentPageTickets,
  setFilterTickets,
} from '@entities/create-ticket';
import {
  CommentType,
  getTicketDeleted,
  MessageData,
  useTicketId,
} from '@entities/ticket';
import {
  DEFAULT_DEBOUNCE_DELAY,
  getIsMobile,
  getIsMobileSmall,
  KeyValueOption,
  SelectOption,
  WsStatus,
} from '@shared';

import { useTicketCommentsWebSocket } from './useTicketCommentsWebSocket';

type UseTicketCommentsFormProps = {
  status: WsStatus;
};

export const useTicketCommentsForm = ({
  status,
}: UseTicketCommentsFormProps) => {
  const isTicketDeleted = useSelector(getTicketDeleted);
  const isMobile = useSelector(getIsMobile);
  const isMobileSmall = useSelector(getIsMobileSmall);
  const ticketsOptions = useSelector(getTicketsSelectList);
  const ticketsProps = useSelector(getPropsTickets);
  const { pageNum, totalPagesTickets, loadingTickets } = ticketsProps;

  const [inputValue, setInputValue] = useState<string>('');
  const [isTicketsIds, setIsTicketsIds] = useState<boolean>(false);

  const dispatch = useDispatch();

  const isMobileAll = isMobile || isMobileSmall;

  const toggleIsTicketsIds = () => {
    setIsTicketsIds((prevState) => !prevState);
  };

  const {
    methods: { sendMessage },
  } = useTicketCommentsWebSocket();

  const ticketId = useTicketId();

  const {
    register,
    handleSubmit,
    reset,
    setValue,
    watch,
    control,
    resetField,
    formState: { isDirty, isValid },
  } = useForm<MessageData>({
    defaultValues: {
      message: '',
      commentType: CommentType.USUAL,
      ticketsIds: [],
    },
    mode: 'onChange',
  });

  const currentCommentType = watch('commentType');
  const currentMessage = watch('message');

  const messageInputOptions = register('message', {
    required: true,
    maxLength: {
      value: 5000,
      message: 'Текст сообщения не может быть пустым',
    },
  });

  const hideInputRow = () => {
    setIsTicketsIds(false);
    setInputValue('');
    resetField('ticketsIds');
    dispatch(resetTicketsState());
  };

  const formSubmitHandler = handleSubmit(
    ({ message, commentType, ticketsIds }) => {
      sendMessage(
        JSON.stringify({
          ticketId,
          type: commentType,
          message,
          mentionedTickets:
            ticketsIds && ticketsIds instanceof Array
              ? ticketsIds?.map(
                  (ticket: SelectOption): KeyValueOption => ({
                    key: ticket.value,
                    value: ticket.title,
                  })
                )
              : [],
        })
      );
      hideInputRow();
      reset();
    }
  );

  const handleKeyDown = (event: KeyboardEvent<HTMLTextAreaElement>) => {
    if (
      event.key === 'Enter' &&
      !event.shiftKey &&
      !event.ctrlKey &&
      !event.metaKey &&
      !isMobileAll &&
      status.open
    ) {
      event.preventDefault();
      formSubmitHandler();
    }
  };

  const onInputFilter = debounce((value: string) => {
    dispatch(setCurrentPageTickets(0));
    dispatch(setFilterTickets({ number: value }));
    dispatch(fetchTicketsRequest('update'));
  }, DEFAULT_DEBOUNCE_DELAY);

  const handleInput = (value: string) => {
    onInputFilter(value);
    setInputValue(value);
  };

  const setNextPage = (page: number) => {
    dispatch(setCurrentPageTickets(page));
    dispatch(fetchTicketsRequest('join'));
  };

  useEffect(
    () => () => {
      dispatch(resetCreateTicketState());
    },
    []
  );

  useEffect(() => {
    if (isTicketsIds) {
      dispatch(setCurrentPageTickets(0));
      dispatch(fetchTicketsRequest('update'));
    }
  }, [isTicketsIds]);

  return {
    state: {
      setValue,
      isDirty,
      isValid,
      currentCommentType,
      messageInputOptions,
      currentMessage,
      control,
      ticketsOptions,
      pageNum,
      totalPagesTickets,
      loadingTickets,
      inputValue,
      isTicketsIds,
      isTicketDeleted,
    },
    methods: {
      handleKeyDown,
      formSubmitHandler,
      toggleIsTicketsIds,
      handleInput,
      hideInputRow,
      setNextPage,
    },
  };
};
