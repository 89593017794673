import { ApiClient, getEnv, ResponseWithMeta } from '@shared';

import {
  CheckIsExistTagArgs,
  CreateTagData,
  TagsFilter,
} from '../../model/tags/types';
import { Tag } from '../../model/types';

import { tagsEndpoints as endpoints } from './endpoints';

export const tagsRequests = {
  fetchTags: (pageNum = 0, pageSize?: number, filter: TagsFilter = {}) =>
    ApiClient.post<ResponseWithMeta<Tag[]>>({
      baseURL: getEnv('REACT_APP_TICKET_URL'),
      url: endpoints.getTags(),
      params: { pageNum, pageSize },
      data: {
        ...filter,
      },
    }),
  createTag: (data: CreateTagData) =>
    ApiClient.post<Tag>({
      baseURL: getEnv('REACT_APP_TICKET_URL'),
      url: endpoints.createTag(),
      data,
    }),
  checkIsExistTag: (data: CheckIsExistTagArgs) =>
    ApiClient.get<boolean>({
      baseURL: getEnv('REACT_APP_TICKET_URL'),
      url: endpoints.getCheckIsExistTag(data),
      data: {},
    }),
};
