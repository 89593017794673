import { FC, useState } from 'react';

import { Article, CategoryArticle } from '@entities/knowledge-base';
import { Popover } from '@shared';

import {
  getListArticlesAndSubCategories,
  useIsAccessEditCategoryOrArticle,
} from '../../lib';
import { KBCategoryProps } from '../../model';
import { KBArticleTitle } from '../KBArticleTitle';
import { KBCategoryContainer } from '../KBCategoryContainer';
import { KBCategoryDrop } from '../KBCategoryDrop';

import styles from './KBCategory.module.scss';
import { useKBCategory } from './lib';
import { KBSubCategory } from './ui';

export const KBCategory: FC<KBCategoryProps> = ({
  category,
  classNameSubCategory,
  withDrop,
  dropProps,
  showFullCategory = false,
  articleDropProps,
  // eslint-disable-next-line sonarjs/cognitive-complexity
}) => {
  const { articles = [], children = [], organizations } = category;

  const { methods, state } = useKBCategory();
  const { togglePopover, onMouseEnter, onMouseLeave, toggleCategoryOpen } =
    methods;
  const { isMobileAll, isDropOpen, isMouseOnTitle, isCategoryOpen } = state;

  const [isDeleteCategoryModal, setIsDeleteCategoryModal] = useState(false);

  const toggleModal = () => {
    setIsDeleteCategoryModal((prevState) => !prevState);
  };

  const canEditCategory = useIsAccessEditCategoryOrArticle(organizations);

  const { articlesList, categoriesList } = getListArticlesAndSubCategories({
    showFullCategory,
    categories: children,
    articles,
  });

  const needDrop = !isMobileAll && withDrop && canEditCategory;
  const showPopover = needDrop && (isMouseOnTitle || isDropOpen);

  const articlesListComponent = articlesList?.map((article: Article) => (
    <KBArticleTitle
      article={article}
      key={article?.id}
      withTooltip
      withDrop
      dropProps={articleDropProps}
      className={styles.KBCategory__articlesItem}
    />
  ));

  const subCategoriesListComponent = categoriesList?.map(
    (subCategory: CategoryArticle) => (
      <KBSubCategory
        key={subCategory?.id}
        category={subCategory}
        showFullCategory={showFullCategory}
        articleDropProps={articleDropProps}
        classNameSubCategory={classNameSubCategory}
        withDrop={withDrop}
        dropProps={dropProps}
      />
    )
  );

  const categoryDrop = showPopover ? (
    <div
      onClick={(e) => {
        e.stopPropagation();
      }}
      role="button"
      tabIndex={0}
      onKeyDown={undefined}
      className={styles.KBCategory__popover}
    >
      <Popover
        content={
          <KBCategoryDrop
            {...dropProps}
            category={category}
            toggleModal={toggleModal}
          />
        }
        isOpen={isDropOpen}
        withOutsideClick
        togglePopover={togglePopover}
        positions={['right', 'left', 'top', 'bottom']}
        className={styles.KBCategory__popoverButton}
      />
    </div>
  ) : undefined;

  return (
    <KBCategoryContainer
      subCategoriesListComponent={subCategoriesListComponent}
      articlesListComponent={articlesListComponent}
      isMobileAll={isMobileAll}
      isCategoryOpen={isCategoryOpen}
      category={category}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      toggleCategoryOpen={toggleCategoryOpen}
      categoryDrop={categoryDrop}
      needDrop={needDrop}
      isDelete={isDeleteCategoryModal}
      toggleModal={toggleModal}
      dropProps={dropProps}
      showFullCategory={showFullCategory}
    />
  );
};
