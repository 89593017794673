import cn from 'clsx';
import { nanoid } from 'nanoid';
import { FC, MouseEvent, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useLocation } from 'react-router-dom';

import {
  fetchNotificationsProfileRequest,
  getFilterValues,
  getNotificationTabs,
  getProfileTabsUnchecked,
  setAllCheckedNotifications,
  setNotificationsProfileFilter,
} from '@entities/profile';
import {
  CheckMarkIcon,
  getScreenWidth,
  NotificationCircle,
  Popover,
  PopoverButton,
  PopoverContainer,
  PopoverItemWrapper,
  PopoverLine,
  PopoverTitle,
  RouterHref,
  ScreenWidth,
  ToggleSwitch,
} from '@shared';

import { convertTabTitleToPath } from '../../lib/utils/convertTabTitleToPath';
import { getTabsWithStatusesProfile } from '../../lib/utils/getTabsWithStatuses';
import { splitProfileTabsByGroup } from '../../lib/utils/splitProfileTabsByGroup';

import styles from './ProfileDrop.module.scss';

interface ProfileDropProps {
  withFilters?: boolean;
  withTabs?: boolean;
  className?: string;
}

export const ProfileDrop: FC<ProfileDropProps> = ({
  withFilters = false,
  withTabs = false,
  className,
}) => {
  const dispatch = useDispatch();

  const { t: filterT } = useTranslation('filter');

  const { pathname } = useLocation();

  const screenWidth = useSelector(getScreenWidth);
  const tabs = useSelector(getNotificationTabs);
  const tabsStatuses = useSelector(getProfileTabsUnchecked);
  const filterNotifications = useSelector(getFilterValues);

  const tabsWithStatuses = getTabsWithStatusesProfile(tabs, tabsStatuses);
  const tabsSplitByGroup = splitProfileTabsByGroup(tabsWithStatuses);

  const [isPopoverOpen, setIsPopoverOpen] = useState<boolean>(false);

  const togglePopover = () => {
    setIsPopoverOpen((prevState) => !prevState);
  };

  const handleShowOnlyUnread = () => {
    dispatch(
      setNotificationsProfileFilter({
        ...filterNotifications,
        notChecked: !filterNotifications?.notChecked,
      })
    );
    dispatch(
      fetchNotificationsProfileRequest({ updateType: 'update', pageNum: 0 })
    );
  };

  const handleSetAllChecked = (event?: MouseEvent) => {
    event?.preventDefault();
    dispatch(setAllCheckedNotifications());
  };

  const handleSwitch = (event?: MouseEvent) => {
    event?.stopPropagation();
    event?.preventDefault();
    handleShowOnlyUnread();
  };

  const isMobile = screenWidth === ScreenWidth.MOBILE;
  const isMobileSmall = screenWidth === ScreenWidth.MOBILE_SMALL;
  const isMobileAll = isMobile || isMobileSmall;

  const notificationsTopContent = withTabs ? (
    <>
      {!isMobileAll && <PopoverLine />}
      {Object.keys(tabsSplitByGroup).map((item) => (
        <div key={nanoid()} className={styles.profileDrop__tabGroup}>
          {item !== ' ' && !isMobileAll && <PopoverTitle title={item} />}
          {tabsSplitByGroup[item]?.map((elem) => {
            const to = `${
              RouterHref.ProfileNotifications
            }/${convertTabTitleToPath(elem?.titleNotification)}`;
            return (
              <PopoverItemWrapper
                className={styles.profileDrop__linkWrapper}
                key={nanoid()}
              >
                <Link
                  to={to}
                  onClick={togglePopover}
                  className={cn(styles.profileDrop__link, {
                    [styles.profileDrop__link_active]: pathname === to,
                  })}
                >
                  {elem?.description}
                  {elem?.hasUnchecked && <NotificationCircle />}
                </Link>
              </PopoverItemWrapper>
            );
          })}
        </div>
      ))}
    </>
  ) : undefined;

  const dropContent = (
    <PopoverContainer className={cn(styles.profileDrop, className)}>
      <PopoverTitle title="Действия" />
      <PopoverButton
        icon={<CheckMarkIcon className={styles.profileDrop__icon} />}
        onClick={handleSetAllChecked}
      >
        Отметить все как прочитанные
      </PopoverButton>
      {withFilters && (
        <>
          <PopoverLine />
          <PopoverTitle title={filterT('title')} />
          <PopoverItemWrapper className={styles.profileDrop__switchWrapper}>
            <ToggleSwitch
              label="Только непрочитанные"
              onChange={() => handleSwitch()}
              checked={!!filterNotifications?.notChecked}
            />
          </PopoverItemWrapper>
        </>
      )}
      {!isMobileAll && notificationsTopContent}
    </PopoverContainer>
  );

  return (
    <Popover
      content={dropContent}
      togglePopover={togglePopover}
      isOpen={isPopoverOpen}
      topModalContent={notificationsTopContent}
      classNameTopContent={styles.profileDrop__top}
      positions={['bottom', 'left']}
      align="end"
    />
  );
};
