import cn from 'clsx';
import { FC } from 'react';
import { useHistory } from 'react-router-dom';

import { DeleteApproveOrCancel, RollUpButton, RouterHref } from '@shared';

import { KBCategoryProps } from '../../model';
import { KBCategoryTitle } from '../KBCategoryTitle';

import styles from './KBCategoryContainer.module.scss';

type KBCategoryContainerProps = {
  isMobileAll: boolean;
  isCategoryOpen: boolean;
  subCategoriesListComponent?: JSX.Element[];
  articlesListComponent?: JSX.Element[];
  categoryDrop?: JSX.Element;
  needDrop?: boolean;
  isDelete: boolean;
  toggleModal: () => void;
  onMouseEnter: () => void;
  onMouseLeave: () => void;
  toggleCategoryOpen: () => void;
} & KBCategoryProps;

export const KBCategoryContainer: FC<KBCategoryContainerProps> = ({
  category,
  showFullCategory,
  isMobileAll,
  dropProps,
  classNameSubCategory,
  articlesListComponent,
  isCategoryOpen,
  onMouseEnter,
  onMouseLeave,
  toggleCategoryOpen,
  isSubCategory,
  subCategoriesListComponent,
  categoryDrop,
  needDrop,
  isDelete,
  toggleModal,
  // eslint-disable-next-line sonarjs/cognitive-complexity
}) => {
  const { push } = useHistory();

  const { id, title, articles = [], children = [] } = category;

  const showAllButton =
    !isSubCategory &&
    (articles || children) &&
    !showFullCategory &&
    articles?.length + children?.length > 4;

  const onCategoryClick = () => {
    push(`${RouterHref.KnowledgeBase}/list/${id}`);
  };

  const handleDeleteCategory = () => {
    if (category?.id && dropProps?.onDeleteCategory) {
      dropProps.onDeleteCategory(category.id);
    }
  };

  const showAllButtonComponent = showAllButton && (
    <button
      onClick={onCategoryClick}
      className={styles.KBCategoryContainer__showAllButton}
    >
      Показать все
    </button>
  );

  const categoryTitle = (
    <KBCategoryTitle
      id={id}
      title={title}
      isMobileAll={isMobileAll}
      showFullCategory={showFullCategory}
    />
  );

  const showRollUpButton = Boolean(
    (showFullCategory && !isSubCategory) || (isSubCategory && articles.length)
  );

  const rollUpButton = showRollUpButton && (
    <RollUpButton isOpen={isCategoryOpen} toggleButton={toggleCategoryOpen} />
  );

  return (
    <>
      <div
        className={cn(styles.KBCategoryContainer, {
          [styles.KBCategoryContainer_isSubCategory]: isSubCategory,
          [classNameSubCategory || '']: isSubCategory,
          [styles.KBCategoryContainer_empty]: isSubCategory && !articles.length,
          [styles.KBCategoryContainer__rollUp]: !isCategoryOpen,
        })}
      >
        <div
          onClick={isSubCategory ? undefined : onCategoryClick}
          className={cn(styles.KBCategoryContainer__categoryButton, {
            [styles.KBCategoryContainer__categoryButton_withPadding]: needDrop,
          })}
          onMouseEnter={needDrop ? onMouseEnter : undefined}
          onMouseLeave={needDrop ? onMouseLeave : undefined}
          onKeyDown={undefined}
          role="button"
          tabIndex={0}
        >
          {rollUpButton}
          {categoryTitle}
          {categoryDrop}
        </div>
        <div
          className={cn(styles.KBCategoryContainer__articles, {
            [styles.KBCategoryContainer__articles_withMargin]: showFullCategory,
            [styles.KBCategoryContainer__articles_hidden]: !isCategoryOpen,
          })}
        >
          {articlesListComponent}
          {subCategoriesListComponent}
        </div>
        {showAllButtonComponent}
      </div>
      <DeleteApproveOrCancel
        isModal={isDelete}
        toggleModal={toggleModal}
        entityTitle={isSubCategory ? 'подкатегорию' : 'категорию'}
        onApprove={handleDeleteCategory}
      />
    </>
  );
};
