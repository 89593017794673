import cn from 'clsx';
import { FC, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import {
  getUserFirstName,
  getUserLastName,
  getUserMiddleName,
} from '@entities/auth';
import { getIsNotCheckedNotifications } from '@entities/notifications';
import {
  fetchIsUncheckedNewsRequest,
  getIsUncheckedNews,
} from '@entities/profile';
import { getIsModal, setIsModal } from '@entities/user-assistance';
import {
  AuthService,
  BreadCrumbs,
  ExitHeaderIcon,
  getBreadCrumbsConfig,
  getIsMobile,
  getIsMobileSmall,
  InfoQuestionMarkIcon,
  MegaphoneIcon,
  NotificationCircle,
  NotificationsIcon,
  OutsideClickHandler,
  Popover,
  PopoverButton,
  PopoverContainer,
  Ripple,
  RouterHref,
  SettingsHeaderIcon,
} from '@shared';

import { resetStore } from '../../model/actions';
import { NotificationsBlock } from '../NotificationsBlock';

import styles from './TopBar.module.scss';

export const TopBar: FC = () => {
  const isNotCheckedNotifications = useSelector(getIsNotCheckedNotifications);
  const isMobile = useSelector(getIsMobile);
  const isMobileSmall = useSelector(getIsMobileSmall);
  const userFirstName = useSelector(getUserFirstName);
  const userLastName = useSelector(getUserLastName);
  const userMiddleName = useSelector(getUserMiddleName);
  const breadCrumbsConfig = useSelector(getBreadCrumbsConfig);
  const isUserAssistanceModal = useSelector(getIsModal);
  const isUncheckedNews = useSelector(getIsUncheckedNews);

  const isMobileAll = isMobile || isMobileSmall;

  const { push } = useHistory();

  const dispatch = useDispatch();

  const [isPopoverOpen, setIsPopoverOpen] = useState<boolean>(false);
  const [isNotificationsBlockShow, setIsNotificationsBlockShow] =
    useState(false);

  const middleName = userMiddleName ? `${userMiddleName[0]}.` : '';
  const firstName = userFirstName ? `${userFirstName[0]}.` : '';

  const fullName = `${userLastName} ${firstName} ${middleName}`;

  const openIsNotificationsBlock = () => {
    setIsNotificationsBlockShow(true);
  };

  const closeIsNotificationsBlock = () => {
    setIsNotificationsBlockShow(false);
  };

  const togglePopover = () => {
    setIsPopoverOpen((prevState) => !prevState);
  };

  const onSettingsClick = () => {
    push(RouterHref.ProfileSettings);
  };

  const onNewsClick = () => {
    push(RouterHref.ProfileNews);
  };

  const unAuthorization = () => {
    AuthService.doLogout();
    dispatch(resetStore());
  };

  const toggleUserAssistanceModal = () => {
    dispatch(setIsModal(!isUserAssistanceModal));
  };

  useEffect(() => {
    dispatch(fetchIsUncheckedNewsRequest());
  }, []);

  const notificationBlock = !isMobileAll && (
    <OutsideClickHandler
      onClickOutside={closeIsNotificationsBlock}
      className={cn(styles.topBar__notificationsBlock, {
        [styles.topBar__notificationsBlock_show]: isNotificationsBlockShow,
      })}
    >
      <NotificationsBlock onClose={closeIsNotificationsBlock} />
    </OutsideClickHandler>
  );

  const popoverContent = (
    <PopoverContainer className={styles.topBar__popoverContainer}>
      <PopoverButton
        onClick={onSettingsClick}
        icon={<SettingsHeaderIcon className={styles.topBar__popoverIcon} />}
      >
        Настройки
      </PopoverButton>
      <PopoverButton
        onClick={unAuthorization}
        icon={<ExitHeaderIcon className={styles.topBar__popoverIcon} />}
      >
        Выход
      </PopoverButton>
    </PopoverContainer>
  );

  const notification = isNotCheckedNotifications && (
    <NotificationCircle
      className={cn(
        styles.topBar__notification,
        styles.topBar__notification_blue
      )}
    />
  );

  const newsNotification = isUncheckedNews && (
    <NotificationCircle
      className={cn(
        styles.topBar__notification,
        styles.topBar__notification_yellow
      )}
    />
  );

  return (
    <div className={styles.topBar}>
      <BreadCrumbs config={breadCrumbsConfig} />
      <div className={styles.topBar__buttonsWrapper}>
        <button
          onClick={toggleUserAssistanceModal}
          className={styles.topBar__button}
        >
          <InfoQuestionMarkIcon />
          <Ripple appearance="grey" />
        </button>
        <button onClick={onNewsClick} className={styles.topBar__button}>
          <MegaphoneIcon />
          <Ripple appearance="grey" />
          {newsNotification}
        </button>
        <button
          onClick={openIsNotificationsBlock}
          className={styles.topBar__button}
        >
          <NotificationsIcon />
          <Ripple appearance="grey" />
          {notification}
        </button>
        <Popover
          content={popoverContent}
          togglePopover={togglePopover}
          isOpen={isPopoverOpen}
          positions={['bottom', 'right', 'left', 'top']}
          align="end"
          padding={12}
        >
          <button
            onClick={togglePopover}
            className={styles.topBar__profileButton}
          >
            {fullName}
            <Ripple appearance="grey" />
          </button>
        </Popover>
      </div>
      {notificationBlock}
    </div>
  );
};
