import { Attribute, SELECT_ATTRIBUTES_LIST } from '@entities/rules';
import { SelectOption } from '@shared';

export const getAttributesOptions = (attributes: Attribute[]) => {
  const attributesValue = attributes.map(({ attribute }) => {
    const atr = attribute as SelectOption;
    return atr.value;
  });

  return SELECT_ATTRIBUTES_LIST.filter(
    ({ value }) => !attributesValue.includes(value)
  );
};
