import { ComponentType, ReactPropTypes, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { getRedirectPath, setRedirectPath } from '@shared';

export const withRedirectPath =
  (Component: ComponentType<ReactPropTypes>) => (props: ReactPropTypes) => {
    const redirectPath = useSelector(getRedirectPath);

    const dispatch = useDispatch();

    const { push } = useHistory();

    useEffect(() => {
      if (redirectPath) {
        push(redirectPath);
        dispatch(setRedirectPath(null));
      }
    }, [redirectPath]);

    return <Component {...props} />;
  };
