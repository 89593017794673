import { EmployeesState } from './types';

export const getSlaStatisticEmployees = (state: EmployeesState) =>
  state.desktopEmployees.slaStatistic;
export const getEmployeesStatistic = (state: EmployeesState) =>
  state.desktopEmployees.employeesStatistic;
export const getTotalEmployees = (state: EmployeesState) =>
  state.desktopEmployees.totalEmployees;
export const getTicketsStatisticEmployees = (state: EmployeesState) =>
  state.desktopEmployees.ticketsStatistic;
export const getEmployeesPageNumber = (state: EmployeesState) =>
  state.desktopEmployees.pageNum;
export const getEmployeesPageSize = (state: EmployeesState) =>
  state.desktopEmployees.pageSize;
export const getIsEmployeesLoading = (state: EmployeesState) =>
  state.desktopEmployees.isLoading;
