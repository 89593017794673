import {
  ContractDetailPayload,
  SystemsByContractIdPayload,
} from '@entities/contract/model/types';
import { Environment } from '@entities/environment/model/types';
import { SLAType } from '@entities/sla/model/types';
import {
  System,
  SystemPriority,
  SystemsFilter,
} from '@entities/system/model/types';
import { ResponseWithMeta, UpdateContentType } from '@shared';

import { SystemTicketPayload } from './types';

export enum Systems {
  FETCH_SYSTEMS_SUCCESS_UPDATE = 'SYSTEMS/FETCH_SYSTEMS_SUCCESS_UPDATE',
  FETCH_SYSTEMS_SUCCESS_JOIN = 'SYSTEMS/FETCH_SYSTEMS_SUCCESS_JOIN',
  FETCH_FILTERS_SYSTEMS_SUCCESS_UPDATE = 'SYSTEMS/FETCH_FILTERS_SYSTEMS_SUCCESS_UPDATE',
  FETCH_FILTERS_SYSTEMS_SUCCESS_JOIN = 'SYSTEMS/FETCH_FILTERS_SYSTEMS_SUCCESS_JOIN',
  FETCH_SYSTEMS_ORGANIZATION_SUCCESS = 'SYSTEMS/FETCH_SYSTEMS_ORGANIZATION_SUCCESS',
  FETCH_SYSTEMS_REQUEST = 'SYSTEMS/FETCH_SYSTEMS_REQUEST',
  FETCH_SYSTEMS_ORGANIZATION_REQUEST = 'SYSTEMS/FETCH_SYSTEMS_ORGANIZATION_REQUEST',
  FETCH_MY_SYSTEMS_REQUEST = 'SYSTEMS/FETCH_MY_SYSTEMS_REQUEST',
  FETCH_FILTERS_SYSTEMS_REQUEST = 'SYSTEMS/FETCH_FILTERS_SYSTEMS_REQUEST',
  SYSTEMS_LOADING_SHOW = 'SYSTEMS/SYSTEMS_LOADING_SHOW',
  SYSTEMS_LOADING_HIDE = 'SYSTEMS/SYSTEMS_LOADING_HIDE',
  SET_CURRENT_SYSTEMS_PAGE = 'SYSTEMS/SET_CURRENT_SYSTEMS_PAGE',
  SET_SORT_SYSTEMS = 'SYSTEMS/SET_SORT_SYSTEMS',
  SET_SIZE_PAGE_SYSTEMS = 'SYSTEMS/SET_SIZE_PAGE_SYSTEMS',
  FETCH_CURRENT_SYSTEM_REQUEST = 'SYSTEMS/FETCH_CURRENT_SYSTEM_REQUEST',
  SET_CURRENT_SYSTEM_SUCCESS = 'SYSTEMS/SET_CURRENT_SYSTEM_SUCCESS',
  RESET_SYSTEMS_STATE = 'SYSTEMS/RESET_SYSTEMS_STATE',
  SET_CURRENT_SYSTEM_ID = 'SYSTEMS/SET_CURRENT_SYSTEM_ID',
  SET_CURRENT_ORGANIZATION_SYSTEM_ID = 'SYSTEMS/SET_CURRENT_ORGANIZATION_SYSTEM_ID',
  FETCH_SYSTEMS_BY_CONTRACT_ID_REQUEST = 'SYSTEMS/FETCH_SYSTEMS_BY_CONTRACT_ID_REQUEST',
  SET_SYSTEMS_BY_CONTRACT = 'SYSTEMS/SET_SYSTEMS_BY_CONTRACT',
  SET_SYSTEMS_FILTER = 'SYSTEMS/SET_SYSTEMS_FILTER',
  SET_SELECTED_SYSTEM_SUCCESS = 'SYSTEMS/SET_SELECTED_SYSTEM_SUCCESS',
  FETCH_SYSTEM_PRIORITIES_REQUEST = 'SYSTEMS/FETCH_SYSTEM_PRIORITIES_REQUEST',
  FETCH_SYSTEM_PRIORITIES_SUCCESS = 'SYSTEMS/FETCH_SYSTEM_PRIORITIES_SUCCESS',
  RESET_SYSTEM_PRIORITIES = 'SYSTEMS/RESET_SYSTEM_PRIORITIES',
  SET_IS_ERROR_NO_DATA = 'SYSTEMS/SET_IS_ERROR_NO_DATA',
  RESET_SYSTEM_ID_STATE = 'SYSTEMS/RESET_SYSTEM_ID_STATE',
  DELETE_SYSTEM_BY_ID_REQUEST = 'SYSTEMS/DELETE_SYSTEM_BY_ID_REQUEST',
  FETCH_SYSTEMS_ORGANIZATION_FOR_JIRA_REQUEST = 'SYSTEMS/FETCH_SYSTEMS_ORGANIZATION_FOR_JIRA_REQUEST',
  RESET_CURRENT_SYSTEM = 'SYSTEMS/RESET_CURRENT_SYSTEM',
  FETCH_ENVIRONMENTS_REQUEST = 'SYSTEMS/FETCH_ENVIRONMENTS_REQUEST',
  FETCH_ENVIRONMENTS_SUCCESS = 'SYSTEMS/FETCH_ENVIRONMENTS_SUCCESS',
  FETCH_SLA_BY_CONTRACT_ID_BY_SYSTEM_ID_REQUEST = 'SYSTEMS/FETCH_SLA_BY_CONTRACT_ID_BY_SYSTEM_ID_REQUEST',
  FETCH_SLA_BY_CONTRACT_ID_BY_SYSTEM_ID_SUCCESS = 'SYSTEMS/FETCH_SLA_BY_CONTRACT_ID_BY_SYSTEM_ID_SUCCESS',
  RESET_SLA_BY_CONTRACT_ID_BY_SYSTEM_ID = 'SYSTEMS/RESET_SLA_BY_CONTRACT_ID_BY_SYSTEM_ID',
}

export interface ShowSystemsLoadingAction {
  type: Systems.SYSTEMS_LOADING_SHOW;
}

export interface HideSystemsLoadingAction {
  type: Systems.SYSTEMS_LOADING_HIDE;
}

export interface FetchSystemsRequestAction {
  type: Systems.FETCH_SYSTEMS_REQUEST;
}

export interface FetchSystemsOrganizationRequestAction {
  type: Systems.FETCH_SYSTEMS_ORGANIZATION_REQUEST;
  payload: string;
}

export interface FetchSystemsSuccessUpdateAction {
  type: Systems.FETCH_SYSTEMS_SUCCESS_UPDATE;
  payload: ResponseWithMeta<System[]>;
}

export interface FetchSystemsSuccessJoinAction {
  type: Systems.FETCH_SYSTEMS_SUCCESS_JOIN;
  payload: ResponseWithMeta<System[]>;
}

export interface FetchFiltersSystemsSuccessUpdateAction {
  type: Systems.FETCH_FILTERS_SYSTEMS_SUCCESS_UPDATE;
  payload: ResponseWithMeta<System[]>;
}

export interface FetchFiltersSystemsSuccessJoinAction {
  type: Systems.FETCH_FILTERS_SYSTEMS_SUCCESS_JOIN;
  payload: ResponseWithMeta<System[]>;
}

export interface FetchSystemsOrganizationSuccessAction {
  type: Systems.FETCH_SYSTEMS_ORGANIZATION_SUCCESS;
  payload: System[];
}

export interface FetchMySystemsRequestAction {
  type: Systems.FETCH_MY_SYSTEMS_REQUEST;
  payload: UpdateContentType;
}

export interface FetchFiltersSystemsRequestAction {
  type: Systems.FETCH_FILTERS_SYSTEMS_REQUEST;
  payload: SystemTicketPayload;
}

export interface FetchCurrentSystemRequestAction {
  type: Systems.FETCH_CURRENT_SYSTEM_REQUEST;
  payload: string;
}

export interface SetCurrentSystemsPageAction {
  type: Systems.SET_CURRENT_SYSTEMS_PAGE;
  payload: number;
}

export interface SetSizePageAction {
  type: Systems.SET_SIZE_PAGE_SYSTEMS;
  payload: number;
}

export interface SetSortSystemsAction {
  type: Systems.SET_SORT_SYSTEMS;
  payload: string;
}

export interface SetCurrentSystemSuccessAction {
  type: Systems.SET_CURRENT_SYSTEM_SUCCESS;
  payload: System;
}
export interface ResetSystemsStateAction {
  type: Systems.RESET_SYSTEMS_STATE;
}

export interface SetCurrentSystemIdAction {
  type: Systems.SET_CURRENT_SYSTEM_ID;
  payload: string;
}
export interface SetCurrentOrganizationSystemIdAction {
  type: Systems.SET_CURRENT_ORGANIZATION_SYSTEM_ID;
  payload?: string;
}

export interface FetchSystemsByContractIdRequestAction {
  type: Systems.FETCH_SYSTEMS_BY_CONTRACT_ID_REQUEST;
  payload?: SystemsByContractIdPayload;
}

export interface SetSystemsByContractAction {
  type: Systems.SET_SYSTEMS_BY_CONTRACT;
  payload: System;
}

export interface SetSystemsFilterAction {
  type: Systems.SET_SYSTEMS_FILTER;
  payload: SystemsFilter;
}

export interface SetSelectedSystemSuccessAction {
  type: Systems.SET_SELECTED_SYSTEM_SUCCESS;
  payload: System;
}

export interface FetchSystemPrioritiesRequestAction {
  type: Systems.FETCH_SYSTEM_PRIORITIES_REQUEST;
  payload: string;
}

export interface FetchSystemPrioritiesSuccessAction {
  type: Systems.FETCH_SYSTEM_PRIORITIES_SUCCESS;
  payload: SystemPriority[];
}

export interface ResetSystemPrioritiesAction {
  type: Systems.RESET_SYSTEM_PRIORITIES;
}

export interface SetIsErrorNoDataAction {
  type: Systems.SET_IS_ERROR_NO_DATA;
  payload: boolean;
}

export interface ResetSystemIdStateAction {
  type: Systems.RESET_SYSTEM_ID_STATE;
}

export interface DeleteSystemRequestAction {
  type: Systems.DELETE_SYSTEM_BY_ID_REQUEST;
  payload: string;
}

export interface FetchSystemsOrganizationForJiraRequestAction {
  type: Systems.FETCH_SYSTEMS_ORGANIZATION_FOR_JIRA_REQUEST;
  payload: string;
}

export interface ResetCurrentSystemAction {
  type: Systems.RESET_CURRENT_SYSTEM;
}

export interface FetchSLAByContractIdBySystemIdRequestSystemsAction {
  type: Systems.FETCH_SLA_BY_CONTRACT_ID_BY_SYSTEM_ID_REQUEST;
  payload: ContractDetailPayload;
}

export interface FetchSLAByContractIdBySystemIdSuccessSystemsAction {
  type: Systems.FETCH_SLA_BY_CONTRACT_ID_BY_SYSTEM_ID_SUCCESS;
  payload: SLAType;
}

export interface ResetSLAByContractIdBySystemIdSystemsAction {
  type: Systems.RESET_SLA_BY_CONTRACT_ID_BY_SYSTEM_ID;
}

export interface FetchEnvironmentsSuccessAction {
  type: Systems.FETCH_ENVIRONMENTS_SUCCESS;
  payload: ResponseWithMeta<Environment[]>;
}

export type SystemsActionsTypes =
  | FetchSystemsSuccessUpdateAction
  | FetchSystemsSuccessJoinAction
  | FetchFiltersSystemsSuccessUpdateAction
  | FetchFiltersSystemsSuccessJoinAction
  | FetchSystemsOrganizationSuccessAction
  | SetCurrentSystemSuccessAction
  | SetCurrentSystemIdAction
  | FetchSystemsByContractIdRequestAction
  | SetSystemsByContractAction
  | SetSortSystemsAction
  | SetCurrentSystemsPageAction
  | SetSizePageAction
  | SetSystemsFilterAction
  | SetSelectedSystemSuccessAction
  | FetchSystemPrioritiesSuccessAction
  | SetIsErrorNoDataAction
  | SetCurrentOrganizationSystemIdAction
  | ResetCurrentSystemAction
  | FetchSLAByContractIdBySystemIdRequestSystemsAction
  | FetchSLAByContractIdBySystemIdSuccessSystemsAction
  | ResetSLAByContractIdBySystemIdSystemsAction
  | FetchFiltersSystemsRequestAction
  | FetchEnvironmentsSuccessAction;
