import { createSelector } from 'reselect';

import { createOrganizationsSelectListSelector } from '@entities/organizations';
import { SelectOption } from '@shared';

import { ArticleState } from './types';

export const getArticlesArticleViewing = (state: ArticleState) =>
  state.article.articlesArticleViewing || [];

export const getCurrentArticleArticleViewing = (state: ArticleState) =>
  state?.article.currentArticle;
export const getCurrentArticleLoadingArticleViewing = (state: ArticleState) =>
  state.article.currentArticleLoading || false;

export const getCategoriesArticlesArticleViewing = (state: ArticleState) =>
  state.article.categoriesArticleViewing;
export const getLoadingCategoriesArticleViewing = (state: ArticleState) =>
  state.article.categoriesLoadingArticleViewing || false;

export const getLoadingArticlesArticleViewing = (state: ArticleState) =>
  state.article.loadingArticlesArticleViewing || false;
export const getSelectedOrganizationArticlesArticleViewing = (
  state: ArticleState
) => state.article.organizationSelected;

export const getFilterArticlesArticleViewing = (state: ArticleState) =>
  state.article.articlesFilterArticleViewing || {};
export const getPaginationArticlesArticleViewing = (state: ArticleState) =>
  state.article.articlesPaginationArticleViewing || {};

export const getOrganizationsArticleViewing = (state: ArticleState) =>
  state.article.organizationsArticleViewing || [];

export const getSearchValueArticlesArticleViewing = (state: ArticleState) =>
  state.article.searchValue;
export const getOrganizationsArticleViewingSelectList =
  createOrganizationsSelectListSelector(getOrganizationsArticleViewing);

export const getSelectedOrgIdArticlesArticleViewing = createSelector<
  ArticleState,
  SelectOption | null | undefined,
  string[]
>([getSelectedOrganizationArticlesArticleViewing], (organization) =>
  organization?.value ? [organization?.value] : []
);
