import cn from 'clsx';
import { ChangeEvent, useEffect, useState } from 'react';
import { Control, Controller } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';

import {
  Button,
  Checkbox,
  Input,
  InputDatePicker,
  PlusIcon,
  Select,
  Typography,
  TypographyVariants,
} from '@shared';

import { getCurrentField } from '../../lib';
import {
  EnableFields,
  getExportFields,
  getExportFieldsRequest,
  getExtraExportFields,
  getIsExportPanelOpen,
  setTicketsCount,
  UseFormExportData,
} from '../../model';
import { AddCustomFieldsModal } from '../AddCustomFieldsModal';

import styles from './ExportFields.module.scss';

interface ExportFieldsProps {
  control: Control<UseFormExportData>;
  handleChange: (event: ChangeEvent<HTMLInputElement>) => void;
  enableFields: EnableFields;
}

export const ExportFields = (props: ExportFieldsProps) => {
  const { control, handleChange, enableFields } = props;

  const dispatch = useDispatch();

  const extraCustomFields = useSelector(getExtraExportFields);
  const isExportPanelOpen = useSelector(getIsExportPanelOpen);

  const [isModal, setIsModal] = useState<boolean>(false);

  const toggleIsModal = () => {
    setIsModal((prevState) => !prevState);
  };

  const exportFields = useSelector(getExportFields);

  useEffect(() => {
    if (isExportPanelOpen) {
      dispatch(getExportFieldsRequest());
    }
  }, [isExportPanelOpen]);

  const customFieldsList = extraCustomFields?.map((extraCustomField) => (
    <div key={extraCustomField.id} className={styles.exportFields__container}>
      <Checkbox
        name="customFields"
        className={styles.exportFields__container_checkbox}
        onChange={(event) => {
          handleChange(event);
          dispatch(setTicketsCount());
        }}
        checked={enableFields.customFields}
      />
      <Input label={extraCustomField.title} disabled />
    </div>
  ));

  return (
    <div className={styles.exportFields}>
      <Typography
        variant={TypographyVariants.b3}
        className={styles.exportFields__container__mobileDescription}
      >
        Выберите поля для экспорта и при необходимости примените фильтрацию
      </Typography>
      <div className={styles.exportFields__container}>
        <Checkbox
          label="Выбрать все"
          name="all"
          className={cn(
            styles.exportFields__container_checkbox,
            styles.exportFields__container_checkboxAll
          )}
          onChange={(event) => {
            handleChange(event);
            dispatch(setTicketsCount());
          }}
          checked={enableFields.all}
        />
      </div>
      <Controller
        name="status"
        control={control}
        render={({ field }) => (
          <div className={styles.exportFields__container}>
            <Checkbox
              name="status"
              className={styles.exportFields__container_checkbox}
              onChange={(event) => {
                handleChange(event);
                dispatch(setTicketsCount());
              }}
              checked={enableFields.status}
            />
            <Select<string>
              mobileModalTitle="статус"
              label="Статус"
              onChange={(value) => {
                field.onChange(value);
                dispatch(setTicketsCount());
              }}
              options={getCurrentField(exportFields?.status) || []}
              value={field.value}
              classNameContainer={styles.exportFields__container_padding}
              isMulti
              className={styles.exportFields__container_select}
            />
          </div>
        )}
      />
      <Controller
        name="priority"
        control={control}
        render={({ field }) => (
          <div className={styles.exportFields__container}>
            <Checkbox
              name="priority"
              className={styles.exportFields__container_checkbox}
              onChange={(event) => {
                handleChange(event);
                dispatch(setTicketsCount());
              }}
              checked={enableFields.priority}
            />
            <Select<string>
              mobileModalTitle="Приоритет"
              label="Приоритет"
              onChange={(value) => {
                field.onChange(value);
                dispatch(setTicketsCount());
              }}
              classNameContainer={styles.exportFields__container_padding}
              options={getCurrentField(exportFields?.priority) || []}
              value={field.value}
              isMulti
              className={styles.exportFields__container_select}
            />
          </div>
        )}
      />
      <div className={styles.exportFields__container}>
        <Checkbox
          name="description"
          className={styles.exportFields__container_checkbox}
          onChange={(event) => {
            handleChange(event);
            dispatch(setTicketsCount());
          }}
          checked={enableFields.description}
        />
        <Input label="Описание тикета" disabled />
      </div>
      <div className={styles.exportFields__container}>
        <Checkbox
          name="solutions"
          className={styles.exportFields__container_checkbox}
          onChange={(event) => {
            handleChange(event);
            dispatch(setTicketsCount());
          }}
          checked={enableFields.description}
        />
        <Input label="Решение по тикету" disabled />
      </div>
      <Controller
        name="client"
        control={control}
        render={({ field }) => (
          <div className={styles.exportFields__container}>
            <Checkbox
              name="client"
              className={styles.exportFields__container_checkbox}
              onChange={(event) => {
                handleChange(event);
                dispatch(setTicketsCount());
              }}
              checked={enableFields.client}
            />
            <Select<string>
              label="Клиент"
              mobileModalTitle="Клиент"
              onChange={(value) => {
                field.onChange(value);
                dispatch(setTicketsCount());
              }}
              classNameContainer={styles.exportFields__container_padding}
              options={getCurrentField(exportFields?.client) || []}
              value={field.value}
              isMulti
              className={styles.exportFields__container_select}
            />
          </div>
        )}
      />
      <Controller
        name="performer"
        control={control}
        render={({ field }) => (
          <div className={styles.exportFields__container}>
            <Checkbox
              name="performer"
              className={styles.exportFields__container_checkbox}
              onChange={(event) => {
                handleChange(event);
                dispatch(setTicketsCount());
              }}
              checked={enableFields.performer}
            />
            <Select<string>
              label="Исполнитель"
              mobileModalTitle="Исполнитель"
              onChange={(value) => {
                field.onChange(value);
                dispatch(setTicketsCount());
              }}
              options={getCurrentField(exportFields?.performer) || []}
              value={field.value}
              isMulti
              classNameContainer={styles.exportFields__container_padding}
              className={styles.exportFields__container_select}
            />
          </div>
        )}
      />
      <Controller
        name="system"
        control={control}
        render={({ field }) => (
          <div className={styles.exportFields__container}>
            <Checkbox
              name="system"
              className={styles.exportFields__container_checkbox}
              onChange={(event) => {
                handleChange(event);
                dispatch(setTicketsCount());
              }}
              checked={enableFields.system}
            />
            <Select<string>
              label="Система"
              mobileModalTitle="Система"
              onChange={(value) => {
                field.onChange(value);
                dispatch(setTicketsCount());
              }}
              classNameContainer={styles.exportFields__container_padding}
              options={getCurrentField(exportFields?.system) || []}
              value={field.value}
              isMulti
              className={styles.exportFields__container_select}
            />
          </div>
        )}
      />
      <Controller
        name="ticketType"
        control={control}
        render={({ field }) => (
          <div className={styles.exportFields__container}>
            <Checkbox
              name="ticketType"
              className={styles.exportFields__container_checkbox}
              onChange={(event) => {
                handleChange(event);
                dispatch(setTicketsCount());
              }}
              checked={enableFields.ticketType}
            />
            <Select<string>
              label="Тип тикета"
              mobileModalTitle="Тип тикета"
              onChange={(value) => {
                field.onChange(value);
                dispatch(setTicketsCount());
              }}
              options={getCurrentField(exportFields?.ticketType) || []}
              value={field.value}
              isMulti
              classNameContainer={styles.exportFields__container_padding}
              className={styles.exportFields__container_select}
            />
          </div>
        )}
      />
      <Controller
        name="environment"
        control={control}
        render={({ field }) => (
          <div className={styles.exportFields__container}>
            <Checkbox
              name="environment"
              className={styles.exportFields__container_checkbox}
              onChange={(event) => {
                handleChange(event);
                dispatch(setTicketsCount());
              }}
              checked={enableFields.environment}
            />
            <Select<string>
              label="Среда"
              mobileModalTitle="Среда"
              onChange={(value) => {
                field.onChange(value);
                dispatch(setTicketsCount());
              }}
              options={getCurrentField(exportFields?.environment) || []}
              value={field.value}
              isMulti
              classNameContainer={styles.exportFields__container_padding}
              className={styles.exportFields__container_select}
            />
          </div>
        )}
      />
      <Controller
        name="organization"
        control={control}
        render={({ field }) => (
          <div className={styles.exportFields__container}>
            <Checkbox
              name="organization"
              className={styles.exportFields__container_checkbox}
              onChange={(event) => {
                handleChange(event);
                dispatch(setTicketsCount());
              }}
              checked={enableFields.organization}
            />
            <Select<string>
              label="Организация"
              mobileModalTitle="Организация"
              onChange={(value) => {
                field.onChange(value);
                dispatch(setTicketsCount());
              }}
              options={getCurrentField(exportFields?.organization) || []}
              value={field.value}
              isMulti
              classNameContainer={styles.exportFields__container_padding}
              className={styles.exportFields__container_select}
            />
          </div>
        )}
      />
      <Controller
        name="workgroup"
        control={control}
        render={({ field }) => (
          <div className={styles.exportFields__container}>
            <Checkbox
              name="workgroup"
              className={styles.exportFields__container_checkbox}
              onChange={(event) => {
                handleChange(event);
                dispatch(setTicketsCount());
              }}
              checked={enableFields.workgroup}
            />
            <Select<string>
              label="Группа"
              mobileModalTitle="Группа"
              onChange={(value) => {
                field.onChange(value);
                dispatch(setTicketsCount());
              }}
              options={getCurrentField(exportFields?.workgroup) || []}
              value={field.value}
              isMulti
              classNameContainer={styles.exportFields__container_padding}
              className={styles.exportFields__container_select}
            />
          </div>
        )}
      />
      <p className={styles.exportFields__container_dateText}>Дата создания</p>
      <div className={styles.exportFields__container_date}>
        <Checkbox
          name="dateCreation"
          className={styles.exportFields__container_checkbox}
          onChange={(event) => {
            handleChange(event);
            dispatch(setTicketsCount());
          }}
          checked={enableFields.dateCreation}
        />
        <Controller
          name="dateCreation"
          control={control}
          render={({ field }) => (
            <InputDatePicker
              type="datePicker"
              placeholder="Период"
              value={field.value}
              onChange={(options) => {
                field.onChange(options);
                dispatch(setTicketsCount());
              }}
              disabled={!enableFields.dateCreation}
              className={styles.exportFields__dateInput}
              showRange
            />
          )}
        />
      </div>
      <p className={styles.exportFields__container_dateText}>Дата исполнения</p>
      <div className={styles.exportFields__container_date}>
        <Checkbox
          name="dateSolve"
          className={styles.exportFields__container_checkbox}
          onChange={(event) => {
            handleChange(event);
            dispatch(setTicketsCount());
          }}
          checked={enableFields.dateSolve}
        />
        <Controller
          name="dateSolve"
          control={control}
          render={({ field }) => (
            <InputDatePicker
              type="datePicker"
              placeholder="Период"
              value={field.value}
              onChange={(options) => {
                field.onChange(options);
                dispatch(setTicketsCount());
              }}
              disabled={!enableFields.dateSolve}
              className={styles.exportFields__dateInput}
              showRange
            />
          )}
        />
      </div>
      <Controller
        name="sla"
        control={control}
        render={({ field }) => (
          <div className={styles.exportFields__container}>
            <Checkbox
              name="sla"
              className={styles.exportFields__container_checkbox}
              onChange={(event) => {
                handleChange(event);
                dispatch(setTicketsCount());
              }}
              checked={enableFields.sla}
            />
            <Select<string>
              label="SLA"
              mobileModalTitle="SLA"
              value={field.value}
              options={getCurrentField(exportFields?.sla) || []}
              onChange={(value) => {
                field.onChange(value);
                dispatch(setTicketsCount());
              }}
            />
          </div>
        )}
      />
      <Controller
        name="sources"
        control={control}
        render={({ field }) => (
          <div className={styles.exportFields__container}>
            <Checkbox
              name="sources"
              className={styles.exportFields__container_checkbox}
              onChange={(event) => {
                handleChange(event);
                dispatch(setTicketsCount());
              }}
              checked={enableFields.sources}
            />
            <Select<string>
              label="Источник"
              mobileModalTitle="Источник"
              value={field.value}
              options={getCurrentField(exportFields?.sources) ?? []}
              onChange={(value) => {
                field.onChange(value);
                dispatch(setTicketsCount());
              }}
              isMulti
            />
          </div>
        )}
      />
      {customFieldsList}
      <div className={styles.exportFields__container}>
        <Button onClick={toggleIsModal} icon={<PlusIcon />} appearance="flat">
          Кастомные поля
        </Button>
        {isModal && (
          <AddCustomFieldsModal
            isModal={isModal}
            toggleIsModal={toggleIsModal}
            classNameFormPortal={styles.exportFields__containerModalPortal}
            classNameFormPortalCancel={
              styles.exportFields__containerModalPortalCancel
            }
          />
        )}
      </div>
    </div>
  );
};
