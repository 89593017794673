import { ObjDataType } from '../../model';

export const getCheckboxList = (data: ObjDataType[]) => {
  const ids = data.map((item) => item?.id?.toString() || '');

  return ids.reduce<Record<string, boolean>>(
    (acc, id) => ({
      ...acc,
      [id]: false,
    }),
    {}
  );
};
