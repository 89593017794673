import cn from 'clsx';
import { FC } from 'react';

import { PickedTime } from '../../../../model';
import { TimePickerCellProps } from '../TimePickerCell';
import { TimePickerCell } from '../TimePickerCell/TimePickerCell';

import styles from './TimePickerCellList.module.scss';

type TimePickerCellListProps = {
  list: PickedTime[];
} & Pick<TimePickerCellProps, 'pickValue' | 'className'>;

export const TimePickerCellList: FC<TimePickerCellListProps> = ({
  list,
  pickValue,
  className,
  ...rest
}) => (
  <div className={cn(styles.timePickerCellList, className)}>
    {list.map(({ isActive, value, valueForList }) => (
      <TimePickerCell
        {...rest}
        key={value}
        value={value}
        valueForList={valueForList}
        pickValue={pickValue}
        isActive={isActive}
      />
    ))}
  </div>
);
