import { Contract, ContractLayout } from '@features/contract';

import styles from './ContractPage.module.scss';

const ContractPage = () => (
  <ContractLayout>
    <Contract className={styles.contractPage__content} />
  </ContractLayout>
);

export default ContractPage;
