import { ChangeEvent, FC } from 'react';
import { useSelector } from 'react-redux';

import { getSystemsTransfer } from '@entities/contract/model/selectors';
import { System } from '@entities/system/model/types';
import { Table } from '@shared';

import { TABLE_FIELDS_FROM_CONTRACTS } from '../../config';
import { getLoading } from '../../model';

import { getDataTable } from './utils';

interface SystemsFromContractTableProps {
  systems?: System[];
  handleClickSystem?: (value: string) => void;
  activeRowId?: string;
  sort?: string;
  handleSort?: (value: string) => void;
  onChangeCheckbox?: (event: ChangeEvent<HTMLInputElement>) => void;
  onSelectAllCheckbox?: (event: ChangeEvent<HTMLInputElement>) => void;
  isFilterActive?: boolean;
  customLoading?: boolean;
  showFilterRow?: boolean;
  filterComponent?: JSX.Element;
}

export const SystemsFromContractTable: FC<SystemsFromContractTableProps> = ({
  systems,
  activeRowId,
  handleClickSystem,
  sort,
  handleSort,
  onChangeCheckbox,
  onSelectAllCheckbox,
  isFilterActive,
  customLoading = false,
  showFilterRow,
  filterComponent,
}) => {
  const data = getDataTable(systems);

  const loading = useSelector(getLoading);
  const { transferStatus } = useSelector(getSystemsTransfer);

  return (
    <Table
      data={data}
      sort={sort}
      onSort={handleSort}
      // TODO: пустое состояние таблицы должно быть без полей
      columns={TABLE_FIELDS_FROM_CONTRACTS}
      activeRowId={activeRowId}
      onRowClick={handleClickSystem}
      dataLoading={loading || customLoading}
      isFilterActive={isFilterActive}
      valueFieldName="id"
      filterComponent={showFilterRow && filterComponent}
      checkboxProps={{
        withCheckbox: !transferStatus,
        onChangeCheckbox,
        onSelectAllCheckbox,
      }}
      disabledSort={!!transferStatus}
    />
  );
};
