import { WorkGroup, WorkGroupLayout } from '@widgets/work-group';

import styles from './WorkGroupPage.module.scss';

const WorkGroupPage = () => (
  <WorkGroupLayout>
    <WorkGroup className={styles.workGroupPage__content} />
  </WorkGroupLayout>
);

export default WorkGroupPage;
