import cn from 'clsx';
import { FC, useMemo } from 'react';

import { SIDEBAR_OPTIONS } from '../../config';
import { RangeDateType } from '../../model';

import styles from './Sidebar.module.scss';

interface SidebarProps {
  className?: string;
  setPickedDate: (date?: RangeDateType) => void;
}

export const Sidebar: FC<SidebarProps> = ({ className, setPickedDate }) => {
  const firstDayOfWeek = new Date().getDate() - new Date().getDay() + 1;
  const lastDayOfWeek = firstDayOfWeek + 6;
  const currentYear = new Date().getFullYear();
  const quarter = Math.floor((new Date().getMonth() + 3) / 3) - 1;
  const fromMonth = quarter * 3;

  const formatDateMap: Record<string, () => RangeDateType> = {
    'Текущая неделя': () => ({
      from: new Date(new Date().setDate(firstDayOfWeek)),
      to: new Date(new Date().setDate(lastDayOfWeek)),
    }),
    'Последние 7 дней': () => ({
      from: new Date(new Date().setDate(new Date().getDate() - 6)),
      to: new Date(),
    }),
    'Предыдущая неделя': () => ({
      from: new Date(new Date().setDate(firstDayOfWeek - 7)),
      to: new Date(new Date().setDate(lastDayOfWeek - 7)),
    }),
    'Текущий месяц': () => ({
      from: new Date(new Date().setDate(1)),
      to: new Date(currentYear, new Date().getMonth() + 1, 0),
    }),
    'Последние 30 дней': () => ({
      from: new Date(new Date().setDate(new Date().getDate() - 30)),
      to: new Date(),
    }),
    'Предыдущий месяц': () => ({
      to: new Date(currentYear, new Date().getMonth(), 0),
      from: new Date(currentYear, new Date().getMonth() - 1, 1),
    }),
    'Текущий квартал': () => ({
      from: new Date(currentYear, fromMonth, 1),
      to: new Date(currentYear, fromMonth + 3, 0),
    }),
  };

  const handleBtnClick = (option: (typeof SIDEBAR_OPTIONS)[number]) => {
    setPickedDate(formatDateMap[option]());
  };

  const sidebarContent = useMemo(
    () =>
      SIDEBAR_OPTIONS.map((option) => (
        <button
          type="button"
          key={option}
          onClick={() => handleBtnClick(option)}
        >
          <div className={styles.sidebar__typography}>{option}</div>
        </button>
      )),
    [SIDEBAR_OPTIONS]
  );

  return <div className={cn(styles.sidebar, className)}>{sidebarContent}</div>;
};
