import { FC, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
  fetchRoleInGroupByUserId,
  fetchRolesAddInGroupByOrgIdRequest,
  getLoadingAdd,
  getPropsRolesAdd,
  getRoles,
  getRolesAdd,
  getRolesAddFilter,
  resetRolesAddState,
  Role,
  RolesFilter as RolesFilterData,
  RoleType,
  setCurrentRolesAddPage,
  setRolesAddFilter,
  setRolesFilter,
  setSizePageRolesAdd,
  TableRolesTypes,
} from '@entities/roles';
import { getCurrentUserId, updateUserRolesInGroup } from '@entities/users';
import { AddModal, checkObjectIdentity } from '@shared';

import { ROLES_ADD_MODAL_FIELDS } from '../../config';
import { RolesFilter } from '../RolesFilter';

import { getDataTable } from './utils';

interface AddRolesInGroupToUserProps {
  isModal: boolean;
  toggleModal: () => void;
}

export const AddRolesInGroupToUser: FC<AddRolesInGroupToUserProps> = ({
  isModal,
  toggleModal,
}) => {
  const dispatch = useDispatch();

  const rolesAll = useSelector(getRolesAdd) || [];
  const rolesRightTable = useSelector(getRoles) || [];
  const currentUserId = useSelector(getCurrentUserId);
  const filterAddValues = useSelector(getRolesAddFilter);
  const dataLoading = useSelector(getLoadingAdd);
  const { pageNum, pageSize, totalElements } = useSelector(getPropsRolesAdd);

  const isFilterActive = !checkObjectIdentity(filterAddValues, {});

  const onSubmit = (data: Role[]) => {
    if (currentUserId) {
      dispatch(updateUserRolesInGroup(data.map((item) => item.id)));
    }
    toggleModal();
  };

  const handleChangePage = (page: number) => {
    dispatch(setCurrentRolesAddPage(page));
    dispatch(fetchRolesAddInGroupByOrgIdRequest());
  };

  const onChangeFilter = (filter: RolesFilterData) => {
    dispatch(
      setRolesAddFilter({
        ...filter,
        idsRolesDelete: filterAddValues?.idsRolesDelete,
      })
    );
    dispatch(setCurrentRolesAddPage(0));
    dispatch(fetchRolesAddInGroupByOrgIdRequest());
  };

  const onChangeIdsForDelete = (data: Role[]) => {
    dispatch(
      setRolesAddFilter({
        ...filterAddValues,
        idsRolesDelete: data.map((item) => item.id),
      })
    );
    dispatch(fetchRolesAddInGroupByOrgIdRequest());
  };

  const handleChangePageSize = (size: number) => {
    dispatch(setSizePageRolesAdd(size));
  };

  useEffect(() => {
    if (currentUserId) {
      dispatch(
        setRolesFilter({
          type: { title: 'Роли в группе', value: RoleType.ROLE_IN_GROUP },
        })
      );
      dispatch(fetchRoleInGroupByUserId());
    }
  }, [currentUserId]);

  useEffect(
    () => () => {
      dispatch(resetRolesAddState());
    },
    []
  );

  return (
    <AddModal<Role>
      leftData={rolesAll}
      rightData={rolesRightTable}
      getDataTable={getDataTable}
      columns={ROLES_ADD_MODAL_FIELDS}
      mainTitle="роли"
      subModalText="роли"
      leftTableTitle="Все роли"
      isModal={isModal}
      toggleModal={toggleModal}
      onSubmit={onSubmit}
      loadingData={dataLoading}
      isFilterActive={isFilterActive}
      onChangeIdsForDelete={onChangeIdsForDelete}
      paginationLeftTableProps={{
        pageNum,
        pageSize,
        elementsCount: totalElements,
        handleChangePage,
        handleChangePageSize,
      }}
      filterComponent={
        <RolesFilter
          tableType={TableRolesTypes.ADD_MODAL}
          onFilter={onChangeFilter}
        />
      }
    />
  );
};
