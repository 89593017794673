import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { Ticket } from '@entities/ticket/model/types';
import { ResponseWithMeta } from '@shared';

import { TicketsStatistic } from '../types';

export interface HistoryReducerState {
  tickets?: Ticket[];
  totalTickets?: number;
  ticketsStatistic?: TicketsStatistic;
  isTicketsLoading?: boolean;
  sort?: string;
  pageNum?: number;
  pageSize?: number;
}

const initialState: HistoryReducerState = {
  sort: 'datecreate_DESC',
  pageNum: 0,
  pageSize: 10,
  isTicketsLoading: false,
};

const historySlice = createSlice({
  name: 'desktopHistory',
  initialState,
  reducers: {
    fetchTicketsSuccessHistory: (
      state,
      action: PayloadAction<ResponseWithMeta<Ticket[]>>
    ) => {
      state.tickets = action.payload.content;
      state.totalTickets = action.payload.totalElements;
    },
    fetchTicketsRequestHistory: (_state) => {},
    fetchTicketsStatisticSuccessHistory: (
      state,
      action: PayloadAction<TicketsStatistic>
    ) => {
      state.ticketsStatistic = action.payload;
    },
    fetchTicketsStatisticRequestHistory: (_state) => {},
    setIsTicketsLoadingHistory: (state, action: PayloadAction<boolean>) => {
      state.isTicketsLoading = action.payload;
    },
    setPageNumHistory: (state, action: PayloadAction<number | undefined>) => {
      state.pageNum = action.payload;
    },
    setPageSizeHistory: (state, action: PayloadAction<number | undefined>) => {
      state.pageSize = action.payload;
    },
    setSortHistory: (state, action: PayloadAction<string | undefined>) => {
      state.sort = action.payload;
    },
    resetHistoryState: () => initialState,
  },
});

export const {
  fetchTicketsSuccessHistory,
  fetchTicketsRequestHistory,
  fetchTicketsStatisticRequestHistory,
  fetchTicketsStatisticSuccessHistory,
  setIsTicketsLoadingHistory,
  setPageNumHistory,
  setPageSizeHistory,
  setSortHistory,
  resetHistoryState,
} = historySlice.actions;
export const desktopHistory = historySlice.reducer;
