import { isNil } from 'lodash';

import { CustomFieldType } from '@shared/model';

import { TableCustomField } from '../../../Table/model';
import { CustomFieldTypeFilter } from '../../model';

const getPropsCustomFieldType = (field: CustomFieldTypeFilter) => {
  if (!field.type) {
    return null;
  }
  const { date, flag, text, type, listValue } = field;

  const fieldTypeMap = {
    [CustomFieldType.FIELD_DATE]: date,
    [CustomFieldType.FIELD_FLAG]: flag,
    [CustomFieldType.FIELD_TEXT]: text,
    [CustomFieldType.FIELD_LIST]: listValue,
  };

  return fieldTypeMap[type];
};

export const getValueCustomFieldsForFilter = (
  fields?: CustomFieldTypeFilter[]
) => {
  const customFieldsForTable: Record<string, TableCustomField> = {};

  fields?.forEach((field) => {
    const value = getPropsCustomFieldType(field);

    if (!isNil(value)) {
      customFieldsForTable[field.fieldId] = value;
    }
  });

  return customFieldsForTable;
};
