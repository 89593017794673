import { Attribute } from '@entities/rules';
import { getValueFromValueType, SelectOption } from '@shared';

export const prepareAttributeForRequest = (attributes: Attribute[]) =>
  attributes.reduce<Record<string, string[]>>((result, attribute) => {
    const title = getValueFromValueType(attribute.attribute) || '';
    const value = attribute.value as SelectOption[];
    const ids = value.map((item) => item.value);
    // eslint-disable-next-line no-param-reassign
    result[title] = ids;

    return result;
  }, {});
