import { call, put, select, takeEvery } from 'redux-saga/effects';

import {
  createError,
  createSuccessAlert,
  getKeysFromKeyValue,
  KeyValueOption,
  ResponseWithMeta,
  setAlert,
} from '@shared';

import { requests } from '../api';
import { INITIAL_ATTRIBUTES_VALUES } from '../config';

import {
  getCurrentTriggerId,
  getCustomFields,
  getFilterValues,
  getPropsTriggers,
} from './selectors';
import {
  createTrigger,
  deleteTriggerRequest,
  fetchAttributesFieldsRequest,
  fetchAttributesFieldsSuccess,
  fetchCurrentTrigger,
  fetchCustomFieldsRequest,
  fetchCustomFieldsSuccess,
  fetchFiltersFieldsRequest,
  fetchFiltersFieldsSuccess,
  fetchNextTicketStatusRequest,
  fetchNextTicketStatusSuccess,
  fetchTicketStatusRequest,
  fetchTicketStatusSuccess,
  fetchTriggersRequest,
  fetchTriggersSuccess,
  hideTriggersLoading,
  resetCurrentTrigger,
  setCurrentTriggerId,
  setCurrentTriggerSuccess,
  showTriggersLoading,
  updateTrigger,
} from './slice';
import {
  CustomField,
  Trigger,
  TriggerAttributes,
  TriggersFilter,
  TriggersFilterToRequest,
} from './types';

export const getFilterTriggersRequest = (
  filter: TriggersFilter
): TriggersFilterToRequest => filter;

function* filterFieldsFetch() {
  try {
    const triggersFields: TriggerAttributes = yield call(
      requests.fetchFiltersFields
    );
    const statuses: string[] = yield call(requests.fetchFiltersStatus);
    const preparedStatus = statuses.map((status) => ({
      key: status,
      value: status,
    }));

    yield put(
      fetchFiltersFieldsSuccess({
        ...triggersFields,
        status: preparedStatus,
      })
    );
  } catch (error) {
    createError(error);
  }
}

function* attributesFieldsFetch({
  payload,
}: ReturnType<typeof fetchAttributesFieldsRequest>) {
  try {
    const triggersFields: TriggerAttributes = yield call(
      requests.fetchTriggersFields,
      payload
    );
    yield put(fetchAttributesFieldsSuccess(triggersFields));
  } catch (error) {
    createError(error);
  }
}

function* ticketStatusFetch({
  payload,
}: ReturnType<typeof fetchTicketStatusRequest>) {
  try {
    const statuses: KeyValueOption[] = yield call(
      requests.fetchTicketStatuses,
      payload
    );
    yield put(fetchTicketStatusSuccess(statuses));
  } catch (error) {
    createError(error);
  }
}

function* nextTicketStatusFetch({
  payload,
}: ReturnType<typeof fetchNextTicketStatusRequest>) {
  try {
    const nextStatuses: KeyValueOption[] = yield call(
      requests.fetchTicketStatuses,
      payload
    );
    yield put(fetchNextTicketStatusSuccess(nextStatuses));
  } catch (error) {
    createError(error);
  }
}

function* triggersFetch() {
  try {
    const {
      pageNum,
      pageSize,
      sortTriggers,
    }: ReturnType<typeof getPropsTriggers> = yield select(getPropsTriggers);
    const filter: ReturnType<typeof getFilterValues> = yield select(
      getFilterValues
    );
    yield put(showTriggersLoading());

    const triggers: ResponseWithMeta<Trigger[]> = yield call(
      requests.fetchTriggers,
      pageNum,
      pageSize,
      sortTriggers,
      filter
    );
    yield put(fetchTriggersSuccess(triggers));
  } catch (e) {
    createError(e);
  } finally {
    yield put(hideTriggersLoading());
  }
}

function* currentTriggerFetch({
  payload,
}: ReturnType<typeof fetchCurrentTrigger>) {
  try {
    const trigger: Trigger = yield call(requests.fetchCurrentTrigger, payload);
    const customFields: ReturnType<typeof getCustomFields> = yield select(
      getCustomFields
    );

    const attributes = {
      organizations: [trigger.organization.key],
      systems: [trigger.system.key],
      status: [trigger.status.key],
      ticketTypes: getKeysFromKeyValue(trigger.ticketTypes),
      ticketPriorities: getKeysFromKeyValue(trigger.ticketPriorities),
      environments: getKeysFromKeyValue(trigger.environments),
      clients: getKeysFromKeyValue(trigger.clients),
    };

    yield call(attributesFieldsFetch, {
      type: fetchAttributesFieldsRequest.type,
      payload: attributes,
    });

    if (!!customFields && trigger.system.key) {
      const fields: CustomField[] = yield call(
        requests.fetchCustomField,
        trigger.system.key
      );
      yield put(fetchCustomFieldsSuccess(fields));
    }

    yield put(setCurrentTriggerSuccess(trigger));
  } catch (e) {
    createError(e);
  }
}

function* customFieldFetch({
  payload,
}: ReturnType<typeof fetchCustomFieldsRequest>) {
  try {
    const fields: CustomField[] = yield call(
      requests.fetchCustomField,
      payload
    );
    yield put(fetchCustomFieldsSuccess(fields));
  } catch (e) {
    createError(e);
  }
}

function* triggerCreate({ payload }: ReturnType<typeof createTrigger>) {
  try {
    const { pageSize, sortTriggers }: ReturnType<typeof getPropsTriggers> =
      yield select(getPropsTriggers);
    const filter: ReturnType<typeof getFilterValues> = yield select(
      getFilterValues
    );
    const trigger: Trigger = yield call(requests.createTrigger, payload);
    yield put(
      setAlert(
        createSuccessAlert(
          `Успешно выполнено создание триггера ${trigger.title}.`
        )
      )
    );
    yield put(showTriggersLoading());

    const triggers: ResponseWithMeta<Trigger[]> = yield call(
      requests.fetchTriggers,
      0,
      pageSize,
      sortTriggers,
      filter
    );
    yield put(fetchTriggersSuccess(triggers));

    yield call(attributesFieldsFetch, {
      type: fetchAttributesFieldsRequest.type,
      payload: INITIAL_ATTRIBUTES_VALUES,
    });
  } catch (e) {
    createError(e);
  } finally {
    yield put(hideTriggersLoading());
  }
}

function* triggerUpdate({ payload }: ReturnType<typeof createTrigger>) {
  try {
    const id: ReturnType<typeof getCurrentTriggerId> = yield select(
      getCurrentTriggerId
    );
    if (id) {
      const trigger: Trigger = yield call(requests.updateTrigger, payload, id);
      yield put(setCurrentTriggerId(trigger.id));
      yield put(setCurrentTriggerSuccess(trigger));
      yield put(
        setAlert(
          createSuccessAlert(`Триггер ${trigger.title} успешно отредактирован`)
        )
      );
    }

    yield call(triggersFetch);
  } catch (e) {
    createError(e);
  }
}

function* deleteTrigger({ payload }: ReturnType<typeof deleteTriggerRequest>) {
  try {
    yield call(requests.deleteTrigger, payload);
    yield put(setAlert(createSuccessAlert(`Триггер успешно удален`)));
    yield put(resetCurrentTrigger());
    yield call(triggersFetch);
    yield call(attributesFieldsFetch, {
      type: fetchAttributesFieldsRequest.type,
      payload: INITIAL_ATTRIBUTES_VALUES,
    });
  } catch (e) {
    createError(e);
  }
}

export function* triggersSaga() {
  yield takeEvery(fetchFiltersFieldsRequest.type, filterFieldsFetch);
  yield takeEvery(fetchAttributesFieldsRequest.type, attributesFieldsFetch);
  yield takeEvery(fetchTicketStatusRequest.type, ticketStatusFetch);
  yield takeEvery(fetchNextTicketStatusRequest.type, nextTicketStatusFetch);
  yield takeEvery(fetchTriggersRequest.type, triggersFetch);
  yield takeEvery(fetchCurrentTrigger.type, currentTriggerFetch);
  yield takeEvery(fetchCustomFieldsRequest.type, customFieldFetch);
  yield takeEvery(createTrigger.type, triggerCreate);
  yield takeEvery(deleteTriggerRequest.type, deleteTrigger);
  yield takeEvery(updateTrigger.type, triggerUpdate);
}
