import { FC } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
  TableWorkGroupsTypes,
  WorkGroup,
  WorkGroupFilter,
} from '@entities/work-group';
import { Table } from '@shared';

import { fetchFunctions, WORK_GROUPS_FIELDS_SHORT } from '../../config';
import {
  getLoading,
  setCurrentWorkGroupsPage,
  setWorkGroupsFilter,
} from '../../model';
import { WorkGroupsFilter } from '../WorkGroupsFilter';

import { getDataTable } from './utils';

interface WorkGroupsShortTableProps {
  workGroups?: WorkGroup[];
  sort?: string;
  handleSort?: (value: string) => void;
  handleRowClick?: (value: string) => void;
  showFilterRow?: boolean;
  isFilterActive?: boolean;
  activeRowId?: string;
  tableType: TableWorkGroupsTypes;
  customLoading?: boolean;
  customFilter?: JSX.Element;
}

export const WorkGroupsShortTable: FC<WorkGroupsShortTableProps> = ({
  workGroups,
  sort,
  handleSort,
  handleRowClick,
  showFilterRow,
  isFilterActive,
  activeRowId,
  tableType,
  customLoading = false,
  customFilter,
}) => {
  const dispatch = useDispatch();

  const data = getDataTable(workGroups);

  const handleFilter = (filterData: WorkGroupFilter) => {
    dispatch(setCurrentWorkGroupsPage(0));
    dispatch(setWorkGroupsFilter(filterData));
    dispatch(fetchFunctions[tableType]());
  };

  const loading = useSelector(getLoading);

  const filterComponent = customFilter || (
    <WorkGroupsFilter
      tableType={TableWorkGroupsTypes.FROM_ORGANIZATIONS}
      onFilter={handleFilter}
    />
  );

  return (
    <Table
      data={data}
      columns={WORK_GROUPS_FIELDS_SHORT}
      onRowClick={handleRowClick}
      sort={sort}
      onSort={handleSort}
      dataLoading={loading || customLoading}
      isFilterActive={isFilterActive}
      activeRowId={activeRowId}
      valueFieldName="id"
      filterComponent={showFilterRow && filterComponent}
      disabledSort={loading}
    />
  );
};
