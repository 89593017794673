import { call, put, StrictEffect, takeEvery } from 'redux-saga/effects';

import { createError } from '@shared';

import { attachmentRequests } from '../../api/attachment';
import { notesRequests as requests } from '../../api/notes';
import { Note } from '../attachment/types';

import {
  createNoteRequest,
  fetchNotesByTicketIdRequest,
  fetchNotesByTicketIdSuccess,
  noteLoadingHide,
  noteLoadingShow,
} from './actions';
import { Notes } from './actionTypes';

function* noteCreate({ payload }: ReturnType<typeof createNoteRequest>) {
  try {
    const { note, attachmentsData, files } = payload;
    const newNote: Note = yield call(requests.createNote, note);
    if (attachmentsData && newNote.id && files?.has('file')) {
      attachmentsData.parentId = newNote.id;
      attachmentsData.ticketId = newNote.ticketId;
      files.append(
        'objects-attachments',
        new Blob([JSON.stringify(attachmentsData)], {
          type: 'application/json',
        })
      );
      yield call(attachmentRequests.addAttachment, files);
    }
    if (note?.ticketId) {
      yield put(fetchNotesByTicketIdRequest(note.ticketId));
    }
  } catch (e) {
    createError(e);
  }
}

function* fetchNotesByTicketId({
  payload,
}: ReturnType<typeof fetchNotesByTicketIdRequest>) {
  try {
    yield put(noteLoadingShow());
    const notes: Note[] = yield call(requests.fetchNotesByTicketId, payload);
    yield put(fetchNotesByTicketIdSuccess(notes));
    yield put(noteLoadingHide());
  } catch (e) {
    yield put(noteLoadingHide());
    createError(e);
  }
}

export function* notesSaga(): Generator<StrictEffect> {
  yield takeEvery(Notes.NOTE_CREATE_REQUEST, noteCreate);
  yield takeEvery(Notes.FETCH_NOTES_BY_TICKET_ID_REQUEST, fetchNotesByTicketId);
}
