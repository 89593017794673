import { EnableFields } from '../model';

export const ENABLE_FIELDS: EnableFields = {
  all: true,
  status: true,
  priority: true,
  description: true,
  solutions: true,
  client: true,
  performer: true,
  system: true,
  ticketType: true,
  environment: true,
  organization: true,
  workgroup: true,
  dateCreation: true,
  dateSolve: true,
  sla: true,
  sources: true,
  customFields: false,
};
