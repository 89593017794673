import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';

import {
  ActionList,
  getIsAccessedAction,
  getIsClient,
  getIsSpecialist,
  getManagerWorkGroup,
} from '@entities/auth';
import {
  fetchTicketsRequestCurrentState,
  fetchTicketsRequestHistory,
  getCurrentStatePageNumber,
  getCurrentStatePageSize,
  getCurrentStateSort,
  getCurrentStateTickets,
  getCurrentStateTotalTickets,
  getHistoryPageNumber,
  getHistoryPageSize,
  getHistorySort,
  getHistoryTickets,
  getHistoryTotalTickets,
  getIsCurrentStateTicketsLoading,
  getIsHistoryTicketsLoading,
  setPageNumCurrentState,
  setPageNumHistory,
  setPageSizeCurrentState,
  setPageSizeHistory,
  setSortCurrentState,
  setSortHistory,
} from '@entities/desktop';
import {
  getNotificationsMyTicketsIds,
  getNotificationsNewTicketsIds,
} from '@entities/notifications';
import { LocationState, TableTicketsTypes } from '@entities/ticket';
import {
  deleteTicketRequest,
  fetchTicketRequest,
  fetchTicketsRequest,
  fetchTicketsSettingFieldsRequest,
  getCurrentTicket,
  getCurrentTicketId,
  getIsSearchOpen,
  getLoading,
  getPageNum,
  getPageSize,
  getSort,
  getTicketLoading,
  getTickets,
  getTotalElements,
  setCurrentTicket,
  setCurrentTicketId,
  setCurrentTicketPage,
  setSizePage,
  setSortTickets,
  toggleTicketsSearch,
} from '@entities/tickets';
import { getIsExportPanelOpen } from '@entities/tickets-export';
import {
  checkPathnameWithTrailingSlash,
  getScreenWidth,
  Languages,
  RouterHref,
  ScreenWidth,
  Tab,
} from '@shared';

import { getCurrentTableType, getTicketsTabs } from '../utils';

// eslint-disable-next-line sonarjs/cognitive-complexity
export const useTicketsTableContainer = (tableType: TableTicketsTypes) => {
  const { i18n } = useTranslation('tickets');
  const { push } = useHistory();
  const { pathname, state } = useLocation<LocationState>();

  const currentTicketId = useSelector(getCurrentTicketId);
  const ticket = useSelector(getCurrentTicket);
  const isSearchOpen = useSelector(getIsSearchOpen);
  const screenWidth = useSelector(getScreenWidth);
  const pageNum = useSelector(getPageNum);
  const pageSize = useSelector(getPageSize);
  const totalElements = useSelector(getTotalElements);
  const tickets = useSelector(getTickets);
  const ticketsHistory = useSelector(getHistoryTickets);
  const totalTicketsHistory = useSelector(getHistoryTotalTickets);
  const pageNumHistory = useSelector(getHistoryPageNumber);
  const pageSizeHistory = useSelector(getHistoryPageSize);
  const sortHistory = useSelector(getHistorySort);
  const ticketsCurrentState = useSelector(getCurrentStateTickets);
  const totalTicketsCurrentState = useSelector(getCurrentStateTotalTickets);
  const pageNumCurrentState = useSelector(getCurrentStatePageNumber);
  const pageSizeCurrentState = useSelector(getCurrentStatePageSize);
  const sortCurrentState = useSelector(getCurrentStateSort);
  const isClient = useSelector(getIsClient);
  const isSpecialist = useSelector(getIsSpecialist);
  const isWorkGroupManager = useSelector(getManagerWorkGroup);
  const loading = useSelector(getLoading);
  const ticketLoading = useSelector(getTicketLoading);
  const ticketsHistoryLoading = useSelector(getIsHistoryTicketsLoading);
  const ticketsCurrentStateLoading = useSelector(
    getIsCurrentStateTicketsLoading
  );
  const sort = useSelector(getSort);
  const notificationsMyTicketsIds = useSelector(getNotificationsMyTicketsIds);
  const notificationsNewTicketsIds = useSelector(getNotificationsNewTicketsIds);
  const isExportPanelOpen = useSelector(getIsExportPanelOpen);
  const isAccessToCreateTicket = useSelector(
    getIsAccessedAction(ActionList.CreateTicket)
  );
  const isAccessToViewMyGroupTickets = useSelector(
    getIsAccessedAction(ActionList.ViewMyGroupTickets)
  );
  const isAccessToViewingAllTickets = useSelector(
    getIsAccessedAction(ActionList.ViewingAllTickets)
  );

  const isMobile = screenWidth === ScreenWidth.MOBILE;
  const isMobileSmall = screenWidth === ScreenWidth.MOBILE_SMALL;
  const isMobileAll = isMobile || isMobileSmall;

  const isMyTicketsPage = checkPathnameWithTrailingSlash(
    pathname,
    RouterHref.Tickets
  );
  const isAllTicketsPage = checkPathnameWithTrailingSlash(
    pathname,
    RouterHref.TicketsAll
  );
  const isGroupsTicketsPage = checkPathnameWithTrailingSlash(
    pathname,
    RouterHref.TicketsGroup
  );
  const isDesktopPage = tableType === TableTicketsTypes.FROM_DESKTOP;
  const isDesktopHistoryPage =
    tableType === TableTicketsTypes.FROM_DESKTOP_HISTORY;

  const isRU = i18n.language.includes(Languages.RU);

  const isDesktop = isDesktopPage || isDesktopHistoryPage;

  const isTicketsPage =
    isMyTicketsPage || isAllTicketsPage || isGroupsTicketsPage;

  const ticketsDesktop = isDesktopPage ? ticketsCurrentState : ticketsHistory;
  const totalTicketsDesktop = isDesktopPage
    ? totalTicketsCurrentState
    : totalTicketsHistory;
  const ticketsDesktopLoading = isDesktopPage
    ? ticketsCurrentStateLoading
    : ticketsHistoryLoading;
  const pageNumberDesktop = isDesktopPage
    ? pageNumCurrentState
    : pageNumHistory;
  const pageSizeDesktop = isDesktopPage
    ? pageSizeCurrentState
    : pageSizeHistory;
  const sortDesktop = isDesktopPage ? sortCurrentState : sortHistory;

  const ticketTab = getCurrentTableType(pathname);

  const dispatch = useDispatch();

  const [tabs, setTabs] = useState<Tab[]>();

  const [showDeleteModal, setShowDeleteModal] = useState(false);

  const toggleDeleteModal = () => {
    setShowDeleteModal((prev) => !prev);
  };

  const handleDelete = () => {
    if (ticket) {
      dispatch(deleteTicketRequest(ticket));
    }
  };

  const handleShowModalDelete = () => {
    if (currentTicketId) {
      setShowDeleteModal(true);
    }
  };

  useEffect(() => {
    setTabs([
      getTicketsTabs.myTickets(isRU, notificationsMyTicketsIds?.length),
    ]);
    if (isAccessToViewMyGroupTickets || isAccessToViewingAllTickets) {
      setTabs(
        (prevState) =>
          prevState && [
            ...prevState,
            getTicketsTabs.allTickets(isRU, notificationsNewTicketsIds?.length),
          ]
      );
      if (isWorkGroupManager) {
        setTabs(
          (prevState) =>
            prevState && [...prevState, getTicketsTabs.groupTickets(isRU)]
        );
      }
    }
  }, [
    isWorkGroupManager,
    i18n.language,
    notificationsMyTicketsIds?.length,
    notificationsNewTicketsIds?.length,
    isAccessToViewMyGroupTickets,
    isAccessToViewingAllTickets,
  ]);

  useEffect(() => {
    if (isAllTicketsPage && state?.tagName) {
      dispatch(toggleTicketsSearch());
    }
  }, []);

  useEffect(() => {
    if (isTicketsPage) {
      dispatch(fetchTicketsSettingFieldsRequest());
    }
  }, [isTicketsPage]);

  const toggleSearch = () => {
    dispatch(toggleTicketsSearch());
  };

  const handleChangePage = (page: number) => {
    if (isDesktopPage) {
      dispatch(setPageNumCurrentState(page));
      dispatch(fetchTicketsRequestCurrentState());
    }
    if (isDesktopHistoryPage) {
      dispatch(setPageNumHistory(page));
      dispatch(fetchTicketsRequestHistory());
    }
    if (!isDesktop) {
      dispatch(setCurrentTicketPage(page));
      dispatch(fetchTicketsRequest({ ticketTab, withFirstTicketFetch: true }));
    }
  };

  const handleChangePageSize = (arg: number) => {
    if (isDesktopPage) {
      dispatch(setPageSizeCurrentState(arg));
    }
    if (isDesktopHistoryPage) {
      dispatch(setPageSizeHistory(arg));
    }
    if (!isDesktop) {
      dispatch(setSizePage(arg));
    }
  };

  const handleSort = (value: string) => {
    if (isDesktopPage) {
      dispatch(setSortCurrentState(value));
      dispatch(fetchTicketsRequestCurrentState());
    }
    if (isDesktopHistoryPage) {
      dispatch(setSortHistory(value));
      dispatch(fetchTicketsRequestHistory());
    }
    if (!isDesktop) {
      dispatch(setSortTickets(value));
      dispatch(fetchTicketsRequest({ ticketTab, withFirstTicketFetch: true }));
    }
  };

  const toggleCurrentTicket = (value: string) => {
    if (value) {
      if (value === currentTicketId && isMobileAll) {
        dispatch(setCurrentTicketId(undefined));
        dispatch(setCurrentTicket(undefined));
        return;
      }
      if (value !== currentTicketId) {
        dispatch(setCurrentTicketId(value));
        dispatch(fetchTicketRequest(value));
      }
    }
  };

  const goToCreateTicketPage = () => {
    push(RouterHref.CreateTicket);
  };

  const isTicketsByContractIdsTable =
    tableType === TableTicketsTypes.BY_CONTRACT_IDS;
  const isTicketsFromDesktopTable =
    tableType === TableTicketsTypes.FROM_DESKTOP;
  const isTicketsFromDesktopHistoryTable =
    tableType === TableTicketsTypes.FROM_DESKTOP_HISTORY;
  const isTicketsFullTable = tableType === TableTicketsTypes.FULL;

  return {
    methods: {
      toggleDeleteModal,
      handleDelete,
      handleShowModalDelete,
      toggleSearch,
      handleChangePage,
      handleChangePageSize,
      handleSort,
      toggleCurrentTicket,
      goToCreateTicketPage,
    },
    state: {
      isTicketsByContractIdsTable,
      isTicketsFromDesktopTable,
      isTicketsFromDesktopHistoryTable,
      isTicketsFullTable,
      isSpecialist,
      isClient,
      ticket,
      isSearchOpen,
      pageNum: isDesktop ? pageNumberDesktop : pageNum,
      pageSize: isDesktop ? pageSizeDesktop : pageSize,
      totalElements: isDesktop ? totalTicketsDesktop : totalElements,
      tickets: isDesktop ? ticketsDesktop : tickets,
      desktopTickets: ticketsDesktop,
      loading: isDesktop ? ticketsDesktopLoading : loading,
      sort: isDesktop ? sortDesktop : sort,
      isAccessToCreateTicket,
      isMyTicketsPage,
      tabs,
      showDeleteModal,
      isExportPanelOpen,
      ticketLoading: isDesktop ? ticketsDesktopLoading : ticketLoading,
      isTicketsPage,
    },
  };
};
