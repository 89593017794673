import { OrganizationType } from '@entities/organizations/model/types';
import {
  fetchUsersClientsSuccessJoin,
  fetchUsersClientsSuccessUpdate,
  fetchUsersSpecialistsSuccessJoinAction,
  fetchUsersSpecialistsSuccessUpdate,
} from '@entities/users/model/actions';

export const fetchUsersSuccessMap = {
  [OrganizationType.CUSTOMER]: {
    join: fetchUsersClientsSuccessJoin,
    update: fetchUsersClientsSuccessUpdate,
  },
  [OrganizationType.SERVICE]: {
    join: fetchUsersSpecialistsSuccessJoinAction,
    update: fetchUsersSpecialistsSuccessUpdate,
  },
  [OrganizationType.PARTNER]: {
    join: () => {},
    update: () => {},
  },
};
