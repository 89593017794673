import { Attachment, ParentType } from '@entities/attachment/model/types';
import { Article } from '@entities/knowledge-base/model/types';
import { Ticket } from '@entities/ticket/model/types';
import { ApiClient, getEnv, ResponseWithMeta } from '@shared';

import { SearchData } from '../model/types';

import { endpoints } from './endpoints';

export const requests = {
  fetchTickets: (searchData: SearchData) =>
    ApiClient.post<ResponseWithMeta<Ticket[]>>({
      url: endpoints.getTickets(),
      params: { pageNum: 0, pageSize: 1000 },
      data: searchData,
    }),
  fetchArticles: (searchData: SearchData) =>
    ApiClient.post<ResponseWithMeta<Article[]>>({
      baseURL: getEnv('REACT_APP_KNOWLEDGE_BASE_URL'),
      url: endpoints.getArticles(),
      params: { pageNum: 0, pageSize: 1000 },
      data: searchData,
    }),
  fetchTicket: (id: string) =>
    ApiClient.get<Ticket>({
      url: endpoints.getTicket(id),
    }),
  fetchArticle: (id: string) =>
    ApiClient.get<Article>({
      baseURL: getEnv('REACT_APP_KNOWLEDGE_BASE_URL'),
      url: endpoints.getArticle(id),
    }),
  fetchTicketAttachments: (ticketId: string) =>
    ApiClient.get<Attachment[]>({
      baseURL: getEnv('REACT_APP_ATTACHMENT_URL'),
      url: endpoints.getTicketAttachments(),
      params: {
        parent: ParentType.TICKET,
        id: ticketId,
      },
    }),
};
