import {
  CheckedPriorityType,
  SLARowType,
  SLASettings,
  SLATableDataType,
} from '@entities/sla';
import { Priority, TicketTypeSettings } from '@entities/ticket';

import { getActiveTicketTypes } from '../../../../lib/utils';

const checkValidSettingsValue = (value?: string | number) =>
  Number.isNaN(Number(value))
    ? !(value?.toString().includes('_') || value?.toString() === '00:00')
    : Boolean(value);

const checkValidSettings = (data: SLASettings) =>
  checkValidSettingsValue(data?.decisionTime) &&
  checkValidSettingsValue(data?.reactionTime);

const checkValidRow = (data: SLARowType) => {
  const ticketTypes = Object.keys(data);
  return ticketTypes.reduce(
    (isValidRow, type) => isValidRow && checkValidSettings(data[type]),
    true
  );
};

type Args = {
  data: SLATableDataType;
  checkedRows: CheckedPriorityType;
  ticketTypes?: TicketTypeSettings[];
};

export const checkValidTableSLA = ({
  data,
  checkedRows,
  ticketTypes,
}: Args) => {
  const activeTicketTypes = getActiveTicketTypes(ticketTypes);
  const priorities = Object.keys(data);
  const checkedKeys = Object.entries(checkedRows)
    .filter(([, isChecked]) => isChecked)
    ?.map(([priority]) => priority);
  const checkedAndFilled = checkedKeys.every((key) => priorities.includes(key));

  if (!priorities.length || !checkedKeys.length || !checkedAndFilled) {
    return false;
  }

  return checkedKeys.every((key: string) => {
    const checkedPriority = data[key as Priority];
    const existTicketType = activeTicketTypes?.every((item) => {
      const activeTicketType = checkedPriority[item];
      return activeTicketType?.decisionTime && activeTicketType?.reactionTime;
    });

    if (!existTicketType) {
      return false;
    }

    if (checkedPriority && priorities.includes(key)) {
      return checkValidRow(checkedPriority);
    }

    return true;
  });
};
