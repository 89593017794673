import { StorageCustomField } from '@entities/storage';

export const getCustomFieldsForRequest = (
  customFields?: StorageCustomField[]
) =>
  customFields?.map((field) => {
    const { id, text = null, flag = null, date = null } = field;
    return {
      customFieldId: id,
      text,
      flag,
      date,
    };
  });
