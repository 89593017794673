import { useSelector } from 'react-redux';

import { ActionList, getIsAccessedAction } from '@entities/auth';
import { DesktopHeader, DesktopKpi } from '@features/desktop';
import { MainLayout } from '@shared';

const DesktopKpiPage = () => {
  const isAccessToCreateTicket = useSelector(
    getIsAccessedAction(ActionList.CreateTicket)
  );

  return (
    <MainLayout
      customHeader={<DesktopHeader />}
      accessToCreateTicket={isAccessToCreateTicket}
    >
      <DesktopKpi />
    </MainLayout>
  );
};

export default DesktopKpiPage;
