import { FC } from 'react';
import { useSelector } from 'react-redux';

import { Contract, TableContractsTypes } from '@entities/contract';
import { getLoading } from '@entities/contracts';
import { getLoading as getSystemsLoading } from '@entities/systems';
import { Table } from '@shared';

import { CONTRACTS_FULL_FIELDS } from '../../config';
import { ContractsFilter } from '../ContractsFilter';

import { getDataTable } from './utils';

interface ContractsFullTableProps {
  contracts?: Contract[];
  sort?: string;
  handleSort?: (value: string) => void;
  handleRowClick?: (value: string) => void;
  activeRowId?: string;
  showFilterRow?: boolean;
  isFilterActive?: boolean;
}

export const ContractsFullTable: FC<ContractsFullTableProps> = ({
  contracts,
  sort,
  handleSort,
  handleRowClick,
  activeRowId,
  showFilterRow,
  isFilterActive,
}) => {
  const data = getDataTable(contracts);

  const loading = useSelector(getLoading);
  const systemsLoading = useSelector(getSystemsLoading);

  return (
    <Table
      data={data}
      columns={CONTRACTS_FULL_FIELDS}
      sort={sort}
      onRowClick={handleRowClick}
      onSort={handleSort}
      activeRowId={activeRowId}
      dataLoading={loading}
      isFilterActive={isFilterActive}
      valueFieldName="id"
      filterComponent={
        showFilterRow && (
          <ContractsFilter tableType={TableContractsTypes.FULL} />
        )
      }
      disabledSort={loading}
      disabledRows={systemsLoading}
    />
  );
};
