import { FC, MouseEvent } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { ActionList, getIsAccessedAction } from '@entities/auth';
import { getLoading as getResponsibilitiesLoading } from '@entities/responsibilities';
import {
  getLoading,
  Role,
  RolesFilter as RolesFilterData,
  RoleType,
  setCurrentRolesPage,
  setRolesFilter,
  TableRolesTypes,
} from '@entities/roles';
import { CheckMarkIcon, NotificationLink, Table } from '@shared';

import { ROLE_TYPE_TITLE, ROLES_FULL_FIELDS } from '../../config';
import { getFetchFunction } from '../../lib';
import { RolesFilter } from '../RolesFilter';

import styles from './RolesFullTable.module.scss';

interface DataTableConfig {
  roles?: Role[];
  isAccessToViewingCompany: boolean;
  roleClick: (event: MouseEvent<HTMLButtonElement>) => void;
}

const getDataTable = ({
  roles,
  isAccessToViewingCompany,
  roleClick,
}: DataTableConfig) =>
  roles?.map((role) => {
    const { name, description, id, type, manager, organization, readOnly } =
      role;
    return {
      id,
      name: (
        <button onClick={roleClick} className={styles.rolesFullTable__title}>
          {name}
        </button>
      ),
      description,
      type: {
        text: ROLE_TYPE_TITLE[type],
        className:
          type === RoleType.ROLE_IN_GROUP
            ? styles.rolesFullTableType_group
            : styles.rolesFullTableType_system,
      },
      manager: manager ? (
        <div className={styles.rolesFullTable_manager}>
          <CheckMarkIcon />
        </div>
      ) : (
        ' '
      ),
      organization: isAccessToViewingCompany ? (
        <NotificationLink
          to={`/admin/organizations/${organization?.id}`}
          title={organization?.title}
        />
      ) : (
        organization?.title
      ),
      disabled: readOnly,
    };
  }) || [];

interface RolesFullTableProps {
  roles?: Role[];
  sort?: string;
  handleSort?: (value: string) => void;
  handleRowClick?: (value: string) => void;
  activeRowId?: string;
  showFilterRow?: boolean;
  isFilterActive?: boolean;
  handleClickRoleEdit?: () => void;
}

export const RolesFullTable: FC<RolesFullTableProps> = ({
  roles,
  sort,
  handleSort,
  handleRowClick,
  activeRowId,
  showFilterRow,
  isFilterActive,
  handleClickRoleEdit,
}) => {
  const isAccessToViewingCompany = useSelector(
    getIsAccessedAction(ActionList.ViewingCompanyAdministration)
  );
  const loading = useSelector(getLoading);
  const responsibilitiesLoading = useSelector(getResponsibilitiesLoading);

  const dispatch = useDispatch();

  const onRoleClick = (event: MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    if (handleClickRoleEdit) {
      handleClickRoleEdit();
    }
  };

  const data = getDataTable({
    roles,
    isAccessToViewingCompany,
    roleClick: onRoleClick,
  });

  const handleFilter = (filter: RolesFilterData) => {
    dispatch(setRolesFilter(filter));
    dispatch(setCurrentRolesPage(0));
    dispatch(getFetchFunction(TableRolesTypes.FULL)());
  };

  return (
    <Table
      data={data}
      columns={ROLES_FULL_FIELDS}
      sort={sort}
      onRowClick={handleRowClick}
      onSort={handleSort}
      activeRowId={activeRowId}
      dataLoading={loading}
      isFilterActive={isFilterActive}
      valueFieldName="id"
      filterComponent={
        showFilterRow && (
          <RolesFilter
            tableType={TableRolesTypes.FULL}
            onFilter={handleFilter}
          />
        )
      }
      disabledSort={loading}
      disabledRows={responsibilitiesLoading}
    />
  );
};
