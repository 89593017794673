import { call, put, select, takeEvery } from 'redux-saga/effects';

import { Article } from '@entities/knowledge-base/model/types';
import { requests as requestsSimilarSolutions } from '@entities/similar-solutions/api';
import { createError, getHref, ResponseWithMeta } from '@shared';

import { requests } from '../api';

import {
  addArticlesRequest,
  deleteArticleRequest,
  fetchArticleRequest,
  fetchArticlesAllRequest,
  fetchArticlesAllSuccess,
  fetchArticlesRequest,
  fetchArticlesSuccess,
  fetchArticleSuccess,
  setArticleLoading,
  setArticlesAllLoading,
  setArticlesLoading,
} from './slice';

function* articlesFetch() {
  try {
    const href: ReturnType<typeof getHref> = yield select(getHref);
    yield put(setArticlesLoading(true));
    const articles: ResponseWithMeta<Article[]> = yield call(
      requests.fetchArticles,
      href
    );
    yield put(fetchArticlesSuccess(articles));
    yield put(setArticlesLoading(false));
  } catch (error) {
    yield put(setArticlesLoading(false));
    createError(error);
  }
}

function* articlesAllFetch({
  payload,
}: ReturnType<typeof fetchArticlesAllRequest>) {
  try {
    yield put(setArticlesAllLoading(true));
    const articles: ResponseWithMeta<Article[]> = yield call(
      requestsSimilarSolutions.fetchArticles,
      { onlyTitleSearch: false, queryText: payload }
    );
    yield put(fetchArticlesAllSuccess(articles));
    yield put(setArticlesAllLoading(false));
  } catch (error) {
    yield put(setArticlesAllLoading(false));
    createError(error);
  }
}

function* articleFetch({ payload }: ReturnType<typeof fetchArticleRequest>) {
  try {
    yield put(setArticleLoading(true));
    const article: Article = yield call(requests.fetchArticle, payload);
    yield put(fetchArticleSuccess(article));
    yield put(setArticleLoading(false));
  } catch (error) {
    yield put(setArticleLoading(false));
    createError(error);
  }
}

function* addArticles({ payload }: ReturnType<typeof addArticlesRequest>) {
  try {
    const href: ReturnType<typeof getHref> = yield select(getHref);
    yield call(requests.manageArticles, true, payload.articlesIds, href);
    yield call(payload.toggleIsAddMode);
  } catch (error) {
    createError(error);
  }
}

function* deleteArticle({ payload }: ReturnType<typeof deleteArticleRequest>) {
  try {
    const href: ReturnType<typeof getHref> = yield select(getHref);
    yield call(requests.manageArticles, false, [payload], href);
    yield call(articlesFetch);
  } catch (error) {
    createError(error);
  }
}

export function* userAssistanceSaga() {
  yield takeEvery(fetchArticlesRequest.type, articlesFetch);
  yield takeEvery(fetchArticlesAllRequest.type, articlesAllFetch);
  yield takeEvery(fetchArticleRequest.type, articleFetch);
  yield takeEvery(addArticlesRequest.type, addArticles);
  yield takeEvery(deleteArticleRequest.type, deleteArticle);
}
