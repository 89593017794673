import { Reducer } from 'redux';

import { DEFAULT_PAGINATION_PARAMS, PaginationType, ReducerMap } from '@shared';

import {
  FetchOrganizationsAddJoinSuccessAction,
  FetchOrganizationsAddSuccessAction,
  FetchOrganizationsForReportSuccessAction,
  FetchOrganizationsJoinSuccessAction,
  FetchOrganizationsSuccessAction,
  Organizations,
  OrganizationsActionsTypes,
  SetCurrentOrganizationIdAction,
  SetCurrentOrganizationsAddPageAction,
  SetCurrentOrganizationsPageAction,
  SetCurrentOrganizationSuccessAction,
  SetFilterOrganizationIdAction,
  SetOrganizationsAddFilterAction,
  SetOrganizationsFilterAction,
  SetSizePageAction,
  SetSizePageAddAction,
  SetSortOrganizationsAction,
  SetSortOrganizationsAddAction,
} from './actionTypes';
import { Organization, OrganizationsFilter } from './types';

export interface OrganizationReducerState {
  organizations?: Organization[];
  organization?: Organization;
  loading?: boolean;
  loadingAdd?: boolean;
  loadingOrganization?: boolean;
  pagination?: PaginationType;
  sort?: string;
  organizationId?: string;
  filter?: OrganizationsFilter;
  organizationsAdd?: Organization[];
  paginationAdd?: PaginationType;
  sortAdd?: string;
  filterAdd?: OrganizationsFilter;
  filterOrganizationId?: string;
}

const initialState: OrganizationReducerState = {
  organizations: [],
  loading: false,
  loadingAdd: false,
  loadingOrganization: false,
  pagination: DEFAULT_PAGINATION_PARAMS,
  sort: 'date_create_DESC',
  filter: {},
  paginationAdd: DEFAULT_PAGINATION_PARAMS,
  sortAdd: 'date_create_DESC',
  filterAdd: {},
  organizationsAdd: [],
};

const organizationReducerMap: ReducerMap<
  OrganizationReducerState,
  OrganizationsActionsTypes
> = {
  [Organizations.ORGANIZATIONS_LOADING_SHOW]: (state) => ({
    ...state,
    loading: true,
  }),
  [Organizations.ORGANIZATIONS_LOADING_HIDE]: (state) => ({
    ...state,
    loading: false,
  }),
  [Organizations.ORGANIZATIONS_ADD_LOADING_SHOW]: (state) => ({
    ...state,
    loadingAdd: true,
  }),
  [Organizations.ORGANIZATIONS_ADD_LOADING_HIDE]: (state) => ({
    ...state,
    loadingAdd: false,
  }),
  [Organizations.ORGANIZATION_LOADING_SHOW]: (state) => ({
    ...state,
    loadingOrganization: true,
  }),
  [Organizations.ORGANIZATION_LOADING_HIDE]: (state) => ({
    ...state,
    loadingOrganization: false,
  }),
  [Organizations.FETCH_ORGANIZATIONS_SUCCESS]: (state, action) => {
    const { payload } = action as FetchOrganizationsSuccessAction;
    const { content, page, totalElements } = payload;
    return {
      ...state,
      organizations: content,
      pagination: {
        ...state?.pagination,
        pageNum: page,
        totalElements,
      },
    };
  },
  [Organizations.FETCH_ORGANIZATIONS_ADD_JOIN_SUCCESS]: (state, action) => {
    const { payload } = action as FetchOrganizationsAddJoinSuccessAction;
    const { content, page, totalElements } = payload;
    return {
      ...state,
      organizationsAdd: [...(state?.organizationsAdd || []), ...content],
      paginationAdd: {
        ...state?.pagination,
        pageNum: page,
        totalElements,
      },
    };
  },
  [Organizations.FETCH_ORGANIZATIONS_JOIN_SUCCESS]: (state, action) => {
    const { payload } = action as FetchOrganizationsJoinSuccessAction;
    const { content, page, totalElements } = payload;
    return {
      ...state,
      organizations: [...(state?.organizations || []), ...content],
      pagination: {
        ...state?.pagination,
        pageNum: page,
        totalElements,
      },
    };
  },
  [Organizations.SET_CURRENT_ORGANIZATIONS_PAGE]: (state, action) => {
    const { payload } = action as SetCurrentOrganizationsPageAction;
    return {
      ...state,
      pagination: {
        ...state?.pagination,
        pageNum: payload,
      },
    };
  },
  [Organizations.SET_SIZE_PAGE_ORGANIZATIONS]: (state, action) => {
    const { payload } = action as SetSizePageAction;
    return {
      ...state,
      pagination: {
        ...state?.pagination,
        pageSize: payload,
      },
    };
  },
  [Organizations.SET_SORT_ORGANIZATIONS]: (state, action) => {
    const { payload } = action as SetSortOrganizationsAction;
    return {
      ...state,
      sort: payload,
    };
  },
  [Organizations.SET_CURRENT_ORGANIZATION_SUCCESS]: (state, action) => {
    const { payload } = action as SetCurrentOrganizationSuccessAction;
    return {
      ...state,
      organization: payload,
    };
  },
  [Organizations.RESET_ORGANIZATION_STATE]: () => ({
    ...initialState,
  }),
  [Organizations.SET_CURRENT_ORGANIZATION_ID]: (state, action) => {
    const { payload } = action as SetCurrentOrganizationIdAction;
    return {
      ...state,
      organizationId: payload,
    };
  },
  [Organizations.SET_ORGANIZATIONS_FILTER]: (state, action) => {
    const { payload } = action as SetOrganizationsFilterAction;
    return {
      ...state,
      filter: payload,
    };
  },

  [Organizations.FETCH_ORGANIZATIONS_ADD_SUCCESS]: (state, action) => {
    const { payload } = action as FetchOrganizationsAddSuccessAction;
    const { content, page, totalElements } = payload;
    return {
      ...state,
      organizationsAdd: content,
      paginationAdd: {
        ...state?.paginationAdd,
        pageNum: page,
        totalElements,
      },
    };
  },
  [Organizations.SET_CURRENT_ORGANIZATIONS_ADD_PAGE]: (state, action) => {
    const { payload } = action as SetCurrentOrganizationsAddPageAction;
    return {
      ...state,
      paginationAdd: {
        ...state?.paginationAdd,
        pageNum: payload,
      },
    };
  },
  [Organizations.SET_SIZE_PAGE_ORGANIZATIONS_ADD]: (state, action) => {
    const { payload } = action as SetSizePageAddAction;
    return {
      ...state,
      paginationAdd: {
        ...state?.paginationAdd,
        pageSize: payload,
      },
    };
  },
  [Organizations.SET_SORT_ORGANIZATIONS_ADD]: (state, action) => {
    const { payload } = action as SetSortOrganizationsAddAction;
    return {
      ...state,
      sortAdd: payload,
    };
  },
  [Organizations.SET_ORGANIZATIONS_ADD_FILTER]: (state, action) => {
    const { payload } = action as SetOrganizationsAddFilterAction;
    return {
      ...state,
      filterAdd: payload,
    };
  },
  [Organizations.RESET_ORGANIZATION_ADD_STATE]: (state) => {
    const { organizationsAdd, sortAdd, paginationAdd, filterAdd } =
      initialState;
    return {
      ...state,
      organizationsAdd,
      sortAdd,
      paginationAdd,
      filterAdd,
    };
  },
  [Organizations.RESET_ORGANIZATION_ONLY_STATE]: (state) => {
    const { organizations, sort, pagination, filter } = initialState;
    return {
      ...state,
      organizations,
      sort,
      pagination,
      filter,
    };
  },
  [Organizations.SET_FILTER_ORGANIZATION_ID]: (state, action) => {
    const { payload } = action as SetFilterOrganizationIdAction;
    return {
      ...state,
      filterOrganizationId: payload,
    };
  },
  [Organizations.RESET_FILTER_ORGANIZATION_ID]: (state) => ({
    ...state,
    filterOrganizationId: undefined,
  }),
  [Organizations.FETCH_ORGANIZATIONS_FOR_REPORT_SUCCESS]: (state, action) => {
    const { payload } = action as FetchOrganizationsForReportSuccessAction;
    return {
      ...state,
      organizations: payload,
    };
  },
  [Organizations.RESET_CURRENT_ORGANIZATION]: (state) => ({
    ...state,
    organization: initialState.organization,
    organizationId: initialState.organizationId,
    pagination: initialState.pagination,
  }),
};

export const organizationsSlice: Reducer<
  OrganizationReducerState,
  OrganizationsActionsTypes
> = (state = initialState, action) => {
  const reducer = organizationReducerMap[action.type];
  if (!reducer) {
    return state;
  }
  return reducer(state, action);
};
