import { debounce } from 'lodash';
import { ChangeEvent, useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { ActionList, getIsAccessedAction } from '@entities/auth';
import {
  deleteStorageRequest,
  fetchStoragesRequest,
  getOrganizationsOptions,
  getPaginationParams,
  getStorages,
  getStoragesContent,
  resetStorages,
  resetStoragesContent,
} from '@entities/storages';
import {
  DEFAULT_DEBOUNCE_DELAY,
  getMultiOptionValues,
  SelectOption,
} from '@shared';

export const useStorages = () => {
  const dispatch = useDispatch();

  const [isOpenPanel, setIsOpenPanel] = useState(false);
  const [isOpenAddModal, setIsOpenAddModal] = useState(false);
  const [isOpenDeleteModal, setIsOpenDeleteModal] = useState(false);
  const [currentOrganization, setCurrentOrganization] = useState<
    SelectOption[]
  >([]);
  const [selectedStorages, setSelectedStorages] = useState<string[]>([]);
  const [panelType, setPanelType] = useState({
    create: false,
    edit: false,
  });
  const [search, setSearch] = useState('');

  const organizationsOptions = useSelector(getOrganizationsOptions);
  const storages = useSelector(getStorages);
  const storagesContent = useSelector(getStoragesContent);
  const {
    totalPages = 0,
    pageNum = 0,
    loading,
  } = useSelector(getPaginationParams);

  const selectedStorage = storagesContent?.find(
    (file) => file.id === selectedStorages[0]
  );
  const titleSelectedStorage = selectedStorage?.title;
  const isEmpty = selectedStorage?.empty;

  const isAccessCreateStorage = useSelector(
    getIsAccessedAction(ActionList.CreateStorage)
  );

  const deleteStorageDisabled = selectedStorages.length !== 1;

  const toggleCreatePanel = () => {
    setPanelType({ create: true, edit: false });
    setIsOpenPanel((prevState) => !prevState);
  };

  const toggleEditPanel = () => {
    setPanelType({ create: false, edit: true });
    setIsOpenPanel((prevState) => !prevState);
  };

  const toggleAddModal = () => {
    setIsOpenAddModal((prevState) => !prevState);
  };

  const toggleDeleteModal = () => {
    setIsOpenDeleteModal((prevState) => !prevState);
  };

  const handleChangeOrganization = (organizations: SelectOption[]) => {
    dispatch(resetStoragesContent());

    setCurrentOrganization(organizations);
  };

  const handleChangeSearch = debounce(
    (event: ChangeEvent<HTMLInputElement>) => {
      dispatch(resetStoragesContent());

      setSearch(event.target.value);
    },
    DEFAULT_DEBOUNCE_DELAY
  );

  const handleDeleteStorage = () => {
    if (isEmpty) {
      setIsOpenDeleteModal(true);
    }
    setSelectedStorages([]);
    dispatch(deleteStorageRequest(selectedStorages[0]));
  };

  const handleChangeCheckbox = (event: ChangeEvent<HTMLInputElement>) => {
    const { id } = event.target;

    if (selectedStorages.includes(id)) {
      const currentSelectedStorages = selectedStorages.filter(
        (storageId) => storageId !== id
      );
      return setSelectedStorages(currentSelectedStorages);
    }
    return setSelectedStorages([id, ...selectedStorages]);
  };

  const hasMore = useMemo(
    () => totalPages > pageNum + 1 && !loading,
    [totalPages, pageNum, loading]
  );

  const handleLoadMore = useCallback(
    (page: number) => {
      if (page < totalPages && !loading) {
        dispatch(
          fetchStoragesRequest({
            title: search,
            pageNum: page,
            organizationIds: getMultiOptionValues(currentOrganization),
          })
        );
      }
    },
    [totalPages, loading, currentOrganization, search]
  );

  useEffect(() => {
    dispatch(
      fetchStoragesRequest({
        title: search,
        organizationIds: getMultiOptionValues(currentOrganization),
      })
    );
  }, [currentOrganization, search]);

  useEffect(
    () => () => {
      setCurrentOrganization([]);
      dispatch(resetStorages());
    },
    []
  );

  return {
    state: {
      currentOrganization,
      isOpenPanel,
      organizationsOptions,
      storages,
      isAccessCreateStorage,
      selectedStorages,
      isOpenAddModal,
      isOpenDeleteModal,
      deleteStorageDisabled,
      titleSelectedStorage,
      isEmpty,
      panelType,
      selectedStorage,
      storagesLoading: loading,
    },
    methods: {
      handleChangeHeaderOrganization: handleChangeOrganization,
      toggleCreatePanel,
      toggleEditPanel,
      handleChangeSearch,
      handleChangeCheckbox,
      toggleAddModal,
      handleDeleteStorage,
      toggleDeleteModal,
      hasMore,
      handleLoadMore,
    },
  };
};
