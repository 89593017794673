import { ReadyState } from 'react-use-websocket';
import { v4 as uuidv4 } from 'uuid';

import { REOPEN_STATUS_SETTINGS } from '@entities/statuses-builder';
import { Status, VisibilityType } from '@entities/ticket';
import { ColorSchemeNew, SelectOption } from '@shared';

import { CreateNote } from '../model';

const PRIVATE = 'Видно только вам';
const WORKGROUPS = 'Видно всем';

export const VISIBILITY_OPTIONS: SelectOption<VisibilityType>[] = [
  {
    title: PRIVATE,
    value: VisibilityType.PRIVATE,
  },
  {
    title: WORKGROUPS,
    value: VisibilityType.WORKGROUPS,
  },
];

export const VISIBILITY_TYPE_MAP = {
  [VisibilityType.PRIVATE]: PRIVATE,
  [VisibilityType.WORKGROUPS]: WORKGROUPS,
};

export const COLOR_SCHEME_MAP: Record<VisibilityType, ColorSchemeNew> = {
  [VisibilityType.PRIVATE]: 'grey',
  [VisibilityType.WORKGROUPS]: 'blue',
};

export const NOTE_DEFAULT_VALUES: CreateNote = {
  text: '',
  visibility: VISIBILITY_OPTIONS[1],
};

export const REOPEN_STATUS: Status = {
  ...REOPEN_STATUS_SETTINGS,
  id: uuidv4(),
  name: REOPEN_STATUS_SETTINGS.label,
};

export const TEXT_CONTENT_TICKET_TYPE_EDITOR: Record<string, string> = {
  editTicketType: 'Изменить тип тикета',
  createTitle: 'Изменить',
  cancelTitle: 'Отменить',
  subModalText: 'изменение типа тикета',
};

export const DEFAULT_TEXT_TICKET_TOAST: Record<string, string> = {
  success: 'Успешно изменено',
  error: 'Что-то пошло не так...',
};

export const WS_SUCCESS = 'Чат успешно подключен';
export const WS_ERROR =
  'Ошибка подключения чата. Обратитесь к администратору или перезагрузите страницу';

export const WS_CONNECTION_STATUS: Record<ReadyState, string> = {
  [ReadyState.CONNECTING]: 'Connecting',
  [ReadyState.OPEN]: 'Open',
  [ReadyState.CLOSING]: 'Closing',
  [ReadyState.CLOSED]: 'Closed',
  [ReadyState.UNINSTANTIATED]: 'Uninstantiated',
};
