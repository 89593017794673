import cn from 'clsx';
import { FC } from 'react';

import { TableUsersTypes, User } from '@entities/user';
import {
  ConditionBlock,
  DeleteButton,
  PopoverContainer,
  RouterHref,
  TableContainer,
} from '@shared';

import { PAGINATION_RANGE_MAP } from '../../config';
import { useUsersTableContainer } from '../../lib';
import { UsersAddToGroup } from '../UsersAddToGroup';
import { UsersFromDesktopTable } from '../UsersFromDesktopTable';
import { UsersFromGroupsTable } from '../UsersFromGroupsTable';
import { UsersFullTable } from '../UsersFullTable';

import styles from './UsersTableContainer.module.scss';

interface UsersTableContainerProps {
  tableType: TableUsersTypes;
  title?: string;
  className?: string;
  withFilter?: boolean;
  usersList?: User[];
  totalUsers?: number;
}

export const UsersTableContainer: FC<UsersTableContainerProps> = ({
  tableType,
  title,
  className,
  withFilter = true,
  usersList,
  totalUsers,
}) => {
  const { methods, state } = useUsersTableContainer({
    tableType,
    usersList,
    totalUsers,
  });
  const {
    toggleDeleteModal,
    handleDelete,
    handleShowModalDelete,
    handleUserClick,
    handleChangePage,
    handleChangePageSize,
    handleSort,
    toggleFilterRow,
    toggleModal,
  } = methods;

  const {
    pageNum,
    pageSize,
    totalElements,
    sort,
    groupTitle,
    isAccessToDeleteUser,
    tableTitle,
    showDeleteModal,
    searchDisabled,
    getDisabledCreateBtn,
    createTableTitle,
    createIcon,
    currentGroupId,
    pathname,
    users,
    user,
    showFilterRow,
    isFilterActive,
    currentUserId,
    isModal,
    getCreateFunction,
    loadingUsers,
  } = state;

  const additionalHeaderComponent =
    tableType === TableUsersTypes.FROM_GROUPS &&
    currentGroupId &&
    pathname === RouterHref.AdminGroups ? (
      <ConditionBlock text={groupTitle} />
    ) : undefined;

  const getTableComponent: Record<TableUsersTypes, () => JSX.Element | null> = {
    [TableUsersTypes.FULL]: () => (
      <UsersFullTable
        users={users}
        handleRowClick={handleUserClick}
        activeRowId={currentUserId}
        sort={sort}
        handleSort={handleSort}
        showFilterRow={showFilterRow}
        isFilterActive={isFilterActive}
      />
    ),
    [TableUsersTypes.FROM_GROUPS]: () => (
      <UsersFromGroupsTable
        users={users}
        handleRowClick={handleUserClick}
        activeRowId={currentUserId}
        showFilterRow={showFilterRow}
        sort={sort}
        handleSort={handleSort}
        isFilterActive={isFilterActive}
      />
    ),
    [TableUsersTypes.FROM_DESKTOP]: () => (
      <UsersFromDesktopTable
        users={users}
        sort={sort}
        handleSort={handleSort}
        loading={loadingUsers}
      />
    ),
    [TableUsersTypes.ADD_MODAL]: () => null,
  };

  const getContent = () => getTableComponent[tableType]();

  const addUsersModal = isModal && (
    <UsersAddToGroup isModal={isModal} toggleModal={toggleModal} />
  );

  return (
    <TableContainer
      classNameRoot={cn(
        {
          [styles.usersTableContainer_full]: tableType === TableUsersTypes.FULL,
          [styles.usersTableContainer_fromGroup]:
            tableType === TableUsersTypes.FROM_GROUPS,
          [styles.usersTableContainer_fromDesktop]:
            tableType === TableUsersTypes.FROM_DESKTOP,
        },
        className
      )}
      headerProps={{
        title: title || tableTitle,
        createHandler: getCreateFunction[tableType],
        createTitle: createTableTitle,
        icon: createIcon,
        isSearchOpen: showFilterRow,
        additionalComponent: additionalHeaderComponent,
        searchDisabled,
        dropContent:
          tableType === TableUsersTypes.FULL && isAccessToDeleteUser ? (
            <PopoverContainer>
              <DeleteButton onClick={handleShowModalDelete} disabled={!user} />
            </PopoverContainer>
          ) : undefined,
        createDisabled:
          getDisabledCreateBtn[tableType as keyof typeof getDisabledCreateBtn],
        toggleSearch: withFilter ? toggleFilterRow : undefined,
        loading: loadingUsers,
      }}
      contentTable={
        <>
          {getContent()}
          {addUsersModal}
        </>
      }
      countRecordsProps={{
        records: totalElements,
      }}
      paginationProps={{
        pageNum,
        pageSize,
        elementsCount: totalElements,
        marginPagesDisplayed: PAGINATION_RANGE_MAP[tableType],
        handleChangePage,
        handleChangePageSize,
        disabled: loadingUsers,
        entityName: `USERS_${tableType}`,
      }}
      approveOrCancelProps={{
        onApprove: handleDelete,
        isModal: showDeleteModal,
        toggleModal: toggleDeleteModal,
        approveTitle: 'Удалить',
        approveBtnProps: {
          appearance: 'outline',
          className: styles.usersTableContainer__iconDelete,
        },
        cancelTitle: 'Отмена',
        text: `Вы уверены, что хотите удалить пользователя ${user?.firstName} ${user?.lastName}?`,
      }}
    />
  );
};
