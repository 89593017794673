import cn from 'clsx';
import { FC, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
  fetchRolesByUserIdRequest,
  resetRolesState,
  TableRolesTypes,
} from '@entities/roles';
import {
  fetchCurrentUser,
  getLoadingUser,
  getUser,
  resetUsersState,
  setUserId,
} from '@entities/users';
import { TableWorkGroupsTypes } from '@entities/work-group';
import {
  fetchGroupsByUserIdRequest,
  resetWorkGroupsState,
} from '@entities/work-groups';
import { RolesTableContainer } from '@features/roles';
import { useUserId } from '@features/user';
import { WorkGroupsTableContainer } from '@features/work-groups';
import { LazyLoader } from '@shared';

import { UserForm } from '../UserForm';

import styles from './User.module.scss';

interface UserProps {
  className?: string;
}

export const User: FC<UserProps> = ({ className }) => {
  const userId = useUserId();

  const loading = useSelector(getLoadingUser);
  const user = useSelector(getUser);

  const dispatch = useDispatch();

  useEffect(() => {
    if (userId) {
      dispatch(setUserId(userId));
      dispatch(fetchGroupsByUserIdRequest());
      dispatch(fetchRolesByUserIdRequest());
      dispatch(fetchCurrentUser(userId));
    }
  }, [userId]);

  useEffect(
    () => () => {
      dispatch(resetWorkGroupsState());
      dispatch(resetUsersState());
      dispatch(resetRolesState());
    },
    []
  );

  if (loading && !user) {
    return <LazyLoader className={styles.user__loader} />;
  }

  return (
    <div className={cn(styles.user, className)}>
      <div className={styles.user__leftContainer}>
        <UserForm />
      </div>
      <div className={styles.user__rightContainer}>
        <WorkGroupsTableContainer
          tableType={TableWorkGroupsTypes.FROM_USERS}
          className={styles.user__tableContainer}
        />
        <RolesTableContainer
          tableType={TableRolesTypes.FROM_USERS}
          className={styles.user__tableContainer}
        />
      </div>
    </div>
  );
};
