import {
  Attachment,
  AttachmentOthersFields,
  AttachmentsFiles,
} from '@entities/attachment/model/types';

export const getFileField = (
  file: AttachmentsFiles,
  attachment: Attachment | undefined
): AttachmentOthersFields => ({
  attachmentId: file?.attachmentId || attachment?.id,
  attachmentFileId: file?.attachmentFileId || file?.id,
  created: file?.created || attachment?.dateCreated,
});
