import {
  ChangeUserPasswordAction,
  CurrentUserTypes,
  FetchPermissionsRequestAction,
  FetchPermissionsSuccessAction,
  SetAuthorizedAction,
  SetCurrentUserAction,
} from './actionTypes';
import { CurrentUser, FetchPermissionsSuccessActionPayload } from './types';

export const setCurrentUser = (user: CurrentUser): SetCurrentUserAction => ({
  type: CurrentUserTypes.SET_CURRENT_USER,
  payload: user,
});

export const setAuthorized = (payload: boolean): SetAuthorizedAction => ({
  type: CurrentUserTypes.SET_AUTHORIZED,
  payload,
});

export const fetchPermissionsRequest = (): FetchPermissionsRequestAction => ({
  type: CurrentUserTypes.FETCH_USER_PERMISSIONS_REQUEST,
});

export const fetchPermissionsSuccess = (
  payload: FetchPermissionsSuccessActionPayload
): FetchPermissionsSuccessAction => ({
  type: CurrentUserTypes.FETCH_USER_PERMISSIONS_SUCCESS,
  payload,
});

export const changePassword = (
  newPassword: string
): ChangeUserPasswordAction => ({
  type: CurrentUserTypes.CHANGE_PASSWORD,
  payload: newPassword,
});
