import { TicketTypeSettings } from '@entities/ticket/model/types';
import { ApiClient, getEnv } from '@shared';

import {
  CreateEnvironment,
  CreateSystemData,
  CreateTicketType,
  EnvironmentResponse,
  System,
} from '../model';

import { endpoints } from './endpoints';

export const requests = {
  fetchCurrentSystem: (id: string) =>
    ApiClient.get<System>({
      baseURL: getEnv('REACT_APP_ORGANIZATIONS_URL'),
      url: endpoints.getCurrentSystem(id),
      data: {},
    }),
  createSystem: (data: CreateSystemData) =>
    ApiClient.post<System>({
      baseURL: getEnv('REACT_APP_ORGANIZATIONS_URL'),
      url: endpoints.createSystem(),
      data,
    }),
  editSystem: (data: CreateSystemData) =>
    ApiClient.put<System>({
      baseURL: getEnv('REACT_APP_ORGANIZATIONS_URL'),
      url: endpoints.createSystem(),
      data,
    }),
  createTicketType: ({ systemId, ticketTypes }: CreateTicketType) =>
    ApiClient.post<TicketTypeSettings[]>({
      baseURL: getEnv('REACT_APP_ORGANIZATIONS_URL'),
      url: endpoints.addTicketType(systemId),
      data: ticketTypes,
    }),
  createEnvironment: ({ systemId, environments }: CreateEnvironment) =>
    ApiClient.post<EnvironmentResponse[]>({
      baseURL: getEnv('REACT_APP_ORGANIZATIONS_URL'),
      url: endpoints.addEnvironment(systemId),
      data: environments,
    }),
  fetchSystemIndexList: () =>
    ApiClient.get({
      baseURL: getEnv('REACT_APP_ORGANIZATIONS_URL'),
      url: endpoints.getSystemIndexList(),
    }),
  addTicketTypes: (systemId: string, data: string[]) =>
    ApiClient.post<TicketTypeSettings[]>({
      baseURL: getEnv('REACT_APP_ORGANIZATIONS_URL'),
      url: endpoints.addTicketType(systemId),
      data,
    }),
  editTicketTypes: (systemId: string, data: string[]) =>
    ApiClient.put<TicketTypeSettings[]>({
      baseURL: getEnv('REACT_APP_ORGANIZATIONS_URL'),
      url: endpoints.addTicketType(systemId),
      data,
    }),
  addEnvironments: (systemId: string, data: string[]) =>
    ApiClient.post<EnvironmentResponse[]>({
      baseURL: getEnv('REACT_APP_ORGANIZATIONS_URL'),
      url: endpoints.addEnvironment(systemId),
      data,
    }),
  editEnvironments: (systemId: string, data: string[]) =>
    ApiClient.put<EnvironmentResponse[]>({
      baseURL: getEnv('REACT_APP_ORGANIZATIONS_URL'),
      url: endpoints.addEnvironment(systemId),
      data,
    }),
};
