import { ApiClient, getEnv } from '@shared';

import { Note } from '../../model/attachment/types';

import { notesEndpoints as endpoints } from './endpoints';

export const notesRequests = {
  createNote: (data: Note) =>
    ApiClient.post<Note>({
      baseURL: getEnv('REACT_APP_NOTES_URL'),
      url: endpoints.getCreateNoteUrl(),
      data,
    }),
  fetchNotesByTicketId: (ticketId: string) =>
    ApiClient.get<Note[]>({
      baseURL: getEnv('REACT_APP_NOTES_URL'),
      url: endpoints.getNotesByTicketId(ticketId),
      data: {},
    }),
};
