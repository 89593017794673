import { nanoid } from 'nanoid';

import { Alert, AlertTypes } from '../../model/types';

export const createSuccessAlert = (
  message: string,
  withoutTime?: boolean
): Alert => ({
  id: nanoid(),
  type: AlertTypes.SUCCESS,
  message,
  withoutTime,
});

export const createErrorAlert = (
  message: string,
  withoutTime?: boolean
): Alert => ({
  id: nanoid(),
  type: AlertTypes.ERROR,
  message,
  withoutTime,
});

export const createWarningAlert = (
  message: string,
  withoutTime?: boolean
): Alert => ({
  id: nanoid(),
  type: AlertTypes.WARNING,
  message,
  withoutTime,
});
