import { getUrl } from '@shared';

export const endpoints = {
  getCustomFields: () => getUrl('custom-field/filter'),
  getCurrentCustomField: (id: string) => getUrl(`custom-field/${id}`),
  createCustomField: () => getUrl('custom-field'),
  editCustomField: () => getUrl('custom-field'),
  deleteCustomField: (id: string) => getUrl(`custom-field/${id}`),
  getCustomTicketField: (id: string) =>
    getUrl(`custom-ticket-field/system/${id}`),
};
