import { SearchThinIcon, Typography, TypographyVariants } from '@shared';

import styles from './UserAssistanceSearchState.module.scss';

export const UserAssistanceSearchState = () => (
  <div className={styles.userAssistanceSearchState}>
    <SearchThinIcon className={styles.userAssistanceSearchState__icon} />
    <Typography
      variant={TypographyVariants.b2}
      className={styles.userAssistanceSearchState__text}
    >
      Начните поиск для выбора статей
    </Typography>
  </div>
);
