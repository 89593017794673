import { createSelector } from 'reselect';

import { createOrganizationsSelectListSelector } from '@entities/organizations';
import { SelectOption } from '@shared';

import { ArticlesMainState } from './types';

export const getArticlesMain = (state: ArticlesMainState) =>
  state.articlesMain.articles || [];
export const getFilterArticlesMain = (state: ArticlesMainState) =>
  state.articlesMain.articlesFilter || {};
export const getLoadingArticlesMain = (state: ArticlesMainState) =>
  state.articlesMain.loadingArticles || false;
export const getPaginationArticlesMain = (state: ArticlesMainState) =>
  state.articlesMain.articlesPagination || {};
export const getDefaultArticlesMain = (state: ArticlesMainState) =>
  state.articlesMain.defaultArticles || [];
export const getOrganizationsArticlesMain = (state: ArticlesMainState) =>
  state.articlesMain.organizations || [];

export const getCategoriesArticlesMain = (state: ArticlesMainState) =>
  state.articlesMain.categories;
export const getLoadingCategoriesArticlesMain = (state: ArticlesMainState) =>
  state.articlesMain.categoriesLoading || false;
export const getSelectedOrganizationArticlesMain = (state: ArticlesMainState) =>
  state.articlesMain.organizationSelected;
export const getOrganizationsArticlesMainSelectList =
  createOrganizationsSelectListSelector(getOrganizationsArticlesMain);

export const getSelectedOrgIdArticlesMain = createSelector<
  ArticlesMainState,
  SelectOption | null | undefined,
  string[]
>([getSelectedOrganizationArticlesMain], (organization) =>
  organization?.value ? [organization?.value] : []
);
