import { ResponseWithMeta } from '@shared';

import {
  CheckIsExistRequestAction,
  CheckIsExistSuccessAction,
  CreateRoleAction,
  DeleteRoleRequestAction,
  FetchRoleInGroupByUserIdAction,
  FetchRolesByGroupIdAction,
  FetchRolesByUserIdRequestAction,
  FetchRolesInGroupByOrgIdRequestAction,
  FetchRolesInSystemByOrgIdRequestAction,
  FetchRolesRequestAction,
  FetchRolesSuccessAction,
  HideRolesAddLoadingAction,
  HideRolesLoadingAction,
  ResetCurrentRoleAction,
  ResetRolesAddStateAction,
  ResetRolesStateAction,
  Roles,
  SetCurrentRoleIdAction,
  SetCurrentRolesAddPageAction,
  SetCurrentRolesPageAction,
  SetSizePageAction,
  SetSizePageRolesAddAction,
  SetSortRolesAction,
  ShowRolesAddLoadingAction,
  ShowRolesLoadingAction,
} from './actionTypes';
import { CreateRoleData, Role, RolesFilter } from './types';

export const showRolesLoading = (): ShowRolesLoadingAction => ({
  type: Roles.ROLES_LOADING_SHOW,
});

export const hideRolesAddLoading = (): HideRolesAddLoadingAction => ({
  type: Roles.ROLES_ADD_LOADING_HIDE,
});
export const showRolesAddLoading = (): ShowRolesAddLoadingAction => ({
  type: Roles.ROLES_ADD_LOADING_SHOW,
});

export const hideRolesLoading = (): HideRolesLoadingAction => ({
  type: Roles.ROLES_LOADING_HIDE,
});

export const fetchRolesRequest = (): FetchRolesRequestAction => ({
  type: Roles.FETCH_ROLES_REQUEST,
});

export const fetchRolesSuccess = (
  data: ResponseWithMeta<Role[]>
): FetchRolesSuccessAction => ({
  type: Roles.FETCH_ROLES_SUCCESS,
  payload: data,
});

export const setCurrentRolesPage = (
  page: number
): SetCurrentRolesPageAction => ({
  type: Roles.SET_CURRENT_ROLES_PAGE,
  payload: page,
});

export const setSizePageRolesAdd = (
  size: number
): SetSizePageRolesAddAction => ({
  type: Roles.SET_SIZE_PAGE_ROLES_ADD,
  payload: size,
});

export const setCurrentRolesAddPage = (
  page: number
): SetCurrentRolesAddPageAction => ({
  type: Roles.SET_CURRENT_ROLES_ADD_PAGE,
  payload: page,
});

export const setSizePage = (size: number): SetSizePageAction => ({
  type: Roles.SET_SIZE_PAGE_ROLES,
  payload: size,
});

export const setSortRoles = (sort: string): SetSortRolesAction => ({
  type: Roles.SET_SORT_ROLES,
  payload: sort,
});

export const resetRolesState = (): ResetRolesStateAction => ({
  type: Roles.RESET_ROLES_STATE,
});

export const setCurrentRoleId = (id?: string): SetCurrentRoleIdAction => ({
  type: Roles.SET_CURRENT_ROLE_ID,
  payload: id,
});

export const fetchRolesByUserIdRequest =
  (): FetchRolesByUserIdRequestAction => ({
    type: Roles.FETCH_ROLES_BY_USERID_REQUEST,
  });

export const createRole = (data: CreateRoleData): CreateRoleAction => ({
  type: Roles.CREATE_ROLE,
  payload: data,
});

export const fetchRoleInGroupByUserId = (): FetchRoleInGroupByUserIdAction => ({
  type: Roles.FETCH_ROLES_IN_GROUP_BY_USERID_REQUEST,
});

export const fetchRolesByGroupId = (): FetchRolesByGroupIdAction => ({
  type: Roles.FETCH_ROLES_BY_GROUP_ID,
});

export const setRolesAddFilter = (data: RolesFilter) => ({
  type: Roles.SET_ROLES_ADD_FILTER,
  payload: data,
});

export const setRolesFilter = (data: RolesFilter) => ({
  type: Roles.SET_ROLES_FILTER,
  payload: data,
});

export const fetchRolesAddSuccess = (data: ResponseWithMeta<Role[]>) => ({
  type: Roles.FETCH_ROLES_ADD_SUCCESS,
  payload: data,
});

export const updateRole = (data: Role) => ({
  type: Roles.UPDATE_ROLE,
  payload: data,
});

export const fetchRoleByIdRequest = (id: string) => ({
  type: Roles.FETCH_ROLE_BY_ID_REQUEST,
  payload: id,
});

export const fetchRoleByIdSuccess = (data?: Role) => ({
  type: Roles.FETCH_ROLE_BY_ID_SUCCESS,
  payload: data,
});

export const resetRolesAddState = (): ResetRolesAddStateAction => ({
  type: Roles.RESET_ROLES_ADD_STATE,
});

export const fetchRolesAddInSystemByOrgIdRequest =
  (): FetchRolesInSystemByOrgIdRequestAction => ({
    type: Roles.FETCH_ROLES_ADD_IN_SYSTEM_BY_ORG_ID_REQUEST,
  });

export const fetchRolesAddInGroupByOrgIdRequest =
  (): FetchRolesInGroupByOrgIdRequestAction => ({
    type: Roles.FETCH_ROLES_ADD_IN_GROUP_BY_ORG_ID_REQUEST,
  });

export const checkIsExistRequest = (
  title: string
): CheckIsExistRequestAction => ({
  type: Roles.CHECK_IS_EXIST_REQUEST,
  payload: title,
});

export const checkIsExistSuccess = (
  isExist: boolean
): CheckIsExistSuccessAction => ({
  type: Roles.CHECK_IS_EXIST_SUCCESS,
  payload: isExist,
});

export const deleteRoleRequest = (id: string): DeleteRoleRequestAction => ({
  type: Roles.DELETE_ROLE_BY_ID_REQUEST,
  payload: id,
});

export const resetCurrentRole = (): ResetCurrentRoleAction => ({
  type: Roles.RESET_CURRENT_ROLE,
});
