import { ResponseWithMeta } from '@shared';

import { Access, AccessesFilter } from './types';

export enum AccessesCategories {
  FETCH_ACCESSES_SUCCESS = 'ACCESSES/FETCH_ACCESSES_SUCCESS',
  FETCH_ACCESSES_REQUEST = 'ACCESSES/FETCH_ACCESSES_REQUEST',
  ACCESSES_LOADING_SHOW = 'ACCESSES/ACCESSES_LOADING_SHOW',
  ACCESSES_LOADING_HIDE = 'ACCESSES/ACCESSES_LOADING_HIDE',
  RESET_ACCESSES_STATE = 'ACCESSES/RESET_ACCESSES_STATE',
  SET_CURRENT_ACCESSES_PAGE = 'ACCESSES/SET_CURRENT_ACCESSES_PAGE',
  SET_SORT_ACCESSES = 'ACCESSES/SET_SORT_ACCESSES',
  SET_SIZE_PAGE_ACCESSES = 'ACCESSES/SET_SIZE_PAGE_ACCESSES',
  ACCESS_CATEGORY_CREATE = 'ACCESSES/ACCESS_CATEGORY_CREATE',
  FETCH_ACCESS_BY_RESP_ID_REQUEST = 'ACCESSES/FETCH_ACCESS_BY_RESP_ID_REQUEST',
  SET_ACCESSES_FILTER = 'ACCESSES/SET_ACCESSES_FILTER',
}

export interface ShowAccessesLoadingAction {
  type: AccessesCategories.ACCESSES_LOADING_SHOW;
}

export interface HideAccessesLoadingAction {
  type: AccessesCategories.ACCESSES_LOADING_HIDE;
}

export interface FetchAccessesRequestAction {
  type: AccessesCategories.FETCH_ACCESSES_REQUEST;
}

export interface FetchAccessesSuccessAction {
  type: AccessesCategories.FETCH_ACCESSES_SUCCESS;
  payload: ResponseWithMeta<Access[]>;
}

export interface ResetAccessesStateAction {
  type: AccessesCategories.RESET_ACCESSES_STATE;
}

export interface FetchAccessesByRespIdAction {
  type: AccessesCategories.FETCH_ACCESS_BY_RESP_ID_REQUEST;
}

export interface CreateAccessCategoryAction {
  type: AccessesCategories.ACCESS_CATEGORY_CREATE;
  payload: Access;
}

export interface SetAccessesFilterAction {
  type: AccessesCategories.SET_ACCESSES_FILTER;
  payload: AccessesFilter;
}

export interface SetCurrentAccessesPageAction {
  type: AccessesCategories.SET_CURRENT_ACCESSES_PAGE;
  payload: number;
}

export interface SetSizePageAction {
  type: AccessesCategories.SET_SIZE_PAGE_ACCESSES;
  payload: number;
}

export interface SetSortAccessesAction {
  type: AccessesCategories.SET_SORT_ACCESSES;
  payload: string;
}

export type AccessesActionsTypes =
  | ShowAccessesLoadingAction
  | HideAccessesLoadingAction
  | FetchAccessesRequestAction
  | FetchAccessesSuccessAction
  | ResetAccessesStateAction
  | FetchAccessesByRespIdAction
  | SetAccessesFilterAction
  | SetCurrentAccessesPageAction
  | SetSizePageAction
  | SetSortAccessesAction;
