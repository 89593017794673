import { Access } from '@entities/accesses';

export const getDataTable = (categories?: Access[]) =>
  categories?.map((category) => {
    const { title, nameInDatabase, id } = category;
    return {
      id,
      title,
      nameInDatabase,
    };
  }) || [];
