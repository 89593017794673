import { RedirectComponent } from '@features/profile';
import AdminPage from '@pages/Admin';
import CompaniesPage from '@pages/companies';
import ContractPage from '@pages/contract';
import ContractsPage from '@pages/contracts';
import CreateTicketPage from '@pages/create-ticket';
import CustomFieldsPage from '@pages/custom-fields';
import DesktopCurrentStatePage from '@pages/desktop/DesktopCurrentStatePage';
import DesktopEmployeesPage from '@pages/desktop/DesktopEmployeesPage';
import DesktopHistoryPage from '@pages/desktop/DesktopHistoryPage';
import DesktopKpiPage from '@pages/desktop/DesktopKpiPage';
import JiraIntegrationsPage from '@pages/jira-integrations';
import KnowledgeBaseArticlePage from '@pages/knowledge-base/KBArticlePage';
import KnowledgeBaseCreate from '@pages/knowledge-base/KBCreateArticlePage';
import KnowledgeBaseEditArticlePage from '@pages/knowledge-base/KBEditArticlePage';
import KnowledgeBasePage from '@pages/knowledge-base/KBMainPage';
import KnowledgeBaseTable from '@pages/knowledge-base/KBTablePage';
import NotFoundPage from '@pages/NotFoundPage';
import OrganizationPage from '@pages/organization';
import OrganizationsPage from '@pages/organizations';
import ProfileNewsPage from '@pages/profile/ProfileNews';
import ProfileNotificationsPage from '@pages/profile/ProfileNotifications';
import ProfileSettingsPage from '@pages/profile/ProfileSettings';
import ProfileTelegramPage from '@pages/profile/ProfileTelegram';
import ResponsibilitiesPage from '@pages/responsibilities';
import RolesPage from '@pages/roles';
import RulesPage from '@pages/rules';
import StatusesBuilderPage from '@pages/statuses-builder';
import StoragePage from '@pages/storage';
import StoragesPage from '@pages/storages';
import SupplementaryAgreementPage from '@pages/supplementary-agreement';
import SystemPage from '@pages/system';
import SystemsPage from '@pages/systems';
import TicketPage from '@pages/ticket';
import TicketsPage from '@pages/tickets';
import TriggersPage from '@pages/triggers';
import UserPage from '@pages/user';
import UsersPage from '@pages/users';
import WorkGroupPage from '@pages/work-group';
import WorkGroupsPage from '@pages/work-groups';
import {
  getAdminsTitle,
  getDesktopTitle,
  getKnowledgeBaseTitle,
  getProfileTitle,
  getStorageTitle,
  getTicketsTitle,
  RouterHref,
} from '@shared';

import { RouterConfig } from './types';

export const routerConfig: RouterConfig[] = [
  {
    href: RouterHref.Companies,
    component: CompaniesPage,
    withPermission: false,
    breadCrumbTitle: 'Главная',
  },
  {
    href: RouterHref.Tickets,
    component: TicketsPage,
    getPageTitle: getTicketsTitle,
    breadCrumbTitle: 'Тикеты',
  },
  {
    href: RouterHref.TicketsAll,
    component: TicketsPage,
    getPageTitle: getTicketsTitle,
    breadCrumbTitle: 'Все тикеты',
  },
  {
    href: RouterHref.TicketsGroup,
    component: TicketsPage,
    getPageTitle: getTicketsTitle,
    breadCrumbTitle: 'Тикеты группы',
  },
  {
    href: RouterHref.DesktopKpi,
    component: DesktopKpiPage,
    getPageTitle: getDesktopTitle,
    breadCrumbTitle: 'Рабочий стол',
    withPermission: false,
  },
  {
    href: RouterHref.DesktopCurrentState,
    component: DesktopCurrentStatePage,
    getPageTitle: getDesktopTitle,
    breadCrumbTitle: 'Текущее состояние',
  },
  {
    href: RouterHref.DesktopHistory,
    component: DesktopHistoryPage,
    getPageTitle: getDesktopTitle,
    breadCrumbTitle: 'История',
  },
  {
    href: RouterHref.DesktopEmployees,
    component: DesktopEmployeesPage,
    getPageTitle: getDesktopTitle,
    breadCrumbTitle: 'Сотрудники',
  },
  // {
  //   href: RouterHref.DesktopKpi,
  //   component: DesktopListPage,
  //   pageTitle: getDesktopTitle('Текущее состояние'),
  //   exact: false,
  //   withPermission: false,
  //   breadCrumbTitle: 'Текущее состояние',
  // },
  {
    href: RouterHref.CreateTicket,
    component: CreateTicketPage,
    getPageTitle: getTicketsTitle,
    breadCrumbTitle: 'Создать тикет',
  },
  {
    href: RouterHref.TicketCard,
    component: TicketPage,
    getPageTitle: getTicketsTitle,
    breadCrumbTitle: 'Карточка тикета',
  },
  {
    href: RouterHref.Admin,
    component: AdminPage,
    getPageTitle: getAdminsTitle,
    withPermission: false,
    breadCrumbTitle: 'Админ',
  },
  {
    href: RouterHref.AdminOrganizations,
    component: OrganizationsPage,
    getPageTitle: getAdminsTitle,
    breadCrumbTitle: 'Структура',
  },
  {
    href: RouterHref.AdminContracts,
    component: ContractsPage,
    getPageTitle: getAdminsTitle,
    breadCrumbTitle: 'Контракты',
  },
  {
    href: RouterHref.AdminSystems,
    component: SystemsPage,
    getPageTitle: getAdminsTitle,
    breadCrumbTitle: 'Системы',
  },
  {
    href: RouterHref.AdminUsers,
    component: UsersPage,
    getPageTitle: getAdminsTitle,
    breadCrumbTitle: 'Пользователи',
  },
  {
    href: RouterHref.AdminGroups,
    component: WorkGroupsPage,
    getPageTitle: getAdminsTitle,
    breadCrumbTitle: 'Группы',
  },
  {
    href: RouterHref.AdminRoles,
    component: RolesPage,
    getPageTitle: getAdminsTitle,
    breadCrumbTitle: 'Роли',
  },
  {
    href: RouterHref.AdminPowers,
    component: ResponsibilitiesPage,
    getPageTitle: getAdminsTitle,
    breadCrumbTitle: 'Полномочия',
  },
  {
    href: RouterHref.AdminCustomFields,
    component: CustomFieldsPage,
    getPageTitle: getAdminsTitle,
    breadCrumbTitle: 'Настройки',
    withPermission: false,
  },
  {
    href: RouterHref.AdminJiraIntegrations,
    component: JiraIntegrationsPage,
    getPageTitle: getAdminsTitle,
    breadCrumbTitle: 'Интеграция c Jira',
  },
  {
    href: RouterHref.AdminStatusesBuilder,
    component: StatusesBuilderPage,
    getPageTitle: getAdminsTitle,
    breadCrumbTitle: 'Настройка статусов',
  },
  {
    href: RouterHref.AdminAssigningRules,
    component: RulesPage,
    getPageTitle: getAdminsTitle,
    breadCrumbTitle: 'Правила',
    withPermission: false,
  },
  {
    href: RouterHref.AdminTriggeredRules,
    component: TriggersPage,
    getPageTitle: getAdminsTitle,
    breadCrumbTitle: 'Триггеры',
  },
  {
    href: RouterHref.AdminCreateOrganization,
    component: OrganizationPage,
    getPageTitle: getAdminsTitle,
    breadCrumbTitle: 'Создать организациию',
  },
  {
    href: RouterHref.AdminCreateUser,
    component: UserPage,
    getPageTitle: getAdminsTitle,
    breadCrumbTitle: 'Создать пользователя',
  },
  {
    href: RouterHref.AdminCreateContract,
    component: ContractPage,
    getPageTitle: getAdminsTitle,
    breadCrumbTitle: 'Создать контракт',
  },
  {
    href: RouterHref.AdminCreateSupplementaryAgreement,
    component: SupplementaryAgreementPage,
    getPageTitle: getAdminsTitle,
    withPermission: false,
    breadCrumbTitle: 'Создать допсоглашение',
  },
  {
    href: RouterHref.AdminCreateGroup,
    component: WorkGroupPage,
    getPageTitle: getAdminsTitle,
    breadCrumbTitle: 'Создать группу',
  },
  {
    href: RouterHref.AdminCreateSystem,
    component: SystemPage,
    getPageTitle: getAdminsTitle,
    breadCrumbTitle: 'Создать систему',
  },
  {
    href: RouterHref.AdminOrganizationCard,
    component: OrganizationPage,
    getPageTitle: getAdminsTitle,
    breadCrumbTitle: 'Карточка организации',
  },
  {
    href: RouterHref.AdminUserCard,
    component: UserPage,
    getPageTitle: getAdminsTitle,
    breadCrumbTitle: 'Карточка пользователя',
  },
  {
    href: RouterHref.AdminWorkGroupCard,
    component: WorkGroupPage,
    getPageTitle: getAdminsTitle,
    breadCrumbTitle: 'Карточка рабочей группы',
  },
  {
    href: RouterHref.AdminContractCard,
    component: ContractPage,
    getPageTitle: getAdminsTitle,
    breadCrumbTitle: 'Карточка контракта',
  },
  {
    href: RouterHref.AdminSupplementaryAgreementCard,
    component: SupplementaryAgreementPage,
    getPageTitle: getAdminsTitle,
    breadCrumbTitle: 'Карточка допсоглашения',
  },
  {
    href: RouterHref.AdminSystemCard,
    component: SystemPage,
    getPageTitle: getAdminsTitle,
    breadCrumbTitle: 'Карточка системы',
  },
  {
    href: RouterHref.Profile,
    component: RedirectComponent,
    getPageTitle: getProfileTitle,
    withPermission: false,
    breadCrumbTitle: 'Профиль',
  },
  {
    href: RouterHref.ProfileNotifications,
    component: ProfileNotificationsPage,
    getPageTitle: getProfileTitle,
    exact: false,
    withPermission: false,
    breadCrumbTitle: 'Уведомления',
  },
  {
    href: RouterHref.ProfileTelegram,
    component: ProfileTelegramPage,
    getPageTitle: getProfileTitle,
    withPermission: false,
    breadCrumbTitle: 'Чат бот',
  },
  {
    href: RouterHref.ProfileNews,
    component: ProfileNewsPage,
    getPageTitle: getProfileTitle,
    exact: false,
    withPermission: false,
    breadCrumbTitle: 'Новости',
  },
  {
    href: RouterHref.ProfileSettings,
    component: ProfileSettingsPage,
    getPageTitle: getProfileTitle,
    withPermission: false,
    breadCrumbTitle: 'Настройки',
  },
  {
    href: RouterHref.KnowledgeBase,
    component: KnowledgeBasePage,
    getPageTitle: getKnowledgeBaseTitle,
    withPermission: false,
    breadCrumbTitle: 'База знаний',
  },
  {
    href: RouterHref.KnowledgeBaseCategoryTable,
    component: KnowledgeBaseTable,
    getPageTitle: getKnowledgeBaseTitle,
    withPermission: false,
    breadCrumbTitle: 'Таблица',
  },
  {
    href: RouterHref.KnowledgeBaseArticleCreate,
    component: KnowledgeBaseCreate,
    getPageTitle: getKnowledgeBaseTitle,
    breadCrumbTitle: 'Создание статьи',
  },
  {
    href: RouterHref.KnowledgeBaseArticleEdit,
    component: KnowledgeBaseEditArticlePage,
    getPageTitle: getKnowledgeBaseTitle,
    breadCrumbTitle: 'Редактирование статьи',
  },
  {
    href: RouterHref.KnowledgeBaseArticle,
    component: KnowledgeBaseArticlePage,
    getPageTitle: getKnowledgeBaseTitle,
    withPermission: false,
    breadCrumbTitle: 'Просмотр статьи',
  },
  {
    href: RouterHref.Storages,
    component: StoragesPage,
    getPageTitle: getStorageTitle,
    breadCrumbTitle: 'Хранилища',
  },
  {
    href: RouterHref.Storage,
    component: StoragePage,
    getPageTitle: getStorageTitle,
  },
  {
    href: RouterHref.NotFound,
    component: NotFoundPage,
    withPermission: false,
    breadCrumbTitle: 'Страница не найдена',
  },
];
