import cn from 'clsx';
import { debounce } from 'lodash';
import { useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';

import {
  DEFAULT_DEBOUNCE_DELAY,
  MenuItem,
  RenderMenuItemProps,
  Select,
  SelectOption,
  Size,
  Typography,
  TypographyVariants,
  ValueType,
} from '@shared';

import { SelectActionsProps, SelectorProps, ValuesProps } from '../../model';

import { KB_ORGANIZATION_META_COLOR } from './config';
import styles from './KBOrganizationSelect.module.scss';

interface KBOrganizationSelectProps<T> {
  clearWhenIsInputValue?: boolean;
  className?: string;
  valuesProps: ValuesProps;
  selectorProps: SelectorProps<T>;
  actionsProps: SelectActionsProps;
}

export const KBOrganizationSelect = <T,>(
  props: KBOrganizationSelectProps<T>
) => {
  const {
    clearWhenIsInputValue = false,
    className,
    valuesProps,
    selectorProps,
    actionsProps,
  } = props;
  const {
    onInputFilter,
    fetchCategories,
    fetchOrganizations,
    resetCategories,
    onChangeOrganization,
  } = actionsProps;

  const { getSelectList } = selectorProps;
  const searchValue = valuesProps?.searchValue;
  const organizationSelected = valuesProps?.organizationSelected;

  const organizationsList = useSelector(getSelectList);

  const onFilterOrganization = debounce((valueFilter: string) => {
    onInputFilter(valueFilter);
  }, DEFAULT_DEBOUNCE_DELAY);

  const { control, setValue } = useForm<{
    organization: ValueType<string>;
  }>({
    mode: 'onChange',
  });

  useEffect(() => {
    if (searchValue && organizationSelected && clearWhenIsInputValue) {
      setValue('organization', null);
      resetCategories();
      fetchOrganizations({});
    }
  }, [searchValue, clearWhenIsInputValue]);

  useEffect(() => {
    fetchOrganizations({});
    fetchCategories();
  }, []);

  const renderItemType = ({
    onChange,
    option,
    selected,
  }: RenderMenuItemProps) => (
    <MenuItem
      onClick={onChange}
      selected={selected}
      className={styles.KBOrganizationSelect__option}
    >
      <Typography
        variant={TypographyVariants.b2}
        className={styles.KBOrganizationSelect__optionTitle}
      >
        {option.title}
      </Typography>
      <Typography
        variant={TypographyVariants.s2}
        className={styles.KBOrganizationSelect__optionType}
      >
        <Typography
          component="span"
          variant={TypographyVariants.s2}
          className={cn(
            styles[
              `KBOrganizationSelect__optionType_${
                KB_ORGANIZATION_META_COLOR[option?.meta ?? '']
              }`
            ]
          )}
        >
          {`${option.meta} `}
        </Typography>
        организация
      </Typography>
    </MenuItem>
  );

  return (
    <Controller
      control={control}
      name="organization"
      key="organization"
      rules={{
        required: true,
      }}
      render={() => (
        <Select<string>
          onChange={(value: SelectOption) => {
            onChangeOrganization(value);
          }}
          mobileModalTitle="организацию"
          renderMenuItem={renderItemType}
          placeholder="Организация"
          options={organizationsList}
          onChangeInput={onFilterOrganization}
          className={cn(styles.KBOrganizationSelect, className)}
          value={organizationSelected}
          isSearchable
          size={Size.s}
        />
      )}
    />
  );
};
