import { getUrl } from '@shared';

export const endpoints = {
  getCurrentContract: (id: string) => getUrl(`contract/${id}`),
  fetchAttachments: () => getUrl('attachment'),
  getGroupsByContractIdBySystemId: (contractId: string, systemId: string) =>
    getUrl(`work-group/contract-system/${contractId}/${systemId}`),
  getSLAByContractIdBySystemIdUrl: () => getUrl(`sla/map/by-contract-system`),
  deleteContract: (id: string) => getUrl(`contract/${id}`),
  getSystemByIdUrl: (id: string) => getUrl(`system/${id}`),
  getSystemBySupAgIdUrl: (id: string) => getUrl(`system/contract/${id}`),
  getNumberSupAgUrl: (contractId: string) =>
    getUrl(`contract/number-supplementary-agreement/${contractId}`),
  getCreateSupAgUrl: (contractId: string, systemId: string) =>
    getUrl(`contract/new/supplementary-agreement/${contractId}/${systemId}`),
};
