import { Contracts } from '@widgets/contracts';

import { AdminsLayout } from '../AdminsLayout';

const ContractsPage = () => (
  <AdminsLayout>
    <Contracts />
  </AdminsLayout>
);

export default ContractsPage;
