import { Action } from '@entities/actions/model/types';
import {
  CustomField,
  CustomTicketFields,
} from '@entities/custom-fields/model/types';
import { System } from '@entities/system/model/types';
import { CreateTicketInJiraPayload } from '@entities/tickets';
import { KeyValueOption } from '@shared';

import {
  Comment,
  Status,
  Ticket,
  TicketStatusesRequest,
  TicketType,
} from '../types';

import {
  AddSpecAndSetToWorkAction,
  ChangeStatusAction,
  CloseTicketRequestAction,
  CreateTicketInJiraAction,
  DeleteTicketRequestAction,
  EditTicketPriorityAction,
  FetchActionsForTicketDetailSuccessAction,
  FetchCurrentTicketSystemRequestAction,
  FetchHistoryRequestAction,
  FetchHistorySuccessAction,
  FetchIsSystemIntegratedWithJiraRequestAction,
  FetchIsSystemIntegratedWithJiraSuccessAction,
  FetchJiraUrlRequestAction,
  FetchJiraUrlSuccessAction,
  FetchNextStatusesRequestAction,
  FetchNextStatusesSuccessAction,
  FetchStatusesRequestAction,
  FetchStatusesSuccessAction,
  FetchTicketCustomFieldsRequestAction,
  FetchTicketCustomFieldsSuccessAction,
  FetchTicketRequestAction,
  FetchTicketTypesActions,
  HideTicketLoadingAction,
  ReopenTicketRequestAction,
  ResetTicketDetailStateAction,
  RestoreTicketRequestAction,
  SetIsErrorAction,
  SetIsSuccessAction,
  SetTicketDetailSuccessAction,
  SetTicketSuccessTypesActions,
  SetTicketSystemDetailSuccessAction,
  SetToastMessageAction,
  ShowTicketLoadingAction,
  TicketDetail,
  TicketHistoryLoadingHide,
  TicketHistoryLoadingShow,
  UpdateDataTicketRequestAction,
  UpdateTicketTypeActions,
  UpdateTicketTypePayload,
} from './actionTypes';
import {
  AddSpecAndSetToWorkPayload,
  CheckExistIntegration,
  CloseTicketRequest,
  CustomFieldTicket,
  EditTicketPriorityPayload,
  FetchTicketRequestPayload,
  NextStatusesRequest,
  ReopenTicket,
  TicketHistory,
  TicketUpdateType,
} from './types';

export const showTicketLoading = (): ShowTicketLoadingAction => ({
  type: TicketDetail.TICKET_DETAIL_LOADING_SHOW,
});

export const hideTicketLoading = (): HideTicketLoadingAction => ({
  type: TicketDetail.TICKET_DETAIL_LOADING_HIDE,
});

export const showTicketHistoryLoading = (): TicketHistoryLoadingShow => ({
  type: TicketDetail.TICKET_HISTORY_LOADING_SHOW,
});

export const hideTicketHistoryLoading = (): TicketHistoryLoadingHide => ({
  type: TicketDetail.TICKET_HISTORY_LOADING_HIDE,
});

export const fetchTicketRequest = (
  payload: FetchTicketRequestPayload
): FetchTicketRequestAction => ({
  type: TicketDetail.FETCH_TICKET_DETAIL_REQUEST,
  payload,
});

export const setCurrentTicket = (
  data?: Ticket
): SetTicketDetailSuccessAction => ({
  type: TicketDetail.SET_TICKET_DETAIL_SUCCESS,
  payload: data,
});

export const fetchCurrentTicketSystemRequest = (
  id: string
): FetchCurrentTicketSystemRequestAction => ({
  type: TicketDetail.FETCH_TICKET_SYSTEM_DETAIL_REQUEST,
  payload: id,
});

export const fetchTicketCustomFieldsRequest = (
  id: string
): FetchTicketCustomFieldsRequestAction => ({
  type: TicketDetail.FETCH_TICKET_CUSTOM_FIELDS_BY_SYSTEM_ID_REQUEST,
  payload: id,
});

export const fetchTicketCustomFieldsSuccess = (
  data: CustomField[]
): FetchTicketCustomFieldsSuccessAction => ({
  type: TicketDetail.FETCH_TICKET_CUSTOM_FIELDS_BY_SYSTEM_ID_SUCCESS,
  payload: data,
});

export const setCurrentTicketSystemSuccess = (
  data?: System
): SetTicketSystemDetailSuccessAction => ({
  type: TicketDetail.SET_TICKET_SYSTEM_DETAIL_SUCCESS,
  payload: data,
});

export const addSpecAndSetToWork = (
  payload: AddSpecAndSetToWorkPayload
): AddSpecAndSetToWorkAction => ({
  type: TicketDetail.ADD_SPEC_AND_SET_TO_WORK_TICKET_DETAIL,
  payload,
});

export const fetchHistoryRequest = (
  ticketId: string
): FetchHistoryRequestAction => ({
  type: TicketDetail.FETCH_HISTORY_REQUEST,
  payload: ticketId,
});

export const fetchHistorySuccess = (
  history: TicketHistory[]
): FetchHistorySuccessAction => ({
  type: TicketDetail.FETCH_HISTORY_SUCCESS,
  payload: history,
});

export const resetTicketState = (): ResetTicketDetailStateAction => ({
  type: TicketDetail.RESET_TICKET_DETAIL_STATE,
});

export const editTicketPriority = (
  payload: EditTicketPriorityPayload
): EditTicketPriorityAction => ({
  payload,
  type: TicketDetail.EDIT_TICKET_PRIORITY,
});

export const setIsSuccess = (payload: boolean): SetIsSuccessAction => ({
  payload,
  type: TicketDetail.SET_IS_SUCCESS,
});

export const setToastMessage = (payload: string): SetToastMessageAction => ({
  payload,
  type: TicketDetail.SET_TOAST_MESSAGE,
});

export const setIsError = (payload: boolean): SetIsErrorAction => ({
  payload,
  type: TicketDetail.SET_IS_ERROR,
});

export const fetchActionsForCurrentTicketSuccess = (
  payload: Action[]
): FetchActionsForTicketDetailSuccessAction => ({
  payload,
  type: TicketDetail.FETCH_ACTIONS_FOR_TICKET_DETAIL_SUCCESS,
});

export const deleteTicketRequest = (
  ticket: Ticket
): DeleteTicketRequestAction => ({
  type: TicketDetail.DELETE_TICKET_DETAIL_REQUEST,
  payload: ticket,
});

export const updateDataTicketRequest = (
  ticket: TicketUpdateType
): UpdateDataTicketRequestAction => ({
  type: TicketDetail.UPDATE_DATA_TICKET_DETAIL,
  payload: ticket,
});

export const createTicketInJira = (
  payload: CreateTicketInJiraPayload
): CreateTicketInJiraAction => ({
  type: TicketDetail.CREATE_TICKET_IN_JIRA,
  payload,
});

export const fetchIsSystemIntegratedWithJiraRequest = (
  payload: CheckExistIntegration
): FetchIsSystemIntegratedWithJiraRequestAction => ({
  type: TicketDetail.FETCH_IS_SYSTEM_INTEGRATED_WITH_JIRA_REQUEST,
  payload,
});

export const fetchIsSystemIntegratedWithJiraSuccess = (
  payload: boolean
): FetchIsSystemIntegratedWithJiraSuccessAction => ({
  type: TicketDetail.FETCH_IS_SYSTEM_INTEGRATED_WITH_JIRA_SUCCESS,
  payload,
});

export const fetchJiraUrlRequest = (
  ticketId: string
): FetchJiraUrlRequestAction => ({
  type: TicketDetail.FETCH_JIRA_URL_REQUEST,
  payload: ticketId,
});

export const fetchJiraUrlSuccess = (
  payload: string
): FetchJiraUrlSuccessAction => ({
  type: TicketDetail.FETCH_JIRA_URL_SUCCESS,
  payload,
});

export const fetchCustomFieldsByTicketIdRequest = (payload: string) => ({
  type: TicketDetail.FETCH_CUSTOM_FIELDS_BY_TICKET_ID_REQUEST,
  payload,
});

export const fetchCustomFieldsByTicketIdSuccess = (
  payload: CustomTicketFields
) => ({
  type: TicketDetail.FETCH_CUSTOM_FIELDS_BY_TICKET_ID_SUCCESS,
  payload,
});

export const updateCustomFieldsByTicketIdRequest = (
  payload: CustomFieldTicket[]
) => ({
  type: TicketDetail.UPDATE_CUSTOM_FIELDS_BY_TICKET_ID_REQUEST,
  payload,
});

export const fetchNextStatusesRequest = (
  payload: NextStatusesRequest
): FetchNextStatusesRequestAction => ({
  type: TicketDetail.FETCH_NEXT_STATUSES_REQUEST,
  payload,
});

export const fetchNextStatusesSuccess = (
  payload: Status[]
): FetchNextStatusesSuccessAction => ({
  type: TicketDetail.FETCH_NEXT_STATUSES_SUCCESS,
  payload,
});

export const changeStatus = (payload: Comment): ChangeStatusAction => ({
  type: TicketDetail.CHANGE_STATUS,
  payload,
});

export const closeTicketRequest = (
  payload: CloseTicketRequest
): CloseTicketRequestAction => ({
  type: TicketDetail.CLOSE_TICKET_REQUEST,
  payload,
});

export const restoreTicketRequest = (
  payload?: string
): RestoreTicketRequestAction => ({
  type: TicketDetail.RESTORE_TICKET_REQUEST,
  payload,
});

export const fetchTicketTypesRequest = (
  payload: string
): FetchTicketTypesActions => ({
  type: TicketDetail.FETCH_TICKET_TYPES_REQUEST,
  payload,
});

export const fetchTicketTypesSuccess = (
  payload: TicketType[]
): SetTicketSuccessTypesActions => ({
  type: TicketDetail.FETCH_TICKET_TYPES_SUCCESS,
  payload,
});

export const updateTicketTypesRequest = (
  payload: UpdateTicketTypePayload
): UpdateTicketTypeActions => ({
  type: TicketDetail.UPDATE_TICKET_TYPES_BY_TYPE_ID_REQUEST,
  payload,
});

export const reopenTicketRequest = (
  payload: ReopenTicket
): ReopenTicketRequestAction => ({
  type: TicketDetail.REOPEN_TICKET_REQUEST,
  payload,
});

export const fetchStatusesRequest = (
  payload: TicketStatusesRequest
): FetchStatusesRequestAction => ({
  type: TicketDetail.FETCH_STATUSES_REQUEST,
  payload,
});

export const fetchStatusesSuccess = (
  payload?: KeyValueOption[]
): FetchStatusesSuccessAction => ({
  type: TicketDetail.FETCH_STATUSES_SUCCESS,
  payload,
});
