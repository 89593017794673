import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { getUserOrganizationId } from '@entities/auth';
import {
  CategoryArticle,
  createCategoryRequestRightArticleViewing,
  deleteArticleRequestRightArticleViewing,
  deleteCategoryRequestRightArticleViewing,
  editArticleRequestRightArticleViewing,
  editCategoryRequestRightArticleViewing,
  fetchArticlesArticleViewingRequest,
  FetchArticlesParams,
  fetchCategoriesRequestArticleViewing,
  fetchOrganizationsRequestArticleViewing,
  getArticlesArticleViewing,
  getCategoriesArticlesArticleViewing,
  getLoadingArticlesArticleViewing,
  getLoadingCategoriesArticleViewing,
  getOrganizationsArticleViewingSelectList,
  getPaginationArticlesArticleViewing,
  getSearchValueArticlesArticleViewing,
  getSelectedOrganizationArticlesArticleViewing,
  KBArticleTableFilterToRequest,
  resetArticlesStateArticleViewing,
  resetCategoriesStateArticleViewing,
  setArticlesFilterArticleViewing,
  setSearchValueArticleViewing,
  setSelectedOrganizationArticleViewing,
} from '@entities/knowledge-base';
import {
  KBArticleDropProps,
  KBCategoryDropProps,
} from '@features/knowledge-base';
import {
  getScreenWidth,
  ScreenWidth,
  SelectOption,
  useInfiniteScroll,
} from '@shared';

export const useKBArticleViewingSearch = () => {
  const dispatch = useDispatch();

  const organization = useSelector(
    getSelectedOrganizationArticlesArticleViewing
  );
  const searchValue = useSelector(getSearchValueArticlesArticleViewing);
  const articles = useSelector(getArticlesArticleViewing);
  const categories = useSelector(getCategoriesArticlesArticleViewing);
  const loadingCategories = useSelector(getLoadingCategoriesArticleViewing);
  const screenWidth = useSelector(getScreenWidth);
  const loadingArticles = useSelector(getLoadingArticlesArticleViewing);
  const {
    totalElements,
    pageNum = 0,
    pageSize,
  } = useSelector(getPaginationArticlesArticleViewing);
  const userOrgId = useSelector(getUserOrganizationId);

  const widthDesktop =
    screenWidth === ScreenWidth.DESKTOP ||
    screenWidth === ScreenWidth.DESKTOP_SMALL;

  const { totalPage } = useInfiniteScroll({
    pageNum,
    pageSize,
    totalElements,
  });
  const hasMore = !loadingArticles && totalPage > pageNum + 1;

  const onChangeTitle = (id: string, newTitle = '') => {
    dispatch(editCategoryRequestRightArticleViewing({ id, title: newTitle }));
  };

  const onDeleteCategory = (id?: string) => {
    if (id) {
      dispatch(deleteCategoryRequestRightArticleViewing(id));
    }
  };

  const onSubCategoryCreate = (data: Partial<CategoryArticle>) => {
    dispatch(createCategoryRequestRightArticleViewing(data));
  };

  const onChangeTitleArticle = (id: string, newTitle = '') => {
    dispatch(editArticleRequestRightArticleViewing({ id, title: newTitle }));
  };

  const onArticleDelete = (id: string) => {
    dispatch(deleteArticleRequestRightArticleViewing(id));
  };

  const setNextPageSystem = () => {
    if (!loadingArticles && totalPage > pageNum) {
      dispatch(
        fetchArticlesArticleViewingRequest({
          updateType: 'join',
          page: pageNum + 1,
        })
      );
    }
  };

  const fetchCategories = () => {
    if (userOrgId) {
      dispatch(fetchCategoriesRequestArticleViewing([userOrgId]));
    }
  };

  const fetchOrganizations = () => {
    dispatch(fetchOrganizationsRequestArticleViewing({}));
  };

  const resetCategories = () => {
    dispatch(resetCategoriesStateArticleViewing());
  };

  const onChangeOrganization = (value: SelectOption) => {
    dispatch(setSelectedOrganizationArticleViewing(value));
    dispatch(fetchCategoriesRequestArticleViewing([value?.value]));
  };

  const setSearchValueToState = (value?: string) => {
    dispatch(setSearchValueArticleViewing(value || ''));
  };

  const onFilterOrganization = (valueFilter: string) => {
    dispatch(
      fetchOrganizationsRequestArticleViewing({
        organizationTitle: valueFilter,
      })
    );
  };
  const resetState = () => {
    dispatch(resetArticlesStateArticleViewing());
  };

  const fetch = (params: FetchArticlesParams) => {
    dispatch(fetchArticlesArticleViewingRequest(params));
  };

  const setFilter = (filter: KBArticleTableFilterToRequest) => {
    dispatch(setArticlesFilterArticleViewing(filter));
  };

  const onCreateCategory = useCallback(
    (data?: Partial<CategoryArticle>) => {
      if (data) {
        dispatch(createCategoryRequestRightArticleViewing(data));
      }
    },
    [dispatch]
  );

  const categoryDropProps: KBCategoryDropProps = {
    onChangeTitle,
    onDeleteCategory,
    onSubCategoryCreate,
    withArticleCreate: true,
  };

  const articleDropProps: KBArticleDropProps = {
    onChangeTitle: onChangeTitleArticle,
    onDelete: onArticleDelete,
    withChangeArticleTitle: true,
    withDeleteArticle: true,
  };

  const selectOrganizationsProps = {
    getSelectList: getOrganizationsArticleViewingSelectList,
  };

  const valuesProps = {
    searchValue,
    organizationSelected: organization,
  };

  const actionsOrganizationsProps = {
    onInputFilter: onFilterOrganization,
    fetchCategories,
    fetchOrganizations,
    resetCategories,
    onChangeOrganization,
    setSearchValueToState,
  };

  const selectorsSearchProps = {
    getList: getArticlesArticleViewing,
    getLoading: getLoadingArticlesArticleViewing,
    getOrganizationsSelected: getSelectedOrganizationArticlesArticleViewing,
    getPaginationArticles: getPaginationArticlesArticleViewing,
  };

  const actionsSearchProps = {
    resetState,
    fetch,
    setFilter,
    setSearchValueToState,
    fetchCategories,
  };

  return {
    state: {
      categoryDropProps,
      articleDropProps,
      selectOrganizationsProps,
      valuesProps,
      actionsOrganizationsProps,
      selectorsSearchProps,
      actionsSearchProps,
      categories,
      hasMore,
      widthDesktop,
      articles,
      organization,
      searchValue,
      loadingCategories,
      loadingArticles,
    },
    methods: {
      onCreateCategory,
      setNextPageSystem,
    },
  };
};
