import { AdminsLayout } from '@pages/AdminsLayout';
import { WorkGroups } from '@widgets/work-groups';

const WorkGroupsPage = () => (
  <AdminsLayout>
    <WorkGroups />
  </AdminsLayout>
);

export default WorkGroupsPage;
