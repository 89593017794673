import { Languages } from '@shared';

import {
  PRIORITY_TITLE_MAP_SHORT,
  PRIORITY_TITLE_MAP_SHORT_EN,
} from '../../config';

export const getPriorityByTitleTitleShortMap = (language: Languages) => {
  const isRU = language.includes(Languages.RU);
  return isRU ? PRIORITY_TITLE_MAP_SHORT : PRIORITY_TITLE_MAP_SHORT_EN;
};
