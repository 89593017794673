export const DEFAULT_PAGINATION_PARAMS = {
  pageNum: 0,
  pageSize: 10,
};

export const DEFAULT_DEBOUNCE_DELAY = 500;
export const ON_FILTER_DEBOUNCE_DELAY = 700;
export const PAGE_SIZE_SEARCH_SELECT = 25;

export const BREAKPOINTS = {
  xs: 576,
  sm: 768,
  md: 992,
  lg: 1200,
  xl: 1400,
};

export const MAX_FILE_SIZE = 50000000;

export const isDev = process.env.NODE_ENV === 'development';

export const entityNames = {
  MY_TICKET: 'MY_TICKET',
  ALL_TICKET: 'ALL_TICKET',
  GROUPS_TICKETS: 'GROUPS_TICKETS',
  CONTRACTS_FULL: 'CONTRACTS_FULL',
  CONTRACTS_FROM_ORGANIZATIONS: 'CONTRACTS_FROM_ORGANIZATIONS',
  CONTRACTS_FROM_SYSTEMS: 'CONTRACTS_FROM_SYSTEMS',
  GROUPS_FULL: 'GROUPS_FULL',
  GROUPS_FROM_ORGANIZATIONS: 'GROUPS_FROM_ORGANIZATIONS',
  GROUPS_ADD_GROUPS: 'GROUPS_ADD_GROUPS',
  GROUPS_FROM_USERS: 'GROUPS_FROM_USERS',
  ORGANIZATIONS: 'ORGANIZATIONS',
  SYSTEMS_FULL: 'SYSTEMS_FULL',
  SYSTEMS_FROM_CONTRACTS: 'SYSTEMS_FROM_CONTRACTS',
  STATUSES_BUILDER_SYSTEMS: 'STATUSES_BUILDER_SYSTEMS',
  ROLES_FULL: 'ROLES_FULL',
  ROLES_FROM_USERS: 'ROLES_FROM_USERS',
  ROLES_FROM_GROUPS: 'ROLES_FROM_GROUPS',
  ROLES_ADD_MODAL: 'ROLES_ADD_MODAL',
  RULES: 'RULES',
  ACCESSES: 'ACCESSES',
  ACTIONS: 'ACTIONS',
  CUSTOM_FIELDS: 'CUSTOM_FIELDS',
  JIRA_INTEGRATION: 'JIRA_INTEGRATION',
  KB_TABLE_ARTICLES: 'KB_TABLE_ARTICLES',
  RESPONSIBILITIES_FULL: 'RESPONSIBILITIES_FULL',
  RESPONSIBILITIES_FROM_GROUPS: 'RESPONSIBILITIES_FROM_GROUPS',
  RESPONSIBILITIES_FROM_ROLES: 'RESPONSIBILITIES_FROM_ROLES',
  RESPONSIBILITIES_ADD_MODAL: 'RESPONSIBILITIES_ADD_MODAL',
  STORAGE: 'STORAGE',
  TRIGGERS: 'TRIGGERS',
  USERS_FULL: 'USERS_FULL',
  USERS_FROM_GROUPS: 'USERS_FROM_GROUPS',
  USERS_ADD_MODAL: 'USERS_ADD_MODAL',
  USERS_FROM_DESKTOP: 'USERS_FROM_DESKTOP',
  ENVIRONMENT: 'ENVIRONMENT',
};

export const GREY_100 = 'var(--grey100)';
