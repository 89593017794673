import { useSelector } from 'react-redux';

import { ActionList, getIsAccessedAction } from '@entities/auth';
import { AuthLoading as AuthLoadingContent, MainLayout } from '@shared';

import { SidebarLayout } from '../../layouts';

import styles from './AuthLoading.module.scss';

export const AuthLoading = () => {
  const isAccessToCreateTicket = useSelector(
    getIsAccessedAction(ActionList.CreateTicket)
  );

  return (
    <SidebarLayout authLoading>
      <MainLayout
        withHeader={false}
        classNameRoot={styles.authLoading__content}
        accessToCreateTicket={isAccessToCreateTicket}
      >
        <AuthLoadingContent />
      </MainLayout>
    </SidebarLayout>
  );
};
