import {
  ComponentType,
  ReactPropTypes,
  useCallback,
  useLayoutEffect,
} from 'react';
import { useDispatch } from 'react-redux';

import { BREAKPOINTS, ScreenWidth, setScreenWidth } from '@shared';

export const withScreenWidth =
  (Component: ComponentType<ReactPropTypes>) =>
  // eslint-disable-next-line sonarjs/cognitive-complexity
  (props: ReactPropTypes) => {
    const dispatch = useDispatch();

    const changeScreenWidth = useCallback(() => {
      const { innerWidth } = window;
      const { xs, sm, md, lg, xl } = BREAKPOINTS;

      if (innerWidth <= xs) {
        dispatch(setScreenWidth(ScreenWidth.MOBILE_SMALL));
        return;
      }
      if (innerWidth > xs && innerWidth <= sm) {
        dispatch(setScreenWidth(ScreenWidth.MOBILE));
        return;
      }
      if (innerWidth > sm && innerWidth <= md) {
        dispatch(setScreenWidth(ScreenWidth.TABLET_SMALL));
        return;
      }
      if (innerWidth > md && innerWidth <= lg) {
        dispatch(setScreenWidth(ScreenWidth.TABLET));
        return;
      }
      if (innerWidth > lg && innerWidth <= xl) {
        dispatch(setScreenWidth(ScreenWidth.DESKTOP_SMALL));
        return;
      }
      if (innerWidth > xl) {
        dispatch(setScreenWidth(ScreenWidth.DESKTOP));
      }
    }, [dispatch]);

    useLayoutEffect(() => {
      changeScreenWidth();
      window.addEventListener('resize', changeScreenWidth);
      return () => {
        window.removeEventListener('resize', changeScreenWidth);
      };
    }, [changeScreenWidth]);

    return <Component {...props} />;
  };
