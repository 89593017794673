import { call, put, StrictEffect, takeEvery } from 'redux-saga/effects';

import { createError } from '@shared';

import { assessmentInteractionRequests as requests } from '../../api/assessment-interaction';

import {
  fetchImprovementsSuccess,
  fetchTicketEvaluationSuccess,
} from './actions';
import {
  AssessmentInteraction,
  EvaluateSpecialistAction,
  FetchImprovementsRequestAction,
  FetchTicketEvaluationRequestAction,
} from './actionTypes';
import { Improvement, TicketEvaluation } from './types';

function* ticketEvaluationFetch({
  payload,
}: FetchTicketEvaluationRequestAction) {
  try {
    const ticketEvaluation: TicketEvaluation = yield call(
      requests.fetchTicketEvaluation,
      payload
    );
    yield put(fetchTicketEvaluationSuccess(ticketEvaluation));
  } catch (e) {
    createError(e);
  }
}

function* improvementsFetch({ payload }: FetchImprovementsRequestAction) {
  try {
    const improvements: Improvement[] = yield call(
      requests.fetchImprovements,
      payload
    );
    yield put(fetchImprovementsSuccess(improvements));
  } catch (e) {
    createError(e);
  }
}

function* evaluateSpecialist({ payload }: EvaluateSpecialistAction) {
  try {
    yield call(requests.evaluateSpecialist, payload);
    yield call(ticketEvaluationFetch, {
      type: AssessmentInteraction.FETCH_TICKET_EVALUATION_REQUEST,
      payload: payload.ticketId,
    });
  } catch (e) {
    createError(e);
  }
}

export function* assessmentInteractionSaga(): Generator<StrictEffect> {
  yield takeEvery(
    AssessmentInteraction.FETCH_IMPROVEMENTS_REQUEST,
    improvementsFetch
  );
  yield takeEvery(
    AssessmentInteraction.EVALUATE_SPECIALIST,
    evaluateSpecialist
  );
  yield takeEvery(
    AssessmentInteraction.FETCH_TICKET_EVALUATION_REQUEST,
    ticketEvaluationFetch
  );
}
