import { ChangeEvent, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { ENABLE_FIELDS } from '../../config';
import {
  EnableFields,
  ExportFieldsType,
  getExtraExportFields,
} from '../../model';

export const useEnableFields = () => {
  const extraExportFields = useSelector(getExtraExportFields);

  const [enableFields, setEnableFields] = useState<EnableFields>(ENABLE_FIELDS);

  const handleChangeEnable = (event: ChangeEvent<HTMLInputElement>) => {
    const { name, checked } = event.target;
    const value = name as ExportFieldsType;

    setEnableFields((prev) => {
      if (value === 'all') {
        return Object.keys(enableFields).reduce(
          (acc, el) => ({ ...acc, ...{ [el]: checked } }),
          prev
        );
      }
      return { ...prev, ...{ [value]: checked } };
    });
  };

  useEffect(() => {
    setEnableFields((prev) => ({
      ...prev,
      ...{ customFields: !!extraExportFields?.length },
    }));
  }, [extraExportFields]);

  return {
    state: {
      enableFields,
    },
    methods: {
      handleChangeEnable,
    },
  };
};
