import { useEffect, useRef, useState } from 'react';
// import { useDispatch, useSelector } from 'react-redux';
import useWebSocket from 'react-use-websocket';

// TODO hotfix/tsp-2573
// import { deleteAlert, setAlert } from 'core/ducks/actions';
// import { getAlerts } from 'core/ducks/selectors';
// import { createErrorAlert, createSuccessAlert } from 'core/layouts';
import { useTicketId } from '@entities/ticket';
import { AuthService } from '@shared';

import { WS_CONNECTION_STATUS } from '../../config';
import { getSocketUrl } from '../utils';

export const useTicketCommentsWebSocket = () => {
  // const dispatch = useDispatch();
  const ticketId = useTicketId();
  // const alerts = useSelector(getAlerts) || [];

  const [socketUrl] = useState(getSocketUrl(ticketId));
  const didUnmount = useRef(false);

  const { sendMessage, lastJsonMessage, readyState } = useWebSocket(socketUrl, {
    protocols: ['access_token', AuthService.getToken() || ''],
    shouldReconnect: () => !didUnmount.current,
    reconnectAttempts: 10,
    reconnectInterval: 3000,
    share: true,
  });

  // TODO hotfix/tsp-2573
  // const existSuccessAlert = alerts.some(
  //   (alert) => alert.message === WS_SUCCESS
  // );
  // const errorAlert = alerts.find((alert) => alert.message === WS_ERROR);

  const wsStatus = WS_CONNECTION_STATUS[readyState];

  const status = {
    connecting: wsStatus === 'Connecting',
    open: wsStatus === 'Open',
    closing: wsStatus === 'Closing',
    closed: wsStatus === 'Closed',
    uninstantiated: wsStatus === 'Uninstantiated',
  };

  // const isStatusClose = status.closing || status.closed;

  // useEffect(() => {
  //   if (status.open) {
  //     if (!existSuccessAlert && errorAlert) {
  //       dispatch(setAlert(createSuccessAlert(WS_SUCCESS)));
  //     }

  //     if (errorAlert) {
  //       dispatch(deleteAlert(errorAlert.id));
  //     }
  //   }
  // }, [status.open]);

  // useEffect(() => {
  //   if (isStatusClose && !errorAlert) {
  //     dispatch(setAlert(createErrorAlert(WS_ERROR, true)));
  //   }
  // }, [isStatusClose]);

  useEffect(
    () => () => {
      didUnmount.current = true;
    },
    []
  );

  return {
    state: {
      lastJsonMessage,
      status,
    },
    methods: {
      sendMessage,
    },
  };
};
