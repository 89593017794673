import { FC } from 'react';

import { TicketNavIcon, Typography, TypographyVariants } from '@shared';

import styles from './SlaWarning.module.scss';

export const SlaWarning: FC = () => (
  <div className={styles.slaWarning}>
    <div className={styles.slaWarning__icons}>
      <TicketNavIcon className={styles.slaWarning__iconsNav} />
    </div>
    <Typography
      variant={TypographyVariants.b3}
      className={styles.slaWarning__text}
    >
      Нет данных
    </Typography>
  </div>
);
