import { FC } from 'react';

import { CrossIcon as CloseFilter } from '@shared/assets';
import { IconButton } from '@shared/ui/IconButton';
import { TableBodyCell, TableRow } from '@shared/ui/Table';

import styles from './TableFilter.module.scss';

interface TableFilterProps {
  filterComponent: JSX.Element;
  onReset?: () => void;
  disableReset?: boolean;
  withCheckbox?: boolean;
}

export const TableFilter: FC<TableFilterProps> = ({
  filterComponent,
  onReset,
  disableReset,
  withCheckbox,
}) => (
  <form className={styles.filter}>
    <TableRow className={styles.filter__row} isFilter>
      {withCheckbox && <div className={styles.filter__emptyCell} />}
      {filterComponent}
      <TableBodyCell className={styles.filter__closeButtonCell}>
        <IconButton
          icon={<CloseFilter className={styles.filter__icon} />}
          appearance="flat"
          onClick={onReset}
          disabled={disableReset}
          className={styles.filter__closeButton}
        />
      </TableBodyCell>
    </TableRow>
  </form>
);
