import { Suspense } from 'react';
import { useSelector } from 'react-redux';
import { Switch } from 'react-router-dom';

import { getAccessedRoutes } from '@entities/auth';
import { LazyLoader } from '@shared';

import { routerConfig } from './config';
import { getRouteList } from './utils';

export const SwitchElements = () => {
  const accessedRoutes = useSelector(getAccessedRoutes);

  const switchesEls = getRouteList(routerConfig, accessedRoutes);

  return (
    <Suspense fallback={<LazyLoader />}>
      <Switch>{switchesEls}</Switch>
    </Suspense>
  );
};
