import { SupplementaryAgreementState } from './types';

export const getLoadingSupAgDetail = (state: SupplementaryAgreementState) =>
  state.supplementaryAgreementDetail.supplementaryAgreementLoading;
export const getSupAgDetail = (state: SupplementaryAgreementState) =>
  state.supplementaryAgreementDetail.supplementaryAgreement;
export const getSystemsSupAgDetail = (state: SupplementaryAgreementState) =>
  state.supplementaryAgreementDetail.systems;
export const getSystemIdForSupAg = (state: SupplementaryAgreementState) => {
  const systems = getSystemsSupAgDetail(state);
  return systems?.length ? systems[0]?.id : undefined;
};
export const getSystemsLoadingSupAgDetail = (
  state: SupplementaryAgreementState
) => state.supplementaryAgreementDetail.systemsLoading;

export const getAttachmentsSupAgDetail = (state: SupplementaryAgreementState) =>
  state.supplementaryAgreementDetail.attachments;
export const getLoadingAttachmentsSupAgDetail = (
  state: SupplementaryAgreementState
) => state.supplementaryAgreementDetail.attachmentsLoading;

export const getGroupsSupAgDetail = (state: SupplementaryAgreementState) =>
  state.supplementaryAgreementDetail.groups;
export const getGroupsLoadingSupAgDetail = (
  state: SupplementaryAgreementState
) => state.supplementaryAgreementDetail.groupsLoading;
export const getGroupsFilterSupAgDetail = (
  state: SupplementaryAgreementState
) => state.supplementaryAgreementDetail.groupsFilter || {};
export const getPropsGroupsSupAgDetail = (
  state: SupplementaryAgreementState
) => ({
  pageNum: state.supplementaryAgreementDetail.groupsPagination?.pageNum,
  pageSize: state.supplementaryAgreementDetail.groupsPagination?.pageSize,
  totalElements:
    state.supplementaryAgreementDetail.groupsPagination?.totalElements,
  sort: state.supplementaryAgreementDetail.groupsSort,
  loading: getGroupsLoadingSupAgDetail(state),
});

export const getSLASupAgDetail = (state: SupplementaryAgreementState) =>
  state.supplementaryAgreementDetail.sla;
export const getTicketTypesSupAgDetail = (state: SupplementaryAgreementState) =>
  state.supplementaryAgreementDetail.ticketTypes || [];
export const getSLALoadingSupAgDetail = (state: SupplementaryAgreementState) =>
  state.supplementaryAgreementDetail.slaLoading;

export const getTitleForCreateSupAg = (state: SupplementaryAgreementState) =>
  state.supplementaryAgreementDetail.titleForCreateSupAg;
