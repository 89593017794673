import { debounce } from 'lodash';
import { FC, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
  DEFAULT_DEBOUNCE_DELAY,
  Select,
  SelectProps,
  Size,
  useInfiniteScroll,
} from '@shared';

import {
  getPropsSystems,
  getSystemsSelectList,
  resetSystemsState,
  setCurrentSystemsPage,
  setSortSystems,
  setSystemsFilter,
} from '../../model';

interface SystemSelectProps extends Omit<SelectProps, 'options'> {}

export const SystemSelect: FC<SystemSelectProps> = (props) => {
  const { className, placeholder, isMulti = true } = props;
  const dispatch = useDispatch();
  const { totalElements, pageNum, pageSize, loadingSystem } =
    useSelector(getPropsSystems);
  const systemsSelectList = useSelector(getSystemsSelectList);

  const { totalPage } = useInfiniteScroll({
    pageNum,
    pageSize,
    totalElements,
  });

  const onFilterSystem = debounce((valueFilter: string) => {
    dispatch(setCurrentSystemsPage(0));
    dispatch(setSystemsFilter({ title: valueFilter }));
  }, DEFAULT_DEBOUNCE_DELAY);

  const setNextPageSystem = (valuePage: number) => {
    dispatch(setCurrentSystemsPage(valuePage));
  };

  useEffect(() => {
    dispatch(setCurrentSystemsPage(0));
    dispatch(setSystemsFilter({}));
    dispatch(setSortSystems('TITLE_ASC'));
    return () => {
      dispatch(resetSystemsState());
    };
  }, []);
  return (
    <Select<string>
      {...props}
      size={Size.xs}
      options={systemsSelectList}
      onChangeInput={onFilterSystem}
      infiniteScrollable
      currentPage={pageNum}
      totalPage={totalPage}
      setNextPage={setNextPageSystem}
      classNameContainer={className}
      loading={loadingSystem}
      placeholder={placeholder}
      isMulti={isMulti}
      isSearchable
      isClearable
    />
  );
};
