import { ObjectType } from '@shared/model';

import { PaginationType } from '../../Pagination/model';
import { SelectProps } from '../../Select';

export type UpdateContentType = 'join' | 'update';

export enum TicketsTabType {
  MY_TICKETS = 'MY_TICKETS',
  ALL_TICKETS = 'ALL_TICKETS',
  MY_GROUPS_TICKETS = 'MY_GROUPS_TICKETS',
}

export interface SearchSelectPaginationParams
  extends Pick<PaginationType, 'pageSize' | 'pageNum' | 'totalElements'> {}

export type FetchRequestArgs<T extends ObjectType> = {
  filter?: T;
  sort?: string;
  updateType?: UpdateContentType;
  searchValue?: string;
  loading?: boolean;
} & SearchSelectPaginationParams;

type FetchRequest<T extends ObjectType> = (payload: FetchRequestArgs<T>) => {
  type: string;
  payload: T;
};

export interface SearchSelectProps<T extends ObjectType, K = string>
  extends Omit<
    SelectProps<K>,
    | 'isSearchable'
    | 'isMulti'
    | 'isClearable'
    | 'infiniteScrollable'
    | 'setNextPage'
    | 'totalPage'
    | 'currentPage'
    | 'onChangeInput'
  > {
  fetchRequest: FetchRequest<T>;
  resetState: () => void;
  dataPagination: PaginationType;
  ticketTab?: TicketsTabType;
  filter?: T;
  triggerFetchField?: string;
}
