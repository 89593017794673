import { combineReducers, Reducer } from 'redux';

import { accessesSlice } from '@entities/accesses';
import { actionsSlice } from '@entities/actions';
import { currentUserSlice } from '@entities/auth';
import { contractDetailSlice } from '@entities/contract';
import { contractsSlice } from '@entities/contracts';
import { createTicketSlice } from '@entities/create-ticket';
import { customFieldsSlice } from '@entities/custom-fields';
import {
  desktop,
  desktopCurrentState,
  desktopEmployees,
  desktopHistory,
  desktopKpi,
} from '@entities/desktop';
import { jiraSlice } from '@entities/jira-integrations';
import {
  articleCreateSlice,
  articleSlice,
  articlesMainSlice,
  articlesTableSlice,
} from '@entities/knowledge-base';
import { notificationsSlice } from '@entities/notifications';
import { organizationsSlice } from '@entities/organizations';
import { notificationsProfileSlice } from '@entities/profile';
import { responsibilitiesSlice } from '@entities/responsibilities';
import { rolesSlice } from '@entities/roles';
import { rules } from '@entities/rules';
import { similarSolutionsSlice } from '@entities/similar-solutions';
import { statusesBuilderSlice } from '@entities/statuses-builder';
import { storage } from '@entities/storage';
import { storages } from '@entities/storages';
import { supplementaryAgreementDetail } from '@entities/supplementary-agreement';
import { system } from '@entities/system';
import { systemsSlice } from '@entities/systems';
import {
  assessmentInteractionSlice,
  attachmentsSlice,
  notesSlice,
  tagsSlice,
  ticketSlice,
} from '@entities/ticket';
import { ticketsSlice } from '@entities/tickets';
import { ticketsExportSlice } from '@entities/tickets-export';
import { triggers } from '@entities/triggers';
import { userAssistance } from '@entities/user-assistance';
import { usersSlice } from '@entities/users';
import { workGroupsSlice } from '@entities/work-groups';
import { core } from '@shared';

import { Root } from './types';

const appReducer = combineReducers({
  accesses: accessesSlice,
  actions: actionsSlice,
  articleCreate: articleCreateSlice,
  article: articleSlice,
  articlesMain: articlesMainSlice,
  articlesTable: articlesTableSlice,
  assessmentInteraction: assessmentInteractionSlice,
  attachments: attachmentsSlice,
  contractDetail: contractDetailSlice,
  contracts: contractsSlice,
  core,
  createTicket: createTicketSlice,
  currentUser: currentUserSlice,
  customFields: customFieldsSlice,
  desktop,
  desktopCurrentState,
  desktopEmployees,
  desktopHistory,
  desktopKpi,
  jira: jiraSlice,
  notes: notesSlice,
  notifications: notificationsSlice,
  notificationsProfile: notificationsProfileSlice,
  organizations: organizationsSlice,
  responsibilities: responsibilitiesSlice,
  roles: rolesSlice,
  rules,
  similarSolutions: similarSolutionsSlice,
  statusesBuilder: statusesBuilderSlice,
  storage,
  storages,
  supplementaryAgreementDetail,
  system,
  systems: systemsSlice,
  tags: tagsSlice,
  ticket: ticketSlice,
  tickets: ticketsSlice,
  ticketsExport: ticketsExportSlice,
  triggers,
  userAssistance,
  users: usersSlice,
  workGroups: workGroupsSlice,
});

export type RootState = ReturnType<typeof appReducer>;

export const rootReducer: Reducer<RootState> = (state, action) => {
  if (action.type === Root.RESET_STORE) {
    return appReducer(undefined, action);
  }
  return appReducer(state, action);
};
