import { TableSystemsTypes } from '@entities/system';
import { RenderTypes, TableColumns } from '@shared';

export const TABLE_HEADER_FULL_FIELDS: TableColumns = [
  {
    title: 'Название',
    sortField: 'title',
    fieldName: 'title',
    renderType: RenderTypes.NOTIFICATION_LINK,
  },
  { title: 'Описание', sortField: 'description', fieldName: 'description' },
  {
    title: 'Версия',
    sortField: 'version',
    fieldName: 'versionTitle',
    renderType: RenderTypes.CONDITION,
  },
  {
    title: 'Организация',
    sortField: 'organization',
    fieldName: 'organizationId',
    renderType: RenderTypes.NOTIFICATION_LINK,
  },
  {
    title: 'Контракт',
    fieldName: 'activeContract',
  },
  {
    title: 'Тип тикета',
    fieldName: 'ticketType',
  },
  {
    title: 'Среда',
    fieldName: 'environment',
  },
];

export const PAGINATION_RANGE_MAP: Record<
  TableSystemsTypes,
  number | undefined
> = {
  [TableSystemsTypes.FULL]: 1,
  [TableSystemsTypes.FROM_CONTRACTS]: 0,
};

export const DEFAULT_SORT_FILTER_SYSTEMS = 'TITLE_ASC';
