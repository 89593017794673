import { call, put, StrictEffect, takeEvery } from 'redux-saga/effects';

import { Attachment } from '@entities/attachment/model/types';
import { Article } from '@entities/knowledge-base/model/types';
import { Ticket } from '@entities/ticket/model/types';
import { createError, ResponseWithMeta } from '@shared';

import { requests } from '../api';

import {
  fetchArticlesSuccess,
  fetchSimilarArticleSuccess,
  fetchSimilarTicketAttachmentsSuccess,
  fetchSimilarTicketSuccess,
  fetchTicketsSuccess,
  hideLoading,
  hideSimilarDetailLoading,
  showLoading,
  showSimilarDetailLoading,
} from './actions';
import {
  FetchArticlesRequestAction,
  FetchSimilarArticleRequestAction,
  FetchSimilarTicketAttachmentsRequestAction,
  FetchSimilarTicketRequestAction,
  FetchTicketsRequestAction,
  SimilarSolutions,
} from './actionTypes';

function* ticketsFetch({ payload }: FetchTicketsRequestAction) {
  try {
    yield put(showLoading());
    const tickets: ResponseWithMeta<Ticket[]> = yield call(
      requests.fetchTickets,
      payload
    );
    yield put(fetchTicketsSuccess(tickets));
    yield put(hideLoading());
  } catch (e) {
    createError(e);
    yield put(hideLoading());
  }
}

function* articlesFetch({ payload }: FetchArticlesRequestAction) {
  try {
    yield put(showLoading());
    const articles: ResponseWithMeta<Article[]> = yield call(
      requests.fetchArticles,
      payload
    );
    yield put(fetchArticlesSuccess(articles));
    yield put(hideLoading());
  } catch (e) {
    createError(e);
    yield put(hideLoading());
  }
}

function* articleFetch({ payload }: FetchSimilarArticleRequestAction) {
  try {
    yield put(showSimilarDetailLoading());
    const article: Article = yield call(requests.fetchArticle, payload);
    yield put(fetchSimilarArticleSuccess(article));
    yield put(hideSimilarDetailLoading());
  } catch (e) {
    yield put(hideSimilarDetailLoading());
    createError(e);
  }
}

function* ticketAttachmentsFetch({
  payload,
}: FetchSimilarTicketAttachmentsRequestAction) {
  try {
    const attachments: Attachment[] = yield call(
      requests.fetchTicketAttachments,
      payload
    );
    yield put(fetchSimilarTicketAttachmentsSuccess(attachments));
  } catch (e) {
    createError(e);
  }
}

function* ticketFetch({ payload }: FetchSimilarTicketRequestAction) {
  try {
    yield put(showSimilarDetailLoading());
    const ticket: Ticket = yield call(requests.fetchTicket, payload);
    yield put(fetchSimilarTicketSuccess(ticket));
    yield call(ticketAttachmentsFetch, {
      type: SimilarSolutions.FETCH_SIMILAR_TICKET_ATTACHMENTS_REQUEST,
      payload,
    });
    yield put(hideSimilarDetailLoading());
  } catch (e) {
    yield put(hideSimilarDetailLoading());
    createError(e);
  }
}

export function* similarSolutionsSaga(): Generator<StrictEffect> {
  yield takeEvery(SimilarSolutions.FETCH_SIMILAR_TICKETS_REQUEST, ticketsFetch);
  yield takeEvery(SimilarSolutions.FETCH_SIMILAR_TICKET_REQUEST, ticketFetch);
  yield takeEvery(
    SimilarSolutions.FETCH_SIMILAR_ARTICLES_REQUEST,
    articlesFetch
  );
  yield takeEvery(SimilarSolutions.FETCH_SIMILAR_ARTICLE_REQUEST, articleFetch);
}
