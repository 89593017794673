import { Ticket } from '@entities/ticket';
import { TabItem } from '@shared';

export const getMatchedTicketTypeValue = (
  items: TabItem[],
  ticket?: Ticket
) => {
  const matchingTicketType = items.find(
    (item) => item.title === ticket?.ticketType?.value
  );
  return matchingTicketType?.value;
};
