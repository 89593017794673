import { FC } from 'react';

import { Tooltip, WarningIcon } from '@shared';

import styles from './InfoTooltip.module.scss';

interface InfoTooltipProps {
  isUpdated?: boolean;
  updatedFrom?: string | null;
}

export const InfoTooltip: FC<InfoTooltipProps> = ({ isUpdated, updatedFrom }) =>
  isUpdated && updatedFrom ? (
    <>
      <WarningIcon data-tip data-for={updatedFrom} />
      <Tooltip id={updatedFrom} place="bottom" className={styles.infoTooltip}>
        {updatedFrom}
      </Tooltip>
    </>
  ) : null;
