import { FC } from 'react';

import { Attachment } from '@entities/attachment';
import { AttachmentDetail } from '@features/contract';
import { DataEmpty, Typography, TypographyVariants } from '@shared';

import styles from './AttachmentsSupAg.module.scss';

interface AttachmentsSupAgProps {
  attachments?: Attachment[];
}

export const AttachmentsSupAg: FC<AttachmentsSupAgProps> = ({
  attachments,
}) => {
  const content = attachments?.length ? (
    attachments?.map((attachment) => (
      <AttachmentDetail attachment={attachment} key={attachment.id} />
    ))
  ) : (
    <DataEmpty className={styles.attachmentsContract__empty} />
  );

  return (
    <div className={styles.attachmentsContract}>
      <Typography
        variant={TypographyVariants.h4}
        className={styles.attachmentsContract__header}
      >
        Вложения
      </Typography>
      <div className={styles.attachmentsContract__list}>{content}</div>
    </div>
  );
};
