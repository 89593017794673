import { Attachment, AttachmentsFiles } from '@entities/attachment/model/types';

import {
  AddAttachmentsRequestAction,
  AddMessageAttachmentsAction,
  Attachments,
  FetchContractAttachmentsRequestAction,
  FetchContractAttachmentsSuccessAction,
  FetchTicketAttachmentsRequestAction,
  FetchTicketAttachmentsSuccessAction,
  FetchTicketCommentsAttachmentsRequestAction,
  FetchTicketCommentsAttachmentsSuccessAction,
  HideAttachmentsLoadingAction,
  ResetAttachmentsStateAction,
  ShowAttachmentsLoadingAction,
} from './actionTypes';
import {
  AddMessageAttachmentsPayload,
  AttachmentsRequest,
  DeleteFileAttachmentsTicketRequest,
} from './types';

export const showAttachmentsLoading = (): ShowAttachmentsLoadingAction => ({
  type: Attachments.ATTACHMENTS_LOADING_SHOW,
});

export const hideAttachmentsLoading = (): HideAttachmentsLoadingAction => ({
  type: Attachments.ATTACHMENTS_LOADING_HIDE,
});

export const fetchTicketAttachmentsRequest = (
  ticketId: string
): FetchTicketAttachmentsRequestAction => ({
  type: Attachments.FETCH_TICKET_ATTACHMENTS_REQUEST,
  payload: ticketId,
});

export const fetchContractAttachmentsSuccess = (
  attachments: Attachment[]
): FetchContractAttachmentsSuccessAction => ({
  type: Attachments.FETCH_CONTRACT_ATTACHMENTS_SUCCESS,
  payload: attachments,
});

export const fetchContractAttachmentsRequest =
  (): FetchContractAttachmentsRequestAction => ({
    type: Attachments.FETCH_CONTRACT_ATTACHMENTS_REQUEST,
  });

export const fetchTicketAttachmentsSuccess = (
  attachments: Attachment[]
): FetchTicketAttachmentsSuccessAction => ({
  type: Attachments.FETCH_TICKET_ATTACHMENTS_SUCCESS,
  payload: attachments,
});

export const addMessageAttachments = (
  payload: AddMessageAttachmentsPayload
): AddMessageAttachmentsAction => ({
  type: Attachments.ADD_MESSAGE_ATTACHMENT_REQUEST,
  payload,
});

export const fetchTicketCommentsAttachmentsRequest = (
  ticketId: string
): FetchTicketCommentsAttachmentsRequestAction => ({
  type: Attachments.FETCH_TICKET_COMMENTS_ATTACHMENTS_REQUEST,
  payload: ticketId,
});

export const fetchTicketCommentsAttachmentsSuccess = (
  attachments: AttachmentsFiles[]
): FetchTicketCommentsAttachmentsSuccessAction => ({
  type: Attachments.FETCH_TICKET_COMMENTS_ATTACHMENTS_SUCCESS,
  payload: attachments,
});

export const addAttachmentsRequest = (
  attachment: AttachmentsRequest
): AddAttachmentsRequestAction => ({
  type: Attachments.ADD_ATTACHMENTS_REQUEST,
  payload: attachment,
});

export const resetAttachmentsState = (): ResetAttachmentsStateAction => ({
  type: Attachments.RESET_ATTACHMENTS_STATE,
});

export const deleteFileAttachmentsTicketRequest = (
  payload: DeleteFileAttachmentsTicketRequest
) => ({
  type: Attachments.DELETE_FILE_ATTACHMENT_TICKET_REQUEST,
  payload,
});
