import { ResponseWithMeta } from '@shared';

import {
  CreateResponsibilityAction,
  FetchRespByGroupIdRequestAction,
  FetchRespByPosIdRequestAction,
  FetchRespByRoleIdRequestAction,
  FetchResponsibilitiesRequestAction,
  FetchResponsibilitiesSuccessAction,
  HideResponsibilitiesAddLoadingAction,
  HideResponsibilitiesLoadingAction,
  ResetCurrentResponsibilityAction,
  Responsibilities,
  SetCurrentResponsibilitiesPageAction,
  SetCurrentResponsibilityIdAction,
  SetResponsibilitiesAddPageAction,
  SetSizePageAction,
  SetSizePageResponsibilitiesAddAction,
  SetSortResponsibilitiesAction,
  ShowResponsibilitiesAddLoadingAction,
  ShowResponsibilitiesLoadingAction,
} from './actionTypes';
import {
  CreateResponsibilityType,
  Responsibility,
  ResponsibilityFilter,
  UpdateResponsibilityData,
} from './types';

export const showResponsibilitiesLoading =
  (): ShowResponsibilitiesLoadingAction => ({
    type: Responsibilities.RESPONSIBILITIES_LOADING_SHOW,
  });

export const hideResponsibilitiesLoading =
  (): HideResponsibilitiesLoadingAction => ({
    type: Responsibilities.RESPONSIBILITIES_LOADING_HIDE,
  });

export const showResponsibilitiesAddLoading =
  (): ShowResponsibilitiesAddLoadingAction => ({
    type: Responsibilities.RESPONSIBILITIES_ADD_LOADING_SHOW,
  });

export const hideResponsibilitiesAddLoading =
  (): HideResponsibilitiesAddLoadingAction => ({
    type: Responsibilities.RESPONSIBILITIES_ADD_LOADING_HIDE,
  });

export const fetchResponsibilitiesRequest =
  (): FetchResponsibilitiesRequestAction => ({
    type: Responsibilities.FETCH_RESPONSIBILITIES_REQUEST,
  });

export const fetchResponsibilitiesSuccess = (
  data: ResponseWithMeta<Responsibility[]>
): FetchResponsibilitiesSuccessAction => ({
  type: Responsibilities.FETCH_RESPONSIBILITIES_SUCCESS,
  payload: data,
});

export const setCurrentResponsibilitiesPage = (
  page: number
): SetCurrentResponsibilitiesPageAction => ({
  type: Responsibilities.SET_CURRENT_RESPONSIBILITIES_PAGE,
  payload: page,
});

export const setSizePage = (size: number): SetSizePageAction => ({
  type: Responsibilities.SET_SIZE_PAGE_RESPONSIBILITIES,
  payload: size,
});

export const setResponsibilitiesAddPage = (
  page: number
): SetResponsibilitiesAddPageAction => ({
  type: Responsibilities.SET_RESPONSIBILITIES_ADD_PAGE,
  payload: page,
});

export const setSizePageResponsibilitiesAdd = (
  size: number
): SetSizePageResponsibilitiesAddAction => ({
  type: Responsibilities.SET_SIZE_PAGE_RESPONSIBILITIES_ADD,
  payload: size,
});

export const setSortResponsibilities = (
  sort: string
): SetSortResponsibilitiesAction => ({
  type: Responsibilities.SET_SORT_RESPONSIBILITIES,
  payload: sort,
});

export const setCurrentResponsibilityId = (
  id: string
): SetCurrentResponsibilityIdAction => ({
  type: Responsibilities.SET_CURRENT_RESPONSIBILITY_ID,
  payload: id,
});

export const fetchRespByPosIdRequest = (): FetchRespByPosIdRequestAction => ({
  type: Responsibilities.FETCH_RESPONSIBILITY_BY_POSITION_ID_REQUEST,
});

export const resetResponsibilitiesState = () => ({
  type: Responsibilities.RESET_RESPONSIBILITIES_STATE,
});

export const fetchRespByGroupIdRequest =
  (): FetchRespByGroupIdRequestAction => ({
    type: Responsibilities.FETCH_RESPONSIBILITY_BY_GROUP_ID_REQUEST,
  });

export const fetchRespByRoleIdRequest = (): FetchRespByRoleIdRequestAction => ({
  type: Responsibilities.FETCH_RESPONSIBILITY_BY_ROLE_ID_REQUEST,
});

export const createResponsibility = (
  data: CreateResponsibilityType
): CreateResponsibilityAction => ({
  type: Responsibilities.CREATE_RESPONSIBILITY,
  payload: data,
});

export const setResponsibilitiesAddFilter = (data: ResponsibilityFilter) => ({
  type: Responsibilities.SET_RESPONSIBILITIES_ADD_FILTER,
  payload: data,
});

export const setResponsibilitiesFilter = (data: ResponsibilityFilter) => ({
  type: Responsibilities.SET_RESPONSIBILITIES_FILTER,
  payload: data,
});

export const fetchResponsibilitiesAddRequest = () => ({
  type: Responsibilities.FETCH_RESPONSIBILITIES_ADD_REQUEST,
});

export const fetchResponsibilitiesAddSuccess = (
  data: ResponseWithMeta<Responsibility[]>
) => ({
  type: Responsibilities.FETCH_RESPONSIBILITIES_ADD_SUCCESS,
  payload: data,
});

export const updateResponsibility = (data: UpdateResponsibilityData) => ({
  type: Responsibilities.UPDATE_RESPONSIBILITY,
  payload: data,
});

export const resetResponsibilitiesAddState = () => ({
  type: Responsibilities.RESET_RESPONSIBILITIES_ADD_STATE,
});

export const fetchResponsibilityByIdRequest = (id: string) => ({
  type: Responsibilities.FETCH_RESPONSIBILITY_BY_ID_REQUEST,
  payload: id,
});

export const fetchResponsibilityByIdSuccess = (data: Responsibility) => ({
  type: Responsibilities.FETCH_RESPONSIBILITY_BY_ID_SUCCESS,
  payload: data,
});

export const deleteResponsibilityRequest = (id: string) => ({
  type: Responsibilities.DELETE_RESPONSIBILITY_BY_ID_REQUEST,
  payload: id,
});

export const resetCurrentResponsibility =
  (): ResetCurrentResponsibilityAction => ({
    type: Responsibilities.RESET_CURRENT_RESPONSIBILITY,
  });
