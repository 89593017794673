import { Organization } from '@entities/organizations';
import { WorkGroup } from '@entities/work-group';
import { SelectOption, UpdateContentType, ValueType } from '@shared';

export enum AccessType {
  PRIVATE = 'PRIVATE',
  PUBLIC = 'PUBLIC',
}

export type Article = {
  id?: string;
  title: string;
  text: string;
  organizations: Organization[];
  workgroups: WorkGroup[];
  userId: string;
  userFullName: string;
  categoryId: string;
  categoryTitle: string;
  parentCategoryTitle?: string;
  parentCategoryId?: string;
  accessType: AccessType;
  systemId?: string;
  systemVersion?: string;
  systemTitle?: string;
  created: Date;
  updated: Date;
};

export type CategoryArticle = {
  id?: string;
  title: string;
  parentId?: string;
  parentTitle?: string;
  organizations?: Organization[];
  children?: Array<CategoryArticle>;
  articles?: Article[];
  accessType?: AccessType;
};

export type ArticleCreateData = Partial<Article>;

export type ArticleCreateFormData = Pick<Article, 'text' | 'title'> & {
  organizationIds: SelectOption[];
  category: ValueType<string>;
  parentCategory?: ValueType<string>;
  accessType: ValueType<AccessType>;
  workgroupIds?: SelectOption[];
};

export type CreateCategoryArticle = {
  organizationIds?: string[];
} & Omit<CategoryArticle, 'organizations'>;

export type CategoryArticleCreateForm = Omit<
  CategoryArticle,
  'organizationId' | 'children' | 'accessType' | 'articles' | 'organizations'
> & {
  organizationIds: SelectOption[];
  accessType: ValueType<AccessType>;
};

export interface KBArticleTableFilter
  extends Partial<Pick<Article, 'updated' | 'title'>> {
  organization?: ValueType<string>;
  category?: ValueType<string>;
  parentCategory?: ValueType<string>;
  username?: string;
}

export interface KBArticleTableFilterToRequest
  extends Omit<
    KBArticleTableFilter,
    'organization' | 'category' | 'parentCategory'
  > {
  organizationIds?: string[];
  categoryId?: string;
  parentCategoryId?: string;
}

export interface KBOrganizationsFilter {
  organizationTitle?: string;
}

export type FetchArticlesParams = {
  updateType: UpdateContentType;
  page: number;
};
