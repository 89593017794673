import cn from 'clsx';
import { FC, HTMLAttributes, memo } from 'react';

import styles from './TableBodyCell.module.scss';

interface TableBodyCellProps extends HTMLAttributes<HTMLDivElement> {}

export const TableBodyCell: FC<TableBodyCellProps> = memo(
  ({ className, children, style }) => (
    <div style={style} className={cn(styles.tableBodyCell, className)}>
      {children}
    </div>
  )
);
