import {
  IssuePriorityEditDtos,
  IssueStatusDtos,
  JiraIntegration,
  JiraIntegrationEditData,
  JiraPriority,
  JiraStatus,
  JiraTicketType,
} from '@entities/jira-integrations';
import { convertValueTypeToValue, getValueFromValueType } from '@shared';

type Args = {
  data: JiraIntegrationEditData;
  statuses: JiraStatus[];
  priorities: JiraPriority[];
  jiraTicketTypes?: JiraTicketType[];
  jiraIntegration?: JiraIntegration;
  systemId?: string;
  statusesDisabled?: boolean;
  prioritiesDisabled?: boolean;
  jiraTicketTypeDisabled?: boolean;
};

export const getPreparedDataForEdit = ({
  data,
  statuses,
  priorities,
  jiraTicketTypes,
  jiraIntegration,
  systemId,
  statusesDisabled,
  prioritiesDisabled,
  jiraTicketTypeDisabled,
}: Args) => {
  const ticketTypeId = convertValueTypeToValue<string>(data.ticketTypeId);

  const { jiraTicketType } = data;

  const args = {
    systemId,
    ticketTypeId,
  };

  const currentJiraTicketType = jiraTicketTypes?.find(
    (item) => item.idType === getValueFromValueType(jiraTicketType)
  );

  const ticketTypeUpdateDto = {
    ...currentJiraTicketType,
    propertyId: jiraIntegration?.id || '',
    ticketTypeId: ticketTypeId || '',
  };

  const issueStatusUpdateDtos = statuses.reduce((acc, el) => {
    const result = acc.find((item) => item.idStatus === el.idStatus);
    const { ticketStatusIds } = el;
    const ids = ticketStatusIds?.map((item) => item.id) || [];

    if (result) {
      return [
        ...acc.filter((item) => item.idStatus !== el.idStatus),
        {
          ...el,
          ticketStatusIds: [...ids, ...result.ticketStatusIds],
        },
      ];
    }

    return [...acc, { ...el, ticketStatusIds: ids }];
  }, [] as IssueStatusDtos[]);

  const issueTicketPriorityDtos = priorities.reduce((acc, el) => {
    const result = acc.find((item) => item.idPriority === el.idPriority);
    const { ticketPriorities } = el;

    if (result && result.ticketPriorities && ticketPriorities) {
      return [
        ...acc.filter((item) => item.idPriority !== el.idPriority),
        {
          ...el,
          ticketPriorities: [...ticketPriorities, ...result.ticketPriorities],
        },
      ];
    }

    return [...acc, { ...el, ticketPriorities }];
  }, [] as IssuePriorityEditDtos[]);
  return {
    ...(!statusesDisabled && {
      statusMapping: {
        ...args,
        issueStatusUpdateDtos,
      },
    }),
    ...(!prioritiesDisabled && {
      priorityMapping: {
        ...args,
        issueTicketPriorityDtos,
      },
    }),
    ...(!jiraTicketTypeDisabled && { ticketTypeUpdateDto }),
    ticketTypeId,
  };
};
