import { WorkGroup } from '@entities/work-group';

export const getDataTable = (workGroups?: WorkGroup[]) =>
  workGroups?.map((workGroup) => {
    const { title, description, id } = workGroup;
    return {
      id,
      title,
      description,
    };
  }) || [];
