import cn from 'clsx';
import { FC, MouseEvent, useEffect } from 'react';

import { CloseIcon, ErrorIcon, SuccessIcon } from '../../assets';
import { Typography, TypographyVariants } from '../Typography';

import { ToastType } from './model';
import styles from './Toast.module.scss';

const backgroundStylesMap = {
  error: styles.toast_error,
  success: styles.toast_success,
};

const iconMap = {
  error: <ErrorIcon className={styles.toast__icon} />,
  success: <SuccessIcon className={styles.toast__icon} />,
};

const titleStylesMap = {
  error: styles.toast__title_error,
  success: styles.toast__title_success,
};

const closeIconStylesMap = {
  error: styles.toast__iconClose_error,
  success: styles.toast__iconClose_success,
};

interface ToastProps {
  title: string;
  onClose(): void;
  type?: ToastType;
}

export const Toast: FC<ToastProps> = ({ title, onClose, type = 'success' }) => {
  useEffect(() => {
    const timer = setTimeout(() => {
      onClose();
    }, 7000);
    return () => {
      clearTimeout(timer);
    };
  }, []);

  const handleClick = (event: MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
  };

  return (
    <div className={cn(styles.toast, backgroundStylesMap[type])}>
      <div className={styles.toast__content}>
        {iconMap[type]}
        <Typography
          variant={TypographyVariants.h4}
          className={cn(styles.toast__title, titleStylesMap[type])}
        >
          {title}
        </Typography>
      </div>
      <button
        onMouseDown={handleClick}
        onClick={onClose}
        className={styles.toast__buttonClose}
      >
        <CloseIcon
          className={cn(styles.toast__iconClose, closeIconStylesMap[type])}
        />
      </button>
    </div>
  );
};
