import cn from 'clsx';
import { nanoid } from 'nanoid';
import { Controller } from 'react-hook-form';

import {
  AlertItem,
  AlertTypes,
  ArrowHorizontalIcon,
  Button,
  CardTableHeader,
  DataEmpty,
  getValueFromValueType,
  Input,
  JiraLogoIcon,
  PenIcon,
  PopoverButton,
  PopoverContainer,
  Select,
  Size,
  T1Icon,
  Typography,
  TypographyNew,
  TypographyVariants,
} from '@shared';

import { useJiraIntegrationsCurrent } from '../../lib';
import { JiraIntegrationsFieldsIntegrations } from '../JiraIntegrationsFieldsIntegrations';
import { JiraIntegrationsPriorities } from '../JiraIntegrationsPriorities';
import { JiraIntegrationsStatuses } from '../JiraIntegrationsStatuses';
import { JiraIntegrationsTicketTypes } from '../JiraIntegrationsTicketTypes';

import styles from './JiraIntegrationsCurrent.module.scss';

export const JiraIntegrationsCurrent = () => {
  const { state, methods } = useJiraIntegrationsCurrent();

  const {
    jiraIntegrationId,
    isTableOpen,
    isAddDisabled,
    systemId,
    isEditMode,
    loadingInfo,
    alertIsOpen,
    control,
    ticketTypeId,
    jiraPrioritiesOptions,
    priorities,
    jiraTicketTypesForCurrentOptions,
    jiraStatusesBySystemIdOptions,
    statusMapOptions,
    statuses,
    disabledSubmit,
    fieldsIntegrations,
    ticketTypesOptions,
  } = state;

  const {
    setIsAddDisabled,
    toggleIsTableOpen,
    onCloseAlert,
    toggleIsEditMode,
    setTypeId,
    editJiraIntegrationHandler,
    getIsPriorityAvailable,
    onChangePriorities,
    onChangeStatuses,
    resetState,
  } = methods;

  const warningAlert = alertIsOpen && (
    <AlertItem
      alert={{
        id: nanoid(),
        type: AlertTypes.WARNING,
        message: (
          <TypographyNew variant="h6">
            Проверьте совпадение статусной модели с Jira
          </TypographyNew>
        ),
      }}
      className={styles.jiraIntegrationsCurrent__alert}
      onClose={onCloseAlert}
    />
  );

  const dropContent = (
    <PopoverContainer>
      <PopoverButton
        onClick={toggleIsEditMode}
        icon={<PenIcon className={styles.jiraIntegrationsCurrent__editIcon} />}
        disabled={!jiraIntegrationId}
      >
        Редактировать
      </PopoverButton>
    </PopoverContainer>
  );

  const content = (
    <div className={styles.jiraIntegrationsCurrent__content}>
      {!systemId && !loadingInfo && (
        <DataEmpty className={styles.jiraIntegrationsCurrent__emptyData} />
      )}
      {systemId && (
        <div className={styles.jiraIntegrationsCurrent__wrapper}>
          {warningAlert}
          <div className={styles.jiraIntegrationsCurrent__inputs}>
            <div className={styles.jiraIntegrationsCurrent__header}>
              <div className={styles.jiraIntegrationsCurrent__headerElement}>
                <T1Icon
                  className={styles.jiraIntegrationsCurrent__headerIcon}
                />
                <Typography
                  variant={TypographyVariants.h4}
                  className={styles.jiraIntegrationsCurrent__headerTitle}
                >
                  SupPortal
                </Typography>
              </div>
              <div className={styles.jiraIntegrationsCurrent__headerElement}>
                <JiraLogoIcon
                  className={styles.jiraIntegrationsCurrent__headerIcon}
                />
                <Typography
                  variant={TypographyVariants.h4}
                  className={styles.jiraIntegrationsCurrent__headerTitle}
                >
                  Jira
                </Typography>
              </div>
            </div>
            <div className={styles.jiraIntegrationsCurrent__infoLineWrapper}>
              <Input
                size={Size.s}
                value="Исполнитель"
                className={styles.jiraIntegrationsCurrent__input}
                disabled
              />
              <ArrowHorizontalIcon
                className={styles.jiraIntegrationsCurrent__arrowIcon}
              />
              <Input
                size={Size.s}
                value="Исполнитель"
                className={styles.jiraIntegrationsCurrent__input}
                disabled
              />
            </div>

            <Controller
              control={control}
              rules={{ required: true }}
              name="ticketTypeId"
              render={({ field }) => (
                <Select<string>
                  label="Тип тикета"
                  mobileModalTitle="тип тикета"
                  options={ticketTypesOptions}
                  value={field.value}
                  onChange={(value) => {
                    setTypeId(getValueFromValueType(value));
                    resetState();
                    field.onChange(value);
                  }}
                />
              )}
            />

            {ticketTypeId && (
              <>
                <JiraIntegrationsTicketTypes
                  ticketTypeId={ticketTypeId}
                  jiraTicketTypesOptions={jiraTicketTypesForCurrentOptions}
                  control={control}
                  isEditMode={isEditMode}
                  resetState={resetState}
                />
                <JiraIntegrationsStatuses
                  jiraStatusesOptions={jiraStatusesBySystemIdOptions}
                  isEditMode={isEditMode}
                  onChangeStatuses={onChangeStatuses}
                  statusMapOptions={statusMapOptions}
                  statuses={statuses}
                />
                <JiraIntegrationsPriorities
                  isEditMode={isEditMode}
                  getIsPriorityAvailable={getIsPriorityAvailable}
                  jiraPrioritiesOptions={jiraPrioritiesOptions}
                  priorities={priorities}
                  onChangePriorities={onChangePriorities}
                />
                <JiraIntegrationsFieldsIntegrations
                  isAddDisabled={isAddDisabled}
                  closeEditHandler={() => setIsAddDisabled(false)}
                  fieldsIntegrations={fieldsIntegrations}
                  className={styles.jiraIntegrationsCurrent__fieldsListWrapper}
                />
              </>
            )}
          </div>
        </div>
      )}
    </div>
  );

  const editComponent = (
    <div className={styles.jiraIntegrationsCurrent__editBlock}>
      <div className={styles.jiraIntegrationsCurrent__editBlockHeader}>
        <Typography variant={TypographyVariants.h3}>
          Редактировать интеграцию
        </Typography>
      </div>
      <div className={styles.jiraIntegrationsCurrent__editBlockContainer}>
        {content}
      </div>
      <div className={styles.jiraIntegrationsCurrent__editBlockFooter}>
        <div className={cn(styles.jiraIntegrationsCurrent__editBlockButtons)}>
          <Button onClick={toggleIsEditMode} appearance="flat">
            Отмена
          </Button>
          <Button
            onClick={editJiraIntegrationHandler}
            disabled={disabledSubmit}
          >
            Сохранить
          </Button>
        </div>
      </div>
    </div>
  );

  const previewComponent = (
    <div
      className={cn(styles.jiraIntegrationsCurrent, {
        [styles.jiraIntegrationsCurrent_close]: !isTableOpen,
      })}
    >
      <CardTableHeader
        title="Маппинг полей"
        handleTableOpen={toggleIsTableOpen}
        tableOpened={isTableOpen}
        createTitle="Добавить"
        createDisabled={isAddDisabled || !systemId || !ticketTypeId}
        createHandler={() => setIsAddDisabled(true)}
        dropContent={dropContent}
      />
      {content}
    </div>
  );

  return isEditMode ? editComponent : previewComponent;
};
