import { Priority } from '@entities/ticket/model/types';
import {
  convertFromDateFormat,
  convertToDateFormat,
  getValueFromValueType,
  SelectOption,
  TicketsTabType,
} from '@shared';

import {
  TicketsFilterForRender,
  TicketsFilterForRequest,
} from '../../model/types';

const getValue = (values?: (string | SelectOption)[]) =>
  (values || []).map((item) =>
    typeof item === 'string' ? item : getValueFromValueType(item)
  );

interface Args {
  filter: TicketsFilterForRender;
  isAdmin: boolean;
  ticketTab?: TicketsTabType;
  userId?: string;
}

export const getPrepareFilterTicketsToRequest = ({
  filter,
  isAdmin,
  ticketTab,
  userId,
}: Args): TicketsFilterForRequest => {
  const {
    number,
    theme,
    priorities,
    statuses,
    systemIds,
    specialistIds,
    clientIds,
    memberIds,
    dateSolve,
    workGroupIds,
    tagName,
    onlyActive,
    contractIds,
    typeIds,
    dateCreate,
    customFields,
    sources,
  } = filter;

  const isMyTicketsPage = ticketTab === TicketsTabType.MY_TICKETS;
  const currentUserIdArray = userId ? [userId] : [];

  return {
    dateSolveFrom: convertFromDateFormat(dateSolve),
    dateSolveTo: convertToDateFormat(dateSolve),
    number,
    theme,
    priorities: (priorities || []).map(
      (item) => getValueFromValueType(item) as Priority
    ),
    statuses: getValue(statuses),
    systemIds: getValue(systemIds),
    clientIds: getValue(clientIds),
    specialistIds: getValue(specialistIds),
    ...(isMyTicketsPage && {
      memberIds: memberIds || currentUserIdArray,
    }),
    workGroupIds: isAdmin ? [] : workGroupIds,
    tagName,
    onlyActive,
    contractIds,
    dateCreateFrom: convertFromDateFormat(dateCreate),
    dateCreateTo: convertToDateFormat(dateCreate),
    typeIds: getValue(typeIds),
    customFields,
    sources: getValue(sources),
  } as TicketsFilterForRequest;
};
