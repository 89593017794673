import { KBLayout } from '@features/knowledge-base';
import { KBMain } from '@widgets/knowledge-base';

const KBMainPage = () => (
  <KBLayout>
    <KBMain />
  </KBLayout>
);

export default KBMainPage;
