import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';

import { Storage } from '@entities/storage';
import {
  CreateStorageForm,
  createStoragesRequest,
  editStoragesRequest,
  endpoints,
  fetchAdminIdOrganizationRequest,
  getAdminOrganizationId,
  getOrganizationIdsEditors,
  getOrganizationIdsViewers,
  getOrganizationsForCreateOptions,
  getPropsEditorsGroups,
  getPropsViewersGroups,
  getWorkGroupsEditorsOptions,
  getWorkGroupsViewersOptions,
  PanelType,
  setOrganizationIdsEditors,
  setOrganizationIdsViewers,
} from '@entities/storages';
import {
  ApiClient,
  convertKeyValueOptionToArraySelectOption,
  getEnv,
  getMultiOptionValues,
  SelectOption,
} from '@shared';

import {
  DEFAULT_ATTRIBUTES,
  DEFAULT_CREATE_STORAGE_FORM_VALUES,
} from '../../config';
import { setStorageRequest } from '../utils';

interface UseStorageCreateProps {
  toggleCreatePanel: () => void;
  handleChangeHeaderOrganization: (organizations: SelectOption[]) => void;
  selectedStorage?: Storage;
  panelType: PanelType;
}

export const useStorageCreate = ({
  toggleCreatePanel,
  handleChangeHeaderOrganization,
  selectedStorage,
  panelType,
}: UseStorageCreateProps) => {
  const { create, edit } = panelType;

  const dispatch = useDispatch();

  const adminOrganizationId = useSelector(getAdminOrganizationId);
  const organizationsOptions = useSelector(getOrganizationsForCreateOptions);
  const workGroupsViewersOptions = useSelector(getWorkGroupsViewersOptions);
  const workGroupsEditorsOptions = useSelector(getWorkGroupsEditorsOptions);
  const propsEditorsGroups = useSelector(getPropsEditorsGroups);
  const propsViewersGroups = useSelector(getPropsViewersGroups);
  const organizationIdsEditors = useSelector(getOrganizationIdsEditors);
  const organizationIdsViewers = useSelector(getOrganizationIdsViewers);

  const [isCancelModal, setIsCancelModal] = useState<boolean>(false);
  const [addAttributeIsOpen, setAddAttributeIsOpen] = useState(false);

  const [attributes, setAttributes] = useState(DEFAULT_ATTRIBUTES);

  const {
    control,
    watch,
    setValue,
    reset,
    resetField,
    handleSubmit,
    register,
    getValues,
    formState: { errors, isValid },
  } = useForm<CreateStorageForm>({
    mode: 'onChange',
    defaultValues: DEFAULT_CREATE_STORAGE_FORM_VALUES,
  });

  const { size, organizations, customField } = watch();

  const accessDisabled = !organizations?.length;
  const addAttributeDisabled =
    !customField?.title ||
    !customField?.description ||
    !!errors.customField?.title;

  const titleInput = register('title', {
    required: true,
    maxLength: {
      value: 150,
      message: 'Название не может быть длиннее 150 символов',
    },
    validate: async (value) => {
      if (value) {
        const isExistTitle: boolean = await ApiClient.get<boolean>({
          baseURL: getEnv('REACT_APP_ATTACHMENT_URL'),
          url: endpoints.checkExistStorageTitle(value),
        });

        if (!isExistTitle && selectedStorage?.title !== value) {
          return 'Название уже используется';
        }
      }
      return true;
    },
  });

  const descriptionInput = register('description', {
    required: true,
    maxLength: {
      value: 500,
      message: 'Описание не может быть длиннее 500 символов',
    },
  });

  const attributeTitle = register('customField.title', {
    required: addAttributeIsOpen,
    maxLength: {
      value: 150,
      message: 'Название не может быть длиннее 150 символов',
    },
    validate: (value) => {
      const isAttributeTitleTaken = attributes.find(
        ({ title }) => title === value
      );
      return !isAttributeTitleTaken || 'Название атрибута занято';
    },
  });

  const attributeDescription = register('customField.description', {
    required: addAttributeIsOpen,
    maxLength: {
      value: 500,
      message: 'Описание не может быть длиннее 500 символов',
    },
  });

  const organizationsSelect = register('organizations', {
    required: true,
  });

  const resetAttributes = () => {
    setAttributes(DEFAULT_ATTRIBUTES);
  };

  const toggleCancelModal = () => {
    setIsCancelModal(!isCancelModal);
  };

  const toggleAddAttribute = () => {
    setAddAttributeIsOpen((prev) => !prev);
  };

  const handleCloseAddAttribute = () => {
    setAddAttributeIsOpen(false);
    resetField('customField');
  };

  const onApproveCancelModal = () => {
    toggleCreatePanel();
    toggleCancelModal();
    setAddAttributeIsOpen(false);
    resetAttributes();
  };

  const handlePlusIcon = () => {
    if (size && size < 50) {
      setValue('size', Number(size) + 1);
    }
  };

  const handleMinusIcon = () => {
    if (size && size > 1) {
      setValue('size', Number(size) - 1);
    }
  };

  const handleAddAttribute = () => {
    const value = getValues('customField');

    if (value) {
      setAttributes((prev) => [...prev, { ...value, required: false }]);
    }
    handleCloseAddAttribute();
  };

  const handleDeleteAttribute = (title: string) => () => {
    setAttributes((prev) => prev.filter((item) => item.title !== title));

    if (edit) {
      resetField('customField');
    }
  };

  const onSubmit = handleSubmit((data) => {
    const preparedData = setStorageRequest({ data, customFields: attributes });

    if (create) {
      dispatch(createStoragesRequest(preparedData));
    }

    if (edit) {
      dispatch(editStoragesRequest(preparedData));
    }

    toggleCreatePanel();
    handleChangeHeaderOrganization([]);
    resetAttributes();
  });

  useEffect(() => {
    if (create) {
      reset();
    }
    if (edit) {
      setValue('id', selectedStorage?.id);
      setValue('title', selectedStorage?.title);
      setValue('description', selectedStorage?.description);
      setValue(
        'organizations',
        convertKeyValueOptionToArraySelectOption(selectedStorage?.organizations)
      );
      setValue(
        'viewersGroups',
        convertKeyValueOptionToArraySelectOption(selectedStorage?.viewersGroups)
      );
      setValue(
        'editorsGroups',
        convertKeyValueOptionToArraySelectOption(selectedStorage?.editorsGroups)
      );
      setValue('size', selectedStorage?.size);

      if (selectedStorage?.customFields) {
        const { customFields } = selectedStorage;
        setAttributes([...DEFAULT_ATTRIBUTES, ...customFields]);
      }
    }
  }, [panelType, selectedStorage]);

  useEffect(() => {
    dispatch(fetchAdminIdOrganizationRequest());
  }, []);

  useEffect(() => {
    if (organizations?.length) {
      dispatch(
        setOrganizationIdsViewers(getMultiOptionValues(organizations) || [])
      );
    }
  }, [organizations]);

  useEffect(() => {
    if (organizations?.length && adminOrganizationId) {
      dispatch(setOrganizationIdsEditors([adminOrganizationId]));
    }
  }, [organizations, adminOrganizationId]);

  return {
    state: {
      organizationsOptions,
      workGroupsViewersOptions,
      workGroupsEditorsOptions,
      isCancelModal,
      addAttributeIsOpen,
      titleInput,
      descriptionInput,
      attributeTitle,
      attributeDescription,
      organizationsSelect,
      isValid,
      errors,
      size,
      accessDisabled,
      organizationIds: organizations,
      propsEditorsGroups,
      propsViewersGroups,
      organizationIdsEditors,
      organizationIdsViewers,
      addAttributeDisabled,
      attributes,
    },
    methods: {
      onSubmit,
      toggleCancelModal,
      toggleAddAttribute,
      handleCloseAddAttribute,
      onApproveCancelModal,
      control,
      resetField,
      handlePlusIcon,
      handleMinusIcon,
      handleAddAttribute,
      handleDeleteAttribute,
    },
  };
};
