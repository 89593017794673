import { Edge, MarkerType } from 'reactflow';

import { Theme } from '@shared';

import {
  MARKER_COLOR_MAP,
  SELECTED_MARKER_COLOR_MAP,
} from '../../config/constants';

interface CustomEdgesArgs {
  edges: Edge[];
  theme?: Theme;
}

export const getCustomEdges = ({ edges, theme }: CustomEdgesArgs) =>
  edges.map((edge) => ({
    ...edge,
    markerEnd: {
      type: MarkerType.ArrowClosed,
      color: !edge.selected
        ? MARKER_COLOR_MAP[theme || Theme.LIGHT]
        : SELECTED_MARKER_COLOR_MAP[theme || Theme.LIGHT],
      strokeWidth: 2,
    },
    type: 'smoothstep',
    deletable: true,
  }));
