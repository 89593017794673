import cn from 'clsx';
import { FC, MouseEvent, SVGProps, useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { getIsMobile, getIsMobileSmall } from '@shared/model';

import { ArrowDownIcon, BackArrowIcon } from '../../assets';
import { BreadCrumb, BreadCrumbs } from '../BreadCrumbs';
import { Card } from '../Card';
import { InfoBlock, InfoBlockProps } from '../InfoBlock';
import { Loader } from '../Loader';
import { Popover } from '../Popover';
import { Typography, TypographyVariants } from '../Typography';

import styles from './InfoHeader.module.scss';
import { LoaderProps } from './types';

export interface InfoBlockConfig extends InfoBlockProps {
  popoverContent?: JSX.Element;
  isPopoverOpen?: boolean;
  toggleIsPopoverOpen?(): void;
}

export interface InfoHeaderProps {
  title?: string | JSX.Element;
  subTitle?: string;
  className?: string;
  classNameTitleBlock?: string;
  classNameTitleInfoBlock?: string;
  titleIcon?: SVGProps<SVGElement>;
  infoBlocksConfig?: InfoBlockProps[];
  breadCrumbsConfig?: BreadCrumb[];
  onClickSubTitle?(): void;
  dropContent?: JSX.Element;
  dropDisabled?: boolean;
  customComponent?: JSX.Element;
  loaderProps?: LoaderProps;
  labelComponent?: JSX.Element;
}

export const InfoHeader: FC<InfoHeaderProps> = ({
  title,
  subTitle,
  className,
  classNameTitleBlock,
  classNameTitleInfoBlock,
  titleIcon,
  infoBlocksConfig,
  breadCrumbsConfig,
  onClickSubTitle,
  dropContent,
  dropDisabled,
  customComponent,
  loaderProps,
  labelComponent,
}) => {
  const { goBack } = useHistory();

  const isMobile = useSelector(getIsMobile);
  const isMobileSmall = useSelector(getIsMobileSmall);

  const isMobileAll = isMobile || isMobileSmall;

  const [isPopoverOpen, setIsPopoverOpen] = useState<boolean>(false);

  const { withLoader, isLoading } = loaderProps || {};

  const togglePopover = () => {
    setIsPopoverOpen((prevState) => !prevState);
  };

  const handleClick = (event: MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
  };

  const getInfoBlocks = (config: InfoBlockConfig[]) =>
    config?.map(
      ({
        popoverContent,
        toggleIsPopoverOpen,
        title: infoTitle,
        isPopoverOpen: isStatusPopoverOpen,
        info,
        icon,
        classNameIconWrapper,
        backgroundColor,
        customButton,
      }) => {
        if (popoverContent && toggleIsPopoverOpen) {
          return (
            <Popover
              key={infoTitle?.toString()}
              content={popoverContent}
              togglePopover={toggleIsPopoverOpen}
              isOpen={isStatusPopoverOpen}
              positions={['bottom', 'right', 'left', 'top']}
              popoverClassName={cn(
                styles.infoHeader__infoItem,
                styles.infoHeader__statusPopover
              )}
              align="start"
              padding={12}
            >
              <div
                onClick={toggleIsPopoverOpen}
                onKeyDown={toggleIsPopoverOpen}
                tabIndex={-1}
                role="button"
                className={cn(styles.infoHeader__infoItemWrapper, {
                  [styles.infoHeader__infoItem]: isMobileAll,
                })}
              >
                <InfoBlock
                  mainTitle={infoTitle}
                  info={info}
                  icon={icon}
                  backgroundColor={backgroundColor}
                  classNameIconWrapper={classNameIconWrapper}
                  classNameInfo={styles.infoHeader__infoItemText}
                />
                <ArrowDownIcon
                  className={cn(styles.infoHeader__infoItemArrow, {
                    [styles.infoHeader__infoItemArrow_open]:
                      isStatusPopoverOpen,
                  })}
                />
              </div>
            </Popover>
          );
        }

        return (
          <InfoBlock
            key={infoTitle?.toString()}
            mainTitle={infoTitle}
            info={info}
            icon={icon}
            backgroundColor={backgroundColor}
            className={styles.infoHeader__infoItem}
            classNameIconWrapper={classNameIconWrapper}
            classNameInfo={styles.infoHeader__infoItemText}
            customButton={customButton}
          />
        );
      }
    );

  const infoBlock = titleIcon && (
    <InfoBlock
      icon={titleIcon}
      className={styles.infoHeader__titleInfoBlockWrapper}
      classNameIconWrapper={cn(
        styles.infoHeader__titleInfoBlock,
        classNameTitleInfoBlock
      )}
    />
  );

  const subTitleButton = subTitle && (
    <button
      onClick={onClickSubTitle}
      onMouseDown={handleClick}
      className={cn(styles.infoHeader__subTitle, {
        [styles.infoHeader__subTitle_button]: onClickSubTitle,
        [styles.infoHeader__subTitleActive]: isMobileAll,
      })}
    >
      {subTitle}
    </button>
  );

  const subTitleText = subTitle && (
    <Typography
      variant={TypographyVariants.o}
      className={cn(styles.infoHeader__subTitle, {
        [styles.infoHeader__subTitleActive]: isMobileAll,
      })}
    >
      {subTitle}
    </Typography>
  );

  const subTitleBlock = onClickSubTitle ? subTitleButton : subTitleText;

  const breadCrumbs = breadCrumbsConfig && (
    <BreadCrumbs
      config={breadCrumbsConfig}
      className={styles.infoHeader__breadCrumbs}
    />
  );

  const infoBlocks = infoBlocksConfig && getInfoBlocks(infoBlocksConfig);

  const dropComponent = dropContent && (
    <Popover
      content={dropContent}
      togglePopover={togglePopover}
      isOpen={isPopoverOpen}
      disabled={dropDisabled}
      positions={['left', 'bottom']}
      align="start"
      className={cn({ [styles.infoHeader__popover]: !isMobileAll })}
    />
  );

  const loader = withLoader && isLoading && (
    <Card className={styles.infoHeader__loaderWrapper}>
      <div className={styles.infoHeader__loader}>
        <Loader />
      </div>
    </Card>
  );

  const infoHeader = !isLoading && (
    <Card className={cn(styles.infoHeader, className)}>
      <div
        className={cn({
          [styles.infoHeader__titleBlockWrapper]: !isMobileAll,
          [styles.infoHeader__titleBlockWrapperMobile]: isMobileAll,
        })}
      >
        <div
          className={cn(classNameTitleBlock, {
            [styles.infoHeader__titleBlock]: !isMobileAll,
            [styles.infoHeader__titleBlock_center]:
              !subTitle && !breadCrumbsConfig,
            [styles.infoHeader__titleBlockMobile]: isMobileAll,
          })}
        >
          <button
            onClick={goBack}
            onMouseDown={handleClick}
            className={styles.infoHeader__arrow}
          >
            <BackArrowIcon />
          </button>
          <div className={styles.infoHeader__titleBlockSubWrapper}>
            <div className={styles.infoHeader__titleWrapper}>
              {infoBlock}
              <Typography
                variant={TypographyVariants.h2}
                className={cn(styles.infoHeader__title, {
                  [styles.infoHeader__title_noMb]: !subTitle,
                  [styles.infoHeader__titleMobile]: isMobileAll,
                })}
              >
                {title}
              </Typography>
              {labelComponent}
            </div>
            {subTitleBlock}
            {breadCrumbs}
          </div>
          {isMobileAll && dropComponent}
        </div>
      </div>
      <div className={styles.infoHeader__infoBlockWrapper}>
        <div className={styles.infoHeader__infoBlock}>{infoBlocks}</div>
        {customComponent}
      </div>
      {!isMobileAll && dropComponent}
    </Card>
  );

  return (
    <>
      {loader}
      {infoHeader}
    </>
  );
};
