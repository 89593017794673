import { useEffect } from 'react';
import { useDispatch } from 'react-redux';

import {
  fetchCustomFieldsRequest,
  resetCustomFieldState,
  setCurrentCustomFieldId,
} from '@entities/custom-fields';
import { TablesLayout } from '@shared';

import { CustomFieldsTableContainer } from '../CustomFieldsTableContainer';

import styles from './CustomFields.module.scss';

const MainTable = () => <CustomFieldsTableContainer />;

export const CustomFields = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchCustomFieldsRequest());
    return () => {
      dispatch(setCurrentCustomFieldId(''));
      dispatch(resetCustomFieldState());
    };
  }, []);

  return (
    <TablesLayout
      MainTable={[MainTable]}
      classNameRoot={styles.customField}
      classNameSubTables={styles.customField__subTable}
      classNameMainTables={styles.customField__mainTable}
      SubTable={[]}
    />
  );
};
