import { KeyValueOption, SelectOption, ValueType } from '@shared';

import { RulesReducerState } from './slice';

export type RulesState = { rules: RulesReducerState };

export enum RuleFieldsType {
  organizations = 'organizations',
  systems = 'systems',
  ticketTypes = 'ticketTypes',
  ticketPriorities = 'ticketPriorities',
  environments = 'environments',
  clients = 'clients',
  status = 'status',
}

export enum DestinationType {
  SPECIALIST = 'SPECIALIST',
  WORK_GROUP = 'WORK_GROUP',
}

export enum ConditionType {
  REACTION_EXPIRE_BEFORE = 'REACTION_EXPIRE_BEFORE',
  REACTION_EXPIRE_EQUAL = 'REACTION_EXPIRE_EQUAL',
}

export type Attribute = {
  id: string;
  isActive: boolean;
  attribute: ValueType<string>;
  value: ValueType<string>;
};

export type Attributes = {
  organizations: KeyValueOption[];
  systems: KeyValueOption[];
  environments: KeyValueOption[];
  clients: KeyValueOption[];
  ticketTypes: KeyValueOption[];
  ticketPriorities: KeyValueOption[];
};

export type FieldsRulesType = Exclude<RuleFieldsType, RuleFieldsType.status>;

export type Rule = {
  id: string;
  title: string;
  conditionType: ConditionType;
  targetSpecialist?: KeyValueOption;
  targetWorkgroup?: KeyValueOption;
  minutes: number;
  isEnable: boolean;
} & Attributes;

export type CreateRule = {
  conditionType?: ConditionType;
  targetSpecialist?: string;
  targetWorkgroup?: string;
  organizations?: string[];
  systems?: string[];
  environments?: string[];
  clients?: string[];
  ticketTypes?: string[];
  ticketPriorities?: string[];
} & Pick<Rule, 'title' | 'minutes' | 'isEnable'>;

export type FiltersFields = {
  conditionType?: KeyValueOption[];
} & Attributes;

export type RulesAttributeToRequest = Partial<
  Pick<
    CreateRule,
    | 'organizations'
    | 'systems'
    | 'environments'
    | 'clients'
    | 'ticketTypes'
    | 'ticketPriorities'
  >
>;

export type RulesFilter = {
  isActive?: boolean | null;
} & Partial<Pick<Rule, 'title'>>;

export interface RulesFilterData extends Partial<Pick<Rule, 'title'>> {
  isActive: ValueType<boolean>;
  organizations: SelectOption[];
  systems: SelectOption[];
  ticketTypes: SelectOption[];
  ticketPriorities: SelectOption[];
  environments: SelectOption[];
  clients: SelectOption[];
}

export type RulesFilterToRequest = RulesAttributeToRequest &
  Pick<RulesFilter, 'title' | 'isActive'>;

export type CreateRuleData = {
  executorType: DestinationType;
  executor: ValueType<string>;
  conditionType: ValueType<ConditionType>;
  minutes: number | string;
} & Pick<Rule, 'isEnable' | 'title'>;

export interface EditRuleData extends Pick<CreateRuleData, 'executorType'> {
  executor: SelectOption[];
}

export type InitialRulesFields = Partial<Pick<Rule, 'title'>> &
  RulesAttributeToRequest;

export type AttributesForFilter = Omit<RulesFilterData, 'title' | 'isActive'>;
