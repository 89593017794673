import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { ActionList, getIsAccessedAction } from '@entities/auth';
import { MainLayout, RouterHref } from '@shared';

const Companies = () => {
  const { push } = useHistory();

  const isAccessToCreateTicket = useSelector(
    getIsAccessedAction(ActionList.CreateTicket)
  );

  useEffect(() => {
    push(RouterHref.Tickets);
  }, []);

  return (
    <MainLayout
      headerType="default"
      title="Организации"
      accessToCreateTicket={isAccessToCreateTicket}
    />
  );
};

export default Companies;
