import cn from 'clsx';
import DOMPurify from 'dompurify';
import { FC, MouseEvent, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { AttachmentDeleteType } from '@entities/attachment/model/types';
import {
  getIsClient,
  getIsOrgTypePartner,
} from '@entities/auth/model/selectors';
import { ActionList } from '@entities/auth/model/types';
import { SimilarSolutions } from '@entities/similar-solutions';
import { SLA } from '@entities/sla/ui/SLA';
import { PRIORITY_TITLE_MAP } from '@entities/tickets/config/constants';
import { fetchTicketRequest } from '@entities/tickets/model/actions';
import {
  getClientInfoOrgTypeFromTickets,
  getCurrentTicket,
  getIsHideSLA,
} from '@entities/tickets/model/selectors';
import {
  ApproveOrCancel,
  Button,
  Card,
  ConditionBlock,
  InfoBlock,
  JiraLogoIcon,
  PlusIcon,
  SearchIcon,
  Size,
  Solution,
  System,
  Tooltip,
  Typography,
  TypographyVariants,
  UsersGroupIcon,
} from '@shared';

import { SYSTEM_TICKET_INFO } from '../../config/constants';
import {
  useTicket,
  useTicketAttachments,
  useTicketInfoTabs,
} from '../../lib/hooks';
import { fetchTicketRequest as fetchDetailTicketRequest } from '../../model/ticket/actions';
import {
  getIsAccessedTicketAction,
  getJiraUrl,
  getTicket,
  getTicketSystem,
} from '../../model/ticket/selectors';
import { Priority } from '../../model/types';
import { PreviewImageModal } from '../PreviewImageModal';
import { TicketAttachments } from '../TicketAttachments';
import { TicketCustomFieldsInfo } from '../TicketCustomFieldsInfo';
import { TicketInfoDropContent } from '../TicketInfoDropContent';
import { TicketPriorityEdit } from '../TicketPriorityEdit';
import { TicketTabsBlock } from '../TicketTabsBlock';
import { TicketTags } from '../TicketTags';

import styles from './CurrentTicketAdaptive.module.scss';

const priorityStylesMap = {
  [Priority.NONE]: styles.currentTicketAdaptive_none,
  [Priority.LOW]: styles.currentTicketAdaptive_low,
  [Priority.MIDDLE]: styles.currentTicketAdaptive_middle,
  [Priority.HIGH]: styles.currentTicketAdaptive_high,
  [Priority.VERY_HIGH]: styles.currentTicketAdaptive_veryHigh,
};

interface CurrentTicketAdaptiveProps {
  className?: string;
  withPriority?: boolean;
}

export const CurrentTicketAdaptive: FC<CurrentTicketAdaptiveProps> = ({
  className,
  withPriority,
  // eslint-disable-next-line sonarjs/cognitive-complexity
}) => {
  const { mode, ticketInfoTabs } = useTicketInfoTabs();

  const ticketCurrent = useSelector(getCurrentTicket);
  const ticketDetail = useSelector(getTicket);
  const isClient = useSelector(getIsClient);
  const isPartner = useSelector(getIsOrgTypePartner);
  const clientInfo = useSelector(getClientInfoOrgTypeFromTickets);
  const isHideSLA = useSelector(
    getIsHideSLA(clientInfo, ticketCurrent || ticketDetail)
  );

  const isAccessToTakeTicketToWork = useSelector(
    getIsAccessedTicketAction(ActionList.TakeTicketToWork)
  );
  const isAccessToEditPriority = useSelector(
    getIsAccessedTicketAction(ActionList.EditTicketPriority)
  );
  const ticketSystem = useSelector(getTicketSystem);
  const jiraUrl = useSelector(getJiraUrl);

  const dispatch = useDispatch();

  const [isSimilarSolutionsModal, setIsSimilarSolutionsModal] =
    useState<boolean>(false);

  const ticket = withPriority ? ticketDetail : ticketCurrent;
  const ticketId = ticket?.id;
  const isTicketDeleted = ticket?.isDeleted;
  const isShowSLA = isPartner ? !isHideSLA : !isClient;

  const {
    isTakeToWorkDisabled,
    isJiraButtonDisabled,
    setTicketToWorkHandler,
    setTicketToWorkAndCreateTaskInJiraHandler,
    restoreTicketHandler,
    toggleRestoreModal,
    isSeeTags,
    isEditTags,
    isRestoreModal,
    isAccessToRestore,
  } = useTicket();

  const [isModal, setIsModal] = useState<boolean>(false);
  const {
    isImageModal,
    initialImageIndex,
    attachment,
    imageFiles,
    documentFiles,
    toggleIsImageModal,
    handleInitialImageIndex,
  } = useTicketAttachments();

  const toggleIsSimilarSolutionsModal = () => {
    setIsSimilarSolutionsModal((prevState) => !prevState);
  };

  const toggleModal = () => {
    setIsModal(!isModal);
  };

  const handleClick = (event: MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
  };

  const updateTicket = () => {
    if (ticketId) {
      dispatch(
        withPriority
          ? fetchDetailTicketRequest({ ticketId })
          : fetchTicketRequest(ticketId)
      );
    }
  };

  const onApproveRestoreModal = () => {
    restoreTicketHandler?.();
  };

  const priorityTitleButton = (
    <button
      onClick={(e) => {
        e.stopPropagation();
        toggleModal();
      }}
      onMouseDown={handleClick}
      className={cn(
        styles.currentTicketAdaptive__priorityTitle,
        styles.currentTicketAdaptive__priorityTitle_button
      )}
    >
      {PRIORITY_TITLE_MAP[ticket?.priority || Priority.NONE]}
    </button>
  );

  const priorityTitle = (
    <Typography
      variant={TypographyVariants.o}
      className={styles.currentTicketAdaptive__priorityTitle}
    >
      {PRIORITY_TITLE_MAP[ticket?.priority || Priority.NONE]}
    </Typography>
  );

  const priorityTitleBlock = isAccessToEditPriority
    ? priorityTitleButton
    : priorityTitle;

  const hasAccessToSimilarSolutions =
    !isClient && isAccessToTakeTicketToWork && ticketId;

  const similarSolutions = hasAccessToSimilarSolutions && (
    <button
      onClick={toggleIsSimilarSolutionsModal}
      className={styles.currentTicketAdaptive__similarSolutions}
    >
      <SearchIcon
        className={styles.currentTicketAdaptive__similarSolutionsIcon}
      />
      <Typography
        variant={TypographyVariants.h5}
        className={styles.currentTicketAdaptive__similarSolutionsTitle}
      >
        Искать похожие решения
      </Typography>
    </button>
  );

  const getHeader = () => {
    if (withPriority) {
      return (
        <div className={styles.currentTicketAdaptive__priorityHeader}>
          {priorityTitleBlock}
          <ConditionBlock text={ticket?.ticketType?.value} />
        </div>
      );
    }
    return (
      <div className={styles.currentTicketAdaptive__header}>
        <ConditionBlock
          text={ticket?.ticketType?.value}
          className={styles.currentTicketAdaptive__headerCondition}
        />
      </div>
    );
  };

  const solution = ticket?.solution && ticket.solution?.textSolution && (
    <Solution
      text={ticket.solution.textSolution}
      className={styles.currentTicketAdaptive__solution}
    />
  );

  const sla = !ticket?.solution && ticket && isShowSLA && (
    <div className={styles.currentTicketAdaptive__sla}>
      <SLA ticket={ticket} updateTicket={updateTicket} size="small" />
    </div>
  );

  const setSpecAndGoToWork = !isClient && !isTicketDeleted && ticketId && (
    <div className={styles.currentTicketAdaptive__buttonsWrapper}>
      {isAccessToTakeTicketToWork && (
        <Button
          onClick={setTicketToWorkHandler}
          disabled={isTakeToWorkDisabled}
          icon={<PlusIcon width={20} />}
          type="button"
          className={styles.currentTicketAdaptive__takeInProcess}
          extraActionContent={
            !isJiraButtonDisabled ? (
              <TicketInfoDropContent
                onClick={setTicketToWorkAndCreateTaskInJiraHandler}
                disabled={isJiraButtonDisabled}
              />
            ) : undefined
          }
        >
          Взять в работу
        </Button>
      )}
    </div>
  );

  const restoreButton = isTicketDeleted && (
    <Button
      onClick={toggleRestoreModal}
      type="button"
      className={cn(
        styles.currentTicketAdaptive__takeInProcess,
        styles.currentTicketAdaptive__buttonsWrapper
      )}
      disabled={!isAccessToRestore}
    >
      Восстановить
    </Button>
  );

  const infoBlock = ticketId && (
    <div className={styles.currentTicketAdaptive__systemInfo}>
      <div className={styles.currentTicketAdaptive__systemInfoItem}>
        <InfoBlock
          mainTitle="Система"
          info={ticket?.systemId?.value}
          icon={<System className={styles.currentTicketAdaptive__iconInner} />}
          classNameInfo={styles.currentTicketAdaptive__blockInfo}
          dataInfoIcon={SYSTEM_TICKET_INFO}
        />
        <Tooltip
          id={SYSTEM_TICKET_INFO}
          className={styles.currentTicketAdaptive__tooltipSystem}
        >
          <Typography
            className={styles.currentTicketAdaptive__tooltipSystemVersion}
          >
            {ticketSystem?.versionTitle}
          </Typography>
          <Typography>{ticketSystem?.description}</Typography>
        </Tooltip>
      </div>
      <div className={styles.currentTicketAdaptive__systemInfoItem}>
        <InfoBlock
          mainTitle="Среда"
          info={ticket?.environmentId?.value}
          icon={
            <UsersGroupIcon
              className={styles.currentTicketAdaptive__iconInner}
            />
          }
          classNameInfo={styles.currentTicketAdaptive__blockInfo}
        />
      </div>
      {jiraUrl && (
        <div className={styles.currentTicketAdaptive__systemInfoItem}>
          <InfoBlock
            mainTitle="Jira"
            info={
              <a
                href={jiraUrl}
                target="_blank"
                className={styles.currentTicketAdaptive__jiraLink}
                rel="noreferrer"
              >
                {jiraUrl}
              </a>
            }
            icon={
              <JiraLogoIcon
                className={styles.currentTicketAdaptive__iconInner}
              />
            }
            classNameInfo={styles.currentTicketAdaptive__blockInfo}
          />
        </div>
      )}
    </div>
  );

  const ticketTagsBlock = isSeeTags && ticketId && withPriority && (
    <TicketTags canEditTags={isEditTags} />
  );

  const attachments = (
    <div className={styles.currentTicketAdaptive__ticketAttachments}>
      <TicketAttachments
        toggleIsModal={toggleIsImageModal}
        handleInitialImageIndex={handleInitialImageIndex}
        attachment={attachment}
        imageFiles={imageFiles}
        documentFiles={documentFiles}
        size={Size.s}
        attachmentDeleteType={AttachmentDeleteType.INFO}
      />
    </div>
  );

  const imageModal = isImageModal && (
    <PreviewImageModal
      toggleModal={toggleIsImageModal}
      initialImageIndex={initialImageIndex}
      imageFiles={imageFiles}
    />
  );

  const ticketMainInfo = (
    <>
      <Typography
        variant={TypographyVariants.h5}
        className={styles.currentTicketAdaptive__title}
      >
        {ticket?.theme}
      </Typography>
      {similarSolutions}
      <div
        className={cn('ck-content', styles.currentTicketAdaptive__description)}
        // eslint-disable-next-line react/no-danger
        dangerouslySetInnerHTML={{
          __html: DOMPurify.sanitize(ticket?.description || ''),
        }}
      />
      {imageModal}
      {attachments}
      {setSpecAndGoToWork}
      {restoreButton}
      {infoBlock}
      {solution}
      {ticketTagsBlock}
      {sla}
    </>
  );

  const ticketContent =
    mode === 'info' ? (
      <>
        {getHeader()}
        {ticketMainInfo}
        <TicketPriorityEdit isModal={isModal} toggleModal={toggleModal} />
      </>
    ) : (
      <TicketCustomFieldsInfo />
    );

  return (
    <Card
      className={cn(styles.currentTicketAdaptive, className, {
        [styles.currentTicketAdaptive_withPriority]: withPriority,
        [priorityStylesMap[ticket?.priority || Priority.NONE]]: withPriority,
      })}
    >
      {withPriority && <TicketTabsBlock tabs={ticketInfoTabs} />}
      {ticketContent}
      <SimilarSolutions
        isOpen={isSimilarSolutionsModal}
        toggleIsOpen={toggleIsSimilarSolutionsModal}
      />

      <ApproveOrCancel
        onApprove={onApproveRestoreModal}
        isModal={isRestoreModal}
        toggleModal={toggleRestoreModal}
        text={`Вы уверены, что хотите восстановить тикет ${ticket?.number}?`}
      />
    </Card>
  );
};
