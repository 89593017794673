import { AdminsLayout } from '@pages/AdminsLayout';
import { Users } from '@widgets/users';

const UsersPage = () => (
  <AdminsLayout>
    <Users />
  </AdminsLayout>
);

export default UsersPage;
