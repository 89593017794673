import { v4 as uuidv4 } from 'uuid';

import { DefaultConditions } from '@entities/triggers';
import { getConvertedTime } from '@shared';

import { CONDITION_MAP } from '../../config';

export const getNormalizedTriggerConditions = (
  triggerConditions: DefaultConditions[]
) =>
  triggerConditions.map((conditions) => {
    const { conditionType, minutes } = conditions || {};

    return {
      id: uuidv4(),
      isActive: true,
      condition: {
        title: (conditionType && CONDITION_MAP[conditionType]) || '',
        value: conditionType || '',
      },
      value: getConvertedTime(minutes) || '00:00',
    };
  });
