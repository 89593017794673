import { CustomFieldsState } from './types';

export const getCustomFields = (state: CustomFieldsState) =>
  state.customFields.customFields;
export const getLoading = (state: CustomFieldsState): boolean | undefined =>
  state.customFields.loading;
export const getCurrentCustomField = (state: CustomFieldsState) =>
  state?.customFields.customField;
export const getCurrentCustomFieldId = (state: CustomFieldsState) =>
  state.customFields.customFieldId;
export const getFilterValues = (state: CustomFieldsState) =>
  state.customFields.filter || {};

export const getPropsCustomFields = (state: CustomFieldsState) => ({
  pageNum: state.customFields.pagination?.pageNum,
  pageSize: state.customFields.pagination?.pageSize,
  loadingCustomFields: getLoading(state),
  sortCustomFields: state?.customFields.sort,
  totalElements: state.customFields.pagination?.totalElements,
});
