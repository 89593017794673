import { EvaluateSpecialistPayload } from '@entities/ticket/model';
import { getUrl } from '@shared';

export const assessmentInteractionEndpoints = {
  getImprovements: (rating: number) => getUrl(`v1/kpi/rating/${rating}`),
  evaluateSpecialist: ({ ticketId, rating }: EvaluateSpecialistPayload) =>
    getUrl(`v1/kpi/rating/${ticketId}/${rating}`),
  getTicketEvaluation: (ticketId: string) =>
    getUrl(`v1/kpi/ticket/rating/${ticketId}`),
};
