import { Organization } from '@entities/organizations';
import { ResponseWithMeta, SelectOption } from '@shared';

import {
  Article,
  CategoryArticle,
  CreateCategoryArticle,
  FetchArticlesParams,
  KBArticleTableFilter,
  KBArticleTableFilterToRequest,
  KBOrganizationsFilter,
} from '../types';

import {
  ArticlesTable,
  DeleteArticlesTableRequestAction,
  FetchArticlesTableCategoryRequestAction,
  FetchArticlesTableCategorySuccessAction,
  FetchArticlesTableRequestAction,
  FetchArticlesTableSuccessAction,
  HideArticlesTableLoadingAction,
  ResetArticlesTableStateAction,
  SetArticlesTableFilterAction,
  SetCurrentArticlesTablePageAction,
  SetCurrentArticleTableIdAction,
  SetSizePageAction,
  SetSortArticlesTableAction,
  ShowArticlesTableLoadingAction,
} from './actionTypes';
import { KBDataTable } from './types';

export const fetchArticlesTableRequest = (
  categoryId: string
): FetchArticlesTableRequestAction => ({
  type: ArticlesTable.FETCH_ARTICLES_REQUEST_ARTICLES_TABLE,
  payload: categoryId,
});

export const fetchArticlesTableSuccess = (
  data: ResponseWithMeta<Article[]>
): FetchArticlesTableSuccessAction => ({
  type: ArticlesTable.FETCH_ARTICLES_SUCCESS_ARTICLES_TABLE,
  payload: data,
});

export const fetchArticlesTableCategoryRequest = (
  id: string
): FetchArticlesTableCategoryRequestAction => ({
  type: ArticlesTable.FETCH_CATEGORY_REQUEST_ARTICLES_TABLE,
  payload: id,
});

export const fetchArticlesTableCategorySuccess = (
  data: CategoryArticle
): FetchArticlesTableCategorySuccessAction => ({
  type: ArticlesTable.FETCH_CATEGORY_SUCCESS_ARTICLES_TABLE,
  payload: data,
});

export const showArticlesTableLoading = (): ShowArticlesTableLoadingAction => ({
  type: ArticlesTable.ARTICLES_TABLE_LOADING_SHOW,
});

export const hideArticlesTableLoading = (): HideArticlesTableLoadingAction => ({
  type: ArticlesTable.ARTICLES_TABLE_LOADING_HIDE,
});

export const setCurrentArticlesTablePage = (
  page: number
): SetCurrentArticlesTablePageAction => ({
  type: ArticlesTable.SET_CURRENT_ARTICLES_PAGE_ARTICLES_TABLE,
  payload: page,
});

export const setSortArticlesTable = (
  sort: string
): SetSortArticlesTableAction => ({
  type: ArticlesTable.SET_SORT_ARTICLES_ARTICLES_TABLE,
  payload: sort,
});

export const setSizePage = (size: number): SetSizePageAction => ({
  type: ArticlesTable.SET_SIZE_PAGE_ARTICLES_ARTICLES_TABLE,
  payload: size,
});

export const setCurrentArticleTableId = (
  article?: KBDataTable
): SetCurrentArticleTableIdAction => ({
  type: ArticlesTable.SET_CURRENT_ARTICLE_ID_ARTICLES_TABLE,
  payload: article,
});

export const resetArticlesTableState = (): ResetArticlesTableStateAction => ({
  type: ArticlesTable.RESET_STATE_ARTICLES_TABLE,
});

export const setArticlesTableFilter = (
  data: KBArticleTableFilter
): SetArticlesTableFilterAction => ({
  type: ArticlesTable.SET_ARTICLES_FILTER_ARTICLES_TABLE,
  payload: data,
});

export const fetchOrganizationsRequestArticleTable = (
  filter: KBOrganizationsFilter
) => ({
  type: ArticlesTable.FETCH_ORGANIZATIONS_REQUEST_ARTICLES_TABLE,
  payload: filter,
});

export const fetchOrganizationsSuccessArticleTable = (
  data: Organization[]
) => ({
  type: ArticlesTable.FETCH_ORGANIZATIONS_SUCCESS_ARTICLES_TABLE,
  payload: data,
});

export const deleteArticleTableRequest = (
  id: string
): DeleteArticlesTableRequestAction => ({
  type: ArticlesTable.DELETE_ARTICLE_BY_ID_REQUEST_ARTICLES_TABLE,
  payload: id,
});

export const setArticlesFilterRightArticlesTable = (
  filter: KBArticleTableFilterToRequest
) => ({
  type: ArticlesTable.SET_FILTER_ARTICLES_RIGHT_ARTICLES_TABLE,
  payload: filter,
});

export const fetchArticlesRightArticlesTableRequest = (
  params: FetchArticlesParams
) => ({
  type: ArticlesTable.FETCH_REQUEST_ARTICLES_RIGHT_ARTICLES_TABLE,
  payload: params,
});

export const fetchArticlesSuccessRightArticlesTable = (
  articles: ResponseWithMeta<Article[]>
) => ({
  type: ArticlesTable.FETCH_SUCCESS_ARTICLES_RIGHT_ARTICLES_TABLE,
  payload: articles,
});

export const fetchArticlesJoinSuccessRightArticlesTable = (
  articles: ResponseWithMeta<Article[]>
) => ({
  type: ArticlesTable.FETCH_ARTICLES_JOIN_SUCCESS_RIGHT_ARTICLES_TABLE,
  payload: articles,
});

export const setArticlesPageRightArticlesTable = (page: number) => ({
  type: ArticlesTable.SET_ARTICLES_PAGE_RIGHT_ARTICLES_TABLE,
  payload: page,
});

export const resetArticlesStateRightArticlesTable = () => ({
  type: ArticlesTable.RESET_ARTICLES_STATE_RIGHT_ARTICLES_TABLE,
});

export const loadingShowRightArticlesTable = () => ({
  type: ArticlesTable.LOADING_SHOW_ARTICLES_RIGHT_ARTICLES_TABLE,
});

export const loadingHideRightArticlesTable = () => ({
  type: ArticlesTable.LOADING_HIDE_ARTICLES_RIGHT_ARTICLES_TABLE,
});

export const fetchOrganizationsRequestRightArticlesTable = (
  filter?: KBOrganizationsFilter
) => ({
  type: ArticlesTable.FETCH_ORGANIZATIONS_REQUEST_RIGHT_ARTICLES_TABLE,
  payload: filter,
});

export const fetchOrganizationsSuccessRightArticlesTable = (
  data: Organization[]
) => ({
  type: ArticlesTable.FETCH_ORGANIZATIONS_SUCCESS_RIGHT_ARTICLES_TABLE,
  payload: data,
});

export const organizationsLoadingShowRightArticlesTable = () => ({
  type: ArticlesTable.ORGANIZATIONS_LOADING_SHOW_RIGHT_ARTICLES_TABLE,
});

export const organizationsLoadingHideRightArticlesTable = () => ({
  type: ArticlesTable.ORGANIZATIONS_LOADING_HIDE_RIGHT_ARTICLES_TABLE,
});

export const fetchCategoriesRequestRightArticlesTable = (
  organizationIds?: string[]
) => ({
  type: ArticlesTable.FETCH_CATEGORIES_REQUEST_RIGHT_ARTICLES_TABLE,
  payload: organizationIds,
});

export const fetchCategoriesSuccessRightArticlesTable = (
  categories: CategoryArticle[]
) => ({
  type: ArticlesTable.FETCH_CATEGORIES_SUCCESS_RIGHT_ARTICLES_TABLE,
  payload: categories,
});

export const categoriesLoadingShowRightArticlesTable = () => ({
  type: ArticlesTable.CATEGORIES_LOADING_SHOW_RIGHT_ARTICLES_TABLE,
});

export const categoriesLoadingHideRightArticlesTable = () => ({
  type: ArticlesTable.CATEGORIES_LOADING_HIDE_RIGHT_ARTICLES_TABLE,
});

export const resetCategoriesStateRightArticlesTable = () => ({
  type: ArticlesTable.RESET_CATEGORIES_STATE_RIGHT_ARTICLES_TABLE,
});

export const setSelectedOrganizationRightArticlesTable = (
  option: SelectOption | null
) => ({
  type: ArticlesTable.SET_ORGANIZATION_SELECTED_RIGHT_ARTICLES_TABLE,
  payload: option,
});

export const setSearchValueRightArticlesTable = (value: string) => ({
  type: ArticlesTable.SET_SEARCH_VALUE_RIGHT_ARTICLES_TABLE,
  payload: value,
});

export const resetOnlyTableArticlesTableState = () => ({
  type: ArticlesTable.RESET_ONLY_TABLE_STATE_ARTICLES_TABLE,
});

export const createCategoryRequestRightArticlesTable = (
  data: Partial<CreateCategoryArticle>
) => ({
  type: ArticlesTable.CREATE_CATEGORY_REQUEST_RIGHT_ARTICLES_TABLE,
  payload: data,
});

export const editCategoryRequestRightArticlesTable = (
  data: CategoryArticle
) => ({
  type: ArticlesTable.EDIT_CATEGORY_REQUEST_RIGHT_ARTICLES_TABLE,
  payload: data,
});

export const deleteCategoryRequestRightArticlesTable = (id: string) => ({
  type: ArticlesTable.DELETE_CATEGORY_REQUEST_RIGHT_ARTICLES_TABLE,
  payload: id,
});

export const editArticleRequestRightArticlesTable = (
  article: Partial<Article>
) => ({
  type: ArticlesTable.EDIT_ARTICLE_REQUEST_RIGHT_ARTICLES_TABLE,
  payload: article,
});

export const deleteArticleRequestRightArticlesTable = (id: string) => ({
  type: ArticlesTable.DELETE_ARTICLE_REQUEST_RIGHT_ARTICLES_TABLE,
  payload: id,
});
