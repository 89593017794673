import { FC } from 'react';

import { ApproveOrCancel, ApproveOrCancelProps } from '../ApproveOrCancelModal';

import styles from './DeleteApproveOrCancelModal.module.scss';

interface DeleteApproveOrCancelModalProps
  extends Omit<ApproveOrCancelProps, 'text'> {
  entityTitle: string;
}

export const DeleteApproveOrCancelModal: FC<
  DeleteApproveOrCancelModalProps
> = ({
  toggleModal,
  isModal,
  entityTitle,
  onApprove,
  withHideBodyScroll = true,
}) => (
  <ApproveOrCancel
    text={`Вы уверены, что хотите удалить ${entityTitle}?`}
    toggleModal={toggleModal}
    isModal={isModal}
    onApprove={onApprove}
    approveTitle="Удалить"
    withHideBodyScroll={withHideBodyScroll}
    approveBtnProps={{
      appearance: 'outline',
      className: styles.deleteApproveOrCancelModal__iconDelete,
    }}
  />
);
