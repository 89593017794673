import { Action } from '@entities/actions/model/types';
import {
  CustomField,
  CustomFieldToRequest,
  CustomTicketFields,
} from '@entities/custom-fields/model/types';
import { System } from '@entities/system/model/types';
import { CreateTicketInJiraPayload } from '@entities/tickets/model';
import { KeyValueOption } from '@shared';

import {
  Comment,
  Status,
  Ticket,
  TicketStatusesRequest,
  TicketType,
} from '../types';

import {
  AddSpecAndSetToWorkPayload,
  CheckExistIntegration,
  CloseTicketRequest,
  EditTicketPriorityPayload,
  FetchTicketRequestPayload,
  NextStatusesRequest,
  ReopenTicket,
  TicketHistory,
  TicketUpdateType,
} from './types';

export enum TicketDetail {
  SET_TICKET_DETAIL_SUCCESS = 'TICKET/SET_TICKET_DETAIL_SUCCESS',
  SET_TICKET_SYSTEM_DETAIL_SUCCESS = 'TICKET/SET_TICKET_SYSTEM_DETAIL_SUCCESS',
  FETCH_TICKET_SYSTEM_DETAIL_REQUEST = 'TICKET/FETCH_TICKET_SYSTEM_DETAIL_REQUEST',
  FETCH_TICKET_DETAIL_REQUEST = 'TICKET/FETCH_TICKET_DETAIL_REQUEST',
  TICKET_DETAIL_LOADING_SHOW = 'TICKET/TICKET_DETAIL_LOADING_SHOW',
  TICKET_DETAIL_LOADING_HIDE = 'TICKET/TICKET_DETAIL_LOADING_HIDE',
  ADD_SPEC_AND_SET_TO_WORK_TICKET_DETAIL = 'TICKET/ADD_SPEC_AND_SET_TO_WORK_TICKET_DETAIL',
  FETCH_HISTORY_SUCCESS = 'TICKET/FETCH_HISTORY_SUCCESS',
  FETCH_HISTORY_REQUEST = 'TICKET/FETCH_HISTORY_REQUEST',
  RESET_TICKET_DETAIL_STATE = 'TICKET/RESET_TICKET_DETAIL_STATE',
  EDIT_TICKET_PRIORITY = 'TICKET/EDIT_TICKET_PRIORITY',
  SET_IS_ERROR = 'TICKET/SET_IS_ERROR',
  SET_IS_SUCCESS = 'TICKET/SET_IS_SUCCESS',
  SET_TOAST_MESSAGE = 'TICKET/SET_TOAST_MESSAGE',
  FETCH_ACTIONS_FOR_TICKET_DETAIL_REQUEST = 'TICKET/FETCH_ACTIONS_FOR_TICKET_DETAIL_REQUEST',
  FETCH_ACTIONS_FOR_TICKET_DETAIL_SUCCESS = 'TICKET/FETCH_ACTIONS_FOR_TICKET_DETAIL_SUCCESS',
  DELETE_TICKET_DETAIL_REQUEST = 'TICKET/DELETE_TICKET_DETAIL_REQUEST',
  UPDATE_DATA_TICKET_DETAIL = 'TICKET/UPDATE_DATA_TICKET_DETAIL',
  TICKET_HISTORY_LOADING_SHOW = 'TICKET/TICKET_HISTORY_LOADING_SHOW',
  TICKET_HISTORY_LOADING_HIDE = 'TICKET/TICKET_HISTORY_LOADING_HIDE',
  CREATE_TICKET_IN_JIRA = 'TICKET/CREATE_TICKET_IN_JIRA',
  FETCH_IS_SYSTEM_INTEGRATED_WITH_JIRA_REQUEST = 'TICKET/FETCH_IS_SYSTEM_INTEGRATED_WITH_JIRA_REQUEST',
  FETCH_IS_SYSTEM_INTEGRATED_WITH_JIRA_SUCCESS = 'TICKET/FETCH_IS_SYSTEM_INTEGRATED_WITH_JIRA_SUCCESS',
  FETCH_JIRA_URL_REQUEST = 'TICKET/FETCH_JIRA_URL_REQUEST',
  FETCH_JIRA_URL_SUCCESS = 'TICKET/FETCH_JIRA_URL_SUCCESS',
  FETCH_CUSTOM_FIELDS_BY_TICKET_ID_REQUEST = 'TICKET/FETCH_CUSTOM_FIELDS_BY_TICKET_ID_REQUEST',
  FETCH_CUSTOM_FIELDS_BY_TICKET_ID_SUCCESS = 'TICKET/FETCH_CUSTOM_FIELDS_BY_TICKET_ID_SUCCESS',
  UPDATE_CUSTOM_FIELDS_BY_TICKET_ID_REQUEST = 'TICKET/UPDATE_CUSTOM_FIELDS_BY_TICKET_ID_REQUEST',
  FETCH_NEXT_STATUSES_REQUEST = 'TICKET/FETCH_NEXT_STATUSES_REQUEST',
  FETCH_NEXT_STATUSES_SUCCESS = 'TICKET/FETCH_NEXT_STATUSES_SUCCESS',
  CHANGE_STATUS = 'TICKET/CHANGE_STATUS',
  CLOSE_TICKET_REQUEST = 'TICKET/CLOSE_TICKET_REQUEST',
  RESTORE_TICKET_REQUEST = 'TICKET/RESTORE_TICKET_REQUEST',
  FETCH_TICKET_TYPES_REQUEST = 'TICKET/FETCH_TICKET_TYPES_REQUEST',
  FETCH_TICKET_TYPES_SUCCESS = 'TICKET/FETCH_TICKET_TYPES_SUCCESS',
  UPDATE_TICKET_TYPES_BY_TYPE_ID_REQUEST = 'TICKET/UPDATE_TICKET_TYPES_BY_TYPE_ID_REQUEST',
  REOPEN_TICKET_REQUEST = 'TICKET/REOPEN_TICKET_REQUEST',
  FETCH_STATUSES_REQUEST = 'TICKET/FETCH_STATUSES_REQUEST',
  FETCH_STATUSES_SUCCESS = 'TICKET/FETCH_STATUSES_SUCCESS',
  FETCH_TICKET_CUSTOM_FIELDS_BY_SYSTEM_ID_REQUEST = 'TICKET/FETCH_TICKET_CUSTOM_FIELDS_BY_SYSTEM_ID_REQUEST',
  FETCH_TICKET_CUSTOM_FIELDS_BY_SYSTEM_ID_SUCCESS = 'TICKET/FETCH_TICKET_CUSTOM_FIELDS_BY_SYSTEM_ID_SUCCESS',
}

export interface ShowTicketLoadingAction {
  type: TicketDetail.TICKET_DETAIL_LOADING_SHOW;
}

export interface HideTicketLoadingAction {
  type: TicketDetail.TICKET_DETAIL_LOADING_HIDE;
}

export interface FetchTicketRequestAction {
  type: TicketDetail.FETCH_TICKET_DETAIL_REQUEST;
  payload: FetchTicketRequestPayload;
}

export interface FetchCurrentTicketSystemRequestAction {
  type: TicketDetail.FETCH_TICKET_SYSTEM_DETAIL_REQUEST;
  payload: string;
}

export interface FetchTicketCustomFieldsRequestAction {
  type: TicketDetail.FETCH_TICKET_CUSTOM_FIELDS_BY_SYSTEM_ID_REQUEST;
  payload: string;
}

export interface FetchTicketCustomFieldsSuccessAction {
  type: TicketDetail.FETCH_TICKET_CUSTOM_FIELDS_BY_SYSTEM_ID_SUCCESS;
  payload: CustomField[];
}

export interface SetTicketDetailSuccessAction {
  type: TicketDetail.SET_TICKET_DETAIL_SUCCESS;
  payload?: Ticket;
}

export interface SetTicketSystemDetailSuccessAction {
  type: TicketDetail.SET_TICKET_SYSTEM_DETAIL_SUCCESS;
  payload?: System;
}

export interface AddSpecAndSetToWorkAction {
  type: TicketDetail.ADD_SPEC_AND_SET_TO_WORK_TICKET_DETAIL;
  payload: AddSpecAndSetToWorkPayload;
}

export interface FetchHistoryRequestAction {
  type: TicketDetail.FETCH_HISTORY_REQUEST;
  payload: string;
}

export interface FetchHistorySuccessAction {
  type: TicketDetail.FETCH_HISTORY_SUCCESS;
  payload: TicketHistory[];
}

export interface ResetTicketDetailStateAction {
  type: TicketDetail.RESET_TICKET_DETAIL_STATE;
}

export interface EditTicketPriorityAction {
  type: TicketDetail.EDIT_TICKET_PRIORITY;
  payload: EditTicketPriorityPayload;
}

export interface SetIsErrorAction {
  type: TicketDetail.SET_IS_ERROR;
  payload: boolean;
}

export interface SetIsSuccessAction {
  type: TicketDetail.SET_IS_SUCCESS;
  payload: boolean;
}

export interface SetToastMessageAction {
  type: TicketDetail.SET_TOAST_MESSAGE;
  payload: string;
}

export interface FetchActionsForTicketDetailRequestAction {
  type: TicketDetail.FETCH_ACTIONS_FOR_TICKET_DETAIL_REQUEST;
  payload: string[];
}

export interface FetchActionsForTicketDetailSuccessAction {
  type: TicketDetail.FETCH_ACTIONS_FOR_TICKET_DETAIL_SUCCESS;
  payload: Action[];
}

export interface DeleteTicketRequestAction {
  type: TicketDetail.DELETE_TICKET_DETAIL_REQUEST;
  payload: Ticket;
}

export interface UpdateDataTicketRequestAction {
  type: TicketDetail.UPDATE_DATA_TICKET_DETAIL;
  payload: TicketUpdateType;
}

export interface TicketHistoryLoadingShow {
  type: TicketDetail.TICKET_HISTORY_LOADING_SHOW;
}

export interface TicketHistoryLoadingHide {
  type: TicketDetail.TICKET_HISTORY_LOADING_HIDE;
}

export interface CreateTicketInJiraAction {
  type: TicketDetail.CREATE_TICKET_IN_JIRA;
  payload: CreateTicketInJiraPayload;
}

export interface FetchIsSystemIntegratedWithJiraRequestAction {
  type: TicketDetail.FETCH_IS_SYSTEM_INTEGRATED_WITH_JIRA_REQUEST;
  payload: CheckExistIntegration;
}

export interface FetchIsSystemIntegratedWithJiraSuccessAction {
  type: TicketDetail.FETCH_IS_SYSTEM_INTEGRATED_WITH_JIRA_SUCCESS;
  payload: boolean;
}

export interface FetchJiraUrlRequestAction {
  type: TicketDetail.FETCH_JIRA_URL_REQUEST;
  payload: string;
}

export interface FetchJiraUrlSuccessAction {
  type: TicketDetail.FETCH_JIRA_URL_SUCCESS;
  payload: string;
}

export interface FetchCustomFieldsByTicketIdRequestAction {
  type: TicketDetail.FETCH_CUSTOM_FIELDS_BY_TICKET_ID_REQUEST;
  payload: string;
}

export interface FetchCustomFieldsByTicketIdSuccessAction {
  type: TicketDetail.FETCH_CUSTOM_FIELDS_BY_TICKET_ID_SUCCESS;
  payload: CustomTicketFields;
}

export interface UpdateCustomFieldsByTicketIdRequestAction {
  type: TicketDetail.UPDATE_CUSTOM_FIELDS_BY_TICKET_ID_REQUEST;
  payload: CustomFieldToRequest[];
}

export interface FetchNextStatusesRequestAction {
  type: TicketDetail.FETCH_NEXT_STATUSES_REQUEST;
  payload: NextStatusesRequest;
}

export interface FetchNextStatusesSuccessAction {
  type: TicketDetail.FETCH_NEXT_STATUSES_SUCCESS;
  payload: Status[];
}

export interface ChangeStatusAction {
  type: TicketDetail.CHANGE_STATUS;
  payload: Comment;
}

export interface CloseTicketRequestAction {
  type: TicketDetail.CLOSE_TICKET_REQUEST;
  payload: CloseTicketRequest;
}

export interface RestoreTicketRequestAction {
  type: TicketDetail.RESTORE_TICKET_REQUEST;
  payload?: string;
}

export interface FetchTicketTypesActions {
  type: TicketDetail.FETCH_TICKET_TYPES_REQUEST;
  payload: string;
}

export interface SetTicketSuccessTypesActions {
  type: TicketDetail.FETCH_TICKET_TYPES_SUCCESS;
  payload: TicketType[];
}

export interface UpdateTicketTypePayload {
  typeId: string;
}
export interface UpdateTicketTypeActions {
  type: TicketDetail.UPDATE_TICKET_TYPES_BY_TYPE_ID_REQUEST;
  payload: UpdateTicketTypePayload;
}

export interface ReopenTicketRequestAction {
  type: TicketDetail.REOPEN_TICKET_REQUEST;
  payload: ReopenTicket;
}

export interface FetchStatusesRequestAction {
  type: TicketDetail.FETCH_STATUSES_REQUEST;
  payload: TicketStatusesRequest;
}

export interface FetchStatusesSuccessAction {
  type: TicketDetail.FETCH_STATUSES_SUCCESS;
  payload?: KeyValueOption[];
}

export type TicketActionsTypes =
  | SetTicketDetailSuccessAction
  | FetchHistorySuccessAction
  | SetIsErrorAction
  | SetIsSuccessAction
  | FetchActionsForTicketDetailSuccessAction
  | FetchIsSystemIntegratedWithJiraSuccessAction
  | FetchJiraUrlSuccessAction
  | FetchCustomFieldsByTicketIdSuccessAction
  | UpdateCustomFieldsByTicketIdRequestAction
  | FetchNextStatusesSuccessAction
  | CloseTicketRequestAction
  | RestoreTicketRequestAction
  | SetTicketSystemDetailSuccessAction
  | SetTicketSuccessTypesActions
  | UpdateTicketTypeActions
  | FetchStatusesSuccessAction
  | SetToastMessageAction
  | FetchTicketCustomFieldsSuccessAction;
