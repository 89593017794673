import cn from 'clsx';
import { FC } from 'react';

import { CalendarProps } from '../../model';
import { Controls } from '../Controls';
import { Dates } from '../Dates';
import { TimePicker } from '../TimePicker';
import { Weekdays } from '../Weekdays';

import styles from './Calendar.module.scss';

export const Calendar: FC<CalendarProps> = ({
  className,
  showTime,
  rangeType,
  selectedDate,
  setSelectedDate,
  pickedDate,
  pickedDateRange,
  changeDate,
  changeDateByKey,
  approximateToDate,
  setApproximateToDate,
}) => (
  <div className={cn(styles.calendar, className)}>
    <Controls
      rangeType={rangeType}
      selectedDate={selectedDate}
      setSelectedDate={setSelectedDate}
    />
    <Weekdays />
    <Dates
      className={styles.calendar__dates}
      selectedDate={selectedDate}
      pickedDate={pickedDate}
      pickedDateRange={pickedDateRange}
      onDateClick={changeDate}
      approximateToDate={approximateToDate}
      setApproximateToDate={setApproximateToDate}
    />
    {showTime && (
      <TimePicker
        pickedDate={pickedDate}
        changeDateByKey={changeDateByKey}
        changeDate={changeDate}
      />
    )}
  </div>
);
