import { Reducer } from 'redux';

import { ReducerMap } from '@shared';

import { Note } from '../attachment';

import {
  FetchNotesByTicketIdSuccessAction,
  Notes,
  NotesActionsTypes,
} from './actionTypes';

export interface NotesReducerState {
  notes?: Note[];
  loading?: boolean;
}

const initialState: NotesReducerState = {
  notes: [],
  loading: false,
};

const coreReducerMap: ReducerMap<NotesReducerState, NotesActionsTypes> = {
  [Notes.NOTE_LOADING_SHOW]: (state) => ({
    ...state,
    loading: true,
  }),
  [Notes.NOTE_LOADING_HIDE]: (state) => ({
    ...state,
    loading: false,
  }),
  [Notes.FETCH_NOTES_BY_TICKET_ID_SUCCESS]: (state, action) => {
    const { payload } = action as FetchNotesByTicketIdSuccessAction;
    return {
      ...state,
      notes: payload,
    };
  },
  [Notes.RESET_NOTES_STATE]: () => ({
    ...initialState,
  }),
};

export const notesSlice: Reducer<NotesReducerState, NotesActionsTypes> = (
  state = initialState,
  action
) => {
  const reducer = coreReducerMap[action.type];
  if (!reducer) {
    return state;
  }
  return reducer(state, action);
};
