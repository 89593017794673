import { useState } from 'react';
import useResizeObserver, { ObservedSize } from 'use-resize-observer';

export const useResizeObserverWrapper = <T extends Element>() => {
  const [observedSize, setObservedSize] = useState<ObservedSize>({
    width: undefined,
    height: undefined,
  });

  const { ref } = useResizeObserver<T>({
    onResize: (size) => {
      requestAnimationFrame(() => setObservedSize(size));
    },
  });

  return { ref, ...observedSize };
};
