import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import {
  fetchWorkGroupsBySupAgBySystemRequestSupAgDetail,
  getGroupsFilterSupAgDetail,
  getGroupsSupAgDetail,
  getPropsGroupsSupAgDetail,
  getSupAgDetail,
  getSystemIdForSupAg,
  setFilterWorkGroupsBySupAgBySystemSupAgDetail,
  setPageWorkGroupsBySupAgBySystemSupAgDetail,
  setSizePageWorkGroupsBySupAgBySystemSupAgDetail,
  setSortWorkGroupsBySupAgBySystemSupAgDetail,
} from '@entities/supplementary-agreement';
import { WorkGroupFilter } from '@entities/work-group';
import { checkObjectIdentity } from '@shared';
import { ParamsProps } from '@widgets/supplementary-agreement';

export const useWorkGroupsSupAgTableContainer = () => {
  const { contractId: contractFromUrl, systemId: systemFromIdUrl } =
    useParams<ParamsProps>();

  const workGroups = useSelector(getGroupsSupAgDetail);
  const supplementaryAgreement = useSelector(getSupAgDetail);
  const contractId = contractFromUrl || supplementaryAgreement?.mainContractId;
  const systemId = useSelector(getSystemIdForSupAg) || systemFromIdUrl;

  const filterValues = useSelector(getGroupsFilterSupAgDetail);

  const { totalElements, pageNum, pageSize, sort, loading } = useSelector(
    getPropsGroupsSupAgDetail
  );

  const dispatch = useDispatch();

  const [showFilterRow, setShowFilterRow] = useState(false);

  const isFilterActive = !checkObjectIdentity(filterValues, {});
  const searchDisabled =
    !workGroups?.length || (showFilterRow && isFilterActive);

  const toggleFilterRow = () => {
    setShowFilterRow((prevState) => !prevState);
  };

  const handleSort = (value: string) => {
    dispatch(setSortWorkGroupsBySupAgBySystemSupAgDetail(value));
    dispatch(
      fetchWorkGroupsBySupAgBySystemRequestSupAgDetail({
        contractId,
        systemId,
      })
    );
  };

  const handleChangePage = (page: number) => {
    dispatch(setPageWorkGroupsBySupAgBySystemSupAgDetail(page));
    dispatch(
      fetchWorkGroupsBySupAgBySystemRequestSupAgDetail({
        contractId,
        systemId,
      })
    );
  };

  const handleChangePageSize = (arg: number) => {
    dispatch(setSizePageWorkGroupsBySupAgBySystemSupAgDetail(arg));
  };

  const handleFilter = (filter: WorkGroupFilter) => {
    dispatch(setPageWorkGroupsBySupAgBySystemSupAgDetail(0));
    dispatch(setFilterWorkGroupsBySupAgBySystemSupAgDetail(filter));
    dispatch(
      fetchWorkGroupsBySupAgBySystemRequestSupAgDetail({
        contractId,
        systemId,
      })
    );
  };

  return {
    state: {
      showDeleteModal: false,
      searchDisabled,
      prepareCreateTitle: () => '',
      prepareDisabled: () => false,
      pageNum,
      pageSize,
      sort,
      workGroups,
      showFilterRow,
      isFilterActive,
      isModal: false,
      totalElements,
      loading,
    },
    methods: {
      toggleDeleteModal: () => undefined,
      toggleFilterRow,
      handleSort,
      prepareHandler: () => undefined,
      handleChangePage,
      handleChangePageSize,
      toggleModal: () => undefined,
      handleFilter,
    },
  };
};
