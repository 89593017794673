import { ContractStatus, TableContractsTypes } from '@entities/contract';
import { RenderTypes, SelectOption, TableColumns } from '@shared';

export const CONTRACTS_FULL_FIELDS: TableColumns = [
  {
    title: 'Название',
    sortField: 'title',
    fieldName: 'title',
    renderType: RenderTypes.NOTIFICATION_LINK,
  },
  {
    title: 'Организация',
    fieldName: 'organizationId',
    sortField: 'organization',
    renderType: RenderTypes.NOTIFICATION_LINK,
  },
  {
    title: 'Тип',
    sortField: 'type',
    fieldName: 'type',
    renderType: RenderTypes.CONDITION,
  },
  { title: 'Описание', fieldName: 'description', sortField: 'description' },
  { title: 'Статус', sortField: 'status', fieldName: 'status' },
  { title: 'Номер', sortField: 'number', fieldName: 'number' },
  { title: 'Дата окончания', sortField: 'endDate', fieldName: 'endDate' },
];

export const TABLE_FROM_ORGANIZATIONS_FIELDS: TableColumns = [
  {
    title: 'Название',
    fieldName: 'title',
    sortField: 'title',
    renderType: RenderTypes.NOTIFICATION_LINK,
  },
  {
    title: 'Тип',
    fieldName: 'type',
    sortField: 'type',
    renderType: RenderTypes.CONDITION,
  },
  {
    title: 'Статус',
    sortField: 'status',
    fieldName: 'status',
  },
];

export const TABLE_FROM_SYSTEMS_FIELDS = [
  { title: 'Название', fieldName: 'title', sortField: 'title' },
  { title: 'Тип', fieldName: 'type', sortField: 'type' },
  { title: 'Статус', fieldName: 'status', sortField: 'status' },
  { title: 'Дата', fieldName: 'endDate', sortField: 'endDate' },
];

export const STATUS_OPTIONS: SelectOption<ContractStatus>[] = [
  { title: 'Завершен', value: ContractStatus.CLOSED },
  { title: 'Активен', value: ContractStatus.ACTIVE },
  { title: 'Запланирован', value: ContractStatus.PLANNED },
  { title: 'Не подписан', value: ContractStatus.NOT_SIGNED },
];

export const PAGINATION_RANGE_MAP: Record<
  TableContractsTypes,
  number | undefined
> = {
  [TableContractsTypes.FULL]: 1,
  [TableContractsTypes.FROM_ORGANIZATIONS]: 0,
  [TableContractsTypes.FROM_SYSTEMS]: 0,
};
