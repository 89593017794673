import { call, put, select, takeEvery } from 'redux-saga/effects';

import { createError } from '@shared';

import { requestsEmployees } from '../../api/desktop-employees';
import { getDesktopFilters } from '../selectors';
import {
  DesktopFilters,
  EmployeesStatistic,
  SlaStatistic,
  TicketsStatistic,
} from '../types';

import { getEmployeesPageNumber, getEmployeesPageSize } from './selectors';
import {
  fetchEmployeesStatisticRequest,
  fetchEmployeesStatisticSuccessEmployees,
  fetchSlaStatisticRequestEmployees,
  fetchSlaStatisticSuccessEmployees,
  fetchTicketsStatisticRequestEmployees,
  fetchTicketsStatisticSuccessEmployees,
  setIsLoadingEmployees,
} from './slice';

function* slaStatisticFetch() {
  try {
    const filter: DesktopFilters = yield select(getDesktopFilters);
    const data: SlaStatistic = yield call(
      requestsEmployees.fetchEmployeesSlaStatistic,
      filter
    );
    yield put(fetchSlaStatisticSuccessEmployees(data));
  } catch (error) {
    createError(error);
  }
}

function* employeesStatisticFetch() {
  try {
    yield put(setIsLoadingEmployees(true));
    const pageNum: ReturnType<typeof getEmployeesPageNumber> = yield select(
      getEmployeesPageNumber
    );
    const pageSize: ReturnType<typeof getEmployeesPageSize> = yield select(
      getEmployeesPageSize
    );
    const filter: DesktopFilters = yield select(getDesktopFilters);
    const data: EmployeesStatistic = yield call(
      requestsEmployees.fetchEmployeesStatistic,
      pageNum,
      pageSize,
      filter
    );
    yield put(fetchEmployeesStatisticSuccessEmployees(data));
    yield put(setIsLoadingEmployees(false));
  } catch (error) {
    createError(error);
  }
}

function* ticketsStatisticFetch() {
  try {
    const filter: DesktopFilters = yield select(getDesktopFilters);
    const data: TicketsStatistic = yield call(
      requestsEmployees.fetchEmployeesTicketsStatistic,
      filter
    );
    yield put(fetchTicketsStatisticSuccessEmployees(data));
  } catch (error) {
    createError(error);
  }
}

export function* desktopEmployeesSaga() {
  yield takeEvery(fetchSlaStatisticRequestEmployees.type, slaStatisticFetch);
  yield takeEvery(fetchEmployeesStatisticRequest.type, employeesStatisticFetch);
  yield takeEvery(
    fetchTicketsStatisticRequestEmployees.type,
    ticketsStatisticFetch
  );
}
