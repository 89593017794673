import { TicketsTableFields } from '@entities/tickets';
import {
  ISettingFields,
  RenderTypes,
  SettingField,
  TableColumns,
} from '@shared';

const getRenderTypes = (value: string) => {
  if (value === TicketsTableFields.TICKET_NUMBER) {
    return { renderType: RenderTypes.NOTIFICATION_LINK };
  }
  if (
    value === TicketsTableFields.SYSTEM ||
    value === TicketsTableFields.SOURCE
  ) {
    return { renderType: RenderTypes.CONDITION };
  }
  return undefined;
};

const getPreparedSortField = (value: string): string => {
  const idsMap: Record<string, string> = {
    specialistId: 'specialist',
    clientId: 'client',
    systemId: 'system',
    tags: '',
  };
  return idsMap[value] ?? value;
};

const preparedColumns = (columns: SettingField[], isDefaultColumns?: boolean) =>
  columns.map(({ name, naturalKey }) => ({
    title: name,
    sortField: getPreparedSortField(naturalKey),
    fieldName: naturalKey,
    ...(isDefaultColumns && getRenderTypes(name)),
  }));

export const getColumns = (settingFields?: ISettingFields): TableColumns => {
  const defaultColumns = preparedColumns(
    settingFields?.defaultColumns || [],
    true
  );
  const customColumns = preparedColumns(settingFields?.customColumns || []);
  return [...defaultColumns, ...customColumns];
};
