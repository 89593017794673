import { isBoolean, isString } from 'lodash';

import {
  ACTIVE_SELECT_OPTIONS,
  getReverseDateFromCustomField,
} from '@shared/ui/Table';
import { TableCustomField } from '@shared/ui/Table/model';

export const getValueFromFieldFlag = (value: TableCustomField) => {
  if (isBoolean(value)) {
    return value ? ACTIVE_SELECT_OPTIONS[0] : ACTIVE_SELECT_OPTIONS[1];
  }
  return null;
};

export const getValueFromFieldText = (value?: TableCustomField) =>
  isString(value) ? value : '';

export const getValueFromFieldDate = (value?: TableCustomField) =>
  getReverseDateFromCustomField(getValueFromFieldText(value));
