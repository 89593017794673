import { PayloadAction } from '@reduxjs/toolkit';
import { call, put, takeEvery } from 'redux-saga/effects';

import { Organization } from '@entities/organizations/model/types';
import { createError, KeyValueOption, ResponseWithMeta } from '@shared';

import { requests } from '../api';

import {
  createStoragesRequest,
  deleteStorageRequest,
  editStoragesRequest,
  fetchAdminIdOrganizationRequest,
  fetchAdminIdOrganizationSuccess,
  fetchOrganizationRequest,
  fetchOrganizationsForCreateRequest,
  fetchOrganizationsForCreateSuccessJoin,
  fetchOrganizationsForCreateSuccessUpdate,
  fetchOrganizationsSuccess,
  fetchStoragesRequest,
  fetchStoragesSuccess,
  fetchWorkGroupsEditorsRequest,
  fetchWorkGroupsEditorsSuccessJoin,
  fetchWorkGroupsEditorsSuccessUpdate,
  fetchWorkGroupsViewersRequest,
  fetchWorkGroupsViewersSuccessJoin,
  fetchWorkGroupsViewersSuccessUpdate,
  setStoragesLoading,
} from './slice';
import { Storages } from './types';

export function* organizationsFetch({ payload }: PayloadAction<string>) {
  try {
    const organizations: KeyValueOption[] = yield call(
      requests.fetchOrganizations,
      payload
    );
    yield put(fetchOrganizationsSuccess(organizations));
  } catch (error) {
    createError(error);
  }
}

export function* adminIdOrganizationsFetch() {
  try {
    const adminOrganization: Organization = yield call(
      requests.fetchAdminOrganizations
    );
    if (adminOrganization?.id) {
      yield put(fetchAdminIdOrganizationSuccess(adminOrganization?.id));
    }
  } catch (error) {
    createError(error);
  }
}

export function* organizationsForCreateFetch({
  payload,
}: ReturnType<typeof fetchOrganizationsForCreateRequest>) {
  try {
    const { updateType, pageNum, pageSize, searchValue } = payload;
    const organizations: ResponseWithMeta<KeyValueOption[]> = yield call(
      requests.fetchOrganizationsForCreate,
      pageNum,
      pageSize,
      'TITLE_ASC',
      { title: searchValue }
    );

    if (updateType === 'join') {
      yield put(
        fetchOrganizationsForCreateSuccessJoin({
          content: organizations.content,
          pageNum: organizations.page,
          totalElements: organizations.totalElements,
        })
      );
    }
    if (updateType === 'update') {
      yield put(
        fetchOrganizationsForCreateSuccessUpdate({
          content: organizations.content,
          pageNum: organizations.page,
          totalElements: organizations.totalElements,
        })
      );
    }
  } catch (error) {
    createError(error);
  }
}

export function* workGroupsViewersFetch({
  payload,
}: ReturnType<typeof fetchWorkGroupsViewersRequest>) {
  try {
    const { updateType, pageNum, pageSize, searchValue, filter } = payload;
    const workGroups: ResponseWithMeta<KeyValueOption[]> = yield call(
      requests.fetchWorkGroups,
      pageNum,
      pageSize,
      'TITLE_ASC',
      { title: searchValue, organizationIds: filter?.organizationIds }
    );
    if (updateType === 'join') {
      yield put(
        fetchWorkGroupsViewersSuccessJoin({
          content: workGroups.content,
          pageNum: workGroups.page,
          totalElements: workGroups.totalElements,
        })
      );
    }
    if (updateType === 'update') {
      yield put(
        fetchWorkGroupsViewersSuccessUpdate({
          content: workGroups.content,
          pageNum: workGroups.page,
          totalElements: workGroups.totalElements,
        })
      );
    }
  } catch (error) {
    createError(error);
  }
}

export function* workGroupsEditorsFetch({
  payload,
}: ReturnType<typeof fetchWorkGroupsEditorsRequest>) {
  try {
    const { updateType, pageNum, pageSize, filter, searchValue } = payload;
    const workGroups: ResponseWithMeta<KeyValueOption[]> = yield call(
      requests.fetchWorkGroups,
      pageNum,
      pageSize,
      'TITLE_ASC',
      { title: searchValue, organizationIds: filter?.organizationIds }
    );
    if (updateType === 'join') {
      yield put(
        fetchWorkGroupsEditorsSuccessJoin({
          content: workGroups.content,
          pageNum: workGroups.page,
          totalElements: workGroups.totalElements,
        })
      );
    }
    if (updateType === 'update') {
      yield put(
        fetchWorkGroupsEditorsSuccessUpdate({
          content: workGroups.content,
          pageNum: workGroups.page,
          totalElements: workGroups.totalElements,
        })
      );
    }
  } catch (error) {
    createError(error);
  }
}

export function* storagesFetch(
  action: ReturnType<typeof fetchStoragesRequest>
) {
  try {
    yield put(setStoragesLoading(true));
    const storages: Storages = yield call(
      requests.fetchStorages,
      action.payload
    );
    yield put(fetchStoragesSuccess(storages));
    yield put(setStoragesLoading(false));
  } catch (error) {
    createError(error);
  }
}

export function* createStorage(
  action: ReturnType<typeof createStoragesRequest>
) {
  try {
    yield call(requests.createStorage, action.payload);

    const storages: Storages = yield call(requests.fetchStorages, {});
    yield put(fetchStoragesSuccess(storages));
  } catch (error) {
    createError(error);
  }
}

export function* editStorage(action: ReturnType<typeof editStoragesRequest>) {
  try {
    yield call(requests.editStorage, action.payload);

    const storages: Storages = yield call(requests.fetchStorages, {});
    yield put(fetchStoragesSuccess(storages));
  } catch (error) {
    createError(error);
  }
}

export function* deleteStorage(
  action: ReturnType<typeof deleteStorageRequest>
) {
  try {
    yield call(requests.deleteStorage, action.payload);

    const storages: Storages = yield call(requests.fetchStorages, {});
    yield put(fetchStoragesSuccess(storages));
  } catch (error) {
    createError(error);
  }
}

export function* storagesSaga() {
  yield takeEvery(fetchOrganizationRequest.type, organizationsFetch);
  yield takeEvery(
    fetchAdminIdOrganizationRequest.type,
    adminIdOrganizationsFetch
  );
  yield takeEvery(fetchStoragesRequest.type, storagesFetch);
  yield takeEvery(fetchWorkGroupsViewersRequest.type, workGroupsViewersFetch);
  yield takeEvery(fetchWorkGroupsEditorsRequest.type, workGroupsEditorsFetch);
  yield takeEvery(createStoragesRequest.type, createStorage);
  yield takeEvery(editStoragesRequest.type, editStorage);
  yield takeEvery(
    fetchOrganizationsForCreateRequest.type,
    organizationsForCreateFetch
  );
  yield takeEvery(deleteStorageRequest.type, deleteStorage);
}
