import { FC } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
  fetchSupplementaryAgreementsRequest,
  getPropsSupplementaryAgreements,
  getSupplementaryAgreementsContractDetail,
  setPageSizeSupplementaryAgreementsContractDetail,
  setPageSupplementaryAgreementsContractDetail,
  setSortSupplementaryAgreementsContractDetail,
} from '@entities/contract';
import { CountRecords, Pagination } from '@shared';

import { useContractId } from '../../../../lib';
import { SupplementaryAgreementTable } from '../../SupplementaryAgreementTable';

import styles from './SupplementaryAgreements.module.scss';

export const SupplementaryAgreementsTableContainer: FC = () => {
  const dispatch = useDispatch();

  const supplementaryAgreements = useSelector(
    getSupplementaryAgreementsContractDetail
  );
  const contractId = useContractId();
  const { pageNum, pageSize, totalElements, loadingSA, sortSA } = useSelector(
    getPropsSupplementaryAgreements
  );

  const onChangePage = (page: number) => {
    if (contractId) {
      dispatch(setPageSupplementaryAgreementsContractDetail(page));
      dispatch(fetchSupplementaryAgreementsRequest(contractId));
    }
  };

  const onChangePageSize = (size: number) => {
    if (contractId) {
      dispatch(setPageSizeSupplementaryAgreementsContractDetail(size));
    }
  };

  const handleSort = (value: string) => {
    if (contractId) {
      dispatch(setSortSupplementaryAgreementsContractDetail(value));
      dispatch(fetchSupplementaryAgreementsRequest(contractId));
    }
  };

  return (
    <>
      <SupplementaryAgreementTable
        supplementaryAgreements={supplementaryAgreements}
        loading={loadingSA}
        onSort={handleSort}
        sort={sortSA}
      />
      <div className={styles.supplementaryAgreementsTableContainer__pagination}>
        <CountRecords
          records={totalElements}
          className={styles.supplementaryAgreementsTableContainer__countRecords}
        />
        <Pagination
          pageNum={pageNum}
          pageSize={pageSize}
          elementsCount={totalElements}
          handleChangePage={onChangePage}
          handleChangePageSize={onChangePageSize}
        />
      </div>
    </>
  );
};
