import { type FC, type HTMLAttributes } from 'react';

import { FileItem } from '../../../FileItem';
import { createBemClass } from '../../../helpers/createBemClass';
import { Typography } from '../../../Typography';

import styles from './ListFiles.module.scss';

interface ListFilesProps
  extends Omit<HTMLAttributes<HTMLDivElement>, 'children'> {
  files: File[];
  onDelete: (file: File) => void;
  loading?: boolean;
}

const rootClassName = createBemClass('list-files');

export const ListFiles: FC<ListFilesProps> = ({
  files,
  onDelete,
  loading = false,
  ...other
}) => {
  const onDeleteFile = (file: File) => () => {
    onDelete(file);
  };

  return (
    <div {...other}>
      <Typography
        className={styles[rootClassName({ elementName: 'title' })]}
        variant="h5"
      >
        Загруженные файлы
      </Typography>
      <ul className={styles[rootClassName({ elementName: 'list' })]}>
        {files.map((file) => (
          <FileItem
            key={`${file.name}-${file.size}-${file.lastModified}`}
            name={file.name}
            onDelete={onDeleteFile(file)}
            size={file.size}
            status={loading ? 'loading' : 'default'}
          />
        ))}
      </ul>
    </div>
  );
};
