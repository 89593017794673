import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
  deleteTriggerRequest,
  fetchCurrentTrigger,
  fetchTriggersRequest,
  getCurrentTrigger,
  getCurrentTriggerId,
  getFilterValues,
  getPropsTriggers,
  getTriggers,
  setCurrentTriggerId,
  setCurrentTriggersPage,
  setCurrentTriggerSuccess,
  setSizePage,
  setSortTriggers,
} from '@entities/triggers';
import { checkObjectIdentity, getScreenWidth, ScreenWidth } from '@shared';

export const useTriggersTableContainer = () => {
  const dispatch = useDispatch();

  const { pageNum, pageSize, sortTriggers, loadingTriggers, totalElements } =
    useSelector(getPropsTriggers);
  const triggers = useSelector(getTriggers);
  const currentTrigger = useSelector(getCurrentTrigger);
  const currentTriggerId = useSelector(getCurrentTriggerId);
  const filterValues = useSelector(getFilterValues);
  const screenWidth = useSelector(getScreenWidth);

  const [isModal, setIsModal] = useState<boolean>(false);
  const [changeModals, setOpenChangeModals] = useState({
    isEditMode: false,
    isOpenModal: false,
  });
  const [showFilterRow, setShowFilterRow] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);

  const isMobile = screenWidth === ScreenWidth.MOBILE;
  const isMobileSmall = screenWidth === ScreenWidth.MOBILE_SMALL;

  const toggleDeleteModal = () => {
    setShowDeleteModal((prev) => !prev);
  };

  const toggleIsOpenChangeModals = () => {
    setOpenChangeModals((prev) => ({
      ...prev,
      isOpenModal: !prev.isOpenModal,
    }));
  };

  const setIsEditModeModal = () => {
    setOpenChangeModals((prev) => ({ ...prev, isEditMode: true }));
  };

  const setIsCreateModeModal = () => {
    setOpenChangeModals((prev) => ({ ...prev, isEditMode: false }));
  };

  const handleCreateClick = () => {
    setIsCreateModeModal();
    toggleIsOpenChangeModals();
  };

  const handleEditClick = () => {
    setIsEditModeModal();
    toggleIsOpenChangeModals();
  };

  const toggleFilterRow = () => {
    setShowFilterRow((prevState) => !prevState);
  };

  const isFilterActive = !checkObjectIdentity(filterValues, {});

  const searchDisabled = !triggers?.length || (showFilterRow && isFilterActive);

  const toggleModal = () => {
    setIsModal(!isModal);
    setShowFilterRow(false);
  };

  const handleChangePage = (page: number) => {
    dispatch(setCurrentTriggersPage(page));
    dispatch(fetchTriggersRequest());
  };

  const handleChangePageSize = (size: number) => {
    dispatch(setSizePage(size));
  };

  const handleSort = (value: string) => {
    dispatch(setSortTriggers(value));
    dispatch(fetchTriggersRequest());
  };

  const handleRowFullTableClick = (value: string) => {
    if (currentTriggerId !== value) {
      dispatch(setCurrentTriggerId(value));
      dispatch(fetchCurrentTrigger(value));
      return;
    }
    if (isMobile || isMobileSmall) {
      dispatch(setCurrentTriggerId(undefined));
      dispatch(setCurrentTriggerSuccess(undefined));
    }
  };

  const handleShowModalDelete = () => {
    if (currentTrigger?.id) {
      setShowDeleteModal(true);
    }
  };

  const handleDelete = () => {
    if (currentTrigger?.id) {
      dispatch(deleteTriggerRequest(currentTrigger.id));
    }
  };

  return {
    methods: {
      handleDelete,
      toggleDeleteModal,
      toggleIsOpenChangeModals,
      handleEditClick,
      toggleModal,
      toggleFilterRow,
      handleChangePage,
      handleChangePageSize,
      handleSort,
      handleCreateClick,
      handleShowModalDelete,
      onRowClick: handleRowFullTableClick,
    },
    state: {
      pageNum,
      pageSize,
      sortTriggers,
      totalElements,
      triggers,
      currentTrigger,
      currentTriggerId,
      isModal,
      changeModals,
      filterValues,
      showFilterRow,
      showDeleteModal,
      loadingTriggers,
      searchDisabled,
    },
  };
};
