import cn from 'clsx';
import { forwardRef, InputHTMLAttributes, MouseEvent, ReactNode } from 'react';

import { TabItem } from '@shared/model';

import styles from './RadioGroup.module.scss';

interface RadioGroupProps extends InputHTMLAttributes<HTMLInputElement> {
  items: TabItem[];
  className?: string;
  renderComponent: (title?: string) => ReactNode;
}

export const RadioGroup = forwardRef<HTMLInputElement, RadioGroupProps>(
  ({ className, items, onMouseDown, renderComponent, ...otherProps }, ref) => {
    const handleClick = (event: MouseEvent<HTMLInputElement>) => {
      event.preventDefault();
      if (onMouseDown) {
        onMouseDown(event);
      }
    };

    const itemsEls = items.map(({ title, value, disabled, defaultChecked }) => (
      <label
        key={String(value)}
        className={cn(styles.radioGroup__label, {
          [styles.radioGroup__label_disabled]: disabled,
        })}
      >
        <input
          ref={ref}
          type="radio"
          value={value}
          onMouseDown={handleClick}
          defaultChecked={defaultChecked}
          disabled={disabled}
          className={styles.radioGroup__input}
          {...otherProps}
        />
        {renderComponent(title)}
      </label>
    ));

    return <div className={cn(styles.radioGroup, className)}>{itemsEls}</div>;
  }
);
