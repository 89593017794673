import cn from 'clsx';
import { FC } from 'react';
import ReactPaginate from 'react-paginate';

import { Size } from '@shared/model';

import { ArrowPaginationDoubleIcon, ArrowPaginationIcon } from '../../assets';
import { Select, SelectOption } from '../Select';

import { OPTIONS } from './config';
import { usePaginationPageSizeStored } from './lib/hooks';
import styles from './Pagination.module.scss';

export interface PaginationProps {
  pageNum?: number;
  elementsCount?: number;
  pageSize?: number;
  handleChangePage(page: number): void;
  handleChangePageSize?: (size: number) => void;
  marginPagesDisplayed?: number;
  isMobilePagination?: boolean;
  className?: string;
  disabled?: boolean;
  entityName?: string;
}

export const Pagination: FC<PaginationProps> = ({
  elementsCount,
  pageNum,
  pageSize = 10,
  handleChangePage,
  handleChangePageSize,
  marginPagesDisplayed = 1,
  isMobilePagination,
  className,
  disabled,
  entityName,
}) => {
  const { currentPageSize, handleChangeCurrentPageSize } =
    usePaginationPageSizeStored({
      handleChangePageSize,
      initialPageSize: pageSize,
      entityName,
    });

  const pagesCount = elementsCount ? Math.ceil(elementsCount / pageSize) : 1;

  const handlePageDoublePrevClick = () => {
    handleChangePage(0);
  };

  const handlePagePrevClick = () => {
    if (pageNum) {
      handleChangePage(pageNum - 1);
    }
  };

  const handlePageDoubleNextClick = () => {
    handleChangePage(pagesCount - 1);
  };

  const handlePageNextClick = () => {
    if (pageNum) {
      return handleChangePage(pageNum + 1);
    }
    return handleChangePage(1);
  };

  const handlePageClick = (event: { selected: number }) => {
    handleChangePage(event.selected);
  };

  const handleChangePageSizeWithResetPageNumber = (
    optionSize: SelectOption<number>
  ) => {
    if (handleChangePageSize) {
      handleChangePageSize(optionSize.value);
    }
    if (entityName) {
      handleChangeCurrentPageSize(optionSize.value);
    }
    handleChangePage(0);
  };

  return (
    <div
      className={cn(styles.pagination, className, {
        [styles.pagination_mobile]: isMobilePagination,
      })}
    >
      <div className={styles.pagination__wrapper}>
        <button
          disabled={!pageNum || disabled}
          className={styles.pagination__firstPageButton}
          onClick={handlePageDoublePrevClick}
          tabIndex={pageNum === 0 ? -1 : 0}
        >
          <ArrowPaginationDoubleIcon
            className={styles.pagination__firstPageButtonIcon}
          />
        </button>
        <button
          disabled={Boolean(!pagesCount) || disabled}
          className={cn(
            styles.pagination__firstPageButton,
            styles.pagination__buttonMobile
          )}
          onClick={handlePagePrevClick}
        >
          <ArrowPaginationIcon
            className={styles.pagination__firstPageButtonIcon}
          />
        </button>
        <div className={styles.pagination__pageNumberMobile}>
          {`Страница ${(pageNum || 0) + 1}`}
        </div>
        <ReactPaginate
          nextLabel={<ArrowPaginationIcon />}
          onPageChange={handlePageClick}
          marginPagesDisplayed={marginPagesDisplayed}
          pageCount={pagesCount}
          forcePage={pageNum}
          previousLabel={
            <ArrowPaginationIcon className={styles.pagination__prev} />
          }
          nextClassName={styles.pagination__nextButton}
          previousClassName={styles.pagination__prevButton}
          onClick={() => !disabled}
          containerClassName={cn(styles.pagination__button, {
            [styles.pagination__button_disabled]: disabled,
          })}
          activeClassName={styles.pagination__button_active}
          disabledClassName={styles.pagination__button_disabled}
          disabledLinkClassName={styles.pagination__button_disabled}
        />
        <button
          disabled={pagesCount === (pageNum || 0) + 1 || disabled}
          className={cn(
            styles.pagination__lastPageButton,
            styles.pagination__buttonMobile
          )}
          onClick={handlePageNextClick}
        >
          <ArrowPaginationIcon
            className={styles.pagination__lastPageButtonIcon}
          />
        </button>
        <button
          disabled={pagesCount === (pageNum || 0) + 1 || disabled}
          className={styles.pagination__lastPageButton}
          onClick={handlePageDoubleNextClick}
          tabIndex={pagesCount === (pageNum || 0) + 1 ? -1 : 0}
        >
          <ArrowPaginationDoubleIcon
            className={styles.pagination__lastPageButtonIcon}
          />
        </button>
      </div>
      <Select<number>
        size={Size.xs}
        options={OPTIONS}
        onChange={handleChangePageSizeWithResetPageNumber}
        className={styles.pagination__select}
        dropdownContentClassName={styles.pagination__selectContent}
        titleClassName={styles.pagination__selectTitle}
        currentOptionClassName={styles.pagination__selectCurrentOption}
        value={OPTIONS.find((item) => item.value === currentPageSize)}
        disabled={disabled}
        isTooltip={false}
      />
    </div>
  );
};
