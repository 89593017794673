import cn from 'clsx';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';

import { getIsClient } from '@entities/auth';
import { getIsNotCheckedNotifications } from '@entities/notifications';
import { getIsUncheckedNews } from '@entities/profile';
import { UserTabType } from '@entities/profile/model/types';
import {
  AuthService,
  ExitIcon,
  InfoBlock,
  MegaphoneIcon,
  NotificationCircle,
  RouterHref,
  SettingsIcon,
  SmallBell,
  TelegramIcon,
  Typography,
  TypographyVariants,
} from '@shared';

import styles from './ProfileUserTabs.module.scss';

export const ProfileUserTabs = () => {
  const { t: tGLobal } = useTranslation('global');
  const { t: tNavigation } = useTranslation('navigation');

  const { push } = useHistory();
  const { pathname } = useLocation();

  const isNotCheckedNotifications = useSelector(getIsNotCheckedNotifications);
  const isClient = useSelector(getIsClient);
  const isUncheckedNews = useSelector(getIsUncheckedNews);

  const tabs: UserTabType[] = useMemo(
    () => [
      {
        title: (
          <Typography
            variant={TypographyVariants.b3}
            className={styles.profileUserTabs__titleBlockNotifications}
          >
            {tNavigation('notifications.title')}
          </Typography>
        ),
        icon: <SmallBell className={styles.profileUserTabs__icon} />,
        classNameIconWrapper: styles.profileUserTabs__iconBlockNotifications,
        path: RouterHref.ProfileNotifications,
        onClick: () => {
          push(`${RouterHref.ProfileNotifications}/all`);
        },
        hasCircle: isNotCheckedNotifications,
      },
      {
        title: 'Чат бот',
        icon: <TelegramIcon className={styles.profileUserTabs__icon} />,
        path: RouterHref.ProfileTelegram,
        classNameIconWrapper: styles.profileUserTabs__iconBlockTelegram,
        onClick: () => {
          push(RouterHref.ProfileTelegram);
        },
        isHide: !isClient,
      },
      {
        title: (
          <Typography
            variant={TypographyVariants.b3}
            className={styles.profileUserTabs__titleBlockNotifications}
          >
            Новости
          </Typography>
        ),
        icon: <MegaphoneIcon className={styles.profileUserTabs__icon} />,
        classNameIconWrapper: styles.profileUserTabs__iconBlockNews,
        path: RouterHref.ProfileNews,
        onClick: () => {
          push(RouterHref.ProfileNews);
        },
        hasCircle: isUncheckedNews,
        circleClassName: styles.profileUserTabs__iconCircle_news,
      },
      {
        title: tGLobal('settings'),
        icon: <SettingsIcon className={styles.profileUserTabs__icon} />,
        path: RouterHref.ProfileSettings,
        classNameIconWrapper: styles.profileUserTabs__iconBlockSettings,
        onClick: () => {
          push(RouterHref.ProfileSettings);
        },
      },
      {
        title: tGLobal('exit'),
        icon: (
          <ExitIcon
            className={cn(
              styles.profileUserTabs__icon,
              styles.userTabs__iconExit
            )}
          />
        ),
        classNameIconWrapper: '',
        onClick: () => {
          AuthService.doLogout();
        },
      },
    ],
    [tNavigation, isNotCheckedNotifications, isUncheckedNews]
  );

  const tabsList = tabs.map((item) => {
    const {
      icon: Icon,
      title,
      onClick,
      classNameIconWrapper,
      path,
      hasCircle,
      isHide,
      circleClassName,
    } = item;

    const isTabActive = path?.length && pathname.includes(path);

    const circle = hasCircle && (
      <NotificationCircle
        className={cn(styles.profileUserTabs__iconCircle, circleClassName)}
      />
    );

    if (isHide) {
      return null;
    }

    return (
      <button
        key={classNameIconWrapper}
        onClick={onClick}
        className={cn(styles.profileUserTabs__tab, {
          [styles.profileUserTabs__tab_active]: isTabActive,
        })}
      >
        <InfoBlock
          icon={Icon}
          mainTitle={title}
          classNameIconWrapper={cn(styles.profileUserTabs__iconBlock, {
            [classNameIconWrapper]: isTabActive,
          })}
          classNameTitle={styles.profileUserTabs__text}
          classNameInfoWrapper={styles.profileUserTabs__textWrapper}
        />
        {circle}
      </button>
    );
  });

  return <div className={styles.profileUserTabs}>{tabsList}</div>;
};
