import {
  ChangeEvent,
  Dispatch,
  FC,
  SetStateAction,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import MaskedInput from 'react-input-mask';

import { ClockThin2Icon } from '../../../../assets';
import { HOURS, MINUTES } from '../../config';
import { addZeroIfOneDigit, getPickedTimeValue } from '../../lib';
import { PickedTime, TimePickerProps } from '../../model';

import styles from './TimePicker.module.scss';
import { TimePickerCellList } from './ui';

export const TimePicker: FC<TimePickerProps> = ({
  pickedDate,
  changeDateByKey,
  changeDate,
}) => {
  const dropdownRef = useRef<HTMLDivElement>(null);
  const inputRef = useRef<HTMLInputElement>(null);

  const [hours, setHours] = useState<PickedTime[]>(HOURS);
  const [minutes, setMinutes] = useState<PickedTime[]>(MINUTES);
  const [pickedHours, setPickedHours] = useState<string>();
  const [pickedMinutes, setPickedMinutes] = useState<string>();
  const [showDropdown, setShowDropdown] = useState(false);

  const setTime = (
    setValue: Dispatch<SetStateAction<PickedTime[]>>,
    value: string
  ) => {
    setValue((prev) =>
      prev.map((item) => {
        if (item.valueForList === Number(value.replace('_', ''))) {
          return { ...item, isActive: true };
        }
        return { ...item, isActive: false };
      })
    );
  };

  const pickHours = (value: string) => {
    setPickedHours(value);
    setTime(setHours, value);
  };

  const pickMinutes = (value: string) => {
    setPickedMinutes(value);
    setTime(setMinutes, value);
  };

  const toggleShowDropdown = () => {
    setShowDropdown((prev) => !prev);
  };

  const onChange = (event?: ChangeEvent<HTMLInputElement>) => {
    const { value } = event?.target || {};

    if (value) {
      const [splitHours, splitMinutes] = value.split(':');
      if (splitHours) {
        pickHours(splitHours);
      }
      if (splitMinutes) {
        pickMinutes(splitMinutes);
      }
    }
  };

  useEffect(() => {
    const handleClickOutOfDropdown = (e: MouseEvent) => {
      const target = e.target as HTMLElement;
      const dropdownEl = dropdownRef.current as unknown as HTMLElement;

      if (showDropdown && !dropdownEl?.contains(target)) {
        setShowDropdown(false);
      }
    };
    document.addEventListener('click', handleClickOutOfDropdown);

    return () => {
      document.removeEventListener('click', handleClickOutOfDropdown);
    };
  }, [dropdownRef.current, showDropdown]);

  useEffect(() => {
    if (showDropdown && dropdownRef && dropdownRef.current) {
      const nodeList = dropdownRef.current.querySelectorAll(
        "div[class$='active']"
      );

      Array.from(nodeList).forEach((node) => {
        node.scrollIntoView({
          block: 'center',
        });
      });
    }
  }, [showDropdown]);

  useEffect(() => {
    if (pickedDate) {
      const newDate = new Date(
        pickedDate.getFullYear(),
        pickedDate.getMonth(),
        pickedDate.getDate(),
        pickedDate.getHours(),
        pickedDate.getMinutes()
      );

      pickHours(addZeroIfOneDigit(pickedDate.getHours()));
      pickMinutes(addZeroIfOneDigit(pickedDate.getMinutes()));

      if (changeDateByKey) {
        changeDateByKey(newDate);
        return;
      }

      changeDate(newDate);
      return;
    }
    if (!pickedDate) {
      setPickedHours(undefined);
      setPickedMinutes(undefined);
    }
  }, [
    pickedDate?.getFullYear(),
    pickedDate?.getMonth(),
    pickedDate?.getDate(),
    pickedDate?.getHours(),
    pickedDate?.getMinutes(),
  ]);

  useEffect(() => {
    if (pickedDate && pickedHours && pickedMinutes) {
      const newDate = new Date(
        pickedDate.getFullYear(),
        pickedDate.getMonth(),
        pickedDate.getDate(),
        Number(pickedHours) || 0,
        Number(pickedMinutes) || 0
      );

      if (changeDateByKey) {
        changeDateByKey(newDate);
        return;
      }

      changeDate(newDate);
    }
  }, [pickedHours, pickedMinutes]);

  const hourList = <TimePickerCellList list={hours} pickValue={pickHours} />;

  const minuteList = (
    <TimePickerCellList list={minutes} pickValue={pickMinutes} />
  );

  const pickedTimeValue = getPickedTimeValue(pickedHours, pickedMinutes);

  const input = (
    <MaskedInput
      inputRef={inputRef}
      value={pickedTimeValue}
      onChange={onChange}
      mask="99 : 99"
      placeholder="Время"
    />
  );

  const dropdownTime = useMemo(
    () =>
      showDropdown && (
        <div ref={dropdownRef} className={styles.timePicker__dropdown}>
          <div className={styles.timePicker__dropdown__content}>
            <div className={styles.timePicker__column}>{hourList}</div>
            <div className={styles.timePicker__divider} />
            <div className={styles.timePicker__column}>{minuteList}</div>
          </div>
        </div>
      ),
    [showDropdown, hours, minutes]
  );

  return (
    <button
      type="button"
      className={styles.timePicker}
      onClick={toggleShowDropdown}
    >
      <div className={styles.timePicker__dropdownWrapper}>
        <div className={styles.timePicker__time}>{input}</div>
        {dropdownTime}
      </div>

      <ClockThin2Icon className={styles.timePicker__icon} />
    </button>
  );
};
