import { createSelector } from 'reselect';

import { User } from '@entities/user/model/types';
import { SelectOption } from '@shared';

import { UsersState } from './types';

export const getLoading = (state: UsersState): boolean | undefined =>
  state.users.loading;
export const getLoadingAdd = (state: UsersState): boolean | undefined =>
  state.users.loadingAdd;
export const getLoadingUser = (state: UsersState): boolean | undefined =>
  state.users.loadingUser;
export const getUsers = (state: UsersState): User[] | undefined =>
  state.users.users;
export const getUsersClients = (state: UsersState): User[] | undefined =>
  state.users.usersClients;
export const getUsersSpecialists = (state: UsersState): User[] | undefined =>
  state.users.usersSpecialists;
export const getSortUsers = (state: UsersState) => state.users.sort;
export const getPropsUsersClients = (state: UsersState) => ({
  pageNum: state.users.paginationUserClients?.pageNum,
  pageSize: state.users.paginationUserClients?.pageSize,
  sort: state.users.sortClient,
  totalElements: state.users.paginationUserClients?.totalElements,
  loading: state.users.loading,
});
export const getPropsUsersSpecialists = (state: UsersState) => ({
  pageNum: state.users.paginationUserSpecialists?.pageNum,
  pageSize: state.users.paginationUserSpecialists?.pageSize,
  sort: state.users.sortSpecialist,
  totalElements: state.users.paginationUserSpecialists?.totalElements,
  loading: state.users.loading,
});
export const getPropsUsers = (state: UsersState) => ({
  pageNum: state.users.pagination?.pageNum,
  pageSize: state.users.pagination?.pageSize,
  sort: state.users.sort,
  totalElements: state.users.pagination?.totalElements,
  loading: state.users.loading,
});
export const getPropsUsersAdd = (state: UsersState) => ({
  pageNum: state.users.paginationAdd?.pageNum,
  pageSize: state.users.paginationAdd?.pageSize,
  sort: state.users.sortAdd,
  totalElements: state.users.paginationAdd?.totalElements,
  loading: state.users.loadingAdd,
});
export const getPropsSubordinates = (state: UsersState) => ({
  pageNum: state.users.paginationSubordinates?.pageNum,
  pageSize: state.users.paginationSubordinates?.pageSize,
  sortSubordinates: state.users.sortSubordinates,
});
export const getSubordinates = (state: UsersState): User[] | undefined =>
  state.users.subordinates;
export const getUserLogin = (state: UsersState) => state?.users.userLogin;
export const getUser = (state: UsersState): User | undefined =>
  state.users.user;
export const getCurrentUserId = (state: UsersState) => state.users.userId;
export const getUsersAdd = (state: UsersState) => state.users.usersAdd;
export const getUsersAddFilter = (state: UsersState) =>
  state.users.usersAddFilter || {};
export const getUsersFilter = (state: UsersState) =>
  state.users.usersFilter || {};
export const getOrgIdByUser = (state: UsersState) => state.users.orgIdUser;
export const getIsExistLogin = (state: UsersState) => state.users.isExistLogin;
export const getIsExistEmail = (state: UsersState) => state.users.isExistEmail;
export const getIsExistPhone = (state: UsersState) => state.users.isExistPhone;
export const getSubordinatesFilter = (state: UsersState) =>
  state.users.subordinatesFilter || {};

const getUsersSelectListMapper = (
  users: User[] | undefined
): SelectOption[] => {
  if (Array.isArray(users)) {
    return users.map(
      (user: User): SelectOption => ({
        title: `${user.firstName} ${user.lastName}`,
        value: user.id,
      })
    );
  }
  return [];
};

export const getUsersSelectList = createSelector<
  UsersState,
  User[] | undefined,
  SelectOption[]
>([getUsers], (users) => getUsersSelectListMapper(users));

export const getUsersClientsSelectList = createSelector<
  UsersState,
  User[] | undefined,
  SelectOption[]
>([getUsersClients], (users) => getUsersSelectListMapper(users));

export const getUsersSpecialistsSelectList = createSelector<
  UsersState,
  User[] | undefined,
  SelectOption[]
>([getUsersSpecialists], (users) => getUsersSelectListMapper(users));

export const getSubordinatesSelectList = createSelector<
  UsersState,
  User[] | undefined,
  SelectOption<string>[]
>([getSubordinates], (subordinates) => getUsersSelectListMapper(subordinates));

export const getSubordinatesWithOutCurrentSelectList = (
  specialistId?: string
) =>
  createSelector<
    UsersState,
    SelectOption[] | undefined,
    SelectOption[] | undefined
  >([getSubordinatesSelectList], (subordinates): SelectOption[] | undefined => {
    if (specialistId) {
      return subordinates?.filter(
        (option: SelectOption) => option.value !== specialistId
      );
    }
    return subordinates || [];
  });
