export const INITIAL_ATTRIBUTES_VALUES = {
  organizations: [],
  systems: [],
  status: [],
  environments: [],
  ticketTypes: [],
  ticketPriorities: [],
  clients: [],
};

export const DEFAULT_VALUES_TRIGGER_FORM = {
  title: '',
  isEnable: false,
  customConditionRequests: [],
  ...INITIAL_ATTRIBUTES_VALUES,
  targetStatus: null,
};

export const INITIAL_TRIGGERS_FILTER = {
  title: '',
  isActive: null,
  ...INITIAL_ATTRIBUTES_VALUES,
};
