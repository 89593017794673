import { FC } from 'react';
import { FieldError, UseFormRegisterReturn } from 'react-hook-form';

import { Status, StatusBlock } from '@entities/ticket';
import {
  ArrowRightLong,
  TextArea,
  Typography,
  TypographyVariants,
} from '@shared';

import styles from './EditStatusModalContent.module.scss';

interface EditStatusModalContentProps {
  currentStatus?: Status;
  nextStatus?: Status | null;
  additionalFormData?: UseFormRegisterReturn<string>;
  error?: FieldError | boolean;
  errorMessage?: string;
}

export const EditStatusModalContent: FC<EditStatusModalContentProps> = ({
  currentStatus,
  nextStatus,
  additionalFormData,
  error,
  errorMessage,
}) => (
  <div className={styles.editStatusModalContent}>
    <div className={styles.editStatusModalContent__header}>
      <StatusBlock
        status={currentStatus?.defaultStatus}
        color={currentStatus?.color}
        label={currentStatus?.name}
      />
      <ArrowRightLong className={styles.editStatusModalContent__arrow} />
      <StatusBlock
        status={nextStatus?.defaultStatus}
        color={nextStatus?.color}
        label={nextStatus?.name}
      />
    </div>
    <Typography
      variant={TypographyVariants.b2}
      className={styles.editStatusModalContent__title}
    >
      Для смены статуса оставьте комментарий:
    </Typography>
    <TextArea
      label="Комментарий"
      {...additionalFormData}
      error={!!error}
      errorMessage={errorMessage}
      className={styles.editStatusModalContent__textArea}
    />
  </div>
);
