import { Reducer } from 'redux';

import { Attachment, AttachmentsFiles } from '@entities/attachment/model/types';
import { ReducerMap } from '@shared';

import {
  Attachments,
  AttachmentsActionsTypes,
  FetchContractAttachmentsSuccessAction,
  FetchTicketAttachmentsSuccessAction,
  FetchTicketCommentsAttachmentsSuccessAction,
} from './actionTypes';

export interface AttachmentsReducerState {
  contractAttachments?: Attachment[];
  ticketAttachments?: Attachment[];
  ticketCommentsAttachments?: AttachmentsFiles[];
  loading?: boolean;
}

const initialState: AttachmentsReducerState = {
  ticketCommentsAttachments: [],
  loading: false,
};

const attachmentsReducerMap: ReducerMap<
  AttachmentsReducerState,
  AttachmentsActionsTypes
> = {
  [Attachments.ATTACHMENTS_LOADING_SHOW]: (state) => ({
    ...state,
    loading: true,
  }),
  [Attachments.ATTACHMENTS_LOADING_HIDE]: (state) => ({
    ...state,
    loading: false,
  }),
  [Attachments.FETCH_TICKET_ATTACHMENTS_SUCCESS]: (state, action) => {
    const { payload } = action as FetchTicketAttachmentsSuccessAction;
    return {
      ...state,
      ticketAttachments: payload,
    };
  },
  [Attachments.FETCH_CONTRACT_ATTACHMENTS_SUCCESS]: (state, action) => {
    const { payload } = action as FetchContractAttachmentsSuccessAction;
    return {
      ...state,
      contractAttachments: payload,
    };
  },
  [Attachments.FETCH_TICKET_COMMENTS_ATTACHMENTS_SUCCESS]: (state, action) => {
    const { payload } = action as FetchTicketCommentsAttachmentsSuccessAction;
    return {
      ...state,
      ticketCommentsAttachments: payload,
    };
  },
  [Attachments.RESET_ATTACHMENTS_STATE]: () => ({
    ...initialState,
  }),
};

export const attachmentsSlice: Reducer<
  AttachmentsReducerState,
  AttachmentsActionsTypes
> = (state = initialState, action) => {
  const reducer = attachmentsReducerMap[action.type];
  if (!reducer) {
    return state;
  }
  return reducer(state, action);
};
