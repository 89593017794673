import cn from 'clsx';
import { FC, useEffect } from 'react';
import { Controller } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

import {
  getIsAdmin,
  getIsClients,
  getUserOrganizationId,
  getUserOrganizationList,
} from '@entities/auth';
import {
  AccessType,
  CategoryArticle,
  fetchAllCategoriesRequestArticleCreate,
  fetchCategoriesRequestArticleCreate,
  fetchOrganizationsRequestArticleCreate,
  fetchSubCategoriesRequestArticleCreate,
  fetchWorkGroupsRequestArticleCreate,
  getArticleCategoriesSelectList,
  getArticleOrganizationsSelectList,
  getArticleWorkGroupsSelectList,
  getCurrentArticleArticleViewing,
  getSubCategoriesSelectListArticleCreate,
} from '@entities/knowledge-base';
import {
  getOrganizationIds,
  SELECT_ACCESS_TYPE_LIST,
} from '@features/knowledge-base';
import {
  convertValueTypeToArrayValue,
  createSelectValue,
  getValueFromValueType,
  Select,
  ValueType,
} from '@shared';

import { useCreateArticleForm } from '../../../lib';

import styles from './KBCreateArticleHeaderForm.module.scss';

interface KBCreateArticleHeaderFormProps {
  className?: string;
  form: ReturnType<typeof useCreateArticleForm>;
}

export const KBCreateArticleHeaderForm: FC<KBCreateArticleHeaderFormProps> = ({
  className,
  form,
}) => {
  const dispatch = useDispatch();

  const { state, methods } = form;
  const { control, isEditMode } = state;
  const { setValue, watch, resetField } = methods;
  const formValues = watch();

  const currentArticleArticleViewing = useSelector(
    getCurrentArticleArticleViewing
  );
  const organizationsOptions = useSelector(getArticleOrganizationsSelectList);
  const workGroupsOptions = useSelector(getArticleWorkGroupsSelectList);
  const categoriesOptions = useSelector(getArticleCategoriesSelectList);
  const subCategories = useSelector(getSubCategoriesSelectListArticleCreate);
  const isAdmin = useSelector(getIsAdmin);
  const isClients = useSelector(getIsClients);

  const userOrgId = useSelector(getUserOrganizationId);
  const userOrganizationList = useSelector(getUserOrganizationList);

  const { state: locationState } = useLocation<{
    category: CategoryArticle;
  }>();
  const categoryLocation = locationState?.category;

  const isPrivate =
    getValueFromValueType(formValues.accessType) === AccessType.PRIVATE;

  const fetchCategoriesByOrgId = (value: ValueType<string>) => {
    const orgIds = convertValueTypeToArrayValue(value);
    if (orgIds) {
      dispatch(fetchCategoriesRequestArticleCreate(orgIds));
    }
  };

  const fetchAllCategories = () => {
    dispatch(fetchAllCategoriesRequestArticleCreate());
  };

  const fetchWorkGroups = (value: ValueType<string>) => {
    const organizationIds = convertValueTypeToArrayValue(value).map((item) =>
      item.toString()
    );
    dispatch(fetchWorkGroupsRequestArticleCreate({ organizationIds }));
  };

  const fetchSubCategories = (value: ValueType<string>) => {
    const id = getValueFromValueType(value);
    if (id) {
      dispatch(fetchSubCategoriesRequestArticleCreate(id));
    }
  };

  useEffect(() => {
    dispatch(fetchOrganizationsRequestArticleCreate());

    if (isEditMode) {
      const organizationIds = getOrganizationIds(
        currentArticleArticleViewing?.organizations
      );
      dispatch(fetchWorkGroupsRequestArticleCreate({ organizationIds }));
      dispatch(fetchCategoriesRequestArticleCreate(organizationIds));
    }
  }, [isEditMode, currentArticleArticleViewing?.organizations]);

  useEffect(() => {
    if (categoryLocation) {
      const parentCategory = createSelectValue(
        categoryLocation.parentTitle,
        categoryLocation.parentId
      );
      setValue(
        parentCategory ? 'category' : 'parentCategory',
        createSelectValue(categoryLocation.title, categoryLocation.id)
      );
      if (parentCategory) {
        fetchSubCategories(parentCategory);
        setValue('parentCategory', parentCategory);
      }
    }
  }, [categoryLocation]);

  useEffect(() => {
    if (!isAdmin) {
      setValue('accessType', SELECT_ACCESS_TYPE_LIST[1]);
    }
    if (!isEditMode && isClients) {
      setValue('organizationIds', userOrganizationList);
      if (userOrgId) {
        dispatch(fetchCategoriesRequestArticleCreate([userOrgId]));
        dispatch(
          fetchWorkGroupsRequestArticleCreate({ organizationIds: [userOrgId] })
        );
      }
    }
  }, [isAdmin, isEditMode, isClients, userOrgId, userOrganizationList]);

  return (
    <form className={cn(styles.KBCreateArticleHeaderForm, className)}>
      <div className={styles.KBCreateArticleHeaderForm__selects}>
        <Controller
          control={control}
          name="accessType"
          key="accessType"
          rules={{
            required: true,
          }}
          render={({ field }) => (
            <Select<AccessType>
              className={styles.KBCreateArticleHeaderForm__select}
              label="Кому отправить"
              mobileModalTitle="кому отправить"
              value={field.value}
              onChange={(value) => {
                const accessTypeValue = getValueFromValueType(value);
                if (accessTypeValue === AccessType.PUBLIC) {
                  fetchAllCategories();
                }

                resetField('organizationIds');
                resetField('workgroupIds');
                resetField('parentCategory');
                resetField('category');
                field.onChange(value);
              }}
              options={SELECT_ACCESS_TYPE_LIST}
              disabled={!isAdmin}
            />
          )}
        />
        {isPrivate && (
          <>
            <Controller
              control={control}
              name="organizationIds"
              key="organizationIds"
              rules={{
                required: isPrivate,
              }}
              render={({ field }) => (
                <Select<string>
                  className={styles.KBCreateArticleHeaderForm__select}
                  label="Организация"
                  mobileModalTitle="организацию"
                  onChange={(value) => {
                    resetField('workgroupIds');
                    resetField('parentCategory');
                    resetField('category');
                    fetchCategoriesByOrgId(value);
                    fetchWorkGroups(value);
                    field.onChange(value);
                  }}
                  value={field.value}
                  options={organizationsOptions}
                  disabled={isClients}
                  isMulti
                />
              )}
            />
            <Controller
              control={control}
              name="workgroupIds"
              key="workgroupIds"
              render={({ field }) => (
                <Select<string>
                  className={styles.KBCreateArticleHeaderForm__select}
                  label="Группы"
                  mobileModalTitle="группы"
                  options={workGroupsOptions}
                  value={field.value}
                  onChange={field.onChange}
                  isMulti
                />
              )}
            />
          </>
        )}

        <Controller
          control={control}
          name="parentCategory"
          key="parentCategory"
          rules={{
            required: isPrivate,
          }}
          render={({ field }) => (
            <Select<string>
              className={styles.KBCreateArticleHeaderForm__select}
              value={field.value}
              onChange={(value) => {
                field.onChange(value);
                resetField('category');
                fetchSubCategories(value);
              }}
              options={categoriesOptions}
              label="Категория"
              mobileModalTitle="категорию"
            />
          )}
        />
        <Controller
          control={control}
          name="category"
          key="category"
          render={({ field }) => (
            <Select<string>
              className={styles.KBCreateArticleHeaderForm__select}
              onChange={field.onChange}
              value={field.value}
              options={subCategories}
              label="Подкатегория"
              disabled={!formValues.parentCategory}
              mobileModalTitle="подкатегорию"
            />
          )}
        />
      </div>
    </form>
  );
};
