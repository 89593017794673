import {
  AssessmentInteraction,
  EvaluateSpecialistAction,
  FetchImprovementsRequestAction,
  FetchImprovementsSuccessAction,
  FetchTicketEvaluationRequestAction,
  FetchTicketEvaluationSuccessAction,
  ResetAssessmentInteractionStateAction,
} from './actionTypes';
import {
  EvaluateSpecialistPayload,
  Improvement,
  TicketEvaluation,
} from './types';

export const fetchImprovementsRequest = (
  payload: number
): FetchImprovementsRequestAction => ({
  type: AssessmentInteraction.FETCH_IMPROVEMENTS_REQUEST,
  payload,
});

export const fetchImprovementsSuccess = (
  payload: Improvement[]
): FetchImprovementsSuccessAction => ({
  type: AssessmentInteraction.FETCH_IMPROVEMENTS_SUCCESS,
  payload,
});

export const resetAssessmentInteractionState =
  (): ResetAssessmentInteractionStateAction => ({
    type: AssessmentInteraction.RESET_ASSESSMENT_INTERACTION_STATE,
  });

export const evaluateSpecialist = (
  payload: EvaluateSpecialistPayload
): EvaluateSpecialistAction => ({
  type: AssessmentInteraction.EVALUATE_SPECIALIST,
  payload,
});

export const fetchTicketEvaluationRequest = (
  payload: string
): FetchTicketEvaluationRequestAction => ({
  type: AssessmentInteraction.FETCH_TICKET_EVALUATION_REQUEST,
  payload,
});

export const fetchTicketEvaluationSuccess = (
  payload: TicketEvaluation
): FetchTicketEvaluationSuccessAction => ({
  type: AssessmentInteraction.FETCH_TICKET_EVALUATION_SUCCESS,
  payload,
});
