import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

import {
  getIsClient,
  getIsSpecialist,
  getUserFirstName,
  getUserId,
  getUserLastName,
  getUserMiddleName,
} from '@entities/auth/model/selectors';

export const useFormDefaultValues = () => {
  const location = useLocation();

  const isClient = useSelector(getIsClient);
  const isPerformer = useSelector(getIsSpecialist);

  const userId = useSelector(getUserId);
  const userFirstName = useSelector(getUserFirstName);
  const userMiddleName = useSelector(getUserMiddleName);
  const userLastName = useSelector(getUserLastName);

  const currentLocation = location.pathname === '/tickets';

  const currentUser = [
    {
      title: `${userLastName} ${userFirstName} ${userMiddleName}`,
      value: userId || '',
    },
  ];

  const defaultClient = isClient && currentLocation ? currentUser : [];
  const defaultPerformer = isPerformer && currentLocation ? currentUser : [];

  return {
    methods: {
      defaultClient,
      defaultPerformer,
    },
  };
};
