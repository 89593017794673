import { useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';

import {
  getIsClient,
  getIsOrgTypeCustomer,
  getUserOrganization,
} from '@entities/auth/model/selectors';
import {
  BottomButtonsBlock,
  checkObjectIdentity,
  getIsMobile,
  getIsMobileSmall,
  Loader,
} from '@shared';

import {
  convertCustomFieldsForUpdate,
  convertCustomFieldsFromArrToObject,
} from '../../lib';
import { updateCustomFieldsByTicketIdRequest } from '../../model/ticket/actions';
import {
  getClientInfo,
  getClientTicketInfoCustomFields,
  getLoading,
  getSpecialistTicketInfoCustomFields,
  getTicketDeleted,
} from '../../model/ticket/selectors';
import { CustomFieldTicket } from '../../model/ticket/types';
import { AbstractDataType } from '../../model/types';
import { TicketCustomField } from '../TicketCustomField';
import { TicketCustomFieldAccordion } from '../TicketCustomFieldAccordion';

import styles from './TicketCustomFieldsInfo.module.scss';

export const TicketCustomFieldsInfo = () => {
  const dispatch = useDispatch();

  const isTicketDeleted = useSelector(getTicketDeleted);
  const isClient = useSelector(getIsClient);
  const specFields = useSelector(getSpecialistTicketInfoCustomFields);
  const clientFields = useSelector(getClientTicketInfoCustomFields);
  const isCustomer = useSelector(getIsOrgTypeCustomer);
  const organization = useSelector(getUserOrganization);
  const clientInfo = useSelector(getClientInfo);
  const loading = useSelector(getLoading);
  const isMobile = useSelector(getIsMobile);
  const isMobileSmall = useSelector(getIsMobileSmall);

  const mainLayoutSticky = document.getElementById('mainLayoutSticky');

  const isMobileAll = isMobile || isMobileSmall;

  const customFields = isClient ? clientFields : specFields;
  const renderCustomFields = isClient
    ? clientFields
    : [...specFields, ...clientFields];

  const initialValuesConverted = useMemo(
    () => convertCustomFieldsFromArrToObject(renderCustomFields),
    [renderCustomFields]
  );
  const showClientFields = !isClient && Boolean(clientFields.length);
  const ticketCustomFieldDisabled =
    !isCustomer || organization?.id !== clientInfo?.organizationId;

  const [isModal, setIsModal] = useState<boolean>(false);

  const openModal = () => {
    setIsModal(true);
  };

  const {
    control,
    setValue,
    watch,
    formState: { isDirty, isValid },
    handleSubmit,
  } = useForm<AbstractDataType>({
    mode: 'onChange',
  });

  const values = watch();

  const valuesIdentity = checkObjectIdentity(initialValuesConverted, values);

  const disabledSubmit = !isDirty || !isValid || valuesIdentity;

  const setInitialValues = () => {
    const initialCustomFields =
      convertCustomFieldsFromArrToObject(renderCustomFields);

    Object.keys(initialCustomFields).forEach((key) => {
      setValue(
        (key as keyof AbstractDataType).toString(),
        initialCustomFields[key.toString()]
      );
    });
  };

  const closeModal = () => {
    setInitialValues();
  };

  const formSubmitHandler = handleSubmit((data) => {
    dispatch(
      updateCustomFieldsByTicketIdRequest(
        convertCustomFieldsForUpdate(data, renderCustomFields)
      )
    );
  });

  useEffect(() => {
    if (valuesIdentity && !isMobileAll) {
      setIsModal(false);
    }
  }, [valuesIdentity, isMobileAll]);

  useEffect(() => {
    setInitialValues();
  }, [clientFields, specFields]);

  if (loading) {
    return <Loader />;
  }

  const customFieldsList = customFields.map((item: CustomFieldTicket) => (
    <TicketCustomField
      customField={item}
      control={control}
      key={item.id}
      onChange={openModal}
      disabled={isTicketDeleted}
    />
  ));

  return (
    <>
      <form className={styles.ticketCustomFields}>
        {customFieldsList}
        <BottomButtonsBlock
          isOpen={isModal}
          onCancel={closeModal}
          onSave={formSubmitHandler}
          parentNode={mainLayoutSticky}
          disabledSubmit={disabledSubmit}
        />
      </form>
      {showClientFields && (
        <TicketCustomFieldAccordion
          control={control}
          onChange={openModal}
          clientFields={clientFields}
          disabled={ticketCustomFieldDisabled}
        />
      )}
    </>
  );
};
