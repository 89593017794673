import { createSelector } from 'reselect';

import {
  createOrganizationsSelectListSelector,
  Organization,
} from '@entities/organizations';
import { getArraySelectOptions, SelectOption } from '@shared';

import { CategoryArticle } from '../types';

import { ArticlesTableState } from './types';

export const getArticlesTable = (state: ArticlesTableState) =>
  state.articlesTable.articles;
export const getArticlesTableLoading = (state: ArticlesTableState) =>
  state.articlesTable.loading;
export const getCurrentArticleTable = (state: ArticlesTableState) =>
  state?.articlesTable.article;
export const getPropsArticlesTable = (state: ArticlesTableState) => ({
  pageNum: state.articlesTable.pagination?.pageNum,
  pageSize: state.articlesTable.pagination?.pageSize,
  sortArticlesTable: state?.articlesTable.sort,
  loadingArticlesTable: getArticlesTableLoading(state),
  totalElements: state.articlesTable.pagination?.totalElements,
});
export const getArticlesTableFilter = (state: ArticlesTableState) =>
  state.articlesTable.articlesFilter || {};
export const getArticlesTableCategory = (state: ArticlesTableState) =>
  state.articlesTable.currentCategory;
export const getOrganizationsArticlesTable = (state: ArticlesTableState) =>
  state.articlesTable.organizations || [];

export const getSubCategoriesSelectListTable = createSelector<
  ArticlesTableState,
  CategoryArticle | undefined,
  SelectOption[]
>([getArticlesTableCategory], (category): SelectOption[] => {
  const subCategories = category?.children;
  return getArraySelectOptions(subCategories);
});

export const getOrganizationsArticleTableSelectList = createSelector<
  ArticlesTableState,
  Organization[],
  SelectOption[]
>([getOrganizationsArticlesTable], (organizations): SelectOption[] =>
  getArraySelectOptions(organizations)
);

export const getArticlesRightTable = (state: ArticlesTableState) =>
  state.articlesTable.articlesRightTable || [];
export const getFilterArticlesRightTable = (state: ArticlesTableState) =>
  state.articlesTable.articlesFilterRightTable || {};
export const getLoadingArticlesRightTable = (state: ArticlesTableState) =>
  state.articlesTable.loadingArticlesRightTable || false;
export const getPaginationArticlesRightTable = (state: ArticlesTableState) =>
  state.articlesTable.articlesPaginationRightTable || {};
export const getOrganizationsArticlesRightTable = (state: ArticlesTableState) =>
  state.articlesTable.organizationsRightTable || [];

export const getCategoriesArticlesRightTable = (state: ArticlesTableState) =>
  state.articlesTable.categoriesRightTable;
export const getLoadingCategoriesArticlesRightTable = (
  state: ArticlesTableState
) => state.articlesTable.categoriesLoadingRightTable || false;
export const getSelectedOrganizationArticlesRightTable = (
  state: ArticlesTableState
) => state.articlesTable.organizationSelected;
export const getSearchValueArticlesRightTable = (state: ArticlesTableState) =>
  state.articlesTable.searchValue;
export const getOrganizationsArticlesRightTableSelectList =
  createOrganizationsSelectListSelector(getOrganizationsArticlesRightTable);

export const getSelectedOrgIdArticlesRightTable = createSelector<
  ArticlesTableState,
  SelectOption | null | undefined,
  string[]
>([getSelectedOrganizationArticlesRightTable], (organization) =>
  organization?.value ? [organization?.value] : []
);
