import { ProfileLayout } from '@features/profile';
import { ProfileSettings } from '@features/profile/ui';

const ProfileSettingsPage = () => (
  <ProfileLayout>
    <ProfileSettings />
  </ProfileLayout>
);

export default ProfileSettingsPage;
