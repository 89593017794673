import cn from 'clsx';
import DOMPurify from 'dompurify';
import { FC } from 'react';

import { Article } from '@entities/knowledge-base';

import styles from './UserAssistanceArticleDetailContent.module.scss';

interface UserAssistanceArticleDetailContentProps {
  article?: Article;
}

export const UserAssistanceArticleDetailContent: FC<
  UserAssistanceArticleDetailContentProps
> = ({ article }) => (
  <div
    className={cn('ck-content', styles.userAssistanceArticleDetailContent)}
    // eslint-disable-next-line react/no-danger
    dangerouslySetInnerHTML={{
      __html: DOMPurify.sanitize(article?.text || ''),
    }}
  />
);
