import { Attributes } from '@entities/rules';
import { getValueFromValueType, ValueType } from '@shared';

interface Args {
  attributesFields: Attributes;
  attribute: ValueType<string>;
}

export const getAttributesValueOptions = ({
  attributesFields,
  attribute,
}: Args) => {
  const optionValue = getValueFromValueType(attribute) || '';
  const fields = attributesFields[optionValue as keyof typeof attributesFields];

  return (
    fields?.map((field) => ({ title: field.value, value: field.key })) || []
  );
};
