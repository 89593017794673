import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

import {
  fetchWorkGroupsByContractBySystemRequestContractDetail,
  getActiveSystemIdContractDetail,
  getGroupsByContractBySystemContractDetail,
  getGroupsFilterByContractBySystemContractDetail,
  getPropsGroupsContractDetail,
  getSystemsTransfer,
  setFilterWorkGroupsByContractBySystemContractDetail,
  setPageWorkGroupsByContractBySystemContractDetail,
  setSizePageWorkGroupsByContractBySystemContractDetail,
  setSortWorkGroupsByContractBySystemContractDetail,
} from '@entities/contract';
import { WorkGroupFilter } from '@entities/work-group';
import { checkObjectIdentity, getChangeButtonProps, RouterHref } from '@shared';

import { useContractId } from './useContractId';

export const useWorkGroupsTableContainer = () => {
  const { pathname } = useLocation();

  const workGroups = useSelector(getGroupsByContractBySystemContractDetail);
  const activeSystemId = useSelector(getActiveSystemIdContractDetail);
  const systemsTransfer = useSelector(getSystemsTransfer);
  const contractIdFromParams = useContractId();
  const contractId = contractIdFromParams || systemsTransfer.contractId;

  const filterValues = useSelector(
    getGroupsFilterByContractBySystemContractDetail
  );

  const { totalElements, pageNum, pageSize, sort, loading } = useSelector(
    getPropsGroupsContractDetail
  );

  const dispatch = useDispatch();

  const [isModal, setIsModal] = useState<boolean>(false);
  const [showFilterRow, setShowFilterRow] = useState(false);

  const isFilterActive = !checkObjectIdentity(filterValues, {});
  const searchDisabled =
    !workGroups?.length || (showFilterRow && isFilterActive);

  const toggleModal = () => setIsModal(!isModal);

  const toggleFilterRow = () => {
    setShowFilterRow((prevState) => !prevState);
  };

  const handleSort = (value: string) => {
    dispatch(setSortWorkGroupsByContractBySystemContractDetail(value));
    dispatch(
      fetchWorkGroupsByContractBySystemRequestContractDetail({
        contractId,
      })
    );
  };

  const prepareCreateTitle = () =>
    getChangeButtonProps(totalElements || 0).createTitle;

  const prepareHandler = () => (contractId ? toggleModal : undefined);

  const prepareDisabled = () =>
    pathname === RouterHref.AdminCreateContract || !activeSystemId;

  const handleChangePage = (page: number) => {
    dispatch(setPageWorkGroupsByContractBySystemContractDetail(page));
    dispatch(
      fetchWorkGroupsByContractBySystemRequestContractDetail({
        contractId,
        systemId: activeSystemId,
      })
    );
  };

  const handleChangePageSize = (arg: number) => {
    dispatch(setSizePageWorkGroupsByContractBySystemContractDetail(arg));
  };

  const handleFilter = (filter: WorkGroupFilter) => {
    dispatch(setPageWorkGroupsByContractBySystemContractDetail(0));
    dispatch(setFilterWorkGroupsByContractBySystemContractDetail(filter));
    dispatch(
      fetchWorkGroupsByContractBySystemRequestContractDetail({
        contractId,
        systemId: activeSystemId,
      })
    );
  };

  return {
    state: {
      searchDisabled,
      prepareCreateTitle,
      prepareDisabled,
      pageNum,
      pageSize,
      sort,
      workGroups,
      showFilterRow,
      isFilterActive,
      isModal,
      totalElements,
      loading,
    },
    methods: {
      toggleFilterRow,
      handleSort,
      prepareHandler,
      handleChangePage,
      handleChangePageSize,
      toggleModal,
      handleFilter,
    },
  };
};
