import { Article, CategoryArticle } from '@entities/knowledge-base';

import { MAX_ARTICLES } from '../../config';

type Args = {
  showFullCategory?: boolean;
  categories: CategoryArticle[];
  articles: Article[];
};

export const getListArticlesAndSubCategories = ({
  showFullCategory,
  categories = [],
  articles = [],
}: Args) => {
  const list: { articlesList: Article[]; categoriesList: CategoryArticle[] } = {
    articlesList: showFullCategory ? articles : articles.slice(0, 5),
    categoriesList: [],
  };

  if (showFullCategory) {
    list.categoriesList = categories;
    return list;
  }
  if (articles.length < MAX_ARTICLES) {
    list.categoriesList = categories.slice(0, 5 - articles.length);
  }
  return list;
};
