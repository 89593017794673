import { FC, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
  getLoading,
  Responsibility,
  ResponsibilityFilter,
  setCurrentResponsibilitiesPage,
  setResponsibilitiesFilter,
  TableResponsibilitiesTypes,
} from '@entities/responsibilities';
import { Table } from '@shared';

import { RESPONSIBILITIES_FIELDS } from '../../config';
import { fetchFunction } from '../../lib';
import { ResponsibilitiesFilter } from '../ResponsibilitiesFilter';

import { getDataTable } from './utils';

interface ResponsibilitiesShortTableProps {
  responsibilities?: Responsibility[];
  sort?: string;
  handleSort?: (value: string) => void;
  handleRowClick?: (value: string) => void;
  activeRowId?: string;
  showFilterRow?: boolean;
  isFilterActive?: boolean;
  tableType: TableResponsibilitiesTypes;
}

export const ResponsibilitiesShortTable: FC<
  ResponsibilitiesShortTableProps
> = ({
  responsibilities,
  sort,
  handleSort,
  handleRowClick,
  activeRowId,
  showFilterRow,
  isFilterActive,
  tableType,
}) => {
  const dispatch = useDispatch();

  const loading = useSelector(getLoading);

  const data = getDataTable(responsibilities);

  const handleFilter = useCallback(
    (filter: ResponsibilityFilter) => {
      dispatch(setResponsibilitiesFilter(filter));
      dispatch(setCurrentResponsibilitiesPage(0));
      dispatch(fetchFunction[tableType]());
    },
    [dispatch]
  );

  return (
    <Table
      data={data}
      columns={RESPONSIBILITIES_FIELDS}
      sort={sort}
      onRowClick={handleRowClick}
      onSort={handleSort}
      activeRowId={activeRowId}
      dataLoading={loading}
      isFilterActive={isFilterActive}
      valueFieldName="id"
      filterComponent={
        showFilterRow && (
          <ResponsibilitiesFilter
            tableType={TableResponsibilitiesTypes.ADD_MODAL}
            onFilter={handleFilter}
          />
        )
      }
      disabledSort={loading}
    />
  );
};
