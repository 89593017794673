import { call, put, select, StrictEffect, takeEvery } from 'redux-saga/effects';

import { Attachment, ParentType } from '@entities/attachment/model/types';
import { getWorkGroupsIds } from '@entities/auth/model/selectors';
import { CustomField } from '@entities/custom-fields/model/types';
import { Environment } from '@entities/environment/model/types';
import { attachmentRequests } from '@entities/ticket/api/attachment';
import { loadingTagsHideRequest } from '@entities/ticket/model/tags';
import { Ticket, TicketType } from '@entities/ticket/model/types';
import {
  createError,
  createErrorAlert,
  createSuccessAlert,
  ResponseWithMeta,
  RouterHref,
  setAlert,
  setRedirectPath,
} from '@shared';

import { requests } from '../api';

import {
  fetchEnvironmentsSuccess,
  fetchRequiredCustomFieldsSuccess,
  fetchTicketsSuccessJoin,
  fetchTicketsSuccessUpdate,
  fetchTicketTypesSuccess,
  hideCreateLoading,
  hideEnvironmentsLoading,
  loadingTicketsHide,
  loadingTicketsShow,
  showCreateLoading,
  showEnvironmentsLoading,
} from './actions';
import {
  CreateTicket,
  CreateTicketAction,
  FetchEnvironmentsRequestAction,
  FetchRequiredCustomFieldsRequestAction,
  FetchTicketsRequestAction,
  FetchTicketTypesRequestAction,
} from './actionTypes';
import {
  getFilterTickets,
  getPropsEnvironments,
  getPropsTickets,
} from './selectors';
import { TicketsFilter } from './types';

function* ticketCreate({
  payload: { ticket, attachments },
}: CreateTicketAction) {
  try {
    const { systemName, ...ticketParams } = ticket;
    yield put(showCreateLoading());
    const createdTicket: Ticket = yield call(
      requests.createTicket,
      ticketParams
    );
    yield put(
      setAlert(
        createSuccessAlert(
          `Новый тикет по системе "${systemName}" успешно создан`
        )
      )
    );
    if (attachments?.has('file') && !attachments.entries().next().done) {
      const attachmentData: Omit<Attachment, 'id' | 'attachmentsFiles'> = {
        parentId: createdTicket.id,
        parentType: ParentType.TICKET,
        ticketId: createdTicket.id,
        systemFile: 'test',
      };

      attachments.append(
        'objects-attachments',
        new Blob([JSON.stringify(attachmentData)], {
          type: 'application/json',
        })
      );
      yield call(attachmentRequests.addAttachment, attachments);
    }
    yield put(hideCreateLoading());
    yield put(setRedirectPath(RouterHref.Tickets));
  } catch (e) {
    yield put(
      setAlert(
        createErrorAlert(
          `Произошла ошибка при создании тикета по системе "${ticket.systemName}"`
        )
      )
    );
    yield put(hideCreateLoading());
    createError(e);
  }
}

function* environmentsFetch({ payload }: FetchEnvironmentsRequestAction) {
  const { systemId } = payload;
  try {
    const { pageNum, pageSize, sort }: ReturnType<typeof getPropsEnvironments> =
      yield select(getPropsEnvironments);
    yield put(showEnvironmentsLoading());
    const environments: ResponseWithMeta<Environment[]> = yield call(
      requests.fetchEnvironments,
      pageNum,
      pageSize,
      sort,
      { systemId, isActive: true }
    );
    yield put(fetchEnvironmentsSuccess(environments));
    yield put(hideEnvironmentsLoading());
  } catch (e) {
    createError(e);
    yield put(hideEnvironmentsLoading());
  }
}

function* ticketTypesFetch({ payload }: FetchTicketTypesRequestAction) {
  try {
    const ticketTypes: TicketType[] = yield call(
      requests.fetchTicketTypes,
      payload
    );
    yield put(fetchTicketTypesSuccess(ticketTypes));
  } catch (e) {
    createError(e);
  }
}

function* ticketsFetch({ payload }: FetchTicketsRequestAction) {
  try {
    yield put(loadingTicketsShow());
    const { pageNum, pageSize }: ReturnType<typeof getPropsTickets> =
      yield select(getPropsTickets);
    const filter: TicketsFilter = yield select(getFilterTickets);
    const workGroupsIds: string[] = yield select(getWorkGroupsIds);
    const tickets: ResponseWithMeta<Ticket[]> = yield call(
      requests.fetchTickets,
      {
        pageNum,
        pageSize,
        filter,
        workGroupIds: workGroupsIds,
      }
    );
    yield put(
      payload === 'join'
        ? fetchTicketsSuccessJoin(tickets)
        : fetchTicketsSuccessUpdate(tickets)
    );
    yield put(loadingTicketsHide());
  } catch (e) {
    createError(e);
    yield put(loadingTagsHideRequest());
  }
}

function* fetchRequiredCustomFields({
  payload,
}: FetchRequiredCustomFieldsRequestAction) {
  try {
    const customField: CustomField[] = yield call(
      requests.fetchRequiredCustomField,
      payload
    );
    yield put(fetchRequiredCustomFieldsSuccess(customField));
  } catch (e) {
    createError(e);
  }
}

export function* createTicketSaga(): Generator<StrictEffect> {
  yield takeEvery(CreateTicket.FETCH_ENVIRONMENTS_REQUEST, environmentsFetch);
  yield takeEvery(CreateTicket.CREATE_TICKET, ticketCreate);
  yield takeEvery(
    CreateTicket.FETCH_CREATE_TICKET_TYPES_REQUEST,
    ticketTypesFetch
  );
  yield takeEvery(CreateTicket.FETCH_TICKETS_REQUEST, ticketsFetch);
  yield takeEvery(
    CreateTicket.FETCH_REQUIRED_CUSTOM_FIELDS_REQUEST,
    fetchRequiredCustomFields
  );
}
