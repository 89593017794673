import { ApiClient, getEnv } from '@shared';

import { KpiDataRequest } from '../../model';

import { endpointsKpi } from './endpoints';

export const requestsKpi = {
  getKpiData: (data: KpiDataRequest) =>
    ApiClient.post({
      baseURL: getEnv('REACT_APP_KPI_URL'),
      url: endpointsKpi.getKpiData(),
      data,
    }),
};
