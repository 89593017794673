import { getUrl } from '@shared';

export const endpoints = {
  getRoles: () => getUrl('role/filter'),
  getRolesByUserId: (id: string) => getUrl(`role/user/${id}`),
  createRole: () => getUrl('role'),
  getUserRolesByGroupId: (groupId: string, userId: string) =>
    getUrl(`role/work-group/${groupId}/user/${userId}`),
  getRolesByGroupId: (groupId?: string) => getUrl(`role/work-group/${groupId}`),
  getRoleById: (id: string) => getUrl(`role/${id}`),
  getCheckIsExist: (title: string) => getUrl(`role/exists-title/${title}`),
  deleteRole: (id: string) => getUrl(`role/${id}`),
};
