import cn from 'clsx';
import { FC, memo } from 'react';
import { useSelector } from 'react-redux';

import { ActionList, getIsAccessedAction } from '@entities/auth';
import { getNotificationsProfile } from '@entities/profile';
import { MainLayout } from '@shared';

import { ProfileUser } from '../../ui/ProfileUser';

import styles from './ProfileLayout.module.scss';

export const ProfileLayout: FC = memo(({ children }) => {
  const notifications = useSelector(getNotificationsProfile);
  const isAccessToCreateTicket = useSelector(
    getIsAccessedAction(ActionList.CreateTicket)
  );

  return (
    <MainLayout
      title="Профиль"
      withHeader={false}
      classNameRoot={cn(styles.profileLayout, {
        [styles.profileLayout_noData]: !notifications.length,
      })}
      accessToCreateTicket={isAccessToCreateTicket}
    >
      <div className={styles.profileLayout__wrapper}>
        <ProfileUser className={styles.profileLayout__user} />
        {children}
      </div>
    </MainLayout>
  );
});
