import { useState } from 'react';

import { createError } from '@shared';

import { requests } from '../../api';

interface UseOrganizationDeleteProps {
  orgId?: string;
  funcToShowModal: (show: boolean) => void;
}
export const useOrganizationDelete = ({
  orgId,
  funcToShowModal,
}: UseOrganizationDeleteProps) => {
  const [canDeleteOrg, setCanDeleteOrg] = useState(false);

  const handleShowModalDelete = async () => {
    try {
      if (orgId) {
        const result = await requests.hasActiveContract(orgId);
        setCanDeleteOrg(!result);
        funcToShowModal(true);
      }
    } catch (e) {
      createError(e);
    }
  };
  return { canDeleteOrg, handleShowModalDelete };
};
