import { UserLayout } from '@features/user';
import { User } from '@widgets/user';

import styles from './UserPage.module.scss';

const UserPage = () => (
  <UserLayout>
    <User className={styles.userPage__content} />
  </UserLayout>
);

export default UserPage;
