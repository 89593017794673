import { Reducer } from 'redux';

import { WorkGroup, WorkGroupFilter } from '@entities/work-group/model/types';
import { DEFAULT_PAGINATION_PARAMS, PaginationType, ReducerMap } from '@shared';

import {
  FetchCurrentWorkGroupSuccessAction,
  FetchWorkGroupsAddSuccessAction,
  FetchWorkGroupsFromMyOrgSuccessAction,
  FetchWorkGroupsSuccessAction,
  SetCurrentGroupsPageAction,
  SetGroupsAddPageAction,
  SetGroupTitleAction,
  SetSizePageAction,
  SetSizePageGroupsAddAction,
  SetSortGroupsAction,
  SetWorkGroupAddFilterAction,
  SetWorkGroupFilterAction,
  SetWorkGroupIdAction,
  WorkGroups,
  WorkGroupsActionsTypes,
} from './actionTypes';

export interface WorkGroupsReducerState {
  workGroups?: WorkGroup[];
  workGroup?: WorkGroup;
  loading?: boolean;
  loadingAdd?: boolean;
  loadingWorkGroup?: boolean;
  workGroupId?: string;
  userId?: string;
  pagination?: PaginationType;
  paginationAdd?: PaginationType;
  sort?: string;
  workGroupTitle?: string;
  workGroupFilter?: WorkGroupFilter;
  workGroupsAdd?: WorkGroup[];
  workGroupsAddFilter?: WorkGroupFilter;
  workGroupsFromMyOrg?: WorkGroup[];
}

const initialState: WorkGroupsReducerState = {
  workGroups: [],
  loading: false,
  loadingAdd: false,
  loadingWorkGroup: false,
  workGroup: undefined,
  pagination: DEFAULT_PAGINATION_PARAMS,
  paginationAdd: DEFAULT_PAGINATION_PARAMS,
  sort: 'date_create_DESC',
  workGroupFilter: {},
  workGroupsAddFilter: {},
  workGroupsFromMyOrg: [],
};

const workGroupsReducerMap: ReducerMap<
  WorkGroupsReducerState,
  WorkGroupsActionsTypes
> = {
  [WorkGroups.WORK_GROUPS_LOADING_SHOW]: (state) => ({
    ...state,
    loading: true,
  }),
  [WorkGroups.WORK_GROUPS_LOADING_HIDE]: (state) => ({
    ...state,
    loading: false,
  }),
  [WorkGroups.WORK_GROUP_LOADING_SHOW]: (state) => ({
    ...state,
    loadingWorkGroup: true,
  }),
  [WorkGroups.WORK_GROUP_LOADING_HIDE]: (state) => ({
    ...state,
    loadingWorkGroup: false,
  }),
  [WorkGroups.WORK_GROUPS_ADD_LOADING_SHOW]: (state) => ({
    ...state,
    loadingAdd: true,
  }),
  [WorkGroups.WORK_GROUPS_ADD_LOADING_HIDE]: (state) => ({
    ...state,
    loadingAdd: false,
  }),
  [WorkGroups.FETCH_WORK_GROUPS_SUCCESS]: (state, action) => {
    const { payload } = action as FetchWorkGroupsSuccessAction;
    const { content, page, totalElements } = payload;
    return {
      ...state,
      workGroups: content,
      pagination: {
        ...state?.pagination,
        pageNum: page,
        totalElements,
      },
    };
  },
  [WorkGroups.SET_CURRENT_GROUPS_PAGE]: (state, action) => {
    const { payload } = action as SetCurrentGroupsPageAction;
    return {
      ...state,
      pagination: {
        ...state?.pagination,
        pageNum: payload,
      },
    };
  },
  [WorkGroups.SET_SIZE_PAGE_WORK_GROUPS]: (state, action) => {
    const { payload } = action as SetSizePageAction;
    return {
      ...state,
      pagination: {
        ...state?.pagination,
        pageSize: payload,
      },
    };
  },
  [WorkGroups.SET_GROUPS_ADD_PAGE]: (state, action) => {
    const { payload } = action as SetGroupsAddPageAction;
    return {
      ...state,
      paginationAdd: {
        ...state?.paginationAdd,
        pageNum: payload,
      },
    };
  },
  [WorkGroups.SET_SIZE_PAGE_GROUPS_ADD]: (state, action) => {
    const { payload } = action as SetSizePageGroupsAddAction;
    return {
      ...state,
      paginationAdd: {
        ...state?.paginationAdd,
        pageSize: payload,
      },
    };
  },
  [WorkGroups.SET_SORT_GROUPS]: (state, action) => {
    const { payload } = action as SetSortGroupsAction;
    return {
      ...state,
      sort: payload,
    };
  },
  [WorkGroups.RESET_WORK_GROUPS_STATE]: () => ({
    ...initialState,
  }),
  [WorkGroups.FETCH_WORK_GROUP_SUCCESS]: (state, action) => {
    const { payload } = action as FetchCurrentWorkGroupSuccessAction;
    return {
      ...state,
      workGroup: payload,
    };
  },
  [WorkGroups.SET_WORK_GROUP_ID]: (state, action) => {
    const { payload } = action as SetWorkGroupIdAction;
    return {
      ...state,
      workGroupId: payload,
    };
  },
  [WorkGroups.SET_WORK_GROUP_TITLE]: (state, action) => {
    const { payload } = action as SetGroupTitleAction;
    return {
      ...state,
      workGroupTitle: payload,
    };
  },
  [WorkGroups.SET_WORK_GROUP_FILTER]: (state, action) => {
    const { payload } = action as SetWorkGroupFilterAction;
    return {
      ...state,
      workGroupFilter: payload,
    };
  },
  [WorkGroups.SET_WORK_GROUP_ADD_FILTER]: (state, action) => {
    const { payload } = action as SetWorkGroupAddFilterAction;
    return {
      ...state,
      workGroupsAddFilter: payload,
    };
  },
  [WorkGroups.FETCH_WORK_GROUPS_BY_ORG_ID_BY_SYSTEM_ID_ADD_SUCCESS]: (
    state,
    action
  ) => {
    const { payload } = action as FetchWorkGroupsAddSuccessAction;
    const { content, page, totalElements } = payload;
    return {
      ...state,
      workGroupsAdd: content,
      paginationAdd: {
        ...state?.paginationAdd,
        pageNum: page,
        totalElements,
      },
    };
  },
  [WorkGroups.RESET_WORK_GROUPS_ADD_STATE]: (state) => {
    const { workGroupsAdd, workGroupsAddFilter } = initialState;
    return {
      ...state,
      workGroupsAdd,
      workGroupsAddFilter,
    };
  },
  [WorkGroups.FETCH_WORK_GROUPS_FROM_MY_ORG_SUCCESS]: (state, action) => {
    const { payload } = action as FetchWorkGroupsFromMyOrgSuccessAction;
    return {
      ...state,
      workGroupsFromMyOrg: payload,
    };
  },
  [WorkGroups.RESET_CURRENT_WORK_GROUP]: (state) => ({
    ...state,
    workGroup: initialState.workGroup,
    workGroupId: initialState.workGroupId,
    pagination: initialState.pagination,
  }),
};

export const workGroupsSlice: Reducer<
  WorkGroupsReducerState,
  WorkGroupsActionsTypes
> = (state = initialState, action) => {
  const reducer = workGroupsReducerMap[action.type];
  if (!reducer) {
    return state;
  }
  return reducer(state, action);
};
