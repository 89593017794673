import { CustomFieldToRequest } from '@entities/custom-fields/model/types';
import { CustomFieldListOption } from '@shared';

import { Priority, Status, Ticket } from '../types';

import { TicketReducerState } from './slice';

export type TicketState = {
  ticket: TicketReducerState;
};

export enum HistoryType {
  CHANGE_STATUS = 'Изменение статуса',
  CREATE = 'Тикет создан',
  APPOINT = 'Назначен исполнитель',
}

export enum MessageType {
  CREATE = 'Тикет создан',
  APPOINT = 'Назначен исполнитель',
  IN_WORK = 'В работе',
  WAITING_TO_CLOSE = 'В ожидании закрытия',
  WAITING_INFO = 'В ожидании информации',
  CLOSED = 'Закрыт',
  REOPEN = 'Переоткрыт',
}

export type TicketHistory = {
  id: string;
  ticketId: string;
  date: string;
  message: MessageType;
  type: HistoryType;
  personTriggerId: string;
  personTriggeredName: string;
  statusInfo: Status;
};

export type CheckExistIntegration = {
  systemId: string;
  typeId: string;
};

export interface CloseTicketRequest {
  id?: string;
  reasonClosing?: string;
}

export type CustomFieldTicket = CustomFieldListOption<CustomFieldToRequest>;

export type NextStatusesRequest = {
  currentStatusId: string;
  ticketCreatorId: string;
};

export interface ReopenTicket {
  ticketId: string;
  reasonReopen?: string;
}

export type TicketUpdateType = Omit<
  Partial<Ticket>,
  'environmentId' | 'systemId' | 'tags'
> & {
  environmentId: string;
  systemId: string;
  tags: string[];
};

export interface FetchTicketRequestPayload {
  ticketId: string;
  withJira?: boolean;
  withHistory?: boolean;
  withNextStatuses?: boolean;
}

export interface AddSpecAndSetToWorkPayload {
  ticketId: string[];
  specialistId?: string;
  withTicketsUpdate?: boolean;
}

export interface EditTicketPriorityPayload {
  ticketId: string;
  priority: Priority;
}
