import { call } from 'redux-saga/effects';

import { createError } from '@shared';

import { requests } from '../api';

import { Article, ArticleCreateData, CategoryArticle } from './types';

export function* createCategory(data: Partial<CategoryArticle>) {
  try {
    yield call(requests.createCategoryRequest, data);
  } catch (e) {
    createError(e);
  }
}

export function* editCategory(data: Partial<CategoryArticle>) {
  try {
    yield call(requests.editCategoryRequest, data);
  } catch (e) {
    createError(e);
  }
}

export function* deleteCategory(id: string) {
  try {
    yield call(requests.deleteCategoryRequest, id);
  } catch (e) {
    createError(e);
  }
}

export function* articleCreate(data: ArticleCreateData) {
  let article: Article | undefined;
  try {
    article = yield call(requests.createArticleRequest, data);
  } catch (e) {
    createError(e);
  }
  return article;
}

export function* editArticle(data: Partial<Article>) {
  try {
    yield call(requests.editArticleRequest, data);
  } catch (e) {
    createError(e);
  }
}

export function* deleteArticle(id: string) {
  try {
    yield call(requests.deleteArticleRequest, id);
  } catch (e) {
    createError(e);
  }
}

export function* fetchCategory(id: string) {
  let category: CategoryArticle | undefined;
  try {
    category = yield call(requests.fetchCategoryRequest, id);
  } catch (e) {
    createError(e);
  }
  return category;
}

export function* fetchArticle(id: string) {
  let article: Article | undefined;
  try {
    article = yield call(requests.fetchArticleRequest, id);
  } catch (e) {
    createError(e);
  }
  return article;
}
