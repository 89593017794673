import { Reducer } from 'redux';

import { DEFAULT_PAGINATION_PARAMS, PaginationType, ReducerMap } from '@shared';

import {
  FetchResponsibilitiesSuccessAction,
  FetchResponsibilityByIdSuccessAction,
  Responsibilities,
  ResponsibilitiesActionsTypes,
  SetCurrentResponsibilitiesPageAction,
  SetCurrentResponsibilityIdAction,
  SetResponsibilitiesAddFilterAction,
  SetResponsibilitiesAddPageAction,
  SetResponsibilitiesFilterAction,
  SetSizePageAction,
  SetSizePageResponsibilitiesAddAction,
  SetSortResponsibilitiesAction,
} from './actionTypes';
import { Responsibility, ResponsibilityFilter } from './types';

export interface ResponsibilityReducerState {
  responsibilities?: Responsibility[];
  loading?: boolean;
  loadingAdd?: boolean;
  pagination?: PaginationType;
  paginationAdd?: PaginationType;
  sort?: string;
  responsibilityId?: string;
  responsibilitiesAdd?: Responsibility[];
  currentResponsibility?: Responsibility;
  responsibilitiesAddFilter?: ResponsibilityFilter;
  responsibilitiesFilter?: ResponsibilityFilter;
}

const initialState: ResponsibilityReducerState = {
  responsibilities: [],
  loading: false,
  pagination: DEFAULT_PAGINATION_PARAMS,
  paginationAdd: DEFAULT_PAGINATION_PARAMS,
  sort: 'date_create_DESC',
  responsibilitiesFilter: {},
  responsibilitiesAddFilter: {},
};

const responsibilityReducerMap: ReducerMap<
  ResponsibilityReducerState,
  ResponsibilitiesActionsTypes
> = {
  [Responsibilities.RESPONSIBILITIES_LOADING_SHOW]: (state) => ({
    ...state,
    loading: true,
  }),
  [Responsibilities.RESPONSIBILITIES_LOADING_HIDE]: (state) => ({
    ...state,
    loading: false,
  }),
  [Responsibilities.RESPONSIBILITIES_ADD_LOADING_SHOW]: (state) => ({
    ...state,
    loadingAdd: true,
  }),
  [Responsibilities.RESPONSIBILITIES_ADD_LOADING_HIDE]: (state) => ({
    ...state,
    loadingAdd: false,
  }),
  [Responsibilities.FETCH_RESPONSIBILITIES_SUCCESS]: (state, action) => {
    const { payload } = action as FetchResponsibilitiesSuccessAction;
    const { content, page, totalElements } = payload;
    return {
      ...state,
      responsibilities: content,
      pagination: {
        ...state?.pagination,
        pageNum: page,
        totalElements,
      },
    };
  },
  [Responsibilities.SET_CURRENT_RESPONSIBILITIES_PAGE]: (state, action) => {
    const { payload } = action as SetCurrentResponsibilitiesPageAction;
    return {
      ...state,
      pagination: {
        ...state?.pagination,
        pageNum: payload,
      },
    };
  },
  [Responsibilities.SET_SIZE_PAGE_RESPONSIBILITIES]: (state, action) => {
    const { payload } = action as SetSizePageAction;
    return {
      ...state,
      pagination: {
        ...state?.pagination,
        pageSize: payload,
      },
    };
  },
  [Responsibilities.SET_RESPONSIBILITIES_ADD_PAGE]: (state, action) => {
    const { payload } = action as SetResponsibilitiesAddPageAction;
    return {
      ...state,
      paginationAdd: {
        ...state?.paginationAdd,
        pageNum: payload,
      },
    };
  },
  [Responsibilities.SET_SIZE_PAGE_RESPONSIBILITIES_ADD]: (state, action) => {
    const { payload } = action as SetSizePageResponsibilitiesAddAction;
    return {
      ...state,
      paginationAdd: {
        ...state?.paginationAdd,
        pageSize: payload,
      },
    };
  },
  [Responsibilities.SET_SORT_RESPONSIBILITIES]: (state, action) => {
    const { payload } = action as SetSortResponsibilitiesAction;
    return {
      ...state,
      sort: payload,
    };
  },
  [Responsibilities.SET_CURRENT_RESPONSIBILITY_ID]: (state, action) => {
    const { payload } = action as SetCurrentResponsibilityIdAction;
    return {
      ...state,
      responsibilityId: payload,
    };
  },
  [Responsibilities.RESET_RESPONSIBILITIES_STATE]: () => ({
    ...initialState,
  }),
  [Responsibilities.SET_RESPONSIBILITIES_ADD_FILTER]: (state, action) => {
    const { payload } = action as SetResponsibilitiesAddFilterAction;
    return {
      ...state,
      responsibilitiesAddFilter: payload,
    };
  },
  [Responsibilities.SET_RESPONSIBILITIES_FILTER]: (state, action) => {
    const { payload } = action as SetResponsibilitiesFilterAction;
    return {
      ...state,
      responsibilitiesFilter: payload,
    };
  },
  [Responsibilities.FETCH_RESPONSIBILITIES_ADD_SUCCESS]: (state, action) => {
    const { payload } = action as FetchResponsibilitiesSuccessAction;
    const { content, page, totalElements } = payload;
    return {
      ...state,
      responsibilitiesAdd: content,
      paginationAdd: {
        ...state?.paginationAdd,
        pageNum: page,
        totalElements,
      },
    };
  },
  [Responsibilities.RESET_RESPONSIBILITIES_ADD_STATE]: (state) => {
    const { responsibilitiesAdd, responsibilitiesAddFilter, paginationAdd } =
      initialState;
    return {
      ...state,
      responsibilitiesAdd,
      responsibilitiesAddFilter,
      paginationAdd,
    };
  },
  [Responsibilities.FETCH_RESPONSIBILITY_BY_ID_SUCCESS]: (state, action) => {
    const { payload } = action as FetchResponsibilityByIdSuccessAction;
    return {
      ...state,
      currentResponsibility: payload,
    };
  },
  [Responsibilities.RESET_CURRENT_RESPONSIBILITY]: (state) => ({
    ...state,
    currentResponsibility: initialState.currentResponsibility,
    responsibilityId: initialState.responsibilityId,
    pagination: initialState.pagination,
  }),
};

export const responsibilitiesSlice: Reducer<
  ResponsibilityReducerState,
  ResponsibilitiesActionsTypes
> = (state = initialState, action) => {
  const reducer = responsibilityReducerMap[action.type];
  if (!reducer) {
    return state;
  }
  return reducer(state, action);
};
