import { Attachment, ParentType } from '@entities/attachment/model/types';
import { getIsImage } from '@shared';

export const getAttachmentFilesInfo = (
  ticketAttachments: Attachment[] | undefined
) => {
  const ticketAttachment = ticketAttachments?.find(
    (attachment) => attachment.parentType === ParentType.TICKET
  );

  if (!ticketAttachment?.attachmentsFiles || !ticketAttachments?.length) {
    return { imageFiles: [], documentFiles: [] };
  }

  const imageFiles = ticketAttachment.attachmentsFiles.filter((item) =>
    getIsImage(item.path)
  );

  const documentFiles = ticketAttachment.attachmentsFiles.filter(
    (item) => !getIsImage(item.path)
  );

  return { imageFiles, documentFiles };
};
