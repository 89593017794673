import cn from 'clsx';
import { FC } from 'react';
import { Controller } from 'react-hook-form';

import { Select, ToggleSwitch, Typography, TypographyVariants } from '@shared';

import { useWorkGroupsNotification } from '../../lib/hooks';

import styles from './GroupSpecialistForm.module.scss';

interface GroupSpecialistFormProps {
  className?: string;
}

export const GroupSpecialistForm: FC<GroupSpecialistFormProps> = ({
  className,
}) => {
  const { state, methods } = useWorkGroupsNotification();
  const { workGroupsOptions, withNotification } = state;
  const { control, updateNotifications, handleChangeWorkGroups } = methods;

  const workGroupsSelect = withNotification && (
    <>
      <Typography
        variant={TypographyVariants.b3}
        className={styles.groupSpecialistForm__subTitle}
      >
        Вы будете получать уведомления рабочих групп указанных ниже
      </Typography>
      <Controller
        control={control}
        name="workGroupsIds"
        render={({ field }) => (
          <Select<string>
            options={workGroupsOptions}
            value={field.value}
            onChange={(event) => {
              field.onChange(event);
              handleChangeWorkGroups(event);
            }}
            mobileModalTitle="рабочие группы"
            isMulti
            isChip
            label="Рабочие группы"
            dropdownContentClassName={styles.groupSpecialistForm__selectMenu}
          />
        )}
      />
    </>
  );

  return (
    <div className={cn(styles.groupSpecialistForm, className)}>
      <div className={styles.groupSpecialistForm__switchWrapper}>
        <Controller
          control={control}
          name="withNotification"
          render={({ field }) => (
            <ToggleSwitch
              label="Получать уведомления рабочих групп"
              checked={field.value}
              onChange={(event) => {
                field.onChange(event);
                updateNotifications(event.target.checked);
              }}
              classNameLabel={styles.groupSpecialistForm__switchText}
            />
          )}
        />
      </div>
      {workGroupsSelect}
    </div>
  );
};
