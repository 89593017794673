import { ComponentType, ReactPropTypes } from 'react';
import { Provider } from 'react-redux';

import store from '../model';

export const withRedux =
  (Component: ComponentType<ReactPropTypes>) => (props: ReactPropTypes) =>
    (
      <Provider store={store}>
        <Component {...props} />
      </Provider>
    );
