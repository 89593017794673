import { useSelector } from 'react-redux';

import { ActionList, getIsAccessedAction } from '@entities/auth';
import { MainLayout, NotFound } from '@shared';

const NotFoundPage = () => {
  const isAccessToCreateTicket = useSelector(
    getIsAccessedAction(ActionList.CreateTicket)
  );

  return (
    <MainLayout
      withHeader={false}
      accessToCreateTicket={isAccessToCreateTicket}
    >
      <NotFound />
    </MainLayout>
  );
};

export default NotFoundPage;
