import { ResponseWithMeta } from '@shared';

import {
  AccessesCategories,
  CreateAccessCategoryAction,
  FetchAccessesByRespIdAction,
  FetchAccessesRequestAction,
  FetchAccessesSuccessAction,
  HideAccessesLoadingAction,
  ResetAccessesStateAction,
  SetAccessesFilterAction,
  SetCurrentAccessesPageAction,
  SetSizePageAction,
  SetSortAccessesAction,
  ShowAccessesLoadingAction,
} from './actionTypes';
import { Access, AccessesFilter } from './types';

export const showAccessesLoading = (): ShowAccessesLoadingAction => ({
  type: AccessesCategories.ACCESSES_LOADING_SHOW,
});

export const hideAccessesLoading = (): HideAccessesLoadingAction => ({
  type: AccessesCategories.ACCESSES_LOADING_HIDE,
});

export const fetchAccessesRequest = (): FetchAccessesRequestAction => ({
  type: AccessesCategories.FETCH_ACCESSES_REQUEST,
});

export const fetchAccessesSuccess = (
  data: ResponseWithMeta<Access[]>
): FetchAccessesSuccessAction => ({
  type: AccessesCategories.FETCH_ACCESSES_SUCCESS,
  payload: data,
});

export const fetchAccessesByRespIdRequest =
  (): FetchAccessesByRespIdAction => ({
    type: AccessesCategories.FETCH_ACCESS_BY_RESP_ID_REQUEST,
  });

export const resetAccessesState = (): ResetAccessesStateAction => ({
  type: AccessesCategories.RESET_ACCESSES_STATE,
});

export const createAccessCategory = (
  data: Access
): CreateAccessCategoryAction => ({
  type: AccessesCategories.ACCESS_CATEGORY_CREATE,
  payload: data,
});

export const setAccessesFilter = (
  data: AccessesFilter
): SetAccessesFilterAction => ({
  type: AccessesCategories.SET_ACCESSES_FILTER,
  payload: data,
});

export const setCurrentAccessesPage = (
  page: number
): SetCurrentAccessesPageAction => ({
  type: AccessesCategories.SET_CURRENT_ACCESSES_PAGE,
  payload: page,
});

export const setSizePage = (size: number): SetSizePageAction => ({
  type: AccessesCategories.SET_SIZE_PAGE_ACCESSES,
  payload: size,
});

export const setSortAccesses = (sort: string): SetSortAccessesAction => ({
  type: AccessesCategories.SET_SORT_ACCESSES,
  payload: sort,
});
