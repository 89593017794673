import Highcharts, { SeriesPieOptions } from 'highcharts';

import { graphOptions } from '../../config';

export const getOptions = (
  data?: SeriesPieOptions['data'],
  isPie?: boolean
): Highcharts.Options => {
  if (isPie) {
    return {
      ...graphOptions,
      series: [
        {
          name: 'Тикеты',
          colorByPoint: true,
          type: 'pie',
          data,
        },
      ],
      accessibility: {
        enabled: false,
      },
    };
  }
  return {
    ...graphOptions,
    series: [
      {
        name: 'Тикеты',
        showInLegend: false,
        type: 'column',
        data,
      },
    ],
    yAxis: {
      title: {
        text: 'Кол-во',
      },
    },
    accessibility: {
      enabled: false,
    },
  };
};
