import { debounce } from 'lodash';
import { FC, memo, useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';

import {
  ActionFilter,
  fetchActionsByRespIdRequest,
  setActionFilter,
  setCurrentActionPage,
} from '@entities/actions';
import {
  checkObjectIdentity,
  DEFAULT_DEBOUNCE_DELAY,
  Input,
  Size,
  TableBodyCell,
  TableFilter,
} from '@shared';

import { INITIAL_ACTIONS_FILTER } from '../../config';

import styles from './ActionsFilter.module.scss';

export const ActionsFilter: FC = memo(() => {
  const { register, handleSubmit, watch, reset } = useForm<ActionFilter>({
    mode: 'onChange',
    defaultValues: INITIAL_ACTIONS_FILTER,
  });

  const dispatch = useDispatch();

  const filterValues = watch();

  const resetFilter = () => {
    dispatch(setActionFilter({}));
    dispatch(fetchActionsByRespIdRequest());
    reset();
  };

  const formSubmitHandler = handleSubmit((data) => {
    const { title, nameInDatabase } = data;
    const preparedData = {
      title: title || undefined,
      nameInDatabase: nameInDatabase || undefined,
    };
    dispatch(setActionFilter(preparedData));
    dispatch(setCurrentActionPage(0));
    dispatch(fetchActionsByRespIdRequest());
  });

  const formSubmitHandlerDebounced = useMemo(
    () => debounce(formSubmitHandler, DEFAULT_DEBOUNCE_DELAY),
    []
  );

  const titleToInput = register('title', {
    onChange: formSubmitHandlerDebounced,
  });
  const nameInDatabaseToInput = register('nameInDatabase', {
    onChange: formSubmitHandlerDebounced,
  });

  const tableBodyWrapper = (elements: JSX.Element[]) =>
    elements.map((item) => (
      <TableBodyCell className={styles.actionsFilter__cell} key={item.key}>
        {item}
      </TableBodyCell>
    ));

  const filterElements = [
    <Input size={Size.xs} type="title" key="title" {...titleToInput} />,
    <Input
      size={Size.xs}
      type="nameInDatabase"
      key="nameInDatabase"
      {...nameInDatabaseToInput}
    />,
    <div />,
  ];

  const filter = <>{tableBodyWrapper(filterElements)}</>;

  const disableReset = checkObjectIdentity(filterValues, {});

  return (
    <TableFilter
      filterComponent={filter}
      onReset={resetFilter}
      disableReset={disableReset}
    />
  );
});
