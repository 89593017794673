import { call, put, select, StrictEffect, takeEvery } from 'redux-saga/effects';

import { User } from '@entities/user/model/types';
import { WorkGroup } from '@entities/work-group/model/types';
import {
  AuthService,
  createError,
  createSuccessAlert,
  ResponseWithMeta,
  setAlert,
} from '@shared';

import { requests } from '../api';

import {
  fetchPermissionsSuccess,
  setAuthorized,
  setCurrentUser,
} from './actions';
import { ChangeUserPasswordAction, CurrentUserTypes } from './actionTypes';
import { getSpecialistWorkGroup, getUserId } from './selectors';
import { AccessedRoute } from './types';

function* accessedRoutesFetch() {
  try {
    const accessedRoutes: AccessedRoute[] = yield call(
      requests.fetchAccessedRoutes
    );
    const actionList: string[] = yield call(requests.fetchActionList);
    const userId: ReturnType<typeof getUserId> = yield select(getUserId);

    const usersWorkGroups: ResponseWithMeta<WorkGroup[]> = yield call(
      requests.fetchUsersWorkGroups,
      userId ?? ''
    );

    const user: User = yield call(requests.fetchUser);
    yield put(setCurrentUser(user));
    const isSpecialist: ReturnType<typeof getSpecialistWorkGroup> =
      yield select(getSpecialistWorkGroup);

    const managerWorkGroupsIds: string[] = yield call(
      requests.fetchManagerWorkGroups,
      userId ?? ''
    );

    const specialistWorkGroupsIds: string[] = isSpecialist
      ? yield call(requests.fetchSpecialistWorkGroups, userId ?? '')
      : [];

    yield put(
      fetchPermissionsSuccess({
        accessedRoutes,
        actionList,
        workGroups: usersWorkGroups.content,
        managerWorkGroupsIds,
        specialistWorkGroupsIds,
      })
    );
    yield put(setAuthorized(true));
  } catch (e) {
    createError(e);
    AuthService.kc.logout();
  }
}

function* changePassword({ payload }: ChangeUserPasswordAction) {
  try {
    yield call(requests.changePassword, payload);
    yield put(setAlert(createSuccessAlert('Ваш пароль успешно изменен')));
  } catch (e) {
    createError(e);
  }
}

export function* currentUserSaga(): Generator<StrictEffect> {
  yield takeEvery(
    CurrentUserTypes.FETCH_USER_PERMISSIONS_REQUEST,
    accessedRoutesFetch
  );
  yield takeEvery(CurrentUserTypes.CHANGE_PASSWORD, changePassword);
}
