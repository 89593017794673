import { SettingField } from '../../model';

export const sortFields = (first: SettingField, second: SettingField) => {
  if (!!first.ordinal && !!second.ordinal) {
    if (first.ordinal > second.ordinal) {
      return 1;
    }
    return -1;
  }
  return 0;
};
