import { Environment } from '@entities/environment';

export const getDataTable = (environments?: Environment[]) =>
  environments?.map((environment) => {
    const { name, id } = environment;
    return {
      id,
      name,
    };
  }) || [];
