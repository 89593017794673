import cn from 'clsx';
import { FC } from 'react';
import { useHistory } from 'react-router-dom';

import {
  DocumentIcon,
  LabelSuccessIcon,
  ListRoundIcon,
  SearchBigIcon,
  TicketBig,
} from '../../assets';
import { RouterHref } from '../../config';
import { Button } from '../Button';
import { Typography, TypographyVariants } from '../Typography';

import { SUB_TITLES_MAP, TITLES_MAP } from './config';
import styles from './DataEmpty.module.scss';
import { EmptyDataType } from './model';

const iconsMap = {
  [EmptyDataType.NO_DATA]: (
    <ListRoundIcon className={styles.dataEmpty__iconNoData_default} />
  ),
  [EmptyDataType.NO_DATA_WITH_FILTER]: (
    <SearchBigIcon className={styles.dataEmpty__iconNoData_filter} />
  ),
  [EmptyDataType.NO_DATA_CLIENT]: (
    <TicketBig className={styles.dataEmpty__iconNoData_ticket} />
  ),
  [EmptyDataType.NO_DATA_SPECIALIST]: (
    <TicketBig className={styles.dataEmpty__iconNoData_ticket} />
  ),
  [EmptyDataType.NO_EXPIRED_DATA]: (
    <LabelSuccessIcon className={styles.dataEmpty__iconNoData_noExpired} />
  ),
  [EmptyDataType.NO_DATA_FILES]: (
    <DocumentIcon className={styles.dataEmpty__iconNoData_files} />
  ),
};

interface DataEmptyProps {
  className?: string;
  type?: EmptyDataType;
  buttonComponent?: JSX.Element;
}

export const DataEmpty: FC<DataEmptyProps> = ({
  className,
  type = EmptyDataType.NO_DATA,
  buttonComponent,
}) => {
  const { push } = useHistory();

  const isNoDataType = type === EmptyDataType.NO_DATA;
  const isNoDataFiles = type === EmptyDataType.NO_DATA_FILES;

  const goToCreateTicket = () => {
    push(RouterHref.CreateTicket);
  };

  const goToAllTickets = () => {
    push(RouterHref.TicketsAll);
  };

  const createTicketButton = type === EmptyDataType.NO_DATA_CLIENT && (
    <Button
      onClick={goToCreateTicket}
      appearance="outline"
      className={styles.dataEmpty__button}
    >
      Создать тикет
    </Button>
  );

  const goToAllTicketsButton = type === EmptyDataType.NO_DATA_SPECIALIST && (
    <Button
      onClick={goToAllTickets}
      appearance="outline"
      className={styles.dataEmpty__button}
    >
      Все тикеты
    </Button>
  );

  return (
    <div className={cn(styles.dataEmpty, className)}>
      <div className={styles.dataEmpty__block}>
        {iconsMap[type]}
        <Typography
          variant={TypographyVariants.h2}
          className={cn(styles.dataEmpty__title, {
            [styles.dataEmpty__title_hidden]: isNoDataType,
            [styles.dataEmpty__title_noFiles]: isNoDataFiles,
          })}
        >
          {TITLES_MAP[type]}
        </Typography>
        <Typography
          variant={TypographyVariants.b3}
          className={cn(styles.dataEmpty__message, {
            [styles.dataEmpty__message_mt]: isNoDataType,
            [styles.dataEmpty__message_noFiles]: isNoDataFiles,
          })}
        >
          {SUB_TITLES_MAP[type]}
        </Typography>
        {createTicketButton}
        {goToAllTicketsButton}
        {buttonComponent}
      </div>
    </div>
  );
};
