import { DeleteDataType, ResponseWithMeta } from '@shared';

import {
  CreateOrganizationAction,
  DeleteOrganizationRequestAction,
  EditOrganizationAction,
  FetchOrganizationRequestAction,
  FetchOrganizationsAddJoinSuccessAction,
  FetchOrganizationsAddRequestAction,
  FetchOrganizationsAddSuccessAction,
  FetchOrganizationsForReportRequestAction,
  FetchOrganizationsForReportSuccessAction,
  FetchOrganizationsJoinSuccessAction,
  FetchOrganizationsRequestAction,
  FetchOrganizationsSuccessAction,
  HideOrganizationLoadingAction,
  HideOrganizationsAddLoadingAction,
  HideOrganizationsLoadingAction,
  Organizations,
  ResetCurrentOrganizationAction,
  ResetFilterOrganizationIdAction,
  ResetOrganizationAddStateAction,
  ResetOrganizationOnlyStateAction,
  ResetOrganizationStateAction,
  SetCurrentOrganizationIdAction,
  SetCurrentOrganizationsAddPageAction,
  SetCurrentOrganizationsPageAction,
  SetCurrentOrganizationSuccessAction,
  SetFilterOrganizationIdAction,
  SetSizePageAction,
  SetSizePageAddAction,
  SetSortOrganizationsAction,
  SetSortOrganizationsAddAction,
  ShowOrganizationLoadingAction,
  ShowOrganizationsAddLoadingAction,
  ShowOrganizationsLoadingAction,
} from './actionTypes';
import {
  CreateOrganizationData,
  FetchOrganizations,
  Organization,
  OrganizationsFilter,
} from './types';

export const showOrganizationsLoading = (): ShowOrganizationsLoadingAction => ({
  type: Organizations.ORGANIZATIONS_LOADING_SHOW,
});

export const hideOrganizationsLoading = (): HideOrganizationsLoadingAction => ({
  type: Organizations.ORGANIZATIONS_LOADING_HIDE,
});

export const showOrganizationsAddLoading =
  (): ShowOrganizationsAddLoadingAction => ({
    type: Organizations.ORGANIZATIONS_ADD_LOADING_SHOW,
  });

export const hideOrganizationsAddLoading =
  (): HideOrganizationsAddLoadingAction => ({
    type: Organizations.ORGANIZATIONS_ADD_LOADING_HIDE,
  });

export const showOrganizationLoading = (): ShowOrganizationLoadingAction => ({
  type: Organizations.ORGANIZATION_LOADING_SHOW,
});

export const hideOrganizationLoading = (): HideOrganizationLoadingAction => ({
  type: Organizations.ORGANIZATION_LOADING_HIDE,
});

export const fetchOrganizationsAddRequest = (
  payload?: FetchOrganizations
): FetchOrganizationsAddRequestAction => ({
  type: Organizations.FETCH_ORGANIZATIONS_ADD_REQUEST,
  payload,
});

export const fetchOrganizationsSuccess = (
  data: ResponseWithMeta<Organization[]>
): FetchOrganizationsSuccessAction => ({
  type: Organizations.FETCH_ORGANIZATIONS_SUCCESS,
  payload: data,
});

export const fetchOrganizationsJoinSuccess = (
  data: ResponseWithMeta<Organization[]>
): FetchOrganizationsJoinSuccessAction => ({
  type: Organizations.FETCH_ORGANIZATIONS_JOIN_SUCCESS,
  payload: data,
});

export const fetchOrganizationsAddJoinSuccess = (
  data: ResponseWithMeta<Organization[]>
): FetchOrganizationsAddJoinSuccessAction => ({
  type: Organizations.FETCH_ORGANIZATIONS_ADD_JOIN_SUCCESS,
  payload: data,
});

export const setCurrentOrganizationsPage = (
  page: number
): SetCurrentOrganizationsPageAction => ({
  type: Organizations.SET_CURRENT_ORGANIZATIONS_PAGE,
  payload: page,
});

export const setSizePage = (size: number): SetSizePageAction => ({
  type: Organizations.SET_SIZE_PAGE_ORGANIZATIONS,
  payload: size,
});

export const setSortOrganizations = (
  sort: string
): SetSortOrganizationsAction => ({
  type: Organizations.SET_SORT_ORGANIZATIONS,
  payload: sort,
});

export const fetchCurrentOrganization = (
  id: string
): FetchOrganizationRequestAction => ({
  type: Organizations.FETCH_ORGANIZATION_REQUEST,
  payload: id,
});

export const setFilterOrganizationId = (
  id: string
): SetFilterOrganizationIdAction => ({
  type: Organizations.SET_FILTER_ORGANIZATION_ID,
  payload: id,
});

export const setCurrentOrganizationSuccess = (
  data?: Organization
): SetCurrentOrganizationSuccessAction => ({
  type: Organizations.SET_CURRENT_ORGANIZATION_SUCCESS,
  payload: data,
});

export const createOrganization = (
  data: CreateOrganizationData
): CreateOrganizationAction => ({
  type: Organizations.CREATE_ORGANIZATION,
  payload: data,
});

export const editOrganization = (
  data: CreateOrganizationData
): EditOrganizationAction => ({
  type: Organizations.EDIT_ORGANIZATION,
  payload: data,
});

export const resetOrganizationState = (): ResetOrganizationStateAction => ({
  type: Organizations.RESET_ORGANIZATION_STATE,
});

export const setCurrentOrganizationId = (
  id?: string
): SetCurrentOrganizationIdAction => ({
  type: Organizations.SET_CURRENT_ORGANIZATION_ID,
  payload: id,
});

export const setOrganizationsFilter = (data: OrganizationsFilter) => ({
  type: Organizations.SET_ORGANIZATIONS_FILTER,
  payload: data,
});

export const fetchOrganizationsRequest = (
  payload?: FetchOrganizations
): FetchOrganizationsRequestAction => ({
  type: Organizations.FETCH_ORGANIZATIONS_REQUEST,
  payload,
});

export const fetchOrganizationsAddSuccess = (
  data: ResponseWithMeta<Organization[]>
): FetchOrganizationsAddSuccessAction => ({
  type: Organizations.FETCH_ORGANIZATIONS_ADD_SUCCESS,
  payload: data,
});

export const setCurrentOrganizationsAddPage = (
  page: number
): SetCurrentOrganizationsAddPageAction => ({
  type: Organizations.SET_CURRENT_ORGANIZATIONS_ADD_PAGE,
  payload: page,
});

export const setSizePageAdd = (size: number): SetSizePageAddAction => ({
  type: Organizations.SET_SIZE_PAGE_ORGANIZATIONS_ADD,
  payload: size,
});

export const setSortOrganizationsAdd = (
  sort: string
): SetSortOrganizationsAddAction => ({
  type: Organizations.SET_SORT_ORGANIZATIONS_ADD,
  payload: sort,
});

export const setOrganizationsAddFilterAction = (data: OrganizationsFilter) => ({
  type: Organizations.SET_ORGANIZATIONS_ADD_FILTER,
  payload: data,
});

export const resetOrganizationAddState =
  (): ResetOrganizationAddStateAction => ({
    type: Organizations.RESET_ORGANIZATION_ADD_STATE,
  });

export const resetOrganizationOnlyState =
  (): ResetOrganizationOnlyStateAction => ({
    type: Organizations.RESET_ORGANIZATION_ONLY_STATE,
  });

export const resetFilterOrganizationIdState =
  (): ResetFilterOrganizationIdAction => ({
    type: Organizations.RESET_FILTER_ORGANIZATION_ID,
  });

export const deleteOrganizationRequest = (
  data: DeleteDataType
): DeleteOrganizationRequestAction => ({
  type: Organizations.DELETE_ORGANIZATION_REQUEST,
  payload: data,
});

export const fetchOrganizationsForReportRequest =
  (): FetchOrganizationsForReportRequestAction => ({
    type: Organizations.FETCH_ORGANIZATIONS_FOR_REPORT_REQUEST,
  });

export const fetchOrganizationsForReportSuccess = (
  payload: Organization[]
): FetchOrganizationsForReportSuccessAction => ({
  type: Organizations.FETCH_ORGANIZATIONS_FOR_REPORT_SUCCESS,
  payload,
});

export const resetCurrentOrganization = (): ResetCurrentOrganizationAction => ({
  type: Organizations.RESET_CURRENT_ORGANIZATION,
});
