import {
  Organization,
  OrganizationType,
} from '@entities/organizations/model/types';
import { Responsibility } from '@entities/responsibilities/model/types';
import { User } from '@entities/user/model/types';
import { ValueType } from '@shared';

export type WorkGroupFilterToRequest = {
  title?: string;
  organizationId?: string;
  description?: string;
  idsWorkGroupsDelete?: Array<string>;
};

export type WorkGroupFilter = Omit<
  WorkGroupFilterToRequest,
  'organizationId'
> & {
  organizationId?: ValueType<string>;
};

export enum TableWorkGroupsTypes {
  FULL = 'FULL',
  FROM_ORGANIZATIONS = 'FROM_ORGANIZATIONS',
  ADD_GROUPS = 'ADD_GROUPS',
  FROM_USERS = 'FROM_USERS',
}

export type WorkGroup = {
  id: string;
  title: string;
  description: string;
  organizationId?: ValueType<string>;
  organizationTitle?: string;
  organization?: Organization;
  orgType?: string;
  orgDomain?: string;
  orgDescription?: string;
  orgAddress?: string;
  userList?: User[];
  responsibilityList?: Responsibility[];
  dateCreate?: string;
  organizationType?: OrganizationType;
  deleted?: boolean;
};

export interface CreateWorkGroupData
  extends Pick<WorkGroup, 'id' | 'title' | 'description' | 'organizationId'> {}

export interface CreateWorkGroupDataToRequest
  extends Pick<WorkGroup, 'id' | 'title' | 'description'> {
  organization?: {
    id: string;
  };
}
