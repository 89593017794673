import cn from 'clsx';
import { FC } from 'react';

import { Typography, TypographyVariants } from '../../../Typography';

import styles from './PopoverTitle.module.scss';

export interface PopoverTitleProps {
  title?: string;
  className?: string;
}

export const PopoverTitle: FC<PopoverTitleProps> = ({ title, className }) => (
  <Typography
    variant={TypographyVariants.o}
    className={cn(styles.popoverTitle, className)}
  >
    {title}
  </Typography>
);
