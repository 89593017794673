import cn from 'clsx';
import {
  type FC,
  type HTMLAttributes,
  type MouseEventHandler,
  type ReactNode,
} from 'react';

import { CloseClearIcon } from '../../assets/icons';
import { createBemClass } from '../helpers/createBemClass';
import { IconButtonAlt } from '../IconButtonAlt';
import { type ColorScheme, type Size } from '../types';
import { Typography, type TypographyVariants } from '../Typography';

import styles from './Tag.module.scss';

type Variant = 'fill' | 'outline';

type TagSize = Exclude<Size, 'l' | 'm' | 'xxs'>;

export interface TagProps extends HTMLAttributes<HTMLSpanElement> {
  colorScheme?: ColorScheme;
  variant?: Variant;
  disabled?: boolean;
  size?: TagSize;
  onDelete?: MouseEventHandler<HTMLButtonElement>;
  leftIcon?: ReactNode;
}

const rootClassName = createBemClass('tag');

const typographyVariant: Record<TagSize, keyof typeof TypographyVariants> = {
  s: 'b3',
  xs: 'b4',
};

export const Tag: FC<TagProps> = ({
  children,
  colorScheme = 'grey',
  variant = 'fill',
  disabled = false,
  size = 's',
  onDelete,
  leftIcon,
  ...other
}) => (
  <Typography
    {...other}
    as="span"
    className={cn(
      styles[rootClassName()],
      styles[rootClassName({ modName: size })],
      styles[rootClassName({ modName: variant })],
      styles[rootClassName({ modName: colorScheme })],
      {
        [styles[rootClassName({ modName: 'disabled' })]]: disabled,
      }
    )}
    variant={typographyVariant[size]}
  >
    {leftIcon}
    {children}
    {onDelete && (
      <IconButtonAlt
        colorScheme={colorScheme}
        disabled={disabled}
        icon={<CloseClearIcon />}
        onClick={onDelete}
        size="3xs"
      />
    )}
  </Typography>
);
