import { ApiClient, getEnv, PAGE_SIZE_SEARCH_SELECT } from '@shared';

import {
  CreateStorageRequest,
  DataForCreateStorageRequest,
  EditStorageRequest,
  StorageForCreateRequest,
} from '../model';

import { endpoints } from './endpoints';

export const requests = {
  fetchOrganizations: (queryText: string) =>
    ApiClient.get({
      baseURL: getEnv('REACT_APP_ATTACHMENT_URL'),
      url: endpoints.getOrganizations(),
      params: { queryText },
    }),
  fetchAdminOrganizations: () =>
    ApiClient.get({
      baseURL: getEnv('REACT_APP_ORGANIZATIONS_URL'),
      url: endpoints.getAdminOrganizations(),
    }),
  fetchOrganizationsForCreate: (
    pageNum = 0,
    pageSize?: number,
    sort?: string,
    filter?: StorageForCreateRequest
  ) =>
    ApiClient.post({
      baseURL: getEnv('REACT_APP_ORGANIZATIONS_URL'),
      url: endpoints.getOrganizationsForCreate(),
      params: { pageNum, pageSize, sort },
      data: filter,
    }),
  fetchWorkGroups: (
    pageNum = 0,
    pageSize = PAGE_SIZE_SEARCH_SELECT,
    sort?: string,
    filter?: StorageForCreateRequest
  ) =>
    ApiClient.post({
      baseURL: getEnv('REACT_APP_ORGANIZATIONS_URL'),
      url: endpoints.getWorkGroups(),
      params: { pageNum, pageSize, sort },
      data: filter,
    }),
  fetchStorages: ({
    organizationIds,
    title,
    pageSize = 10,
    pageNum = 0,
  }: DataForCreateStorageRequest) =>
    ApiClient.post({
      baseURL: getEnv('REACT_APP_ATTACHMENT_URL'),
      url: endpoints.getStorages(),
      params: { pageNum, pageSize, sort: 'CREATE_DATE_DESC' },
      data: { organizationIds, title },
    }),
  createStorage: (data: CreateStorageRequest) =>
    ApiClient.post({
      baseURL: getEnv('REACT_APP_ATTACHMENT_URL'),
      url: endpoints.createStorage(),
      data,
    }),
  editStorage: ({ id, ...data }: EditStorageRequest) =>
    ApiClient.patch({
      baseURL: getEnv('REACT_APP_ATTACHMENT_URL'),
      url: endpoints.editStorage(id),
      data,
    }),
  deleteStorage: (id: string) =>
    ApiClient.delete({
      baseURL: getEnv('REACT_APP_ATTACHMENT_URL'),
      url: endpoints.deleteStorage(id),
    }),
};
