import { useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { resetOrganizationState } from '@entities/organizations';
import {
  resetResponsibilitiesState,
  TableResponsibilitiesTypes,
} from '@entities/responsibilities';
import { resetRolesState, TableRolesTypes } from '@entities/roles';
import { TableUsersTypes } from '@entities/user';
import { resetUsersState } from '@entities/users';
import { TableWorkGroupsTypes } from '@entities/work-group';
import {
  fetchWorkGroupsRequest,
  resetWorkGroupsState,
} from '@entities/work-groups';
import { ResponsibilitiesTableContainer } from '@features/responsibilities';
import { RolesTableContainer } from '@features/roles';
import { UsersTableContainer } from '@features/users';
import { WorkGroupsTableContainer } from '@features/work-groups';
import { TablesLayout } from '@shared';

import styles from './WorkGroups.module.scss';

const Main = () => (
  <div className={styles.workGroups__mainWrapper}>
    <WorkGroupsTableContainer tableType={TableWorkGroupsTypes.FULL} />
    <UsersTableContainer tableType={TableUsersTypes.FROM_GROUPS} />
  </div>
);

const SubResponsibilitiesTable = () => (
  <ResponsibilitiesTableContainer
    tableType={TableResponsibilitiesTypes.FROM_GROUPS}
  />
);

const SubRolesTable = () => (
  <RolesTableContainer tableType={TableRolesTypes.FROM_GROUPS} />
);

export const WorkGroups = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchWorkGroupsRequest());
    return () => {
      dispatch(resetWorkGroupsState());
      dispatch(resetUsersState());
      dispatch(resetResponsibilitiesState());
      dispatch(resetRolesState());
      dispatch(resetOrganizationState());
    };
  }, []);

  return (
    <TablesLayout
      MainTable={[Main]}
      SubTable={[SubResponsibilitiesTable, SubRolesTable]}
      classNameRoot={styles.workGroups}
      classNameSubTables={styles.workGroups__subWrapper}
    />
  );
};
