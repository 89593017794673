import { SimilarSolutionsReducerState } from './slice';

export type SimilarSolutionsState = {
  similarSolutions: SimilarSolutionsReducerState;
};

export enum SolutionType {
  TICKET = 'TICKET',
  ARTICLE = 'ARTICLE',
}

export interface SearchData {
  queryText: string;
  onlyTitleSearch: boolean;
}

export type Tab = 'Тикеты' | 'Статьи';
