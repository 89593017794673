import cn from 'clsx';
import { FC, useState } from 'react';
import { useDispatch } from 'react-redux';

import {
  Attachment,
  AttachmentDeleteType,
  AttachmentsFiles,
} from '@entities/attachment/model/types';
import { ApproveOrCancel, downloadFileWithToken, Size } from '@shared';

import { useTicketId } from '../../lib';
import {
  deleteFileAttachmentsTicketRequest,
  DeleteFileAttachmentsTicketRequest,
} from '../../model/attachment';
import { TicketFileType } from '../../model/types';
import { ContentPreviewAttachment } from '../ContentPreviewAttachment';

import styles from './TicketAttachments.module.scss';

interface TicketAttachmentsProps {
  toggleIsModal(): void;
  handleInitialImageIndex(index: number | undefined): void;
  attachment?: Attachment | undefined;
  imageFiles?: AttachmentsFiles[];
  documentFiles?: AttachmentsFiles[];
  classNameAttachmentsColumn?: string;
  size: Size;
  isButtonDelete?: boolean;
  attachmentDeleteType?: AttachmentDeleteType;
}

export const TicketAttachments: FC<TicketAttachmentsProps> = ({
  toggleIsModal,
  handleInitialImageIndex,
  attachment,
  imageFiles,
  documentFiles,
  isButtonDelete,
  classNameAttachmentsColumn,
  attachmentDeleteType,
  size,
}) => {
  const dispatch = useDispatch();
  const ticketId = useTicketId();
  const [isDeleteModal, setIsDeleteModal] = useState(false);
  const [currentAttachment, setCurrentAttachment] = useState<
    DeleteFileAttachmentsTicketRequest | undefined
  >(undefined);

  const toggleDeleteModal = () => {
    setIsDeleteModal((prevState) => !prevState);
  };

  const handleDownloadFile = (data: TicketFileType) => {
    const { e, fileName, path } = data;
    e.stopPropagation();
    downloadFileWithToken(fileName, path);
  };

  const handleDeleteFile = (data: TicketFileType) => {
    const { e, attachmentId, attachmentFileId, fileName } = data;
    e.stopPropagation();
    setIsDeleteModal(!isDeleteModal);
    setCurrentAttachment({
      attachmentId,
      attachmentFileId,
      fileName,
    });
  };

  const onApprove = () => {
    if (currentAttachment) {
      const { attachmentId, attachmentFileId } = currentAttachment;
      dispatch(
        deleteFileAttachmentsTicketRequest({
          attachmentId,
          attachmentFileId,
          ticketId,
          attachmentDeleteType,
        })
      );
    }
  };

  const handleAttachment = (index: number | undefined) => {
    toggleIsModal();
    handleInitialImageIndex(index);
  };

  const approveOrCancel = (
    <ApproveOrCancel
      onApprove={onApprove}
      isModal={isDeleteModal}
      toggleModal={toggleDeleteModal}
      text={`Вы действительно хотите удалить вложение ${currentAttachment?.fileName}?`}
    />
  );

  const contentImageFiles = (
    <ContentPreviewAttachment
      attachment={attachment}
      files={imageFiles}
      handleAttachment={handleAttachment}
      handleDeleteFile={handleDeleteFile}
      handleDownloadFile={handleDownloadFile}
      size={size}
      isButtonDelete={isButtonDelete}
    />
  );

  const contentDocumentFiles = (
    <ContentPreviewAttachment
      attachment={attachment}
      files={documentFiles}
      handleDeleteFile={handleDeleteFile}
      handleDownloadFile={handleDownloadFile}
      size={size}
      isButtonDelete={isButtonDelete}
    />
  );

  return (
    <div className={cn(styles.ticketAttachments, classNameAttachmentsColumn)}>
      {contentImageFiles}
      {contentDocumentFiles}
      {approveOrCancel}
    </div>
  );
};
