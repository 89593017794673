import cn from 'clsx';
import { FC } from 'react';

import {
  DeleteButton,
  entityNames,
  PopoverContainer,
  TableContainer,
} from '@shared';

import { useKBTableContainer } from '../../../lib';
import { KBTableArticles } from '../KBTableArticles';

import styles from './KBTableArticlesContainer.module.scss';

interface KBTableArticlesContainerProps {
  className?: string;
}

export const KBTableArticlesContainer: FC<KBTableArticlesContainerProps> = ({
  className,
}) => {
  const { methods, state } = useKBTableContainer();

  const {
    toggleDeleteModal,
    toggleFilterRow,
    handleSort,
    handleChangePage,
    handleChangePageSize,
    handleDelete,
    handleShowModalDelete,
    onRowClick,
  } = methods;

  const {
    pageNum,
    pageSize,
    sortArticlesTable,
    totalElements,
    articles,
    showFilterRow,
    showDeleteModal,
    isFilterActive,
    searchDisabled,
    currentArticleTable,
    tableTitle,
    articleTitle,
    loadingArticlesTable,
    canEditCategory,
  } = state;

  const getContent = () => (
    <KBTableArticles
      articles={articles}
      handleSort={handleSort}
      sort={sortArticlesTable}
      handleRowClick={onRowClick}
      activeRowId={currentArticleTable?.id}
      showFilterRow={showFilterRow}
      isFilterActive={isFilterActive}
    />
  );

  const dropContent = (
    <PopoverContainer>
      <DeleteButton
        onClick={handleShowModalDelete}
        disabled={!currentArticleTable?.id}
      />
    </PopoverContainer>
  );

  return (
    <TableContainer
      classNameRoot={cn(styles.KBTableArticlesContainer, className)}
      headerProps={{
        title: tableTitle,
        loading: loadingArticlesTable,
        dropContent: canEditCategory ? dropContent : undefined,
        searchDisabled,
        isSearchOpen: showFilterRow,
        toggleSearch: toggleFilterRow,
      }}
      contentTable={getContent()}
      countRecordsProps={{
        records: totalElements,
      }}
      paginationProps={{
        pageNum,
        elementsCount: totalElements,
        pageSize,
        handleChangePage,
        handleChangePageSize,
        disabled: loadingArticlesTable,
        entityName: entityNames.KB_TABLE_ARTICLES,
      }}
      approveOrCancelProps={{
        onApprove: handleDelete,
        isModal: showDeleteModal,
        toggleModal: toggleDeleteModal,
        approveTitle: 'Удалить',
        approveBtnProps: {
          appearance: 'outline',
          className: styles.KBTableArticlesContainer__iconDelete,
        },
        cancelTitle: 'Отмена',
        text: `Вы уверены, что хотите удалить статью ${articleTitle}?`,
      }}
    />
  );
};
