import { FC } from 'react';
import { compose } from 'redux';

import { register } from '../serviceWorker';

import {
  withAuthAwaiter,
  withRedux,
  withScreenWidth,
  withTheme,
} from './providers';
import { RouterHistory } from './routes/RouterHistory';
import '@shared/lib/i18n/config';

const App: FC = () => <RouterHistory />;

const withAppData = (Component: FC): FC =>
  compose<FC>(
    withRedux,
    withScreenWidth,
    withTheme,
    withAuthAwaiter
  )(Component);

register();

export default withAppData(App);
