import { getValueFromValueType, ValueType } from '@shared';

import {
  OrganizationsFilter,
  OrganizationsFilterToRequest,
  OrganizationType,
} from '../../model/types';

const isArrayOrgType = (
  value?: ValueType<OrganizationType> | OrganizationType[]
): value is OrganizationType[] => Array.isArray(value);

const getPreparedOrgTypes = (
  orgTypes?: ValueType<OrganizationType> | OrganizationType[]
) => {
  if (isArrayOrgType(orgTypes)) {
    return orgTypes;
  }
  const value = getValueFromValueType(orgTypes);
  return value ? [value] : undefined;
};

export const getFilterOrganizationToRequest = (
  filter: OrganizationsFilter = {}
): OrganizationsFilterToRequest => {
  const { organizationTypes } = filter;

  return {
    ...filter,
    organizationTypes: getPreparedOrgTypes(organizationTypes),
  };
};
