import _, { debounce } from 'lodash';
import { FC, memo, useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';

import { getActiveSystemIdContractDetail } from '@entities/contract';
import { EnvironmentsFilter } from '@entities/environment';
import {
  DEFAULT_DEBOUNCE_DELAY,
  Input,
  Size,
  TableBodyCell,
  TableFilter,
} from '@shared';

import { INITIAL_ENVIRONMENT_FILTER } from '../../config';

import styles from './EnvironmentFilter.module.scss';

interface EnvironmentFilterProps {
  onFilter: (filter: EnvironmentsFilter) => void;
}

export const EnvironmentFilter: FC<EnvironmentFilterProps> = memo(
  ({ onFilter }) => {
    const activeSystemId = useSelector(getActiveSystemIdContractDetail);
    const { register, handleSubmit, reset, watch } =
      useForm<EnvironmentsFilter>({
        mode: 'onChange',
        defaultValues: INITIAL_ENVIRONMENT_FILTER,
      });
    const filterValues = watch();

    const resetFilter = () => {
      onFilter({ systemId: activeSystemId });
      reset();
    };

    const formSubmitHandler = handleSubmit((data) => {
      const { name } = data;
      onFilter({ name, systemId: activeSystemId });
    });

    const formSubmitHandlerDebounced = useMemo(
      () => debounce(formSubmitHandler, DEFAULT_DEBOUNCE_DELAY),
      [onFilter]
    );

    const nameToInput = register('name', {
      onChange: formSubmitHandlerDebounced,
    });

    const tableBodyWrapper = (
      <TableBodyCell className={styles.environmentFilter__cell}>
        <Input size={Size.xs} type="name" key="name" {...nameToInput} />
      </TableBodyCell>
    );

    const disableReset = _.isEqual(
      _.omitBy(filterValues, (value, key) =>
        _.isNil(value) || _.isEmpty(value) ? key : false
      ),
      {}
    );
    return (
      <TableFilter
        filterComponent={tableBodyWrapper}
        onReset={resetFilter}
        disableReset={disableReset}
      />
    );
  }
);
