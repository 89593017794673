import { getUrl } from '@shared';

export const endpoints = {
  getCurrentSystem: (id: string) => getUrl(`system/${id}`),
  createSystem: () => getUrl('system'),
  addTicketType: (systemId: string) =>
    getUrl(`system/${systemId}/ticket-types`),
  addEnvironment: (systemId: string) =>
    getUrl(`system/${systemId}/environments`),
  getSystemIndexList: () => getUrl('system/indexes'),
};
