import { TableWorkGroupsTypes } from '@entities/work-group/model';
import { RenderTypes, TableColumns } from '@shared';

import {
  fetchGroupsByOrganizationId,
  fetchGroupsByUserIdRequest,
  fetchWorkGroupsByContractBySystemRequest,
  fetchWorkGroupsRequest,
} from '../model';

export const WORK_GROUPS_ADD_ALL_FULL: TableColumns = [
  {
    title: 'Название',
    fieldName: 'title',
  },
  {
    title: 'Организация',
    fieldName: 'organizationId',
    renderType: RenderTypes.NOTIFICATION_LINK,
  },
  { title: 'Описание', fieldName: 'description' },
];

export const INITIAL_GROUPS_FILTER = {
  title: '',
  description: '',
  organizationId: null,
};

export const WORK_GROUPS_FIELDS_SHORT: TableColumns = [
  { title: 'Название', fieldName: 'title', sortField: 'title' },
  { title: 'Описание', fieldName: 'description', sortField: 'description' },
];

export const fetchFunctions = {
  [TableWorkGroupsTypes.FULL]: fetchWorkGroupsRequest,
  [TableWorkGroupsTypes.FROM_ORGANIZATIONS]: fetchGroupsByOrganizationId,
  [TableWorkGroupsTypes.FROM_USERS]: fetchGroupsByUserIdRequest,
  [TableWorkGroupsTypes.ADD_GROUPS]: fetchWorkGroupsByContractBySystemRequest,
};
