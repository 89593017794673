import { useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { resetArticlesMainState } from '@entities/knowledge-base';

import { KBCategoryMainBlock } from '../KBCategoryMainBlock';
import { KBMainWelcome } from '../KBMainWelcome';

import styles from './KBMain.module.scss';

export const KBMain = () => {
  const dispatch = useDispatch();

  useEffect(
    () => () => {
      dispatch(resetArticlesMainState());
    },
    []
  );
  return (
    <>
      <KBMainWelcome />
      <KBCategoryMainBlock className={styles.KBMain__categories} withHeader />
    </>
  );
};
