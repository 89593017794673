import { useState } from 'react';
import { useSelector } from 'react-redux';

import { getTicketAttachments } from '../../model/attachment/selectors';
import { getAttachmentFilesInfo } from '../utils';

import { useCurrentAttachment } from './useCurrentAttachment';

export const useTicketAttachments = () => {
  const ticketAttachments = useSelector(getTicketAttachments);

  const [isImageModal, setIsImageModal] = useState<boolean>(false);
  const [initialImageIndex, setInitialImageIndex] = useState(0);

  const { attachment } = useCurrentAttachment(ticketAttachments);

  const { imageFiles, documentFiles } =
    getAttachmentFilesInfo(ticketAttachments);

  const toggleIsImageModal = () => {
    setIsImageModal((prevState) => !prevState);
  };

  const handleInitialImageIndex = (index: number) => {
    setInitialImageIndex(index);
  };

  return {
    isImageModal,
    initialImageIndex,
    attachment,
    imageFiles,
    documentFiles,
    toggleIsImageModal,
    handleInitialImageIndex,
  };
};
