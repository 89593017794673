import { MapFields } from '../../model';

export const mapFields = ({ fieldsList, field, currentField }: MapFields) =>
  fieldsList.map((f) => {
    if (currentField && f.naturalKey === field.naturalKey) {
      return { ...f, ordinal: currentField.ordinal };
    }
    if (f.naturalKey === currentField?.naturalKey) {
      return { ...f, ordinal: field.ordinal };
    }
    return f;
  });
