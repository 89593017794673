import { Action } from '@entities/actions/model/types';
import { Ticket, TicketStatus, TicketType } from '@entities/ticket/model/types';
import {
  ApiClient,
  CreateSettingFields,
  getEnv,
  ISettingFields,
  PAGE_SIZE_SEARCH_SELECT,
  ResponseWithMeta,
  TicketsTabType,
} from '@shared';

import {
  DEFAULT_SORT_FILTER_STATUSES,
  DEFAULT_SORT_FILTER_TYPES,
} from '../config';
import { FetchTicketsPayload, TicketsTypesPayload } from '../model';
import {
  CreateFilterRequest,
  FilterData,
  StatusTicketPayload,
} from '../model/types';

import { endpoints } from './endpoints';

export const requests = {
  fetchTickets: ({
    pageNum = 0,
    pageSize,
    sort,
    filterValues,
    ticketTab,
  }: FetchTicketsPayload) =>
    ApiClient.post<ResponseWithMeta<Ticket[]>>({
      url: endpoints.getTickets(),
      params: { pageNum, pageSize, sort: sort?.toUpperCase(), ticketTab },
      data: filterValues,
    }),
  fetchTicket: (id: string) =>
    ApiClient.get<Ticket>({
      url: endpoints.getTicket(id),
    }),
  setTicketToWork: (ids: string[]) =>
    ApiClient.post<Ticket[]>({
      url: endpoints.setTicketToWork(),
      data: ids,
    }),
  addSpecialist: (ticketId: string | string[], userId: string) =>
    ApiClient.post<Ticket[]>({
      url: endpoints.addSpecialist(),
      params: { userId },
      data: Array.isArray(ticketId) ? ticketId : [ticketId],
    }),
  fetchOpenTicketsCountByContractId: (contractIds?: string[]) =>
    ApiClient.post<number>({
      url: endpoints.getTicketsCount(),
      data: {
        contractIds,
        statuses: [
          TicketStatus.NEW,
          TicketStatus.WORK,
          TicketStatus.WAITING_INFO,
          TicketStatus.PENDING_CLOSURE,
        ],
      },
    }),
  fetchCurrentTicketActions: (ticketGroupsIds: string[]) =>
    ApiClient.post<Action[]>({
      baseURL: getEnv('REACT_APP_ORGANIZATIONS_URL'),
      url: endpoints.getCurrentTicketActions(),
      data: ticketGroupsIds,
    }),
  deleteTicket: (id: string) =>
    ApiClient.delete<Ticket>({
      url: endpoints.deleteTicket(id),
    }),
  fetchFilterStatuses: ({
    pageNum = 0,
    pageSize = PAGE_SIZE_SEARCH_SELECT,
    sort = DEFAULT_SORT_FILTER_STATUSES,
    ticketTab,
    filter,
  }: StatusTicketPayload) =>
    ApiClient.post<string[]>({
      url: endpoints.getFilterStatuses(),
      params: { pageNum, pageSize, sort, ticketTab },
      data: filter,
    }),
  fetchFiltersData: (ticketTab?: TicketsTabType) =>
    ApiClient.get<FilterData[]>({
      url: endpoints.getFiltersData(),
      params: { ticketTab },
    }),
  createFilter: (data: CreateFilterRequest) =>
    ApiClient.post({
      url: endpoints.createFilter(),
      data,
    }),
  deleteFilter: (id: string) =>
    ApiClient.delete({
      url: endpoints.deleteFilter(id),
    }),
  setFilterTitle: (id: string, newTitle: string) =>
    ApiClient.patch({
      url: endpoints.setFilterTitle(id),
      params: { newTitle },
    }),
  fetchTicketsTableConfig: () =>
    ApiClient.get<ISettingFields>({
      url: endpoints.getTicketsTableConfig(),
    }),
  fetchActualTicketsTableConfig: () =>
    ApiClient.get<ISettingFields>({
      url: endpoints.getActualTicketsTableConfig(),
    }),
  createTicketsTableConfig: (data: CreateSettingFields) =>
    ApiClient.post<ISettingFields>({
      url: endpoints.createTicketsTableConfig(),
      data,
    }),
  fetchFilterTypes: ({
    pageNum = 0,
    pageSize = PAGE_SIZE_SEARCH_SELECT,
    sort = DEFAULT_SORT_FILTER_TYPES,
    ticketTab,
    filter,
  }: TicketsTypesPayload) =>
    ApiClient.post<ResponseWithMeta<TicketType[]>>({
      url: endpoints.getFilterTypes(),
      params: { pageNum, pageSize, sort, ticketTab },
      data: filter,
    }),
};
