import cn from 'clsx';
import { FC, MouseEvent } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
  fetchPatternByIdRequestContractDetail,
  getPatternActive,
  getSLAPatterns,
  getSLAPatternsLoading,
} from '@entities/contract';
import { SLAType } from '@entities/sla';
import {
  DataEmpty,
  EmptyDataType,
  getFormatWorkingTimeForPattern,
  LayersIcon,
  Typography,
  TypographyVariants,
} from '@shared';

import { Loading } from '../Loading';

import styles from './SLAPatternsList.module.scss';

interface SLAPatternsListProps {
  hasSearchValue?: boolean;
  copyPatternToCreateMode?: (data: SLAType) => void;
}

export const SLAPatternsList: FC<SLAPatternsListProps> = ({
  hasSearchValue,
  copyPatternToCreateMode,
}) => {
  const dispatch = useDispatch();
  const slaPatterns = useSelector(getSLAPatterns);
  const loading = useSelector(getSLAPatternsLoading);
  const activePattern = useSelector(getPatternActive);

  const onClickPattern = (id?: string) => () => {
    if (id && activePattern?.id !== id) {
      dispatch(fetchPatternByIdRequestContractDetail(id));
    }
  };

  const onCopyPattern = (event: MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    if (copyPatternToCreateMode && activePattern) {
      copyPatternToCreateMode(activePattern);
    }
  };

  const list = slaPatterns?.map((item: SLAType) => {
    const workingTime = `${getFormatWorkingTimeForPattern(
      item.workingHoursFrom
    )} - ${getFormatWorkingTimeForPattern(item.workingHoursTo)}`;

    const isActive = activePattern?.id === item?.id;

    return (
      <li
        key={item.title}
        aria-hidden
        className={cn(styles.slaPatternsList__item, {
          [styles.slaPatternsList__item_active]: isActive,
        })}
        onClick={onClickPattern(item?.id)}
      >
        <div className={styles.slaPatternsList__itemWrapper}>
          <div className={styles.slaPatternsList__itemTitle}>
            <Typography variant={TypographyVariants.b3}>
              {item.title}
            </Typography>
            <Typography
              variant={TypographyVariants.b3}
              className={styles.slaPatternsList__itemTitleTime}
            >
              {workingTime}
            </Typography>
          </div>
          <button
            type="button"
            onClick={onCopyPattern}
            name="editTemplate"
            disabled={!isActive}
            className={
              !isActive ? styles.slaPatternsList__itemBtn_disabled : undefined
            }
          >
            <LayersIcon className={styles.slaPatternsList__itemIcon} />
          </button>
        </div>
      </li>
    );
  });

  const getContent = () => {
    if (loading) {
      return <Loading />;
    }
    if (!loading && !slaPatterns?.length && hasSearchValue) {
      return (
        <div className={styles.slaPatternsList__dataEmpty}>
          <DataEmpty type={EmptyDataType.NO_DATA_WITH_FILTER} />
        </div>
      );
    }
    return list;
  };

  return <ul className={styles.slaPatternsList}>{getContent()}</ul>;
};
