import { AdminsLayout } from '@pages/AdminsLayout';
import { Systems } from '@widgets/systems';

const SystemsPage = () => (
  <AdminsLayout>
    <Systems />
  </AdminsLayout>
);

export default SystemsPage;
