import { getUrl } from '@shared';

export const endpoints = {
  getAccessedRoutes: () => getUrl('user/frontend-routes'),
  getActionList: () => getUrl('user/action-list'),
  getUsersWorkGroups: (userId: string) => getUrl(`work-group/user/${userId}`),
  getChangePasswordPath: () => getUrl('user/pass-change'),
  getUser: () => getUrl('user/auth'),
  getUserManagerWorkGroup: (userId: string) =>
    getUrl(`work-group/user-manager/${userId}`),
  getUserSpecialistWorkGroup: (userId: string) =>
    getUrl(`work-group/user-specialist/${userId}`),
};
