import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import {
  CurrentStorageFileCardRequest,
  DownloadStorageFileRequest,
  Storage,
  StorageFile,
  StorageFileCard,
  StorageFileCardRequest,
  StorageFileCards,
  StorageFileCardsRequest,
  UploadingFile,
} from './types';

export interface StorageReducerState {
  storage?: Storage;
  storageFiles?: StorageFileCards;
  currentFileCard?: StorageFileCard;
  downloadedFiles?: StorageFile[];
  uploadingFiles: UploadingFile[];
  pageNum?: number;
  pageSize?: number;
  loading?: boolean;
}

const initialState: StorageReducerState = {
  downloadedFiles: [],
  uploadingFiles: [],
  pageNum: 0,
  pageSize: 10,
  loading: false,
};

const storageSlice = createSlice({
  name: 'storage',
  initialState,
  reducers: {
    fetchStorageFileCardsSuccess: (
      state,
      action: PayloadAction<StorageFileCards>
    ) => {
      state.storageFiles = action.payload;
    },
    fetchStorageFileCardsRequest: (
      _state,
      _action: PayloadAction<StorageFileCardsRequest>
    ) => {},
    fetchStorageSuccess: (state, action: PayloadAction<Storage>) => {
      state.storage = action.payload;
    },
    fetchStorageRequest: (
      _state,
      _action: PayloadAction<string | undefined>
    ) => {},
    downloadStorageFileRequest: (
      _state,
      _action: PayloadAction<DownloadStorageFileRequest>
    ) => {},
    setDownloadedStorageFiles: (state, action: PayloadAction<StorageFile>) => {
      state.uploadingFiles = [
        ...state.uploadingFiles.filter(
          (file) =>
            file.title !==
            `${action.payload.fileName}.${action.payload.fileExtension}`
        ),
      ];
      if (state.currentFileCard && !state.downloadedFiles?.length) {
        state.downloadedFiles = [
          action.payload,
          state.currentFileCard.mainFile,
          ...state.currentFileCard.additionalFiles,
        ];
        return;
      }
      if (state.downloadedFiles) {
        state.downloadedFiles = [action.payload, ...state.downloadedFiles];
      }
    },
    setUploadingFile: (state, action: PayloadAction<UploadingFile>) => {
      state.uploadingFiles = [...state.uploadingFiles, action.payload];
    },
    changeUploadingFile: (state, action: PayloadAction<UploadingFile>) => {
      state.uploadingFiles = [
        ...state.uploadingFiles.map((file) =>
          file.id === action.payload.id
            ? {
                ...file,
                progress: action.payload.progress,
                status: action.payload.status,
              }
            : { ...file }
        ),
      ];
    },
    filterUploadingFiles: (state, action: PayloadAction<string>) => {
      state.uploadingFiles = [
        ...state.uploadingFiles.filter((file) => file.id !== action.payload),
      ];
    },
    uploadStorageFileRequest: (_state, _action: PayloadAction<File>) => {},
    deleteDownloadedStorageFile: (
      state,
      action: PayloadAction<StorageFile[]>
    ) => {
      state.downloadedFiles = action.payload;
    },
    addStorageFileCardRequest: (
      _state,
      _action: PayloadAction<StorageFileCardRequest>
    ) => {},
    deleteStorageFileCardRequest: (
      _state,
      _action: PayloadAction<CurrentStorageFileCardRequest>
    ) => {},
    editStorageFileCardRequest: (
      _state,
      _action: PayloadAction<CurrentStorageFileCardRequest>
    ) => {},
    fetchCurrentStorageFileCardSuccess: (
      state,
      action: PayloadAction<StorageFileCard>
    ) => {
      state.currentFileCard = action.payload;
    },
    fetchCurrentStorageFileCardRequest: (
      _state,
      _action: PayloadAction<CurrentStorageFileCardRequest>
    ) => {},
    setPageNumStorage: (state, action: PayloadAction<number | undefined>) => {
      state.pageNum = action.payload;
    },
    setPageSizeStorage: (state, action: PayloadAction<number | undefined>) => {
      state.pageSize = action.payload;
    },
    setLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },
    resetDownloadedStorageFiles: (state) => {
      state.downloadedFiles = initialState.downloadedFiles;
    },
    resetCurrentStorageFileCard: (state) => {
      state.currentFileCard = initialState.currentFileCard;
    },
    resetUploadingFiles: (state) => {
      state.uploadingFiles = initialState.uploadingFiles;
    },
    resetStorage: () => initialState,
  },
});

export const {
  fetchStorageFileCardsSuccess,
  fetchStorageFileCardsRequest,
  fetchStorageSuccess,
  fetchStorageRequest,
  fetchCurrentStorageFileCardSuccess,
  fetchCurrentStorageFileCardRequest,
  downloadStorageFileRequest,
  editStorageFileCardRequest,
  addStorageFileCardRequest,
  deleteStorageFileCardRequest,
  setDownloadedStorageFiles,
  setUploadingFile,
  changeUploadingFile,
  filterUploadingFiles,
  uploadStorageFileRequest,
  deleteDownloadedStorageFile,
  setPageSizeStorage,
  setPageNumStorage,
  setLoading,
  resetDownloadedStorageFiles,
  resetCurrentStorageFileCard,
  resetStorage,
  resetUploadingFiles,
} = storageSlice.actions;
export const storage = storageSlice.reducer;
