import _ from 'lodash';

import { StatusForm } from '@entities/statuses-builder';

export const getNormalizedData = (data?: StatusForm) =>
  _.omit(
    data,
    'defaultStatus',
    'hideBottomHandle',
    'hideLeftHandle',
    'hideRightHandle',
    'hideTopHandle'
  );
