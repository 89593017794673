import { ReactNode } from 'react';

export enum AlertTypes {
  SUCCESS = 'SUCCESS',
  ERROR = 'ERROR',
  WARNING = 'WARNING',
}

export type Alert = {
  id: string;
  message: string | ReactNode;
  type: AlertTypes;
  withoutTime?: boolean;
};

export enum ActionForAlertTypes {
  DELETE = 'DELETE',
  EDIT = 'EDIT',
  CREATE = 'CREATE',
}
