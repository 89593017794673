import { StatusesBuilder } from '@widgets/statuses-builder';

import { AdminsLayout } from '../AdminsLayout';

const StatusesBuilderPage = () => (
  <AdminsLayout>
    <StatusesBuilder />
  </AdminsLayout>
);

export default StatusesBuilderPage;
