import {
  Contract,
  CONTRACT_TYPE_TITLE_MAP,
  ContractStatusBlock,
} from '@entities/contract';

export const getDataTable = (contracts?: Contract[]) =>
  contracts?.map((contract) => {
    const { title, type, status, id } = contract;
    return {
      id,
      title: { title, to: `/admin/contracts/${id}` },
      type: { text: CONTRACT_TYPE_TITLE_MAP[type] },
      status: <ContractStatusBlock status={status} />,
    };
  }) || [];
