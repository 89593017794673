import { getUrl } from '@shared';

export const endpoints = {
  getSystems: () => getUrl('system/filter'),
  getCurrentSystem: (id: string) => getUrl(`system/${id}`),
  getSystemsByContractId: (id: string) =>
    getUrl(`system/filter/contract/${id}`),
  getMySystems: () => getUrl('system/authUser'),
  getFilterSystems: () => getUrl('filter-param/systems'),
  getSystemsOrganization: (id: string) => getUrl(`system/organization/${id}`),
  getSystemPriorities: (systemId: string) =>
    getUrl(`priority/system/${systemId}`),
  deleteSystem: (id: string) => getUrl(`system/${id}`),
  updateSystem: () => getUrl('system'),
  getSystemsOrganizationFirJira: (id: string) =>
    getUrl(`jira-properties/system-permissible/${id}`),
  getSLA: () => getUrl('sla/map/by-contract-system'),
  getEnvironments: () => getUrl(`environment/filter`),
};
