import cn from 'clsx';
import { FC } from 'react';

import {
  ArrowLeftIcon,
  ArrowUpWithLine,
  Button,
  CloseIcon,
  EllipseIconButton,
  IconButtonWrapper,
  LineIcon,
  Loader,
  PlusIcon,
} from '@shared';

import { useUserAssistance } from '../../lib';
import { UserAssistanceAddButtons } from '../UserAssistanceAddButtons';
import { UserAssistanceArticlesList } from '../UserAssistanceArticlesList';
import { UserAssistanceContentWrapper } from '../UserAssistanceContentWrapper';
import { UserAssistanceSearch } from '../UserAssistanceSearch';
import { UserAssistanceSearchState } from '../UserAssistanceSearchState';
import { UserAssistanceStartBlock } from '../UserAssistanceStartBlock';

import styles from './UserAssistance.module.scss';

export const UserAssistance: FC = () => {
  const { methods, state } = useUserAssistance();

  const {
    toggleIsAddMode,
    onReset,
    toggleIsModal,
    register,
    handleSubmit,
    toggleIsHidden,
    toggleIsDetailModal,
  } = methods;

  const {
    articles,
    articlesAll,
    articlesLoading,
    articlesAllLoading,
    isHidden,
    isMobileAll,
    isAccessToAddArticleToTheHelp,
    selectedArticlesIds,
    isAddMode,
    isModal,
    isDetailModal,
  } = state;

  const addButton = !isMobileAll ? (
    <Button
      onClick={toggleIsAddMode}
      icon={<PlusIcon />}
      appearance="flat"
      disabled={isAddMode}
    >
      Добавить
    </Button>
  ) : (
    <EllipseIconButton
      onClick={toggleIsAddMode}
      icon={<PlusIcon />}
      className={cn(styles.userAssistance__mobileAddButton, {
        [styles.userAssistance__mobileAddButton_hidden]: isAddMode,
      })}
    />
  );

  const headerContent = isAccessToAddArticleToTheHelp && addButton;

  const startBlock = !isAddMode && !articlesLoading && (
    <UserAssistanceStartBlock
      emptyContent={!articles?.length}
      className={cn({
        [styles.userAssistance__startBlock_emptyContent]: !articles?.length,
      })}
    />
  );

  const searchBlock = isAddMode && (
    <UserAssistanceSearch
      onReset={onReset}
      toggleIsAddMode={toggleIsAddMode}
      register={register}
      handleSubmit={handleSubmit}
    />
  );

  const searchState = isAddMode &&
    !articlesAll.length &&
    !articlesAllLoading && <UserAssistanceSearchState />;

  const loader = (isAddMode ? articlesAllLoading : articlesLoading) && (
    <Loader classNameRoot={styles.userAssistance__loader} />
  );

  const articlesList = (!isAddMode
    ? !!articles?.length && !articlesLoading
    : !!articlesAll?.length && !articlesAllLoading) && (
    <UserAssistanceArticlesList
      articles={isAddMode ? articlesAll : articles}
      toggleIsHiddenParent={toggleIsHidden}
      toggleIsOpenParent={toggleIsModal}
      isAddMode={isAddMode}
      isAccessToAddArticleToTheHelp={isAccessToAddArticleToTheHelp}
      toggleIsOpen={toggleIsDetailModal}
      isOpen={isDetailModal}
      className={cn({
        [styles.userAssistance__articlesList]: !isAddMode,
      })}
    />
  );

  if (!isModal) {
    return null;
  }

  return (
    <div
      className={cn(styles.userAssistance, {
        [styles.userAssistance_hidden]: isHidden,
      })}
      onMouseDown={toggleIsModal}
      aria-hidden="true"
    >
      <div
        className={cn(styles.userAssistance__container, {
          [styles.userAssistance__container_hidden]: isHidden,
        })}
        onMouseDown={(e) => e.stopPropagation()}
        aria-hidden="true"
      >
        <div className={styles.userAssistance__header}>
          <p className={styles.userAssistance__headerTitle}>Помощь</p>
          <div className={styles.userAssistance__buttonsWrapper}>
            {headerContent}
            <IconButtonWrapper
              className={styles.userAssistance__headerCloseButton}
              onClick={toggleIsHidden}
              icon={
                isHidden ? (
                  <ArrowUpWithLine
                    className={styles.userAssistance__headerCloseIcon}
                  />
                ) : (
                  <LineIcon
                    className={styles.userAssistance__headerCloseIcon}
                  />
                )
              }
            />
            <IconButtonWrapper
              className={styles.userAssistance__headerCloseButton}
              onClick={toggleIsModal}
              icon={
                <CloseIcon className={styles.userAssistance__headerCloseIcon} />
              }
            />
          </div>
          <IconButtonWrapper
            className={styles.userAssistance__headerCloseButtonMobile}
            onClick={toggleIsModal}
            icon={
              <ArrowLeftIcon
                className={styles.userAssistance__headerCloseIcon}
              />
            }
          />
        </div>
        {searchBlock}
        <UserAssistanceContentWrapper>
          {startBlock}
          {loader}
          {articlesList}
          {searchState}
        </UserAssistanceContentWrapper>
        {isAddMode && !!selectedArticlesIds.length && (
          <UserAssistanceAddButtons
            onReset={onReset}
            toggleIsAddMode={toggleIsAddMode}
          />
        )}
      </div>
    </div>
  );
};
