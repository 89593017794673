import {
  CustomConditions,
  DefaultConditions,
  Trigger,
} from '@entities/triggers';
import { getKeysFromKeyValue, KeyValueOption } from '@shared';

interface Args {
  isEnable: boolean;
  selectedTrigger: Trigger;
  customConditions: CustomConditions[];
  defaultConditions: DefaultConditions[];
  targetStatus: KeyValueOption;
}

export const getPreparedDataForUpdate = ({
  isEnable,
  selectedTrigger,
  customConditions,
  defaultConditions,
  targetStatus,
}: Args) => {
  const preparedCustomConditions = customConditions.map(
    ({
      conditionType,
      customField,
      textValue,
      flagValue,
      minutes,
      listValue,
    }) => ({
      conditionType,
      customFieldId: customField.key,
      textValue,
      flagValue,
      minutes,
      listValue,
    })
  );

  return {
    title: selectedTrigger.title,
    isEnable,
    customConditionRequests: preparedCustomConditions,
    defaultConditions,
    targetStatus: targetStatus.key,
    organization: selectedTrigger.organization.key,
    system: selectedTrigger.system.key,
    ticketTypes: getKeysFromKeyValue(selectedTrigger.ticketTypes),
    ticketPriorities: getKeysFromKeyValue(selectedTrigger.ticketPriorities),
    environments: getKeysFromKeyValue(selectedTrigger.environments),
    clients: getKeysFromKeyValue(selectedTrigger.clients),
    status: selectedTrigger.status.key,
  };
};
