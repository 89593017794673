import { FC } from 'react';
import { useHistory } from 'react-router-dom';

import { Article } from '@entities/knowledge-base';
import {
  CloseIcon,
  PenIcon,
  PopoverButton,
  PopoverContainer,
  RouterHref,
} from '@shared';

import { KBChangeFieldDrop } from '../KBChangeFieldDrop';

import styles from './KBArticleDrop.module.scss';

export interface KBArticleDropProps {
  article?: Article;
  withEditArticle?: boolean;
  withChangeArticleTitle?: boolean;
  withDeleteArticle?: boolean;
  onChangeTitle?: (id: string, title?: string) => void;
  toggleModal?(): void;
  onDelete?: (id: string) => void;
}

export const KBArticleDrop: FC<Omit<KBArticleDropProps, 'onDelete'>> = ({
  article,
  withEditArticle,
  withChangeArticleTitle,
  withDeleteArticle,
  onChangeTitle,
  toggleModal,
}) => {
  const { push } = useHistory();

  const onEditArticle = () => {
    if (article?.id) {
      push(`${RouterHref.KnowledgeBase}/edit/${article.id}`);
    }
  };

  const handleChangeTitle = (title = '') => {
    if (article?.id && title && onChangeTitle) {
      onChangeTitle(article.id, title);
    }
  };

  return (
    <PopoverContainer>
      {withEditArticle && (
        <PopoverButton
          icon={<PenIcon className={styles.KBArticleDrop__iconEdit} />}
          onClick={onEditArticle}
        >
          Редактировать статью
        </PopoverButton>
      )}
      {withChangeArticleTitle && (
        <KBChangeFieldDrop
          onChange={handleChangeTitle}
          initialValue={article?.title}
        />
      )}
      {withDeleteArticle && (
        <PopoverButton
          icon={<CloseIcon className={styles.KBArticleDrop__iconDelete} />}
          onClick={toggleModal}
        >
          Удалить статью
        </PopoverButton>
      )}
    </PopoverContainer>
  );
};
