import { getUrl } from '@shared';

export const endpoints = {
  getContracts: () => getUrl('contract/filter'),
  getCurrentContract: (id: string) => getUrl(`contract/${id}`),
  getContractsBySystemId: (id: string) => getUrl(`contract/system/${id}`),
  createContract: () => getUrl('contract'),
  editContract: () => getUrl('contract'),
  addSystem: () => getUrl('contract/add-system'),
  addSLA: () => getUrl('system/add-sla'),
  getSystemsForContract: (id: string) => getUrl(`system/contract/${id}`),
  getSLAForSystem: () => getUrl('sla/by-contract-system'),
  updateGroupsInSystemInContract: (
    contractId: string,
    systemId: string,
    slaId: string
  ) => getUrl(`contract/work-group/${contractId}/${systemId}/${slaId}`),
  deleteContract: (id: string) => getUrl(`contract/${id}`),
  getSLA: () => getUrl('sla/map/by-contract-system'),
};
