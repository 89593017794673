import { CustomFields } from '@features/custom-fields';
import { AdminsLayout } from '@pages/AdminsLayout';

const CustomFieldsPage = () => (
  <AdminsLayout>
    <CustomFields />
  </AdminsLayout>
);

export default CustomFieldsPage;
