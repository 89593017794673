import { KBLayout } from '@features/knowledge-base';
import { KBTable } from '@widgets/knowledge-base';

import styles from './KBTablePage.module.scss';

const KBTablePage = () => (
  <KBLayout className={styles.KBTablePage}>
    <KBTable />
  </KBLayout>
);

export default KBTablePage;
