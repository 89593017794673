import { WorkGroupFilter, WorkGroupFilterToRequest } from '../../model';

export const getFilterGroupsToRequest = (
  filter?: WorkGroupFilter
): WorkGroupFilterToRequest => {
  const organizationId = filter?.organizationId;
  return {
    ...filter,
    organizationId:
      organizationId && !Array.isArray(organizationId)
        ? organizationId.value
        : undefined,
  };
};
