import cn from 'clsx';
import { FC } from 'react';

import { CONTRACT_STATUS_TITLE_MAP } from '../../config';
import { ContractStatus } from '../../model';

import styles from './ContractStatusBlock.module.scss';

export const styleContractStatus = {
  [ContractStatus.ACTIVE]: styles.contractStatus_active,
  [ContractStatus.CLOSED]: styles.contractStatus_closed,
  [ContractStatus.PLANNED]: styles.contractStatus_planned,
  [ContractStatus.NOT_SIGNED]: styles.contractStatus_notSigned,
};

interface ContractStatusBlockProps {
  status?: ContractStatus;
}

export const ContractStatusBlock: FC<ContractStatusBlockProps> = ({
  status = ContractStatus.ACTIVE,
}) => (
  <div className={cn(styles.contractStatus, styleContractStatus[status])}>
    {CONTRACT_STATUS_TITLE_MAP[status]}
  </div>
);
