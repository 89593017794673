import { Attachment } from '@entities/attachment/model/types';
import { Article } from '@entities/knowledge-base/model/types';
import { Ticket } from '@entities/ticket/model/types';
import { ResponseWithMeta } from '@shared';

import {
  FetchArticlesRequestAction,
  FetchArticlesSuccessAction,
  FetchSimilarArticleRequestAction,
  FetchSimilarArticleSuccessAction,
  FetchSimilarTicketAttachmentsRequestAction,
  FetchSimilarTicketAttachmentsSuccessAction,
  FetchSimilarTicketRequestAction,
  FetchSimilarTicketSuccessAction,
  FetchTicketsRequestAction,
  FetchTicketsSuccessAction,
  HideLoadingAction,
  HideSimilarDetailLoadingAction,
  ResetSimilarSolutionsStateAction,
  ShowLoadingAction,
  ShowSimilarDetailLoadingAction,
  SimilarSolutions,
} from './actionTypes';
import { SearchData } from './types';

export const showLoading = (): ShowLoadingAction => ({
  type: SimilarSolutions.SIMILAR_LOADING_SHOW,
});

export const hideLoading = (): HideLoadingAction => ({
  type: SimilarSolutions.SIMILAR_LOADING_HIDE,
});

export const fetchTicketsRequest = (
  payload: SearchData
): FetchTicketsRequestAction => ({
  type: SimilarSolutions.FETCH_SIMILAR_TICKETS_REQUEST,
  payload,
});

export const fetchTicketsSuccess = (
  data: ResponseWithMeta<Ticket[]>
): FetchTicketsSuccessAction => ({
  type: SimilarSolutions.FETCH_SIMILAR_TICKETS_SUCCESS,
  payload: data,
});

export const fetchArticlesRequest = (
  payload: SearchData
): FetchArticlesRequestAction => ({
  type: SimilarSolutions.FETCH_SIMILAR_ARTICLES_REQUEST,
  payload,
});

export const fetchArticlesSuccess = (
  data: ResponseWithMeta<Article[]>
): FetchArticlesSuccessAction => ({
  type: SimilarSolutions.FETCH_SIMILAR_ARTICLES_SUCCESS,
  payload: data,
});

export const resetSimilarSolutionsState =
  (): ResetSimilarSolutionsStateAction => ({
    type: SimilarSolutions.RESET_SIMILAR_SOLUTIONS_STATE,
  });

export const fetchSimilarTicketRequest = (
  ticketId: string
): FetchSimilarTicketRequestAction => ({
  type: SimilarSolutions.FETCH_SIMILAR_TICKET_REQUEST,
  payload: ticketId,
});

export const fetchSimilarTicketSuccess = (
  payload: Ticket
): FetchSimilarTicketSuccessAction => ({
  type: SimilarSolutions.FETCH_SIMILAR_TICKET_SUCCESS,
  payload,
});

export const showSimilarDetailLoading = (): ShowSimilarDetailLoadingAction => ({
  type: SimilarSolutions.SIMILAR_DETAIL_LOADING_SHOW,
});

export const hideSimilarDetailLoading = (): HideSimilarDetailLoadingAction => ({
  type: SimilarSolutions.SIMILAR_DETAIL_LOADING_HIDE,
});

export const fetchSimilarArticleRequest = (
  articleId: string
): FetchSimilarArticleRequestAction => ({
  type: SimilarSolutions.FETCH_SIMILAR_ARTICLE_REQUEST,
  payload: articleId,
});

export const fetchSimilarArticleSuccess = (
  payload: Article
): FetchSimilarArticleSuccessAction => ({
  type: SimilarSolutions.FETCH_SIMILAR_ARTICLE_SUCCESS,
  payload,
});

export const fetchSimilarTicketAttachmentsRequest = (
  ticketId: string
): FetchSimilarTicketAttachmentsRequestAction => ({
  type: SimilarSolutions.FETCH_SIMILAR_TICKET_ATTACHMENTS_REQUEST,
  payload: ticketId,
});

export const fetchSimilarTicketAttachmentsSuccess = (
  attachments: Attachment[]
): FetchSimilarTicketAttachmentsSuccessAction => ({
  type: SimilarSolutions.FETCH_SIMILAR_TICKET_ATTACHMENTS_SUCCESS,
  payload: attachments,
});
