import { ChangeEvent, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
  getContrastTheme,
  getSystemThemeSelector,
  getTheme,
  setIsContrastTheme,
  setIsSystemTheme,
  setTheme,
  Theme,
  ThemeActive,
  ToggleSwitch,
  Typography,
  TypographyVariants,
} from '@shared';

import styles from './InterfaceSettings.module.scss';

const getThemeValue = (isContrastTheme: boolean, isDarkTheme: boolean) => {
  if (isContrastTheme) {
    return isDarkTheme ? Theme.DARK_CONTRAST : Theme.LIGHT_CONTRAST;
  }

  return isDarkTheme ? Theme.DARK : Theme.LIGHT;
};

const getThemeActive = (checked: boolean) =>
  checked ? ThemeActive.ON : ThemeActive.OFF;

export const InterfaceSettings = () => {
  const dispatch = useDispatch();

  const theme = useSelector(getTheme);
  const systemTheme = useSelector(getSystemThemeSelector);
  const contrastTheme = useSelector(getContrastTheme);

  const isContrastTheme = contrastTheme === ThemeActive.ON;
  const isSystemTheme = systemTheme === ThemeActive.ON;
  const isDarkTheme =
    theme === (!isContrastTheme ? Theme.DARK : Theme.DARK_CONTRAST);

  const setThemeHandler = (value: Theme) => {
    dispatch(setTheme(value));
    localStorage.setItem('theme', value);
  };

  const setIsContrastThemeHandler = (value: ThemeActive) => {
    dispatch(setIsContrastTheme(value));
    localStorage.setItem('contrast-theme', value);
  };

  const setIsSystemThemeHandler = (value: ThemeActive) => {
    dispatch(setIsSystemTheme(value));
    localStorage.setItem('system-theme', value);
  };

  const changeThemeHandler = (event: ChangeEvent<HTMLInputElement>) => {
    const themeValue = getThemeValue(isContrastTheme, event.target.checked);
    setThemeHandler(themeValue);
  };

  const changeIsSystemThemeHandler = (event: ChangeEvent<HTMLInputElement>) => {
    const themeActive = getThemeActive(event.target.checked);
    setIsSystemThemeHandler(themeActive);
  };

  const changeIsContrastThemeHandler = (
    event: ChangeEvent<HTMLInputElement>
  ) => {
    const { checked } = event.target;
    const themeValue = getThemeValue(checked, isDarkTheme);
    const themeActive = getThemeActive(checked);

    setThemeHandler(themeValue);
    setIsContrastThemeHandler(themeActive);
  };

  useEffect(() => {
    const themeValue = getThemeValue(isContrastTheme, isDarkTheme);
    setThemeHandler(themeValue);
  }, [isContrastTheme, isDarkTheme]);

  return (
    <div className={styles.interfaceSettings}>
      <Typography
        variant={TypographyVariants.h4}
        className={styles.interfaceSettings__title}
      >
        Интерфейс
      </Typography>
      <div className={styles.interfaceSettings__switch}>
        <ToggleSwitch
          label="Темный режим"
          disabled={isSystemTheme}
          checked={isDarkTheme}
          onChange={changeThemeHandler}
          classNameLabel={styles.interfaceSettings__switchLabel}
        />
      </div>
      <div className={styles.interfaceSettings__switch}>
        <ToggleSwitch
          label="Использовать системную тему"
          checked={isSystemTheme}
          onChange={changeIsSystemThemeHandler}
          classNameLabel={styles.interfaceSettings__switchLabel}
        />
      </div>
      <div className={styles.interfaceSettings__switch}>
        <ToggleSwitch
          label="Повысить контрастность"
          checked={isContrastTheme}
          onChange={changeIsContrastThemeHandler}
          classNameLabel={styles.interfaceSettings__switchLabel}
        />
      </div>
    </div>
  );
};
