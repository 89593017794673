import { useState } from 'react';
import { useSelector } from 'react-redux';

import { getScreenWidth, ScreenWidth } from '@shared';

export const useKBCategory = () => {
  const [isDropOpen, setIsDropOpen] = useState(false);
  const [isMouseOnTitle, setIsMouseOnTitle] = useState(false);
  const [isCategoryOpen, setIsCategoryOpen] = useState(true);

  const toggleCategoryOpen = () => {
    setIsCategoryOpen((prev) => !prev);
  };

  const screenWidth = useSelector(getScreenWidth);

  const isMobile = screenWidth === ScreenWidth.MOBILE;
  const isMobileSmall = screenWidth === ScreenWidth.MOBILE_SMALL;
  const isMobileAll = isMobile || isMobileSmall;

  const onMouseEnter = () => {
    setIsMouseOnTitle(true);
  };

  const onMouseLeave = () => {
    setIsMouseOnTitle(false);
  };

  const togglePopover = (event?: MouseEvent) => {
    event?.stopPropagation();
    setIsDropOpen((prev) => !prev);
  };

  return {
    methods: {
      togglePopover,
      onMouseEnter,
      onMouseLeave,
      toggleCategoryOpen,
    },
    state: {
      isMobileAll,
      isDropOpen,
      isMouseOnTitle,
      isCategoryOpen,
    },
  };
};
