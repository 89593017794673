import { createSelector } from 'reselect';

import { Organization } from '@entities/organizations';
import { WorkGroup } from '@entities/work-group';
import { getArraySelectOptions, SelectOption } from '@shared';

import { CategoryArticle } from '../types';

import { ArticleCreateState } from './types';

export const getArticleCreateOrganizations = (state: ArticleCreateState) =>
  state.articleCreate.organizations;
export const getArticleCreateCategories = (state: ArticleCreateState) =>
  state.articleCreate.categories;
export const getSubCategoriesArticleCreate = (state: ArticleCreateState) =>
  state.articleCreate.subCategories;
export const getCurrentArticle = (state: ArticleCreateState) =>
  state.articleCreate.currentArticle;
export const getCurrentArticleLoading = (state: ArticleCreateState) =>
  state.articleCreate.currentArticleLoading;
export const getWorkGroups = (state: ArticleCreateState) =>
  state.articleCreate.workGroups;

export const getArticleOrganizationsSelectList = createSelector<
  ArticleCreateState,
  Organization[] | undefined,
  SelectOption[]
>([getArticleCreateOrganizations], (organizations): SelectOption[] =>
  getArraySelectOptions(organizations)
);

export const getArticleWorkGroupsSelectList = createSelector<
  ArticleCreateState,
  WorkGroup[] | undefined,
  SelectOption[]
>([getWorkGroups], (workGroups): SelectOption[] =>
  getArraySelectOptions(workGroups)
);

export const getArticleCategoriesSelectList = createSelector<
  ArticleCreateState,
  CategoryArticle[] | undefined,
  SelectOption[]
>([getArticleCreateCategories], (categories): SelectOption[] =>
  getArraySelectOptions(categories)
);

export const getSubCategoriesSelectListArticleCreate = createSelector<
  ArticleCreateState,
  CategoryArticle[] | undefined,
  SelectOption[]
>([getSubCategoriesArticleCreate], (subCategories): SelectOption[] =>
  getArraySelectOptions(subCategories)
);
