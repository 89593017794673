import { pick } from 'lodash';
import { Reducer } from 'redux';

import { Attachment } from '@entities/attachment/model/types';
import {
  Environment,
  EnvironmentSetting,
  EnvironmentsFilter,
} from '@entities/environment/model/types';
import {
  Organization,
  OrganizationsFilter,
} from '@entities/organizations/model/types';
import { SLAType } from '@entities/sla/model/types';
import { System, SystemsFilter } from '@entities/system/model/types';
import { TicketType, TicketTypeSettings } from '@entities/ticket/model/types';
import { User, UsersFilter } from '@entities/user/model/types';
import { WorkGroup, WorkGroupFilter } from '@entities/work-group/model/types';
import {
  DEFAULT_PAGINATION_PARAMS,
  PaginationType,
  ReducerMap,
  SelectOption,
} from '@shared';

import {
  CheckIsExistPatternTitleSuccessContractDetailAction,
  ContractDetail,
  ContractDetailActionsTypes,
  FetchContractAttachmentsSuccessContractDetailAction,
  FetchContractDetailSuccessAction,
  FetchEnvironmentsBySystemSuccessAction,
  FetchEnvironmentSettingsBySystemSuccessAction,
  FetchOrganizationsSuccessUpdateContractDetailAction,
  FetchPartnerOrganizationsSuccessContractDetailAction,
  FetchPatternByIdSuccessContractDetailAction,
  FetchSlaByContractIdBySystemIdSuccessContractDetailAction,
  FetchSLAPatternsSuccessContractDetailAction,
  FetchSupplementaryAgreementsSuccessAction,
  FetchSystemsByContractIdSuccessContractDetailAction,
  FetchSystemsForAddingSupplementaryAgreementSuccessContractDetailAction,
  FetchSystemsForAddingToContractSuccessContractDetailAction,
  FetchTicketTypesSettingsSuccessContractDetailAction,
  FetchTicketTypesSuccessContractDetailAction,
  FetchUsersAllowedToAddToContractDetailSuccessAction,
  FetchWorkGroupsByContractBySystemSuccessContractDetailAction,
  FetchWorkGroupsByContractSuccessContractDetailAction,
  ProlongationContractAction,
  SetActiveSystemByContractIdContractDetailAction,
  SetAllCheckedSystemForSystemTransferAction,
  SetCheckedSystemForSystemTransferAction,
  SetContractIdForSystemTransferAction,
  SetContractPermissionAction,
  SetFilterEnvironmentsBySystemAction,
  SetFilterSystemsByContractIdContractDetailAction,
  SetFilterWorkGroupsByContractBySystemContractDetailAction,
  SetFilterWorkGroupsByContractContractDetailAction,
  SetLoadingTicketTypesSettingsContractDetailAction,
  SetOrganizationsFilterContractDetailAction,
  SetOrganizationsPageContractDetailAction,
  SetOrganizationsSortContractDetailAction,
  SetPageEnvironmentsBySystemAction,
  SetPageSizeSupplementaryAgreementsContractDetailAction,
  SetPageSizeSystemsByContractIdContractDetailAction,
  SetPageSupplementaryAgreementsContractDetailAction,
  SetPageSystemsByContractIdContractDetailAction,
  SetPageWorkGroupsByContractBySystemContractDetailAction,
  SetPageWorkGroupsByContractContractDetailAction,
  SetSelectedSystemIdContractDetailAction,
  SetSelectedSystemSlaContractDetailAction,
  SetSelectSystemByContractAction,
  SetSizeEnvironmentsBySystemAction,
  SetSizePageWorkGroupsByContractBySystemContractDetailAction,
  SetSizePageWorkGroupsByContractContractDetailAction,
  SetSortEnvironmentsBySystemAction,
  SetSortSupplementaryAgreementsContractDetailAction,
  SetSortSystemsByContractIdContractDetailAction,
  SetSortWorkGroupsByContractBySystemContractDetailAction,
  SetTransferStatusAction,
  SetUsersFilterContractDetailAction,
  SetUsersPageContractDetailAction,
  UpdateContractNotificationsSuccessAction,
  UpdateContractPermissionsSuccessAction,
} from './actionTypes';
import {
  Contract,
  ContractPermission,
  ProgressStatus,
  SupportType,
  SystemsTransfer,
} from './types';

export interface ContractDetailReducerState {
  contract?: Contract;
  contractLoading?: boolean;
  contractPermission?: ContractPermission;

  supportType?: SupportType;
  prolongationContract?: ProgressStatus;

  systemId?: string;

  supplementaryAgreements?: Contract[];
  supplementaryAgreementsLoading?: boolean;
  supplementaryAgreementsPagination?: PaginationType;
  supplementaryAgreementsSort?: string;

  organizations?: Organization[];
  organizationsSort?: string;
  organizationsLoading?: boolean;
  organizationsPagination?: PaginationType;
  organizationSelected?: SelectOption<string> | null;
  organizationsFilter?: OrganizationsFilter;

  partnerOrganizations?: Organization[];
  partnerOrganizationsLoading?: boolean;

  selectedSystem?: System;
  systems?: System[];
  systemsForAddingToContractLoading?: boolean;

  systemsByContractIdSaved?: System[];
  systemsByContractIdSavedSort?: string;
  systemsByContractIdSavedLoading?: boolean;
  systemsByContractIdSavedPagination?: PaginationType;
  systemIdActive?: string;
  systemsByContractIdSavedFilter?: SystemsFilter;

  systemsForSA?: System[];
  systemsFofSALoading?: boolean;

  systemsTransfer?: SystemsTransfer;

  users?: User[];
  usersLoading?: boolean;
  usersPagination?: PaginationType;
  usersFilter?: UsersFilter;

  groups?: WorkGroup[];
  groupsLoading?: boolean;
  groupsPagination?: PaginationType;
  groupsFilter?: WorkGroupFilter;
  groupsSort?: string;

  groupsByContract?: WorkGroup[];
  groupsByContractLoading?: boolean;
  groupsByContractPagination?: PaginationType;
  groupsByContractFilter?: WorkGroupFilter;
  groupsByContractSort?: string;

  attachments?: Attachment[];
  attachmentsLoading?: boolean;

  slaPatterns?: SLAType[];
  slaPatternsLoading?: boolean;
  isPatternTitleExist?: boolean;
  slaPatternActive?: SLAType;

  slaByContractBySystem?: SLAType;
  slaByContractBySystemLoading?: boolean;

  ticketTypesSettings?: TicketTypeSettings[];
  ticketTypesSettingsLoading?: boolean;
  ticketTypes?: TicketType[];
  selectedSystemSla?: string;

  environments?: Environment[];
  environmentsFilter?: EnvironmentsFilter;
  environmentsPagination?: PaginationType;
  environmentsSort?: string;
  environmentsLoading?: boolean;
  environmentSettings?: EnvironmentSetting[];
}

const initialState: ContractDetailReducerState = {
  contractLoading: false,

  supplementaryAgreementsPagination: DEFAULT_PAGINATION_PARAMS,
  supplementaryAgreementsLoading: false,
  supplementaryAgreements: [],
  supplementaryAgreementsSort: 'date_create_DESC',

  organizations: [],
  organizationsSort: 'date_create_DESC',
  organizationsLoading: false,
  organizationsPagination: DEFAULT_PAGINATION_PARAMS,
  organizationSelected: null,
  organizationsFilter: {},

  partnerOrganizations: [],
  partnerOrganizationsLoading: false,

  systems: [],
  systemsForAddingToContractLoading: false,

  systemsByContractIdSaved: [],
  systemsByContractIdSavedSort: 'date_create_DESC',
  systemsByContractIdSavedLoading: false,
  systemsByContractIdSavedPagination: DEFAULT_PAGINATION_PARAMS,
  systemsByContractIdSavedFilter: {},

  systemsForSA: [],
  systemsFofSALoading: false,

  users: [],
  usersLoading: false,
  usersPagination: DEFAULT_PAGINATION_PARAMS,
  usersFilter: {},

  groups: [],
  groupsLoading: false,
  groupsPagination: DEFAULT_PAGINATION_PARAMS,
  groupsFilter: {},
  groupsSort: 'date_create_DESC',

  groupsByContract: [],
  groupsByContractLoading: false,
  groupsByContractPagination: DEFAULT_PAGINATION_PARAMS,
  groupsByContractSort: 'date_create_DESC',
  groupsByContractFilter: {},

  attachments: [],
  attachmentsLoading: false,

  slaPatterns: [],
  slaPatternsLoading: false,
  isPatternTitleExist: false,

  slaByContractBySystemLoading: false,

  ticketTypesSettings: [],
  ticketTypesSettingsLoading: false,

  environments: [],
  environmentSettings: [],
  environmentsSort: 'NAME_ASC',
  environmentsLoading: false,
};

const contractDetailReducerMap: ReducerMap<
  ContractDetailReducerState,
  ContractDetailActionsTypes
> = {
  [ContractDetail.CONTRACT_LOADING_SHOW]: (state) => ({
    ...state,
    contractLoading: true,
  }),
  [ContractDetail.CONTRACT_LOADING_HIDE]: (state) => ({
    ...state,
    contractLoading: false,
  }),
  [ContractDetail.FETCH_CONTRACT_SUCCESS]: (state, action) => {
    const { payload } = action as FetchContractDetailSuccessAction;
    return {
      ...state,
      contract: payload,
    };
  },
  [ContractDetail.SUPPLEMENTARY_AGREEMENTS_LOADING_SHOW]: (state) => ({
    ...state,
    supplementaryAgreementsLoading: true,
  }),
  [ContractDetail.SUPPLEMENTARY_AGREEMENTS_LOADING_HIDE]: (state) => ({
    ...state,
    supplementaryAgreementsLoading: false,
  }),
  [ContractDetail.FETCH_SUPPLEMENTARY_AGREEMENTS_SUCCESS]: (state, action) => {
    const { payload } = action as FetchSupplementaryAgreementsSuccessAction;
    const { content, page, totalElements } = payload;
    return {
      ...state,
      supplementaryAgreements: content,
      supplementaryAgreementsPagination: {
        ...state?.supplementaryAgreementsPagination,
        pageNum: page,
        totalElements,
      },
    };
  },
  [ContractDetail.SET_SUPPLEMENTARY_AGREEMENTS_PAGE]: (state, action) => {
    const { payload } =
      action as SetPageSupplementaryAgreementsContractDetailAction;
    return {
      ...state,
      supplementaryAgreementsPagination: {
        ...state?.supplementaryAgreementsPagination,
        pageNum: payload,
      },
    };
  },
  [ContractDetail.SET_SIZE_PAGE_SUPPLEMENTARY_AGREEMENTS]: (state, action) => {
    const { payload } =
      action as SetPageSizeSupplementaryAgreementsContractDetailAction;
    return {
      ...state,
      supplementaryAgreementsPagination: {
        ...state?.supplementaryAgreementsPagination,
        pageSize: payload,
      },
    };
  },
  [ContractDetail.SET_SORT_SUPPLEMENTARY_AGREEMENTS]: (state, action) => {
    const { payload } =
      action as SetSortSupplementaryAgreementsContractDetailAction;
    return {
      ...state,
      supplementaryAgreementsSort: payload,
    };
  },
  [ContractDetail.SET_SELECTED_SYSTEM_ID]: (state, action) => {
    const { payload } = action as SetSelectedSystemIdContractDetailAction;
    return {
      ...state,
      systemId: payload,
    };
  },
  [ContractDetail.SET_CONTRACT_PERMISSION]: (state, action) => {
    const { payload } = action as SetContractPermissionAction;
    return {
      ...state,
      contractPermission: {
        ...state?.contractPermission,
        storageAccessible: payload.isEnable,
      },
    };
  },
  [ContractDetail.UPDATE_CONTRACT_PERMISSION_SUCCESS]: (state, action) => {
    const { payload } = action as UpdateContractPermissionsSuccessAction;
    return {
      ...state,
      contract: payload,
    };
  },
  [ContractDetail.UPDATE_CONTRACT_NOTIFICATIONS_SUCCESS]: (state, action) => {
    const { payload } = action as UpdateContractNotificationsSuccessAction;
    return {
      ...state,
      contract: payload,
    };
  },
  [ContractDetail.SET_PROLONGATION_CONTRACT]: (state, action) => {
    const { payload } = action as ProlongationContractAction;
    return {
      ...state,
      prolongationContract: payload,
    };
  },
  [ContractDetail.RESET_PARTLY_CONTRACT_DETAIL_STATE_FOR_PROLONGATION]: (
    state
  ) => {
    const preparedState = pick(state, [
      'contract',
      'organizations',
      'prolongationContract',
      'systemsByContractIdSaved',
    ]);

    return {
      ...initialState,
      ...preparedState,
    };
  },
  [ContractDetail.LOADING_SHOW_SYSTEMS]: (state) => ({
    ...state,
    systemsForAddingToContractLoading: true,
  }),
  [ContractDetail.LOADING_HIDE_SYSTEMS]: (state) => ({
    ...state,
    systemsForAddingToContractLoading: false,
  }),
  [ContractDetail.FETCH_SYSTEMS_SUCCESS]: (state, action) => {
    const { payload } =
      action as FetchSystemsForAddingToContractSuccessContractDetailAction;
    return {
      ...state,
      systems: payload,
    };
  },
  [ContractDetail.LOADING_SHOW_SYSTEMS_BY_CONTRACT_ID]: (state) => ({
    ...state,
    systemsByContractIdSavedLoading: true,
  }),
  [ContractDetail.LOADING_HIDE_SYSTEMS_BY_CONTRACT_ID]: (state) => ({
    ...state,
    systemsByContractIdSavedLoading: false,
  }),
  [ContractDetail.SET_SYSTEMS_BY_CONTRACT_ID_PAGE]: (state, action) => {
    const { payload } =
      action as SetPageSystemsByContractIdContractDetailAction;
    return {
      ...state,
      systemsByContractIdSavedPagination: {
        ...state?.systemsByContractIdSavedPagination,
        pageNum: payload,
      },
    };
  },
  [ContractDetail.SET_SIZE_PAGE_SYSTEMS_BY_CONTRACT_ID]: (state, action) => {
    const { payload } =
      action as SetPageSizeSystemsByContractIdContractDetailAction;
    return {
      ...state,
      systemsByContractIdSavedPagination: {
        ...state?.systemsByContractIdSavedPagination,
        pageSize: payload,
      },
    };
  },
  [ContractDetail.SET_SYSTEMS_BY_CONTRACT_ID_FILTER]: (state, action) => {
    const { payload } =
      action as SetFilterSystemsByContractIdContractDetailAction;
    return {
      ...state,
      systemsByContractIdSavedFilter: payload,
    };
  },
  [ContractDetail.SET_ACTIVE_SYSTEM_BY_CONTRACT_ID]: (state, action) => {
    const { payload } =
      action as SetActiveSystemByContractIdContractDetailAction;
    return {
      ...state,
      systemIdActive: payload,
    };
  },
  [ContractDetail.SET_SYSTEMS_BY_CONTRACT_ID_SORT]: (state, action) => {
    const { payload } =
      action as SetSortSystemsByContractIdContractDetailAction;
    return {
      ...state,
      systemsByContractIdSavedSort: payload,
    };
  },
  [ContractDetail.RESET_ONLY_SYSTEMS_BY_CONTRACT_ID]: (state) => {
    const {
      systemsByContractIdSaved,
      systemsByContractIdSavedFilter,
      systemsByContractIdSavedLoading,
      systemsByContractIdSavedPagination,
      systemsByContractIdSavedSort,
      systemIdActive,
    } = initialState;
    return {
      ...state,
      systemsByContractIdSaved,
      systemsByContractIdSavedFilter,
      systemsByContractIdSavedLoading,
      systemsByContractIdSavedPagination,
      systemsByContractIdSavedSort,
      systemIdActive,
    };
  },
  [ContractDetail.SET_CHECKED_SYSTEM_FOR_TRANSFER]: (state, action) => {
    const { payload } = action as SetCheckedSystemForSystemTransferAction;

    const selectedSystem = state?.systemsTransfer?.systems?.find(
      (system) => system.id === payload.id
    );

    return {
      ...state,
      systemsTransfer: {
        ...state?.systemsTransfer,
        systems: !selectedSystem
          ? [...(state?.systemsTransfer?.systems || []), payload]
          : state?.systemsTransfer?.systems?.filter(
              (system) => system.id !== payload.id
            ),
      },
    };
  },
  [ContractDetail.SET_ALL_CHECKED_SYSTEMS_FOR_TRANSFER]: (state, action) => {
    const { payload } = action as SetAllCheckedSystemForSystemTransferAction;

    return {
      ...state,
      systemsTransfer: {
        ...state?.systemsTransfer,
        systems: payload,
      },
    };
  },
  [ContractDetail.SET_TRANSFER_STATUS]: (state, action) => {
    const { payload } = action as SetTransferStatusAction;

    return {
      ...state,
      systemsTransfer: {
        ...state?.systemsTransfer,
        transferStatus: payload,
      },
    };
  },
  [ContractDetail.SET_CONTRACT_ID_FOR_TRANSFER]: (state, action) => {
    const { payload } = action as SetContractIdForSystemTransferAction;

    return {
      ...state,
      systemsTransfer: {
        ...state?.systemsTransfer,
        contractId: payload,
      },
    };
  },
  [ContractDetail.RESET_PARTLY_CONTRACT_DETAIL_STATE_FOR_TRANSFER]: (
    state
  ) => ({
    ...initialState,
    supportType: state?.contract?.supportType,
    systemsTransfer: {
      ...state?.systemsTransfer,
    },
  }),
  [ContractDetail.FETCH_SYSTEMS_FOR_ADDING_SUPPLEMENTARY_AGREEMENT_SUCCESS]: (
    state,
    action
  ) => {
    const { payload } =
      action as FetchSystemsForAddingSupplementaryAgreementSuccessContractDetailAction;
    return {
      ...state,
      systemsForSA: payload,
    };
  },
  [ContractDetail.LOADING_SHOW_SYSTEMS_FOR_ADDING_SUPPLEMENTARY_AGREEMENT]: (
    state
  ) => ({
    ...state,
    systemsFofSALoading: true,
  }),
  [ContractDetail.LOADING_HIDE_SYSTEMS_FOR_ADDING_SUPPLEMENTARY_AGREEMENT]: (
    state
  ) => ({
    ...state,
    systemsFofSALoading: false,
  }),
  [ContractDetail.FETCH_SYSTEMS_BY_CONTRACT_ID_SUCCESS]: (state, action) => {
    const { payload } =
      action as FetchSystemsByContractIdSuccessContractDetailAction;
    const { content, page, totalElements } = payload;
    return {
      ...state,
      systemsByContractIdSaved: content,
      systemsByContractIdSavedPagination: {
        ...state?.systemsByContractIdSavedPagination,
        pageNum: page,
        totalElements,
      },
    };
  },
  [ContractDetail.RESET_CONTRACT_DETAIL_STATE]: () => ({
    ...initialState,
  }),
  [ContractDetail.SET_SELECT_SYSTEM_BY_CONTRACT]: (state, action) => {
    const { payload } = action as SetSelectSystemByContractAction;
    return {
      ...state,
      selectedSystem: payload,
    };
  },
  [ContractDetail.ORGANIZATIONS_LOADING_SHOW]: (state) => ({
    ...state,
    organizationsLoading: true,
  }),
  [ContractDetail.ORGANIZATIONS_LOADING_HIDE]: (state) => ({
    ...state,
    organizationsLoading: false,
  }),
  [ContractDetail.SET_ORGANIZATIONS_SORT]: (state, action) => {
    const { payload } = action as SetOrganizationsSortContractDetailAction;
    return {
      ...state,
      organizationsSort: payload,
    };
  },
  [ContractDetail.SET_CURRENT_ORGANIZATIONS_PAGE]: (state, action) => {
    const { payload } = action as SetOrganizationsPageContractDetailAction;
    return {
      ...state,
      organizationsPagination: {
        ...state?.organizationsPagination,
        pageNum: payload,
      },
    };
  },
  [ContractDetail.SET_ORGANIZATIONS_FILTER]: (state, action) => {
    const { payload } = action as SetOrganizationsFilterContractDetailAction;
    return {
      ...state,
      organizationsFilter: payload,
    };
  },
  [ContractDetail.FETCH_ORGANIZATIONS_UPDATE_SUCCESS]: (state, action) => {
    const { payload } =
      action as FetchOrganizationsSuccessUpdateContractDetailAction;
    const { content, page, totalElements } = payload;
    return {
      ...state,
      organizations: content,
      organizationsPagination: {
        ...state?.organizationsPagination,
        pageNum: page,
        totalElements,
      },
    };
  },
  [ContractDetail.FETCH_ORGANIZATIONS_JOIN_SUCCESS]: (state, action) => {
    const { payload } =
      action as FetchOrganizationsSuccessUpdateContractDetailAction;
    const { content, page, totalElements } = payload;
    return {
      ...state,
      organizations: [...(state?.organizations || []), ...content],
      organizationsPagination: {
        ...state?.organizationsPagination,
        pageNum: page,
        totalElements,
      },
    };
  },
  [ContractDetail.RESET_ORGANIZATION_ONLY_STATE]: (state) => {
    const {
      organizations,
      organizationSelected,
      organizationsPagination,
      organizationsFilter,
      organizationsLoading,
      organizationsSort,
    } = initialState;
    return {
      ...state,
      organizations,
      organizationSelected,
      organizationsPagination,
      organizationsFilter,
      organizationsLoading,
      organizationsSort,
    };
  },
  [ContractDetail.PARTNER_ORGANIZATIONS_LOADING_SHOW]: (state) => ({
    ...state,
    partnerOrganizationsLoading: true,
  }),
  [ContractDetail.PARTNER_ORGANIZATIONS_LOADING_HIDE]: (state) => ({
    ...state,
    partnerOrganizationsLoading: false,
  }),
  [ContractDetail.FETCH_PARTNER_ORGANIZATIONS_SUCCESS]: (state, action) => {
    const { payload } =
      action as FetchPartnerOrganizationsSuccessContractDetailAction;
    return {
      ...state,
      partnerOrganizations: payload.content,
    };
  },
  [ContractDetail.USERS_LOADING_SHOW]: (state) => ({
    ...state,
    usersLoading: true,
  }),
  [ContractDetail.USERS_LOADING_HIDE]: (state) => ({
    ...state,
    usersLoading: false,
  }),
  [ContractDetail.FETCH_USERS_ALLOWED_TO_ADD_TO_SUCCESS_REQUEST]: (
    state,
    action
  ) => {
    const { payload } =
      action as FetchUsersAllowedToAddToContractDetailSuccessAction;
    const { content, page, totalElements } = payload;
    return {
      ...state,
      users: content,
      usersPagination: {
        ...state?.usersPagination,
        pageNum: page,
        totalElements,
      },
    };
  },
  [ContractDetail.SET_USERS_PAGE]: (state, action) => {
    const { payload } = action as SetUsersPageContractDetailAction;
    return {
      ...state,
      usersPagination: {
        ...state?.usersPagination,
        pageNum: payload,
      },
    };
  },
  [ContractDetail.SET_USERS_FILTER]: (state, action) => {
    const { payload } = action as SetUsersFilterContractDetailAction;
    return {
      ...state,
      usersFilter: payload,
    };
  },
  [ContractDetail.RESET_USERS_ALLOWED_TO_ADD_TO_CONTRACT_ONLY_STATE]: (
    state
  ) => {
    const { users, usersFilter, usersLoading, usersPagination } = initialState;
    return {
      ...state,
      users,
      usersFilter,
      usersLoading,
      usersPagination,
    };
  },
  [ContractDetail.ATTACHMENTS_LOADING_SHOW]: (state) => ({
    ...state,
    attachmentsLoading: true,
  }),
  [ContractDetail.ATTACHMENTS_LOADING_HIDE]: (state) => ({
    ...state,
    attachmentsLoading: false,
  }),
  [ContractDetail.FETCH_CONTRACT_ATTACHMENTS_SUCCESS]: (state, action) => {
    const { payload } =
      action as FetchContractAttachmentsSuccessContractDetailAction;
    return {
      ...state,
      attachments: payload,
    };
  },
  [ContractDetail.RESET_ONLY_ATTACHMENTS_STATE]: (state) => {
    const { attachments, attachmentsLoading } = initialState;
    return {
      ...state,
      attachments,
      attachmentsLoading,
    };
  },
  [ContractDetail.LOADING_SHOW_WORK_GROUPS_BY_CONTRACT_BY_SYSTEM]: (state) => ({
    ...state,
    groupsLoading: true,
  }),
  [ContractDetail.LOADING_HIDE_WORK_GROUPS_BY_CONTRACT_BY_SYSTEM]: (state) => ({
    ...state,
    groupsLoading: false,
  }),
  [ContractDetail.FETCH_WORK_GROUPS_BY_CONTRACT_BY_SYSTEM_SUCCESS]: (
    state,
    action
  ) => {
    const { payload } =
      action as FetchWorkGroupsByContractBySystemSuccessContractDetailAction;
    const { content, page, totalElements } = payload;
    return {
      ...state,
      groups: content,
      groupsPagination: {
        ...state?.groupsPagination,
        pageNum: page,
        totalElements,
      },
    };
  },
  [ContractDetail.FETCH_ENVIRONMENTS_BY_SYSTEM_SUCCESS]: (state, action) => {
    const { payload } = action as FetchEnvironmentsBySystemSuccessAction;
    const { content, page, totalElements } = payload;
    return {
      ...state,
      environments: content,
      environmentsPagination: {
        ...state?.environmentsPagination,
        pageNum: page,
        totalElements,
      },
    };
  },
  [ContractDetail.SET_ENVIRONMENTS_BY_SYSTEM_FILTER]: (state, action) => {
    const { payload } = action as SetFilterEnvironmentsBySystemAction;
    return {
      ...state,
      environmentsFilter: payload,
    };
  },
  [ContractDetail.SET_PAGE_ENVIRONMENTS_BY_SYSTEM]: (state, action) => {
    const { payload } = action as SetPageEnvironmentsBySystemAction;
    return {
      ...state,
      environmentsPagination: {
        ...state?.environmentsPagination,
        pageNum: payload,
      },
    };
  },
  [ContractDetail.SET_SIZE_ENVIRONMENTS_BY_SYSTEM]: (state, action) => {
    const { payload } = action as SetSizeEnvironmentsBySystemAction;
    return {
      ...state,
      environmentsPagination: {
        ...state?.environmentsPagination,
        pageSize: payload,
      },
    };
  },
  [ContractDetail.SET_SORT_ENVIRONMENTS_BY_SYSTEM]: (state, action) => {
    const { payload } = action as SetSortEnvironmentsBySystemAction;
    return {
      ...state,
      environmentsSort: payload,
    };
  },
  [ContractDetail.LOADING_SHOW_ENVIRONMENTS_BY_SYSTEM]: (state) => ({
    ...state,
    environmentsLoading: true,
  }),
  [ContractDetail.LOADING_HIDE_ENVIRONMENTS_BY_SYSTEM]: (state) => ({
    ...state,
    environmentsLoading: false,
  }),
  [ContractDetail.FETCH_ENVIRONMENT_SETTINGS_BY_SYSTEM_SUCCESS]: (
    state,
    action
  ) => {
    const { payload } = action as FetchEnvironmentSettingsBySystemSuccessAction;
    return {
      ...state,
      environmentSettings: payload,
    };
  },
  [ContractDetail.SET_WORK_GROUPS_PAGE_BY_CONTRACT_BY_SYSTEM]: (
    state,
    action
  ) => {
    const { payload } =
      action as SetPageWorkGroupsByContractBySystemContractDetailAction;
    return {
      ...state,
      groupsPagination: {
        ...state?.groupsPagination,
        pageNum: payload,
      },
    };
  },
  [ContractDetail.SET_SIZE_PAGE_WORK_GROUPS_BY_CONTRACT_BY_SYSTEM]: (
    state,
    action
  ) => {
    const { payload } =
      action as SetSizePageWorkGroupsByContractBySystemContractDetailAction;
    return {
      ...state,
      groupsPagination: {
        ...state?.groupsPagination,
        pageSize: payload,
      },
    };
  },
  [ContractDetail.SET_WORK_GROUPS_FILTER_BY_CONTRACT_BY_SYSTEM]: (
    state,
    action
  ) => {
    const { payload } =
      action as SetFilterWorkGroupsByContractBySystemContractDetailAction;
    return {
      ...state,
      groupsFilter: payload,
    };
  },
  [ContractDetail.SET_WORK_GROUPS_SORT_BY_CONTRACT_BY_SYSTEM]: (
    state,
    action
  ) => {
    const { payload } =
      action as SetSortWorkGroupsByContractBySystemContractDetailAction;
    return {
      ...state,
      groupsSort: payload,
    };
  },
  [ContractDetail.RESET_ONLY_WORK_GROUPS_BY_CONTRACT_BY_SYSTEM]: (state) => {
    const {
      groups,
      groupsFilter,
      groupsLoading,
      groupsPagination,
      groupsSort,
    } = initialState;
    return {
      ...state,
      groups,
      groupsFilter,
      groupsLoading,
      groupsPagination,
      groupsSort,
    };
  },
  [ContractDetail.LOADING_SHOW_WORK_GROUPS_BY_CONTRACT]: (state) => ({
    ...state,
    groupsByContractLoading: true,
  }),
  [ContractDetail.LOADING_HIDE_WORK_GROUPS_BY_CONTRACT]: (state) => ({
    ...state,
    groupsByContractLoading: false,
  }),
  [ContractDetail.FETCH_WORK_GROUPS_BY_CONTRACT_SUCCESS]: (state, action) => {
    const { payload } =
      action as FetchWorkGroupsByContractSuccessContractDetailAction;
    const { content, page, totalElements } = payload;
    return {
      ...state,
      groupsByContract: content,
      groupsByContractPagination: {
        ...state?.groupsByContractPagination,
        pageNum: page,
        totalElements,
      },
    };
  },
  [ContractDetail.SET_WORK_GROUPS_PAGE_BY_CONTRACT]: (state, action) => {
    const { payload } =
      action as SetPageWorkGroupsByContractContractDetailAction;
    return {
      ...state,
      groupsByContractPagination: {
        ...state?.groupsByContractPagination,
        pageNum: payload,
      },
    };
  },
  [ContractDetail.SET_WORK_GROUPS_SIZE_PAGE_BY_CONTRACT]: (state, action) => {
    const { payload } =
      action as SetSizePageWorkGroupsByContractContractDetailAction;
    return {
      ...state,
      groupsByContractPagination: {
        ...state?.groupsByContractPagination,
        pageSize: payload,
      },
    };
  },
  [ContractDetail.SET_WORK_GROUPS_FILTER_BY_CONTRACT]: (state, action) => {
    const { payload } =
      action as SetFilterWorkGroupsByContractContractDetailAction;
    return {
      ...state,
      groupsByContractFilter: { ...payload },
    };
  },
  [ContractDetail.RESET_ONLY_WORK_GROUPS_BY_CONTRACT]: (state) => {
    const {
      groupsByContract,
      groupsByContractFilter,
      groupsByContractLoading,
      groupsByContractPagination,
      groupsByContractSort,
    } = initialState;
    return {
      ...state,
      groupsByContract,
      groupsByContractFilter,
      groupsByContractLoading,
      groupsByContractPagination,
      groupsByContractSort,
    };
  },
  [ContractDetail.LOADING_SHOW_SLA_PATTERNS_REQUEST]: (state) => ({
    ...state,
    slaPatternsLoading: true,
  }),
  [ContractDetail.LOADING_HIDE_SLA_PATTERNS_REQUEST]: (state) => ({
    ...state,
    slaPatternsLoading: false,
  }),
  [ContractDetail.FETCH_SLA_PATTERNS_SUCCESS]: (state, action) => {
    const { payload } = action as FetchSLAPatternsSuccessContractDetailAction;
    return {
      ...state,
      slaPatterns: payload,
    };
  },
  [ContractDetail.CHECK_IS_EXIST_PATTERN_TITLE_SUCCESS]: (state, action) => {
    const { payload } =
      action as CheckIsExistPatternTitleSuccessContractDetailAction;
    return {
      ...state,
      isPatternTitleExist: payload,
    };
  },
  [ContractDetail.FETCH_PATTERN_BY_ID_SUCCESS]: (state, action) => {
    const { payload } = action as FetchPatternByIdSuccessContractDetailAction;
    return {
      ...state,
      slaPatternActive: payload,
    };
  },
  [ContractDetail.RESET_SLA_PATTERNS]: (state) => {
    const {
      slaPatterns,
      slaPatternsLoading,
      isPatternTitleExist,
      slaPatternActive,
    } = initialState;
    return {
      ...state,
      slaPatterns,
      slaPatternsLoading,
      isPatternTitleExist,
      slaPatternActive,
    };
  },
  [ContractDetail.LOADING_SHOW_SLA_BY_CONTRACT_ID_BY_SYSTEM_ID_REQUEST]: (
    state
  ) => ({
    ...state,
    slaByContractBySystemLoading: true,
  }),
  [ContractDetail.LOADING_HIDE_SLA_BY_CONTRACT_ID_BY_SYSTEM_ID_REQUEST]: (
    state
  ) => ({
    ...state,
    slaByContractBySystemLoading: false,
  }),
  [ContractDetail.FETCH_SLA_BY_CONTRACT_ID_BY_SYSTEM_ID_SUCCESS]: (
    state,
    action
  ) => {
    const { payload } =
      action as FetchSlaByContractIdBySystemIdSuccessContractDetailAction;
    return {
      ...state,
      slaByContractBySystem: payload,
    };
  },
  [ContractDetail.FETCH_TICKET_TYPE_SETTINGS_SUCCESS]: (state, action) => {
    const { payload } =
      action as FetchTicketTypesSettingsSuccessContractDetailAction;
    return {
      ...state,
      ticketTypesSettings: payload,
    };
  },
  [ContractDetail.SET_LOADING_TICKET_TYPE_SETTINGS]: (state, action) => {
    const { payload } =
      action as SetLoadingTicketTypesSettingsContractDetailAction;
    return {
      ...state,
      ticketTypesSettingsLoading: payload,
    };
  },
  [ContractDetail.FETCH_TICKET_TYPES_SUCCESS]: (state, action) => {
    const { payload } = action as FetchTicketTypesSuccessContractDetailAction;
    return {
      ...state,
      ticketTypes: payload,
    };
  },
  [ContractDetail.SET_SELECTED_SYSTEM_SLA]: (state, action) => {
    const { payload } = action as SetSelectedSystemSlaContractDetailAction;
    return {
      ...state,
      selectedSystemSla: payload,
    };
  },
};

export const contractDetailSlice: Reducer<
  ContractDetailReducerState,
  ContractDetailActionsTypes
> = (state = initialState, action) => {
  const reducer = contractDetailReducerMap[action.type];
  if (!reducer) {
    return state;
  }
  return reducer(state, action);
};
