import { createSelector } from 'reselect';

import { KeyValueOption, SelectOption } from '@shared';

import { CONDITION_MAP } from '../config';

import {
  AttributesForFilter,
  ConditionType,
  DestinationType,
  EditRuleData,
  RulesState,
} from './types';

export const getRules = (state: RulesState) => state.rules.rules;
export const getLoading = (state: RulesState) => state.rules.loading;
export const getCurrentRule = (state: RulesState) => state.rules.rule;
export const getCurrentRuleId = (state: RulesState) => state.rules.ruleId;
export const getFilterValues = (state: RulesState) => state.rules.filter;
export const getAttributesFields = (state: RulesState) =>
  state.rules.attributesFields;
export const getSpecialists = (state: RulesState) =>
  state.rules.targetSpecialist || [];
export const getWorkGroups = (state: RulesState) =>
  state.rules.targetWorkgroup || [];
export const getCurrentRuleSpecialist = (state: RulesState) =>
  state.rules.rule?.targetSpecialist;
export const getCurrentRuleWorkGroup = (state: RulesState) =>
  state.rules.rule?.targetWorkgroup;
export const getConditionType = (state: RulesState) =>
  state.rules.rule?.conditionType;
export const getMinutes = (state: RulesState) => state.rules.rule?.minutes || 0;
export const getFiltersFields = (state: RulesState) =>
  state.rules.filtersFields;
export const getOrganizations = (state: RulesState) =>
  state.rules.filtersFields?.organizations || [];
export const getSystems = (state: RulesState) =>
  state.rules.filtersFields?.systems || [];
export const getTicketTypes = (state: RulesState) =>
  state.rules.filtersFields?.ticketTypes || [];
export const getTicketPriorities = (state: RulesState) =>
  state.rules.filtersFields?.ticketPriorities || [];
export const getEnvironments = (state: RulesState) =>
  state.rules.filtersFields?.environments || [];
export const getClients = (state: RulesState) =>
  state.rules.filtersFields?.clients || [];

export const getCurrentRuleSpecialistOptions = createSelector<
  RulesState,
  KeyValueOption | undefined,
  SelectOption[] | null
>([getCurrentRuleSpecialist], (targetSpecialists): SelectOption[] | null => {
  if (targetSpecialists) {
    return [targetSpecialists].map((targetSpecialist) => ({
      title: targetSpecialist.value,
      value: targetSpecialist.key,
    }));
  }
  return null;
});

export const getCurrentRuleWorkGroupOptions = createSelector<
  RulesState,
  KeyValueOption | undefined,
  SelectOption[] | null
>([getCurrentRuleWorkGroup], (targetWorkgroups): SelectOption[] | null => {
  if (targetWorkgroups) {
    return [targetWorkgroups].map((targetWorkgroup) => ({
      title: targetWorkgroup.value,
      value: targetWorkgroup.key,
    }));
  }
  return null;
});

export const getExecutor = createSelector<
  RulesState,
  SelectOption[] | null,
  EditRuleData | null
>(
  [getCurrentRuleSpecialistOptions, getCurrentRuleWorkGroupOptions],
  (targetSpecialist, targetWorkgroup): EditRuleData | null => {
    if (targetSpecialist) {
      return {
        executorType: DestinationType.SPECIALIST,
        executor: targetSpecialist,
      };
    }
    if (targetWorkgroup) {
      return {
        executorType: DestinationType.WORK_GROUP,
        executor: targetWorkgroup,
      };
    }
    return null;
  }
);

export const getConditionTypeOptions = createSelector<
  RulesState,
  ConditionType | undefined,
  SelectOption<ConditionType> | null
>([getConditionType], (conditionType): SelectOption<ConditionType> | null => {
  if (conditionType) {
    return { title: CONDITION_MAP[conditionType], value: conditionType };
  }
  return null;
});

export const getSpecialistsOptions = createSelector<
  RulesState,
  KeyValueOption[] | undefined,
  SelectOption[]
>([getSpecialists], (specialists): SelectOption[] => {
  if (specialists) {
    return specialists.map(
      (specialist): SelectOption => ({
        title: specialist.value,
        value: specialist.key,
      })
    );
  }
  return [];
});

export const getWorkGroupsOptions = createSelector<
  RulesState,
  KeyValueOption[] | undefined,
  SelectOption[]
>([getWorkGroups], (workGroups): SelectOption[] => {
  if (workGroups) {
    return workGroups.map(
      (workGroup): SelectOption => ({
        title: workGroup.value,
        value: workGroup.key,
      })
    );
  }
  return [];
});

export const getAttributesForFilter = createSelector<
  RulesState,
  KeyValueOption[],
  AttributesForFilter
>(
  [
    getOrganizations,
    getSystems,
    getTicketTypes,
    getTicketPriorities,
    getEnvironments,
    getClients,
  ],
  (
    organizations,
    systems,
    ticketTypes,
    ticketPriorities,
    environments,
    clients
  ): AttributesForFilter => {
    const getTitleValue = (data: KeyValueOption[]) =>
      data.map((ticketPriority) => ({
        title: ticketPriority.value,
        value: ticketPriority.key,
      }));

    return {
      organizations: getTitleValue(organizations),
      systems: getTitleValue(systems),
      ticketTypes: getTitleValue(ticketTypes),
      ticketPriorities: getTitleValue(ticketPriorities),
      environments: getTitleValue(environments),
      clients: getTitleValue(clients),
    };
  }
);

export const getPropsRules = (state: RulesState) => ({
  pageNum: state.rules.pagination?.pageNum,
  pageSize: state.rules.pagination?.pageSize,
  loadingRules: getLoading(state),
  sortRules: state?.rules.sort,
  totalElements: state.rules.pagination?.totalElements,
});
