import { SimilarSolutionsState } from './types';

export const getLoading = (state: SimilarSolutionsState) =>
  state.similarSolutions.loading;
export const getTickets = (state: SimilarSolutionsState) =>
  state.similarSolutions.tickets;
export const getArticles = (state: SimilarSolutionsState) =>
  state.similarSolutions.articles;
export const getTicket = (state: SimilarSolutionsState) =>
  state.similarSolutions.ticket;
export const getArticle = (state: SimilarSolutionsState) =>
  state.similarSolutions.article;
export const getLoadingDetail = (state: SimilarSolutionsState) =>
  state.similarSolutions.loadingDetail;
export const getSimilarTicketAttachments = (state: SimilarSolutionsState) =>
  state?.similarSolutions.ticketAttachments;
